import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormHelperText,
  FormControl,
} from "@mui/material";
import React, { useState } from "react";
import { updateCompanyPartsById } from "../../api/api";

const EditDataDialog = ({
  openEditDialog,
  setOpenEditDialog,
  rowId,
  setRowId,
  row,
  submitted,
  setSubmitted,
}) => {
  const [newName, setNewName] = useState(row.name);
  const [newDescription, setNewDescription] = useState(row.description);
  const [errorMessage, setErrorMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleNameChange = (e) => {
    const newText = e.target.value;
    setNewName(newText);
    if (newText.length > 50) {
      setErrorMessage("Ad 50-dən çox simvol ola bilməz");
      setIsButtonDisabled(true);
    } else {
      setErrorMessage("");
      setIsButtonDisabled(false);
    }
  };

  const handleUpdateCompanyById = async (rowId, newName, newDescription) => {
    await updateCompanyPartsById(rowId, newName, newDescription);
    setSubmitted(!submitted);
  };

  return (
    <>
      {rowId === row.id ? (
        <Dialog
          fullWidth
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            setRowId();
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Məlumatları dəyişdir</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Adı"
                slotProps={{
                  input: {
                    maxLength: 50,
                  },
                }}
                type="text"
                placeholder={row.name}
                defaultValue={row.name}
                fullWidth
                onChange={handleNameChange}
                error={errorMessage !== ""}
                helperText={errorMessage}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenEditDialog(false);
                setRowId();
              }}
              color="primary"
            >
              Ləğv et
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleUpdateCompanyById(rowId, newName, newDescription);
                setOpenEditDialog(false);
              }}
              disabled={isButtonDisabled}
            >
              Redaktə et
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default EditDataDialog;
