import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Day from "./Day";
import LabelOfDay from "./parts/LabelOfDay";
import { deleteProductionCalendar } from "../../services/prodCalendar/productionCalendar";
import { enqueueSnackbar } from "notistack";

const Container = styled.div`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  overflow: hidden;
`;

const Table = styled.table`
  border-radius: 5px;
  padding: 10px 40px;
  width: fit-content;
  min-width: 100%;
  display: table;
`;

const Colgroup = styled.colgroup``;
const Col = styled.col`
  padding: 5px 15px;
  border: 1px solid rgba(255, 255, 255, 0.025);
  min-width: 100px;
  width: fit-content;
`;

const Thead = styled.thead`
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(41, 47, 96, 1);
`;

const Th = styled.th`
  resize: horizontal;
  white-space: nowrap;
  padding: 5px 5px;
  text-align: center;
  font-weight: 500;
  text-overflow: ellipsis;
  cursor: default;
  width: 120px;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  border: 1px solid rgba(255, 255, 255, 0.05);
`;

const Calendar = ({
  date,
  calendarDatas,
  days,
  updatedCalendar,
  deletedCalendar,
  delProductionCalendar,
  editable,
  setStartDate,
  setEndDate,
  dayIsDeleted,
  handleIsDeletedDay,
  handleDayIsEdited,
}) => {
  // Step 1: Get current month and year
  const currentDate = new Date();
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
  const [isSelect, setIsSelect] = useState(false);

  const [draggedLabel, setDraggedLabel] = useState(null);
  useEffect(() => {
    const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
    const lastDayOfWeek = lastDayOfMonth.getDay();

    const weekDays = [
      { number: 0, name: "Sunday" },
      { number: 1, name: "Monday" },
      { number: 2, name: "Tuesday" },
      { number: 3, name: "Wednesday" },
      { number: 4, name: "Thursday" },
      { number: 5, name: "Friday" },
      { number: 6, name: "Saturday" },
    ];

    const weekDayInfo = weekDays.find((day) => day.number === lastDayOfWeek);

    const firstDayOfWeek = weekDayInfo?.number;

    const daysInPreviousMonth = new Date(
      currentYear,
      currentMonth,
      0
    ).getDate();

    const calcweeks = firstDayOfWeek - 1;

    const totalCalcedDate = daysInPreviousMonth - calcweeks;
    setStartDate(() => {
      let adjustedYear = currentYear;
      let adjustedMonth = currentMonth;
      if (currentMonth - 1 < 0) {
        adjustedYear -= 1;
        adjustedMonth = 11;
      } else {
        adjustedMonth = currentMonth - 1;
      }

      if (totalCalcedDate > 31) {
        return `${currentYear}-${String(currentMonth).padStart(2, "0")}-01`;
      } else {
        return `${adjustedYear}-${String(adjustedMonth).padStart(
          2,
          "0"
        )}-${String(totalCalcedDate).padStart(2, "0")}`;
      }
    });

    setEndDate(() => {
      const nextMonth = currentMonth + 1;
      const normalizedMonth = nextMonth > 12 ? 1 : nextMonth;
      const yearAdjustment = nextMonth > 12 ? currentYear + 1 : currentYear;

      const daysInCurrentMonth = new Date(
        currentYear,
        currentMonth,
        0
      ).getDate();
      const endDay = 35 - (daysInCurrentMonth + calcweeks);

      const normalizedDay = Math.max(1, Math.min(endDay, 31));

      return `${yearAdjustment}-${String(normalizedMonth).padStart(
        2,
        "0"
      )}-${String(normalizedDay).padStart(2, "0")}`;
    });

    // for (let i = firstDayOfWeek - 2; i >= 0; i--) {
    //   no_data_days.push({
    //     day: daysInPreviousMonth - i,
    //     isCurrentMonth: false,
    //   });
    // }
    // // // Render days of current month
    // for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
    //   no_data_days.push({
    //     day: i,
    //     isCurrentMonth: true,
    //   });
    // }
    // // Render first days of next month
    // const daysInNextMonth = 35 - no_data_days.length;
    // for (let i = 1; i <= daysInNextMonth; i++) {
    //   no_data_days.push({
    //     day: i,
    //     isCurrentMonth: false,
    //   });
    //   console.log({
    //     day: i,
    //     isCurrentMonth: false,
    //   });
    // }
    // // }
    // no_data_days.forEach((dayObj) => {
    //   let match = dayss.find((obj) => obj.day === dayObj.day);
    //   if (match) {
    //     dayObj.offDayType = match.offDayType;
    //     dayObj.dayId = match.dayId;
    //   }
    // });

    // dayssetDays(dayss);
    // setDays(days?.length ? days : no_data_days);
  }, [currentMonth, currentYear, calendarDatas]);
  useEffect(() => {
    setCurrentMonth(date?.month);
    setCurrentYear(date?.year);
  }, [date]);

  const onDragStart = (label) => {
    setDraggedLabel(label);
  };

  const onDrop = (e, day) => {
    e.preventDefault();

    // if (!day.isCurrentMonth) return;

    if (draggedLabel) {
      // e.target.style.border = "none";
      const updatedDays = days.state.map((d) => ({
        ...d,
        offDayType: d.day === day.day ? draggedLabel : d.offDayType,
        isHighlighted: false, // Reset highlighting
        dayIsDeleted: false,
        dayIsEdited: false,
      }));
      days.set(updatedDays);
      updatedCalendar.set((prev) => {
        let dayDetails = [...prev?.dayDetails];
        let workDayIds = [...deletedCalendar.state.workDayIds];
        workDayIds = workDayIds?.filter((wid) => wid !== day?.dayId);
        deletedCalendar.set((prev) => ({ ...prev, workDayIds }));
        dayDetails = [
          ...dayDetails,
          ...findDifferences(days.state, updatedDays),
        ];

        return { ...prev, dayDetails };
      });
      // setDraggedLabel(null);
    }
  };
  const onDragOver = (e, day) => {
    if (!draggedLabel) return;
    e.preventDefault();

    const updatedDays = days.state.map((d) => ({
      ...d,
      isHighlighted: d.day === day.day,
    }));
    days.set(updatedDays);
  };

  const onDelete = (day) => {
    // days.set((prevDays) =>
    //   prevDays.map((d) => {
    //     if (d?.dayId === day.dayId) {
    //       return { ...d, offDayType: null };
    //     }
    //     return d;
    //   })
    // );
    // delProductionCalendar(day?.dayId);
    deletedCalendar.set((prev) => {
      let workDayIds = [...prev?.workDayIds];
      let dayDetails = [...updatedCalendar.state.dayDetails];

      if (workDayIds.includes(day?.dayId)) {
        workDayIds = workDayIds?.filter((d) => d !== day?.dayId);
      } else {
        workDayIds.push(day?.dayId);
        dayDetails = dayDetails.filter((d) => d?.dayId !== day?.dayId);
        updatedCalendar.set((prev) => ({ ...prev, dayDetails }));
      }
      return { ...prev, workDayIds };
    });
  };

  function findDifferences(arr1, arr2) {
    let differences = [];

    // Iterate over the arrays assuming they have the same structure and order
    for (let i = 0; i < arr1.length; i++) {
      let obj1 = arr1[i];
      let obj2 = arr2[i];

      // Check if the objects have the same dayId
      if (obj1.dayId === obj2.dayId) {
        // Compare the offdayType of both objects
        if (obj1.offDayType !== obj2.offDayType) {
          differences.push({
            dayId: obj1.dayId,
            offDayType: obj2.offDayType,
          });
        }
      }
    }

    return differences;
  }
  const labels = [
    {
      title: "Bayram günü",
      bgColor: "red",
      key: "holiday",
      active: draggedLabel === "holiday",
    },
    {
      title: "Qeyri-iş günü",
      bgColor: "blue",
      key: "nonWorkingDay",
      active: draggedLabel === "nonWorkingDay",
    },
    {
      title: "İstirahət günü",
      bgColor: "green",
      key: "restDay",
      active: draggedLabel === "restDay",
    },
    {
      title: "Hüzn günü",
      bgColor: "black",
      key: "mourningDay",
      active: draggedLabel === "mourningDay",
    },
  ];

  const labelOnClick = (label) => {
    if (!draggedLabel && !isSelect) {
      setIsSelect(true);
      setDraggedLabel(label.key);
    } else if (draggedLabel === label.key && isSelect) {
      setIsSelect(false);
      setDraggedLabel(null);
    } else {
      setDraggedLabel(label.key);
    }
  };

  const onClickHandler = (day) => {
    if (!isSelect) return;

    days.set((prevDays) =>
      prevDays.map((d) => {
        if (d === day) {
          return { ...d, offdayType: draggedLabel };
        }
        return d;
      })
    );
  };

  const handleDailyHours = (value, dayId) => {
    if (value < 24) {
      const updatedDays = days.state.map((d) => {
        if (d?.dayId === dayId) {
          d = {
            ...d,
            dailyHours: +value,
          };
        }
        return d;
      });
      days.set(updatedDays);
    }

    if (value !== "" && value < 24) {
      updatedCalendar.set((prev) => {
        let dayDetails = [...prev?.dayDetails];
        let current = dayDetails?.find((d) => d?.dayId === dayId);
        if (current !== undefined) {
          current.dailyHours = +value;
        } else {
          dayDetails.push({
            dayId: dayId,
            dailyHours: +value,
          });
        }
        return { ...prev, dayDetails };
      });
    }
  };
  return (
    <div>
      <div style={{ display: "flex", position: "relative" }}>
        <div style={{ marginRight: "5px" }}>
          {labels.map((label, index) => (
            <LabelOfDay
              key={label.key}
              active={label.active}
              bgColor={label.bgColor}
              title={label.title}
              onDragStart={() => onDragStart(label.key)}
              onDragEnd={() => setDraggedLabel(null)}
              onClick={() => labelOnClick(label)}
              draggable={editable}
            />
          ))}
        </div>
        <Container>
          {!calendarDatas?.calendar?.length ? (
            <div className="no-data-layout">data yoxdur</div>
          ) : null}
          <Table className="calendar">
            <Colgroup>
              <Col
                span={5}
                style={{
                  backgroundColor: "rgba(21, 27, 76, 0.7)",
                }}
              />
              <Col
                span={2}
                style={{ backgroundColor: "rgba(21, 27, 0, 0.35)" }}
              />
            </Colgroup>
            <Thead>
              <Tr>
                {[
                  "Bazar Ertəsi",
                  "Çərşənbə axşamı",
                  "Çərşənbə",
                  "Cümə axşamı",
                  "Cümə",
                  "Şənbə",
                  "Bazar",
                ].map((day, index) => (
                  <Th
                    key={`${day}${index}`}
                    className="day-label"
                    style={{
                      color: (day === "Sun" || day === "Sat") && "red",
                    }}
                  >
                    {day}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {days.state.map((day, index) => {
                if (index < 40) {
                  return (
                    <React.Fragment key={`${day} ${index}`}>
                      {index % 7 === 0 && <Tr></Tr>}
                      <td style={{ height: "100px", padding: "0" }}>
                        <Day
                          day={day}
                          onDrop={(e) => onDrop(e, day)}
                          onDragOver={(e) => onDragOver(e, day)}
                          isSelect={isSelect}
                          onDelete={onDelete}
                          onClick={(day) => {
                            onClickHandler(day);
                          }}
                          editable={editable}
                          limitExceed={day?.limitExceed}
                          dayIsDeleted={dayIsDeleted}
                          handleIsDeletedDay={handleIsDeletedDay}
                          handleDayIsEdited={handleDayIsEdited}
                          handleDailyHours={handleDailyHours}
                          updatedCalendar={updatedCalendar}
                        />
                      </td>
                    </React.Fragment>
                  );
                }
              })}
            </Tbody>
          </Table>
        </Container>
      </div>

      {/* <button
        onClick={() => setCurrentMonth((prev) => (prev === 0 ? 11 : prev - 1))}
      >
        Previous Month
      </button>
      <button
        onClick={() => setCurrentMonth((prev) => (prev === 11 ? 0 : prev + 1))}
      >
        Next Month
      </button> */}
    </div>
  );
};

export default Calendar;
