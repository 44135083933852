import React, { useEffect, useState } from "react";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Divider,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import AddEmployee from "../../pages/employee/AddEmployee";
import { styled } from "@mui/material/styles";
import Badge from "@mui/icons-material/Badge";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { AccountCircle } from "@mui/icons-material";
import { GENERAL_BLOCK } from "../../styled";
import useUserActions from "../../hooks/useUserActions";
import { act } from "react";
import { getAllStructures } from "../../api/api";
import useAccountActions from "../../hooks/useAccountActions";
const AccountCreate = ({ preDefinedData }) => {
  const [completed, setCompleted] = useState({});
  const [structures, setAllStructures] = useState([]);
  //   const { preDefinedData } = useUserActions();
  const [userRegisterData, setUserRegisterData] = useState({
    user: preDefinedData?.user || "",
    title: "",
    email: preDefinedData?.email || "",
    structure: "",
  });
  console.log(userRegisterData);
  const { registerAccount } = useAccountActions();
  const handleChange = (e) => {
    setUserRegisterData({
      ...userRegisterData,
      [e.target.name]: e.target.value,
    });
  };

  const getAllStructures_ = async () => {
    try {
      const { data } = await getAllStructures();
      if (!data.structures) return [];
      console.log(data);
      return data?.structures && setAllStructures(data.structures);
    } catch (error) {
      console.log("getAllStructuresForFilter", error);
    }
  };
  useEffect(() => {
    getAllStructures_();
  }, []);

  console.log(structures);
  return (
    <div>
      <form action="">
        <TextField
          label="Poçt ünvanı"
          type="email"
          name="email"
          value={userRegisterData.email}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          label="Başlıq"
          type="title"
          name="title"
          value={userRegisterData.title}
          onChange={handleChange}
          fullWidth
        />
        <Divider sx={{ my: 3 }} />
        <FormControl fullWidth>
          <InputLabel>Struktur</InputLabel>
          <Select
            name="structure"
            value={userRegisterData?.citizenship}
            onChange={handleChange}
            required
            label="Struktur"
          >
            {structures?.length > 0 &&
              structures.map((structure) => (
                <MenuItem value={structure._id}>
                  {structure?.organization?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <div className="d-flex justify-content-end mt-3">
          {/* <Button onClick={() => setUserRegisterDialog(false)}>Bağla</Button> */}
          <Button
            onClick={() => registerAccount(userRegisterData)}
            variant="contained"
          >
            Yadda Saxla
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AccountCreate;
