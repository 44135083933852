import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useTheme } from "@emotion/react";
// import SwipeableViews from "react-swipeable-views";

// Components
import Header from "../../components/layouts/header/Header";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";
import MyCabinetNavbar from "./MyCabinetNavbar";

// Material UI
import {
  AppBar,
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

// CSS
import style from "./myProfileStyle.module.css";
import OrdersTable from "./OrdersContracts/OrdersTable";
import ContractsTable from "./OrdersContracts/ContractsTable";
import Navbar from "./Navbar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function OrdersContracts() {
  const [navbarStatus, setNavbarStatus] = useState(false);
  const [row, setrow] = useState();

  const [value, setValue] = useState(0);
  const theme = useTheme();

  const showHandler = () => setNavbarStatus(!navbarStatus);
  const handleChangeTab = (e, value) => {
    setValue(value);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function expDialogSwitcher(paramsrow) {
    setrow(paramsrow);
  }
  // Header data
  const headerData = {
    title: "Əmr və müqavilələr",
    variant: "h3",
  };

  const navbarStatusHandler = (currentValue) => {
    setNavbarStatus(currentValue);
  };

  return (
    <>
      <Grid
        className={style.navbarPlace}
        // onMouseEnter={showHandler}
        // onMouseLeave={showHandler}
      >
        {/* {navbarStatus === false ? (
          <Grid className={style.navbarProfile}>
            <ReceiptLongIcon sx={{ width: "95%", height: "95%" }} />
          </Grid>
        ) : (
          <MyCabinetNavbar />
        )} */}
        <Navbar navbarStatus={navbarStatusHandler} />
      </Grid>
      <React.Fragment>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
          <ToastContainer />
          <Divider sx={{ marginTop: 5 }} />
          <Grid>
            <Grid
              className={
                style.infoSection +
                " " +
                (navbarStatus ? style.animation2 : style.animation1)
              }
            >
              <Grid item xs={12} mb={6}>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    sx={{
                      "& .MuiTabs-flexContainer": {
                        transition: "transform 0.5s ease-out", // Smooth transition for tabs
                      },
                    }}
                  >
                    <Tab label="Əmrlər" value={0} {...a11yProps(0)} />
                    <Tab label="Müqavilələr" value={1} {...a11yProps(1)} />
                  </Tabs>
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <Grid maxWidth={"100%"}>
                      <OrdersTable />
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <Grid maxWidth={"100%"}>
                      <ContractsTable />
                    </Grid>
                  </TabPanel>
                </Box>
                {/* <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0}>
                    <Grid maxWidth={"100%"}>
                      <OrdersTable />
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Grid maxWidth={"100%"}>
                      <ContractsTable />
                    </Grid>
                  </TabPanel> */}
                {/* </SwipeableViews> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    </>
  );
}
