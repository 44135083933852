import React, { useEffect, useState } from "react";
import { Field, FieldArray, ErrorMessage, useFormikContext } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  FormHelperText,
} from "@mui/material";
import { getCommonData } from "../../api/api";
import { checkUniqueness } from "../../utils/checkUniquenessForEmployee";

const SalarySupplements = ({
  values,
  setSelectedType,
  setOpenAddDialog,
  submitted,
}) => {
  const [supplementName, setSupplementName] = useState([]);
  const { errors, setFieldValue, setErrors } = useFormikContext();

  const handleWageSelect = (item) => {
    console.log(item);
    const selectWage = supplementName.find((wage) => {
      return wage._id === item;
    });
    return selectWage;
  };
  console.log(supplementName);
  useEffect(() => {
    const handleSupplements = async () => {
      const response = await getCommonData("supplementalWageGeneral");
      setSupplementName(response.data);
    };
    handleSupplements();
  }, [submitted]);
  return (
    <>
      <FieldArray name="supplementalWages">
        {({ push, remove }) => (
          <div>
            {values?.supplementalWages &&
              values?.supplementalWages.map((form, index) => (
                <div key={index}>
                  <>
                    <Typography mt={3} mb={3} variant="h6">
                      {index + 1})Əməkhaqqına əlavələr
                    </Typography>

                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.supplementalWages?.[index]?.name &&
                            Boolean(errors?.supplementalWages?.[index]?.name)
                          }
                          fullWidth
                        >
                          <InputLabel id={`supplementalWages[${index}].name`}>
                            Əməkhaqqına əlavənin səbəbi *
                          </InputLabel>
                          <Field
                            as={Select}
                            name={`supplementalWages[${index}].name`}
                            labelId={`supplementalWages[${index}].name`}
                            label="Əməkhaqqına əlavənin səbəbi *"
                            id={`supplementalWages[${index}].name`}
                            onChange={(event) => {
                              checkUniqueness(
                                values.supplementalWages,
                                "name",
                                event,
                                setFieldValue,
                                `supplementalWages[${index}]`,
                                "əməkhaqqına əlavənin səbəbi",
                                handleWageSelect
                              );
                              if (errors?.supplementalWages?.[index]?.name) {
                                setErrors({
                                  ...errors,
                                  supplementalWages: [
                                    ...errors.supplementalWages.slice(0, index),
                                    {
                                      ...errors.supplementalWages[index],
                                      name: "",
                                    },
                                    ...errors.supplementalWages.slice(
                                      index + 1
                                    ),
                                  ],
                                });
                              }
                            }}
                            error={
                              errors?.supplementalWages?.[index]?.name &&
                              Boolean(errors?.supplementalWages?.[index]?.name)
                            }
                            fullWidth
                          >
                            {supplementName &&
                              supplementName
                                .sort((a, b) =>
                                  a?.name?.localeCompare(b?.name, "az")
                                )
                                .map((item, index) => (
                                  <MenuItem key={index} value={item._id}>
                                    {item.value}
                                  </MenuItem>
                                ))}
                            <Button
                              sx={{
                                mt: 3,
                                ml: 3,
                              }}
                              variant="outlined"
                              onClick={() => {
                                setOpenAddDialog(true);
                                setSelectedType([
                                  "supplementalWageGeneral",
                                  { name: "Ümumi əlavələr" },
                                ]);
                              }}
                            >
                              Yeni əməkhaqqına əlavənin səbəbi əlavə et
                            </Button>
                          </Field>
                        </FormControl>
                        {errors?.supplementalWages?.[index]?.name &&
                          errors?.supplementalWages?.[index]?.name && (
                            <FormHelperText error>
                              {errors?.supplementalWages?.[index]?.name}
                            </FormHelperText>
                          )}
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          onChange={(e) => {
                            const value = e.target.value;

                            setFieldValue(
                              `supplementalWages[${index}].value`,
                              value
                            );
                          }}
                          as={TextField}
                          name={`supplementalWages[${index}].value`}
                          label="Əməkhaqqına əlavənin dəyəri"
                          fullWidth
                          error={
                            errors.supplementalWages?.[index]?.value &&
                            Boolean(errors.supplementalWages?.[index]?.value)
                          }
                          helperText={
                            errors.supplementalWages?.[index]?.value &&
                            errors.supplementalWages?.[index]?.value
                          }
                        />
                      </Grid>
                    </Grid>
                  </>

                  <Button
                    sx={{ mt: 3 }}
                    variant="contained"
                    color="error"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    Əməkhaqqına əlavəni sil
                  </Button>
                </div>
              ))}
            <Button
              sx={{ mt: 3 }}
              variant="contained"
              type="button"
              onClick={() => push({ name: "", value: "" })}
            >
              Əməkhaqqına əlavə et
            </Button>
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default SalarySupplements;
