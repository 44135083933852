import { Grid, InputBase, Typography } from "@mui/material";
import React from "react";

export default function CommentInput({ label, value }) {
  return (
    <Grid sx={styles.container}>
      <Typography color={"white"}>{label}</Typography>
      <hr />
      <InputBase
        readOnly={true}
        value={value}
        minRows={3}
        disabled={true}
        maxRows={3}
        multiline={true}
        placeholder="Şərh yoxdur..."
        contentEditable={false}
        sx={styles.input}
        slotProps={{
          input: {
            style: {
              color: value ? styles.input.color : undefined,
              opacity: "1",
              WebkitTextFillColor: styles.input.color,
            },
          },
        }}
      />
    </Grid>
  );
}

const styles = {
  container: { width: "100%", marginTop: 5 },
  input: {
    backgroundColor: "#FFF",
    color: "#000",
    paddingY: 5,
    paddingX: "20px",
    width: "100%",
    borderRadius: 3,
    lineHeight: "24px",
  },
};
