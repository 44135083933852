import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getCommand_API_SERVICE,
  getEmployeeId_API_SERVICE,
  getEmployeeWithDetails_SERVICE,
  postFillingVacantPositionOrder_SERVICE,
  putVacantOrder_SERVICE,
} from "../../../../../services/ordersServices";
import { enqueueSnackbar } from "notistack";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const RecallWork = () => {
  const [value, setValue] = useState(null);
  const [searchParams] = useSearchParams();

  // Routerdakı valunu görürməy üçün
  useEffect(() => {
    const value = searchParams.get("value");
    setValue(value);
  }, [searchParams]);
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [data, setData] = useState([]);
  const [currentContractData, setCurrentContract] = useState(null);
  const [commandData, setCommandData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [employee, setEmployee] = useState({
    employeeId: "",
    tableNumber: "",
    staffId: "",
    absenceInfo: "",
  });
  const [recallWorkData, setRecallWorkData] = useState({
    reasonOfRecall: "",
    dateOfRecallToWork: null,
    absenceDays: null,
  });
  const isFormValid = () => {
    return (
      !recallWorkData?.reasonOfRecall || !recallWorkData?.dateOfRecallToWork
    );
  };

  //Əməkdaşları gətirmək üçün
  const getFillingVacantPositionOrder = async () => {
    try {
      const res = await getEmployeeWithDetails_SERVICE();

      if (res) {
        setEmployeeData(res?.data?.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Əmr yaratmaq
  const createFillingVacantPositionOrder = async () => {
    try {
      const payload = {
        staffId: employee.staffId,
        reasonOfRecall: recallWorkData.reasonOfRecall,
      };

      if (recallWorkData.dateOfRecallToWork) {
        payload.dateOfRecallToWork = moment(
          recallWorkData.dateOfRecallToWork
        ).format("MM/DD/yyyy");
      }
      let final = {
        docModel: "Employee",
        subjects: [employee.employeeId],
        data: {
          ...payload,
        },
      };
      let response = await postFillingVacantPositionOrder_SERVICE(
        "recall-to-work-order",
        final
      );
      navigate("/documentation/orders");
      if (response) {
        enqueueSnackbar({ message: "Uğurla yaradıldı", variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar({ message: "Xəta baş verdi", variant: "error" });
      // validateForm();
    }
  };
  // Əmr update etmək
  const updateVacantorder = async () => {
    try {
      const payload = {
        ...recallWorkData,
        // vacantStaffId: staffId,
        staffId: employee?.empStaffId,
      };
      if (recallWorkData.dateOfRecallToWork) {
        payload.dateOfRecallToWork = moment(
          recallWorkData.dateOfRecallToWork
        ).format("MM/DD/yyyy");
      }
      // payload.additionToSalary = Number(payload.additionToSalary);
      // const result = removeEmptyStrings(payload);
      let final = {
        docModel: "Employee",
        subjects: [employee.employeeId],
        data: {
          ...payload,
        },
      };
      let response = await putVacantOrder_SERVICE(
        "recall-to-work-order",
        value,
        final
      );
      navigate("/documentation/orders");
      if (response) {
        enqueueSnackbar({ message: "Uğurla yaradıldı", variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar({ message: "Xəta baş verdi", variant: "error" });
      // validateForm();
    }
  };

  // Editde dataları gətirmək üçün
  const getCommandApi = async () => {
    try {
      if (value) {
        const res = await getCommand_API_SERVICE(value, "recall-to-work-order");
        setCommandData(res?.data);

        setEmployee({
          ...employee,
          employeeId: res?.data?.subjects?.[0]?.subject?._id,
          tableNumber: res?.data?.subjects?.[0]?.subject?.tableNumber,
          empStaffId: res?.data?.data?.staffId?.value,
        });
        setRecallWorkData({
          ...recallWorkData,
          reasonOfRecall: res?.data?.data?.reasonOfRecall?.value,
          dateOfRecallToWork: res?.data?.data?.dateOfRecallToWork?.value,
        });
        if (commandData.length > 0) {
          setCurrentContract(
            contractData?.find((s) =>
              s?._id === commandData?.data?.staffId?.value?.value
                ? commandData?.data?.staffId?.value?.value
                : commandData?.data?.staffId?.value
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getContract = async () => {
    try {
      const res = await getEmployeeId_API_SERVICE(employee.employeeId);
      if (res) {
        setContractData(res.data?.staffs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    employee?.employeeId && getContract();
  }, [employee?.employeeId]);
  useEffect(() => {
    if (value) {
      getCommandApi();
    }
  }, [value]);
  useEffect(() => {
    getFillingVacantPositionOrder();
  }, []);

  useEffect(() => {
    if (commandData?.data?.staffId?.value && contractData?.length > 0) {
      const matchingContract = contractData?.find((s) =>
        s?._id === commandData?.data?.staffId?.value?.value
          ? commandData?.data?.staffId?.value?.value
          : commandData?.data?.staffId?.value
      );

      if (matchingContract) {
        setCurrentContract(matchingContract);
      }
    }
  }, [commandData, contractData]);

  //Seçilən əməkdaşın datasını gətirmək
  const handleEmployeeChange = (value) => {
    const emp = employeeData.find((e) => {
      return e?.employee?._id === value;
    });
    setData(emp);
    setEmployee({
      ...employee,
      employeeId: value || "",
      tableNumber: emp?.employee?.tableNumber || "",
      staffId: emp?.contracts?.[0]?.staff || "",
      absenceInfo: emp?.contracts?.[0]?.absenceInfo || "",
    });
  };

  useEffect(() => {
    handleEmployeeChange(employee.employeeId);
  }, [employee.employeeId]);

  const handleInputChange = (name, value) => {
    setRecallWorkData({
      ...recallWorkData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    await createFillingVacantPositionOrder();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        <CardContent>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            İşə geri çağırma Əmri
          </Typography>
          <Divider my={6} />
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid sx={{ width: "50%" }}>
              <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                Əməkdaş Məlumatları
              </Typography>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: employeeData.length > 0 ? "" : "none",
                }}
              >
                <InputLabel>Əməkdaş(A.S.A)</InputLabel>
                <Select
                  name="Əməkdaş(A.S.A)"
                  label="Əməkdaş(A.S.A)"
                  variant="outlined"
                  value={employee.employeeId || ""}
                  onChange={(e) => handleEmployeeChange(e.target.value)}
                >
                  {employeeData &&
                    employeeData.map((d, i) => (
                      <MenuItem key={i} value={d?.employee?._id}>
                        {`${d?.employee?.firstname} ${d?.employee?.lastname} ${d?.employee?.fathername}`}
                      </MenuItem>
                    ))}
                </Select>
                {!employeeData.length > 0 && (
                  <Typography>
                    Məzuniyyətdə və ya ezamiyyətdə olan Əməkdaş yoxdur
                  </Typography>
                )}
              </FormControl>
              <Grid sx={{ display: "flex", gap: "25px" }}>
                <TextField
                  name="Müqavilə nömrəsi"
                  label="Müqavilə nömrəsi"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  variant="outlined"
                  my={2}
                  value={
                    data?.contracts?.[0]?.labourContract?.contractNumber || ""
                  }
                />
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={
                      data?.contracts?.[0]?.labourContract?.contractSignDate ||
                      null
                    }
                    label="Müqavilənin tarixi"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
              </Grid>
              <TextField
                name="Tabel nömrəsi"
                label="Tabel nömrəsi"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
                value={employee?.tableNumber || ""}
              />
              <TextField
                name="Təşkilat"
                label="Təşkilat"
                value={data?.contracts?.[0]?.structure?.name || ""}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
              />
              <TextField
                name="Struktur"
                label="Struktur"
                value={data?.contracts?.[0]?.topPartOfStructure?.name || ""}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
              />
              <TextField
                name="Substruktur"
                label="Substruktur"
                value={data?.contracts?.[0]?.subStructure?.name || ""}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
              />
              <TextField
                name="Vəzifə"
                label="Vəzifə"
                value={data?.contracts?.[0]?.position?.name || ""}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
              />
            </Grid>
            <Grid sx={{ width: "50%" }}>
              <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                İşə geri çağırma səbəbi
              </Typography>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel>İşə geri çağırma növü</InputLabel>
                <Select
                  label="İşə geri çağırma növü"
                  variant="outlined"
                  value={data?.contracts?.[0]?.absenceInfo?.type || ""}
                >
                  <MenuItem value="businessTrip">
                    Ezamiyyədən geri çağırma
                  </MenuItem>
                  <MenuItem value="vacation">
                    Məzuniyyətdən geri çağırma
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <TextField
                  name="İşə geri çağırma səbəbi"
                  label="İşə geri çağırma səbəbi"
                  slotProps={{
                    input: {
                      maxLength: 500,
                    },
                  }}
                  value={
                    recallWorkData?.reasonOfRecall?.value
                      ? recallWorkData?.reasonOfRecall?.value
                      : recallWorkData?.reasonOfRecall
                  }
                  variant="outlined"
                  onChange={(e) =>
                    handleInputChange("reasonOfRecall", e.target.value)
                  }
                  my={2}
                />
              </FormControl>
              <Grid
                mb={4}
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: "25px",
                  pointerEvents: "none",
                }}
              >
                <TextField
                  name="Əmrin nömrəsi"
                  label="Əmrin nömrəsi"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  value={
                    data?.contracts?.[0]?.absenceInfo?.contractDetails
                      ?.businessTripContract?.docNumber || ""
                  }
                  my={2}
                />
                <DatePicker
                  my={2}
                  inputFormat="dd/MM/yyyy"
                  label="Əmrin imzalanma tarixi"
                  value={
                    data?.contracts?.[0]?.absenceInfo?.contractDetails
                      ?.businessTripContract?.signDate || null
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid
                mb={4}
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: "25px",
                  pointerEvents: "none",
                }}
              >
                <DatePicker
                  my={2}
                  sx={{ width: "50%" }}
                  inputFormat="dd/MM/yyyy"
                  label="Məzuniyyətin /Ezamiyyənin başlama tarixi"
                  value={data?.contracts?.[0]?.absenceInfo?.startDate || null}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  my={2}
                  sx={{ width: "50%" }}
                  inputFormat="dd/MM/yyyy"
                  label="Məzuniyyətin /Ezamiyyənin bitmə tarixi"
                  value={data?.contracts?.[0]?.absenceInfo?.endDate || null}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid mb={4} sx={{ display: "flex", width: "100%", gap: "25px" }}>
                <FormControl sx={{ pointerEvents: "none", width: "100%" }}>
                  <DatePicker
                    my={2}
                    inputFormat="dd/MM/yyyy"
                    label="İşə çıxma tarixi"
                    value={
                      data?.contracts?.[0]?.absenceInfo?.returnDate || null
                    }
                    minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
                <DatePicker
                  my={2}
                  inputFormat="dd/MM/yyyy"
                  name="dateOfRecallToWork"
                  label="Yeni işə çıxma tarixi"
                  value={
                    recallWorkData?.dateOfRecallToWork?.value
                      ? recallWorkData?.dateOfRecallToWork?.value
                      : recallWorkData?.dateOfRecallToWork
                  }
                  minDate={new Date()}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  onChange={(date) =>
                    handleInputChange("dateOfRecallToWork", date)
                  }
                />
              </Grid>
              <TextField
                name="Gün"
                label="Gün"
                value={data?.contracts?.[0]?.absenceInfo?.absenceDays || ""}
                sx={{ width: "100%", pointerEvents: "none" }}
                variant="outlined"
                my={2}
              />
            </Grid>
          </Grid>
          <Divider my={6} />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "25px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => navigate("/documentation/orders")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              type="submit"
              title="Kənarlaşdırma yarat"
              variant="contained"
              sx={{ height: "100%" }}
              disabled={commandData?._id ? false : isFormValid()}
              onClick={
                commandData?._id
                  ? updateVacantorder
                  : createFillingVacantPositionOrder
              }
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Təsdiq et
              </p>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default RecallWork;
