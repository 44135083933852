import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid2,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import NestedSelect from "../../../../../utils/NestedSelect";
import useOrders from "../../../../../hooks/useOrders";

const Divider = styled(MuiDivider)(spacing);

const ExtensionOrder = () => {
  const {
    data,
    loading,
    employee,
    navigate,
    setStaffId,
    isFormValid,
    structureId,
    commandData,
    contractData,
    handleSubmit,
    setPositionId,
    allStructures,
    setStructureId,
    nestedChildren,
    setContractName,
    handleInputChange,
    emptyPositionDatas,
    currentContractData,
    setContractPosition,
    handleEmployeeChange,
    handleCurrentContract,
    createFillingVacantPositionOrder,
  } = useOrders();
  useEffect(() => {
    setContractName("vacation-extension-order");
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "700px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <CardContent>
            <Grid2 sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => navigate("/documentation/orders")}
                variant="text"
                sx={{ color: "#fff" }}
              >
                {" "}
                <CloseIcon />
              </Button>
            </Grid2>
            <Typography variant="h3" textAlign="center" mt={5} mb={7}>
              Uzadılma əmri
            </Typography>
            <Divider my={6} />
            <Grid2 sx={{ display: "flex", gap: "25px" }} mb={4}>
              <Grid2 sx={{ width: "50%" }}>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <Autocomplete
                    fullWidth
                    options={data || []}
                    getOptionLabel={(option) =>
                      `${option?.firstname} ${option?.lastname} ${option?.fathername}` ||
                      ""
                    }
                    onChange={(event, newValue) => {
                      handleEmployeeChange(newValue?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Əməkdaş(A.S.A)"
                        variant="outlined"
                      />
                    )}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.id}>
                        {`${option?.firstname} ${option?.lastname} ${option?.fathername}`}
                      </MenuItem>
                    )}
                  />
                </FormControl>
                <Grid2
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "25px",
                    marginBottom: "16px",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="contract_number">
                      Müqavilə nömrəsi
                    </InputLabel>
                    <Select
                      name="contract_number"
                      labelId="Müqavilə nömrəsi"
                      label="Müqavilə nömrəsi"
                      fullWidth
                      variant="outlined"
                      onChange={(e) => handleCurrentContract(e.target.value)}
                      disabled={!employee.employeeId}
                    >
                      {contractData &&
                        contractData?.map((d, i) => (
                          <MenuItem
                            key={i}
                            value={d?.contract?.staffs?.[0]?._id}
                          >
                            {d?.contract?.data?.docNumber}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <DatePicker
                    my={2}
                    sx={{ width: "100%", pointerEvents: "none" }}
                    inputFormat="dd/MM/yyyy"
                    name="contractDate"
                    value={
                      currentContractData?.contract?.decisionDate
                        ? new Date(currentContractData?.contract?.decisionDate)
                        : null
                    }
                    label="Müqavilənin tarixi"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Grid2>
                <TextField
                  name="Tabel nömrəsi"
                  label="Tabel nömrəsi"
                  value={employee?.tabelNumber}
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  variant="outlined"
                  my={2}
                />
                <TextField
                  name="Təşkilat"
                  label="Təşkilat"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  my={2}
                  value={
                    currentContractData?.structure?.organization?.name || ""
                  }
                />
                <TextField
                  name="Struktur"
                  label="Struktur"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  my={2}
                  value={
                    currentContractData?.contract?.topPartOfStructures?.[0]
                      ?.source?.name || ""
                  }
                />
                <TextField
                  name="Substruktur"
                  label="Substruktur"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  my={2}
                  value={currentContractData?.part?.parent?.source?.name || ""}
                />
                <TextField
                  name="Vəzifə"
                  label="Vəzifə"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  my={2}
                  value={currentContractData?.part?.source.name || ""}
                />
              </Grid2>
              <Grid2 sx={{ width: "50%" }}>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel id="organization"> Uzadılma növü</InputLabel>
                  <Select
                    name="organization"
                    labelId="Uzadılma növü"
                    label="Uzadılma növü"
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="ssds">Məzuniyyətdən geri çağırma</MenuItem>
                    <MenuItem value="ssds">Ezamiyyədən geri çağırma</MenuItem>
                  </Select>
                </FormControl>
                <Grid2
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "25px",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <TextField
                    sx={{ width: "50%" }}
                    name="personalCategory"
                    label="Əmrin nömrəsi"
                    variant="outlined"
                    my={2}
                  />
                  <DatePicker
                    sx={{ width: "50%" }}
                    inputFormat="dd/MM/yyyy"
                    name="endWorkYear"
                    value={emptyPositionDatas?.endWorkYear}
                    onChange={(date) => handleInputChange("endWorkYear", date)}
                    label="Əmrin imzalanma tarixi"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Grid2>
                <Grid2
                  sx={{
                    display: "flex",
                    gap: "25px",
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <DatePicker
                    my={2}
                    sx={{ width: "50%" }}
                    inputFormat="dd/MM/yyyy"
                    name="startWorkYear"
                    value={emptyPositionDatas?.startWorkYear}
                    label=" Mez/Ez başlama Tarixi "
                    onChange={(date) =>
                      handleInputChange("startWorkYear", date)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                  <DatePicker
                    sx={{ width: "50%" }}
                    inputFormat="dd/MM/yyyy"
                    name="endWorkYear"
                    value={emptyPositionDatas?.endWorkYear}
                    onChange={(date) => handleInputChange("endWorkYear", date)}
                    label="Bitmə tarixi"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Grid2>
                <Grid2
                  sx={{
                    display: "flex",
                    gap: "25px",
                    width: "100%",
                    marginBottom: "16px",
                  }}
                >
                  <DatePicker
                    my={2}
                    sx={{ width: "50%", pointerEvents: "none" }}
                    inputFormat="dd/MM/yyyy"
                    name="startWorkYear"
                    value={emptyPositionDatas?.startWorkYear}
                    label="İşə çıxma tarixi"
                    onChange={(date) =>
                      handleInputChange("startWorkYear", date)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                  <DatePicker
                    sx={{ width: "50%" }}
                    inputFormat="dd/MM/yyyy"
                    name="endWorkYear"
                    value={emptyPositionDatas?.endWorkYear}
                    onChange={(date) => handleInputChange("endWorkYear", date)}
                    label="Yeni işə çıxma tarixi"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Grid2>
                <TextField
                  sx={{ width: "100%", pointerEvents: "none" }}
                  name="newContractNumber"
                  label="Gün"
                  variant="outlined"
                  my={2}
                />
              </Grid2>
            </Grid2>
            <Grid2 mb={10}>
              <TextField
                fullWidth
                name="positionLevel"
                label="Uzadılmanın səbəbi"
                variant="outlined"
                my={2}
                inputProps={{
                  maxLength: 500,
                }}
              />
            </Grid2>
            <Divider my={6} />
            <Grid2
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "25px",
              }}
            >
              <Button
                title="İmtina et"
                variant="contained"
                sx={{ height: "100%" }}
                color="error"
                onClick={() => navigate("/documentation/orders")}
              >
                <p
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Geri
                </p>
              </Button>
              <Button
                title="Kənarlaşdırma yarat"
                variant="contained"
                sx={{ height: "100%" }}
              >
                <p
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Təsdiq et
                </p>
              </Button>
            </Grid2>
          </CardContent>
        )}
      </Card>
    </form>
  );
};

export default ExtensionOrder;
