import React from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Field } from "formik";

const InformationAboutParties = ({
  setFieldValue,
  selectedValue,
  values,
  errors,
}) => {
  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>Əmək müqaviləsi bağlayan tərəflər haqqında məlumat</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>İş yeri əsas və ya əlavə</InputLabel>
                <Field
                  as={Select}
                  label="İş yeri əsas və ya əlavə"
                  defaultValue=""
                  fullWidth
                  name="informationAboutParties.empWorkPlaceType"
                  onChange={(event) => {
                    setFieldValue(
                      "informationAboutParties.empWorkPlaceType",
                      event.target.value
                    );
                  }}
                >
                  {selectedValue?.empWorkPlaceType &&
                    selectedValue?.empWorkPlaceType.map((item, index) => {
                      return (
                        <MenuItem
                          key={`${item?.value}${index}`}
                          value={item?.value}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                </Field>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </CustomAccordion>
    </>
  );
};

export default InformationAboutParties;
