import React, { useEffect, useRef, useState } from "react";
import {
  BUSINESS_TRIP_MODELS,
  COLORS_STATUS,
  CONT_UPDATE_STATUS,
  DISSMISAL,
  EMPLOYEES_STATUS,
  GENERAL_DOCUMENTS,
  OVERTIME_STATUS,
  STAFF_STATUS,
  STRUCTURE_CHGANGE_REQUEST,
  SYSTEM_CONFIRMATION_STATUS,
} from "../../constants";

export default function StatusGenerator({
  data,
  approvement,
  value,
  docType,
  isVerified = undefined,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const contRef = useRef(null);
  const [doc, setDoc] = useState({});

  useEffect(() => {
    if (docType === "systemConfirmation") {
      setDoc(SYSTEM_CONFIRMATION_STATUS);
    } else if (docType === "dissmisal") {
      setDoc(DISSMISAL);
    } else if (docType === "generalDocuments") {
      setDoc(GENERAL_DOCUMENTS);
    } else if (docType === "structureChangeRequest") {
      setDoc(STRUCTURE_CHGANGE_REQUEST);
    } else if (docType === "overtime") {
      setDoc(OVERTIME_STATUS);
    } else if (docType === "staffStatus") {
      setDoc(STAFF_STATUS(isVerified));
    } else if (docType === "employeeStatus") {
      setDoc(EMPLOYEES_STATUS);
    } else if (docType === "contUpdate") {
      setDoc(CONT_UPDATE_STATUS);
    } else if (docType === "businessTrip") {
      setDoc(BUSINESS_TRIP_MODELS);
    }
  }, [docType]);

  function statusConverter(x) {
    switch (x) {
      case "rejected":
        return "İmtina edilmişdir";
      case "approved":
        return "Təsdiqlənmişdir";
      case "pending":
        return "Gözlənilir";
      case "notSigned":
        return "İmzalanmamış";
      case "concluded":
        return "Bitmiş";
      case "ongoing":
        return "Davam Edən";
      case "pendingUpdate":
        return "Təsdiqlənmişdir";
      case "draft":
        return "Ə.M təsdiq gözlənilir";
      default:
        return "";
    }
  }

  const handleDivClick = (e) => {
    e.preventDefault();
    setShowTooltip(!showTooltip);
  };

  const handleOutsideClick = (event) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      !contRef.current.contains(event.target)
    ) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showTooltip]);

  const renderChain = () => {
    return approvement?.map((item, index) => {
      const name = `${item.index}. ${item.employee} -${statusConverter(
        item.status
      )}`;
      return (
        <div
          key={`${item} ${index}`}
          style={
            item.status === "pending"
              ? {
                  backgroundColor: COLORS_STATUS.BLUE,
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  padding: "5px",
                }
              : item.status === "approved" ||
                item.status === "completed" ||
                item.status === "active" ||
                item.status === "pendingUpdate"
              ? {
                  backgroundColor: COLORS_STATUS.GREEN,
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  padding: "5px",
                }
              : {
                  backgroundColor: COLORS_STATUS.RED,
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  padding: "5px",
                }
          }
        >
          {name}
        </div>
      );
    });
  };
  return (
    <>
      <div
        style={{
          width: "90%",
          height: "90%",
          margin: "auto",
          backgroundColor: doc[value] && doc[value].color,
          textAlign: "center",
          position: "relative",
          borderRadius: "5px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={handleDivClick}
        ref={contRef}
      >
        {/* special circle */}
        {/* {value === "pendingUpdate" && (
          <div
            style={{
              width: "7px",
              height: "7px",
              borderRadius: "50%",
              position: "absolute",
              top: -3,
              right: -3,
              zIndex: 2,
              backgroundColor: "cornsilk",
              border: "1px solid white",
            }}
          />
        )} */}

        <span
          style={{
            display: "inline-block",
            textOverflow: "ellipsis",
            width: "80%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            // eslint-disable-next-line no-dupe-keys
            textOverflow: "ellipsis",
          }}
        >
          {doc[value] && doc[value].shortName}
        </span>
        {data?.updateStatus === "pending" && (
          <div
            style={{
              backgroundColor: "#f7980b",
              width: "5px",
              height: "5px",
              borderRadius: "60%",
            }}
          ></div>
        )}
        <div
          style={{
            position: "absolute",
            transform: "translate(-80%, 5%)",
            padding: "5px",
            borderRadius: "5px",
            display: showTooltip ? "block" : "none",
            zIndex: 999,
            backgroundColor: "#171e45",
            border: "0.5px solid #777",
          }}
          ref={tooltipRef}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "5px",
              whiteSpace: "nowrap",
            }}
          >
            {doc[value] && doc[value].longName}

            {renderChain()}
          </div>
        </div>
      </div>
    </>
  );
}
