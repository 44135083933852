import {
  Autocomplete,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { removeEmptyStrings } from "../../utils/removeEmptyString";
import { getActiveStaffss } from "../../services/staff/StaffServices";

const FindEmployee = (props) => {
  const {
    findEmployeeDialog,
    setFindEmployeeDialog,
    selectedUser,
    setSelectedUser,
    structureId,
  } = props;

  const [employees, setEmployees] = useState([]);
  const [user, setUser] = useState();
  const [totalEmployee, setTotalEmployee] = useState();
  const [employeesPayload, setEmployeesPayload] = useState({
    limit: 5,
    skip: 0,
    filter: {
      employeeFirstName: "",
      employeeLastName: "",
      employeeFatherName: "",
    },
  });
  const [page, setPage] = useState(1);
  useEffect(() => {
    setEmployeesPayload({
      ...employeesPayload,
      structureId,
    });
  }, [structureId]);

  const fetchEmployees = async (payload) => {
    try {
      const { data } = await getActiveStaffss(payload);
      if (data) {
        setEmployees(data?.records);
        setTotalEmployee(data.total);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchFilteredEmployees = async () => {
      const { limit, skip, filter, structureId } = employeesPayload;

      const datas = Object.entries(filter)
        .filter(([key, value]) => value.trim() !== "")
        .map(([key, value]) => ({
          column: key,
          operator: "equalsTo",
          value: value,
        }));

      const payload = { limit, offset: skip, structureId };
      if (datas.length > 0) {
        const filterString = JSON.stringify(datas);
        const encodedFilter = encodeURIComponent(filterString);
        payload.filter = encodedFilter;
      }
      if (structureId) {
        await fetchEmployees(payload);
      }
    };

    fetchFilteredEmployees();
  }, [employeesPayload]);

  const handleClose = () => {
    setFindEmployeeDialog(false);
    setUser();
    setSelectedUser();
  };
  const handleSelectEmp = () => {
    setFindEmployeeDialog(false);
    setSelectedUser(user);
    setUser();
  };
  const handleCancel = () => {
    setFindEmployeeDialog(false);
    setSelectedUser();
    setUser();
    setEmployeesPayload({
      limit: 5,
      skip: 0,
      filter: {
        employeeFirstName: "",
        employeeLastName: "",
        employeeFatherName: "",
      },
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        open={findEmployeeDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" mb={-3}>
          <Typography variant="h4" mb={4}>
            Əməkdaşı seçin
          </Typography>
        </DialogTitle>
        <>
          <DialogContent>
            <Autocomplete
              fullWidth
              sx={{ marginTop: "10px" }}
              options={employees?.flatMap((item) => item.employee) || []}
              getOptionLabel={(option) =>
                `${option.firstname} ${option.lastname} ${option.fathername}${
                  option?.position?.name ? ` (${option.position.name})` : ""
                }`
              }
              onChange={(event, newValue) => {
                setUser(
                  newValue
                    ? employees.find(
                        (item) => item.employee[0]._id === newValue._id
                      )
                    : null
                );
              }}
              renderOption={(props, option) => (
                <ListItem {...props} key={option._id}>
                  <ListItemAvatar>
                    <Avatar
                      src={`/static/img/avatars/user.png`}
                      alt={option.firstname}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${option.firstname} ${option.lastname} ${option.fathername}`}
                    secondary={option?.position?.name}
                  />
                </ListItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="İstifadəçi axtar"
                  variant="outlined"
                />
              )}
            />

            {/* <Pagination
              mb={2}
              sx={{ display: "flex", justifyContent: "center" }}
              count={Math.ceil(totalEmployee / employeesPayload.limit)}
              variant="outlined"
              shape="rounded"
              page={page}
              color="secondary"
              onChange={(event, value) => {
                setPage(value);
                if (value !== page) {
                  setEmployeesPayload((prevState) => ({
                    ...prevState,
                    skip: (value - 1) * prevState.limit,
                  }));
                }
              }}
            /> */}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Ləğv et
            </Button>
            <Button
              color="primary"
              onClick={handleSelectEmp}
              disabled={!user?._id}
            >
              Seç
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default FindEmployee;
