import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import Header from "../../components/layouts/header/Header";
import SalaryTable from "../../components/table/SalaryTabel";
import { useEffect, useMemo, useState } from "react";
import Column from "../../models/table/Column";
import { useTheme } from "@emotion/react";
import { OPERATORS_CONTAINING } from "../../constants";
import {
  getDownloadSalaries,
  getSalaryHistory,
  postSalaryCalculation,
} from "./services";
import { downloadFile } from "../../utils/downloadCSV";

import { getAllStructures } from "../../api/api";
import SelectInput from "../../components/ui/Select/SelectInput";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { width } from "@mui/system";
import { isArray } from "lodash";

const header = {
  title: "Əməkhaqqının hesablanması",
  variant: "h1",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const cellStyle = {
  textAlign: "center",
};

export default function SalaryCalculation() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalSalary, setTotalSalary] = useState("");
  const [structures, setStructure] = useState([]);
  const [currStructure, setCurrStructure] = useState("");
  const [currStructureID, setCurrStructureID] = useState("");
  const [date, setDate] = useState("");
  const [dataForStructure, setDataForStructure] = useState(false);
  useEffect(() => {
    paginationFetchingHandler();
  }, [limit, offset, filters]);
  useEffect(() => {
    getStructures();
  }, []);

  // kesr hisseni 2 reqeme salmaq ucun
  function formatToTwoDecimalPlaces(number) {
    if (Number(number)) {
      return Math.floor(number * 100) / 100;
    } else {
      return "0";
    }
  }
  useEffect(() => {
    const columns_ = [
      new Column({
        path: "employee.lastname",
        headerName: "Soyad",
        groupHeader: null,
        filterType: OPERATORS_CONTAINING.TEXT,
      }),
      new Column({
        path: "employee.firstname",
        headerName: "Ad",
        groupHeader: null,
        filterType: OPERATORS_CONTAINING.TEXT,
      }),
      new Column({
        path: "employee.fathername",
        headerName: "Ata adı",
        groupHeader: null,
        filterType: OPERATORS_CONTAINING.TEXT,
      }),
      new Column({
        path: "employee.pin",
        headerName: "Fin",
        groupHeader: null,
        filterType: OPERATORS_CONTAINING.TEXT,
      }),
      new Column({
        path: "structure.organization.name",
        headerName: "Struktur",
        groupHeader: null,
        filterType: OPERATORS_CONTAINING.TEXT,
      }),
      new Column({
        path: "totalWorkDays",
        headerName: "İş günlərinin sayı",
        filterType: OPERATORS_CONTAINING.NUMBER,
      }),
      new Column({
        path: "workDays",
        headerName: "İşlədiyi günlərin sayı",
        filterType: OPERATORS_CONTAINING.NUMBER,
      }),
      new Column({
        path: "nonWorkDays",
        headerName: "İşləmədiyi günlərin sayı",
        filterType: OPERATORS_CONTAINING.NUMBER,
      }),
      // new Column({
      //   path: "employee.position",
      //   headerName: "Vəzifə",
      // }),

      new Column({
        path: "salary",
        headerName: "Əməkhaqqı",
        filterType: OPERATORS_CONTAINING.NUMBER,
        groupHeader: "Hesablanıb",
        headerColor: "#1db8d5",

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>{formatToTwoDecimalPlaces(row.salary)}</div>
          );
        },
      }),

      new Column({
        path: "overtimeAdditions",
        headerName: "İş vaxtından artıq işlənmiş saatlar",
        groupHeader: "Hesablanıb",
        headerColor: "#1db8d5",
        filterType: OPERATORS_CONTAINING.NUMBER,
      }),
      new Column({
        path: "vacationAmount",
        headerName: "Məzuniyyət",
        groupHeader: "Hesablanıb",
        headerColor: "#1db8d5",
        filterType: OPERATORS_CONTAINING.NUMBER,
      }),
      new Column({
        path: "vacataionAdditionToSalary",
        headerName: "Məzuniyyətə Ə/H əlavə",
        groupHeader: "Hesablanıb",
        headerColor: "#1db8d5",
        filterType: OPERATORS_CONTAINING.NUMBER,
      }),
      new Column({
        path: "totalWithSupplumental",
        headerName: "Cəm hesablanıb",
        groupHeader: "Hesablanıb",
        headerColor: "#1db8d5",
        filterType: OPERATORS_CONTAINING.NUMBER,

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.totalBeforeTax)}
            </div>
          );
        },
      }),
      new Column({
        path: "additionalInsuranceAmount",
        headerName: "Paşa Həyat Siğorta",
        filterType: OPERATORS_CONTAINING.NUMBER,
        groupHeader: null,

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.additionalInsuranceAmount)}
            </div>
          );
        },
      }),
      new Column({
        path: "totalWithAdditionInsurance",
        headerName: "Cəm hesablanıb",
        filterType: OPERATORS_CONTAINING.NUMBER,

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.totalWithAdditionInsurance)}
            </div>
          );
        },
      }),
      new Column({
        path: "incomeTax",
        headerName: "Gəlir vergisi",
        filterType: OPERATORS_CONTAINING.NUMBER,
        headerColor: "#db2b35",

        groupHeader: "Tutulub",
        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.incomeTax)}
            </div>
          );
        },
      }),
      new Column({
        path: "pensionFund",
        headerName: "Pensiya Fondu 10 %",
        filterType: OPERATORS_CONTAINING.NUMBER,
        headerColor: "#db2b35",

        groupHeader: "Tutulub",
        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.pensionFund)}
            </div>
          );
        },
      }),
      new Column({
        path: "unemploymentInsurance",
        headerName: "İşsizlik siğortası 0.5 %",
        filterType: OPERATORS_CONTAINING.NUMBER,
        headerColor: "#db2b35",

        groupHeader: "Tutulub",
        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.unemploymentInsurance)}
            </div>
          );
        },
      }),
      new Column({
        path: "medicalInsurance",
        headerName: "İTS 2 %",
        filterType: OPERATORS_CONTAINING.NUMBER,
        headerColor: "#db2b35",

        groupHeader: "Tutulub",
        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.medicalInsurance)}
            </div>
          );
        },
      }),
      new Column({
        path: "empDeductionWage",
        headerName: "Avans/Tutulmalar",
        filterType: OPERATORS_CONTAINING.NUMBER,
        headerColor: "#db2b35",

        groupHeader: "Tutulub",
        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {row.empDeductionWage < 0
                ? `(${Math.abs(
                    formatToTwoDecimalPlaces(row.empDeductionWage)
                  )})`
                : formatToTwoDecimalPlaces(row.empDeductionWage)}
            </div>
          );
        },
      }),
      new Column({
        path: "allDeduction",
        headerName: "Cəmi tutulmuşdur",
        filterType: OPERATORS_CONTAINING.NUMBER,
        groupHeader: "Tutulub",
        headerColor: "#db2b35",

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.allDeduction)}
            </div>
          );
        },
      }),
      new Column({
        path: "nettSalary",
        headerName: "Ödənilməli məbləğ",
        filterType: OPERATORS_CONTAINING.NUMBER,

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.nettSalary)}
            </div>
          );
        },
      }),
      new Column({
        path: "sosialInsurance",
        headerName: "İşəgötürənin hesabına ödənilən Sosial Sığorta haqqı 15%",
        filterType: OPERATORS_CONTAINING.NUMBER,
        groupHeader: null,

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.sosialInsurance)}
            </div>
          );
        },
      }),
      new Column({
        path: "pashaLifeTax",
        headerName: "Paşa Həyat 15%",
        filterType: OPERATORS_CONTAINING.NUMBER,
        groupHeader: null,

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.pashaLifeTax)}
            </div>
          );
        },
      }),
      new Column({
        path: "unemploymentInsurance",
        headerName: "İşsizlik siğortası işəgötürən hesabına 0.5 %",
        filterType: OPERATORS_CONTAINING.TEXT,

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.unemploymentInsurance)}
            </div>
          );
        },
      }),
      new Column({
        path: "medicalInsurance",
        headerName: "İTS 2 %",
        filterType: OPERATORS_CONTAINING.TEXt,

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.medicalInsurance)}
            </div>
          );
        },
      }),
    ];

    // MonthlySupplementalWages columns create part(birdefelik elaveler)
    const uniqueMonthlySupplementalWagesNames = [
      ...new Set(
        data?.flatMap((item, itemIndex) =>
          item.monthlySupplementalWages.map((wage, wageIndex) => ({
            name: wage.name,
            dataIndex: itemIndex,
            wageIndex: wageIndex,
          }))
        )
      ),
    ];
    const uniqueMonthlySupplementalWagesNameObjects = [
      ...uniqueMonthlySupplementalWagesNames
        .reduce((map, obj) => {
          if (!map.has(obj.name)) {
            map.set(obj.name, obj);
          }
          return map;
        }, new Map())
        .values(),
    ];
    const dynamicMonthlySupplementalWagesColumns =
      uniqueMonthlySupplementalWagesNameObjects.map((wage) => {
        return new Column({
          path: `monthlySupplementalWages.[${wage.name}].value`,
          headerName: wage.name,
          groupHeader: "Birdəfəlik əlavələr",
          headerColor: "#6c8139",
          colSpan: uniqueMonthlySupplementalWagesNameObjects.length,
          filterType: OPERATORS_CONTAINING.NUMBER,
          renderCell: (row, column) => {
            return (
              <div style={cellStyle}>
                {formatToTwoDecimalPlaces(
                  row.monthlySupplementalWages[wage.wageIndex]?.value
                )}
              </div>
            );
          },
        });
      });

    // SupplementalWages columns create part(daimi elaveler)

    const uniqueSupplementalWagesNames = [
      ...new Set(
        data?.flatMap((item, itemIndex) =>
          item.supplementalWages.map((wage, wageIndex) => ({
            name: wage.name,
            dataIndex: itemIndex,
            wageIndex: wageIndex,
          }))
        )
      ),
    ];

    const uniqueSupplementalWagesNameObjects = [
      ...uniqueSupplementalWagesNames
        .reduce((map, obj) => {
          if (!map.has(obj.name)) {
            map.set(obj.name, obj);
          }
          return map;
        }, new Map())
        .values(),
    ];

    const dynamicSupplementalWagesColumns =
      uniqueSupplementalWagesNameObjects.map((wage, index) => {
        return new Column({
          path: `supplementalWages.[${wage.name}].value`,
          headerName: wage.name,
          groupHeader: "Daimi əlavələr",
          headerColor: "#6c8139",
          filterType: OPERATORS_CONTAINING.NUMBER,

          colSpan: uniqueSupplementalWagesNameObjects.length,

          filterType: OPERATORS_CONTAINING.NUMBER,
          renderCell: (row, column) => {
            return (
              <div style={cellStyle}>
                {formatToTwoDecimalPlaces(
                  row.supplementalWages[wage.wageIndex]?.value
                )}
              </div>
            );
          },
        });
      });

    // monthlyDeductionWages columns create part (birdefelik tutulmalar)
    const uniqueMonthlyDeductionWagesNames = [
      ...new Set(
        data?.flatMap((item, itemIndex) =>
          item.monthlyDeductionWages.map((wage, wageIndex) => ({
            name: wage.name,
            dataIndex: itemIndex,
            wageIndex: wageIndex,
          }))
        )
      ),
    ];

    const uniqueMonthlyDeductionWagesNameObjects = [
      ...uniqueMonthlyDeductionWagesNames
        .reduce((map, obj) => {
          if (!map.has(obj.name)) {
            map.set(obj.name, obj);
          }
          return map;
        }, new Map())
        .values(),
    ];

    const dynamicMonthlyDeductionColumns =
      uniqueMonthlyDeductionWagesNameObjects.map((wage) => {
        return new Column({
          path: `monthlyDeductionWages.[${wage.name}].value`,
          headerName: wage.name,
          filterType: OPERATORS_CONTAINING.NUMBER,
          groupHeader: "Birdəfəlik tutulmalar",
          headerColor: "#ee253e",
          filterType: OPERATORS_CONTAINING.NUMBER,

          renderCell: (row, column) => {
            return (
              <div style={cellStyle}>
                {formatToTwoDecimalPlaces(
                  row.monthlyDeductionWages[wage.wageIndex]?.value
                )}
              </div>
            );
          },
        });
      });
    // deductionWages columns create part (daimi tutulma)
    const uniqueDeductionWagesNames = [
      ...new Set(
        data?.flatMap((item, itemIndex) =>
          item.deductionWages.map((wage, wageIndex) => ({
            name: wage.name,
            dataIndex: itemIndex,
            wageIndex: wageIndex,
          }))
        )
      ),
    ];

    const uniqueDeductionWagesNameObjects = [
      ...uniqueDeductionWagesNames
        .reduce((map, obj) => {
          if (!map.has(obj.name)) {
            map.set(obj.name, obj);
          }
          return map;
        }, new Map())
        .values(),
    ];

    const dynamicDeductionWagesColumns = uniqueDeductionWagesNameObjects.map(
      (wage) => {
        return new Column({
          path: `deductionWages.[${wage.name}].value`,
          headerName: wage.name,
          filterType: OPERATORS_CONTAINING.NUMBER,
          groupHeader: "Daimi tutulmalar",
          headerColor: "#ee253e",
          filterType: OPERATORS_CONTAINING.NUMBER,

          renderCell: (row, column) => {
            return (
              <div style={cellStyle}>
                {formatToTwoDecimalPlaces(
                  row.deductionWages[wage.wageIndex]?.value
                )}
              </div>
            );
          },
        });
      }
    );

    // columns_?.push(
    //   ...dynamicMonthlySupplementalWagesColumns,
    //   ...dynamicSupplementalWagesColumns,

    //   ...dynamicMonthlyDeductionColumns,
    //   ...dynamicDeductionWagesColumns
    // );
    setColumns([...columns_]);
  }, [data]);

  const paginationFetchingHandler = async () => {
    if (filters.length) {
      await getAllSalaryHistory([...filters]);
    } else {
      await getAllSalaryHistory();
    }
  };
  const getAllSalaryHistory = async (filters = undefined) => {
    let month, year;
    if (date) {
      const newDate = new Date(date);
      month = newDate?.getMonth();
      year = newDate?.getFullYear();
    }
    try {
      setIsLoading(true);

      if (filters) {
        const resp = await getSalaryHistory({
          offset: offset || 0,
          limit: limit || 10,
          month: date ? month : "",
          year: date ? year : "",
          structureId: currStructureID ? currStructureID : "",
          filters: encodeURIComponent(
            JSON.stringify([
              ...filters,
              {
                column: "structure",
                operator: "equalsTo",
                value: dataForStructure ? currStructureID : "",
              },
            ])
          ),
        });
        setTotalSalary(resp.data.totalSalary);
        setData([...resp.data.staffWages]);
        setTotal(resp.data.total);
      } else {
        const resp = await getSalaryHistory({
          offset: offset || 0,
          limit: limit || 10,
          month: date ? month : "",
          year: date ? year : "",
          structureId: currStructureID ? currStructureID : "",
        });
        setTotalSalary(resp.data.totalSalary);

        setData([...resp.data.staffWages]);
        setTotal(resp.data.total);
      }
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setIsLoading(false);
    }
  };
  // show data with structure filter or date
  const showSalary = async (structure, month, year) => {
    getAllSalaryHistory();
  };

  // salary calculation
  const calculateSalary = async () => {
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    try {
      setIsLoading(true);
      const resp = await postSalaryCalculation(currStructureID, month, year);
      setData(resp.data.staffWages);
      setTotal(resp.data.length);
      setCurrStructureID("");
      setDataForStructure(true);
    } catch (err) {
      console.log("error: ", err);
      setDataForStructure(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStructureChange = (value) => {
    setCurrStructure(value);
    const selectedStructure = structures.find(
      (structure) => structure?.organization?.name === value
    );
    setCurrStructureID(selectedStructure._id);
  };
  const getStructures = async () => {
    const structures = await getAllStructures();
    setStructure(structures.data.structures);
  };

  // yukle

  const downloadHandler = async (columnsForExport) => {
    let month, year;
    if (date) {
      const newDate = new Date(date);
      month = newDate?.getMonth();
      year = newDate?.getFullYear();
    }

    try {
      const colsExp = [];

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        colsExp.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodeColumns = encodeURIComponent(JSON.stringify(colsExp));
      const encodeFilters = encodeURIComponent(JSON.stringify([...filters]));
      const resp = await getDownloadSalaries({
        columns: encodeColumns,
        filter: encodeFilters,
        month: date ? month : "",
        year: date ? year : "",
        structureId: currStructureID ? currStructureID : "",
      });
      if (resp) downloadFile(resp, `report-${month}-${year}.xlsx`);
    } catch (error) {
      console.log("err", error);
    }
  };

  // swipe datas
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const handleChangeTab = (e, value) => {
    setValue(value);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  return (
    <>
      <Grid sx={{ width: "100%", textAlign: "center", marginBottom: "50px" }}>
        <Header data={header} />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div className="d-flex" style={{ width: "300px", marginRight: "50px" }}>
          <SelectInput
            label={"Struktur: "}
            value={currStructure}
            onChange={handleStructureChange}
          >
            {structures.map((structure) => (
              <option
                key={structure?.organization?.name}
                value={structure?.organization?.name}
              >
                {structure?.organization?.name}
              </option>
            ))}
          </SelectInput>
        </div>
        <div style={{ marginRight: "50px" }}>
          <DatePicker
            label={'"ay" və "il"'}
            views={["year", "month"]}
            openTo="month"
            sx={{ width: "100%" }}
            maxDate={Date()}
            value={date ? new Date(date) : null}
            slotProps={{
              textField: {
                sx: {
                  "& .MuiInputBase-input": {
                    height: "8px",
                  },
                },
              },
            }}
            onChange={(date) => {
              if (date) {
                setDate(date);
              }
            }}
          />
        </div>
        <Button
          disabled={!currStructureID && !date}
          variant="contained"
          color="primary"
          onClick={showSalary}
        >
          Göstər
        </Button>
        <Button
          sx={{ marginLeft: "40px", marginRight: "40px" }}
          disabled={!currStructureID || !date}
          variant="contained"
          color="primary"
          onClick={() => {
            calculateSalary();
          }}
        >
          Hesabla
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/salaries/salarycalculation", { replace: true });
            navigate(0);
          }}
        >
          Struktur filterini sıfırla
        </Button>
      </Grid>

      {/* <Box>
        <Box>
          <Tabs value={value} onChange={handleChangeTab}>
            <Tab
              label="İşəgötürən tutulmalarına görə"
              value={0}
              {...a11yProps(0)}
            />
            <Tab label="Digər tutulmalara görə" value={1} {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box> */}

      {/* <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex} */}
      {/* > */}
      {/* <TabPanel value={value} index={0}> */}
      <Grid className="mt-4">
        {columns.length || filters.length ? (
          <>
            <SalaryTable
              columns={columns}
              data={data}
              totalRows={total}
              offset={offset}
              setOffset={(value) => setOffset(value)}
              limit={limit}
              setLimit={(value) => setLimit(value)}
              isLoading={isLoading}
              applyFiltersHandler={(filters) => setFilters(filters)}
              cancelAppliedFilters={() => setFilters([])}
              downloadHandler={(cols) => downloadHandler(cols)}
            />
            {totalSalary && (
              <Grid
                sx={{
                  width: "100%",
                  marginTop: "50px",
                  textAlign: "end",
                  fontSize: "20px",
                }}
              >
                Total: {formatToTwoDecimalPlaces(totalSalary)}
              </Grid>
            )}
          </>
        ) : (
          <></>
        )}
      </Grid>
      {/* </TabPanel> */}

      {/* <TabPanel value={value} index={1}></TabPanel>
      </SwipeableViews> */}
    </>
  );
}
