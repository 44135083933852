import { toast } from "react-toastify";
import { api } from "../../api/axiosInterceptor";

// salary calculation part-----------------------------------------------------

export const getSalaryHistory = async (payload) => {
  let params = {
    offset: payload?.offset,
    limit: payload?.limit,
    filter: payload?.filters,
    month: payload.month,
    year: payload.year,
    structureId: payload.structureId,
  };

  try {
    const response = await api.get("employee/wages-calc/history", {
      params: params,
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const postSalaryCalculation = async (id, month, year) => {
  try {
    return await api.post(`/employee/wages-calc/`, {
      structure: id,
      month: `${month}`,
      year: `${year}`,
    });
  } catch (err) {
    console.log("postsalarycalculation", err);
  }
};

// download excell

export const getDownloadSalaries = async ({
  columns,
  filter = undefined,
  month,
  year,
  structureId,
}) => {
  const url = `employee/wages-calc/export?filters=${filter}&columns=${columns}&month=${month}&year=${year}&structureId=${structureId}`;

  return await api.get(url, { responseType: "blob" });
};

// deduction wages------------------------------------------------------

export const getPermanentDeductions = async (payload) => {
  let params = {
    offset: payload?.offset,
    limit: payload?.limit,
    filter: payload?.filters,
  };

  try {
    const response = await api.get("/employee/wage-deduction", {
      params: params,
    });
    return response;
  } catch (err) {
    console.log(err);
    // err.response.data.errors.map((item) => {
    //   return toast.error(item.message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // });
  }
};

export const getTemporaryDeductions = async (payload) => {
  let params = {
    offset: payload?.offset,
    limit: payload?.limit,
    filter: payload?.filters,
  };

  try {
    const response = await api.get("/employee/wage-deduction", {
      params: params,
    });
    return response;
  } catch (err) {
    console.log(err);
    // err.response.data.errors.map((item) => {
    //   return toast.error(item.message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // });
  }
};

export const postDeductionWage = async (body, id) => {
  try {
    const response = await api.post(`employee/wage-deduction/`, {
      clause: {
        id,
        name: body.article,
      },
      staffId: body.staffId,
      type: body.deductionType,
      data: {
        id: body.deductionId,
        name: body.deductionName,
        value: body.value,
        amountType: body.amountType,
      },

      startDate: body.startDate,
      endDate: body.endDate,
      note: body.note,
    });
    toast.success("Uğurla tutulma yaradıldı!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteDeductionData = async (id) => {
  try {
    const response = await api.delete(`employee/wage-deduction/${id}`);
    toast.success("Uğurla silindi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const createOrderForDeduction = async (id) => {
  try {
    const response = await api.post(
      `employee/wage-deduction/create-contract/${id}`
    );
    toast.success("Uğurla əmr yaradıldı!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getDeductionData = (id) => {
  return api.get(`employee/wage-deduction/${id}`);
};

export const editDeductionData = async (data) => {
  console.log(data);
  try {
    const response = await api.put(`employee/wage-deduction/${data.id}`, {
      ...data,
    });
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// supplement wages------------------------------------------------------

export const getPermanentSupplement = async (payload) => {
  let params = {
    offset: payload?.offset,
    limit: payload?.limit,
    filter: payload?.filters,
  };

  try {
    const response = await api.get("/employee/wage-supplement", {
      params: params,
    });
    return response;
  } catch (err) {
    console.log(err);
    // err.response.data.errors.map((item) => {
    //   return toast.error(item.message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // });
  }
};

export const getTemporarySupplement = async (payload) => {
  let params = {
    offset: payload?.offset,
    limit: payload?.limit,
    filter: payload?.filters,
  };

  try {
    const response = await api.get("/employee/wage-supplement", {
      params: params,
    });
    return response;
  } catch (err) {
    console.log(err);
    // err.response.data.errors.map((item) => {
    //   return toast.error(item.message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // });
  }
};

export const postAdditionsWage = async (body, id) => {
  try {
    const response = await api.post(`employee/wage-supplement/`, {
      clause: {
        id,
        name: body.article,
      },
      staffId: body.staffId,
      type: body.supplementType,
      data: {
        id: body.supplementId,
        name: body.supplementName,
        value: body.value,
        amountType: body.amountType,
      },

      startDate: body.startDate,
      endDate: body.endDate,
      note: body.note,
    });
    toast.success("Uğurla əlavə yaradıldı!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteSupplementData = async (id) => {
  try {
    const response = await api.delete(`employee/wage-supplement/${id}`);
    toast.success("Uğurla silindi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const createOrderForSupplement = async (id) => {
  try {
    const response = await api.post(
      `employee/wage-supplement/create-contract/${id}`
    );
    toast.success("Uğurla əmr yaradıldı!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getSupplementData = (id) => {
  return api.get(`employee/wage-supplement/${id}`);
};

export const editSupplementData = async (data) => {
  try {
    const response = await api.put(`employee/wage-supplement/${data.id}`, {
      ...data,
    });
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// salarybase-----------------------------------------

export const getSalaryBaseHistory = async (payload) => {
  let params = {
    offset: payload?.offset,
    limit: payload?.limit,
    filter: payload?.filters,
    month: payload.month,
    year: payload.year,
    position: payload.positionId,
  };

  try {
    const response = await api.get("employee/wage-base", {
      params: params,
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const postAddBaseData = async (body, id) => {
  try {
    const response = await api.post(`employee/wage-base/createFromUI`, {
      ...body,
      staff: body.staffId,
    });
    toast.success("Uğurla əlavə edildi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getActiveStructuresEmptyStaff = async (payload) => {
  try {
    const response = await api.get("employee/s/staff?isVerified=true", {
      params: payload,
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error("Hazırda heç bir strukturda boş ştat yoxdur", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};
