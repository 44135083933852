import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import ruLocale from "date-fns/locale/ru";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useFormalizationShortenedWorkingHour from "../../../../../hooks/useFormalizationShortenedWorkingHour";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const Divider = styled(MuiDivider)(spacing);
const FormalizationShortenedWorkingHour = () => {
  const navigate = useNavigate();
  const {
    data,
    employee,
    contractData,
    handleFileChange,
    handleInputChange,
    currentContractData,
    handleEmployeeChange,
    formalizationData,
    handleCurrentContract,
    createFillingVacantPositionOrder,
  } = useFormalizationShortenedWorkingHour();

  return (
    <form>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        <CardContent>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            Qısaldılmış iş vaxtının rəsmiləşdirilməsi
          </Typography>
          <Divider my={6} />
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid sx={{ width: "50%" }}>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel>Əməkdaş(A.S.A)</InputLabel>
                <Select
                  onChange={(e) => handleEmployeeChange(e.target.value)}
                  name="employee"
                  value={employee.employeeId || ""}
                  fullWidth
                  label="Əməkdaş(A.S.A)"
                >
                  {data &&
                    data.map((d, i) => (
                      <MenuItem key={i} value={d?._id}>
                        {d?.firstname} {d?.lastname} {d?.fathername}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Grid sx={{ display: "flex", gap: "25px" }}>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel id="Müqavilə nömrəsi">
                    Müqavilə nömrəsi
                  </InputLabel>
                  <Select
                    name="Müqavilə nömrəsi"
                    label="Müqavilə nömrəsi"
                    fullWidth
                    variant="outlined"
                    value={
                      employee?.empStaffId?.value
                        ? employee?.empStaffId?.value || ""
                        : employee?.empStaffId || ""
                    }
                    onChange={(e) => handleCurrentContract(e.target.value)}
                    disabled={!employee.employeeId}
                  >
                    {contractData &&
                      contractData?.map((d, i) => (
                        <MenuItem key={i} value={d?.contract?.staffs?.[0]?._id}>
                          {d?.contract?.data?.docNumber}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="Müqavilənin tarixi"
                    value={currentContractData?.contract?.decisionDate || null}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
              </Grid>
              <TextField
                name="Tabel nömrəsi"
                label="Tabel nömrəsi"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                value={employee?.tabelNumber || ""}
                variant="outlined"
                my={2}
              />
              <TextField
                name="Təşkilat"
                label="Təşkilat"
                variant="outlined"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                my={2}
                value={currentContractData?.structure?.organization?.name || ""}
              />
              <TextField
                name="Substruktur"
                label="Substruktur"
                variant="outlined"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                my={2}
                value={
                  currentContractData?.contract?.topPartOfStructures?.[0]
                    ?.source?.name || ""
                }
              />
              <TextField
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                name="Substruktur"
                label="Substruktur"
                variant="outlined"
                my={2}
                value={currentContractData?.part?.parent?.source?.name || ""}
              />
              <TextField
                name="Vəzifə"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                label="Vəzifə"
                variant="outlined"
                my={2}
                value={currentContractData?.part?.source.name || ""}
              />
            </Grid>
            <Grid sx={{ width: "50%" }}>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel>İş saatları (Qrafik)</InputLabel>
                <Select
                  name="Əməkdaş(A.S.A)"
                  fullWidth
                  label="İş saatları (Qrafik)"
                >
                  <MenuItem value="sdsd">sdsdsd</MenuItem>
                </Select>
              </FormControl>
              <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
                <FormControl
                  sx={{ width: "50%" }}
                  //   error={!!errors.startDate}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ruLocale}
                  >
                    <TimePicker
                      label="İşin başlama saatı"
                      value={formalizationData?.workStartTime || null}
                      onChange={(newValue) => {
                        handleInputChange("workStartTime", newValue || "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} required />
                      )}
                    />
                  </LocalizationProvider>
                  {/* {errors.startDate && (
                      <FormHelperText>{errors.startDate}</FormHelperText>
                    )} */}
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ruLocale}
                  >
                    <TimePicker
                      label="İşin bitmə saatı"
                      value={formalizationData?.workEndTime || null}
                      onChange={(newValue) => {
                        handleInputChange("workEndTime", newValue || "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} required />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
                <FormControl sx={{ width: "50%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ruLocale}
                  >
                    <TimePicker
                      label="Naharın başlama saatı"
                      value={formalizationData?.lunchStartTime || null}
                      onChange={(newValue) => {
                        handleInputChange("lunchStartTime", newValue || "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} required />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ruLocale}
                  >
                    <TimePicker
                      label="Naharın bitmə saatı"
                      value={formalizationData?.lunchEndTime || null}
                      onChange={(newValue) => {
                        handleInputChange("lunchEndTime", newValue || "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} required />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel>Səbəb</InputLabel>
                <Select
                  onChange={(e) =>
                    handleInputChange("employeeCategory", e.target.value)
                  }
                  name="employeeCategory"
                  fullWidth
                  label="Səbəb"
                >
                  <MenuItem value="under_sixteen">
                    16 yaşınadək işçilər
                  </MenuItem>
                  <MenuItem value="sixteen_and_eighteen">
                    16 yaşdan 18 yaşadək işçilər
                  </MenuItem>
                  <MenuItem value="disability_groups">
                    1-ci, 2-ci qrup əlil olan işçilər
                  </MenuItem>
                  <MenuItem value="pregnant_or_with_child">
                    hamilə və yaşyarımadək uşağı olan qadınlar
                  </MenuItem>
                  <MenuItem value="single_parent">
                    3 yaşınadək uşağını təkbaşına böyüdən valideynlər
                  </MenuItem>
                  <MenuItem value="hazardous_work_conditions">
                    əmək şəraiti zərərli olan işlərdə çalışan işçilər
                  </MenuItem>
                  <MenuItem value="special_character_work">
                    xüsusi xarakterli işlərdə çalışan işçilər
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                name="note"
                label="Sənəd"
                fullWidth
                variant="outlined"
                my={2}
                type="file"
                slotProps={{
                  input: {
                    accept: "application/pdf,image/jpeg",
                  },
                }}
                onChange={(event) => {
                  const file = event.target.files[0];
                  const maxFileSize = 10 * 1024 * 1024;

                  if (file) {
                    if (file.size > maxFileSize) {
                      alert("Faylın ölçüsü 10 MB-dan çox olmamalıdır.");
                      event.target.value = null;
                    } else if (
                      !["application/pdf", "image/jpeg"].includes(file.type)
                    ) {
                      alert("Yalnız PDF və JPEG fayllarına icazə verilir.");
                      event.target.value = null;
                    }
                    if (
                      file.size < maxFileSize &&
                      ["application/pdf", "image/jpeg"].includes(file.type)
                    ) {
                      handleFileChange(file);
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
          <Divider my={6} />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => navigate("/documentation/orders")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              title="Kənarlaşdırma yarat"
              variant="contained"
              sx={{ height: "100%" }}
              onClick={createFillingVacantPositionOrder}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Təsdiq et
              </p>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default FormalizationShortenedWorkingHour;
