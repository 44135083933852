import React, { useEffect } from "react";
import { Router, useRoutes } from "react-router-dom";
import { Provider } from "react-redux";

import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store, persistor } from "./redux/root/store";
import { PersistGate } from "redux-persist/integration/react";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/JWTContext";
import { SocketProvider } from "./contexts/SocketContext";
import { SnackbarProvider } from "notistack";
import "./App.css";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Blink-Bi.az" defaultTitle="Blink-Bi.az" />
        <SnackbarProvider>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MuiThemeProvider theme={createTheme(theme)}>
                  <AuthProvider>
                    <SocketProvider>{content}</SocketProvider>
                  </AuthProvider>
                </MuiThemeProvider>
              </LocalizationProvider>
            </PersistGate>
          </Provider>
        </SnackbarProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
