import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { updateStructureNaming } from "../../../api/api";
import { postDesign } from "../../../api/tsDesignRequests";
import { GENERAL_BLOCK } from "../../../styled";
import { toast } from "react-toastify";

const StructureEditDialog = ({
  openStructureEdit,
  setOpenStructureEdit,
  dataTable,
  submitted,
  setSubmitted,
  structureId,
  setStructureId,
  designs,
  fetchDesigns,
}) => {
  //   update company by id
  const handleUpdateCompanyById = async (id, name, level) => {
    await updateStructureNaming(id, name, level);
    setSubmitted(!submitted);
  };

  const new_row = {
    label: "",
    style: {
      border: {
        width: "1",
        color: "#ffffff",
      },
      backgroundColor: "#000",
      font: {
        name: "Arial",
        size: "23",
        color: "#ffffff",
      },
    },
  };
  const [newName, setNewName] = useState();
  const [selected_structure_level, set_selected_structure_level] = useState();
  const [show_dialog_add, set_show_dialog_add] = useState(false);
  const [one_design, set_one_design] = useState(new_row);
  const [errorMessage, setErrorMessage] = useState("");
  function handleRowState(value, prop, i) {
    let deepCopy = JSON.parse(JSON.stringify(one_design));
    switch (prop) {
      case "label":
        deepCopy.label = value;
        break;
      case "width":
        deepCopy.style.border.width = value;
        break;
      case "color":
        deepCopy.style.border.color = value;
        break;
      case "backgroundColor":
        deepCopy.style.backgroundColor = value;
        break;

      case "fontName":
        deepCopy.style.font.name = value;
        break;

      case "fontSize":
        deepCopy.style.font.size = value;
        break;

      default:
        break;
    }
    set_one_design(deepCopy);
  }

  async function addRow() {
    let designs_ = JSON.parse(JSON.stringify(designs));
    one_design.style.border.width = one_design.style.border.width.toString();
    one_design.style.font.size = one_design.style.font.size.toString();
    designs_.push(one_design);
    // setDesigns(designs_);
    try {
      let res = await postDesign(one_design);
      if (res) {
        await fetchDesigns();
        set_one_design(new_row);
        set_show_dialog_add(false);
        toast.success("Uğurla dəyişdirildi!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
  }

  useEffect(() => {
    set_selected_structure_level(dataTable?.level?._id);
  }, [dataTable]);
  return (
    <>
      {structureId === dataTable._id ? (
        <Dialog
          fullWidth
          open={openStructureEdit}
          onClose={() => {
            setOpenStructureEdit(false);
            setStructureId();
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Məlumatları dəyişdir</DialogTitle>
          <DialogContent>
            <InputLabel id="demo-simple-select-label">Adı</InputLabel>
            <FormControl fullWidth>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                // label="Adı"
                type="text"
                placeholder={dataTable.name}
                defaultValue={dataTable.name}
                onChange={(e) => {
                  const value = e.target.value;
                  setNewName(value);
                  if (value?.length > 50) {
                    setErrorMessage("Maksimum 50 simvol daxil edilə bilər");
                  } else {
                    setErrorMessage("");
                  }
                }}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
                fullWidth
              />
            </FormControl>
            <InputLabel id="demo-simple-select-label" className="mt-2">
              Struktur səviyyəsi
            </InputLabel>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selected_structure_level ? selected_structure_level : ""}
                className="mt-2"
                // label="Struktur səviyyəsi"
                onChange={(e) => set_selected_structure_level(e.target.value)}
              >
                {designs &&
                  designs?.map((d) =>
                    d?.label ? (
                      <MenuItem value={d?._id}>{`${d?.label}`}</MenuItem>
                    ) : null
                  )}
              </Select>
            </FormControl>
            <div className="d-flex jce mt-2">
              <Button className="w-25" onClick={set_show_dialog_add}>
                Səviyyə əlavə et
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenStructureEdit(false);
                setStructureId();
              }}
              color="primary"
            >
              Ləğv et
            </Button>
            <Button
              color="primary"
              disabled={!newName || newName.length > 50}
              onClick={() => {
                handleUpdateCompanyById(
                  structureId,
                  newName,
                  selected_structure_level
                );
                setStructureId();
                setOpenStructureEdit(false);
              }}
            >
              Redaktə et
            </Button>
          </DialogActions>
          {/* LEVEL ADDING DIALOG */}
          <style>{`.add_state .MuiDialog-paperScrollPaper { width: 25% }`}</style>
          <Dialog
            open={show_dialog_add}
            className="add_state"
            onClose={() => {
              set_show_dialog_add(false);
              set_one_design(new_row);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Struktur səviyyəsini əlavə edin
            </DialogTitle>
            <DialogContent>
              <p className="mt-2 mb-1">
                <b>Səviyyə adı</b>
              </p>
              <TextField
                fullWidth
                value={one_design?.name}
                onChange={(e) => handleRowState(e.target.value, "label")}
              />
              <p className="mt-2 mb-1">
                <b>Border qalınlığı</b>
              </p>
              <TextField
                fullWidth
                value={one_design?.style?.border?.width}
                onChange={(e) =>
                  handleRowState(
                    !isNaN(+e.target.value)
                      ? +e.target.value
                      : one_design?.style?.border.width,
                    "width"
                  )
                }
              />
              <div className="d-flex">
                <div className="w-50">
                  <p className="mt-2 mb-1">
                    <b>Border rəngi</b>
                  </p>
                  <TextField
                    type="color"
                    className="w-100"
                    value={one_design?.style?.border?.color}
                    onChange={(e) => handleRowState(e.target.value, "color")}
                  />
                </div>
                <div className="ms-3 w-50">
                  <p className="mt-2 mb-1">
                    <b>Arxa font rəngi</b>
                  </p>
                  <TextField
                    fullWidth
                    type="color"
                    className="w-100"
                    value={one_design?.style?.backgroundColor}
                    onChange={(e) =>
                      handleRowState(e.target.value, "backgroundColor")
                    }
                  />
                </div>
              </div>
              <p className="mt-2 mb-1">
                <b>Font</b>
              </p>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label"></InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={one_design?.style?.font?.name}
                  onChange={(e) => handleRowState(e.target.value, "fontName")}
                >
                  <MenuItem value="Arial">Arial</MenuItem>
                  <MenuItem value="Cascadia Code">Cascadia Code</MenuItem>
                  <MenuItem value="'Times New Roman', Times, serif">
                    New times roman
                  </MenuItem>
                  <MenuItem value="Trebuchet MS">Trebuchet MS</MenuItem>
                  <MenuItem value="Ebrima">Ebrima</MenuItem>
                </Select>
              </FormControl>
              <p className="mt-2 mb-1">
                <b>Font ölçüsü</b>
              </p>
              <TextField
                fullWidth
                value={one_design?.style?.font.size}
                onChange={(e) =>
                  handleRowState(
                    !isNaN(+e.target.value)
                      ? +e.target.value
                      : one_design?.style?.font.size,
                    "fontSize"
                  )
                }
              />
              <GENERAL_BLOCK
                className="mt-5"
                bord={`${one_design?.style?.border?.width}px solid ${one_design?.style?.border?.color}`}
                bg_color={one_design?.style?.backgroundColor}
                pad="15px 10px"
                brad="5px"
              >
                <GENERAL_BLOCK
                  f_size={`${one_design?.style?.font.size}px`}
                  f_family={one_design?.style?.font.name}
                >
                  Başlıq
                </GENERAL_BLOCK>
                <GENERAL_BLOCK>Alt Başlıq</GENERAL_BLOCK>
              </GENERAL_BLOCK>
            </DialogContent>
            <DialogActions>
              <Button onClick={addRow}>Yadda saxla</Button>
            </DialogActions>
          </Dialog>
        </Dialog>
      ) : null}
    </>
  );
};

export default StructureEditDialog;
