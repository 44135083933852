import { useEffect, useState } from "react";
import DataTable from "../../../components/layouts/dataTable/DataTable";
import { Button, Grid } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import {
  getDescendantProp,
  normalizeDate,
} from "../../../utils/helperFunctions";
import {
  OPERATORS_CONTAINING,
  STAFFS_STATUS_TYPE,
  STAFF_DROPDOWN_VALUE,
} from "../../../constants";
import { getExplanationsApplications } from "../../../services/employeeApplication(employee)/EmployeeApplicationService";
import Table from "../../../components/table/Table";
import Column from "../../../models/table/Column";
import ButtonCustom from "../../../components/ui/Button/ButtonCustom";
import ExplanationRequestsDialog from "./ExplanationsDialog";
import { fetchExplanation_ApplicationRequest_SERVICE } from "../../../services/cabinetServices";
import moment from "moment";

export default function Applications({
  appDialog,
  appDialogSwitcher,
  setViewAppDialog,
}) {
  const [applications, setApplications] = useState([]);
  const [totalAppRow, setTotalAppRow] = useState();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [contId, setContId] = useState("");
  const [showContDialog, setShowContDialog] = useState(false);
  const [rowData, setRowData] = useState({});
  const handlePaginationChange = (pageSize, table) => {
    if (!pageSize) return;
    const value = pageSize.page * pageSize.pageSize;
    setOffset(value);
    setLimit(parseInt(pageSize.pageSize));
  };

  function contentConverter(data) {
    switch (data) {
      case "employment-application":
        return "İşə qəbul ərizəsi";
      case "vacation-application":
        return "Məzuniyyət ərizəsi";
      default:
        return "";
    }
  }

  useEffect(() => {
    // const getResponse = async () => {
    //   try {
    //     setIsLoading(true);
    //     const { data } = await getApplications({
    //       limit: limit || 5,
    //       offset: offset || 0,
    //     });
    //     setTotalAppRow(data.total);
    //     setApplications(data.contracts);
    //   } catch (error) {
    //     console.log("error", error);
    //   } finally {
    //     setIsLoading(false);
    //   }
    // };
    // getResponse();
    paginationHandler();
  }, [limit, offset, filters]);

  function openAppDialog(paramsrow) {
    appDialogSwitcher(paramsrow);
  }

  const handleRowDialogBtnClick = async (id) => {
    // // let { data } = await fetchChangeRequest(id);
    // console.log(data);
    setContId(id);
    setShowContDialog(true);
  };

  async function fetchExplanationApplication(id) {
    try {
      // setIsLoading(true);
      const { data } = await fetchExplanation_ApplicationRequest_SERVICE(id);
      setRowData(data);
      // setTotalAppRow(data.total);
      // setApplications(data.contracts);
    } catch (error) {
      console.log("error", error);
    }
    // paginationHandler();
  }

  const columns = [
    new Column({
      path: "#",
      headerName: "Operator",
      disableColSearch: true,
      renderCell: (row, column) => {
        return (
          <div
            style={{
              position: "relative",
              display: "grid",
              columnGap: "5px",
              gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))",
              height: "100%",
            }}
          >
            <ButtonCustom
              onClick={() => {
                fetchExplanationApplication(row?._id);
                setViewAppDialog(true);
              }}
              children={<RemoveRedEye />}
              style={{ height: "100%", background: "none" }}
              styleContainer={{ height: "auto" }}
            />
          </div>
        );
      },
    }),
    new Column({
      path: "activity.explanationAsFile.docNumber",
      headerName: "Sənəd no",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "docModel",
      headerName: "Məzmun",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "createdAt",
      headerName: "Tarix",
      filterType: OPERATORS_CONTAINING.DATE,
      renderCell: (row, column) => {
        return (
          <div className="text-center">
            {moment(row.createdAt)?.format("MM/DD/YYYY")}
          </div>
        );
      },
    }),
    new Column({
      path: "adresat",
      headerName: "Adresat",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
  ];

  const paginationHandler = async () => {
    if (!filters.length > 0) {
      await fetchData();
      return;
    } else {
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));
      await fetchData(encodedFilter);
    }
  };

  const fetchData = async (filters = undefined) => {
    try {
      setIsLoading(true);
      const payload = {
        offset: offset,
        limit: limit,
        applicationRequestType: "application",
        filter: filters,
      };
      const { data } = await getExplanationsApplications(payload);
      setApplications(data?.records);
      setTotalAppRow(data?.total);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  return (
    <>
      {/* {totalAppRow !== 0 ? ( */}
      <Table
        columns={columns}
        data={applications}
        totalRows={totalAppRow}
        offset={offset}
        setOffset={(value) => setOffset(value)}
        limit={limit}
        setLimit={(value) => setLimit(value)}
        isLoading={isLoading}
        applyFiltersHandler={applyFiltersHandler}
        cancelAppliedFilters={cancelAppliedFiltersHandler}
      />
      <ExplanationRequestsDialog
        row={rowData}
        open={appDialog}
        onClose={appDialogSwitcher}
        click={appDialogSwitcher}
      />
      {/* // ) : (
      //   <Grid
      //     sx={{
      //       width: "100%",
      //       fontSize: "40px",
      //       fontWeight: 600,
      //       textAlign: "center",
      //       marginTop: "20px",
      //       backgroundColor: "#233044",
      //       borderRadius: "10px",
      //     }}
      //   >
      //     Heç bir məlumat tapılmadı
      //   </Grid>
      // )} */}
    </>
  );
}
