import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay } from "date-fns";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getCommonData, updateEmployee } from "../../api/api";
import useStaticData from "../../hooks/useStaticData/useStaticData";
import { removeEmptyStrings } from "../../utils/removeEmptyString";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  driverEditLicenseValidation,
  driverLicenseValidation,
} from "../employees/Validations";
import CustomAccordion from "../ui/Accordion/Accordion";
const ChildContainer = styled.div`
  width: 100%;
`;

const LabelContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

export default function EditDriverLicense({
  submitted,
  setSubmitted,
  employeeById,
  setSelectedType,
  setOpenAddDialog,
}) {
  const [licenseTypes, setLicenseTypes] = useState([]);
  const [issuers, setIssuers] = useState();
  const [isHas, setIsHas] = useState(false);
  const [isDate, setIsDate] = useState(false);
  const [pastData, setPastData] = useState([]);
  const today = endOfDay(new Date());

  const [empStaticData] = useStaticData();

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const issuersList = await getCommonData("identityCardissuerList");
        const issuerName = issuersList?.data || [];
        setIssuers(issuerName);

        const combinedData = [];
        if (issuerName.length) {
          combinedData.push({
            issuerName: [...issuerName],
          });
        }

        handlePastData(combinedData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    fetchOptions();
  }, [submitted]);

  useEffect(() => {
    setLicenseTypes(empStaticData["licenceCategories"]?.data);
  }, [empStaticData]);
  function handlePastData(data) {
    setPastData(data);
  }
  issuers && issuers.push(employeeById?.idCardAuthority);

  function handleHidden(name, data) {
    return pastData.every((past) => !past[name]?.includes(data));
  }
  const renderTextField = ({
    name,
    label,
    errors,
    values,
    handleChange,
    touched,
  }) => (
    <LabelContainer>
      <Field
        type="text"
        name={`driverLicense.${name}`}
        as={TextField}
        fullWidth
        label={`${label}`}
        error={
          touched?.driverLicense?.[name] &&
          Boolean(errors.driverLicense?.[name])
        }
        helperText={
          touched?.driverLicense?.[name] && errors.driverLicense?.[name]
        }
      />
    </LabelContainer>
  );

  const renderDateField = ({
    name,
    label,
    maxDate,
    minDate,
    errors,
    values,
    handleChange,
    touched,
    setFieldTouched,
  }) => (
    <>
      <LabelContainer onClick={() => setIsDate(true)}>
        <Field
          name={`driverLicense.${name}`}
          render={({ field, form }) => (
            <DatePicker
              {...field}
              fullWidth
              label={`${label}`}
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              value={
                values.driverLicense[name]
                  ? new Date(values.driverLicense[name])
                  : null
              }
              slotProps={{
                textField: {
                  error:
                    isDate &&
                    errors.driverLicense?.[name] &&
                    Boolean(errors.driverLicense?.[name]),
                  helperText:
                    (isDate &&
                      errors.driverLicense?.[name] &&
                      errors.driverLicense?.[name]) ||
                    "",
                  fullWidth: true,
                },
              }}
              maxDate={maxDate}
              minDate={minDate ? minDate : today}
              onChange={(date) => {
                form.setFieldValue(`driverLicense.${name}`, date);
                // form.setFieldTouched(`driverLicense.${name}`, true);
              }}
              // onClick={setIsDate(true)}
            />
          )}
        />
      </LabelContainer>
    </>
  );

  // const renderSelectField = ({
  //   name,
  //   label,
  //   items,
  //   errors,
  //   values,
  //   handleChange,
  // }) => (
  //   <LabelContainer>
  //     <FormControl error={Boolean(errors.driverLicense?.[name])} fullWidth>
  //       <InputLabel id={`driverLicense.${name}`}>{`${label} *`}</InputLabel>
  //       <Field
  //         as={Select}
  //         name={`driverLicense.${name}`}
  //         labelId={`driverLicense.${name}`}
  //         label={`${label}`}
  //         fullWidth
  //         defaultValue=""
  //         error={Boolean(errors.driverLicense?.[name])}
  //       >
  //         <MenuItem hidden value=""></MenuItem>
  //         {items.map((item, index) => (
  //           <MenuItem key={`${item} ${index}`} value={item}>
  //             {item}
  //           </MenuItem>
  //         ))}
  //       </Field>
  //       {errors.driverLicense?.[name] && (
  //         <FormHelperText error>{errors.driverLicense?.[name]}</FormHelperText>
  //       )}
  //     </FormControl>
  //   </LabelContainer>
  // );

  const renderMultipleSelectField = ({
    name,
    label,
    options,
    errors,
    values,
    touched,
  }) => (
    <LabelContainer>
      <FormControl
        error={
          touched?.driverLicense?.[name] &&
          Boolean(errors.driverLicense?.[name])
        }
        fullWidth
      >
        <InputLabel id={`driverLicense.${name}`}>{`${label} *`}</InputLabel>
        <Field
          as={Select}
          name={`driverLicense.${name}`}
          labelId={`driverLicense.${name}`}
          label={`${label}`}
          fullWidth
          defaultValue={[]}
          multiple
          error={
            touched?.driverLicense?.[name] &&
            Boolean(errors.driverLicense?.[name])
          }
        >
          {options &&
            [...options]
              .sort((a, b) => a.localeCompare(b, "az"))
              .map((option, index) => (
                <MenuItem key={`${option} ${index}`} value={option}>
                  {option}
                </MenuItem>
              ))}
        </Field>
        {touched?.driverLicense?.[name] && errors.driverLicense?.[name] && (
          <FormHelperText error>{errors?.driverLicense?.[name]}</FormHelperText>
        )}
      </FormControl>
    </LabelContainer>
  );

  // const resetFormFields = (values, setValues) => {
  //   setValues({
  //     ...values,
  //     driverLicense: {
  //       licenseIssuer: "",
  //       licenseIssuanceDate: "",
  //       licenseExpirationDate: "",
  //       licenseNumber: "",
  //       licenseCategory: [],
  //     },
  //   });
  // };
  // const resetFormFields = (resetForm) => {
  //   resetForm({
  //     values: {
  //       driverLicense: {
  //         licenseIssuer: "",
  //         licenseIssuanceDate: "",
  //         licenseExpirationDate: "",
  //         licenseNumber: "",
  //         licenseCategory: [],
  //       },
  //       status: "Yoxdur",
  //     },
  //   });
  // };
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    if (values.status === "Yoxdur") {
      await updateEmployee(employeeById._id, { driverLicense: null });
      setIsDate(false);
    } else {
      let modifiedValues = {
        ...values,
        driverLicense:
          values.status === "Var"
            ? {
                licenseIssuer: values.driverLicense?.licenseIssuer
                  ? values.driverLicense?.licenseIssuer?.trim()
                  : null,
                licenseIssuanceDate: values.driverLicense?.licenseIssuanceDate
                  ? moment(values.driverLicense?.licenseIssuanceDate).format(
                      "MM/DD/yyyy"
                    )
                  : null,
                licenseExpirationDate: values.driverLicense
                  ?.licenseExpirationDate
                  ? moment(values.driverLicense?.licenseExpirationDate).format(
                      "MM/DD/yyyy"
                    )
                  : null,
                licenseNumber: values.driverLicense?.licenseNumber
                  ? values.driverLicense?.licenseNumber?.trim()
                  : null,
                licenseCategory: values.driverLicense?.licenseCategory,
              }
            : null,
      };
      const result = removeEmptyStrings(modifiedValues);
      await updateEmployee(employeeById._id, result);
    }
    setIsDate(false);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };
  // const initialValues = {
  //   driverLicense: employeeById.driverLicense || {},
  // };

  const checkdefaultValue = (values) =>
    values && values.length !== 0 ? "Var" : "Yoxdur";
  useEffect(() => {
    if (employeeById?.driverLicense?.hasDriverLicense === true) {
      setIsHas(true);
    } else {
      setIsHas(false);
    }
  }, [employeeById?.driverLicense?.hasDriverLicense]);
  const initialFormValues = {
    driverLicense: employeeById?.driverLicense || {
      licenseIssuer: "",
      licenseIssuanceDate: "",
      licenseExpirationDate: "",
      licenseNumber: "",
      licenseCategory: [],
    },
    status: employeeById?.driverLicense?.hasDriverLicense ? "Var" : "Yoxdur",
  };
  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Sürücülük vəsiqəsi</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={initialFormValues}
            validationSchema={driverEditLicenseValidation}
            onSubmit={handleSubmit}
            // validateOnChange={true}
            // validateOnBlur={true}
          >
            {({
              values,
              errors,
              isValid,
              isSubmitting,
              touched,
              setFieldTouched,
              resetForm,
              setFieldValue,
              dirty,
            }) => {
              const valueLeng = values.driverLicense.licenseCategory;
              return (
                <>
                  <Form>
                    {employeeById && (
                      <FormControl
                        fullWidth
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <InputLabel id="status-select-label">
                          Sürücülük vəsiqəsinin mövcudluğu
                        </InputLabel>
                        <Field
                          as={Select}
                          name="status"
                          label="Sürücülük vəsiqəsinin mövcudluğu"
                          labelId="status-select-label"
                          fullWidth
                          defaultValue={checkdefaultValue(valueLeng)}
                        >
                          <MenuItem
                            onClick={() => {
                              setIsHas(true);
                            }}
                            value="Var"
                          >
                            Var
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setIsHas(false);
                              setIsDate(false);
                              setFieldValue("driverLicense", {
                                licenseIssuer: "",
                                licenseIssuanceDate: "",
                                licenseExpirationDate: "",
                                licenseNumber: "",
                                licenseCategory: [],
                              });
                              setFieldValue("status", "Yoxdur");
                            }}
                            value="Yoxdur"
                          >
                            Yoxdur
                          </MenuItem>
                        </Field>
                      </FormControl>
                    )}
                    {isHas && (
                      <>
                        <Grid>
                          <ChildContainer>
                            <LabelContainer>
                              <FormControl
                                error={
                                  errors?.driverLicense?.licenseIssuer &&
                                  touched?.driverLicense?.licenseIssuer &&
                                  errors.driverLicense.licenseIssuer
                                }
                                fullWidth
                              >
                                <InputLabel id="driverLicense.licenseIssuer">
                                  Sənədi verən orqan *
                                </InputLabel>

                                <Field
                                  as={Select}
                                  name="driverLicense.licenseIssuer"
                                  labelId="driverLicense.licenseIssuer"
                                  label="Sənədi verən orqan"
                                  id="licenseIssuer"
                                  defaultValue=""
                                  fullWidth
                                  error={
                                    errors.driverLicense?.licenseIssuer &&
                                    touched?.driverLicense?.licenseIssuer &&
                                    Boolean(errors.driverLicense.licenseIssuer)
                                  }
                                >
                                  <MenuItem hidden value=""></MenuItem>
                                  {issuers &&
                                    [...new Set(issuers)]
                                      .sort((a, b) => a.localeCompare(b, "az"))
                                      ?.map((item, index) => (
                                        <MenuItem
                                          key={`${item} ${index}`}
                                          value={item}
                                          hidden={handleHidden(
                                            "issuerName",
                                            item
                                          )}
                                        >
                                          {item}
                                        </MenuItem>
                                      ))}
                                  <Button
                                    sx={{
                                      mt: 3,
                                      ml: 3,
                                    }}
                                    variant="outlined"
                                    onClick={() => {
                                      setOpenAddDialog(true);
                                      setSelectedType([
                                        "identityCardissuerList",
                                        { name: "Sənədi verən orqanlar" },
                                      ]);
                                    }}
                                  >
                                    Yeni sənəd verən orqan əlavə et
                                  </Button>
                                </Field>
                                {errors.driverLicense?.licenseIssuer &&
                                  touched?.driverLicense?.licenseIssuer && (
                                    <FormHelperText error>
                                      {errors.driverLicense.licenseIssuer}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </LabelContainer>
                            {renderDateField({
                              name: "licenseIssuanceDate",
                              label: "Vəsiqənin verilmə tarixi *",
                              maxDate: values.driverLicense
                                .licenseExpirationDate
                                ? values.driverLicense.licenseExpirationDate
                                : today,
                              minDate: new Date(1940, 0, 1),
                              values: values,
                              errors: errors,
                              touched: touched,
                              setFieldTouched: setFieldTouched,
                            })}
                            {renderDateField({
                              name: "licenseExpirationDate",
                              label: "Vəsiqənən etibarlıq müddəti *",
                              values: values,
                              errors: errors,
                              touched: touched,
                              setFieldTouched: setFieldTouched,
                            })}
                            {renderTextField({
                              name: "licenseNumber",
                              label: "Vəsiqənin seriya nömrəsi *",
                              values: values,
                              errors: errors,
                              touched: touched,
                            })}
                            {renderMultipleSelectField({
                              name: "licenseCategory",
                              label: "Kateqoriya",
                              options: licenseTypes,
                              values: values,
                              errors: errors,
                              touched: touched,
                            })}
                          </ChildContainer>
                        </Grid>
                      </>
                    )}
                    <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || !isValid || !dirty}
                      >
                        Dəyişiklikləri yadda saxla
                      </Button>
                    </Grid>
                  </Form>
                </>
              );
            }}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
}
