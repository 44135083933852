import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { handleServiceCall } from "../services/handleServiceCall";
import {
  registerUser_SERVICE,
  deleteUser_SERVICE,
  editUser_SERVICE,
  getUser_SERVICE,
  getUsers_SERVICE,
} from "../services/user-accountServices";
import {
  createRole_SERVICE,
  deleteRole_SERVICE,
  editRole_SERVICE,
  getModuleTree_SERVICE,
  getPermissionByModule_SERVICE,
  getRoles_SERVICE,
} from "../services/role_permissonServices";

const useRolePermissionActions = () => {
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [tree, setTree] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [moduleId, setModuleId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [roleCreateData, setRoleCreateData] = useState({
    title: { az: "", en: "", ru: "" },
    description: { az: "", en: "", ru: "" },
    permissions: ["67a30d625bd32c6afa168b64", "67a30d625bd32c6afa168b67"],
  });
  const [roleCreateDataRef, setRoleCreateDataRef] = useState({
    email: "",
  });
  const [roleCreateDialog, setRoleCreateDialog] = useState(false);
  const [roleEditDialog, setRoleEditDialog] = useState(false);
  const [roleDeleteDialog, setRoleDeleteDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [preDefinedData, setPreDefinedData] = useState({});
  const requestError = (error) => {
    enqueueSnackbar("Xəta baş verdi", { variant: "error" });
    // setUserRegisterDialog(false);
  };

  // const refetchUsers = async () => {
  //   await handleServiceCall(
  //     handleGetAllSuccess,
  //     requestError,
  //     getUsers_SERVICE,
  //     { limit: 10, offset: 0 }
  //   );
  // };
  // Success Handlers
  const handleRegisterSuccess = (response_data) => {
    setData((data) => [...data, response_data]);
    setRoleCreateDialog(false);
    enqueueSnackbar("Rol uğurla yaradıldı", { variant: "success" });
  };
  const handleDeleteSuccess = (response_data) => {
    setData((data) => [...data, response_data]);
    enqueueSnackbar("Rol uğurla silindi", { variant: "success" });
    setRoleDeleteDialog(false);
  };

  const handleGetUserSuccess = (data) => {
    setRoleCreateData({
      citizenship: data?.citizenship,
      email: data?.email,
      pin: data?.pin,
      passportNumber: data?.passportNumber,
      id: data?._id,
      email_ref: data?.email,
      citizenship_ref: data?.citizenship,
    });
  };

  const handleEditSuccess = (data) => {
    enqueueSnackbar("Rol uğurla redaktə edildi", { variant: "success" });
    setRoleEditDialog(false);
    // getRoles();
  };

  const handleGetAllSuccess = (data) => {
    setData(data?.items);
    setTotal(data?.total);
  };

  const handleGetModuleTreeSuccess = (data) => {
    setTree(data);
  };

  // API Calls
  const createRole = async (roleCreateData) =>
    await handleServiceCall(
      setLoading,
      handleRegisterSuccess,
      requestError,
      createRole_SERVICE,
      roleCreateData
    );

  const deleteRole = async (id) =>
    await handleServiceCall(
      setLoading,
      handleDeleteSuccess,
      requestError,
      deleteRole_SERVICE,
      id
    );

  const getUser = async (id) =>
    await handleServiceCall(
      setLoading,
      handleGetUserSuccess,
      requestError,
      getUser_SERVICE,
      id
    );

  const editRole = async (roleEditData) =>
    await handleServiceCall(
      setLoading,
      handleEditSuccess,
      requestError,
      editRole_SERVICE,
      roleEditData
    );

  const getRoles = async () =>
    await handleServiceCall(
      setLoading,
      handleGetAllSuccess,
      requestError,
      getRoles_SERVICE,
      { limit: 10, offset: 0 }
    );

  const getModuleTree = async () =>
    await handleServiceCall(
      setLoading,
      handleGetModuleTreeSuccess,
      requestError,
      getModuleTree_SERVICE
    );

  const getPermissionByModule = async () =>
    await handleServiceCall(
      setLoading,
      (data) => setPermissions(data?.items),
      requestError,
      getPermissionByModule_SERVICE,
      { limit: 10, offset: 0, moduleId }
    );

  const handleChange = (e, lang) => {
    setRoleCreateData({
      ...roleCreateData,
      [e.target.name]: {
        ...roleCreateData[e.target.name],
        [lang]: e.target.value,
      },
    });
  };

  const handlePermissions = (permissions) => {
    setRoleCreateData({
      ...roleCreateData,
      permissions,
    });
  };

  return {
    createRole,
    deleteRole,
    getUser,
    editRole,
    getRoles,
    getModuleTree,
    getPermissionByModule,
    handleChange,
    handlePermissions,
    permissions,
    roleCreateData,
    setRoleCreateData,
    roleCreateDialog,
    setRoleCreateDialog,
    roleDeleteDialog,
    setRoleDeleteDialog,
    activeStep,
    setActiveStep,
    setRoleEditDialog,
    roleEditDialog,
    setModuleId,
    moduleId,
    data,
    setData,
    tree,
    total,
    loading,
    offset,
    limit,
    preDefinedData,
  };
};

export default useRolePermissionActions;
