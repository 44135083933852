import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Grid2,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, FieldArray } from "formik";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker } from "@mui/x-date-pickers";

export const OverTimeFormCenter = ({
  index,
  errors,
  values,
  remove,
  isDate,
  touched,
  empData,
  setIsDate,
  setFieldValue,
}) => {
  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography mt={3} mb={3} variant="h6">
            {/* {index + 1}) */}
            Əməkdaş
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Grid2
              key={index}
              sx={{
                gap: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                mb: 2,
              }}
            >
              <Grid2
                sx={{
                  gap: "10px",
                  width: "100%",
                  display: "flex",
                }}
              >
                <Grid2 sx={{ width: "33%" }}>
                  <FormControl
                    fullWidth
                    error={
                      errors?.overtime?.[index]?.employee &&
                      touched?.overtime?.[index]?.employee &&
                      Boolean(errors?.overtime?.[index]?.employee)
                    }
                  >
                    <Field name={`overtime[${index}].employee`}>
                      {({ field, form }) => {
                        const selectedEmployee =
                          empData.find(
                            (emp) =>
                              emp?._id === values?.overtime?.[index]?.employee
                          ) || null;
                        return (
                          <Autocomplete
                            options={empData || []}
                            getOptionLabel={(option) => {
                              if (!option) return "";
                              return `${option?.firstname || ""} ${
                                option?.lastname || ""
                              } ${option?.fathername || ""}`;
                            }}
                            value={selectedEmployee || ""}
                            onChange={(event, selectedOption) => {
                              form.setFieldValue(
                                `overtime[${index}].employee`,
                                selectedOption?._id ? selectedOption?._id : ""
                              );
                              if (!selectedOption?._id) {
                                setFieldValue(
                                  `overtime[${index}].contract`,
                                  ""
                                );
                                setFieldValue(
                                  `overtime[${index}].contractDate`,
                                  ""
                                );
                                setFieldValue(
                                  `overtime[${index}].position`,
                                  ""
                                );
                                setFieldValue(
                                  `overtime[${index}].structure`,
                                  ""
                                );
                              }
                            }}
                            renderOption={(props, option) => (
                              <li {...props} key={option?._id}>
                                {option?.firstname} {option?.lastname} (
                                {option?.fathername})
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Əməkdaş"
                                variant="outlined"
                                helperText={
                                  touched?.overtime?.[index]?.employee &&
                                  errors?.overtime?.[index]?.employee
                                }
                                error={
                                  Boolean(
                                    errors?.overtime?.[index]?.employee
                                  ) &&
                                  Boolean(touched?.overtime?.[index]?.employee)
                                }
                              />
                            )}
                          />
                        );
                      }}
                    </Field>

                    <ErrorMessage
                      name={`overtime[${index}].employee`}
                      component="div"
                      style={{
                        color: "red",
                        fontSize: "12px",
                      }}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 sx={{ width: "33%" }}>
                  <FormControl
                    fullWidth
                    error={
                      errors?.overtime?.[index]?.contract &&
                      touched?.overtime?.[index]?.contract &&
                      Boolean(errors?.overtime?.[index]?.contract)
                    }
                  >
                    <InputLabel>Əmək müqaviləsi</InputLabel>
                    <Field
                      as={Select}
                      name={`overtime[${index}].contract`}
                      label="Əmək müqaviləsi"
                      defaultValue=""
                      fullWidth
                      onChange={(e) => {
                        const { value } = e.target;

                        const selectStaff = empData
                          ?.find(
                            (emp) =>
                              emp?._id === values?.overtime?.[index]?.employee
                          )
                          ?.staffs?.find((staff) => staff?._id === value);

                        setFieldValue(`overtime[${index}].contract`, value);
                        setFieldValue(
                          `overtime[${index}].contractDate`,
                          selectStaff?.contract?.decisionDate || ""
                        );
                        setFieldValue(
                          `overtime[${index}].position`,
                          selectStaff?.position?.name || ""
                        );
                        setFieldValue(
                          `overtime[${index}].structure`,
                          selectStaff?.structure?.organization?.name || ""
                        );
                      }}
                      disabled={!values?.overtime?.[index]?.employee}
                      error={
                        errors?.overtime?.[index]?.contract &&
                        touched?.overtime?.[index]?.contract &&
                        Boolean(errors?.overtime?.[index]?.contract)
                      }
                    >
                      {values?.overtime?.[index]?.employee &&
                        empData
                          ?.filter(
                            (emp) =>
                              emp?._id === values?.overtime?.[index]?.employee
                          )
                          ?.flatMap((emp) =>
                            emp?.staffs?.map((staff) => (
                              <MenuItem key={staff?._id} value={staff?._id}>
                                {staff?.contract?.data?.docNumber}
                              </MenuItem>
                            ))
                          )}
                    </Field>
                    <ErrorMessage
                      name={`overtime[${index}].contract`}
                      component="div"
                      style={{
                        color: "red",
                        fontSize: "12px",
                      }}
                    />
                  </FormControl>
                </Grid2>
                <Grid2
                  sx={{
                    width: "33%",
                    pointerEvents: "none",
                  }}
                >
                  <Field name={`overtime[${index}].contractDate`}>
                    {({ field }) => (
                      <DatePicker
                        {...field}
                        sx={{ width: "100%" }}
                        label="Müqavilənin tarixi"
                        inputFormat="dd/MM/yyyy"
                        value={field?.value ? new Date(field?.value) : null}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    )}
                  </Field>
                </Grid2>
              </Grid2>
              <Grid2
                sx={{
                  gap: "10px",
                  width: "100%",
                  display: "flex",
                }}
              >
                <Grid2
                  sx={{
                    width: "50%",
                    pointerEvents: "none",
                  }}
                >
                  <Field
                    type="text"
                    name={`overtime[${index}].structure`}
                    as={TextField}
                    fullWidth
                    label="Struktur"
                  />
                </Grid2>
                <Grid2
                  sx={{
                    width: "50%",
                    pointerEvents: "none",
                  }}
                >
                  <Field
                    type="text"
                    name={`overtime[${index}].position`}
                    as={TextField}
                    fullWidth
                    label="Vəzifə"
                  />
                </Grid2>
              </Grid2>
              <Grid2
                sx={{
                  gap: "10px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <FieldArray name={`overtime[${index}].footer`}>
                  {({ push, remove }) => (
                    <>
                      {values.overtime?.[index]?.footer.map((foot, ind) => (
                        <Grid2 container spacing={2} key={ind}>
                          <Grid2
                            item
                            xs={4}
                            sx={{ width: "25%" }}
                            onClick={() => setIsDate(true)}
                          >
                            <Field
                              name={`overtime[${index}].footer[${ind}].date`}
                            >
                              {({ field, form }) => {
                                const error =
                                  isDate &&
                                  form.errors.overtime?.[index]?.footer?.[ind]
                                    ?.date;

                                return (
                                  <FormControl sx={{ width: "100%" }}>
                                    <DatePicker
                                      {...field}
                                      label="Tarix"
                                      format="dd/MM/yyyy"
                                      value={
                                        field?.value
                                          ? new Date(field?.value)
                                          : null
                                      }
                                      disabled={
                                        !values?.overtime?.[index]?.contract
                                      }
                                      onChange={(value) =>
                                        form.setFieldValue(field.name, value)
                                      }
                                      minDate={new Date(2025, 0, 1)}
                                      maxDate={new Date(2099, 11, 31)}
                                      slotProps={{
                                        textField: {
                                          error: !!error,
                                          helperText: error || "",
                                          fullWidth: true,
                                        },
                                      }}
                                    />
                                  </FormControl>
                                );
                              }}
                            </Field>
                          </Grid2>

                          <Grid2 item xs={4} sx={{ width: "25%" }}>
                            <Field
                              name={`overtime[${index}].footer[${ind}].hour`}
                            >
                              {({ field, form }) => {
                                const error =
                                  form.touched.overtime &&
                                  form.touched.overtime?.[index]?.footer?.[ind]
                                    ?.hour &&
                                  form.errors.overtime &&
                                  form.errors.overtime?.[index]?.footer?.[ind]
                                    ?.hour;

                                return (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    disabled={
                                      !values?.overtime?.[index]?.contract
                                    }
                                    label="Saat"
                                    error={!!error}
                                    helperText={error ? error : ""}
                                  />
                                );
                              }}
                            </Field>
                          </Grid2>

                          <Grid2 item xs={4} sx={{ width: "25%" }}>
                            <FormControl
                              component="fieldset"
                              variant="standard"
                            >
                              <RadioGroup
                                name={`overtime[${index}].footer[${ind}].paymentType`}
                                row
                                defaultValue="Double"
                                onChange={(e) => {
                                  setFieldValue(
                                    `overtime[${index}].footer[${ind}].paymentType`,
                                    e?.target?.value
                                  );
                                }}
                                value={
                                  values?.overtime?.[index]?.footer?.[ind]
                                    ?.paymentType
                                }
                              >
                                <FormControlLabel
                                  value="Double"
                                  control={<Radio color="primary" />}
                                  label="2X (2 qat)"
                                />
                                {values?.type !== "Extended" && (
                                  <FormControlLabel
                                    value="Substitute_Day"
                                    control={<Radio color="primary" />}
                                    label="Əvəzgün"
                                  />
                                )}
                              </RadioGroup>
                            </FormControl>
                          </Grid2>

                          <Grid2
                            item
                            xs={12}
                            sx={{
                              gap: "10px",
                              width: "25%",
                              display: "flex",
                            }}
                          >
                            {ind ===
                              values?.overtime?.[index]?.footer.length - 1 &&
                              values?.overtime?.[index]?.footer.length < 30 && (
                                <Button
                                  type="button"
                                  variant="outlined"
                                  onClick={() => {
                                    push({
                                      date: "",
                                      hour: "",
                                      paymentType: "Double",
                                    });
                                    setIsDate(false);
                                  }}
                                >
                                  + Əlavə et
                                </Button>
                              )}

                            {values?.overtime?.[index]?.footer.length === 1
                              ? ind > 0
                              : ind >= 0 && (
                                  <Button
                                    type="button"
                                    color="error"
                                    variant="outlined"
                                    onClick={() => {
                                      remove(ind);
                                      setIsDate(false);
                                    }}
                                  >
                                    - Sil
                                  </Button>
                                )}
                          </Grid2>
                        </Grid2>
                      ))}
                    </>
                  )}
                </FieldArray>
              </Grid2>

              {/* <Grid2>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    remove(index);
                    setIsDate(false);
                  }}
                >
                  Əməkdaş Sil
                </Button>
              </Grid2> */}
            </Grid2>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
