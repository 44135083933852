import { Add as AddIcon } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ErrorMessage, Field, FieldArray, useFormikContext } from "formik";
import React, { useState } from "react";
import { checkUniqueness } from "../../utils/checkUniquenessForEmployee";

const educationType = [
  { value: "primary", name: "Ümumi təhsil - İbtidati təhsil" },
  { value: "bachelor", name: "Ali təhsil - bakalavriat" },
  { value: "master", name: "Ali təhsil - magistratura" },
  { value: "doctoral", name: "Ali təhsil - doktorantura" },
  { value: "generalSecondary", name: "Ümumi təhsil - ümumi orta təhsil" },
  { value: "completeSecondary", name: "Ümumi təhsil - tam orta təhsil" },
  { value: "firstVocational", name: "İlk peşə-ixtisas təhsili" },
  { value: "secondarySpecial", name: "Orta ixtisas təhsili" },
  {
    value: "secBasisOfGeneralSec",
    name: "Ümumi orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "secBasisOfCompleteSec",
    name: "Tam orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "incompleteHigherTill1993",
    name: "Ali təhsil - 1993 cü ilə qədər natamam ali təhsil",
  },
  {
    value: "completeHigherTill1993",
    name: "Ali təhsil - 1997 ci ilə qədər tam ali təhsil",
  },
].sort((a, b) => a.name.localeCompare(b.name));

const countries = [
  "Albaniya",
  "Almaniya",
  "Anqola",
  "Antiqua və Barbuda",
  "Argentina",
  "Avstraliya",
  "Avstriya",
  "Azərbaycan",
  "Bahamalar",
  "Bahrayn",
  "Banqladeş",
  "Barbados",
  "Belarus",
  "Belçika",
  "Boliviya",
  "Bosniya və Herseqovina",
  "Botswana",
  "Braziliya",
  "Böyük Britaniya",
  "Büyükdəniz adası",
  "Bəhreyn",
  "Bəruney",
  "Cənubi Afrika",
  "Cənubi Sudan",
  "Cənubi Koreya",
  "Çad",
  "Çexiya",
  "Çili",
  "Çin",
  "Çernoqoriya",
  "Çexoslavakiya",
  "Danemarka",
  "Dominika",
  "Dominikan Respublikası",
  "Ekvador",
  "Ekvatorial Qvineya",
  "Eritreya",
  "Estoniya",
  "Əfqanıstan",
  "Fici",
  "Filippin",
  "Finlandiya",
  "Fransa",
  "Fransız Qvineyası",
  "Fələstin",
  "Gana",
  "Qabon",
  "Qambiya",
  "Qazaxıstan",
  "Qatar",
  "Qrenada",
  "Qvineya",
  "Qvineya-Bisau",
  "Gürcüstan",
  "Haiti",
  "Hollandiya",
  "Honduras",
  "Honkonq",
  "Hindistan",
  "Horvatiya",
  "Indoneziya",
  "İngiltərə",
  "İordaniya",
  "İraq",
  "İran",
  "İrlandiya",
  "İslandiya",
  "İspaniya",
  "İsrail",
  "İsveç",
  "İsveçrə",
  "İtaliya",
  "Kamboca",
  "Kamerun",
  "Kanada",
  "Keniya",
  "Kipr",
  "Kiribati",
  "Kolumbiya",
  "Komor Adaları",
  "Kongo",
  "Kosta Rika",
  "Kotd’İvor",
  "Kuba",
  "Kuveyt",
  "Kırqızıstan",
  "Latviya",
  "Lesoto",
  "Livan",
  "Liberiya",
  "Litva",
  "Lixtenşteyn",
  "Liviya",
  "Lüksemburq",
  "Makedoniya",
  "Malavi",
  "Malayziya",
  "Maldiv adaları",
  "Malta",
  "Maroq",
  "Marşal adaları",
  "Mauritaniya",
  "Mauritius",
  "Meksika",
  "Moldova",
  "Monako",
  "Monqolustan",
  "Monteneqro",
  "Morokko",
  "Mozambik",
  "Myanma",
  "Mərakeş",
  "Nambiya",
  "Nauru",
  "Nepal",
  "Niderland",
  "Niger",
  "Nigeriya",
  "Nikaraqua",
  "Norveç",
  "Pakistan",
  "Palau",
  "Panama",
  "Paraqvay",
  "Portuqaliya",
  "Polşa",
  "Rumıniya",
  "Rusiya",
  "Ruanda",
  "San-Marino",
  "Svazilend",
  "Salvador",
  "Samoa",
  "San-Tome və Prinsip",
  "Seneqal",
  "Seyşel adaları",
  "Sinhapur",
  "Slovakiya",
  "Sloveniya",
  "Solomon adaları",
  "Somali",
  "Sudan",
  "Surinam",
  "Suriya",
  "Svədistan",
  "Səudiyyə Ərəbistanı",
  "Sərbiya",
  "Tacikistan",
  "Tanzaniya",
  "Tailand",
  "Tayvan",
  "Toga",
  "Tonqa",
  "Trinidad və Tobaqo",
  "Tunis",
  "Turkmənistan",
  "Tuvalu",
  "Türkiyə",
  "Ukrayna",
  "Urugvay",
  "Vanuatu",
  "Vatikan",
  "Venesuela",
  "Vyetnam",
  "Yamayka",
  "Yaponiya",
  "Yeni Zelandiya",
  "Yunanıstan",
  "Yəmən",
  "Zambiya",
  "Zimbabve",
].sort((a, b) => a.localeCompare(b));

const RelationShipInfo = ({ values }) => {
  const [tRPNumberCheckbox, setTrpCheckbox] = useState(false);
  const { validateForm, setErrors, errors, setFieldValue, handleChange } =
    useFormikContext();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const operators = [
    { code: "055", name: "Bakcell" },
    { code: "099", name: "Bakcell" },
    { code: "010", name: "Azercell" },
    { code: "050", name: "Azercell" },
    { code: "051", name: "Sim" },
    { code: "070", name: "Nar" },
    { code: "077", name: "Nar" },
  ];
  const isUnderSixYearsOld = (dob) => {
    if (!dob) return true; // Disable if dob is not set

    const dobDate = new Date(dob); // Convert dob to a Date object
    const today = new Date();
    const ageDifference = today.getFullYear() - dobDate.getFullYear();

    return (
      ageDifference < 5 ||
      (ageDifference === 5 &&
        today < new Date(dobDate.setFullYear(dobDate.getFullYear() + 5)))
    );
  };

  return (
    <FieldArray name="relationshipInfo">
      {({ push, remove }) => (
        <div>
          {values?.relationshipInfo &&
            values?.relationshipInfo.map((form, index) => (
              <div key={index}>
                <>
                  <Typography mt={3} mb={3} variant="h6">
                    {index + 1})Qohumluq məlumatı{" "}
                  </Typography>
                  {/* Relation type and full name */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.relationshipInfo?.[index]?.relationType &&
                          Boolean(
                            errors?.relationshipInfo?.[index]?.relationType
                          )
                        }
                        fullWidth
                      >
                        <InputLabel
                          id={`relationshipInfo[${index}].relationType`}
                        >
                          Qohumluq əlaqəsi növü *
                        </InputLabel>
                        <Field
                          as={Select}
                          name={`relationshipInfo[${index}].relationType`}
                          labelId={`relationshipInfo[${index}].relationType`}
                          label="Qohumluq əlaqəsi növü *"
                          id={`relationshipInfo[${index}].relationType-select`}
                          defaultValue=""
                          onChange={(event) => {
                            const selectedValue = event.target.value;
                            if (
                              selectedValue !== "sister" &&
                              selectedValue !== "brother" &&
                              selectedValue !== "son" &&
                              selectedValue !== "daughter"
                            ) {
                              checkUniqueness(
                                values?.relationshipInfo,
                                "relationType",
                                event,
                                setFieldValue,
                                `relationshipInfo[${index}]`,
                                "qohumluq əlaqəsi növü"
                              );
                            } else {
                              setFieldValue(
                                `relationshipInfo[${index}].relationType`,
                                selectedValue
                              );
                            }
                            if (
                              errors?.relationshipInfo?.[index]?.relationType
                            ) {
                              setErrors({
                                ...errors,
                                relationshipInfo: [
                                  ...errors.relationshipInfo.slice(0, index),
                                  {
                                    ...errors.relationshipInfo[index],
                                    relationType: "",
                                  },
                                  ...errors.relationshipInfo.slice(index + 1),
                                ],
                              });
                            }
                          }}
                          fullWidth
                          error={
                            errors?.relationshipInfo?.[index]?.relationType &&
                            Boolean(
                              errors?.relationshipInfo?.[index]?.relationType
                            )
                          }
                        >
                          <MenuItem value="father">Atam</MenuItem>
                          <MenuItem value="mother">Anam </MenuItem>
                          <MenuItem value="sister">Bacım </MenuItem>
                          {/* <MenuItem value="grandfather">Babam </MenuItem> */}
                          {/* <MenuItem value="grandmother">Nənəm </MenuItem> */}
                          <MenuItem value="grandchild">Nəvəm </MenuItem>
                          <MenuItem value="son">Oğlum </MenuItem>
                          <MenuItem value="daughter">Qızım </MenuItem>
                          <MenuItem value="brother">Qardaşım </MenuItem>
                          <MenuItem value="father-in-law">Qaynatam </MenuItem>
                          <MenuItem value="mother-in-law">Qaynanam </MenuItem>
                          <MenuItem value="husband">Yoldaşım </MenuItem>
                        </Field>
                        {errors.relationshipInfo?.[index]?.relationType &&
                          errors.relationshipInfo?.[index]?.relationType && (
                            <FormHelperText error>
                              {errors.relationshipInfo?.[index]?.relationType}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name={`relationshipInfo[${index}].fullname`}
                        as={TextField}
                        label="Soyadı Adı Atasının adı *"
                        fullWidth
                        error={
                          errors?.relationshipInfo?.[index]?.fullname &&
                          Boolean(errors?.relationshipInfo?.[index]?.fullname)
                        }
                        helperText={
                          errors?.relationshipInfo?.[index]?.fullname &&
                          errors?.relationshipInfo?.[index]?.fullname
                        }
                        onChange={(event) => {
                          setFieldValue(
                            `relationshipInfo[${index}].fullname`,
                            event.target.value
                          );

                          if (errors?.relationshipInfo?.[index]?.fullname) {
                            setErrors({
                              ...errors,
                              relationshipInfo: [
                                ...errors.relationshipInfo.slice(0, index),
                                {
                                  ...errors.relationshipInfo[index],
                                  fullname: "",
                                },
                                ...errors.relationshipInfo.slice(index + 1),
                              ],
                            });
                          }
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* fin and national id card number */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    {(values.relationshipInfo[index]?.isMyiExist &&
                      values.relationshipInfo[index].isForeigner) ||
                    !values.relationshipInfo[index].isForeigner ? (
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name={`relationshipInfo[${index}].pin`}
                          as={TextField}
                          fullWidth
                          label="FIN"
                          onChange={(event) => {
                            const uppercaseValue =
                              event.target.value.toUpperCase();
                            setFieldValue(
                              `relationshipInfo[${index}].pin`,
                              uppercaseValue
                            );
                            if (errors?.relationshipInfo?.[index]?.pin) {
                              setErrors({
                                ...errors,
                                relationshipInfo: [
                                  ...errors.relationshipInfo.slice(0, index),
                                  {
                                    ...errors.relationshipInfo[index],
                                    pin: "",
                                  },
                                  ...errors.relationshipInfo.slice(index + 1),
                                ],
                              });
                            }
                          }}
                          error={
                            errors?.relationshipInfo?.[index]?.pin &&
                            Boolean(errors?.relationshipInfo?.[index]?.pin)
                          }
                          helperText={
                            errors?.relationshipInfo?.[index]?.pin &&
                            errors?.relationshipInfo?.[index]?.pin
                          }
                        />
                      </Grid>
                    ) : null}

                    <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
                      {values.relationshipInfo[index].isForeigner ? null : (
                        <Grid sx={{ width: 1 / 5 }}>
                          <Field
                            as={Select}
                            name={`relationshipInfo[${index}].idCardNumber.code`}
                            labelId={`relationshipInfo[${index}].idCardNumber.code`}
                            label=""
                            id={`relationshipInfo[${index}].idCardNumber.code`}
                            defaultValue=""
                            fullWidth
                            onChange={(event) => {
                              setFieldValue(
                                `relationshipInfo[${index}].idCardNumber.code`,
                                event.target.value
                              );

                              if (
                                errors?.relationshipInfo?.[index]?.idCardNumber
                                  ?.code
                              ) {
                                const updatedErrors = [
                                  ...errors.relationshipInfo,
                                ];
                                updatedErrors[index] = {
                                  ...updatedErrors[index],
                                  idCardNumber: {
                                    ...updatedErrors[index].idCardNumber,
                                    code: null,
                                  },
                                };
                                setErrors({
                                  ...errors,
                                  relationshipInfo: updatedErrors,
                                });
                              }
                            }}
                          >
                            <MenuItem value="AA">AA</MenuItem>
                            <MenuItem value="AZE">AZE</MenuItem>
                          </Field>
                        </Grid>
                      )}

                      <Grid
                        sx={{
                          width:
                            values.relationshipInfo[index].isForeigner &&
                            !values.relationshipInfo[index]?.isMyiExist
                              ? "50%"
                              : values.relationshipInfo[index].isForeigner
                              ? "100%"
                              : 4 / 5,
                        }}
                      >
                        <Field
                          type="text"
                          name={`relationshipInfo[${index}].idCardNumber.number`}
                          as={TextField}
                          fullWidth
                          label={
                            values.relationshipInfo[index].isForeigner
                              ? "Passportun nömrəsi *"
                              : "Vəsiqəsinin nömrəsi"
                          }
                          error={
                            errors?.relationshipInfo?.[index]?.idCardNumber
                              ?.number &&
                            Boolean(
                              errors?.relationshipInfo?.[index]?.idCardNumber
                                ?.number
                            )
                          }
                          helperText={
                            errors?.relationshipInfo?.[index]?.idCardNumber
                              ?.number &&
                            errors?.relationshipInfo?.[index]?.idCardNumber
                              ?.number
                          }
                          onChange={(event) => {
                            setFieldValue(
                              `relationshipInfo[${index}].idCardNumber.number`,
                              event.target.value
                            );

                            if (
                              errors?.relationshipInfo?.[index]?.idCardNumber
                                ?.number
                            ) {
                              const updatedErrors = [
                                ...errors.relationshipInfo,
                              ];
                              updatedErrors[index] = {
                                ...updatedErrors[index],
                                idCardNumber: {
                                  ...updatedErrors[index].idCardNumber,
                                  number: null,
                                },
                              };
                              setErrors({
                                ...errors,
                                relationshipInfo: updatedErrors,
                              });
                            }
                          }}
                        />
                      </Grid>

                      {values.relationshipInfo[index]?.isMyiExist &&
                        values.relationshipInfo[index].isForeigner && (
                          <Field
                            type="text"
                            name={`relationshipInfo[${index}].tRPNumber`}
                            as={TextField}
                            fullWidth
                            label="MYİ seriya Nömrəsi"
                            error={
                              errors?.relationshipInfo?.[index]?.tRPNumber &&
                              Boolean(
                                errors?.relationshipInfo?.[index]?.tRPNumber
                              )
                            }
                            helperText={
                              errors?.relationshipInfo?.[index]?.tRPNumber &&
                              errors?.relationshipInfo?.[index]?.tRPNumber
                            }
                          />
                        )}
                      {values.relationshipInfo[index].isForeigner && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                values.relationshipInfo[index]?.isMyiExist ||
                                false
                              }
                              onChange={handleChange}
                              name={`relationshipInfo[${index}].isMyiExist`}
                              color="primary"
                            />
                          }
                          label="Müvəqqəti yaşayış icazəm var"
                        />
                      )}
                    </Grid>
                  </Grid>

                  {/* DOB and education info */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Field name={`relationshipInfo[${index}].dob`}>
                      {({ field, form }) => (
                        <DatePicker
                          {...field}
                          label="Doğum tarixi *"
                          sx={{ width: "100%" }}
                          format="dd/MM/yyyy"
                          value={field?.value ? new Date(field?.value) : null}
                          slotProps={{
                            textField: {
                              error: Boolean(
                                form.errors?.relationshipInfo?.[index]?.dob
                              ),
                              helperText:
                                form.errors?.relationshipInfo?.[index]?.dob ||
                                "",
                              fullWidth: true,
                            },
                          }}
                          onChange={(date) => {
                            if (date instanceof Date && !isNaN(date)) {
                              form.setFieldValue(
                                `relationshipInfo[${index}].dob`,
                                date
                              );
                              if (errors?.relationshipInfo?.[index]?.dob) {
                                setErrors({
                                  ...errors,
                                  relationshipInfo: [
                                    ...errors.relationshipInfo.slice(0, index),
                                    {
                                      ...errors.relationshipInfo[index],
                                      dob: "",
                                    },
                                    ...errors.relationshipInfo.slice(index + 1),
                                  ],
                                });
                              }
                            } else {
                              form.setFieldValue(
                                `relationshipInfo[${index}].dob`,
                                ""
                              );
                            }
                          }}
                          maxDate={new Date()}
                          minDate={new Date(1940, 0, 1)}
                        />
                      )}
                    </Field>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.relationshipInfo?.[index]?.education &&
                          Boolean(errors?.relationshipInfo?.[index]?.education)
                        }
                        fullWidth
                      >
                        <InputLabel id={`relationshipInfo[${index}].education`}>
                          Təhsili
                        </InputLabel>
                        <Field
                          as={Select}
                          name={`relationshipInfo[${index}].education`}
                          labelId={`relationshipInfo[${index}].education`}
                          label="Təhsili"
                          id={`relationshipInfo[${index}].education-select`}
                          defaultValue=""
                          fullWidth
                          error={
                            errors?.relationshipInfo?.[index]?.education &&
                            Boolean(
                              errors?.relationshipInfo?.[index]?.education
                            )
                          }
                          disabled={isUnderSixYearsOld(
                            values.relationshipInfo[index].dob
                          )}
                          onChange={(event) => {
                            setFieldValue(
                              `relationshipInfo[${index}].education`,
                              event.target.value
                            );

                            if (errors?.relationshipInfo?.[index]?.education) {
                              setErrors({
                                ...errors,
                                relationshipInfo: [
                                  ...errors.relationshipInfo.slice(0, index),
                                  {
                                    ...errors.relationshipInfo[index],
                                    education: "",
                                  },
                                  ...errors.relationshipInfo.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        >
                          {educationType &&
                            educationType
                              .sort((a, b) =>
                                a?.name.localeCompare(b?.name, "az")
                              )
                              .map((item, index) => (
                                <MenuItem value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                        </Field>
                        {errors.relationshipInfo?.[index]?.education &&
                          errors.relationshipInfo?.[index]?.education && (
                            <FormHelperText error>
                              {errors.relationshipInfo?.[index]?.education}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Service period and same workplace */}
                  <Grid sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name={`relationshipInfo[${index}].placeOfWork`}
                        as={TextField}
                        fullWidth
                        label="İş yeri (vəzifəsi)"
                        error={
                          errors?.relationshipInfo?.[index]?.placeOfWork &&
                          Boolean(
                            errors?.relationshipInfo?.[index]?.placeOfWork
                          )
                        }
                        helperText={
                          errors?.relationshipInfo?.[index]?.placeOfWork &&
                          errors?.relationshipInfo?.[index]?.placeOfWork
                        }
                        onChange={(event) => {
                          setFieldValue(
                            `relationshipInfo[${index}].placeOfWork`,
                            event.target.value
                          );

                          if (errors?.relationshipInfo?.[index]?.placeOfWork) {
                            setErrors({
                              ...errors,
                              relationshipInfo: [
                                ...errors.relationshipInfo.slice(0, index),
                                {
                                  ...errors.relationshipInfo[index],
                                  placeOfWork: "",
                                },
                                ...errors.relationshipInfo.slice(index + 1),
                              ],
                            });
                          }
                        }}
                      />
                    </Grid>

                    {values.relationshipInfo[index].isForeigner ? (
                      <FormControl
                        error={errors.citizenship && errors.citizenship}
                        fullWidth
                      >
                        <InputLabel id="citizenship">Vətəndaşlığı</InputLabel>
                        <Field
                          as={Select}
                          name={`relationshipInfo[${index}].citizenship`}
                          labelId="citizenship"
                          label="citizenship"
                          id="citizenship-select"
                          fullWidth
                          error={
                            errors?.relationshipInfo?.[index]?.citizenship &&
                            Boolean(
                              errors?.relationshipInfo?.[index]?.citizenship
                            )
                          }
                          required
                        >
                          {countries &&
                            countries
                              .sort((a, b) => a.localeCompare(b, "az"))
                              .map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                        </Field>
                        {errors?.relationshipInfo?.[index]?.citizenship &&
                          errors?.relationshipInfo?.[index]?.citizenship && (
                            <FormHelperText error>
                              {errors?.relationshipInfo?.[index]?.citizenship}
                            </FormHelperText>
                          )}
                      </FormControl>
                    ) : null}
                  </Grid>
                  <Grid sx={{ marginTop: "20px", width: "50%" }}>
                    <FieldArray name={`relationshipInfo[${index}].mobile`}>
                      {({ push, remove }) => (
                        <>
                          {values?.relationshipInfo?.[index]?.mobile &&
                            values?.relationshipInfo?.[index]?.mobile.map(
                              (mobile, ind) => (
                                <Grid
                                  mb={3}
                                  key={ind}
                                  container
                                  spacing={2}
                                  alignItems="center"
                                >
                                  <Grid item xs={9}>
                                    <Field
                                      type="tel"
                                      name={`relationshipInfo[${index}].mobile[${ind}]`}
                                      as={TextField}
                                      fullWidth
                                      label={`Mobil ${ind + 1}`}
                                    />
                                    <ErrorMessage
                                      name={`relationshipInfo[${index}].mobil[${ind}]`}
                                      component="div"
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    {ind ===
                                      values.relationshipInfo[index].mobile
                                        .length -
                                        1 && (
                                      <Button
                                        type="button"
                                        onClick={() => push("")}
                                      >
                                        + Mobil əlavə et
                                      </Button>
                                    )}
                                    {ind > 0 && (
                                      <Button
                                        type="button"
                                        onClick={() => remove(ind)}
                                      >
                                        - Mobil sil
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              )
                            )}
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                </>

                <Button
                  sx={{ mt: 3 }}
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={() => remove(index)}
                >
                  Qohumluq məlumatı sil
                </Button>
              </div>
            ))}
          <div>
            <Button
              sx={{ mt: 3 }}
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={handleFilterClick}
              variant="contained"
              color="primary"
            >
              <AddIcon />
              Qohumluq məlumatı əlavə et
            </Button>

            <Menu
              id="nationality-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleFilterClose}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={(e) => {
                  push({
                    isForeigner: false,
                    isMyiExist: false,
                    relationType: "",
                    mobile: [""],
                  });
                  handleFilterClose();
                }}
              >
                Azərbaycan Vətəndaşı
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  push({
                    isForeigner: true,
                    isMyiExist: false,
                    relationType: "",
                    mobile: [""],
                  });
                  handleFilterClose();
                }}
              >
                Xarici Vətəndaşı
              </MenuItem>
            </Menu>
          </div>
        </div>
      )}
    </FieldArray>
  );
};

export default RelationShipInfo;
