import {
  Box,
  Button,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Header from "../../../components/layouts/header/Header";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import {
  ActiveDaysCalendar,
  ActiveMonthTable,
  PlanningDaysCalendar,
  PlanningMonthTable,
} from "./VacationScheduleTabel";
import Tabel from "../../../components/table/Table";
import NestedSelect from "../../../utils/NestedSelect";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import styles from "../vacation-schedule/vacationSchedule.module.css";
import {
  useActiveVacationSchedule,
  usePlanningVacationSchedule,
  useVacationSchedule,
} from "../../../hooks/useVacationSchedule";
import {
  activeVacationColumns,
  activeVacationRows,
  planningVacationColumns,
  planningVacationRows,
} from "./vacationSchedulMock";

const VacationSchedule = () => {
  const [planningRowData, setPlanningRowData] = useState(null);
  const [activeRowData, setActiveRowData] = useState(null);
  const {
    years,
    value,
    header,
    staffId,
    setStaffId,
    structureId,
    selectedYear,
    allStructures,
    setPositionId,
    nestedChildren,
    planningToogle,
    setStructureId,
    handleChangeTab,
    handleChangeYear,
    setPlanningToogle,
    setContractPosition,
  } = useVacationSchedule();

  const {
    planningLimit,
    planningOffset,
    planningFilters,
    planningLoading,
    handleFindMonth,
    showDaysCalendar,
    setPlanningLimit,
    selectedNameMonth,
    setPlanningOffset,
    selectedIndexMonth,
    setPlanningLoading,
    setPlanningFilters,
    setShowDaysCalendar,
  } = usePlanningVacationSchedule(selectedYear);

  const {
    activeLimit,
    activeOffset,
    activeFilters,
    activeLoading,
    setActiveLimit,
    setActiveOffset,
    setActiveFilters,
    setActiveLoading,
    handleActiveFindMonth,
    showActiveDaysCalendar,
    selectedActiveNameMonth,
    selectedActiveIndexMonth,
    setShowActiveDaysCalendar,
  } = useActiveVacationSchedule();

  return (
    <TabContext value={value}>
      <Grid2 sx={{ width: "100%", marginBottom: "50px" }}>
        <Header data={header} />
      </Grid2>
      <Box sx={{ marginBottom: "24px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TabList aria-label="lab API tabs example" onChange={handleChangeTab}>
            <Tab label="Planlama" value="0" />
            <Tab label="Aktiv məzuniyyətlər" value="1" />
          </TabList>
          {value === "0" && (
            <Grid2
              sx={{
                display: "flex",
                alignItems: "center",
                width: "23.75vw",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => setPlanningToogle(!planningToogle)}
              >
                {planningToogle ? (
                  <Box sx={{ display: "flex", gap: "7px" }}>
                    <Box
                      component="img"
                      sx={{
                        height: 24,
                        width: 40,
                        objectFit: "cover",
                      }}
                      alt="switchOn"
                      src="/static/img/vacations/switchOn.png"
                    />
                    <p className={styles.toggleText}>Planlama aktivdir</p>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", gap: "7px" }}>
                    <Box
                      component="img"
                      sx={{
                        height: 24,
                        width: 40,
                        objectFit: "cover",
                      }}
                      alt="switchOff"
                      src="/static/img/vacations/switchOff.png"
                    />
                    <p className={styles.toggleText}>Planlama bağlıdır</p>
                  </Box>
                )}
              </Button>
              <FormControl sx={{ width: "50%" }}>
                <select
                  name="authorizedPerson"
                  label="Səlahiyyətli şəxs"
                  className={styles.authorizedPerson}
                  onChange={(e) => {
                    const value = e.target.value;
                    setStructureId(value?._id);
                  }}
                >
                  <option hidden>Səlahiyyətli şəxs</option>
                  {allStructures.map(
                    (structure, index) =>
                      structure?.organization?.name && (
                        <option key={index} value={structure}>
                          {structure?.organization?.name}
                        </option>
                      )
                  )}
                </select>
              </FormControl>
            </Grid2>
          )}
        </Box>
      </Box>
      <Grid2 sx={{ width: "100%", display: "flex", gap: "12px" }}>
        <Grid2 sx={{ width: "22vw" }}>
          <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
            <InputLabel id="Təşkilat-label">Təşkilat</InputLabel>
            <Select
              name="Təşkilat"
              labelId="Təşkilat-label"
              label="Təşkilat"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value;
                setStructureId(value?._id);
              }}
            >
              {allStructures.map(
                (structure, index) =>
                  structure?.organization?.name && (
                    <MenuItem key={index} value={structure}>
                      {structure?.organization?.name}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
          <NestedSelect
            setStaffId={setStaffId}
            structureId={structureId}
            setPositionId={setPositionId}
            nestedChildren={nestedChildren}
            setContractPosition={setContractPosition}
          />
        </Grid2>
        <Grid2 sx={{ width: "11vw" }}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel>İl</InputLabel>
            <Select
              as={Select}
              label="İl"
              name="İl"
              fullWidth
              onChange={(e) => handleChangeYear(e.target?.value)}
            >
              {years.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>
      </Grid2>

      <TabPanel value="0">
        {staffId && selectedYear ? (
          <Grid2>
            {!showDaysCalendar && (
              <Grid2>
                <Typography
                  variant="h5"
                  className={styles.titleYear}
                  sx={{ width: "55vw" }}
                >{`İllik ${selectedYear}`}</Typography>
                <PlanningMonthTable
                  rowData={planningRowData}
                  selectedYear={selectedYear}
                  handleFindMonth={handleFindMonth}
                  selectedMonth={selectedIndexMonth}
                />
              </Grid2>
            )}

            {showDaysCalendar && (
              <Grid2 sx={{ padding: "0 22px 0 0" }}>
                <Grid2 sx={{ textAlign: "right", paddingRight: "40px" }}>
                  <Tooltip title="Aylıq cədvələ geri qayıt" followCursor>
                    <CalendarMonthIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => setShowDaysCalendar(false)}
                    />
                  </Tooltip>
                </Grid2>
                <Typography
                  variant="h5"
                  className={styles.titleYear}
                >{`${selectedNameMonth} ${selectedYear}`}</Typography>
                <PlanningDaysCalendar
                  year={selectedYear}
                  rowData={planningRowData}
                  month={selectedIndexMonth}
                />
              </Grid2>
            )}
            <Grid2 sx={{ marginTop: "100px" }}>
              <Tabel
                limit={planningLimit}
                offset={planningOffset}
                isLoading={planningLoading}
                data={planningVacationRows}
                setLimit={setPlanningLimit}
                setOffset={setPlanningOffset}
                columns={planningVacationColumns(setPlanningRowData)}
              />
            </Grid2>
          </Grid2>
        ) : (
          []
        )}
      </TabPanel>
      <TabPanel value="1">
        {staffId && selectedYear ? (
          <Grid2>
            {showActiveDaysCalendar ? (
              <Grid2 sx={{ padding: "0 22px 0 0" }}>
                <Grid2 sx={{ textAlign: "right", paddingRight: "40px" }}>
                  <Tooltip title="Aylıq cədvələ geri qayıt" followCursor>
                    <CalendarMonthIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => setShowActiveDaysCalendar(false)}
                    />
                  </Tooltip>
                </Grid2>
                <Typography
                  variant="h5"
                  className={styles.titleYear}
                >{`${selectedActiveNameMonth} ${selectedYear}`}</Typography>
                <ActiveDaysCalendar
                  year={selectedYear}
                  rowData={activeRowData}
                  month={selectedActiveIndexMonth}
                />
              </Grid2>
            ) : (
              <Grid2>
                <Typography
                  variant="h5"
                  className={styles.titleYear}
                  sx={{ width: "55vw" }}
                >{`İllik ${selectedYear}`}</Typography>
                <ActiveMonthTable
                  rowData={activeRowData}
                  selectedYear={selectedYear}
                  handleActiveFindMonth={handleActiveFindMonth}
                />
              </Grid2>
            )}
            <Grid2 sx={{ marginTop: "100px" }}>
              <Tabel
                limit={activeLimit}
                offset={activeOffset}
                isLoading={activeLoading}
                data={activeVacationRows}
                setLimit={setActiveLimit}
                setOffset={setActiveOffset}
                columns={activeVacationColumns(setActiveRowData)}
              />
            </Grid2>
          </Grid2>
        ) : (
          []
        )}
      </TabPanel>
    </TabContext>
  );
};

export default VacationSchedule;
