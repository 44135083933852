import { Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getVacationRequestsCount } from "../../../api/vacationRequests/vacationRequests";
import moment from "moment";

const DetailedRequestStatistics = () => {
  const initialStatistics = [
    {
      keyword: "pending",
      title: "Qərar gözlənilir",
      titleColor: "#4D9DF8",
      statistics: "Yüklənir...",
      textColor: "#1262DA",
      backgroundColor: "#B3CEEC",
    },
    {
      keyword: "approved",
      title: "Təsdiq olunub",
      titleColor: "#008D0E",
      statistics: "Yüklənir...",
      textColor: "#055B0D",
      backgroundColor: "#ACDCB1",
    },
    {
      keyword: "rejected",
      title: "İmtina olunub",
      titleColor: "#D32F2F",
      statistics: "Yüklənir...",
      textColor: "#D32F2F",
      backgroundColor: "#FFA4A4",
    },
    {
      keyword: "cancelled",
      title: "Ləğv olunub",
      titleColor: "#530000",
      statistics: "Yüklənir...",
      textColor: "#530000",
      backgroundColor: "#D68D8D",
    },
    {
      keyword: "updated",
      title: "Yeniləndi",
      titleColor: "#8B25F1",
      statistics: "Yüklənir...",
      textColor: "#8B25F1",
      backgroundColor: "#C8A4EB",
    },
    {
      keyword: "resendToManager",
      title: "Yönləndirilib rəhbərə",
      titleColor: "#F57C00",
      statistics: "Yüklənir...",
      textColor: "#F57C00",
      backgroundColor: "#F8CFA6",
    },
    {
      keyword: "resendToEmployee",
      title: "Yönləndirilib əməkdaşa",
      titleColor: "#CBAEE8",
      statistics: "Yüklənir...",
      textColor: "#8B25F1",
      backgroundColor: "#EDE1F9",
    },
  ];

  var date = new Date();
  var firstDay = moment(
    new Date(date.getFullYear(), date.getMonth(), 1)
  ).format("MM/DD/yyyy");
  var lastDay = moment(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  ).format("MM/DD/yyyy");

  const [vacationStatistics, setVacationStatistics] =
    useState(initialStatistics);

  const getAllVacationsStatistics = async () => {
    try {
      const response = await getVacationRequestsCount(
        "",
        "",
        "VacationRequest",
        firstDay,
        lastDay
      );

      if (response && response.data) {
        const updatedStatistics = initialStatistics.map((statistic) => {
          const apiValue = response.data.byStatus[statistic.keyword];

          return {
            ...statistic,
            statistics:
              apiValue !== undefined ? apiValue.toString() : "Yüklənir...",
          };
        });
        setVacationStatistics(updatedStatistics);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    getAllVacationsStatistics();
  }, []);

  return (
    <CardContent>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h4" width={"100%"} textAlign={"center"}>
          Məzuniyyət sorğularının ümumi statistikası
        </Typography>
        {vacationStatistics.map((item, index) => (
          <Grid key={index}>
            <Grid
              sx={{
                height: "62px",
                width: "164px",
                borderRadius: "5px",
                backgroundColor: item.titleColor,
                fontSize: "20px",
                fontWeight: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
                mt: 3,
              }}
            >
              {item.title}
            </Grid>
            <Grid
              sx={{
                width: "164px",
                height: "84px",
                borderRadius: "5px",
                backgroundColor: item.backgroundColor,
                color: item.textColor,
                fontSize: "32px",
                lineHeight: "22px",
                fontWeight: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item.statistics}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </CardContent>
  );
};

export default DetailedRequestStatistics;
