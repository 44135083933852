import React from "react";
import {
  ContentCopy,
  PlayArrow,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";
import role_icon from "../../assets/icons/role-model.png";
import Chips from "../layouts/chips/Chips";

const RoleCard = ({ role, deleteRole, editRole }) => {
  const theme = useTheme();
  const users = [
    { id: 1, img: "https://i.pravatar.cc/40?img=1" },
    { id: 2, img: "https://i.pravatar.cc/40?img=2" },
    { id: 3, img: "https://i.pravatar.cc/40?img=3" },
    { id: 4, img: "https://i.pravatar.cc/40?img=3" },
    { id: 5, img: "https://i.pravatar.cc/40?img=3" },
    { id: 6, img: "https://i.pravatar.cc/40?img=4" },
  ];
  return (
    <Card
      sx={{
        backgroundColor: "rgba(42, 56, 137, 0.40)",
        borderRadius: 5,
        transition: "0.3s",
        "&:hover": {
          boxShadow: 6,
          transform: "scale(1.02)",
          backgroundColor: "rgba(42, 56, 137, 0.60)",
        },
      }}
    >
      <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <div className="text-secondary">
            Total {role?.userCount} istifadəçi
          </div>
        </Box>
        {/* <Stack direction="row" spacing={1}>
          <Chip avatar={<Avatar>M</Avatar>} label="Avatar" />
          <Chip
            avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
            label="Avatar"
            variant="outlined"
          />
        </Stack> */}
        {/* <AvatarGroup
          max={3}
          sx={{
            "& .MuiAvatar-root": { width: 40, height: 40, fontSize: "14px" },
            "& .MuiAvatar-root:last-child": {
              backgroundColor: "#ddd",
              color: "#333",
              fontWeight: "bold",
              fontSize: "14px",
            },
          }}
        >
          {users.map((user) => (
            <Avatar key={user.id} src={user.img} />
          ))}
        </AvatarGroup> */}
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "100%" }}>
          <div>
            <b>{role?.title?.az}</b>
          </div>
          <div>
            <span className="text-secondary">{role?.description?.az}</span>
          </div>
          <div className="d-flex mt-3">
            <Button
              variant="contained"
              size="small"
              className="mt-2"
              fullWidth
              color="success"
              onClick={() => editRole(role?._id)}
            >
              Redaktə et
            </Button>
            <Button
              variant="contained"
              size="small"
              className="mt-2 ms-2"
              color="error"
              fullWidth
              onClick={() => deleteRole(role?._id)}
            >
              Sil
            </Button>
          </div>
        </Box>
        {/* <Button size="medium" color="primary" variant="text">
          <ContentCopy />
        </Button> */}
      </CardActions>
    </Card>
  );
};

export default RoleCard;
