import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { addCommonData, getCommonData } from "../../api/api";
import { handleUniqueness } from "../employees/Validations";
import { getCompanyStructures } from "../../services/structure/StructureServices";

const pinRegex = /^[a-hj-np-zA-HJ-NP-Z0-9]*$/;

const AddCommonDataDialog = ({
  openAddDialog,
  setOpenAddDialog,
  state,
  dispatch,
  selected_type,
  structureDatas,
  currStructure,
}) => {
  const { selectedType, submitted } = state;
  const [checkErrors, setCheckErrors] = useState({
    name: false,
    lastname: false,
    fathername: false,
    pin: false,
    type: false,
    value: false,
    vacation: false,
    gender: false,
  });
  const [name, setName] = useState("");

  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [fathername, setFathername] = useState("");
  const [pin, setPin] = useState("");

  const [socialValue, setSocialValue] = useState("");
  const [wageType, setWageType] = useState("number");
  const [reason, setReason] = useState("");
  const [itemTypes, setItemTypes] = useState([]);
  const [itemType, setItemType] = useState("");
  const [vacationDays, setVacationDays] = useState("");
  const addWorkValidation = (selected_type) => {
    let baseSchema = Yup.object({
      name: Yup.string()
        .required("Ad daxil edilməlidir")
        .max(150, "maksimum 150 simvol daxil edilə bilər"),
      type: Yup.string().required("Tipi daxil edilməlidir"),
      value: Yup.string().required("Dəyəri daxil edilməlidir"),
      vacation: Yup.string().required("Məzuniyyət daxil edilməlidir"),
      reason: Yup.string().required("Səbəbi daxil edilməlidir"),
    });

    if (selected_type !== "İş şəraitinə görə əlavələr") {
      baseSchema = baseSchema.shape({
        lastname: Yup.string().required("Soyad daxil edilməlidir"),
        gender: Yup.string().required("Cinsi daxil edilməlidir"),
        fathername: Yup.string().required("Ata adı daxil edilməlidir"),
        pin: Yup.string()
          .min(6, "FIN nömrəsi minimum 6 simvol olmalıdır")
          .max(7, "FIN nömrəsi maksimum 7 simvol olmalıdır")
          .matches(pinRegex, "FIN nömrəsi düzgün deyil")
          .required("FIN nömrəsi daxil edilməlidir")
          .test(
            "check-pin",
            "Bu PIN artıq istifadə edilib",
            async function (value) {
              const response = await handleUniqueness("pin", value);
              return response;
            }
          ),
      });
    }

    return baseSchema;
  };

  const isValidInput = (input) => {
    const regex = /^[a-zA-Z0-9ƏəİıÖöÜüÇçŞşĞğ\s\S]*$/;
    return regex.test(input);
  };

  const isDisabled = (key) => {
    if (key === "recruiterList") {
      // Check conditions for "recruiterList"
      return !(
        name?.trim() &&
        lastname?.trim() &&
        gender?.trim() &&
        fathername?.trim() &&
        pin?.trim() &&
        isValidInput(pin?.trim())
      );
    } else if (
      selectedType[0] === "socialStatusTypes" ||
      selectedType[0] === "overtimeRules" ||
      selectedType[0] === "academicDegrees" ||
      selectedType[0] === "supplementalWageLabour" ||
      selectedType[0] === "supplementalWageLabourItems"
    ) {
      return !(
        name?.trim() &&
        isValidInput(name.trim()) &&
        Boolean(socialValue?.trim())
      );
      // return true;
    } else {
      // Default case or any other case
      return (
        !(name?.trim() || isValidInput(name?.trim())) ||
        (selected_type === "İş şəraitinə görə əlavələr" &&
          Object?.values(checkErrors)?.some((error) => error))
      );
    }
  };

  const handleAddCommonData = async (key, name, value) => {
    let payload = [];
    if (
      key === "socialStatusTypes" ||
      key === "overtimeRules" ||
      key === "academicDegrees"
    ) {
      payload = [{ key: key, values: [{ name: name, value: Number(value) }] }];
    } else if (key === "supplementalWageLabour") {
      payload = [
        {
          key: key,
          values: [
            {
              name: name,
              value: Number(value),
              reason: reason,
              valueType: wageType,
            },
          ],
        },
      ];
    } else if (key === "supplementalWageLabourItems") {
      payload = [
        {
          key: key,
          values: [
            {
              name: name,
              value: Number(value),
              reason: reason,
              valueType: wageType,
              parent: itemType,
              vacationDays: Number(vacationDays),
            },
          ],
        },
      ];
    } else if (key === "inventoryItems" || key === "carModels") {
      payload = [{ key: key, values: [{ name: name, parent: itemType }] }];
    } else if (key === "recruiterList") {
      payload = [
        {
          key: key,
          values: [
            {
              name: name,
              lastname: lastname,
              gender: gender,
              fathername: fathername,
              pin: pin,
              structureId: structureDatas?.structures?.find(
                (s) => s?.organization?.name === currStructure?.state
              )?._id,
            },
          ],
        },
      ];
    } else {
      payload = [{ key: key, values: [name] }];
    }
    try {
      let resp = await addCommonData(payload);
    } catch (error) {
      console.log("error");
    }
    setItemType("");
    setName();
    setLastname();
    setGender();
    setFathername();
    setPin();
    setSocialValue();
    setReason();
    setVacationDays();
    setOpenAddDialog(false);

    dispatch({ type: "SET_SUBMITTED", payload: !submitted });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddCommonData(selectedType[0], name, socialValue);
  };
  const getInventoryTypes = async () => {
    if (selectedType[0] === "inventoryItems") {
      try {
        const response = await getCommonData("inventoryTypes");
        setItemTypes(response.data);
      } catch (error) {
        console.log(error);
      }
    } else if (selectedType[0] === "carModels") {
      try {
        const response = await getCommonData("carManufacturers");
        setItemTypes(response.data);
      } catch (error) {
        console.log(error);
      }
    } else if (selectedType[0] === "supplementalWageLabourItems") {
      try {
        const response = await getCommonData("supplementalWageLabourItems");
        const types = ["Ağır Zərərli", "Zərərli"];

        setItemTypes(types);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const checkErrors = async () => {
      // const checkPin = async (value) => {
      //   if (!value || value.trim() === "")
      //     return "FIN nömrəsi daxil edilməlidir";
      //   else if (value.length < 6)
      //     return "FIN nömrəsi minimum 6 simvol olmalıdır";
      //   else if (value.length > 7)
      //     return "FIN nömrəsi maksimum 7 simvol olmalıdır";
      //   else if (!pinRegex.test(value)) return "FIN nömrəsi düzgün deyil";
      //   else if (await !handleUniqueness("pin", value))
      //     return "Bu FİN artıq istifadə edilib";
      //   else return false;
      // };

      const newErrors = {
        name: !name || name.trim() === "",
        type: !itemType || itemType.trim() === "",
        value: !socialValue || socialValue.trim() === "",
        vacation: !vacationDays || vacationDays.trim() === "",
        lastname:
          selected_type !== "İş şəraitinə görə əlavələr"
            ? !lastname || lastname.trim() === ""
            : false,
        gender:
          selected_type !== "İş şəraitinə görə əlavələr"
            ? !gender || gender.trim() === ""
            : false,
        fathername:
          selected_type !== "İş şəraitinə görə əlavələr"
            ? !fathername || fathername.trim() === ""
            : false,
      };
      setCheckErrors(newErrors);
    };

    checkErrors();
  }, [
    name,
    itemType,
    socialValue,
    vacationDays,
    lastname,
    gender,
    fathername,
    pin,
    selected_type,
  ]);
  useEffect(() => {
    getInventoryTypes();
  }, [selectedType]);

  function handleInputChange(field, value, setFieldValue, setValue) {
    const trimmedValue = value.trimStart();
    setFieldValue(field, trimmedValue);
    setValue(trimmedValue);
  }
  return (
    <>
      <Dialog
        fullWidth
        open={openAddDialog}
        onClose={() => {
          setOpenAddDialog(false);
          setItemType("");
          setName("");
          setLastname("");
          setGender("");
          setFathername("");
          setPin("");
          setSocialValue("");
          setReason("");
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">{selectedType[1].name} əlavə et</Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: "",
              type: "",
              value: "",
              vacation: "",
              reason: "",
              lastname: "",
              gender: "",
              fathername: "",
              pin: "",
            }}
            validationSchema={addWorkValidation}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              setErrors,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>
                {console.log(isValid)}
                <Field
                  as={TextField}
                  autoFocus
                  margin="dense"
                  id="name"
                  value={name}
                  name="name"
                  label="Adı *"
                  type="text"
                  onChange={(e) =>
                    handleInputChange(
                      "name",
                      e.target.value,
                      setFieldValue,
                      setName
                    )
                  }
                  fullWidth
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />

                {selectedType[0] === "recruiterList" ? (
                  <>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        margin="dense"
                        id="lastname"
                        name="lastname"
                        value={lastname}
                        label="Soyadı *"
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        // onChange={(e) => {
                        //   const value = e.target.value.trimStart();
                        //   setFieldValue("lastname", value);
                        //   setLastname(value);
                        // }}
                        onChange={(e) =>
                          handleInputChange(
                            "lastname",
                            e.target.value,
                            setFieldValue,
                            setLastname
                          )
                        }
                        error={touched.lastname && Boolean(errors.lastname)}
                        helperText={touched.lastname && errors.lastname}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        margin="dense"
                        id="fathername"
                        name="fathername"
                        value={fathername}
                        label="Ata adı *"
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        // onChange={(e) => {
                        //   setFathername(e.target.value);
                        // }}
                        onChange={(e) =>
                          handleInputChange(
                            "fathername",
                            e.target.value,
                            setFieldValue,
                            setFathername
                          )
                        }
                        error={touched.fathername && Boolean(errors.fathername)}
                        helperText={touched.fathername && errors.fathername}
                      />
                      {errors.fathername ? (
                        <ErrorMessage
                          name="fatername"
                          className="error_work"
                          component="span"
                          style={{ marginBottom: "10px" }}
                        />
                      ) : null}
                    </FormControl>
                    <FormControl
                      error={touched.gender && Boolean(errors.gender)}
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      <InputLabel>Cins *</InputLabel>
                      <Field
                        as={Select}
                        margin="dense"
                        id="gender"
                        name="gender"
                        value={gender}
                        label="Cins *"
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        error={touched.gender && Boolean(errors.gender)}
                        helperText={touched.gender && errors.gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                          setFieldValue("gender", e.target.value);
                        }}
                      >
                        <MenuItem value={"male"}>Kişi</MenuItem>
                        <MenuItem value={"female"}>Qadın</MenuItem>
                      </Field>
                      {errors.gender ? (
                        <ErrorMessage
                          name="gender"
                          className="error_work"
                          component="span"
                          style={{ marginBottom: "10px" }}
                        />
                      ) : null}
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        margin="dense"
                        id="pin"
                        name="pin"
                        value={pin}
                        label="FİN *"
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          setPin(e.target.value);
                          setFieldValue("pin", e.target.value);
                        }}
                        error={touched.pin && Boolean(errors.pin)}
                        helperText={touched.pin && errors.pin}
                      />
                      {/* {errors?.pin?.length > 0 ? (
                        <span style={{ fontSize: "12px", color: "#f44336" }}>
                          {errors.pin}
                        </span>
                      ) : null} */}
                    </FormControl>
                    <FormControl fullWidth className="mt-3">
                      <InputLabel>Strukturu əlavə et</InputLabel>
                      <Select
                        margin="dense"
                        id="structure"
                        name="structure"
                        sx={{ mb: 3 }}
                        defaultValue={currStructure?.state}
                        label="Strukturu əlavə et"
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          currStructure?.set(e.target.value);
                        }}
                      >
                        {structureDatas?.structures.map((structure) => (
                          <MenuItem
                            key={structure?.organization?.name}
                            value={structure?.organization?.name}
                          >
                            {structure?.organization?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                ) : null}
                {(selectedType[0] === "inventoryItems" ||
                  selectedType[0] === "carModels" ||
                  selectedType[0] === "supplementalWageLabourItems") && (
                  <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel id={`type`}>Tipi *</InputLabel>
                    <Field
                      as={Select}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="type"
                      value={itemType}
                      label="Age"
                      onChange={(e) => {
                        setItemType(e.target.value);
                      }}
                    >
                      {itemTypes &&
                        itemTypes.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Field>
                    {errors.type ? (
                      <ErrorMessage
                        name="type"
                        className="error_work"
                        component="span"
                      />
                    ) : null}
                  </FormControl>
                )}

                {selectedType[0] === "socialStatusTypes" ||
                selectedType[0] === "overtimeRules" ||
                selectedType[0] === "academicDegrees" ||
                selectedType[0] === "supplementalWageLabour" ||
                selectedType[0] === "supplementalWageLabourItems" ? (
                  <Box sx={{ display: "flex", gap: 5 }}>
                    <FormControl fullWidth>
                      <Field
                        as={TextField}
                        margin="dense"
                        id="value"
                        name="value"
                        value={socialValue}
                        label="Dəyəri *"
                        type="number"
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          setSocialValue(e.target.value);
                        }}
                      />
                      {errors.value ? (
                        <ErrorMessage
                          name="value"
                          className="error_work"
                          component="span"
                        />
                      ) : null}
                    </FormControl>

                    {(selectedType[0] === "supplementalWageLabour" ||
                      selectedType[0] === "supplementalWageLabourItems") && (
                      <FormControl component="fieldset" variant="standard">
                        <RadioGroup
                          aria-label="position"
                          name="position"
                          value={wageType}
                          onChange={(e) => {
                            setWageType(e.target.value);
                          }}
                          row
                        >
                          <FormControlLabel
                            value="number"
                            control={<Radio color="primary" />}
                            label="Dəyər"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="percentage"
                            control={<Radio color="primary" />}
                            label="Faiz"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Box>
                ) : null}

                {selectedType[0] === "supplementalWageLabourItems" ? (
                  <FormControl fullWidth>
                    <Field
                      as={TextField}
                      margin="dense"
                      id="vacation"
                      name="vacation"
                      value={vacationDays}
                      label="Məzuniyyət günlərinin sayı *"
                      type="number"
                      onChange={(e) => setVacationDays(e.target.value)}
                    />
                    {errors.vacation ? (
                      <ErrorMessage
                        name="vacation"
                        className="error_work"
                        component="span"
                      />
                    ) : null}
                  </FormControl>
                ) : null}

                {selectedType[0] === "supplementalWageLabour" ||
                selectedType[0] === "supplementalWageLabourItems" ? (
                  <Field
                    as={TextField}
                    margin="dense"
                    id="reason"
                    name="reason"
                    label="Səbəbi"
                    type="text"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    fullWidth
                  />
                ) : null}

                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenAddDialog(false);
                      setName("");
                      setLastname("");
                      setGender("");
                      setFathername("");
                      setPin("");
                      setSocialValue("");
                      setWageType("");
                      setReason("");
                      setItemTypes("");
                      setItemType("");
                      setVacationDays("");
                    }}
                    color="primary"
                  >
                    Ləğv et
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={
                      isDisabled(selectedType[0]) ||
                      values?.name?.length > 150 ||
                      !values?.name ||
                      values?.pin?.length > 7 ||
                      values?.pin?.length < 6
                    }
                  >
                    Əlavə et
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCommonDataDialog;
