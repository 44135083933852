import { Button } from "@mui/material";

const CustomButton = ({
  bgColor,
  hoverBgColor,
  Icon,
  width,
  content,
  disabled = false,
  onClick = () => {},
}) => {
  // const useStyles = makeStyles(() => ({
  //   generalStyles: {
  //     textTransform: "capitalize",
  //   },
  //   buttonStyles: {
  //     backgroundColor: bgColor,
  //     borderRadius: "4px",
  //     color: "white",
  //     "&:hover": {
  //       backgroundColor: hoverBgColor,
  //     },
  //   },
  //   disableButtonStyles: {
  //     backgroundColor: "#AFAFAF !important",
  //     color: "#fff !important",
  //   },
  // }));

  return (
    <Button
      variant="contained"
      className={width ? width : "w-100"}
      startIcon={Icon && <Icon />}
      disabled={disabled}
      onClick={onClick}
      sx={{
        textTransform: "capitalize",
        backgroundColor: disabled ? "#AFAFAF" : bgColor,
        borderRadius: "4px",
        color: "white",
        "&:hover": {
          backgroundColor: disabled ? "#AFAFAF" : hoverBgColor,
        },
      }}
    >
      {content}
    </Button>
  );
};

export default CustomButton;
