import React from "react";
import { PlayArrow, SkipNext, SkipPrevious } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";
import role_icon from "../../assets/icons/role-model.png";

const NewRoleCard = ({ setRoleCreateDialog }) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        display: "flex",
        borderRadius: 5,
        // transition: "0.3s",
        // "&:hover": {
        //   boxShadow: 6,
        //   transform: "scale(1.02)",
        //   backgroundColor: "rgba(42, 56, 137, 0.60)",
        // },
      }}
    >
      <CardContent>
        <CardMedia
          component="img"
          height="140"
          image={role_icon}
          alt="green iguana"
        />
      </CardContent>
      <CardActions
        sx={{ display: "flex", justifyContent: "flex-end", width: "60%" }}
      >
        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={setRoleCreateDialog}
        >
          Yeni rol
        </Button>
      </CardActions>
    </Card>
  );
};

export default NewRoleCard;
