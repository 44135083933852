import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid2,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import {
  getEmployeeId_API_SERVICE,
  getFillingVacantPositionOrder_SERVICE,
} from "../../../../../services/ordersServices";
import NestedSelect from "../../../../../utils/NestedSelect";
import useOrders from "../../../../../hooks/useOrders";

const Divider = styled(MuiDivider)(spacing);

const SpecialityDegree = () => {
  const {
    data,
    loading,
    employee,
    navigate,
    setStaffId,
    isFormValid,
    structureId,
    commandData,
    contractData,
    handleSubmit,
    setPositionId,
    allStructures,
    setStructureId,
    nestedChildren,
    setContractName,
    handleInputChange,
    emptyPositionDatas,
    currentContractData,
    setContractPosition,
    handleEmployeeChange,
    handleCurrentContract,
    createFillingVacantPositionOrder,
  } = useOrders();
  useEffect(() => {
    setContractName("granting-profession-order");
  }, []);

  const [specialityDegrees, setSpecialityDegrees] = useState([]);

  useEffect(() => {
    setContractName("granting-profession-order");
  }, []);

  const handleAddSpecialityDegree = () => {
    setSpecialityDegrees([
      ...specialityDegrees,
      { id: specialityDegrees.length + 1 },
    ]);
  };

  return (
    <form>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "700px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <CardContent>
            <Grid2 sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => navigate("/documentation/orders")}
                variant="text"
                sx={{ color: "#fff" }}
              >
                {" "}
                <CloseIcon />
              </Button>
            </Grid2>
            <Typography variant="h3" textAlign="center" mt={5} mb={7}>
              İxtisas dərəcəsinin verilməsi əmri
            </Typography>
            <Divider my={6} />
            <Grid2 sx={{ display: "flex", gap: "25px" }} mb={4}>
              <Grid2 sx={{ width: "50%" }}>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <Autocomplete
                    fullWidth
                    options={data || []}
                    getOptionLabel={(option) =>
                      `${option?.firstname} ${option?.lastname} ${option?.fathername}` ||
                      ""
                    }
                    onChange={(event, newValue) => {
                      handleEmployeeChange(newValue?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Əməkdaş(A.S.A)"
                        variant="outlined"
                      />
                    )}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.id}>
                        {`${option?.firstname} ${option?.lastname} ${option?.fathername}`}
                      </MenuItem>
                    )}
                  />
                </FormControl>
                <Grid2
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "25px",
                    marginBottom: "16px",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="contract_number">
                      Müqavilə nömrəsi
                    </InputLabel>
                    <Select
                      name="contract_number"
                      labelId="Müqavilə nömrəsi"
                      label="Müqavilə nömrəsi"
                      fullWidth
                      variant="outlined"
                      onChange={(e) => handleCurrentContract(e.target.value)}
                      disabled={!employee.employeeId}
                    >
                      {contractData &&
                        contractData?.map((d, i) => (
                          <MenuItem
                            key={i}
                            value={d?.contract?.staffs?.[0]?._id}
                          >
                            {d?.contract?.data?.docNumber}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <DatePicker
                    my={2}
                    sx={{ width: "100%", pointerEvents: "none" }}
                    inputFormat="dd/MM/yyyy"
                    name="contractDate"
                    value={
                      currentContractData?.contract?.decisionDate
                        ? new Date(currentContractData?.contract?.decisionDate)
                        : null
                    }
                    label="Müqavilənin tarixi"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Grid2>
                <TextField
                  name="Tabel nömrəsi"
                  label="Tabel nömrəsi"
                  value={employee?.tabelNumber}
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  variant="outlined"
                  my={2}
                />
                <TextField
                  name="Təşkilat"
                  label="Təşkilat"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  my={2}
                  value={
                    currentContractData?.structure?.organization?.name || ""
                  }
                />
                <TextField
                  name="Struktur"
                  label="Struktur"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  my={2}
                  value={
                    currentContractData?.contract?.topPartOfStructures?.[0]
                      ?.source?.name || ""
                  }
                />
                <TextField
                  name="Substruktur"
                  label="Substruktur"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  my={2}
                  value={currentContractData?.part?.parent?.source?.name || ""}
                />
                <TextField
                  name="Vəzifə"
                  label="Vəzifə"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  my={2}
                  value={currentContractData?.part?.source.name || ""}
                />
              </Grid2>
              <Grid2 sx={{ width: "50%" }}>
                <Grid2
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    width: "100%",
                    gap: "25px",
                    marginBottom: "16px",
                  }}
                >
                  <TextField
                    name="İxtisas dərəcəsi"
                    label="İxtisas dərəcəsi"
                    sx={{ width: "100%", pointerEvents: "none" }}
                    variant="outlined"
                    my={2}
                  />
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="position">Yeni İxtisas dərəcəsi</InputLabel>
                    <Select
                      name="position"
                      label="Yeni İxtisas dərəcəsi"
                      fullWidth
                      variant="outlined"
                    >
                      <MenuItem value="employee1">
                        Yeni İxtisas dərəcəsi 1
                      </MenuItem>
                      <MenuItem value="employee2">
                        Yeni İxtisas dərəcəsi 2
                      </MenuItem>
                      <MenuItem value="employee3">
                        Yeni İxtisas dərəcəsi 3
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    sx={{ width: "30%", height: "35px" }}
                    aria-haspopup="true"
                    variant="outlined"
                    color="primary"
                    onClick={handleAddSpecialityDegree}
                  >
                    <AddIcon />
                    Əlavə et
                  </Button>
                </Grid2>
                {specialityDegrees.map((degree, index) => (
                  <TextField
                    key={degree.id}
                    name={`Yeni ixtisas dərəcəsinin adı ${index + 1}`}
                    label="Yeni ixtisas dərəcəsinin adı"
                    sx={{ width: "100%", marginBottom: "16px" }}
                    variant="outlined"
                    inputProps={{
                      maxLength: 100,
                    }}
                    my={2}
                  />
                ))}
                <TextField
                  name={`Əmək haqqıya əlavə`}
                  label="Əmək haqqıya əlavə"
                  sx={{ width: "100%", marginBottom: "16px" }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "+" ||
                      e.key === "-"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  // onChange={(e) => {
                  //   let value = e.target.value;
                  //   // Sadəcə nöqtə və rəqəmə icazə ver
                  //   if (!/^\d*\.?\d*$/.test(value)) {
                  //     return;
                  //   }

                  //   if (value.length <= 7) {
                  //     handleInputChange_emptyPosition("additionToSalary", value);
                  //   }
                  // }}
                  variant="outlined"
                  my={2}
                />
                <Grid2 sx={{ display: "flex", gap: "25px", width: "100%" }}>
                  <DatePicker
                    my={2}
                    sx={{ width: "50%" }}
                    inputFormat="dd/MM/yyyy"
                    label="Başlama tarixi"
                    minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                  <DatePicker
                    sx={{ width: "50%" }}
                    inputFormat="dd/MM/yyyy"
                    label="Bitmə tarixi"
                    minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2 mb={10}>
              <TextField
                name="note"
                value={emptyPositionDatas?.note}
                inputProps={{
                  maxLength: 500,
                }}
                label="Qeyd"
                fullWidth
                variant="outlined"
                my={2}
              />
            </Grid2>
            <Divider my={6} />
            <Grid2
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "25px",
              }}
            >
              <Button
                title="İmtina et"
                variant="contained"
                sx={{ height: "100%" }}
                color="error"
                onClick={() => navigate("/documentation/orders")}
              >
                <p
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Geri
                </p>
              </Button>
              <Button
                type="submit"
                title="Kənarlaşdırma yarat"
                variant="contained"
                sx={{ height: "100%" }}
              >
                <p
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Yadda saxla
                </p>
              </Button>
            </Grid2>
          </CardContent>
        )}
      </Card>
    </form>
  );
};

export default SpecialityDegree;
