import moment from "moment";
import Column from "../../../../models/table/Column";
import * as Yup from "yup";
import { OPERATORS_CONTAINING } from "../../../../constants";
import { GROUP_NAME } from "../../../../components/dialogs/documentDialog/dialogConstants";
import { translationMap } from "../../../../components/dialogs/documentDialog/helpers";
import DocumentDialog from "../../../../components/dialogs/documentDialog/DocumentDialog";
import { GENERAL_BLOCK } from "../../../../styled";
// SERVICES
import { getLabourContractDoc_API } from "../../../../api/documentRequests";

export function empConstInitialValues(oldData) {
  const convertedOffDays = (oldData?.offDays || []).map((dayName) => {
    const matchingDay = weekDays.find((day) => day.name === dayName);
    return matchingDay ? matchingDay.key : dayName;
  });
  return {
    labourContractChangeDate: "",
    createOrder: "",
    informationAboutParties: {
      empWorkPlaceType: oldData?.empWorkPlaceType || "",
    },
    labourPeriod: {
      contractPeriodType: oldData?.contractPeriodType || "",
      probationPeriodType: oldData?.probationPeriodType || "",
      probationPeriod: oldData?.probationPeriod || "",
      reasonOfTemporaryContract: oldData?.reasonOfTemporaryContract || "",
      contractStartDate: oldData?.contractStartDate || "",
      contractEndDate: oldData?.contractEndDate || "",
      jobStartDate: oldData?.jobStartDate || "",
      laborFunctions: oldData?.laborFunctions || [],
    },
    wage: {
      paymentOfWork: oldData?.paymentOfWork || "",
      wage: oldData?.wage || "",
      bonusPeriod: oldData?.bonusPeriod || "",
      givenBonus: oldData?.givenBonus || "",
      partTimeWorkHour: oldData?.partTimeWorkHour || "",
      overtime: oldData?.overtime || "",
      workingCondition: {
        name: oldData?.workingCondition?.name || "",
        parent: oldData?.workingCondition?.parent || "",
        reason: oldData?.workingCondition?.reason || "",
        vacationDays: oldData?.workingCondition?.vacationDays || "",
        value: oldData?.workingCondition?.value || "",
        valueType: oldData?.workingCondition?.valueType || "",
      },
      selectedBank: oldData?.selectedBank || "",
      conditionsOfSalaryPayment: oldData?.conditionsOfSalaryPayment || "",
      paymentPeriod: oldData?.paymentPeriod || "",
      paymentPeriodDate: oldData?.paymentPeriodDate || "",
      freelanceWorkIncomeType: oldData?.freelanceWorkIncomeType || "",
      freelanceWorkIncome: oldData?.freelanceWorkIncome || "",
    },
    protection: {
      providedProtectiveEquipment: oldData?.providedProtectiveEquipment || [],
      providedFood: oldData?.providedFood || [],
      instructionalPeriod: oldData?.instructionalPeriod || "",
    },
    workVacationTime: {
      workingMode: oldData?.workingMode || "",
      offDays: convertedOffDays,
      workScheduleId: oldData?.workScheduleId || "",
      workScheduleAsText: oldData?.workScheduleAsText || "",
      assignedWorkTurnValue: oldData?.assignedWorkTurnValue || "",
    },
    vacation: {
      vacationDaysCount: oldData?.vacationDaysCount || "",
      forSeniorityLevel: oldData?.forSeniorityLevel || "",
      forParental: oldData?.forParental || "",
      forCollectiveAgreement: oldData?.forCollectiveAgreement || "",
      socialHouseholdBenefit: oldData?.socialHouseholdBenefit || "",
      aboutEventOrPayer: oldData?.aboutEventOrPayer || "",
    },
    physicalEducationSport: {
      physicalEducationAndSportsConditions:
        oldData?.physicalEducationAndSportsConditions || "",
      otherAdditionalConditions: oldData?.otherAdditionalConditions || "",
    },
    socialProtection: {
      monthlyInsuranceFee: oldData?.monthlyInsuranceFee || "",
      extraSumInsured: oldData?.extraSumInsured || "",
    },
    regulationPropertyRelation: {
      shareOwnershipOfEnterprise: oldData?.shareOwnershipOfEnterprise || "",
      dividendAmount: oldData?.dividendAmount || "",
      personalPropertiesUsedByEmployee:
        oldData?.personalPropertiesUsedByEmployee || [],
    },
    administrative: {
      oldRecruiterId: oldData?.recruiterId || "",
    },
    jobRelated: {
      staffId: oldData?.staffId || "",
      oldStructureAsStr: "",
      newStructureAsStr: "",
      oldPositionAsStr: "",
      newPositionAsStr: "",
    },
  };
}

export const weekDays = [
  { key: "monday", name: "Bazar ertəsi" },
  { key: "tuesday", name: "Çərşənbə axşamı" },
  { key: "wednesday", name: "Çərşənbə" },
  { key: "thursday", name: "Cümə axşamı" },
  { key: "friday", name: "Cümə" },
  { key: "saturday", name: "Şənbə" },
  { key: "sunday", name: "Bazar" },
];

export const empConstTranslationMap = {
  day: "gün",
  week: "həftə",
  month: "ay",
  year: "il",
  term: "müddətli",
  indefinite: "müddətsiz",
  monday: "Bazar ertəsi",
  tuesday: "Çərşənbə axşamı",
  wednesday: "Çərşənbə",
  thursday: "Cümə axşamı",
  friday: "Cümə",
  saturday: "Şənbə",
  sunday: "Bazar",
  time: "Vaxtamuzd",
  work: "İşəmuzd",
  monthly: "aylıq",
  amount: "məbləğ",
  rule: "qayda",
  quarterly: "rüblük",
  halfyearly: "yarım illik",
  yearly: "illik",
  none: "yoxdur",
};

export const empGeneralConstInitialValues = {
  labourContractChangeDate: "",
  informationAboutParties: {
    empWorkPlaceType: "",
  },
  labourPeriod: {
    contractPeriodType: "",
    probationPeriodType: "",
    probationPeriod: "",
    reasonOfTemporaryContract: "",
    contractStartDate: "",
    contractEndDate: "",
    jobStartDate: "",
    laborFunctions: [],
  },
  wage: {
    paymentOfWork: "",
    wage: "",
    bonusPeriod: "",
    givenBonus: "",
    partTimeWorkHour: "",
    overtime: "",
    workingCondition: {
      name: "",
      parent: "",
      reason: "",
      vacationDays: "",
      value: "",
      valueType: "",
    },
    selectedBank: "",
    conditionsOfSalaryPayment: "",
    paymentPeriod: "",
    paymentPeriodDate: "",
    freelanceWorkIncomeType: "",
    freelanceWorkIncome: "",
  },
  protection: {
    providedProtectiveEquipment: [],
    providedFood: [],
    instructionalPeriod: "",
  },
  workVacationTime: {
    workingMode: "",
    offDays: [],
    workScheduleId: "",
    workScheduleAsText: "",
    assignedWorkTurnValue: "",
  },
  vacation: {
    vacationDaysCount: "",
    forSeniorityLevel: "",
    forParental: "",
    forCollectiveAgreement: "",
    socialHouseholdBenefit: "",
    aboutEventOrPayer: "",
  },
  physicalEducationSport: {
    physicalEducationAndSportsConditions: "",
    otherAdditionalConditions: "",
  },
  socialProtection: {
    monthlyInsuranceFee: "",
    extraSumInsured: "",
  },
  regulationPropertyRelation: {
    shareOwnershipOfEnterprise: "",
    dividendAmount: "",
    personalPropertiesUsedByEmployee: [],
  },
};

export function empConstModifeValue(values, selectedCont, oldData) {
  const isWeekDaysEqual = values?.workVacationTime?.offDays.every((item) => {
    const existsInNew = oldData?.offDays.includes(translationMap[item]);
    return existsInNew;
  });
  const isLaborFunctionsEqual = values?.labourPeriod?.laborFunctions.every(
    (item) => {
      const existsInNew = oldData?.laborFunctions.includes(item);
      return existsInNew;
    }
  );
  const isProvidedProtectiveEquipmentEqual =
    oldData?.providedProtectiveEquipment.every((item) => {
      const existsInNew = Array.isArray(
        values?.protection?.providedProtectiveEquipment
      )
        ? values?.protection?.providedProtectiveEquipment.includes(item)
        : values?.protection?.providedProtectiveEquipment === item;
      return existsInNew;
    });
  const isProvidedFoodEqual = oldData?.providedFood.every((item) => {
    const existsInNew = Array.isArray(values?.protection?.providedFood)
      ? values?.protection?.providedFood.includes(item)
      : values?.protection?.providedFood === item;
    return existsInNew;
  });
  const isOtherAdditionalConditionsEqual =
    Array.isArray(oldData?.otherAdditionalConditions) &&
    oldData?.otherAdditionalConditions.length > 0
      ? oldData.otherAdditionalConditions.every((item) => {
          const existsInNew =
            Array.isArray(
              values?.physicalEducationSport?.otherAdditionalConditions
            ) &&
            values.physicalEducationSport.otherAdditionalConditions.includes(
              item
            );
          return existsInNew;
        })
      : false;

  const isPersonalPropertiesUsedByEmployeeEqual =
    oldData?.personalPropertiesUsedByEmployee.length > 0
      ? oldData?.personalPropertiesUsedByEmployee.every((item) => {
          const existsInNew =
            values?.regulationPropertyRelation?.personalPropertiesUsedByEmployee.includes(
              item
            );
          return existsInNew;
        })
      : false;

  const isEqual =
    oldData?.workingCondition?.name === values?.wage?.workingCondition?.name &&
    oldData?.workingCondition?.parent ===
      values?.wage?.workingCondition?.parent;

  const allValuesEmpty = Object.values(values?.wage?.workingCondition).every(
    (v) => v === ""
  );

  return {
    staffs: Array.isArray(selectedCont) ? selectedCont : [selectedCont],
    labourContractChangeDate: values.labourContractChangeDate
      ? moment(values?.labourContractChangeDate).format("MM/DD/yyyy")
      : "",
    createOrder: `${values?.createOrder}`,
    updates: [
      {
        section: "informationAboutParties",
        data: {
          empWorkPlaceType:
            oldData?.empWorkPlaceType !==
              values?.informationAboutParties?.empWorkPlaceType &&
            values?.informationAboutParties?.empWorkPlaceType
              ? values?.informationAboutParties?.empWorkPlaceType
              : "",
        },
      },
      {
        section: "labourPeriod",
        data: {
          contractPeriodType:
            oldData?.contractPeriodType !==
              values?.labourPeriod?.contractPeriodType &&
            values?.labourPeriod?.contractPeriodType
              ? values?.labourPeriod?.contractPeriodType
              : "",
          probationPeriodType:
            oldData?.probationPeriodType !==
              values?.labourPeriod?.probationPeriodType &&
            values?.labourPeriod?.probationPeriodType
              ? values?.labourPeriod?.probationPeriodType
              : "",
          probationPeriod:
            oldData?.probationPeriod !==
              parseInt(values?.labourPeriod?.probationPeriod) &&
            !isNaN(parseInt(values?.labourPeriod?.probationPeriod))
              ? parseInt(values?.labourPeriod?.probationPeriod)
              : "",
          reasonOfTemporaryContract:
            oldData?.reasonOfTemporaryContract !==
              values?.labourPeriod?.reasonOfTemporaryContract &&
            values?.labourPeriod?.contractPeriodType !== "indefinite" &&
            !isNaN(values?.labourPeriod?.reasonOfTemporaryContract)
              ? values?.labourPeriod?.reasonOfTemporaryContract
              : "",
          contractStartDate:
            oldData?.contractStartDate !==
              values?.labourPeriod?.contractStartDate &&
            values?.labourPeriod?.contractStartDate
              ? moment(values?.labourPeriod?.contractStartDate).format(
                  "MM/DD/yyyy"
                )
              : "",
          contractEndDate:
            oldData?.contractEndDate !==
              values?.labourPeriod?.contractEndDate &&
            values?.labourPeriod?.contractPeriodType !== "indefinite" &&
            values?.labourPeriod?.contractEndDate
              ? moment(values?.labourPeriod?.contractEndDate).format(
                  "MM/DD/yyyy"
                )
              : "",
          jobStartDate:
            oldData?.jobStartDate !== values?.labourPeriod?.jobStartDate &&
            values?.labourPeriod?.jobStartDate
              ? moment(values?.labourPeriod?.jobStartDate).format("MM/DD/yyyy")
              : "",
          laborFunctions: isLaborFunctionsEqual
            ? ""
            : values?.labourPeriod?.laborFunctions.length > 0
            ? values?.labourPeriod?.laborFunctions
            : "",
        },
      },
      {
        section: "wage",
        data: {
          paymentOfWork:
            oldData?.paymentOfWork !== values?.wage?.paymentOfWork &&
            values?.wage?.paymentOfWork
              ? values?.wage?.paymentOfWork
              : "",
          wage:
            oldData?.wage !== parseFloat(values?.wage?.wage) &&
            !isNaN(parseFloat(values?.wage?.wage))
              ? parseFloat(values?.wage?.wage)
              : "",
          bonusPeriod:
            oldData?.bonusPeriod !== values?.wage?.bonusPeriod &&
            values?.wage?.bonusPeriod
              ? values?.wage?.bonusPeriod
              : "",
          givenBonus:
            oldData?.givenBonus !== parseFloat(values?.wage?.givenBonus) &&
            !isNaN(parseFloat(values?.wage?.givenBonus))
              ? parseFloat(values?.wage?.givenBonus)
              : "",
          partTimeWorkHour:
            oldData?.partTimeWorkHour !==
              parseInt(values?.wage?.partTimeWorkHour) &&
            !isNaN(parseInt(values?.wage?.partTimeWorkHour))
              ? parseInt(values?.wage?.partTimeWorkHour)
              : "",
          overtime:
            oldData?.overtime !== parseInt(values?.wage?.overtime) &&
            !isNaN(parseInt(values?.wage?.overtime))
              ? parseInt(values?.wage?.overtime)
              : "",
          workingCondition: isEqual
            ? ""
            : allValuesEmpty
            ? ""
            : values?.wage?.workingCondition,
          selectedBank:
            oldData?.selectedBank !== values?.wage?.selectedBank &&
            values?.wage?.selectedBank
              ? values?.wage?.selectedBank
              : "",
          conditionsOfSalaryPayment:
            oldData?.conditionsOfSalaryPayment !==
              values?.wage?.conditionsOfSalaryPayment &&
            values?.wage?.conditionsOfSalaryPayment
              ? values?.wage?.conditionsOfSalaryPayment
              : "",
          paymentPeriod:
            oldData?.paymentPeriod !== parseInt(values?.wage?.paymentPeriod) &&
            !isNaN(parseInt(values?.wage?.paymentPeriod))
              ? parseInt(values?.wage?.paymentPeriod)
              : "",
          paymentPeriodDate:
            oldData?.paymentPeriodDate !== values?.wage?.paymentPeriodDate &&
            values?.wage?.paymentPeriodDate
              ? values?.wage?.paymentPeriodDate
              : "",
          freelanceWorkIncomeType:
            oldData?.freelanceWorkIncomeType !==
              values?.wage?.freelanceWorkIncomeType &&
            values?.wage?.freelanceWorkIncomeType
              ? values?.wage?.freelanceWorkIncomeType
              : "",
          freelanceWorkIncome:
            oldData?.freelanceWorkIncome !==
              values?.wage?.freelanceWorkIncome &&
            values?.wage?.freelanceWorkIncome
              ? values?.wage?.freelanceWorkIncome
              : "",
        },
      },
      {
        section: "protection",
        data: {
          providedProtectiveEquipment: isProvidedProtectiveEquipmentEqual
            ? ""
            : Array.isArray(values?.protection?.providedProtectiveEquipment)
            ? values?.protection?.providedProtectiveEquipment.length > 0
              ? values?.protection?.providedProtectiveEquipment
              : ""
            : values?.protection?.providedProtectiveEquipment
            ? [values?.protection?.providedProtectiveEquipment]
            : "",
          providedFood: isProvidedFoodEqual
            ? ""
            : Array.isArray(values?.protection?.providedFood)
            ? values?.protection?.providedFood.length > 0
              ? values?.protection?.providedFood
              : ""
            : values?.protection?.providedFood
            ? [values?.protection?.providedFood]
            : "",

          instructionalPeriod:
            oldData?.instructionalPeriod !==
              values?.protection?.instructionalPeriod &&
            values?.protection?.instructionalPeriod
              ? values?.protection?.instructionalPeriod
              : "",
        },
      },
      {
        section: "workVacationTime",
        data: {
          offDays: isWeekDaysEqual
            ? ""
            : values?.workVacationTime?.offDays.length > 0
            ? values?.workVacationTime?.offDays
            : "",
          workScheduleId:
            oldData?.workScheduleId !==
              values?.workVacationTime?.workScheduleId &&
            values?.workVacationTime?.workScheduleId
              ? values?.workVacationTime?.workScheduleId
              : "",
          workScheduleAsText:
            oldData?.workScheduleAsText !==
              values?.workVacationTime?.workScheduleAsText &&
            values?.workVacationTime?.workScheduleAsText
              ? values?.workVacationTime?.workScheduleAsText
              : "",
          assignedWorkTurnValue:
            oldData?.assignedWorkTurnValue !==
              values?.workVacationTime?.assignedWorkTurnValue &&
            values?.workVacationTime?.assignedWorkTurnValue
              ? values?.workVacationTime?.assignedWorkTurnValue
              : "",
        },
      },
      {
        section: "vacation",
        data: {
          vacationDaysCount:
            oldData?.vacationDaysCount !==
              values?.vacation?.vacationDaysCount &&
            values?.vacation?.vacationDaysCount
              ? values?.vacation?.vacationDaysCount
              : "",
          forSeniorityLevel:
            oldData?.forSeniorityLevel !==
              values?.vacation?.forSeniorityLevel &&
            !isNaN(values?.vacation?.forSeniorityLevel)
              ? values?.vacation?.forSeniorityLevel
              : "",
          aboutEventOrPayer:
            oldData?.aboutEventOrPayer !==
              values?.vacation?.aboutEventOrPayer &&
            values?.vacation?.aboutEventOrPayer
              ? values?.vacation?.aboutEventOrPayer
              : "",
          forParental:
            oldData?.forParental !== values?.vacation?.forParental &&
            !isNaN(values?.vacation?.forParental)
              ? values?.vacation?.forParental
              : "",
          forCollectiveAgreement:
            oldData?.forCollectiveAgreement !==
              parseInt(values?.vacation?.forCollectiveAgreement) &&
            !isNaN(parseInt(values?.vacation?.forCollectiveAgreement))
              ? parseInt(values?.vacation?.forCollectiveAgreement)
              : "",
          socialHouseholdBenefit:
            oldData?.socialHouseholdBenefit !==
              parseInt(values?.vacation?.socialHouseholdBenefit) &&
            !isNaN(parseInt(values?.vacation?.socialHouseholdBenefit))
              ? parseInt(values?.vacation?.socialHouseholdBenefit)
              : "",
        },
      },
      {
        section: "physicalEducationSport",
        data: {
          physicalEducationAndSportsConditions:
            oldData?.physicalEducationAndSportsConditions !==
              values?.physicalEducationSport
                ?.physicalEducationAndSportsConditions &&
            values?.physicalEducationSport?.physicalEducationAndSportsConditions
              ? values?.physicalEducationSport
                  ?.physicalEducationAndSportsConditions
              : "",
          otherAdditionalConditions: isOtherAdditionalConditionsEqual
            ? ""
            : oldData?.otherAdditionalConditions !==
                values?.physicalEducationSport?.otherAdditionalConditions &&
              values?.physicalEducationSport?.otherAdditionalConditions
            ? values?.physicalEducationSport?.otherAdditionalConditions
            : "",
        },
      },
      {
        section: "socialProtection",
        data: {
          monthlyInsuranceFee:
            oldData?.monthlyInsuranceFee !==
              parseFloat(values?.socialProtection?.monthlyInsuranceFee) &&
            !isNaN(parseFloat(values?.socialProtection?.monthlyInsuranceFee))
              ? parseFloat(values?.socialProtection?.monthlyInsuranceFee)
              : "",
          extraSumInsured:
            oldData?.extraSumInsured !==
              parseFloat(values?.socialProtection?.extraSumInsured) &&
            !isNaN(parseFloat(values?.socialProtection?.extraSumInsured))
              ? parseFloat(values?.socialProtection?.extraSumInsured)
              : "",
        },
      },
      {
        section: "regulationPropertyRelation",
        data: {
          shareOwnershipOfEnterprise:
            oldData?.shareOwnershipOfEnterprise !==
              parseFloat(
                values?.regulationPropertyRelation?.shareOwnershipOfEnterprise
              ) &&
            !isNaN(
              parseFloat(
                values?.regulationPropertyRelation?.shareOwnershipOfEnterprise
              )
            )
              ? parseFloat(
                  values?.regulationPropertyRelation?.shareOwnershipOfEnterprise
                )
              : "",
          dividendAmount:
            oldData?.dividendAmount !==
              parseFloat(values?.regulationPropertyRelation?.dividendAmount) &&
            !isNaN(
              parseFloat(values?.regulationPropertyRelation?.dividendAmount)
            )
              ? parseFloat(values?.regulationPropertyRelation?.dividendAmount)
              : "",
          personalPropertiesUsedByEmployee:
            isPersonalPropertiesUsedByEmployeeEqual
              ? ""
              : values?.regulationPropertyRelation
                  ?.personalPropertiesUsedByEmployee.length > 0
              ? values?.regulationPropertyRelation
                  ?.personalPropertiesUsedByEmployee
              : "",
        },
      },
      {
        section: "administrative",
        data: {
          oldRecruiterId:
            oldData?.recruiterId !== values?.administrative?.oldRecruiterId &&
            values?.administrative?.oldRecruiterId
              ? values?.administrative?.oldRecruiterId
              : "",
        },
      },
      {
        section: "jobRelated",
        data: {
          staffId:
            oldData?.isVerified &&
            oldData?.staffId !== values?.jobRelated?.staffId &&
            values?.jobRelated?.staffId
              ? values?.jobRelated?.staffId
              : "",
          oldStructureAsStr:
            !oldData?.isVerified && values?.jobRelated?.oldStructureAsStr
              ? values?.jobRelated?.oldStructureAsStr
              : "",
          newStructureAsStr:
            !oldData?.isVerified && values?.jobRelated?.newStructureAsStr
              ? values?.jobRelated?.newStructureAsStr
              : "",
          oldPositionAsStr:
            !oldData?.isVerified && values?.jobRelated?.oldPositionAsStr
              ? values?.jobRelated?.oldPositionAsStr
              : "",
          newPositionAsStr:
            !oldData?.isVerified && values?.jobRelated?.newPositionAsStr
              ? values?.jobRelated?.newPositionAsStr
              : "",
        },
      },
    ].filter((update) => {
      const { data } = update;

      return Object.keys(data).some((key) => {
        const value = data[key];
        const allEmptyStrings =
          typeof value === "object" &&
          Object.values(value).every((v) => v === "");

        return (
          value !== "" &&
          !(Array.isArray(value) && value.length === 0) &&
          !(
            typeof value === "object" &&
            (Object.keys(value).length === 0 || allEmptyStrings)
          )
        );
      });
    }),
  };
}

export function empGeneralConstModifeValue(values, selectedCont) {
  return {
    staffs: Array.isArray(selectedCont) ? selectedCont : [selectedCont],
    labourContractChangeDate: values.labourContractChangeDate
      ? moment(values?.labourContractChangeDate).format("MM/DD/yyyy")
      : "",
    updates: [
      {
        section: "informationAboutParties",
        data: {
          empWorkPlaceType: values?.informationAboutParties?.empWorkPlaceType
            ? values?.informationAboutParties?.empWorkPlaceType
            : "",
        },
      },
      {
        section: "labourPeriod",
        data: {
          contractPeriodType: values?.labourPeriod?.contractPeriodType
            ? values?.labourPeriod?.contractPeriodType
            : "",
          probationPeriodType: values?.labourPeriod?.probationPeriodType
            ? values?.labourPeriod?.probationPeriodType
            : "",
          probationPeriod: !isNaN(
            parseInt(values?.labourPeriod?.probationPeriod)
          )
            ? parseInt(values?.labourPeriod?.probationPeriod)
            : "",
          reasonOfTemporaryContract: !isNaN(
            values?.labourPeriod?.reasonOfTemporaryContract
          )
            ? values?.labourPeriod?.reasonOfTemporaryContract
            : "",
          contractStartDate: values?.labourPeriod?.contractStartDate
            ? moment(values?.labourPeriod?.contractStartDate).format(
                "MM/DD/yyyy"
              )
            : "",
          contractEndDate: values?.labourPeriod?.contractEndDate
            ? moment(values?.labourPeriod?.contractEndDate).format("MM/DD/yyyy")
            : "",
          jobStartDate: values?.labourPeriod?.jobStartDate
            ? moment(values?.labourPeriod?.jobStartDate).format("MM/DD/yyyy")
            : "",
          laborFunctions:
            values?.labourPeriod?.laborFunctions.length > 0
              ? values?.labourPeriod?.laborFunctions
              : "",
        },
      },
      {
        section: "wage",
        data: {
          paymentOfWork: values?.wage?.paymentOfWork
            ? values?.wage?.paymentOfWork
            : "",
          wage: !isNaN(parseInt(values?.wage?.wage))
            ? parseInt(values?.wage?.wage)
            : "",
          bonusPeriod: values?.wage?.bonusPeriod
            ? values?.wage?.bonusPeriod
            : "",
          givenBonus: !isNaN(parseInt(values?.wage?.givenBonus))
            ? parseInt(values?.wage?.givenBonus)
            : "",
          partTimeWorkHour: !isNaN(parseInt(values?.wage?.partTimeWorkHour))
            ? parseInt(values?.wage?.partTimeWorkHour)
            : "",
          overtime: !isNaN(parseInt(values?.wage?.overtime))
            ? parseInt(values?.wage?.overtime)
            : "",
          workingCondition: Object.values(values?.wage?.workingCondition).every(
            (v) => v === ""
          )
            ? ""
            : values?.wage?.workingCondition,
          selectedBank: values?.wage?.selectedBank,
          conditionsOfSalaryPayment: values?.wage?.conditionsOfSalaryPayment
            ? values?.wage?.conditionsOfSalaryPayment
            : "",
          paymentPeriod: !isNaN(parseInt(values?.wage?.paymentPeriod))
            ? parseInt(values?.wage?.paymentPeriod)
            : "",
          paymentPeriodDate: values?.wage?.paymentPeriodDate
            ? values?.wage?.paymentPeriodDate
            : "",
          freelanceWorkIncomeType: values?.wage?.freelanceWorkIncomeType
            ? values?.wage?.freelanceWorkIncomeType
            : "",
          freelanceWorkIncome: values?.wage?.freelanceWorkIncome
            ? values?.wage?.freelanceWorkIncome
            : "",
        },
      },
      {
        section: "protection",
        data: {
          providedProtectiveEquipment: Array.isArray(
            values?.protection?.providedProtectiveEquipment
          )
            ? values?.protection?.providedProtectiveEquipment.length > 0
              ? [values?.protection?.providedProtectiveEquipment]
              : ""
            : values?.protection?.providedProtectiveEquipment
            ? [values?.protection?.providedProtectiveEquipment]
            : "",
          providedFood: Array.isArray(values?.protection?.providedFood)
            ? values?.protection?.providedFood.length > 0
              ? [values?.protection?.providedFood]
              : ""
            : values?.protection?.providedFood
            ? [values?.protection?.providedFood]
            : "",

          instructionalPeriod: values?.protection?.instructionalPeriod,
        },
      },
      {
        section: "workVacationTime",
        data: {
          offDays:
            values?.workVacationTime?.offDays.length > 0
              ? values?.workVacationTime?.offDays
              : "",
          workScheduleId: values?.workVacationTime?.workScheduleId
            ? values?.workVacationTime?.workScheduleId
            : "",
          workScheduleAsText: values?.workVacationTime?.workScheduleAsText
            ? values?.workVacationTime?.workScheduleAsText
            : "",
          assignedWorkTurnValue: values?.workVacationTime?.assignedWorkTurnValue
            ? values?.workVacationTime?.assignedWorkTurnValue
            : "",
        },
      },
      {
        section: "vacation",
        data: {
          vacationDaysCount: values?.vacation?.vacationDaysCount
            ? values?.vacation?.vacationDaysCount
            : "",
          forSeniorityLevel: !isNaN(values?.vacation?.forSeniorityLevel)
            ? values?.vacation?.forSeniorityLevel
            : "",
          aboutEventOrPayer: values?.vacation?.aboutEventOrPayer
            ? values?.vacation?.aboutEventOrPayer
            : "",
          forParental: !isNaN(values?.vacation?.forParental)
            ? values?.vacation?.forParental
            : "",
          forCollectiveAgreement: !isNaN(
            parseInt(values?.vacation?.forCollectiveAgreement)
          )
            ? parseInt(values?.vacation?.forCollectiveAgreement)
            : "",
          socialHouseholdBenefit: !isNaN(
            parseInt(values?.vacation?.socialHouseholdBenefit)
          )
            ? parseInt(values?.vacation?.socialHouseholdBenefit)
            : "",
        },
      },
      {
        section: "physicalEducationSport",
        data: {
          physicalEducationAndSportsConditions: values?.physicalEducationSport
            ?.physicalEducationAndSportsConditions
            ? values?.physicalEducationSport
                ?.physicalEducationAndSportsConditions
            : "",
          otherAdditionalConditions: values?.physicalEducationSport
            ?.otherAdditionalConditions
            ? values?.physicalEducationSport?.otherAdditionalConditions
            : "",
        },
      },
      {
        section: "socialProtection",
        data: {
          monthlyInsuranceFee: !isNaN(
            parseInt(values?.socialProtection?.monthlyInsuranceFee)
          )
            ? parseInt(values?.socialProtection?.monthlyInsuranceFee)
            : "",
          extraSumInsured: !isNaN(
            parseInt(values?.socialProtection?.extraSumInsured)
          )
            ? parseInt(values?.socialProtection?.extraSumInsured)
            : "",
        },
      },
      {
        section: "regulationPropertyRelation",
        data: {
          shareOwnershipOfEnterprise: !isNaN(
            parseInt(
              values?.regulationPropertyRelation?.shareOwnershipOfEnterprise
            )
          )
            ? parseInt(
                values?.regulationPropertyRelation?.shareOwnershipOfEnterprise
              )
            : "",
          dividendAmount: !isNaN(
            parseInt(values?.regulationPropertyRelation?.dividendAmount)
          )
            ? parseInt(values?.regulationPropertyRelation?.dividendAmount)
            : "",
          personalPropertiesUsedByEmployee:
            values?.regulationPropertyRelation?.personalPropertiesUsedByEmployee
              .length > 0
              ? values?.regulationPropertyRelation
                  ?.personalPropertiesUsedByEmployee
              : "",
        },
      },
      {
        section: "administrative",
        data: {
          oldRecruiterId: values?.administrative?.oldRecruiterId
            ? values?.administrative?.oldRecruiterId
            : "",
        },
      },
      {
        section: "jobRelated",
        data: {
          staffId: values?.jobRelated?.staffId
            ? values?.jobRelated?.staffId
            : "",
        },
      },
    ].filter((update) => {
      const { data } = update;

      return Object.keys(data).some((key) => {
        const value = data[key];
        const allEmptyStrings =
          typeof value === "object" &&
          Object.values(value).every((v) => v === "");

        return (
          value !== "" &&
          !(Array.isArray(value) && value.length === 0) &&
          !(
            typeof value === "object" &&
            (Object.keys(value).length === 0 || allEmptyStrings)
          )
        );
      });
    }),
  };
}
export const empConstColumns = (
  getDescendantProp,
  statusColumnGenerator,
  operatorColumnGenerator
) => {
  return [
    new Column({
      headerName: "Operator",
      path: "#",
      renderCell: (row, column) => {
        return operatorColumnGenerator(row);
        // <GENERAL_BLOCK
        //   d_play="grid"
        //   gtc="repeat(auto-fit, minmax(0, 1fr))"
        //   gap="5px"
        // >
        //   {/* <DocumentDialog
        //     documentId={row._id}
        //     disableSigning={true}
        //     fetchHandler={getLabourContractDoc_API}
        //     // buttonGroup={button_group}
        //     // refreshPage={paginationFetchingHandler}
        //   /> */}
        // </GENERAL_BLOCK>
      },
      isWithFilter: false,
    }),
    new Column({
      headerName: "Ə.M. No.",
      path: "data.contract.docNumber",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      headerName: "Dəyişiklik sorğusu tarixi",
      path: "data.contract.contractUpdateDate",
      filterType: OPERATORS_CONTAINING.DATE,
    }),
    new Column({
      headerName: "Dəyişikliklər",
      path: "data.updates",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => {
        const arr = getDescendantProp(row, column.path);
        const changes = arr?.map((item) => GROUP_NAME[item.section]);

        return changes?.join(", ");
      },
    }),
    new Column({
      headerName: "A.S.A.",
      path: "data.contract.employeeFullName",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      headerName: "Qüvvəyə minmə tarixi",
      path: "data.effectiveDate",
      filterType: OPERATORS_CONTAINING.DATE,
    }),
    new Column({
      headerName: "Status",
      path: "status",
      renderCell: statusColumnGenerator,
      filterType: OPERATORS_CONTAINING.OPTION,
    }),
  ];
};

export const empConstValidation = () => {
  return Yup.object().shape({
    labourPeriod: Yup.object().shape({
      contractEndDate: Yup.date()
        .nullable()
        .when("contractPeriodType", {
          is: (val) => val !== "indefinite",
          then: (schema) => schema.required("Bitiş tarixi zəruridir"),
          otherwise: (schema) => schema.nullable(),
        }),
      reasonOfTemporaryContract: Yup.number()
        .nullable()
        .when("contractPeriodType", {
          is: (val) => val !== "indefinite",
          then: (schema) =>
            schema.required("Müddətli bağlanma səbəbi zəruridir"),
          otherwise: (schema) => schema.nullable(),
        }),
    }),
  });
};
