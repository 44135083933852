import styled from "@emotion/styled";
import { Form, Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Info } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Tooltip,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import moment from "moment/moment";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEmployee } from "../../api/api";
import AcademicDegree from "../../components/employees/AcademicDegree";
import AddToStaff from "../../components/employees/AddToStaff";
import BankInfo from "../../components/employees/BankInfo";
import ContactInfo from "../../components/employees/ContactInfo";
import Documents from "../../components/employees/Documents";
import DriveLicenseStep from "../../components/employees/DriveLicenseStep";
import Education from "../../components/employees/Education";
import Inventories from "../../components/employees/Inventories";
import LaborActivity from "../../components/employees/LaborActivity";
import MilitaryStep from "../../components/employees/MilitaryStep";
import NewCommonDataDialog from "../../components/employees/NewCommonDataDialog";
import PersonalInformation from "../../components/employees/PersonalInformation";
import RelationShipInfo from "../../components/employees/RelationShipInfo";
import RewardsReprimands from "../../components/employees/RewardsReprimands";
import SalarySupplements from "../../components/employees/SalarySupplements";
import SocialStatus from "../../components/employees/SocialStatus";
import {
  ContactInfoValidation,
  foreignEmployeeValidation,
  EducationValidation,
  Step3Schema,
  Step4Schema,
  academicDegreeValidation,
  bankInfoValidation,
  documentsValidation,
  driverLicenseValidation,
  inventoriesValidation,
  militaryValidation,
  personalInformationValidation,
  reprimandRewardValidation,
  socialStatusValidation,
  supplementalWagesValidation,
  transportVehiclesValidation,
  certificationsValidation,
  deductionWagesValidation,
} from "../../components/employees/Validations";
import Vehicles from "../../components/employees/Vehicles";
import {
  addEmpToStaff,
  updateEmployeeById,
} from "../../services/employees/EmployeeServices";
import { checkUniquenessForNext } from "../../utils/checkUniquenessForEmployee";
import { removeEmptyStrings } from "../../utils/removeEmptyString";
import { getStructureById } from "../../services/structure/StructureServices";
import Certification from "../../components/employees/Certification";
import DeductionsWages from "../../components/employees/DeductionsWages";
import {
  setEmployees,
  setActiveStep,
  initialStateEmp,
} from "../../redux/features/slices/employee.slice";
import useUserActions from "../../hooks/useUserActions";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AddEmployee({ goBack }) {
  const navigate = useNavigate();
  const { nationality } = useSelector((state) => state.nationality);
  const dispatch = useDispatch();
  const emp = useSelector((state) => state.emp);
  const { preDefinedData } = useUserActions();
  let preDefinedPin = preDefinedData?.pin;
  // IDCard Scan
  const [showCam, setShowCam] = useState(false);
  const [allStructures, setAllStructures] = useState([]);
  const [picUpload, setPicUpload] = useState(false);
  const [showAddStaff, setShowAddStaff] = useState(false);
  const [structureById, setStructureById] = useState();
  const [structureId, setStructureId] = useState();
  const [staffId, setStaffId] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  // Dialogs
  const [employee, setEmployee] = useState();
  const [positionId, setPositionId] = useState();
  const [IDScan, setIDScan] = useState(false);

  const [isHas, setIsHas] = useState(false);

  // const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 18;
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
  // ** Labor activity structure IDS
  const [structuresIds, setStructuresIds] = useState([]);

  // New Common Data Dialog states
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedType, setSelectedType] = useState([
    "",
    {
      name: "",
      values: [],
    },
  ]);

  // IDScan Dialog

  const handleOpenIDScan = () => {
    setIDScan(true);
  };

  const handleCloseIDScan = () => {
    setShowCam(false);
    setPicUpload(false);
    setIDScan(false);
  };

  // ** Add to staff states
  const [addToStaff, setAddToStaff] = useState({
    structureId: "",
    positionId: "",
    jobStartDate: moment().format("MM/DD/YYYY"),
    agreementDate: moment().format("MM/DD/YYYY"),
    contractId: "",
    staffId: "",
    employeeId: "",
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const hasDuplicates = checkUniquenessForNext(
      values.transportVehicles,
      ["registrationNumber"],
      ["qeydiyyat şəhadətnaməsi nömrəsi"]
    );
    if (!hasDuplicates) {
      const modifiedValues = {
        ...values,
        firstname: values.firstname.trim(),
        lastname: values.lastname.trim(),
        fathername: values.fathername.trim(),
        placeOfBirth: values.placeOfBirth.trim(),
        idCardAuthority: values.idCardAuthority.trim(),
        ssn: values.ssn.trim(),
        actualAddress: {
          ...values.actualAddress,
          address: values.actualAddress.address.trim(),
        },
        registeredAddress: {
          ...values.registeredAddress,
          address: values.registeredAddress.address.trim(),
        },
        isForeigner: nationality === "foreign" ? true : false,
        citizenship:
          nationality === "foreign" ? values.citizenship : "Azərbaycan",
        mobile: values.mobile.map((mobile) => mobile.code + mobile.number),
        email: values.email.map((item) => item.trim()),
        idCardNumber:
          nationality === "foreign"
            ? values.idCardNumber.number
            : values.idCardNumber.code + values.idCardNumber.number,

        bankInfos: values.bankInfos.map((bank) => ({
          ...bank,
          bankCode: parseInt(bank.bankCode),
          accountNumber: bank.accountNumber.trim(),
          iban: bank.iban.trim(),
          cardExpirationDate: `${bank?.month}/${bank?.year}`,
        })),
        dob: values.dob ? moment(values.dob).format("MM/DD/yyyy") : null,
        idCardIssuanceDate: values.idCardIssuanceDate
          ? moment(values.idCardIssuanceDate).format("MM/DD/yyyy")
          : null,
        idCardValidityPeriod: values.dob
          ? new Date().getFullYear() - new Date(values.dob).getFullYear() > 50
            ? null
            : values.idCardValidityPeriod
            ? moment(values.idCardValidityPeriod).format("MM/DD/yyyy")
            : null
          : null,
        workPermitStartDate: values.idCardIssuanceDate
          ? moment(values.idCardIssuanceDate).format("MM/DD/yyyy")
          : null,
        workPermitEndDate: values.idCardIssuanceDate
          ? moment(values.idCardIssuanceDate).format("MM/DD/yyyy")
          : null,
        residencePermitStartDate: values.idCardIssuanceDate
          ? moment(values.idCardIssuanceDate).format("MM/DD/yyyy")
          : null,
        residencePermitEndDate: values.idCardIssuanceDate
          ? moment(values.idCardIssuanceDate).format("MM/DD/yyyy")
          : null,
        laborActivity: values.laborActivity.map((activity) => ({
          ...activity,
          startDate: activity.startDate
            ? moment(activity.startDate).format("MM/DD/yyyy")
            : null,
          endDate: activity.endDate
            ? moment(activity.endDate).format("MM/DD/yyyy")
            : null,
        })),
        relationshipInfo: values.relationshipInfo.map((relationship) => ({
          ...relationship,
          fullname: relationship.fullname.trim(),
          pin:
            nationality !== "foreign"
              ? relationship.pin?.trim()
              : relationship?.isForeigner && relationship?.isMyiExist
              ? relationship.pin?.trim()
              : "",
          placeOfWork: relationship.placeOfWork
            ? relationship.placeOfWork.trim()
            : null,
          dob: relationship.dob
            ? moment(relationship.dob).format("MM/DD/yyyy")
            : null,
          education: relationship.education
            ? relationship.education.trim()
            : null,
          idCardNumber:
            relationship.isForeigner === true
              ? relationship.idCardNumber?.number
                ? relationship.idCardNumber.number
                : ""
              : relationship.idCardNumber?.code &&
                relationship.idCardNumber?.number
              ? relationship.idCardNumber.code +
                relationship.idCardNumber.number
              : "",
          mobile: relationship.mobile
            ? relationship.mobile.map((m) => m.trim())
            : [],
        })),
        education: values.education.map((education) => ({
          ...education,
          startDate: education.startDate
            ? moment(education.startDate).format("MM/DD/yyyy")
            : null,
          endDate: education.endDate
            ? moment(education.endDate).format("MM/DD/yyyy")
            : null,
        })),
        academicDegrees: values.academicDegrees.map((degree) => {
          const { value, ...rest } = degree;
          return {
            ...rest,
            date: rest.date ? moment(rest.date).format("MM/DD/yyyy") : null,
            note: rest?.note ? rest.note.trim() : null,
          };
        }),
        socialStatus: values.socialStatus.map((social) => {
          const { value, ...rest } = social;
          return {
            ...rest,
            date: rest.date ? moment(rest.date).format("MM/DD/yyyy") : null,
          };
        }),
        supplementalWages: values.supplementalWages.map((supplement) => {
          const { value, valueType, ...rest } = supplement;
          return {
            ...rest,
            value: value,
          };
        }),
        deductionWages: values.deductionWages.map((deductions) => {
          const { value, valueType, ...rest } = deductions;
          return {
            ...rest,
            value: value,
          };
        }),
        reprimandsAndRewards: values.reprimandsAndRewards.map(
          (reprimandReward) => ({
            ...reprimandReward,
            date: reprimandReward.date
              ? moment(reprimandReward.date).format("MM/DD/yyyy")
              : null,
            description: reprimandReward.description.trim(),
          })
        ),
        inventories: values.inventories.map((inventory) => ({
          ...inventory,
          date: inventory.date
            ? moment(inventory.date).format("MM/DD/yyyy")
            : null,
          number: inventory.number.trim(),
          serialNumber: inventory.serialNumber.trim(),
        })),
        documents: values.documents.map((document) => ({
          ...document,
          issuanceDate: document.issuanceDate
            ? moment(document.issuanceDate).format("MM/DD/yyyy")
            : null,
          expirationDate: document.expirationDate
            ? moment(document.expirationDate).format("MM/DD/yyyy")
            : null,
        })),
        certifications: values?.certifications.map((certification) => ({
          ...certification,
          issueDate: certification?.issueDate
            ? moment(certification?.issueDate).format("MM/DD/yyyy")
            : "",
          expireDate: certification?.expireDate
            ? moment(certification?.expireDate).format("MM/DD/yyyy")
            : "",
        })),
        transportVehicles: values.transportVehicles.map((vehicle) => ({
          ...vehicle,
          brand: vehicle.brand.trim(),
          engineCapacity: vehicle.engineCapacity.trim(),
          name: vehicle.name.trim(),
          registrationNumber: vehicle.registrationNumber.trim(),
          registrationPlate: vehicle.registrationPlate.trim(),
          transmissionType: vehicle.transmissionType.trim(),
          year: vehicle.year.trim(),
        })),
        militaryService:
          nationality !== "foreign"
            ? {
                hasMilitaryService: values.militaryService?.hasMilitaryService,
                issuerName: values.militaryService?.issuerName?.trim(),
                troopType: values.militaryService?.troopType?.trim(),
                militaryRank: values.militaryService?.militaryRank?.trim(),
                serviceStartDate: values.militaryService?.serviceStartDate
                  ? moment(values.militaryService?.serviceStartDate).format(
                      "MM/DD/yyyy"
                    )
                  : null,
                serviceEndDate: values.militaryService?.serviceEndDate
                  ? moment(values.militaryService?.serviceEndDate).format(
                      "MM/DD/yyyy"
                    )
                  : null,
                reason: values.militaryService?.reason.trim(),
                articleNumber: values.militaryService?.articleNumber
                  ? values.militaryService?.articleNumber
                  : null,
                note: values.militaryService?.note
                  ? values.militaryService?.note?.trim()
                  : null,
              }
            : null,
        driverLicense:
          values.driverLicense?.licenseCategory?.length > 0
            ? {
                licenseIssuer: values.driverLicense?.licenseIssuer
                  ? values.driverLicense?.licenseIssuer?.trim()
                  : null,
                licenseIssuanceDate: values.driverLicense?.licenseIssuanceDate
                  ? moment(values.driverLicense?.licenseIssuanceDate).format(
                      "MM/DD/yyyy"
                    )
                  : null,
                licenseExpirationDate: values.driverLicense
                  ?.licenseExpirationDate
                  ? moment(values.driverLicense?.licenseExpirationDate).format(
                      "MM/DD/yyyy"
                    )
                  : null,
                licenseNumber: values.driverLicense?.licenseNumber
                  ? values.driverLicense?.licenseNumber?.trim()
                  : null,
                licenseCategory: values.driverLicense?.licenseCategory,
              }
            : null,
      };

      const result = removeEmptyStrings(modifiedValues);

      setSubmitting(true);

      // Validation off
      const response = await addEmployee(result);
      if (response && response.data) {
        setEmployee(response.data);
        setShowAddStaff(true);
        // dispatch(setEmployees({ data1: "" }));
        // dispatch(setActiveStep(initialStateEmp.activeStep));
        resetForm(values);
        handleNext();
      }
      setSubmitting(false);
    }
  };

  // ** Add to staff
  const handleAddToStaff = async () => {
    try {
      if (structureById?.isVerified === true) {
        const response = await addEmpToStaff(addToStaff);
        if (response) {
          toast.success("Employee added to staff successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setShowAddStaff(false);
          dispatch(setActiveStep(1));
          dispatch(setEmployees(initialStateEmp.data));
          setTimeout(() => {
            navigate("/employees");
          }, 3000);
        }
      } else {
        const newStaffId = staffId;
        const newSelectedEmp = employee._id;
        const response = await updateEmployeeById(newStaffId, newSelectedEmp);
        if (response) {
          toast.success("Uğurla əlavə edildi!");
          setShowAddStaff(false);
          navigate("/employees");
          dispatch(setActiveStep(1));
          dispatch(setEmployees(initialStateEmp.data));
        } else {
          toast.error("Hər hansı bir xəta baş verdi!");
        }
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } finally {
      setPositionId();
    }
  };
  const handleFinish = () => {
    dispatch(setActiveStep(1));
    dispatch(setEmployees(initialStateEmp.data));
    setTimeout(() => {
      navigate("/employees");
    }, 1000);
  };
  const handleNext = () => {
    if (nationality === "foreign" && emp?.activeStep === 3) {
      dispatch(setActiveStep(emp?.activeStep + 1));
    } else {
      dispatch(setActiveStep(emp?.activeStep + 1));
    }
  };

  const handlePrevious = () => {
    if (nationality === "foreign" && emp.activeStep === 4) {
      dispatch(setActiveStep(emp?.activeStep - 2));
    } else {
      dispatch(setActiveStep(emp?.activeStep - 1));
    }
  };
  const renderStep = (step, values, showAddStaff) => {
    if (showAddStaff && employee) {
      return (
        <AddToStaff
          positionId={positionId}
          setPositionId={setPositionId}
          employee={employee}
          setAddToStaff={setAddToStaff}
          addToStaff={addToStaff}
          structureId={structureId}
          setStructureId={setStructureId}
          structureById={structureById}
          setStructureById={setStructureById}
          staffId={staffId}
          setStaffId={setStaffId}
          allStructures={allStructures}
          setAllStructures={setAllStructures}
        />
      );
    }
    switch (step) {
      case 1:
        return (
          <PersonalInformation
            values={values}
            submitted={submitted}
            setOpenAddDialog={setOpenAddDialog}
            setSelectedType={setSelectedType}
          />
        );
      case 2:
        return (
          <LaborActivity
            values={values}
            setOpenAddDialog={setOpenAddDialog}
            setSelectedType={setSelectedType}
            submitted={submitted}
            setStructuresIds={setStructuresIds}
            structuresIds={structuresIds}
            isCurrentlyWorking={isCurrentlyWorking}
            setIsCurrentlyWorking={setIsCurrentlyWorking}
            Step3Schema={Step3Schema}
          />
        );
      case 3:
        return nationality !== "foreign" ? (
          <MilitaryStep
            values={values}
            submitted={submitted}
            setOpenAddDialog={setOpenAddDialog}
            setSelectedType={setSelectedType}
          />
        ) : null;
      case 4:
        return (
          <DriveLicenseStep
            isHas={isHas}
            values={values}
            setIsHas={setIsHas}
            submitted={submitted}
            setOpenAddDialog={setOpenAddDialog}
            setSelectedType={setSelectedType}
          />
        );

      case 5:
        return <RelationShipInfo values={values} />;
      case 6:
        return <ContactInfo values={values} />;
      case 7:
        return (
          <Education
            setOpenAddDialog={setOpenAddDialog}
            setSelectedType={setSelectedType}
            submitted={submitted}
            values={values}
          />
        );
      case 8:
        return (
          <AcademicDegree
            setOpenAddDialog={setOpenAddDialog}
            setSelectedType={setSelectedType}
            submitted={submitted}
            values={values}
          />
        );
      case 9:
        return (
          <SocialStatus
            setOpenAddDialog={setOpenAddDialog}
            setSelectedType={setSelectedType}
            submitted={submitted}
            values={values}
          />
        );
      case 10:
        return (
          <SalarySupplements
            setOpenAddDialog={setOpenAddDialog}
            setSelectedType={setSelectedType}
            submitted={submitted}
            values={values}
          />
        );
      case 11:
        return (
          <RewardsReprimands
            setOpenAddDialog={setOpenAddDialog}
            setSelectedType={setSelectedType}
            submitted={submitted}
            values={values}
          />
        );
      case 12:
        return (
          <Inventories
            setOpenAddDialog={setOpenAddDialog}
            setSelectedType={setSelectedType}
            submitted={submitted}
            values={values}
            setSubmitted={setSubmitted}
            selectedType={selectedType}
          />
        );
      case 13:
        return (
          <Certification
            values={values}
            submitted={submitted}
            setSelectedType={setSelectedType}
            setOpenAddDialog={setOpenAddDialog}
          />
        );
      case 14:
        return <BankInfo values={values} />;
      case 15:
        return (
          <Vehicles
            setOpenAddDialog={setOpenAddDialog}
            setSelectedType={setSelectedType}
            submitted={submitted}
            values={values}
            setSubmitted={setSubmitted}
            selectedType={selectedType}
          />
        );
      case 16:
        return (
          <Documents
            setOpenAddDialog={setOpenAddDialog}
            setSelectedType={setSelectedType}
            submitted={submitted}
            values={values}
          />
        );
      case 17:
        return (
          <DeductionsWages
            values={values}
            submitted={submitted}
            setSelectedType={setSelectedType}
            setOpenAddDialog={setOpenAddDialog}
          />
        );
      case 18:
        return (
          <AddToStaff
            positionId={positionId}
            setPositionId={setPositionId}
            employee={employee}
            setAddToStaff={setAddToStaff}
            addToStaff={addToStaff}
            structureId={structureId}
            setStructureId={setStructureId}
            staffId={staffId}
            setStaffId={setStaffId}
            allStructures={allStructures}
            setAllStructures={setAllStructures}
          />
        );
      default:
        return null;
    }
  };

  const calculateProgress = () => {
    return Math.floor((emp?.activeStep / totalSteps) * 100);
  };

  const getValidationSchema = (step, nationality, isCurrentlyWorking) => {
    if (nationality === "foreign" && step === 3) {
      dispatch(setActiveStep(emp?.activeStep + 1));
    }
    const stepValidations = {
      1:
        nationality === "foreign"
          ? foreignEmployeeValidation
          : personalInformationValidation,
      2: Step3Schema(isCurrentlyWorking),
      3: nationality !== "foreign" ? militaryValidation : null,
      4: driverLicenseValidation,
      5: Step4Schema,
      6: ContactInfoValidation,
      7: EducationValidation,
      8: academicDegreeValidation,
      9: socialStatusValidation,
      10: supplementalWagesValidation,
      11: reprimandRewardValidation,
      12: inventoriesValidation,
      13: certificationsValidation,
      14: bankInfoValidation,
      15: transportVehiclesValidation,
      16: documentsValidation,
      17: deductionWagesValidation,
    };

    // Adjust step for foreign nationality

    return stepValidations[step] || null;
  };

  const validationSchema = getValidationSchema(
    emp?.activeStep,
    nationality,
    isCurrentlyWorking
  );
  return (
    <Card mb={6}>
      <CardContent>
        <LinearProgress variant="determinate" value={calculateProgress()} />

        <Formik
          initialValues={emp?.data}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, isSubmitting, isValid, validateForm, resetForm }) => (
            <>
              <MyForm
                values={values}
                addToStaff={addToStaff}
                currentStep={emp?.activeStep}
                handleAddToStaff={handleAddToStaff}
                handleNext={handleNext}
                goBack={goBack}
                pin={preDefinedPin}
                handleOpenIDScan={handleOpenIDScan}
                handlePrevious={handlePrevious}
                isHas={isHas}
                isSubmitting={isSubmitting}
                positionId={positionId}
                renderStep={renderStep}
                setIsHas={setIsHas}
                showAddStaff={showAddStaff}
                structureById={structureById}
                totalSteps={totalSteps}
                validateForm={validateForm}
                employee={employee}
                handleFinish={handleFinish}
                allStructures={allStructures}
              />
            </>
          )}
        </Formik>
      </CardContent>

      <NewCommonDataDialog
        openAddDialog={openAddDialog}
        setOpenAddDialog={setOpenAddDialog}
        submitted={submitted}
        setSubmitted={setSubmitted}
        selectedType={selectedType}
      />
      <Dialog maxWidth={"100%"} open={IDScan} onClose={handleCloseIDScan}>
        <Grid
          sx={{
            padding: "20px",
          }}
        >
          <Grid sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Grid>
              <Button
                onClick={() => setShowCam(!showCam)}
                sx={
                  picUpload
                    ? {
                        display: "none",
                      }
                    : {
                        color: "#fff",
                        backgroundColor: "#407ad6",
                        width: "200px",
                      }
                }
              >
                <ArrowBackIcon
                  sx={showCam ? { marginRight: "20px" } : { display: "none" }}
                />
                {!showCam ? "Skan et" : "Geriyə"}
              </Button>
              <Tooltip
                title={
                  "Qoşulduğunuz cihazın kamerası vasitəsilə şəxsiyyət vəsiqəsini skan edin"
                }
                sx={showCam || picUpload ? { display: "none" } : {}}
              >
                <IconButton>
                  <Info />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid>
              <Button
                onClick={() => setPicUpload(!picUpload)}
                sx={
                  showCam
                    ? {
                        display: "none",
                      }
                    : {
                        color: "#fff",
                        backgroundColor: "#407ad6",
                        width: "200px",
                      }
                }
              >
                <ArrowBackIcon
                  sx={picUpload ? { marginRight: "20px" } : { display: "none" }}
                />
                {!picUpload ? "Skan edilmiş şəkli yüklə" : "Geriyə"}
              </Button>
              <Tooltip
                title={
                  "Skan edilmiş jpeg və ya png formatında olan şəkilləri sistemə yükləyin"
                }
                sx={showCam || picUpload ? { display: "none" } : {}}
              >
                <IconButton>
                  <Info />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid sx={showCam ? {} : { display: "none" }}>
            <CameraCapture stopCam={!showCam} />
          </Grid>
          <Grid sx={picUpload ? {} : { display: "none" }}>
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                paddingTop: "50px",
              }}
            >
              <label>
                <input
                  style={{ width: "200px", height: "25px" }}
                  type="file"
                  onChange={handleFileChange}
                  accept="image/jpeg, image/png"
                />
              </label>
              <button
                style={{
                  backgroundColor: "#407ad6",
                  color: "#FFF",
                  width: "200px",
                  height: "25px",
                  textAlign: "center",
                  borderRadius: "5px",
                  border: "none",
                }}
              >
                Yüklə
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </Card>
  );
}

const MyForm = ({
  currentStep,
  handleOpenIDScan,
  renderStep,
  showAddStaff,
  totalSteps,
  handlePrevious,
  validateForm,
  handleNext,
  goBack,
  isHas,
  setIsHas,
  isSubmitting,
  handleAddToStaff,
  positionId,
  addToStaff,
  structureById,
  employee,
  handleFinish,
  allStructures,
  pin,
}) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    // setFieldValue("pin", pin);
    dispatch(setEmployees(values));
  }, [dispatch, values]);

  return (
    <Form>
      {currentStep === 1 ? (
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              backgroundColor: "#407ad6",
              color: "#FFF",
              marginTop: "10px",
            }}
            onClick={handleOpenIDScan}
          >
            Ş.V. skan et
          </Button>
        </Grid>
      ) : (
        <></>
      )}
      {renderStep(currentStep, values, showAddStaff)}
      <Grid
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: goBack
            ? "space-between"
            : currentStep === 1 || currentStep === totalSteps
            ? "flex-end"
            : "space-between",
        }}
      >
        {goBack && currentStep === 1 && (
          <Button onClick={goBack} variant="contained">
            Geri
          </Button>
        )}
        {currentStep > 1 &&
          currentStep !== totalSteps &&
          !employee &&
          showAddStaff === false && (
            <Button
              type="button"
              onClick={() => {
                handlePrevious();
              }}
            >
              Əvvəlki
            </Button>
          )}
        {currentStep < totalSteps &&
          currentStep !== 17 &&
          !employee &&
          showAddStaff === false && (
            <Button
              variant="contained"
              type="button"
              onClick={() => {
                if (currentStep === 12) {
                  checkUniquenessForNext(
                    values.inventories,
                    ["serialNumber", "number"],
                    ["inventar seriya nömrəsi", "inventar nömrəsi"],
                    validateForm,
                    handleNext
                  );
                } else if (currentStep === 14) {
                  checkUniquenessForNext(
                    values.bankInfos,
                    ["iban"],
                    ["IBAN"],
                    validateForm,
                    handleNext
                  );
                } else {
                  validateForm().then((errors) => {
                    if (Object.keys(errors).length === 0) {
                      handleNext();
                    }
                    if (currentStep === 4 && isHas === false) {
                      handleNext();
                    }
                  });
                }
                if (isHas === true) {
                  setIsHas(true);
                }
              }}
              sx={{ justifyContent: "flex-end" }}
              disabled={isSubmitting}
            >
              Növbəti
            </Button>
          )}
        {currentStep === 17 && !employee && (
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            Əməkdaş yarat
          </Button>
        )}
        {currentStep === totalSteps && employee && (
          <div style={{ display: "flex", gap: "20px" }}>
            {allStructures?.length > 0 && (
              <Button
                onClick={handleAddToStaff}
                variant="contained"
                disabled={isSubmitting || !positionId}
              >
                Əməkdaşı ştata əlavə et
              </Button>
            )}
            <Button onClick={handleFinish} variant="contained">
              Bitir
            </Button>
          </div>
        )}
        {currentStep === totalSteps && !employee && (
          <div style={{ display: "flex", gap: "20px" }}>
            {allStructures?.length > 0 && (
              <Button
                onClick={handleAddToStaff}
                variant="contained"
                disabled={isSubmitting || !positionId}
              >
                Əməkdaşı ştata əlavə et
              </Button>
            )}
            <Button onClick={handleFinish} variant="contained">
              Bitir
            </Button>
          </div>
        )}
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: "10px",
        }}
      >
        {currentStep > 6 &&
          currentStep < 17 &&
          showAddStaff === false &&
          !employee && (
            <Button variant="contained" type="submit" disabled={isSubmitting}>
              Əməkdaş yarat
            </Button>
          )}
      </Grid>
      {showAddStaff && currentStep !== totalSteps && employee && (
        <Grid sx={{ display: "flex", gap: "20px" }}>
          {allStructures?.length > 0 && (
            <Button
              onClick={handleAddToStaff}
              variant="contained"
              disabled={
                structureById?.isVerified === true
                  ? isSubmitting || !positionId || !addToStaff.contractId
                  : structureById?.isVerified === true
              }
            >
              Əməkdaşı ştata əlavə et
            </Button>
          )}
          <Button onClick={handleFinish} variant="contained">
            Bitir
          </Button>
        </Grid>
      )}
      {}
    </Form>
  );
};
// Camera Component

const CameraCapture = ({ stopCam }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const capturedImageRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [capture, setCapture] = useState(false);

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  };
  useEffect(() => {
    if (stopCam) {
      stopCamera();
    }
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream, stopCam]);

  const startCamera = () => {
    setCapture(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((mediaStream) => {
        setStream(mediaStream);
        if (videoRef.current) {
          videoRef.current.srcObject = mediaStream;
        }
      })
      .catch((error) => {
        console.error("Error accessing camera:", error);
      });
  };

  const handleCapture = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      context.drawImage(
        videoRef.current,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      const imageUrl = canvasRef.current.toDataURL("image/png");

      capturedImageRef.current.src = imageUrl;
      capturedImageRef.current.style.display = "block";
    }
  };

  return (
    <div>
      <Button onClick={startCamera}>Kameranı aç</Button>
      <Button onClick={handleCapture} sx={capture ? {} : { display: "none" }}>
        Şəkil çək
      </Button>
      <div style={{ display: "flex", gap: "10px" }}>
        <video ref={videoRef} width="550" height="450" autoPlay></video>

        <canvas
          ref={canvasRef}
          width="550"
          height="450"
          style={{ display: "none" }}
        ></canvas>
        <img
          width="550"
          height="450"
          ref={capturedImageRef}
          alt="Captured Image"
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
};

function Blank({ goBack }) {
  return (
    <React.Fragment>
      <Helmet title="Əməkdaş əlavə et" />
      <Typography variant="h3" gutterBottom display="inline">
        Əməkdaş əlavə et
      </Typography>

      <Divider />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AddEmployee goBack={goBack} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Blank;
