import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useAuth from "./useAuth";
import { useDispatch } from "react-redux";
import { getEmployeeByUserId } from "../api/api";
import { setCurrentEmployee } from "../redux/features/slices/commonThings.slice";

const useDashboard = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);

  const handleOpen = () => {
    setAnchorEl(true);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const { user } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    //Get current employee
    if (user) {
      const getUser = async () => {
        try {
          const response = await getEmployeeByUserId(user.id);
          if (response && response.data) {
            dispatch(setCurrentEmployee(response.data));
          }
        } catch (err) {
          console.log(err);
        }
      };
      getUser();
    }
  }, [user]);
  return {
    isLgUp,
    anchorEl,
    mobileOpen,
    handleOpen,
    setAnchorEl,
    handleClose,
    handleDrawerToggle,
  };
};

export default useDashboard;
