import { api } from "../../api/axiosInterceptor";

export const getFitlersData = async ({
  dataGridName,
  structures = [],
  topPartOfStructures = [],
  baseStructureName = "",
  topPartOfStructureName = "",
  subStructureName = "",
  positionName = "",
  es = [],
}) => {
  const esPart =
    es?.length > 0
      ? `/employee/static-data/date-list?es=${encodeURIComponent(
          JSON.stringify(es)
        )}&`
      : `/employee/static-data/filters?`;

  const url =
    esPart +
    `dataGridName=${dataGridName}` +
    (structures.length > 0 ? `&structures=${structures.join(",")}` : "") +
    (topPartOfStructures.length > 0
      ? `&topPartOfStructures=${topPartOfStructures.join(",")}`
      : "") +
    (baseStructureName ? `&baseStructureName=${baseStructureName}` : "") +
    (topPartOfStructureName
      ? `&topPartOfStructureName=${topPartOfStructureName}`
      : "") +
    (subStructureName ? `&subStructureName=${subStructureName}` : "") +
    (positionName ? `&positionName=${positionName}` : "");

  return await api.get(url);
};
