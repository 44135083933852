import React, { Fragment, useState } from "react";
import styled from "styled-components";
import ButtonCustom from "../../ui/Button/ButtonCustom";
import Input from "../../ui/Input/Input";
import SelectInput from "../../ui/Select/SelectInput";
import ColumnsVisibility from "./ColumnsVisibility";
import Filter from "./Filter/Filter";
import { FilterAlt as FilterIcon, RestartAlt } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import ResetGraphicDialog from "../../../pages/working-time/working-hours-graphic/ResetWorkingHours";

const arr = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
const rowArr = [5, 10, 15, 20, 25, 50, 100];

const Container = styled.div`
  padding: 10px 0px;
  width: 100%;
  height: auto;
`;
const Div = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
`;
const rotateAnimation = {
  transition: "transform 0.8s ease-in-out",
  transform: "rotate(360deg)",
};

export default function Toolbar({
  fontSize,
  fontSizeHandler,
  limit,
  setLimit,
  columns = { data: [], setData: () => {} },
  applyFiltersHandler,
  cancelAppliedFilters,
  quickFilters,
  downloadHandler,
  addLeftContButtons,
  addRightContButtons,
  hideFilter = false,
  hideColumnVisibility = false,
  hideDownload = false,
  setColWidth,
  colWidth,
  editMode,
  setShowFilter,
  resetAll,
  showReset,
  isAllShown,
  setIsAllShown,
}) {
  const [resetDialog, setResetDialog] = useState(false);
  const fontSizeChangeHandler = (value) => {
    fontSizeHandler(+value);
  };

  const rowCountCahngeHandler = (value) => {
    setLimit(+value);
  };

  const FontSizer = (
    <div
      style={{
        display: "flex",
        width: "100px",
      }}
    >
      <SelectInput
        dropdownHeight={"300px"}
        value={fontSize}
        onChange={fontSizeChangeHandler && fontSizeChangeHandler}
        label={"Şrift ölçüsü: "}
      >
        {arr.map((item, index) => (
          <option
            key={`${item}${index}`}
            style={{
              textAlign: "center",
              width: "100%",
              backgroundColor: item === fontSize && "rgba(255,255,255,0.5)",
            }}
            value={item}
          >
            {item}
          </option>
        ))}
      </SelectInput>
    </div>
  );

  const RowCounter = (
    <div
      style={{
        display: "flex",
        width: "100px",
        marginLeft: "5px",
      }}
    >
      <SelectInput
        dropdownHeight={"auto"}
        value={limit}
        onChange={rowCountCahngeHandler && rowCountCahngeHandler}
        label={"Sətir sayı: "}
      >
        {rowArr.map((item, index) => (
          <option
            key={`${item}${index}`}
            style={{
              textAlign: "center",
              width: "100%",
              backgroundColor: item === limit && "rgba(255,255,255,0.5)",
            }}
            value={item}
          >
            {item}
          </option>
        ))}
      </SelectInput>
    </div>
  );

  return (
    <>
      <Container>
        <div style={{ display: "flex", height: "35px" }}>
          <Div>
            {!hideColumnVisibility && (
              <div style={{ marginRight: "5px" }}>
                <ColumnsVisibility
                  width={"80px"}
                  data={columns.data}
                  setData={columns.setData}
                  isAllShown={isAllShown}
                  setIsAllShown={setIsAllShown}
                />
              </div>
            )}
            {!hideFilter && (
              <div>
                <Filter
                  columns={columns.data}
                  applyFiltersHandler={applyFiltersHandler}
                  cancelAppliedFilters={cancelAppliedFilters}
                  quickFilters={quickFilters}
                />
              </div>
            )}
            {addLeftContButtons?.map((item, index) => (
              <div key={`${item} ${index}`}>{item}</div>
            ))}
          </Div>

          <Div style={{ display: "flex", justifyContent: "flex-end" }}>
            {setShowFilter && (
              <div className="me-2 d-flex aic">
                <Tooltip title="Qrafiki yenilə">
                  <Button
                    variant="outlined"
                    resetAll
                    onClick={() => {
                      setResetDialog(true);
                    }}
                    disabled={showReset}
                  >
                    <RestartAlt
                      sx={{ rotate: "90deg" }}
                      style={showReset ? rotateAnimation : {}}
                    />
                  </Button>
                </Tooltip>
                <Tooltip title="Filter et" className="ms-2">
                  <Button variant="outlined" onClick={() => setShowFilter()}>
                    <FilterIcon />
                  </Button>
                </Tooltip>
                {/* <ButtonCustom
                onClick={() => setShowFilter()}
                width={"100%"}
                tooltip="Filter et"
                variant="outlined"
              >
                <FilterIcon />
              </ButtonCustom> */}
              </div>
            )}
            {editMode && (
              <div className="me-2">
                <Tooltip title="Filter et">
                  <Button variant="outlined" onClick={() => editMode()}>
                    Cədvələ dəyişiklik et
                  </Button>
                </Tooltip>
                {/* <ButtonCustom
                onClick={() => editMode()}
                width={"200px"}
                variant="outlined"
              >
                Cədvələ dəyişiklik et
              </ButtonCustom> */}
              </div>
            )}
            {!hideDownload && (
              <ButtonCustom onClick={downloadHandler} width={"80px"}>
                Yüklə
              </ButtonCustom>
            )}
            {addRightContButtons?.map((item, index) => (
              <div key={`${item} ${index}`}>{item}</div>
            ))}
          </Div>
        </div>

        <div style={{ marginTop: "10px", display: "flex" }}>
          {FontSizer}
          {RowCounter}
          <Input
            onChange={(e) => {
              if (+e.target.value === 0) {
                setColWidth("");
              } else {
                setColWidth(+e.target.value);
              }
            }}
            label={"Sütun eni: "}
            styleContainer={{ width: "110px", marginLeft: "5px" }}
            value={colWidth}
            type={"number"}
            min={50}
          />
        </div>
      </Container>
      <ResetGraphicDialog
        reset={resetAll}
        title={"İş vaxtının qrafikini"}
        resetDialog={resetDialog}
        subTitle={"Əməliyyata davam etsəniz iş vaxtının qrafiki"}
        setResetDialog={setResetDialog}
      />
    </>
  );
}
