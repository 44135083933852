import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import VacationReporting from "../../../layouts/vacations/reporting/VacationReporting";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import styled from "styled-components";
import { downloadVacationRequestManager } from "../../../api/employeeRequests/employeeRequests";
import MultiDatePicker from "../../../layouts/vacations/multiDatePicker/MultiDatePicker";
import { downloadFile } from "../../../utils/downloadCSV";
import { removeEmptyStrings } from "../../../utils/removeEmptyString";
import FindSubstructure from "../../dialogs/FindSubstructure";
import FindTopPartRels from "../../dialogs/FindTopPartRels";
import { getStaticData } from "../../../services/employees/StaticDataServices";
const ErrorContainer = styled.div`
  height: 18px; /* Adjust this height based on your error message height */
`;
const ReportingForm = ({
  requestStatus,
  headerTitle,
  requestType,
  reportingFormat,
  // if disable[component] is true then not rendering on ui
  disableRequestType,
  disableCreationDate,
  disableReportFormat,
  disableStatus,
  disableDecisionDate,
  disableStructure,
  // if endpoint undefined then running this.handleReportDownload method
  // else if endpoint have function then running endpoint()
  endpoint = undefined,
}) => {
  const [status, setStatus] = useState([]);
  const [selectedStatusNames, setSelectedStatusNames] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedDocumentsNames, setSelectedDocumentsNames] = useState([]);

  // Structure dialog
  const [selectedStructure, setSelectedStructure] = useState([]);
  const [documentsType, setDocumentsType] = useState([]);
  const [structureNames, setStructureNames] = useState([]);
  const [selectedSubstructure, setSelectedSubstructure] = useState([]);
  const [substructureNames, setSubstructureNames] = useState([]);
  const [structureDialog, setStructureDialog] = useState(false);
  const [substructureDialog, setSubstructureDialog] = useState(false);

  // Date ranges
  const [creationDate, setCreationDate] = useState({
    fromDate: "",
    toDate: "",
  });
  const creationDateError =
    creationDate.toDate === "undefined/undefined/undefined";
  const [updateDate, setUpdateDate] = useState({
    fromDate: "",
    toDate: "",
  });
  const [decisionDate, setDecisionDate] = useState({
    fromDate: "",
    toDate: "",
  });
  const decisionDateError =
    decisionDate.toDate === "undefined/undefined/undefined";

  const [credentials, setCredentials] = useState({
    status: "",
    creationDateFrom: "",
    creationDateTo: "",
    updateDateFrom: "",
    updateDateTo: "",
    decisionDateFrom: "",
    decisionDateTo: "",
    structures: "",
  });

  useEffect(() => {
    setCredentials({
      status: status,
      creationDateFrom: creationDate.fromDate,
      creationDateTo: creationDate.toDate,
      updateDateFrom: updateDate.fromDate,
      updateDateTo: updateDate.toDate,
      decisionDateFrom: decisionDate.fromDate,
      decisionDateTo: decisionDate.toDate,
      structures: selectedStructure,
    });
  }, [status, creationDate, updateDate, decisionDate, selectedStructure]);
  async function getDocumentsType() {
    try {
      const response = await getStaticData(["contractTypes"]);

      setDocumentsType(response?.data?.[0]?.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      await getDocumentsType();
    };

    fetchData();
  }, []);
  const handleMultipleChoice = (event) => {
    const {
      target: { value },
    } = event;
    if (event?.target?.name === "documents") {
      const selectedNames = documentsType
        .filter((item) => value.includes(item?.name))
        .map((item) => item?.label);
      setDocuments(value);
      setSelectedDocumentsNames(selectedNames);
    } else {
      const selectedNames = requestStatus
        .filter((item) => value.includes(item?.value))
        .map((item) => item?.name);

      setStatus(value);
      setSelectedStatusNames(selectedNames);
    }
  };

  const handleReportDownload = async (values) => {
    const apiParams = {
      requestType: requestType.value,
      status: status.join(", "),
      creationDateFrom: creationDate.fromDate,
      creationDateTo: creationDate.toDate,
      updateDateFrom: updateDate.fromDate,
      updateDateTo: updateDate.toDate,
      decisionDateFrom: decisionDate.fromDate,
      decisionDateTo: decisionDate.toDate,
      structures: selectedStructure.join(", "),
    };
    const modifiedParams = removeEmptyStrings(apiParams);
    const response = await downloadVacationRequestManager(modifiedParams);
    downloadFile(response);
  };
  return (
    <VacationReporting reportingTitle={headerTitle}>
      <Formik
        initialValues={{
          reportFormat: "",
          requestType: "",
          structure: "",
        }}
      >
        {({ values, errors }) => (
          <>
            <Form>
              <Grid
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <Grid>
                  <Grid
                    sx={{
                      width: "100%",
                      display: disableRequestType && "none",
                      // paddingY: 10,
                    }}
                  >
                    <ErrorContainer></ErrorContainer>
                    <FormControl fullWidth>
                      <InputLabel id="documents">Sənədin növü</InputLabel>
                      <Select
                        labelId="documents"
                        label="Sənədin növü"
                        id="documents"
                        name="documents"
                        sx={{
                          maxWidth: "501px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        multiple
                        value={documents}
                        onChange={handleMultipleChoice}
                        // input={<OutlinedInput label="status" />}
                        renderValue={() => selectedDocumentsNames.join(", ")}
                      >
                        {documentsType &&
                          documentsType?.map((item, index) => (
                            <MenuItem key={index} value={item?.name}>
                              <Checkbox
                                checked={documents.indexOf(item?.name) > -1}
                              />
                              <ListItemText primary={item?.label} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <ErrorContainer></ErrorContainer>
                  </Grid>

                  <Grid sx={{ width: "100%" }}>
                    <FormControl fullWidth>
                      {!disableStatus && (
                        <>
                          <InputLabel id="status">Sorğu Statusu</InputLabel>
                          <Select
                            labelId="status"
                            label="Sorğu Statusu"
                            id="status"
                            multiple
                            sx={{
                              maxWidth: "501px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            value={status}
                            onChange={handleMultipleChoice}
                            // input={<OutlinedInput label="status" />}
                            renderValue={() => selectedStatusNames.join(", ")}
                          >
                            {requestStatus &&
                              requestStatus?.map((item, index) => (
                                <MenuItem key={index} value={item?.value}>
                                  <Checkbox
                                    checked={status.indexOf(item?.value) > -1}
                                  />
                                  <ListItemText primary={item?.name} />
                                </MenuItem>
                              ))}
                          </Select>
                        </>
                      )}
                    </FormControl>
                    <ErrorContainer></ErrorContainer>
                  </Grid>
                </Grid>

                <Grid>
                  <Grid sx={{ width: "100%" }}>
                    <ErrorContainer></ErrorContainer>
                    {!disableCreationDate && (
                      <MultiDatePicker
                        range={creationDate}
                        setRange={setCreationDate}
                        title={"Sorğu yaradılma tarixi"}
                        maxDate={new Date()}
                      />
                    )}
                    <ErrorContainer>
                      {creationDateError && (
                        <FormHelperText sx={{ color: "#f44336" }}>
                          Bitmə tarixini seçin
                        </FormHelperText>
                      )}
                    </ErrorContainer>
                  </Grid>

                  <Grid sx={{ width: "100%" }}>
                    {!disableDecisionDate && (
                      <MultiDatePicker
                        range={decisionDate}
                        setRange={setDecisionDate}
                        title={"Sorğu qərarı tarixi"}
                        maxDate={new Date()}
                      />
                    )}
                    <ErrorContainer>
                      {decisionDateError && (
                        <FormHelperText sx={{ color: "#f44336" }}>
                          Bitmə tarixini seçin
                        </FormHelperText>
                      )}
                    </ErrorContainer>
                  </Grid>
                </Grid>
                <Grid>
                  {!disableReportFormat ? (
                    <Grid
                      sx={{
                        width: "100%",
                      }}
                    >
                      <ErrorContainer></ErrorContainer>
                      <FormControl fullWidth>
                        {
                          <Grid>
                            <InputLabel id="reportFormat">
                              Hesabat formatı
                            </InputLabel>
                            <Field
                              as={Select}
                              name="reportFormat"
                              labelId="reportFormat"
                              label="Hesabat formatı"
                              id="reportFormat-select"
                              fullWidth
                            >
                              {reportingFormat &&
                                reportingFormat?.map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                            </Field>
                          </Grid>
                        }
                      </FormControl>
                      <ErrorContainer></ErrorContainer>
                    </Grid>
                  ) : null}

                  <Grid sx={{ width: "100%" }}>
                    <Grid sx={{ display: "flex", gap: "8px" }}>
                      <FormControl
                        onClick={() => {
                          setStructureDialog(true);
                        }}
                        fullWidth
                      >
                        {!disableStructure && (
                          <TextField
                            name="contactDetails"
                            label="Struktur"
                            fullWidth
                            variant="outlined"
                            value={structureNames.join(", ") || ""}
                            my={2}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        )}
                      </FormControl>
                      <FormControl
                        onClick={() => {
                          setSubstructureDialog(true);
                        }}
                        fullWidth
                      >
                        <TextField
                          name="contactDetails"
                          label="Sub-struktur"
                          fullWidth
                          variant="outlined"
                          value={substructureNames.join(", ") || ""}
                          my={2}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <ErrorContainer></ErrorContainer>
                  </Grid>

                  {structureDialog && (
                    <FindTopPartRels
                      selectedStructure={selectedStructure}
                      setSelectedStructure={setSelectedStructure}
                      structureDialog={structureDialog}
                      setStructureDialog={setStructureDialog}
                      structureNames={structureNames}
                      setStructureNames={setStructureNames}
                      pageKey="doc-sign"
                    />
                  )}
                  {substructureDialog && (
                    <FindSubstructure
                      selectedStructure={selectedSubstructure}
                      setSelectedStructure={setSelectedSubstructure}
                      structureDialog={substructureDialog}
                      setStructureDialog={setSubstructureDialog}
                      structureNames={substructureNames}
                      setStructureNames={setSubstructureNames}
                      pageKey="doc-sign"
                    />
                  )}
                </Grid>

                {/* <Grid
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  px: 3,
                  py: 2,
                  borderRadius: 2,
                  backgroundColor: "#0F4C75",
                  cursor: "pointer",
                }}
              >
                <CloudDownloadIcon sx={{ width: 30, height: 30 }} />
              </Grid> */}
              </Grid>
              <div className="d-flex justify-content-end">
                <IconButton
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: 2,
                    width: "16%",
                    backgroundColor: "rgba(50,109,181, 0.9)",
                  }}
                  onClick={() => {
                    endpoint(credentials);
                  }}
                >
                  <CloudDownloadIcon sx={{ width: 20, height: 20 }} />
                  <Typography>Yüklə</Typography>
                </IconButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </VacationReporting>
  );
};

export default ReportingForm;
