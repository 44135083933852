/* eslint-disable prettier/prettier */
import { Close, EditSharp, Person, RemoveRedEye } from "@mui/icons-material";
import { Button, Dialog, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import DocumentDialog from "../../components/dialogs/documentDialog/DocumentDialog";
import {
  EMPLOYMENT_CONTRACT_TYPE,
  GENERAL_DOCUMENTS_FOR_FILTER,
  OPERATORS_CONTAINING,
  STAFF_DROPDOWN_VALUE,
  clauses_EM,
} from "../../constants";
import {
  downloadContFilter,
  getAllContracts,
  getContractsWithFilter,
  sendTerminateApplication,
  sendTerminateDirectly,
  sendTerminateExplanation,
} from "../../services/documentations/EmploymentContractsService";
import { downloadFile } from "../../utils/downloadCSV";
import { getStaticFilterValues } from "../../utils/forFilterDropdown/getStructureUtils";
import { getDescendantProp as getValue } from "../../utils/helperFunctions";
import "./allcontract.css";

import { toast } from "react-toastify";
import OptionGroup from "../../components/OptionGroup/OptionGroup";
import StatusGenerator from "../../components/table/StatusGenerator";
import ButtonCustom from "../../components/ui/Button/ButtonCustom";
import ButtonDropdown from "../../components/ui/ButtonDropdown/ButtonDropdown";
import Column from "../../models/table/Column";
import { GENERAL_BLOCK } from "../../styled";
import Table from "./../../components/table/Table";
import { getLabourContractDoc_SERVICE } from "../../services/documentServices";
import { Link } from "react-router-dom";
import ContractDialog from "../../components/dialogs/documentDialog/ContractDialog";
import { handleApiError } from "../../api/changeRequests";
import { padding, width } from "@mui/system";

const bgColorDialog = (type) => {
  switch (type) {
    case EMPLOYMENT_CONTRACT_TYPE.PENDING:
      return "rgba(95,154,226, 0.95)";
    case EMPLOYMENT_CONTRACT_TYPE.REJECTED:
      return "rgba(208, 130, 130, 0.95)";
    case EMPLOYMENT_CONTRACT_TYPE.APPROVED:
      return "rgba(141,181,160,0.95)";
    case EMPLOYMENT_CONTRACT_TYPE.EXPIRED:
      return "grey";
    case EMPLOYMENT_CONTRACT_TYPE.TERMINATED:
      return "purple";
    default:
      break;
  }
};

export default function LabourContractsTable() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState();
  const [limit, setLimit] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [filters, setFilters] = useState([]);
  const [contId, setContId] = useState("");

  const [contDialogItem, setContDialogItem] = useState();
  const [showContDialog, setShowContDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorId, setAnchorId] = useState("");

  const [show_teminate_dialog, set_show_teminate_dialog] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState({});

  const [terminate_state, set_terminate_state] = useState({
    labourContractId: "",
    reason: "",
    clause: "",
    jobTerminationDate: "",
  });

  const [app_exp_selection, show_app_exp_selection] = useState({
    step1: false,
    step2: false,
  });
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    paginationFetchingHandler();
  }, [filters, limit, offset]);

  const paginationFetchingHandler = async () => {
    if (!filters.length > 0) {
      await fetchData();
    } else {
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));
      await fetchData(encodedFilter);
    }
  };

  const fetchData = async (filters = undefined) => {
    try {
      setIsLoading(true);
      const payload = {
        offset: offset,
        limit: limit,
        filter: filters,
      };

      const { data } = !filters
        ? await getAllContracts(filters, offset ? offset : 0, limit ? limit : 5)
        : await getContractsWithFilter(filters, offset, limit);

      setTotal(data.total);
      setData([...data.contracts]);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };
  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const handleOpenPopover = (event, id) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setAnchorId(id);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
    setAnchorId("");
  };
  const handleOptionClick = (option) => {
    // Add your logic here for handling the selected option
    handleClosePopover();
  };

  const [dataID, setDataID] = useState();
  const columns = [
    new Column({
      path: "#",
      headerName: "Operator",
      disableColSearch: true,
      renderCell: (row, column) => {
        if (row.status === EMPLOYMENT_CONTRACT_TYPE.TERMINATED) {
          return (
            <div
              style={{
                position: "relative",
                display: "flex",
                height: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <ButtonCustom
                onClick={(e) => {
                  setDataID(row._id);
                  handleOpenPopover(e, row._id);
                }}
                children={<RemoveRedEye />}
                style={{ height: "100%", background: "none" }}
                styleContainer={{ height: "auto" }}
              />
              {/* <DocumentDialog
                documentId={row._id}
                disableSigning={true}
                fetchHandler={getLabourContractDoc_SERVICE}
                // buttonGroup={button_group}
                setStatus={setStatus}
                refreshPage={paginationFetchingHandler}
              /> */}
              <ButtonDropdown
                dropdownComponent={
                  <Grid sx={{ flexDirection: "column", display: "flex" }}>
                    <Button
                      width={"100%"}
                      onClick={() => handleOptionClick("Option 1")}
                    >
                      <Typography color={"white"}>Xitam verilmiş ƏM</Typography>
                    </Button>
                    <Button
                      width={"100%"}
                      onClick={() => handleOptionClick("Option 2")}
                    >
                      <Typography color={"white"}>Xitam əmri</Typography>
                    </Button>
                  </Grid>
                }
                styleContainer={{ height: "auto", width: "30px" }}
                title={<Close />}
                styleButton={{ background: "none" }}
                styleBtnContainer={{ width: "33px" }}
              />
            </div>
          );
        } else {
          const editPath = `/labour-contract/edit/${row?.staffs?.[0]?._id}-${row?.staffs?.[0]?.contract}`;

          return (
            <div
              style={{
                position: "relative",
                display: "grid",
                columnGap: "5px",
                gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))",
                height: "100%",
              }}
            >
              <ButtonCustom
                onClick={() => {
                  handleRowDialogBtnClick(row._id);
                }}
                children={<RemoveRedEye />}
                style={{ height: "100%", background: "none" }}
                styleContainer={{ height: "auto" }}
              />
              {/* <DocumentDialog
                documentId={row._id}
                disableSigning={true}
                fetchHandler={getLabourContractDoc_SERVICE}
                // buttonGroup={button_group}
                setStatus={setStatus}
                refreshPage={paginationFetchingHandler}
              /> */}
              {row.status === EMPLOYMENT_CONTRACT_TYPE.DRAFT &&
                !row?.baseStructures?.[0].isVerified && (
                  <Link to={editPath} target="_blank">
                    <ButtonCustom
                      children={<EditSharp />}
                      style={{
                        height: "100%",
                        background: "none",
                      }}
                      styleContainer={{ height: "auto" }}
                    />
                  </Link>
                )}
              {row.status === EMPLOYMENT_CONTRACT_TYPE.APPROVED && (
                <>
                  <ButtonDropdown
                    dropdownComponent={
                      <Grid sx={{ flexDirection: "column", display: "flex" }}>
                        <Button
                          width={"100%"}
                          onClick={() => {
                            if (!row.isTerminable) {
                              toast.error(
                                "Qüvvədən salmaq üçün üçün mövcud əməkdaşı təsdiqləmə matrisindən çıxartmalısınız"
                              );
                              return;
                            }
                            set_terminate_state({
                              ...terminate_state,
                              labourContractId: row?._id,
                            });
                            set_show_teminate_dialog({
                              active: true,
                              type: "deactive",
                            });
                          }}
                        >
                          <Typography color={"white"}>
                            Əmək müqaviləsinin qüvvədən salımması
                          </Typography>
                        </Button>
                        <Button
                          width={"100%"}
                          onClick={() => {
                            if (!row.isTerminable) {
                              toast.error(
                                "Xitam vermək üçün mövcud əməkdaşı təsdiqləmə matrisindən çıxartmalısınız"
                              );
                              return;
                            }
                            set_terminate_state({
                              ...terminate_state,
                              labourContractId: row?._id,
                            });
                            set_show_teminate_dialog({
                              active: true,
                              type: "terminate",
                              id: row?._id,
                            });
                          }}
                        >
                          <Typography color={"white"}>
                            Əmək müqaviləsinə xitam verilməsi
                          </Typography>
                        </Button>
                      </Grid>
                    }
                    styleContainer={{ height: "auto" }}
                    title={<Close />}
                    styleButton={{ background: "none" }}
                  />
                </>
              )}
              {row && row?.staffs?.[0]?.labourTerminateOrder?.status === "approved" && (
              <Link to={`/documentation/create/static-order?value=${row._id}&name=compensation-order&module=true`}>
                <ButtonCustom
                      style={{
                        height: "100%",
                        background: "none",
                      }}
                      styleContainer={{ height: "auto" }}
                    >Kompensassiya əmri yarat</ButtonCustom>
              </Link>
              )}
            </div>
          );
        }
      },
    }),
    new Column({
      path: "data.docNumber",
      headerName: "Əmək M. NO",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => {
        return getValue(row, column.path + ".value");
      },
    }),
    new Column({
      path: "decisionDate",
      headerName: "ƏM bağlanma tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) => {
        if (!row?.decisionDate) return "-";
        return normalizeDate(getValue(row, column.path));
      },
    }),
    new Column({
      path: "subjects.subject.firstname",
      headerName: "Əməkdaş adı",
      filterColumnKey: "employeeFirstName",
      hasCellButton: true,
      cellButtonProps: {
        icon: <Person />,
        onClick: (value) => window.open(`/employees/${value}/view`),
        value: (row) => {
          return getValue(row, "subjects.subject.id", true);
        },
      },
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => {
        return getValue(row, column.path, true);
      },
    }),
    new Column({
      path: "subjects.subject.lastname",
      headerName: "Əməkdaş soyadı",
      filterColumnKey: "employeeLastName",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => {
        return getValue(row, column.path, true);
      },
    }),
    new Column({
      path: "subjects.subject.fathername",
      headerName: "Əməkdaş ata adı",
      filterColumnKey: "employeeFatherName",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => {
        return getValue(row, column.path, true);
      },
    }),
    new Column({
      path: "data.organizationName",
      headerName: "Təşkilat",
      filterColumnKey: "baseStructures",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "labour-contract",
      keyForResponseDataFromFilter: "baseStructures",
      filterType: OPERATORS_CONTAINING.IN,
      getValue: (row, column) => {
        return getValue(row, column.path + ".value");
      },
    }),
    new Column({
      path: "topPartOfStructures.source.name",
      headerName: "Struktur",
      filterColumnKey: "topPartOfStructures",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "labour-contract",
      dependencieKeysOfFilter: {
        structures: ["baseStructures"],
      },
      keyForResponseDataFromFilter: "topPartOfStructures",
      forExportIsArr: true,
      filterType: OPERATORS_CONTAINING.IN,
      getValue: (row, column) => {
        return getValue(row, column.path, true);
      },
    }),
    new Column({
      path: "subStructures.source.name",
      headerName: "Sub-Struktur",
      filterColumnKey: "subStructures",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "labour-contract",
      dependencieKeysOfFilter: {
        structures: ["baseStructures"],
        topPartOfStructures: ["topPartOfStructures"],
      },
      keyForResponseDataFromFilter: "subStructures",
      forExportIsArr: true,
      filterType: OPERATORS_CONTAINING.IN,
      getValue: (row, column) => getValue(row, column.path, true),
    }),
    new Column({
      path: "staffs.position.name",
      headerName: "Vəzifə",
      filterColumnKey: "positions",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "labour-contract",
      dependencieKeysOfFilter: {
        structures: ["baseStructures"],
        topPartOfStructures: ["topPartOfStructures", "subStructures"],
      },
      keyForResponseDataFromFilter: "positions",
      forExportIsArr: true,
      filterType: OPERATORS_CONTAINING.IN,
      getValue: (row, column) => getValue(row, column.path, true),
    }),
    new Column({
      path: "staffs.staffUnit",
      headerName: "Ştat",
      filterValues: STAFF_DROPDOWN_VALUE,
      forExportIsArr: true,
      filterType: OPERATORS_CONTAINING.OPTION_NUMBER,
      getValue: (row, column) => getValue(row, column.path, true),
    }),
    new Column({
      path: "data.contractStartDate",
      headerName: "Başlama tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) =>
        normalizeDate(getValue(row, column.path + ".value")),
    }),

    new Column({
      path: "data.contractEndDate",
      headerName: "Bitmə tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) => {
        if (!getValue(row, column.path + ".value"))
          return getValue(row, "data.contractPeriodType.value");
        return normalizeDate(getValue(row, column.path + ".value"));
      },
    }),
    new Column({
      path: "verifiers",
      headerName: "İmza Tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) => {
        const employee = row?.staffs?.[0]?.labourTerminateOrder?.data;

        if (
          (row?.status === "approved" || row?.status === "active") &&
          (row?.staffs?.[0]?.labourTerminateOrder?.status === "approved" ||
            row?.staffs?.[0]?.labourTerminateOrder?.status === "active")
        ) {
          return (
            <div>
              <p>{employee?.jobTerminationDate}</p>
              <p>{employee?.employeeName}</p>
            </div>
          );
        } else {
          return "-";
        }
      },
    }),
    new Column({
      path: "status",
      headerName: "Cari status",
      filterType: OPERATORS_CONTAINING.SINGLE_OPTION,
      filterValues: GENERAL_DOCUMENTS_FOR_FILTER,
      renderCell: (row, column) => {
        const isVerified = row?.baseStructures?.[0]?.isVerified;
        const approvers = [];
        row.staffs &&
          row.staffs.map((item, index) => {
            let status;
            if (item.status === "contract_in_draft") {
              if (isVerified) {
                status = "pendingSignature";
              } else {
                status = "pendingApprove";
              }
            } else {
              status = item.status;
            }

            approvers.push({
              index: index + 1,
              employee: row.data.employeeFullName.value,
              status: status,
            });
          });
        return (
          <StatusGenerator
            isVerified={isVerified}
            data={row}
            approvement={approvers}
            value={getValue(row, column.path)}
            docType={"staffStatus"}
          />
        );
      },
    }),
  ];

  const changeSpecialColumnForExport = (obj) => {
    let newObj = {};

    if (obj.filterColumnKey === "employeeFirstName") {
      newObj = {
        fieldName: obj.headerName,
        path: "subject.firstname",
        isArr: obj.forExportIsArr,
        combineFields:
          obj.combineFields?.length > 0 ? obj.combineFields : undefined,
      };
    } else if (obj.filterColumnKey === "employeeLastName") {
      newObj = {
        fieldName: obj.headerName,
        path: "subject.lastname",
        isArr: obj.forExportIsArr,
        combineFields:
          obj.combineFields?.length > 0 ? obj.combineFields : undefined,
      };
    } else if (obj.filterColumnKey === "employeeFatherName") {
      newObj = {
        fieldName: obj.headerName,
        path: "subject.fathername",
        isArr: obj.forExportIsArr,
        combineFields:
          obj.combineFields?.length > 0 ? obj.combineFields : undefined,
      };
    } else {
      newObj = {
        fieldName: obj.headerName,
        path: obj.path,
        isArr: obj.forExportIsArr,
        combineFields:
          obj.combineFields?.length > 0 ? obj.combineFields : undefined,
      };
    }
    return newObj;
  };

  const allContExportHandler = async (columnsForExport) => {
    try {
      const forExport = [];
      const arrFilter = [];

      // const isWithFilter =
      //   filters.length === 1 && filters[0].columns.length === 0 ? true : false;

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        forExport.push(changeSpecialColumnForExport(item));
      });
      if (filters.length) {
        arrFilter.push(...filters);
      }
      const encodedColumns = encodeURIComponent(JSON.stringify(forExport));
      const encodedFilter = encodeURIComponent(JSON.stringify(arrFilter));
      const resp = await downloadContFilter(
        encodedColumns,
        encodedFilter,
        filters.length > 0
      );
      resp && downloadFile(resp);
    } catch (error) {
      handleApiError(error);
    }
  };

  const sendExplanation = async () => {
    let state = { ...terminate_state };
    state.jobTerminationDate = moment(state.jobTerminationDate).format(
      "MM/DD/YYYY"
    );
    try {
      setIsLoading(true);
      const { data } = await sendTerminateExplanation(state);
      set_show_teminate_dialog({ ...show_teminate_dialog, active: false });
      show_app_exp_selection({
        ...app_exp_selection,
        step1: false,
        step2: false,
      });
      set_terminate_state({
        ...terminate_state,
        clause: "",
        jobTerminationDate: "",
        reason: "",
      });
      paginationFetchingHandler();
      setIsLoading(false);
      enqueueSnackbar("İzahat sorğusu uğurla göndərildi", {
        variant: "success",
      });
    } catch (error) {
      handleApiError(error);
      setIsLoading(false);
    }
  };

  const sendApplication = async () => {
    let state = { ...terminate_state };
    state.jobTerminationDate = moment(state.jobTerminationDate).format(
      "MM/DD/YYYY"
    );
    try {
      setIsLoading(true);
      const { data } = await sendTerminateApplication(state);
      set_show_teminate_dialog({ ...show_teminate_dialog, active: false });
      show_app_exp_selection({
        ...app_exp_selection,
        step1: false,
        step2: false,
      });
      set_terminate_state({
        ...terminate_state,
        clause: "",
        jobTerminationDate: "",
        reason: "",
      });
      paginationFetchingHandler();
      setIsLoading(false);
      enqueueSnackbar("Ərizə sorğusu uğurla göndərildi", {
        variant: "success",
      });
    } catch (error) {
      handleApiError(error);
      setIsLoading(false);
    }
  };

  const sendTermDirectly = async () => {
    let state = { ...terminate_state };
    state.jobTerminationDate = moment(state.jobTerminationDate).format(
      "MM/DD/YYYY"
    );
    try {
      setIsLoading(true);
      const { data } = await sendTerminateDirectly(
        state,
        state?.labourContractId
      );
      if (data) {
        set_show_teminate_dialog({ ...show_teminate_dialog, active: false });
        enqueueSnackbar("Müqaviləyə uğurla xitam verildi", {
          variant: "success",
        });
        paginationFetchingHandler();
        setIsLoading(false);
      }
    } catch (error) {
      handleApiError(error);
      enqueueSnackbar(error, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };
  const handleCloseTerminateDialog = async () => {
    set_show_teminate_dialog(false);
    set_terminate_state({
      ...terminate_state,
      clause: [],
      jobTerminationDate: "",
      reason: "",
    });
  };

  const normalizeDate = (dateString) => {
    const date = moment(dateString);
    return date.format("DD/MM/YYYY");
  };

  const handleCellClick = (rowId) => {
    // Toggle the tooltip state for the clicked row
    setTooltipOpen((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const handleRowDialogBtnClick = (id) => {
    setContId(id);
    setShowContDialog(true);
  };

  const renderStatus = (status) => {
    switch (status) {
      case EMPLOYMENT_CONTRACT_TYPE.APPROVED:
        return "Qüvvədədir";
      case EMPLOYMENT_CONTRACT_TYPE.PENDING:
        return "İmza gözləyir";
      case EMPLOYMENT_CONTRACT_TYPE.REJECTED:
        return "İmzalanmamış";
      case EMPLOYMENT_CONTRACT_TYPE.EXPIRED:
        return "Müddəti bitmiş";
      case EMPLOYMENT_CONTRACT_TYPE.TERMINATED:
        return "Xitam verilmiş";
      case EMPLOYMENT_CONTRACT_TYPE.DRAFT:
        return "Ə.M təsdiq gözlənilir'";
      default:
        break;
    }
  };

  // let button_group = [
  //   ...(status === "draft"
  //     ? [
  //       {
  //         onClick: () =>
  //           appLabourContract(),
  //         label: "Təsdiqlə",
  //         color: "success",
  //         gap: "me-1",
  //       },
  //     ]
  //     : []),
  // ];

  return (
    <>
      <Grid sx={styles.allContractsContainer}>
        <Table
          columns={columns}
          data={data}
          totalRows={total}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={isLoading}
          applyFiltersHandler={applyFiltersHandler}
          cancelAppliedFilters={cancelAppliedFiltersHandler}
          downloadHandler={(columns) => allContExportHandler(columns)}
        />
      </Grid>

      {contId && showContDialog && (
        <ContractDialog
          id={contId}
          open={showContDialog}
          onClose={() => {
            setShowContDialog(false);
            setContId();
          }}
          disableSigning={true}
          fetchHandler={getLabourContractDoc_SERVICE}
        />
      )}
      <Dialog
        open={show_teminate_dialog?.active}
        onClose={handleCloseTerminateDialog}
        maxWidth={"1000px"}
        PaperProps={{
          style: {
            backgroundColor: bgColorDialog(contDialogItem?.status),
            opacity: 1,
            borderRadius: "15px",
          },
        }}
      >
        <div className="dialog-body">
          <h2 className="text-center">
            {show_teminate_dialog?.type === "deactive"
              ? "QÜVVƏDƏN SALINMA"
              : "XİTAM"}
          </h2>
          {show_teminate_dialog?.type === "deactive" ? (
            <>
              <p>
                "Ə.M. 49cu maddəsi - Əmək müqaviləsinin hüquqi qüvvəyə minməsi
                və düşməsi"
              </p>
              <br />
              <p>Səbəbi: </p>
              <TextField
                className="mt-2"
                fullWidth
                multiline
                rows={12}
                value={terminate_state.reason}
                onChange={(e) =>
                  set_terminate_state({
                    ...terminate_state,
                    reason: e.target.value,
                  })
                }
              />
              <DatePicker
                className="mt-2 w-50"
                //   {...field}
                // label="Elmi dərəcəni aldığı tarix"
                inputFormat="dd/MM/yyyy"
                value={terminate_state?.date || null}
                // maxDate={new Date()}
                minDate={new Date(new Date().getTime() - 86400000 * 30)}
                onChange={(date) =>
                  set_terminate_state({
                    ...terminate_state,
                    jobTerminationDate: date,
                  })
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </>
          ) : (
            <>
              <p className="mb-2 mt-4">
                Əmək Məcəlləsi (68.2, 69.3, 70, 72, 74, 75.2 )
              </p>

              <OptionGroup
                data={clauses_EM}
                position="absolute"
                value={terminate_state?.clause}
                set_value={(value) =>
                  set_terminate_state({ ...terminate_state, clause: value })
                }
                default_label="Ə.M maddəsi Seçin"
              />

              <br />
              <hr />
              <p>Səbəbi: </p>
              <TextField
                className="mt-2"
                fullWidth
                multiline
                rows={12}
                value={terminate_state.reason}
                onChange={(e) =>
                  set_terminate_state({
                    ...terminate_state,
                    reason: e.target.value,
                  })
                }
              />
              <div className="d-flex aic">
                <DatePicker
                  className="mt-2 w-50"
                  // label="Elmi dərəcəni aldığı tarix"
                  inputFormat="dd/MM/yyyy"
                  value={terminate_state?.jobTerminationDate || null}
                  onChange={(date) =>
                    set_terminate_state({
                      ...terminate_state,
                      jobTerminationDate: date,
                    })
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </div>
            </>
          )}
          <div className="d-flex jce mt-3">
            <Button
              className="me-2"
              onClick={(date) =>
                set_terminate_state({
                  ...terminate_state,
                  jobTerminationDate: "",
                  clause: [],
                  reason: "",
                })
              }
            >
              Sıfırla
            </Button>
            {/* {show_teminate_dialog?.type === "terminate" ? (
              <Button
                variant="contained"
                disabled={
                  !terminate_state.clause.length ||
                  terminate_state.jobTerminationDate === ""
                }
                onClick={sendTermDirectly}
              >
                Sorğu yarat
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() =>
                  show_app_exp_selection({
                    ...app_exp_selection,
                    step1: !app_exp_selection.step1,
                  })
                }
              >
                Sorğu yarat
              </Button>
            )} */}
          </div>

          <div className="d-flex jce mt-3">
            {show_teminate_dialog?.type === "terminate" ? (
              <Button
                variant="contained"
                disabled={
                  !terminate_state.clause.length ||
                  terminate_state.jobTerminationDate === ""
                }
                onClick={() => {
                  show_app_exp_selection({
                    ...app_exp_selection,
                    step1: !app_exp_selection.step1,
                    step2: false,
                  });
                }}
              >
                {app_exp_selection.step1 || app_exp_selection.step2
                  ? "Bağla"
                  : "Sorğu yarat"}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() =>
                  show_app_exp_selection({
                    ...app_exp_selection,
                    step1: !app_exp_selection.step1,
                  })
                }
              >
                Sorğu yarat
              </Button>
            )}
          </div>
          {app_exp_selection.step1 && (
            <div>
              <GENERAL_BLOCK f_size="18px">
                Əməkdaşa ərizə/izahat sorğusu yönləndirilsinmi?
              </GENERAL_BLOCK>
              <div className="mt-2">
                <Button
                  variant="contained"
                  onClick={() =>
                    show_app_exp_selection({
                      ...app_exp_selection,
                      step1: false,
                      step2: true,
                    })
                  }
                >
                  Bəli
                </Button>
                <Button
                  variant="contained"
                  className="ms-2"
                  onClick={sendTermDirectly}
                >
                  Xeyr, xitam ver
                </Button>
              </div>
            </div>
          )}
          {app_exp_selection.step2 && (
            <div>
              <Button variant="contained" onClick={sendExplanation}>
                İzahat sorğusu
              </Button>
              <Button
                variant="contained"
                className="ms-2"
                onClick={sendApplication}
              >
                Ərizə sorğusu
              </Button>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}

const styles = {
  statisticContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
  },
  endContractsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "400px",
    marginTop: "10px",
  },
  contractsSettingsCont: {
    width: "20%",
    borderRadius: 2,
    padding: 2.5,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    marginRight: 2,
  },
  settingsHeader: { fontSize: 16 },
  settingsLabelCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 2,
    marginLeft: 2,
  },
  allContractsContainer: {
    marginTop: 5,
    width: "100%",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
