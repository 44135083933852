// services/apiHandler.js
export const handleApiCall = async (apiFunction, ...args) => {
  try {
    const response = await apiFunction(...args);
    console.log(response);
    return response;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};
