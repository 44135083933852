import {
  getChangeRequest,
  getChangeRequests,
  getExplanation_Application_API,
  getExplanation_ApplicationRequest_API,
  postExplanation_Application_API,
} from "../api/cabinetRequests";
import { handleApiCall } from "./apiHandler";

// ⇩ ⇩ ⇩  Cabinet Services; ⇩ ⇩ ⇩
export const fetchChangeRequests = (filter, offset, limit) => {
  return handleApiCall(getChangeRequests, filter, offset, limit);
};

export const fetchChangeRequest = (id) => {
  return handleApiCall(getChangeRequest, id);
};

export const fetchExplanation_ApplicationRequests_SERVICE = (
  applicationRequestType,
  offset,
  limit
) => {
  return handleApiCall(
    getExplanation_Application_API,
    applicationRequestType,
    offset,
    limit
  );
};

export const fetchExplanation_ApplicationRequest_SERVICE = (applicationId) => {
  return handleApiCall(getExplanation_ApplicationRequest_API, applicationId);
};

export const sendExplanation_ApplicationRequest_SERVICE = (
  applicationId,
  explanation
) => {
  return handleApiCall(
    postExplanation_Application_API,
    applicationId,
    explanation
  );
};
// ⇧ ⇧ ⇧  Cabinet Services; ⇧ ⇧ ⇧;
