import React, { useState, useEffect } from "react";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { getNestedChildren, getUtilsEmployees } from "../api/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NestedSelect = ({
  nestedChildren,
  structureId,
  setUtilsEmployees,
  setPositionId,
  setContractPosition,
  setStaffId,
}) => {
  const [nestedChildStates, setNestedChildStates] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [positionIdSelectedIndex, setPositionIdSelectedIndex] = useState(-1); // Track the index of the form where positionId is selected
  const [staffs, setStaffs] = useState();

  useEffect(() => {
    // Initialize nestedChildStates with the initial nestedChildren data
    setNestedChildStates([nestedChildren]);
    setSelectedValues([]);
    setPositionIdSelectedIndex(-1); // Reset the positionId selection index
  }, [nestedChildren]);

  const nestedHandleChange = async (e, index) => {
    const selectedNestedChild = e?.target?.value;

    setSelectedValues((prevSelectedValues) => {
      const updatedValues = [...prevSelectedValues];
      updatedValues[index] = selectedNestedChild;
      return updatedValues;
    });

    if (e?.target?.value?.position) {
      setPositionId(e?.target?.value);
      setStaffId(e?.target?.value?._id);
    } else {
      setPositionId();
      if (selectedNestedChild?.child?.staff?.length !== 0) {
        setStaffs(e?.target?.value?.child?.staff);
        setContractPosition(e?.target?.value?.child?._id);

        setNestedChildStates((prevNestedChildStates) => {
          const updatedNestedChildStates = [...prevNestedChildStates];
          updatedNestedChildStates[index + 1] = e?.target?.value?.child?.staff;
          return updatedNestedChildStates.slice(0, index + 2);
        });

        // if (index === 1) {
        //   setPositionIdSelectedIndex(1);
        // } else {
        //   setNestedChildStates((prevNestedChildStates) =>
        //     prevNestedChildStates.slice(0, index + 1)
        //   );
        // }
      } else {
        setStaffs();

        setNestedChildStates((prevNestedChildStates) => {
          const updatedNestedChildStates = [...prevNestedChildStates];
          updatedNestedChildStates[index + 1] = e?.target?.value?.children;
          return updatedNestedChildStates.slice(0, index + 2);
        });

        if (index >= positionIdSelectedIndex) {
          setPositionIdSelectedIndex(-1);
        }
      }
    }
  };

  const renderSelectContent = (index) => {
    const nestedChild = nestedChildStates?.[index];

    if (nestedChild?.length === 0 && structureId) {
      return (
        <Typography style={{ color: "red" }} id={`structureName-${index}`}>
          Strukturda vakant yer yoxdur
        </Typography>
      );
    }

    if (!staffs && structureId) {
      const hasValidOptions =
        nestedChild &&
        nestedChild?.some(
          (nestedItem) => nestedItem?.child?.docModel === "CompanyPart"
        );

      if (!hasValidOptions) {
        return (
          <Typography style={{ color: "red" }} id={`structureName-${index}`}>
            Strukturda vakant yer yoxdur
          </Typography>
        );
      }
    }
    return (
      <>
        {nestedChild?.map((nestedItem, nestedIndex) => {
          if (nestedIndex === 0 && nestedItem?.position) {
            return nestedIndex === 0 ? (
              <InputLabel key={nestedIndex} id={`structureName-${index}`}>
                Vəzifə seçin
              </InputLabel>
            ) : null;
          }
          if (!nestedItem?.position && nestedItem?.children?.length !== 0) {
            return nestedIndex === 0 ? (
              <InputLabel id={`structureName-${index}`}>
                Strukturu seçin
              </InputLabel>
            ) : null;
          }
          if (!nestedItem?.position && nestedItem?.children?.length === 0) {
            return nestedIndex === 0 ? (
              <InputLabel id={`structureName-${index}`}>
                Substrukturu seçin
              </InputLabel>
            ) : null;
          }
          return null;
        })}
        {nestedChild?.length !== 0 && (
          <Select
            labelId={`structureName-${index}`}
            id={`structureName-${index}`}
            input={<Input />}
            fullWidth
            MenuProps={MenuProps}
            value={selectedValues?.[index] || ""}
            onChange={(e) => {
              nestedHandleChange(e, index);
            }}
          >
            {nestedChild?.map((nestedItem, nestedIndex) => {
              if (nestedItem?.child?.docModel === "CompanyPart") {
                return (
                  <MenuItem key={nestedIndex} value={nestedItem}>
                    {nestedItem?.child?.name}
                  </MenuItem>
                );
              } else if (nestedItem?.child?.docModel === "Management") {
                return (
                  <MenuItem key={nestedIndex} value={nestedItem}>
                    {nestedItem?.child?.title}
                  </MenuItem>
                );
              } else if (nestedItem?.position) {
                return (
                  <MenuItem key={nestedIndex} value={nestedItem}>
                    {nestedItem?.position?.name} - {nestedItem?.positionSalary}{" "}
                    ₼
                  </MenuItem>
                );
              }
              return null;
            })}
          </Select>
        )}

        {/* {structureId && (
         
        )} */}
      </>
    );
  };

  return (
    <>
      {nestedChildStates &&
        nestedChildStates.map((nestedChild, index) => (
          <FormControl key={index} sx={{ mb: 6 }} fullWidth>
            {renderSelectContent(index)}
          </FormControl>
        ))}
    </>
  );
};

export default NestedSelect;
