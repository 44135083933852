import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PriorityHigh } from "@mui/icons-material";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";
import Header from "../../components/layouts/header/Header";
import SelectInput from "../../components/ui/Select/SelectInput";
import { GENERAL_BLOCK } from "../../styled";
import CalendarPart from "./parts/CalendarPart";
import HoursTable from "./parts/HoursTable";
import * as companyStructureServices from "../../services/structure/StructureServices";
import { Button } from "@mui/material";
import {
  deleteProductionCalendar,
  getAllProductionCalendar,
  patchProductionCalendar,
  postProductionCalendar,
} from "../../services/prodCalendar/productionCalendar";
import { enqueueSnackbar } from "notistack";
const title = "İstehsalat təqvimi";

const Container = styled.div``;

// const PageContentContainer = styled.div`
//   background-color: rgba(255, 255, 255, 0.1);
//   height: auto;
//   border-radius: 5px;
// `;

const HeaderContainer = styled.div`
  margin-bottom: 10px;
`;

const SelectDateContainer = styled.div`
  display: flex;
  justify-content: end;
  position: sticky;
  top: 57px;
  background-color: #1b2635;
  padding: 10px 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  // width: max-content;
  z-index: 9999999;
`;

const CalendarContainer = styled.div`
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const TableContainer = styled.div``;

// static datas

const months = [
  {
    name: "Yanvar",
    key: 0,
  },
  {
    name: "Fevral",
    key: 1,
  },
  {
    name: "Mart",
    key: 2,
  },
  {
    name: "Aprel",
    key: 3,
  },
  {
    name: "May",
    key: 4,
  },
  {
    name: "İyun",
    key: 5,
  },
  {
    name: "İyul",
    key: 6,
  },
  {
    name: "Avqust",
    key: 7,
  },
  {
    name: "Sentyabr",
    key: 8,
  },
  {
    name: "Oktyabr",
    key: 9,
  },
  {
    name: "Noyabr",
    key: 10,
  },
  {
    name: "Dekabr",
    key: 11,
  },
];

const ProductionCalendarPage = () => {
  const [days, setDays] = useState([]);
  const [currStructure, setCurrStructure] = useState("");
  const [currYear, setCurrYear] = useState(new Date().getFullYear());
  const [currMonth, setCurrMonth] = useState(new Date().getMonth());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [years, setYears] = useState(
    Array.from({ length: 27 }, (_, index) => 2024 + index)
  );
  const [new_cal_years, setNew_cal_years] = useState(
    Array.from({ length: 27 }, (_, index) => 2024 + index)
  );
  const [new_Year, setnew_Year] = useState(new_cal_years[0]);
  const [structureDatas, setStructureDatas] = useState({
    total: 0,
    structures: [],
  });
  const [calendarDatas, setCalendarDatas] = useState({
    calendar: [],
    shift: [],
  });
  const [editable, setEditable] = useState(false);
  const [newCalendar, setNewCalendar] = useState(false);
  const [updatedCalendar, setUpdatedCalendar] = useState({
    structureId: "",
    dayDetails: [],
  });
  const [deletedCalendar, setDeletedCalendar] = useState({
    structureId: "",
    workDayIds: [],
  });
  const [dayIsDeleted, setDayIsDeleted] = useState(false);
  const [limitExceed, setLimitExceed] = useState(null);
  useEffect(() => {
    setnew_Year(new_cal_years[0]);
  }, [new_cal_years]);

  useEffect(() => {
    const currMonth = new Date().getMonth();
    const currYear = new Date().getFullYear();
    setCurrMonth(currMonth);
    setCurrYear(currYear);
  }, []);

  const handleStructureChange = (value) => {
    setCurrStructure(value);
  };

  const handleYearChange = (value) => {
    setCurrYear(value);
  };

  const handleMonthChange = (value) => {
    const selectedMonth = months.find((month) => month.name === value);
    if (selectedMonth) {
      setCurrMonth(selectedMonth.key);
    }
  };

  const headerData = {
    title: title,
    variant: "h3",
  };

  useEffect(() => {
    fetchCompanyStructures();
  }, []);

  useEffect(() => {
    if (currStructure) {
      fetchAllProductionCalendar();
    }
  }, [currStructure, currYear, currMonth, startDate, endDate]);

  async function fetchCompanyStructures() {
    try {
      const data = await companyStructureServices.getCompanyStructures({
        limit: 100,
        skip: 0,
      });
      if (data && data.structures && data.structures.length > 0) {
        setStructureDatas(data);
        setCurrStructure(data?.structures[0]?.organization?.name);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  async function fetchAllProductionCalendar() {
    try {
      const data = await getAllProductionCalendar(
        structureDatas?.structures?.find(
          (structure) => structure?.organization?.name === currStructure
        )?._id,
        currYear,
        startDate,
        endDate
      );
      if (data) {
        let days = [];
        for (let i = 0; i < data.calendar?.length; i++) {
          days.push({
            offDayType: data?.calendar[i]?.offDayType,
            day: data?.calendar[i]?.day,
            dayId: data?.calendar[i]?._id,
            alreadyShortened: data?.calendar[i]?.alreadyShortened,
            dailyHours: data?.calendar[i]?.dailyHours,
            dailyHours_ref: data?.calendar[i]?.dailyHours,
            isCurrentMonth: data?.calendar[i]?.isCurrentMonth,
            isWorkDay: data?.calendar[i]?.isWorkDay,
            month: data?.calendar[i]?.month,
            isLocked: data?.calendar[i]?.isLocked,
          });
        }
        setNew_cal_years(years?.filter((y) => y !== data.calendar?.[0]?.year));
        const modifiedDays = modifyDays(days, data?.structure?.workHourMode);
        setDays(modifiedDays);
        setCalendarDatas({ calendar: data?.calendar, shift: data?.shift });
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  function modifyDays(days, threshold) {
    const firstDayIndex = days.findIndex(
      (day) => day.day === 1 && day.isCurrentMonth === true
    );
    const secondDayIndex = days.findIndex(
      (day) => day.day === 1 && day.isCurrentMonth === false
    );

    const slicedDays = days.slice(firstDayIndex, secondDayIndex);

    const unslicedDaysBefore = days.slice(0, firstDayIndex);
    const unslicedDaysAfter = days.slice(secondDayIndex);
    const chunkSize = 7;
    const chunks = [];
    for (let i = 0; i < slicedDays.length; i += chunkSize) {
      chunks.push(slicedDays.slice(i, i + chunkSize));
    }

    const modifiedChunks = chunks.map((week) => {
      const totalHours = week.reduce((sum, day) => sum + day.dailyHours, 0);
      if (totalHours > threshold) {
        setLimitExceed(threshold);
        return week.map((day) => {
          if (day.dailyHours > 0) {
            return {
              ...day,
              limitExceed: true,
            };
          }
          return day;
        });
      }

      return week;
    });

    // Flatten the chunks back into a single array
    const modifiedSlicedDays = modifiedChunks.flat();

    // Combine unsliced days and modified sliced days
    return [...unslicedDaysBefore, ...modifiedSlicedDays, ...unslicedDaysAfter];
  }

  async function createProductionCalendar() {
    try {
      const data = await postProductionCalendar(
        structureDatas?.structures?.find(
          (structure) => structure?.organization?.name === currStructure
        )?._id,
        new_Year
      );
      if (data) {
        enqueueSnackbar(
          `İstehsalat təqvimi ${currStructure} ${new_Year} üçün uğurla yaradıldı`,
          { variant: "success" }
        );
        fetchAllProductionCalendar();
        setNewCalendar(false);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
  function cancelUpdate() {
    setEditable(false);
    let updatedDays = days.map((day) => {
      // Find the matching object in the updatedCalendar
      let dayDetailsUpdate = updatedCalendar.dayDetails.find(
        (updatedDay) => updatedDay.dayId === day.dayId
      );
      let workDayIdsUpdate = deletedCalendar.workDayIds.find(
        (updatedDay) => updatedDay === day.dayId
      );
      if (dayDetailsUpdate || workDayIdsUpdate) {
        day = {
          ...day, // Spread the original properties of the day
          dayIsDeleted: false,
          offDayType: null, // Overwrite with the properties from updatedCalendar
          dailyHours: day?.dailyHours_ref,
        };
      }
      // Return the original day object if there's no match
      return day;
    });
    updatedDays = updatedDays?.map((d) => ({
      ...d,
      dayIsEdited: false,
    }));
    setUpdatedCalendar({ ...updatedCalendar, dayDetails: [] });
    setDeletedCalendar({ ...deletedCalendar, workDayIds: [] });
    setDays(updatedDays);
  }
  async function updateCalendar() {
    if (updatedCalendar?.dayDetails?.length) {
      updateProductionCalendar();
      setUpdatedCalendar({ ...updatedCalendar, dayDetails: [] });
      setDeletedCalendar({ ...deletedCalendar, workDayIds: [] });
      setEditable(false);
    }
    if (deletedCalendar?.workDayIds?.length) {
      delProductionCalendar();
      setUpdatedCalendar({ ...updatedCalendar, dayDetails: [] });
      setDeletedCalendar({ ...deletedCalendar, workDayIds: [] });
    }
  }
  async function updateProductionCalendar() {
    let updCalendar = { ...updatedCalendar };
    updCalendar.structureId = structureDatas?.structures?.find(
      (structure) => structure?.organization?.name === currStructure
    )?._id;

    try {
      const data = await patchProductionCalendar(updCalendar);
      if (data) {
        enqueueSnackbar(
          `İstehsalat təqvimi ${currStructure} ${currYear} üçün uğurla dəyişdirildi`,
          { variant: "success" }
        );
        fetchAllProductionCalendar();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  async function delProductionCalendar(id) {
    let delCalendar = { ...deletedCalendar };
    delCalendar.structureId = structureDatas?.structures?.find(
      (structure) => structure?.organization?.name === currStructure
    )?._id;
    try {
      const data = await deleteProductionCalendar(delCalendar);
      if (data) {
        enqueueSnackbar(
          `İstehsalat təqvimi ${currStructure} ${currYear} üçün uğurla dəyişdirildi`,
          { variant: "success" }
        );
        fetchAllProductionCalendar();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  function handleNewCalendar() {
    setNewCalendar(true);
    setEditable(false);
  }

  function handleCalendarEditable() {
    setEditable(true);
    setNewCalendar(false);
  }

  function handleIsDeletedDay(dayId) {
    let days_ = [...days];
    days_ = days_?.map((d) => {
      if (d?.dayId === dayId) {
        if (!d?.dayIsDeleted) {
          d = {
            ...d,
            dayIsDeleted: true,
          };
        } else {
          d = {
            ...d,
            dayIsDeleted: false,
          };
        }
      }
      return d;
    });
    setDays(days_);
  }

  const handleDayIsEdited = (dayId) => {
    let days_ = [...days];
    days_ = days_?.map((d) => {
      if (d?.dayId === dayId) {
        if (!d?.dayIsEdited) {
          d = {
            ...d,
            dayIsEdited: true,
          };
        } else {
          d = {
            ...d,
            dayIsEdited: false,
          };
        }
      }
      return d;
    });
    setDays(days_);
  };

  let total_dailyHours = calendarDatas?.calendar
    ?.filter((c) => c?.isCurrentMonth && c?.isWorkDay)
    .reduce((acc, value) => acc + value?.dailyHours, 0);

  return (
    <React.Fragment>
      <Container>
        <HeaderContainer>
          <Header data={headerData} />
        </HeaderContainer>

        <SelectDateContainer>
          <GENERAL_BLOCK className="text-center" f_size="23px" wid="80%">
            {months[currMonth].name} {currYear}
          </GENERAL_BLOCK>
          <div className="d-flex">
            <SelectInput
              styleOuterContainer={{ width: "80%" }}
              label={"Struktur: "}
              value={currStructure}
              onChange={handleStructureChange}
            >
              {structureDatas?.structures.map((structure) => (
                <option
                  key={structure?.organization?.name}
                  value={structure?.organization?.name}
                >
                  {structure?.organization?.name}
                </option>
              ))}
            </SelectInput>
            <SelectInput
              styleOuterContainer={{ width: "130px" }}
              label={"İl: "}
              value={currYear}
              onChange={handleYearChange}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </SelectInput>

            <SelectInput
              styleOuterContainer={{ width: "130px" }}
              label={`Ay: `}
              value={months[currMonth].name}
              data={months}
              itemName={"name"}
              itemValue={"name"}
              onChange={handleMonthChange}
            >
              <option value={months[currMonth].key}>
                {months[currMonth].name}
              </option>
            </SelectInput>
          </div>
        </SelectDateContainer>
        {/* {limitExceed && (
          <GENERAL_BLOCK clr="red" className="mt-3">
            {months[currMonth].name} ayı üçün həftəlik norma {limitExceed} saat
            pozulmuşdur <PriorityHigh />
          </GENERAL_BLOCK>
        )} */}
        <CalendarContainer>
          <CalendarPart
            date={{ year: currYear, month: currMonth }}
            calendarDatas={calendarDatas}
            days={{
              set: setDays,
              state: days,
            }}
            updatedCalendar={{
              set: setUpdatedCalendar,
              state: updatedCalendar,
            }}
            deletedCalendar={{
              set: setDeletedCalendar,
              state: deletedCalendar,
            }}
            editable={editable}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            dayIsDeleted={{
              set: setDayIsDeleted,
              state: dayIsDeleted,
            }}
            handleIsDeletedDay={handleIsDeletedDay}
            handleDayIsEdited={handleDayIsEdited}
          />
        </CalendarContainer>
        <GENERAL_BLOCK
          bg_color="#233044"
          pad="20px 10px"
          className="mb-2 d-flex align-items-center justify-content-between"
        >
          <div>
            İş günlərinin sayı:{" "}
            {
              calendarDatas?.calendar?.filter(
                (c) => c?.isCurrentMonth && c?.isWorkDay
              )?.length
            }
            {" gün"}, qeyri-iş günlərinin sayı:{" "}
            {
              calendarDatas?.calendar?.filter(
                (c) => c?.isCurrentMonth && !c?.isWorkDay
              )?.length
            }
            {" gün"}, aylıq iş saat norması: {total_dailyHours?.toFixed(2)} saat
          </div>
          <div className="d-flex align-items-center justify-content-end w-50">
            {newCalendar ? (
              <>
                <SelectInput
                  styleOuterContainer={{ width: "35%" }}
                  label={"Struktur: "}
                  value={currStructure}
                  onChange={handleStructureChange}
                >
                  {structureDatas?.structures.map((structure) => (
                    <option
                      key={structure?.organization?.name}
                      value={structure?.organization?.name}
                    >
                      {structure?.organization?.name}
                    </option>
                  ))}
                </SelectInput>
                <div className="ms-2">
                  <SelectInput
                    styleOuterContainer={{ width: "130px" }}
                    label={"İl: "}
                    value={
                      years?.filter(
                        (y) => y !== calendarDatas.calendar?.[0]?.year
                      )?.[0]
                    }
                    onChange={setnew_Year}
                  >
                    {new_cal_years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </SelectInput>
                </div>
                <Button
                  variant="contained"
                  className="ms-2"
                  onClick={() => setNewCalendar(!newCalendar)}
                >
                  Bağla
                </Button>
                <Button
                  variant="contained"
                  className="ms-2"
                  onClick={() => createProductionCalendar()}
                >
                  Yarat
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                className="me-2 w-25"
                onClick={handleNewCalendar}
              >
                Yeni təqvim yarat
              </Button>
            )}
            {editable ? (
              <>
                <Button
                  variant="contained"
                  className="ms-2"
                  onClick={cancelUpdate}
                >
                  Ləğv et
                </Button>
                {(updatedCalendar?.dayDetails.length > 0 ||
                  deletedCalendar?.workDayIds?.length) > 0 && (
                  <Button
                    variant="contained"
                    className="w-25 ms-2"
                    onClick={updateCalendar}
                  >
                    Təqvimi yenilə
                  </Button>
                )}
              </>
            ) : calendarDatas?.calendar?.length !== 0 ? (
              <Button
                variant="contained"
                className="w-25 ms-2"
                onClick={handleCalendarEditable}
              >
                Təqvimə dəyişiklik et
              </Button>
            ) : null}
          </div>
        </GENERAL_BLOCK>
        <GENERAL_BLOCK
          bg_color="#1b2635"
          poss="sticky"
          pad="20px 10px"
          className="mb-2 text-center"
          f_size="30px"
          topp="50px"
          zIndex="9999999"
        >
          Norma saatlar
        </GENERAL_BLOCK>
        <TableContainer>
          <HoursTable
            shift={calendarDatas?.shift}
            date={{ year: currYear, month: currMonth }}
          />
        </TableContainer>
      </Container>
    </React.Fragment>
  );
};

export default ProductionCalendarPage;
