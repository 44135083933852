import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid2,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Table from "../../components/table/Table";
// icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete as DeleteIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";

import Header from "../../components/layouts/header/Header";
import { useEffect, useReducer, useState } from "react";
import useOrders from "../../hooks/useOrders";
import { getChainlessPart, getChainlessPartForSalary } from "../../api/api";
import {
  decisionChainReducer,
  initialState,
} from "../../redux/DecisionChainReducer";
import NestedSelectForSalaryBase from "./nestedSelectForSalaryBase";
import { DatePicker } from "@mui/x-date-pickers";
import Column from "../../models/table/Column";
import { OPERATORS_CONTAINING } from "../../constants";
import { normalizeDate } from "../../utils/helperFunctions";
import Operator from "../../models/table/Operator";
import OperatorGenerator from "../../components/table/OperatorGenerator";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const header = {
  title: "Əməkhaqqı çıxarışı",
  variant: "h1",
};

export default function SalaryBase() {
  const [state, dispatch] = useReducer(decisionChainReducer, initialState);
  const { structureId, organizationId, nestedChildren, utilsEmployees } = state;
  const [watchDataDialogue, setWatchDataDialogue] = useState(false);
  const { allStructures } = useOrders();
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState("");
  const [showData, setShowData] = useState(false);
  const [initialFilter, setInitialFilter] = useState({
    position: "",
    month: "",
    year: "",
  });
  const [errorStartDate, setErrorStartDate] = useState("");
  const [errorEndDate, setErrorEndDate] = useState("");

  useEffect(() => {
    setShowData(
      initialFilter?.position === "" ||
        initialFilter?.month === "" ||
        initialFilter?.year === ""
    );
  }, [initialFilter]);

  const handlePositionIDForInitialFilters = async (id) => {
    setInitialFilter({
      ...initialFilter,
      position: id,
    });
  };

  const handleOrganizationName = async (e) => {
    dispatch({
      type: "SET_STRUCTURE_ID",
      payload: e.target.value._id,
    });
    dispatch({
      type: "SET_ORGANIZATION_ID",
      payload: e.target.value.organization._id,
    });

    const response = await getChainlessPartForSalary({
      structureId: e.target.value._id,
    });

    dispatch({
      type: "GET_NESTED_CHILDREN",
      payload: response.data.records,
    });
  };

  const operatorGeneratorHandler = (row, column) => {
    let visibility = false;
    let createOrder = false;
    if (row.status === "active" || row.status === "rejected") {
      visibility = true;
    }
    if (row.status === "pending_order_approve") {
      createOrder = true;
    }
    const operators = [
      new Operator({
        icon: <VisibilityIcon />,
        onClick: () => {
          // setWatchDataDialogue(true);
          // getDataForEdit(row._id);
        },
        type: 0,
      }),
    ];

    return <OperatorGenerator operators={operators} />;
  };

  const column = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      // isStaticColumn: true,
      widthOfColumn: 200,
      renderCell: operatorGeneratorHandler,
    }),
    new Column({
      path: "employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "pin",
      headerName: "Fin",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "structure.organization.name",
      headerName: "Təşkilat",
      filterType: OPERATORS_CONTAINING.OPTION,
    }),
    new Column({
      path: "topPartOfStructure.source.name",
      headerName: "Struktur",
      filterType: OPERATORS_CONTAINING.OPTION,
    }),
    new Column({
      path: "subStructure.source.name",
      headerName: "Sub-struktur",
      filterType: OPERATORS_CONTAINING.OPTION,
    }),
    new Column({
      path: "position",
      headerName: "Vəzifə",
      filterType: OPERATORS_CONTAINING.OPTION,
    }),

    new Column({
      path: "startDate",
      headerName: `Cəmi  ödənilmiş( Son 12 tam ay)`,

      filterType: OPERATORS_CONTAINING.NUMBER,
    }),
    new Column({
      path: "startDate",
      headerName: `Orta aylıq əmək haqqı`,

      filterType: OPERATORS_CONTAINING.NUMBER,
    }),
    new Column({
      path: "startDate",
      headerName: `Cari ay üçün orta günlük əmək haqqı(standart)`,

      filterType: OPERATORS_CONTAINING.NUMBER,
    }),
    new Column({
      path: "startDate",
      headerName: `Cari ay üçün orta günlük əmək haqqı(Əmək məzuniyyəti)`,

      filterType: OPERATORS_CONTAINING.NUMBER,
    }),
  ];

  return (
    <>
      <Grid2 sx={{ width: "100%", textAlign: "center", marginBottom: "50px" }}>
        <Header data={header} />
      </Grid2>
      {/* form part */}

      <DialogContent>
        <Grid2 sx={{ display: "flex", width: "100%", gap: 15 }}>
          <Grid2
            sx={{
              width: "100%",
              py: 2,
              maxHeight: "55vh",
              overflowY: "auto",
            }}
          >
            <FormControl sx={{ mb: 6 }} fullWidth>
              <InputLabel id="organizationName">Təşkilatı seçin</InputLabel>
              <Select
                labelId="organizationName"
                id="organizationName"
                input={<Input />}
                fullWidth
                MenuProps={MenuProps}
                onChange={(e) => {
                  handleOrganizationName(e);
                }}
              >
                {allStructures &&
                  allStructures.map((structure, index) => {
                    if (structure?.organization?.name) {
                      return (
                        <MenuItem key={index} value={structure}>
                          {structure.organization.name}
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
            </FormControl>
            {structureId && organizationId && nestedChildren !== "" ? (
              <div style={{ display: "flex", columnGap: "15px" }}>
                <NestedSelectForSalaryBase
                  state={state}
                  dispatch={dispatch}
                  initialFilter={handlePositionIDForInitialFilters}
                />
              </div>
            ) : null}
          </Grid2>
        </Grid2>
        <FormControl fullWidth>
          <div className="d-flex w-100 gap-3">
            <div className="w-25">
              <div>Əməkdaşlar</div>
              <div className="mt-2">
                <Select
                  as={Select}
                  name="contactDetails"
                  label="Əməkdaşlar"
                  // value={requestTypeStatus}
                  fullWidth
                  variant="outlined"
                  my={2}
                  sx={{
                    maxWidth: "501px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  // onChange={handleMultipleChoice}
                  multiple
                  // renderValue={() => selectedRequestTypeNames.join(", ")}
                >
                  {/* {requestType &&
                requestType.map((type, index) => (
                  <MenuItem key={index} value={type.value}>
                    <Checkbox
                      checked={requestTypeStatus.indexOf(type.value) > -1}
                    />
                    <ListItemText primary={type.name} / f >
                  </MenuItem>
                ))} */}
                </Select>
              </div>
            </div>
            <div className="w-25">
              <div>Başlama tarixi</div>
              <div className="mt-2">
                <DatePicker
                  sx={{ width: "100%" }}
                  format="dd/MM/yyyy"
                  // value={
                  //   leave_state?.startDate
                  //     ? new Date(leave_state?.startDate)
                  //     : null
                  // }
                  slotProps={{
                    textField: {
                      // error: !!errorStartDate,
                      // helperText: errorStartDate,
                      fullWidth: true,
                    },
                  }}
                  // maxDate={leave_state?.endDate}
                  onChange={(date) => {
                    // set_leave_state({
                    //   ...leave_state,
                    //   startDate: date,
                    // });
                  }}
                  onError={(reason, value) => {
                    if (reason === "invalidDate") {
                      setErrorStartDate("Keçərsiz tarix formatı");
                    } else if (reason === "maxDate") {
                      setErrorStartDate(
                        "Başlama tarixi bitmə tarixindən böyük ola bilməz"
                      );
                    } else {
                      setErrorStartDate("");
                    }
                  }}
                />
              </div>
            </div>
            <div className="w-25 ">
              <div>Bitmə tarixi</div>
              <div className="mt-2">
                <DatePicker
                  // disabled={
                  //   leave_state.supplementType === "temporary" ? true : false
                  // }
                  sx={{ width: "100%" }}
                  format="dd/MM/yyyy"
                  // value={
                  //   leave_state?.endDate ? new Date(leave_state?.endDate) : null
                  // }
                  slotProps={{
                    textField: {
                      error: !!errorEndDate,
                      helperText: errorEndDate,
                      fullWidth: true,
                    },
                  }}
                  // minDate={leave_state?.startDate}
                  // shouldDisableDate={shouldDisableDate}
                  onChange={(date) => {
                    // set_leave_state({
                    //   ...leave_state,
                    //   endDate: date,
                    // });
                  }}
                  onError={(reason, value) => {
                    if (reason === "invalidDate") {
                      setErrorEndDate("Keçərsiz tarix formatı");
                    } else if (reason === "minDate") {
                      setErrorEndDate(
                        "Bitmə tarixi başlama tarixindən kiçik ola bilməz"
                      );
                    } else {
                      setErrorEndDate("");
                    }
                  }}
                />
              </div>
            </div>
            <div className="w-25">
              <div>Komponentlər</div>
              <div className="mt-2">
                <Select
                  as={Select}
                  name="contactDetails"
                  label="Komponentlər"
                  // value={requestTypeStatus}
                  fullWidth
                  variant="outlined"
                  my={2}
                  sx={{
                    maxWidth: "501px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  // onChange={handleMultipleChoice}
                  multiple
                  // renderValue={() => selectedRequestTypeNames.join(", ")}
                >
                  {/* {requestType &&
                requestType.map((type, index) => (
                  <MenuItem key={index} value={type.value}>
                    <Checkbox
                      checked={requestTypeStatus.indexOf(type.value) > -1}
                    />
                    <ListItemText primary={type.name} />
                  </MenuItem>
                ))} */}
                </Select>
              </div>
            </div>
          </div>
        </FormControl>
      </DialogContent>

      <div style={{ display: "flex", columnGap: "10px" }}>
        {/* button */}
        <div
          style={{
            marginLeft: "20px",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <div style={{ height: "50%" }}>
            <Button
              disabled={showData}
              variant="contained"
              color="primary"
              // onClick={showSalary}
            >
              Yüklə
            </Button>
          </div>
        </div>
      </div>
      {/* <div style={{ marginTop: "100px" }}>
        <Table
          columns={column}
          data={datas}
          totalRows={total}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={isLoading}
          applyFiltersHandler={(filters) => setFilters(filters)}
          cancelAppliedFilters={() => setFilters([])}
          //   downloadHandler={(cols) => downlaodHandler(cols)}
        />
      </div> */}
    </>
  );
}
