import { weekDays } from "../../../../../labour-contract-changes/dialog/empConstMock/empConstMock";

export function orderEmpInitialValues(oldData) {
  const convertedOffDays = (oldData?.offDays || []).map((dayName) => {
    const matchingDay = weekDays.find((day) => day.name === dayName);
    return matchingDay ? matchingDay.key : dayName;
  });
  return {
    labourContractChangeDate: "",
    createOrder: "",
    informationAboutParties: {
      empWorkPlaceType: oldData?.empWorkPlaceType || "",
    },
    labourPeriod: {
      contractPeriodType: oldData?.contractPeriodType || "",
      probationPeriodType: oldData?.probationPeriodType || "",
      probationPeriod: oldData?.probationPeriod || "",
      reasonOfTemporaryContract: oldData?.reasonOfTemporaryContract || "",
      contractStartDate: oldData?.contractStartDate || "",
      contractEndDate: oldData?.contractEndDate || "",
      jobStartDate: oldData?.jobStartDate || "",
      laborFunctions: oldData?.laborFunctions || [],
    },
    wage: {
      paymentOfWork: oldData?.paymentOfWork || "",
      wage: oldData?.wage || "",
      bonusPeriod: oldData?.bonusPeriod || "",
      givenBonus: oldData?.givenBonus || "",
      partTimeWorkHour: oldData?.partTimeWorkHour || "",
      overtime: oldData?.overtime || "",
      workingCondition: {
        name: oldData?.workingCondition?.name || "",
        parent: oldData?.workingCondition?.parent || "",
        reason: oldData?.workingCondition?.reason || "",
        vacationDays: oldData?.workingCondition?.vacationDays || "",
        value: oldData?.workingCondition?.value || "",
        valueType: oldData?.workingCondition?.valueType || "",
      },
      selectedBank: oldData?.selectedBank || "",
      conditionsOfSalaryPayment: oldData?.conditionsOfSalaryPayment || "",
      paymentPeriod: oldData?.paymentPeriod || "",
      paymentPeriodDate: oldData?.paymentPeriodDate || "",
      freelanceWorkIncomeType: oldData?.freelanceWorkIncomeType || "",
      freelanceWorkIncome: oldData?.freelanceWorkIncome || "",
    },
    protection: {
      providedProtectiveEquipment: oldData?.providedProtectiveEquipment || [],
      providedFood: oldData?.providedFood || [],
      instructionalPeriod: oldData?.instructionalPeriod || "",
    },
    workVacationTime: {
      workingMode: oldData?.workingMode || "",
      offDays: convertedOffDays,
      workScheduleId: oldData?.workScheduleId || "",
      workScheduleAsText: oldData?.workScheduleAsText || "",
      assignedWorkTurnValue: oldData?.assignedWorkTurnValue || "",
    },
    vacation: {
      vacationDaysCount: oldData?.vacationDaysCount || "",
      forSeniorityLevel: oldData?.forSeniorityLevel || "",
      forParental: oldData?.forParental || "",
      forCollectiveAgreement: oldData?.forCollectiveAgreement || "",
      socialHouseholdBenefit: oldData?.socialHouseholdBenefit || "",
      aboutEventOrPayer: oldData?.aboutEventOrPayer || "",
    },
    physicalEducationSport: {
      physicalEducationAndSportsConditions:
        oldData?.physicalEducationAndSportsConditions || "",
      otherAdditionalConditions: oldData?.otherAdditionalConditions || "",
    },
    socialProtection: {
      monthlyInsuranceFee: oldData?.monthlyInsuranceFee || "",
      extraSumInsured: oldData?.extraSumInsured || "",
    },
    regulationPropertyRelation: {
      shareOwnershipOfEnterprise: oldData?.shareOwnershipOfEnterprise || "",
      dividendAmount: oldData?.dividendAmount || "",
      personalPropertiesUsedByEmployee:
        oldData?.personalPropertiesUsedByEmployee || [],
    },
    administrative: {
      oldRecruiterId: oldData?.recruiterId || "",
    },
    jobRelated: {
      staffId: oldData?.staffId || "",
      oldStructureAsStr: "",
      newStructureAsStr: "",
      oldPositionAsStr: "",
      newPositionAsStr: "",
    },
  };
}
