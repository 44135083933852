import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { validateDate } from "../pages/documentation/orders/create-orders/order-validations";
import {
  getActiveStructuresEmptyStaff,
  getCommand_API_SERVICE,
  getEmployeeId_API_SERVICE,
  getFillingVacantPositionOrder_SERVICE,
  postFillingVacantPositionOrder_SERVICE,
  putVacantOrder_SERVICE,
} from "../services/ordersServices";
import { enqueueSnackbar } from "notistack";
import { removeEmptyStrings } from "../utils/removeEmptyString";
import moment from "moment";

function useTransferAnotherJob() {
  const [value, setValue] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const value = searchParams.get("value");
    setValue(value);
  }, [searchParams]);

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [staffId, setStaffId] = useState();
  const [persent, setPersent] = useState(false);
  const [positionId, setPositionId] = useState();
  const [loading, setIsLoading] = useState(false);
  const [commandData, setCommandData] = useState([]);
  const [structureId, setStructureId] = useState("");
  const [contractName, setContractName] = useState("");
  const [contractData, setContractData] = useState([]);
  const [allStructures, setAllStructures] = useState([]);
  const [nestedChildren, setNestedChildren] = useState([]);
  const [vacantStaffData, setVacantStaffData] = useState("");
  const [contractPosition, setContractPosition] = useState();
  const [currentContractData, setCurrentContract] = useState(null);
  const [oldStructure, setOldStructure] = useState({
    organization: {},
    _id: "",
  });
  const [emptyPositionDatas, setEmptyPositionDatas] = useState({
    note: "",
    clause: "",
    currency: "AZN",
    endDate: null,
    structure: "",
    orderType: "",
    startDate: null,
    oldStructure: "",
    typeOfPayment: "cash",
    endWorkYear: null,
    vacantStaffId: "",
    positionLevel: "",
    startWorkYear: null,
    personalCategory: "",
    newContractDate: null,
    newContractNumber: "",
    additionToSalary: null,
  });

  const [transferAnotherJobData, setTransferAnotherJobData] = useState({
    note: "",
    clause: "",
    staffId: "",
    structure: "",
    orderType: "",
    oldStructure: "",
    endWorkYear: null,
    positionLevel: "",
    vacantStaffId: "",
    startWorkYear: null,
    newVocationLevel: "",
    personalCategory: "",
    newContractDate: null,
    additionToSalary: null,
  });
  const [employee, setEmployee] = useState({
    employeeId: "",
    tabelNumber: "",
    empStaffId: "",
  });
  const validateForm = () => {
    const newErrors = {};
    newErrors.endWorkYear = validateDate(transferAnotherJobData.endWorkYear);
    // if (emptyPositionDatas.endDate < emptyPositionDatas.startDate) {
    //   newErrors.endDate = "Bitmə tarixi başlama tarixindən əvvəl ola bilməz";
    // }
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  // Əmr xana dolmamışsa button disabled üçün
  const isFormValid = () => {
    return (
      // !emptyPositionDatas?.endWorkYear ||
      !transferAnotherJobData?.orderType ||
      !transferAnotherJobData?.startWorkYear ||
      !transferAnotherJobData?.positionLevel ||
      !transferAnotherJobData?.personalCategory
    );
  };

  // Əmr yaratmaq
  const createFillingVacantPositionOrder = async () => {
    try {
      const payload = {
        ...transferAnotherJobData,
        vacantStaffId: staffId,
        staffId: employee?.empStaffId,
        clause: transferAnotherJobData.clause,
        orderType: transferAnotherJobData.orderType,
        note: transferAnotherJobData?.note,
      };
      if (transferAnotherJobData.endWorkYear) {
        payload.endWorkYear = moment(transferAnotherJobData.endWorkYear).format(
          "MM/DD/yyyy"
        );
      }
      if (transferAnotherJobData.startWorkYear) {
        payload.startWorkYear = moment(
          transferAnotherJobData.startWorkYear
        ).format("MM/DD/yyyy");
      }

      if (persent) {
        delete payload.currency;
      }

      const result = removeEmptyStrings(payload);
      let final = {
        docModel: "Employee",
        subjects: [employee.employeeId],
        data: {
          ...result,
        },
      };
      let response = await postFillingVacantPositionOrder_SERVICE(
        contractName,
        final
      );
      navigate("/documentation/orders");
      if (response) {
        enqueueSnackbar({ message: "Uğurla yaradıldı", variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar({ message: "Xəta baş verdi", variant: "error" });
      validateForm();
    }
  };

  // Əmr update etmək
  const updateVacantorder = async () => {
    try {
      const payload = {
        ...transferAnotherJobData,
        vacantStaffId: staffId,
        clause: transferAnotherJobData.clause,
        staffId: employee?.empStaffId,
      };
      if (transferAnotherJobData.endWorkYear) {
        payload.endWorkYear = moment(transferAnotherJobData.endWorkYear).format(
          "MM/DD/yyyy"
        );
      }
      // if (!persent) {
      //   delete payload.currency;
      // }
      const result = removeEmptyStrings(payload);
      let final = {
        docModel: "Employee",
        subjects: [employee.employeeId],
        data: {
          ...result,
        },
      };
      let response = await putVacantOrder_SERVICE(contractName, value, final);
      navigate("/documentation/orders");
      if (response) {
        enqueueSnackbar({ message: "Uğurla yaradıldı", variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar({ message: "Xəta baş verdi", variant: "error" });
      validateForm();
    }
  };

  // Əməkdaşları gətirmək üçün
  const getFillingVacantPositionOrder = async () => {
    const filter = [
      { column: "status", operator: "equalsTo", value: "active" },
    ];

    try {
      const res = await getFillingVacantPositionOrder_SERVICE(
        encodeURIComponent(JSON.stringify(filter))
      );
      if (res) {
        setData(res.data.employees);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCommandApi = async () => {
    try {
      if (value) {
        const res = await getCommand_API_SERVICE(value, contractName);
        setCommandData(res.data);
        setEmployee({
          ...employee,
          // employeeId: res?.data?.subjects?.[0]?.subject?._id,
          employeeId: res?.data?.data?.staff?.employee?.[0]?._id,
          // tabelNumber: res?.data?.subjects?.[0]?.subject?.tableNumber,
          tabelNumber: res?.data?.data?.staff?.employee?.[0]?.tableNumber,
          empStaffId: res?.data?.data?.staffId,
          trAnJobEmpStaffId: res?.data?.data?.staffId,
        });
        setTransferAnotherJobData({
          ...transferAnotherJobData,
          personalCategory: res?.data?.data?.personalCategory,
          positionLevel: res?.data?.data?.positionLevel,
          startWorkYear: res?.data?.data?.startWorkYear,
          endWorkYear: res?.data?.data?.endWorkYear,
          orderType: res?.data?.data?.orderType,
          note: res?.data?.data?.note,
          clause: res?.data?.data?.clause,
          contractDate: res?.data?.data?.contractDate,
          structure: res?.data?.data?.baseStructureName,
          newStructure: res?.data?.data?.newbaseStructureName,
          topPartOfStrucutre: res?.data?.data?.topPartOfStructureName,
          subStructure: res?.data?.data?.subStructureName,
          position: res?.data?.data?.positionName,
          staffId: res?.data?.data?.staff?._id,
        });
        setOldStructure({
          ...oldStructure,
          organization: res?.data?.data?.hierarchies
            ? res?.data?.data?.hierarchies?.value?.vacantStaffData
                ?.vacantOrganization
            : { name: res?.data?.baseStructures?.[0]?.organization?.name },
          _id: res?.data?.data?.hierarchies
            ? res?.data?.data?.hierarchies?.value?.vacantStaffData
                ?.vacantOrganization?.structureId
            : res?.data?.baseStructures?.[0]?._id,
        });
        setVacantStaffData(
          res?.data?.data?.hierarchies?.value?.vacantStaffData
        );
        if (commandData.length > 0) {
          setCurrentContract(
            contractData?.find((s) =>
              s?._id === commandData?.data?.staff?._id
                ? commandData?.data?.staff?._id
                : commandData?.data?.staffId
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getContract = async () => {
    try {
      const res = await getEmployeeId_API_SERVICE(employee.employeeId);
      if (res) {
        setContractData(res.data?.staffs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFillingVacantPositionOrder();
    setIsLoading(true);
  }, []);
  useEffect(() => {
    if (value) {
      getCommandApi();
    }
  }, [value]);

  useEffect(() => {
    employee?.employeeId && getContract();
  }, [employee?.employeeId]);
  useEffect(() => {
    if (commandData?.data?.staffId && contractData?.length > 0) {
      const matchingContract = contractData?.find((s) =>
        s?._id === commandData?.data?.staffId?.value
          ? commandData?.data?.staffId?.value
          : commandData?.data?.staffId
      );

      if (matchingContract) {
        setCurrentContract(matchingContract);
      }
    }
  }, [commandData, contractData]);

  const handleEmployeeChange = (value) => {
    const emp = data?.find((e) => e?._id === value);
    setEmployee({
      ...employee,
      employeeId: value || "",
      tabelNumber: emp?.tableNumber || "",
    });
  };

  const handleCurrentContract = (id) => {
    const value = contractData?.find((s) => s?._id === id);
    setCurrentContract(contractData?.find((s) => s?._id === id));
    setEmployee({
      ...employee,
      empStaffId: value?.contract?.data?.staffId,
      trAnJobEmpStaffId: value?.contract?.data?.staffId,
    });
  };
  async function getPositionsData() {
    if (structureId || oldStructure?._id) {
      try {
        const res = await getActiveStructuresEmptyStaff({
          structureId: structureId ? structureId : oldStructure?._id,
        });
        setNestedChildren(res?.data?.children);
      } catch (err) {
        console.log(err);
      }
    }
  }
  useEffect(() => {
    const handleAllStructures = async () => {
      try {
        const response = await getActiveStructuresEmptyStaff({
          getStructures: true,
        });
        setAllStructures(response.data);
      } catch (error) {
        console.log("err", error);
      }
    };
    handleAllStructures();
  }, []);
  useEffect(() => {
    (structureId || oldStructure?._id) && getPositionsData();
  }, [structureId, oldStructure?._id]);

  const handleInputChange = (name, value) => {
    setTransferAnotherJobData({
      ...transferAnotherJobData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    if (validateForm()) {
      e.preventDefault();
      setIsLoading(true);
      await createFillingVacantPositionOrder();
      setIsLoading(false);
    }
  };
  return {
    data,
    errors,
    persent,
    loading,
    employee,
    navigate,
    setPersent,
    setStaffId,
    commandData,
    isFormValid,
    structureId,
    handleSubmit,
    contractData,
    oldStructure,
    setPositionId,
    allStructures,
    setStructureId,
    nestedChildren,
    vacantStaffData,
    setContractName,
    updateVacantorder,
    handleInputChange,
    emptyPositionDatas,
    currentContractData,
    setContractPosition,
    handleEmployeeChange,
    handleCurrentContract,
    setEmptyPositionDatas,
    transferAnotherJobData,
    setTransferAnotherJobData,
    createFillingVacantPositionOrder,
  };
}

export default useTransferAnotherJob;
