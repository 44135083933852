import React, { useEffect, useState } from "react";
import { Field, FieldArray, ErrorMessage, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  FormHelperText,
} from "@mui/material";
import { getCommonData } from "../../../api/api";
import CreateIcon from "@mui/icons-material/Create";
import { sendPersonalInfoChanges } from "../../../services/employeeCabinet/employeeCabinet";
import { supplementalWagesValidation } from "./Validations";

const MCViewSalarySupplements = ({ employeeById }) => {
  const [supplementName, setSupplementName] = useState([]);
  const [formCorrection, setFormCorrection] = useState(false);
  const [requestButton, setRequestButton] = useState(false);

  const onClickCB = () => {
    setFormCorrection(!formCorrection);
  };
  useEffect(() => {
    const handleSupplements = async () => {
      const response = await getCommonData("supplementalWageGeneral");
      setSupplementName(response.data);
    };
    handleSupplements();
  }, []);
  const initialValues = {
    supplementalWages:
      employeeById.supplementalWages.map((item, index) => ({
        ...item,
        id: index,
      })) || [],
  };
  const [dataIndex, setDataIndex] = useState(
    initialValues.supplementalWages.at(-1).id
  );

  // form change request methods

  function updateData(newObject, oldObject) {
    let updateData = {
      index: newObject.id,
      operation: "update",
      data: {},
    };

    const keys = Object.keys(newObject);
    for (const key of keys) {
      if (newObject[key] !== oldObject[key]) {
        updateData.data[key] = {
          old: newObject[key],
          new: oldObject[key],
        };
      }
    }
    const a = Object.keys(updateData.data);
    if (a.length) {
      return updateData;
    }
  }

  function makeChangesData(formValue, oldValue) {
    let changesData = {
      section: "supplemental-wage",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];

    formValue.supplementalWages.forEach((obj1) => {
      const obj2 = oldValue.supplementalWages.find(
        (item) => item.id === obj1.id
      );

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          index: obj1.id,
          data: { ...obj1 },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.supplementalWages.forEach((obj2) => {
      const obj1 = formValue.supplementalWages.find(
        (item) => item.id === obj2.id
      );

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }
    sendPersonalInfoChanges(changesData);
  }

  function handleSubmit(formValue) {
    makeChangesData(formValue, initialValues);
  }

  // form controller for submitting

  function controlFormForSubmitting(data) {
    controllData(data, initialValues);
  }

  function controllData(formValue, oldValue) {
    let changesData = {
      section: "supplemental-wage",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];

    formValue.supplementalWages.forEach((obj1) => {
      const obj2 = oldValue.supplementalWages.find(
        (item) => item.id === obj1.id
      );

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          index: obj1.id,
          data: { ...obj1 },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.supplementalWages.forEach((obj2) => {
      const obj1 = formValue.supplementalWages.find(
        (item) => item.id === obj2.id
      );

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }
    if (!changesData.changes.length) {
      setRequestButton(false);
    }
  }
  return (
    <>
      {employeeById ? (
        <Grid>
          <Button
            style={
              formCorrection
                ? { backgroundColor: "rgba(121,46,46, 0.9)", marginTop: "10px" }
                : { marginTop: "10px" }
            }
            onClick={onClickCB}
            variant="contained"
            color="secondary"
          >
            {formCorrection ? "Ləğv et" : "Dəyişiklik et"}

            <CreateIcon sx={{ marginLeft: 5 }} />
          </Button>
          <Formik
            validationSchema={supplementalWagesValidation}
            onSubmit={handleSubmit}
            initialValues={{
              supplementalWages: initialValues.supplementalWages || [],
            }}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
            }) => (
              <Form
                style={
                  !formCorrection
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                <FieldArray name="supplementalWages">
                  {({ push, remove }) => (
                    <div>
                      {values.supplementalWages.map((form, index) => (
                        <div key={index}>
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                              }}
                            >
                              <Typography mt={3} mb={3} variant="h6">
                                {index + 1 + ")"}Əməkhaqqına əlavələr
                              </Typography>
                            </div>

                            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                              <Grid sx={{ width: "100%" }}>
                                <FormControl fullWidth>
                                  <InputLabel
                                    id={`supplementalWages[${index}].name`}
                                  >
                                    Əməkhaqqına əlavənin səbəbi
                                  </InputLabel>

                                  <Field
                                    as={Select}
                                    name={`supplementalWages[${index}].name`}
                                    labelId={`supplementalWages[${index}].name`}
                                    label="Əməkhaqqına əlavənin səbəbi"
                                    id={`supplementalWages[${index}].name`}
                                    onChange={(e) => {
                                      setRequestButton(true);
                                      setFieldValue(
                                        `supplementalWages[${index}].name`,
                                        e.target.value
                                      );
                                    }}
                                    fullWidth
                                    error={
                                      errors?.supplementalWages?.[index]
                                        ?.name &&
                                      Boolean(
                                        errors?.supplementalWages?.[index]?.name
                                      )
                                    }
                                  >
                                    {supplementName?.map((item, index) => (
                                      <MenuItem key={index} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                </FormControl>
                                {errors?.supplementalWages?.[index]?.name &&
                                  errors?.supplementalWages?.[index]?.name && (
                                    <FormHelperText error>
                                      {errors?.supplementalWages?.[index]?.name}
                                    </FormHelperText>
                                  )}
                              </Grid>
                              <Grid sx={{ width: "100%" }}>
                                <TextField
                                  type="text"
                                  value={values.supplementalWages[index].value}
                                  // as={TextField}
                                  name={`supplementalWages[${index}].value`}
                                  label="Əməkhaqqına əlavənin dəyəri"
                                  fullWidth
                                  InputProps={{
                                    // readOnly: true,
                                    endAdornment: (
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "19px",
                                        }}
                                      >
                                        &#8380;
                                      </span>
                                    ),
                                  }}
                                  error={
                                    errors?.supplementalWages?.[index]?.value &&
                                    Boolean(
                                      errors?.supplementalWages?.[index]?.value
                                    )
                                  }
                                  onChange={(e) => {
                                    setRequestButton(true);
                                    setFieldValue(
                                      `supplementalWages[${index}].value`,
                                      e.target.value
                                    );
                                  }}
                                />
                                {errors?.supplementalWages?.[index]?.value &&
                                  errors?.supplementalWages?.[index]?.value && (
                                    <FormHelperText error>
                                      {
                                        errors?.supplementalWages?.[index]
                                          ?.value
                                      }
                                    </FormHelperText>
                                  )}
                              </Grid>
                            </Grid>
                          </>
                          <Button
                            sx={
                              formCorrection
                                ? { visibility: "visible" }
                                : { visibility: "hidden" }
                            }
                            variant="contained"
                            color="error"
                            type="button"
                            style={{
                              marginBottom: "10px",
                            }}
                            onClick={() => {
                              setRequestButton(true);
                              remove(index);

                              const updatedArray =
                                values.supplementalWages.filter(
                                  (item) => item.id !== index
                                );
                              controlFormForSubmitting({
                                supplementalWages: updatedArray,
                              });
                            }}
                          >
                            Ləğv et
                          </Button>
                        </div>
                      ))}
                      <Button
                        sx={
                          formCorrection
                            ? { visibility: "visible" }
                            : { visibility: "hidden" }
                        }
                        variant="contained"
                        color="secondary"
                        style={{ marginBottom: "10px", marginTop: "20px" }}
                        onClick={() => {
                          let newId = dataIndex + 1;
                          setDataIndex(newId);
                          push({
                            id: newId,
                          });
                        }}
                      >
                        Əlavə et
                      </Button>
                    </div>
                  )}
                </FieldArray>
                <Grid
                  style={
                    formCorrection
                      ? { visibility: "visible", marginTop: "10px" }
                      : { visibility: "hidden" }
                  }
                >
                  <Button
                    type="submit"
                    style={
                      !requestButton
                        ? { pointerEvents: "none" }
                        : {
                            backgroundColor: "#28358a",
                            pointerEvents: "auto",
                          }
                    }
                    variant="contained"
                    color="secondary"
                  >
                    Dəyişikliklər sorğusu göndər
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default MCViewSalarySupplements;
