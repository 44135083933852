import { Button, Grid2 } from "@mui/material";
import React from "react";
import Header from "../../../components/layouts/header/Header";
import { useAddOvertime } from "../../../hooks/useOvertime";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const OverTimeHead = ({ set_show_Accordion, show_Accordion, setIsDate }) => {
  const { headerData } = useAddOvertime();
  return (
    <>
      <Grid2>
        <Grid2>
          <Grid2>
            <Header data={headerData} />
          </Grid2>
        </Grid2>
      </Grid2>
      <div className="d-flex justify-content-end">
        <Button
          title="Yarat"
          onClick={() => {
            set_show_Accordion(!show_Accordion);
            setIsDate(false);
          }}
          variant="contained"
          sx={{ height: "100%" }}
        >
          <p
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!show_Accordion ? (
              <>
                Yarat <AddIcon />
              </>
            ) : (
              <>
                Sıfırla <CloseIcon />
              </>
            )}
          </p>
        </Button>
      </div>
    </>
  );
};

export default OverTimeHead;
