import { endOfDay, isValid } from "date-fns";
import * as Yup from "yup";
import { checkUniqueness } from "../../api/api";
import moment from "moment";
const Swift_Leng_Min = 2,
  educationMin = 2,
  Position_Min = 2,
  Workplace_Min = 2,
  nameMinLength = 2,
  Structure_Min = 2,
  birthMinLength = 2,
  Organization_Min = 2,
  fullMinLength = 2;

const finMin = 7,
  finMax = 7,
  AA_Length = 7,
  MYI_Length = 7,
  phoneNumber = 7;

const Structure_Max = 100,
  Organization_Max = 100,
  Position_Max = 100;

const fullMaxLength = 150,
  birthMaxLength = 150,
  emailMax = 150,
  inventarLeng = 150,
  bankNameLeng = 150;

const educationMax = 500,
  Workplace_Max = 500,
  RewardsReprimandsLeng = 500,
  bankCode = 500,
  Iban_Leng = 28;

const actualAddress_Max = 255;
const foreignFin = 6;
const AZA_Length = 8;
const VOEN_Leng = 10;
const SSN_Length = 13;
const nameMaxLength = 50,
  homeNumberMax = 50;

const registrationNumber = 25;
const registrationPlate = 15;
const Swift_Leng_Max = 250;
const actualAddress_Min = 5;
const taxAccountNumber = 11;
const accountNumber_Leng = 28;
const IBAN_Leng = 28;
const cityMax = 70;
const letterNumberRegex = /^[a-zA-ZüçğıöəşÜÇĞIİÖƏŞ0-9]*$/;
const pinRegex = /^[a-hj-np-zA-HJ-NP-Z0-9]*$/;
const letterRegex = /^[a-zA-ZüçğıöəşÜÇĞIÖƏŞ\s]*$/;
const numberRegex = /^[0-9]*$/;
const cardExpirationDateRegex = /^(0[1-9]|1[0-2])\/(20[2-9][0-9])$/;
const ninetyYearsAgo = new Date();
ninetyYearsAgo.setFullYear(ninetyYearsAgo.getFullYear() - 84);
const hasFaculties = (val) => {
  switch (val) {
    case "bachelor":
    case "incompleteHigherTill1993":
    case "completeHigherTill1993":
    case "master":
    case "doctoral":
      return true;
    case "primary":
    case "generalSecondary":
    case "completeSecondary":
      return false;
    case "firstVocational":
    case "secondarySpecial":
      return true;
    case "secBasisOfGeneralSec":
    case "secBasisOfCompleteSec":
      return false;
    default:
      return false;
  }
};
export async function handleUniqueness(field, value, employeeById) {
  if (!value) return true;

  try {
    const response = await checkUniqueness(field, value, employeeById?._id);
    return response.data.isUnique;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const isDateMoreThan50Years = (dateString) => {
  const enteredDate = new Date(dateString);
  const currentDate = new Date();

  // Calculate the date 50 years ago from the current date
  const fiftyYearsAgo = new Date();
  fiftyYearsAgo.setFullYear(currentDate.getFullYear() - 50);

  // Compare the entered date with the date 50 years ago
  return enteredDate < fiftyYearsAgo;
};

const isEmailUnique = (emailArray) => {
  const filledEmails = emailArray.filter((email) => email !== "");
  const uniqueEmails = new Set(filledEmails);
  return uniqueEmails.size === 1 && filledEmails.length > 1;
};

export const personalInformationValidation = (employeeId) => {
  // const fiftyYearsAgo = new Date();
  // fiftyYearsAgo.setFullYear(fiftyYearsAgo.getFullYear() - 50);

  return Yup.object({
    firstname: Yup.string()
      .matches(/^[^\d]+$/, "Rəqəmlər daxil edilə bilməz")
      .min(nameMinLength, "Ad minimum 2 hərf olmalıdır")
      .max(nameMaxLength, "Ad maksimum 50 hərf olmalıdır")
      .required("Ad daxil edilməlidir"),
    lastname: Yup.string()
      .matches(/^[^\d]+$/, "Rəqəmlər daxil edilə bilməz")
      .min(nameMinLength, "Soyad minimum 2 hərf olmalıdır")
      .max(nameMaxLength, "Soyad maksimum 50 hərf olmalıdır")
      .required("Soyad daxil edilməlidir"),
    fathername: Yup.string()
      .matches(/^[^\d]+$/, "Rəqəmlər daxil edilə bilməz")
      .min(nameMinLength, "Ata adı minimum 2 hərf olmalıdır")
      .max(nameMaxLength, "Ata adı maksimum 50 hərf olmalıdır")
      .required("Ata adı daxil edilməlidir"),
    gender: Yup.string().required("Cins daxil edilməlidir"),
    dob: Yup.date()
      .typeError("Tarix etibarlı formatda olmalıdır")
      .max(new Date(), "Doğum tarixi gələcək tarix ola bilməz.")
      .min(
        ninetyYearsAgo,
        `Doğum tarixi ${moment(ninetyYearsAgo).format(
          "DD/MM/YYYY"
        )} tarixindən əvvəl ola bilməz.`
      )
      .nullable()
      .required("Doğum tarixi düzgün doldurulmalıdır.")
      .test(
        "is-after-idCardIssuanceDate",
        "Doğum tarixi şəxsiyyət vəsiqəsinin verilmə tarixindən sonra ola bilməz.",
        function (value) {
          const { idCardIssuanceDate } = this.parent;
          return !idCardIssuanceDate || value < idCardIssuanceDate;
        }
      ),
    placeOfBirth: Yup.string()
      .min(birthMinLength, "Doğum yeri minimum 2 hərf olmalıdır")
      .max(birthMaxLength, "Doğum yeri maksimum 150 hərf olmalıdır")
      .required("Doğum yeri daxil edilməlidir"),
    idCardNumber: Yup.object().shape({
      code: Yup.string().required("Ş.V/Seriya № -nin kodunu seçin"),
      number: Yup.string()
        .when("code", {
          is: (code) => code === "AA",
          then: Yup.string()
            .min(AA_Length, "Ş.V/Seriya nömrəsi minimum 7 simvol olmalıdır")
            .max(AA_Length, "Ş.V/Seriya nömrəsi maksimum 7 simvol olmalıdır")
            .required("Ş.V/Seriya № -ni daxil edin"),
          otherwise: Yup.string()
            .min(AZA_Length, "Ş.V/Seriya nömrəsi minimum 8 simvol olmalıdır")
            .max(AZA_Length, "Ş.V/Seriya nömrəsi maksimum 8 simvol olmalıdır")
            .required("Ş.V/Seriya № -ni daxil edin"),
        })
        .matches(numberRegex, "Yalnız rəqəmlər qəbul olunur")
        .test(
          "check-cardNumber",
          "Bu Ş.V/Seriya № artıq istifadə edilib",
          async function (value) {
            const { code, number } = this.parent;
            const combinedValue = code + number;

            const response = await handleUniqueness(
              "idCardNumber",
              combinedValue,
              employeeId
            );

            return response;
          }
        ),
    }),
    pin: Yup.string()
      .min(finMin, "FIN nömrəsi minimum 7 simvol olmalıdır")
      .max(finMax, "FIN nömrəsi maksimum 7 simvol olmalıdır")
      .matches(pinRegex, "FIN nömrəsi düzgün deyil")
      .required("FIN nömrəsi daxil edilməlidir")
      .test(
        "check-pin",
        "Bu PIN artıq istifadə edilib",
        async function (value) {
          const response = await handleUniqueness("pin", value, employeeId);
          return response;
        }
      ),
    idCardIssuanceDate: Yup.date()
      .max(new Date(), "Gələcək tarix ola bilməz.")
      .min(
        ninetyYearsAgo,
        `Tarix ${moment(ninetyYearsAgo).format(
          "DD/MM/YYYY"
        )} tarixindən əvvəl ola bilməz.`
      )
      .nullable()
      .required("Şəxsiyyət vəsiqəsinin verilmə tarixi daxil edilməlidir")
      .test(
        "is-before-dob",
        "Şəxsiyyət vəsiqəsinin verilmə tarixi doğum tarixidən əvvəl ola bilməz.",
        function (value) {
          const { dob } = this.parent;
          return !dob || value > dob;
        }
      ),
    idCardValidityPeriod: Yup.date().when("dob", (dob, schema) => {
      return isDateMoreThan50Years(dob)
        ? schema.nullable().notRequired()
        : schema
            .min(
              new Date(),
              "Şəxsiyyət vəsiqəsinin etibarlılıq tarixi keçmiş tarix ola bilməz."
            )
            .max(
              new Date("2099-12-31"),
              "Şəxsiyyət vəsiqəsinin etibarlılıq tarixi 2099-cu ildən yuxarı ola bilməz."
            )
            .nullable();
      // .required(
      //   "Şəxsiyyət vəsiqəsinin etibarlılıq müddəti daxil edilməlidir"
      // );
    }),
    idCardAuthority: Yup.string().required(
      "Şəxsiyyət vəsiqəsinin verən orqanı daxil edilməlidir"
    ),
    nationality: Yup.string().required("Milliyyət daxil edilməlidir"),
    ssn: Yup.string()
      .matches(/^\d+$/, {
        message:
          "Sosial Təhlükəsizlik Nömrəsi (SSN) yalnız rəqəmlərdən ibarət olmalıdır",
      })
      .length(
        SSN_Length,
        "Sosial Təhlükəsizlik Nömrəsi (SSN) 13 simvol olmalıdır"
      )
      .test(
        "check-SSN",
        "Bu SSN artıq istifadə edilib",
        async function (value) {
          const response = await handleUniqueness("ssn", value, employeeId);
          return response;
        }
      ),
    maritalStatus: Yup.string().required("Ailə vəziyyəti daxil edilməlidir"),
  });
};

export const foreignEmployeeValidation = (employeeId) => {
  return Yup.object({
    firstname: Yup.string()
      .matches(/^[^\d]+$/, "Rəqəmlər daxil edilə bilməz")
      .min(nameMinLength, "Ad minimum 2 hərf olmalıdır")
      .max(nameMaxLength, "Ad maksimum 50 hərf olmalıdır")
      .required("Ad daxil edilməlidir"),
    lastname: Yup.string()
      .matches(/^[^\d]+$/, "Rəqəmlər daxil edilə bilməz")
      .min(nameMinLength, "Soyad minimum 2 hərf olmalıdır")
      .max(nameMaxLength, "Soyad maksimum 50 hərf olmalıdır")
      .required("Soyad daxil edilməlidir"),
    fathername: Yup.string()
      .matches(/^[^\d]+$/, "Rəqəmlər daxil edilə bilməz")
      .min(nameMinLength, "Ata adı minimum 2 hərf olmalıdır")
      .max(nameMaxLength, "Ata adı maksimum 50 hərf olmalıdır")
      .required("Ata adı daxil edilməlidir"),
    gender: Yup.string().required("Cins daxil edilməlidir"),
    dob: Yup.date()
      .max(new Date(), "Doğum tarixi gələcək tarix ola bilməz.")
      .min(
        ninetyYearsAgo,
        `Doğum tarixi ${moment(ninetyYearsAgo).format(
          "DD/MM/YYYY"
        )} tarixindən əvvəl ola bilməz.`
      )
      .nullable()
      .required("Doğum tarixi düzgün doldurulmalıdır.")
      .test(
        "is-after-idCardIssuanceDate",
        "Doğum tarixi şəxsiyyət vəsiqəsinin verilmə tarixindən sonra ola bilməz.",
        function (value) {
          const { idCardIssuanceDate } = this.parent;
          return !idCardIssuanceDate || value < idCardIssuanceDate;
        }
      ),
    placeOfBirth: Yup.string()
      .min(birthMinLength, "Doğum yeri minimum 2 hərf olmalıdır")
      .max(birthMaxLength, "Doğum yeri maksimum 150 hərf olmalıdır")
      .required("Doğum yeri daxil edilməlidir"),
    idCardNumber: Yup.object().shape({
      number: Yup.string()
        .required("Passport nömrəsi daxil edilməlidir")
        .test(
          "check-idCardNumber",
          "Passport nömrəsi artıq istifadə edilib",
          async function (value) {
            const response = await handleUniqueness(
              "idCardNumber",
              value,
              employeeId
            );
            return response;
          }
        ),
    }),
    pin: Yup.string()
      .min(foreignFin, "FIN nömrəsi minimum 6 simvol olmalıdır")
      .max(foreignFin, "FIN nömrəsi maksimum 6 simvol olmalıdır")
      .matches(pinRegex, "FIN nömrəsi düzgün deyil")
      .required("FIN nömrəsi daxil edilməlidir")
      .test(
        "check-pin",
        "Bu PIN artıq istifadə edilib",
        async function (value) {
          const response = await handleUniqueness("pin", value, employeeId);
          return response;
        }
      ),
    tRPNumber: Yup.string()
      .matches(/^\d+$/, {
        message: "MYİ seriya nömrəsi yalnız rəqəmlərdən ibarət olmalıdır",
      })
      .min(MYI_Length, "MYİ seriya nömrəsi minimum 7 simvol olmalıdır")
      .max(MYI_Length, "MYİ seriya nömrəsi maksimum 7 simvol olmalıdır")
      .required("MYİ seriya nömrəsi daxil edilməlidir")
      .test(
        "check-myi",
        "Bu MYİ seriya nömrəsi artıq istifadə edilib",
        async function (value) {
          const response = await handleUniqueness(
            "tRPNumber",
            value,
            employeeId
          );
          return response;
        }
      ),
    idCardIssuanceDate: Yup.date()
      .max(new Date(), "Gələcək tarix ola bilməz.")
      .nullable()
      .required("Passportun/Vəsiqəsinin verilmə tarixi daxil edilməlidir"),
    idCardValidityPeriod: Yup.date().when("dob", (dob, schema) => {
      return isDateMoreThan50Years(dob)
        ? schema.nullable().notRequired()
        : schema.min(new Date(), "Keçmiş tarix ola bilməz .").nullable();
      // .required(
      //   "Şəxsiyyət vəsiqəsinin etibarlılıq müddəti daxil edilməlidir"
      // );
    }),
    // .required("Passportun etibarlılıq müddəti qeyd olunmalıdır .")
    // .typeError("Passportun etibarlılıq müddəti qeyd olunmalıdır."),
    residencePermitStartDate: Yup.date()
      .max(new Date(), "Gələcək tarix ola bilməz.")
      .nullable()
      .required("Yaşamaq icazəsi başlama tarixi daxil edilməlidir"),
    residencePermitEndDate: Yup.date()
      .min(new Date(), "Keçmiş tarix ola bilməz.")
      .nullable()
      .required("Yaşamaq icazəsi bitmə tarixini daxil edilməlidir"),
    workPermitStartDate: Yup.date()
      .max(new Date(), "Gələcək tarix ola bilməz.")
      .nullable()
      .required("İş icazəsi başlama tarixi daxil edilməlidir"),
    workPermitEndDate: Yup.date()
      .min(new Date(), "Keçmiş tarix ola bilməz.")
      .nullable()
      .required("İş icazəsi bitmə tarixi daxil edilməlidir"),
    idCardAuthority: Yup.string().required(
      "Sənədi/Vəsiqəni veren orqan daxil edilməlidir"
    ),
    nationality: Yup.string().required("Milliyyəti daxil edilməlidir"),
    citizenship: Yup.string().required("Vətəndaşlıq daxil edilməlidir"),
    ssn: Yup.string()
      .matches(/^\d+$/, {
        message:
          "Sosial Təhlükəsizlik Nömrəsi (SSN) yalnız rəqəmlərdən ibarət olmalıdır",
      })
      .length(
        SSN_Length,
        "Sosial Təhlükəsizlik Nömrəsi (SSN) 13 simvol olmalıdır"
      )
      .test(
        "check-SSN",
        "Bu SSN artıq istifadə edilib",
        async function (value) {
          const response = await handleUniqueness("ssn", value, employeeId);
          return response;
        }
      ),
    maritalStatus: Yup.string().required("Ailə vəziyyəti daxil edilməlidir"),
  });
};

export const bankInfoValidation = (employeeId) => {
  return Yup.object().shape({
    bankInfos: Yup.array().of(
      Yup.object().shape({
        bankName: Yup.string().required("Bank adı zəruridir"),
        // bankCode: Yup.number()
        //   .required("Bank kodu zəruridir")
        //   .max(bankCode, "Bank kodu maksimum 500 simvol olmalıdır"),
        // bankVoen: Yup.string().required("Bank VÖEN zəruridir"),
        accountNumber: Yup.string()
          .trim()
          .matches(
            letterNumberRegex,
            "Hesab nömrəsi hərf və rəqəmlərdən ibarət olmalıdır"
          )
          .min(IBAN_Leng, "Hesab nömrəsi minimum 28 simvol olmalıdır")
          .max(IBAN_Leng, "Hesab nömrəsi maksimum 28 simvol olmalıdır")
          .required("Hesab nömrəsi zəruridir")
          .test(
            "check-accountNumber-iban",
            "Hesab nömrəsi və IBAN eyni ola bilməz",
            function (value, context) {
              const iban = this.parent.iban;
              const { bankInfos } = context?.from[1]?.value;

              const accountNumberExists =
                bankInfos &&
                bankInfos.some((detail) => detail.accountNumber === iban);

              // Return true if ibanExists is false, because we want the test to pass
              return !accountNumberExists;
            }
          )
          .test(
            "unique-accountNumber",
            "Hesab nömrəsi eyni ola bilməz",
            function (value, context) {
              const { bankInfos } = context?.from[1]?.value;
              const accountNumbers =
                bankInfos && bankInfos?.map((detail) => detail?.accountNumber);
              return (
                accountNumbers?.filter((number) => number === value).length ===
                1
              );
            }
          ),
        iban: Yup.string()
          .trim()
          .matches(
            letterNumberRegex,
            "IBAN yalnız hərf və rəqəmlərdən ibarət olmalıdır"
          )
          .min(IBAN_Leng, "IBAN 28 minimum simvol olmalıdır")
          .max(IBAN_Leng, "IBAN 28 maksimum simvol olmalıdır")
          .required("IBAN zəruridir")
          .test(
            "check-accountNumber-iban",
            "Hesab nömrəsi və IBAN eyni ola bilməz",
            function (value, context) {
              const accountNumber = this.parent.accountNumber;
              const { bankInfos } = context?.from[1]?.value;

              const ibanExists =
                bankInfos &&
                bankInfos.some((detail) => detail.iban === accountNumber);

              // Return true if ibanExists is false, because we want the test to pass
              return !ibanExists;
            }
          )

          .test(
            "unique-IBAN",
            "IBAN kodu eyni ola bilməz",
            function (value, context) {
              const { bankInfos } = context?.from[1]?.value;
              const iban =
                bankInfos && bankInfos?.map((detail) => detail?.iban);
              return (
                iban && iban.filter((number) => number === value).length === 1
              );
            }
          ),
        cardExpirationDate: Yup.string()
          .test("custom-validations", function (value) {
            if (!value) return true; // Allow empty value, if required add .required() to Yup.string()

            const [month, year] = value.split("/").map(Number);
            const today = new Date();
            const expirationDate = new Date(year, month - 1); // JavaScript months are 0-based

            if (year > 2099) {
              return this.createError({
                message:
                  "Zəhmət olmasa kartın etibarlıq müddətini 2099-dan böyük daxil etməyin.",
              });
            }

            if (
              expirationDate < new Date(today.getFullYear(), today.getMonth())
            ) {
              return this.createError({
                message: "Kartın etibarlıq müddəti keçmiş ola bilməz.",
              });
            }

            return true;
          })
          .matches(
            cardExpirationDateRegex,
            "Zəhmət olmasa kartın etibarlıq müddətini düzgün daxil edin.(MM/YYYY)"
          ),
      })
    ),
  });
};

export const test = (employeeId) => {
  return Yup.object().shape({
    bankInfos: Yup.array().of(
      Yup.object()
        .shape({
          bankName: Yup.string(),
          bankCode: Yup.number().typeError("Bank code must be a number"),
          bankVoen: Yup.string(),
          accountNumber: Yup.string(),
          iban: Yup.string(),
        })
        .test(
          "all-required-or-all-optional",
          "Bütün hissələr doldurulmalıdır və yaxud boş keçilməlidir",
          function (value) {
            const { bankName, bankCode, bankVoen, accountNumber, iban } = value;
            const filledFields = [
              bankName,
              bankCode,
              bankVoen,
              accountNumber,
              iban,
            ].filter(Boolean);

            if (filledFields.length > 0 && filledFields.length < 5) {
              return false;
            }

            return true;
          }
        )
    ),
  });
};

export const Step3Schema = (employeeById) => {
  return Yup.object().shape({
    laborActivity: Yup.array().of(
      Yup.lazy((value, { parent, createError, index }) => {
        return Yup.object().shape({
          organizationName: Yup.string()
            .when("sameWorkplace", {
              is: false,
              then: Yup.string()
                .test(
                  "minLength",
                  "Təşkilatın adı minimum 2 hərf olmalıdır",
                  (value) => value && value.trim().length >= Organization_Min
                )
                .max(
                  Organization_Max,
                  "Təşkilatın adı maksimum 100 hərf olmalıdır"
                )
                .required("Təşkilatın adı zəruridir"),
            })
            .nullable(),
          department: Yup.string()
            .min(Structure_Min, "Struktur bölmə adı minimum 2 hərf olmalıdır")
            .max(
              Structure_Max,
              "Struktur bölmə adı maksimum 100 hərf olmalıdır"
            )
            .required("Struktur bölmə adı zəruridir"),
          position: Yup.string()
            .min(Position_Min, "Vəzifə adı minimum 2 hərf olmalıdır")
            .max(Position_Max, "Vəzifə adı maksimum 100 hərf olmalıdır")
            .required("Vəzifə adı zəruridir"),
          isCivilServant: Yup.string().required(
            "Dövlət qulluqçusu xanası zəruridir"
          ),
          startDate: Yup.date()
            .required("Başlama tarixi zəruridir")
            .typeError("Tarix etibarlı formatda olmalıdır")
            .min(
              Yup.ref("startDate"),
              "Başlama tarixi bitiş tarixidən əvvəl olmalıdır"
            )
            .max(
              Yup.ref("endDate"),
              "Başlama tarixi bitiş tarixidən əvvəl olmalıdır"
            )
            .max(new Date(), "Başlama tarixi gələcək tarix ola bilməz.")
            .test(
              "unique-startDate",
              "Başlama tarixləri fərqli olmalıdır",
              function (value) {
                if (!value) {
                  return true;
                }
                for (let i = 0; i < parent.length; i++) {
                  let elem = parent[i].startDate;
                  if (
                    elem &&
                    elem.toString() === value.toString() &&
                    i !== index
                  ) {
                    return false;
                  }
                }
                return true;
              }
            )
            .nullable(),
          endDate: Yup.date()
            .typeError("Tarix etibarlı formatda olmalıdır")
            .min(
              Yup.ref("startDate"),
              "Bitiş tarixi başlama tarixidən sonra olmalıdır"
            )
            .max(new Date(), "Bitiş tarixi gələcək tarix ola bilməz.")
            .when("present", {
              is: false,
              then: Yup.date()
                .required("Bitiş tarixi zəruridir")
                .typeError("Tarix etibarlı formatda olmalıdır")
                .test(
                  "unique-endDate",
                  "Bitiş tarixləri fərqli olmalıdır",
                  function (value) {
                    if (!value) {
                      return true;
                    }
                    for (let i = 0; i < parent.length; i++) {
                      let elem = parent[i].endDate;

                      if (
                        elem &&
                        elem.toString() === value.toString() &&
                        i !== index
                      ) {
                        return false;
                      }
                    }
                    return true;
                  }
                ),
              otherwise: Yup.date().nullable(),
            })
            .nullable(),
        });
      })
    ),
  });
};

export const editLaborActivity = (employeeId) => {
  return Yup.object().shape({
    laborActivity: Yup.array().of(
      Yup.lazy((value, { parent, createError, index }) => {
        return Yup.object().shape({
          organizationName: Yup.string()
            .when("sameWorkplace", {
              is: false,
              then: Yup.string()
                .test(
                  "minLength",
                  "Təşkilatın adı minimum 2 hərf olmalıdır",
                  (value) => value && value.trim().length >= Organization_Min
                )
                .max(
                  Organization_Max,
                  "Təşkilatın adı maksimum 100 hərf olmalıdır"
                )
                .required("Təşkilatın adı zəruridir"),
            })
            .nullable(),
          department: Yup.string().when("sameWorkplace", {
            is: false,
            then: Yup.string()
              .min(Structure_Min, "Struktur bölmə adı minimum 2 hərf olmalıdır")
              .max(
                Structure_Max,
                "Struktur bölmə adı maksimum 100 hərf olmalıdır"
              )
              .required("Struktur bölmə adı zəruridir"),
          }),
          position: Yup.string().when("sameWorkplace", {
            is: false,
            then: Yup.string()
              .min(Position_Min, "Vəzifə adı minimum 2 hərf olmalıdır")
              .max(Position_Max, "Vəzifə adı maksimum 100 hərf olmalıdır")
              .required("Vəzifə adı zəruridir"),
          }),
          isCivilServant: Yup.string().when("sameWorkplace", {
            is: false,
            then: Yup.string().required("Dövlət qulluqçusu xanası zəruridir"),
          }),
          startDate: Yup.date()
            .transform((value, originalValue) => {
              // Attempt to convert the input value to a Date object
              const parsedDate = new Date(originalValue);
              // Return the parsed Date object if it is valid, or null otherwise
              return isValid(parsedDate) ? parsedDate : null;
            })
            .min(
              Yup.ref("startDate"),
              "Başlama tarixi bitiş tarixidən əvvəl olmalıdır"
            )
            .max(
              Yup.ref("endDate"),
              "Başlama tarixi bitiş tarixidən əvvəl olmalıdır"
            )
            .max(new Date(), "Başlama tarixi gələcək tarix ola bilməz.")
            .test(
              "unique-startDate",
              "Başlama tarixləri fərqli olmalıdır",
              function (value) {
                if (!value) {
                  return true;
                }
                for (let i = 0; i < parent.length; i++) {
                  let elem = parent[i].startDate;
                  if (
                    elem &&
                    elem.toString() === value.toString() &&
                    i !== index
                  ) {
                    return false;
                  }
                }
                return true;
              }
            )
            .nullable()
            .required("Başlama tarixi zəruridir"),
          endDate: Yup.date()
            .transform((value, originalValue) => {
              // Attempt to convert the input value to a Date object
              const parsedDate = new Date(originalValue);
              // Return the parsed Date object if it is valid, or null otherwise
              return isValid(parsedDate) ? parsedDate : null;
            })
            .min(
              Yup.ref("startDate"),
              "Bitiş tarixi başlama tarixidən sonra olmalıdır"
            )
            .max(new Date(), "Bitiş tarixi gələcək tarix ola bilməz.")
            .nullable()
            .when("present", {
              is: false,
              then: Yup.date()
                .required("Bitiş tarixi zəruridir")
                .test(
                  "unique-endDate",
                  "Bitiş tarixləri fərqli olmalıdır",
                  function (value) {
                    if (!value) {
                      return true;
                    }
                    for (let i = 0; i < parent.length; i++) {
                      let elem = parent[i].endDate;

                      if (
                        elem &&
                        elem.toString() === value.toString() &&
                        i !== index
                      ) {
                        return false;
                      }
                    }
                    return true;
                  }
                ),
              otherwise: Yup.date().nullable(),
            }),
        });
      })
    ),
  });
};

export const Step4Schema = (employeeId) => {
  const relationshipName =
    /^[a-zA-ZüçğıöəşÜÇĞIİÖƏŞ](?:[a-zA-ZüçğıöəşÜÇĞIİÖƏŞ\s]{0,2}[a-zA-ZüçğıöəşÜÇĞIİÖƏŞ])*[a-zA-ZüçğıöəşÜÇĞIİÖƏŞ]$/;
  return Yup.object().shape({
    relationshipInfo: Yup.array().of(
      Yup.object().shape({
        relationType: Yup.string()
          .required("Qohumluq əlaqəsi növü zəruridir")
          .test(
            "unique-relationType",
            "Eyni qohumluq əlaqəsi növü iki dəfə yazıla bilməz",
            function (value) {
              // Check if value is "sister", "brother", "son", or "daughter"
              if (
                value === "sister" ||
                value === "brother" ||
                value === "son" ||
                value === "daughter" ||
                value === "grandchild"
              ) {
                return true; // Skip validation error for these values
              }

              // Otherwise, perform the duplicate check
              const relationshipInfo =
                this.options.from[1].value.relationshipInfo;
              const currentRelationType = this.parent.relationType;

              const count = relationshipInfo.filter(
                (info) => info.relationType === currentRelationType
              ).length;

              return count <= 1; // Return true or false based on duplicate check
            }
          ),
        fullname: Yup.string()
          .min(
            fullMinLength,
            "Soyadı Adı Atasının adı minimum 2 simvol olmalıdır"
          )
          .max(
            fullMaxLength,
            "Soyadı Adı Atasının adı maksimum 150 simvol olmalıdır"
          )
          // .matches(
          //   relationshipName,
          //   "Soyadı Adı Atasının adı  yalnız hərflərdən ibarət olmalıdır"
          // )
          // .test("spaces", "Soyadı Adı adı tam daxil edilməlidir", (value) => {
          //   if (!value) {
          //     return false; // Return false if value is undefined
          //   }
          //   const spaceCount = (value.match(/ /g) || []).length;
          //   return spaceCount === 1;
          // })
          .required("Soyadı Adı adı zəruridir"),
        pin: Yup.string()
          .matches(
            letterNumberRegex,
            "FIN yalnız hərf və rəqəmlərdən ibarət olmalıdır"
          )
          .when("isForeigner", {
            is: false,
            then: Yup.string()
              .min(finMin, "FIN minimum 7 simvol olmalıdır")
              .max(finMax, "FIN maksimum 7 simvol olmalıdır"),
            // .required("FIN zəruridir"),
          })
          .when("isForeigner && isMyiExist", {
            is: true,
            then: Yup.string()
              .min(foreignFin, "FIN minimum 6 simvol olmalıdır")
              .max(foreignFin, "FIN maksimum 6 simvol olmalıdır"),
            // .required("FIN zəruridir"),
          }),
        idCardNumber: Yup.object().when("isForeigner", {
          is: true,
          then: Yup.object().shape({
            number: Yup.string().matches(/^\d+$/, {
              message: "Passport nömrəsini yalnız rəqəmlərdən ibarət olmalıdır",
            }),
            // .required("Passport nömrəsini daxil edin"),
          }),
          otherwise: Yup.object().shape({
            code: Yup.string().nullable(),
            number: Yup.string().when("code", {
              is: (code) => code === "AA",
              then: Yup.string()
                .min(AA_Length, "Ş.V/Seriya minimum 7 simvol olmalıdır")
                .max(AA_Length, "Ş.V/Seriya maksimum 7 simvol olmalıdır")
                .matches(
                  letterNumberRegex,
                  "Ş.V/Seriya yalnız hərf və rəqəmlərdən ibarət olmalıdır"
                ),
              // .required("Ş.V/Seriya № -ni daxil edin"),
              otherwise: Yup.string()
                .min(AZA_Length, "Ş.V/Seriya minimum 8 simvol olmalıdır")
                .max(AZA_Length, "Ş.V/Seriya maksimum 8 simvol olmalıdır")
                .matches(
                  letterNumberRegex,
                  "Ş.V/Seriya yalnız hərf və rəqəmlərdən ibarət olmalıdır"
                ),
              // .required("Ş.V/Seriya № -ni daxil edin"),
            }),
          }),
        }),
        tRPNumber: Yup.string().when("isForeigner && isMyiExist", {
          is: true,
          then: Yup.string()
            .matches(/^\d+$/, {
              message: "MYİ seriya nömrəsi yalnız rəqəmlərdən ibarət olmalıdır",
            })
            .min(MYI_Length, "MYİ seriya nömrəsi 7 simvol olmalıdır")
            .max(MYI_Length, "MYİ seriya nömrəsi maksimum 7 simvol olmalıdır")
            .required("MYİ seriya nömrəsi zəruridir"),
        }),
        dob: Yup.date().max(
          new Date(),
          "Doğum tarixi gələcək tarix ola bilməz."
        ),
      })
    ),
  });
};

export const ContactInfoValidation = (employeeId) => {
  return Yup.object().shape({
    actualAddress: Yup.object().shape({
      address: Yup.string()
        // .matches(
        //   /^[a-zA-ZüçğıöəşÜÇĞIİÖƏŞ0-9]+(?: [a-zA-ZüçğıöəşÜÇĞIİÖƏŞ0-9]+)*$/,
        //   "Yalnız hərflər, rəqəmlər və boşluqlar qəbul olunur"
        // )
        .min(actualAddress_Min, "Faktiki ünvan minimum 5 simvol olmalıdır")
        .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır")
        .required("Faktiki ünvanı daxil edin"),
      city: Yup.string()
        .when("district", {
          is: (val) => !val || val === "",
          then: Yup.string().required("Şəhər və ya rayon seçin"),
          otherwise: Yup.string(),
        })
        .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır"),
      district: Yup.string()
        .test("district", "Rayon daxil edin", function (value) {
          const city = this.resolve(Yup.ref("city"));
          if (
            (city === "Bakı" || city === "Sumqayıt") &&
            (!value || value === "")
          ) {
            return this.createError({
              path: "actualAddress.district",
              message: "Rayon daxil edin",
            });
          }
          return true;
        })
        .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır"),
      homeNumber: Yup.array().of(
        Yup.string()
          .max(homeNumberMax, "Ev nömrəsi maksimum 50 simvol olmalıdır")
          .matches(
            numberRegex,
            "Ev nömrəsi yalnız rəqəmlərdən ibarət olmalıdır!"
          )
          .test(
            "check-homeNumber",
            "Bu ev telefonu artıq istifadə edilib",
            async function (value) {
              const response = await handleUniqueness(
                "homeNumber",
                value,
                employeeId
              );
              return response;
            }
          )
      ),
    }),
    registeredAddress: Yup.object().when(["sameAddress", "actualAddress"], {
      is: (sameAddress, actualAddress) => !sameAddress && actualAddress,
      then: Yup.object().shape({
        address: Yup.string()
          .min(actualAddress_Min, "Qeydiyyat ünvan minimum 5 simvol olmalıdır")
          .max(
            actualAddress_Max,
            "Qeydiyyat ünvan maksimum 500 simvol olmalıdır"
          )
          .required("Qeydiyyat ünvanı daxil edin")
          .test(
            "unique-address",
            "Eyni adres iki dəfə yazıla bilməz",
            function (value) {
              let address1 = this.options.from[1].value.actualAddress.address;
              let address2 = this.parent.address;
              const actualAddress = address1 === address2;
              return !actualAddress;
            }
          ),
        city: Yup.string().when("district", {
          is: (val) => !val || val === "",
          then: Yup.string().required("Şəhər və ya rayon seçin"),
          otherwise: Yup.string().max(
            cityMax,
            "Şəhər adı maksimum 70 simvol olmalıdır"
          ),
        }),
        district: Yup.string()
          .test("district", "Rayon daxil edin", function (value) {
            const city = this.resolve(Yup.ref("city"));
            if (
              (city === "Bakı" || city === "Sumqayıt") &&
              (!value || value === "")
            ) {
              return this.createError({
                path: "registeredAddress.district",
                message: "Rayon daxil edin",
              });
            }
            return true;
          })
          .max(
            actualAddress_Max,
            "Qeydiyyat ünvan maksimum 500 simvol olmalıdır"
          ),
      }),
    }),
    mobile: Yup.array()
      .of(
        Yup.object().shape({
          code: Yup.string().required("Operator daxil edilməlidir"),
          number: Yup.string()
            .matches(numberRegex, "Mobil nömrə rəqəmlərdən ibarət olmalıdır")
            // .min(phoneNumber, "Mobil nömrə minimum 7 simvol olmalıdır")
            // .max(phoneNumber, "Mobil nömrə maksimum 7 simvol olmalıdır")
            .test("number", "Nömrə daxil edilməlidir", (value) => value !== "")
            .required("Nömrə daxil edilməlidir")
            .test(
              "check-mobile",
              "Bu nömrə artıq istifadə edilib",
              async function (value) {
                const { code, number } = this.parent;
                const combinedValue = code + number;
                const response = await handleUniqueness(
                  "mobile",
                  combinedValue,
                  employeeId
                );
                return response;
              }
            ),
        })
      )
      .required("Mobil nömrəni daxil edin"),

    email: Yup.array().of(
      Yup.string()
        .email("Düzgün email daxil edin")
        .required("Email daxil edilməlidir")
        .test("check-mail", "", async function (value) {
          const response = await checkUniqueness("email", value, employeeId);
          if (!response?.data?.isUnique) {
            return this.createError({
              message: response?.data?.message,
            });
          }
          return true;
        })
        .test(
          "check-email",
          "Bu email artıq istifadə edilib və yaxud heç bir istifadəçiyə aid deyil",
          async function (value, { parent }) {
            const isAllEmailsSame = isEmailUnique(parent);
            return !isAllEmailsSame;
          }
        )
        .max(150, "Email maksimum 150 simvol olmalıdır")
    ),
  });
};
export const ContactEditInfoValidation = (employeeId) => {
  return Yup.object().shape({
    actualAddress: Yup.object().shape({
      address: Yup.string()
        // .matches(
        //   /^[a-zA-ZüçğıöəşÜÇĞIİÖƏŞ0-9]+(?: [a-zA-ZüçğıöəşÜÇĞIİÖƏŞ0-9]+)*$/,
        //   "Yalnız hərflər, rəqəmlər və boşluqlar qəbul olunur"
        // )
        .min(actualAddress_Min, "Faktiki ünvan minimum 5 simvol olmalıdır")
        .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır")
        .required("Faktiki ünvanı daxil edin"),
      city: Yup.string()
        .when("district", {
          is: (val) => !val || val === "",
          then: Yup.string().required("Şəhər və ya rayon seçin"),
          otherwise: Yup.string(),
        })
        .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır"),
      district: Yup.string()
        .test("district", "Rayon daxil edin", function (value) {
          const city = this.resolve(Yup.ref("city"));
          if (
            (city === "Bakı" || city === "Sumqayıt") &&
            (!value || value === "")
          ) {
            return this.createError({
              path: "actualAddress.district",
              message: "Rayon daxil edin",
            });
          }
          return true;
        })
        .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır"),
      homeNumber: Yup.array().of(
        Yup.string()
          .max(homeNumberMax, "Ev nömrəsi maksimum 50 simvol olmalıdır")
          .matches(
            numberRegex,
            "Ev nömrəsi yalnız rəqəmlərdən ibarət olmalıdır!"
          )
        // .test(
        //   "check-homeNumber",
        //   "Bu ev telefonu artıq istifadə edilib",
        //   async function (value) {
        //     const response = await handleUniqueness(
        //       "homeNumber",
        //       value,
        //       employeeId
        //     );
        //     return response;
        //   }
        // )
      ),
    }),
    registeredAddress: Yup.object().when(["sameAddress", "actualAddress"], {
      is: (sameAddress, actualAddress) => !sameAddress && actualAddress,
      then: Yup.object().shape({
        address: Yup.string()
          .min(actualAddress_Min, "Faktiki ünvan minimum 5 simvol olmalıdır")
          .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır")
          .required("Faktiki ünvanı daxil edin")
          .test(
            "unique-address",
            "Eyni adres iki dəfə yazıla bilməz",
            function (value) {
              let address1 = this.options.from[1].value.actualAddress.address;
              let address2 = this.parent.address;
              const actualAddress = address1 === address2;
              return !actualAddress;
            }
          ),
        city: Yup.string().when("district", {
          is: (val) => !val || val === "",
          then: Yup.string().required("Şəhər və ya rayon seçin"),
          otherwise: Yup.string().max(
            cityMax,
            "Şəhər adı maksimum 70 simvol olmalıdır"
          ),
        }),
        district: Yup.string()
          .test("district", "Rayon daxil edin", function (value) {
            const city = this.resolve(Yup.ref("city"));
            if (
              (city === "Bakı" || city === "Sumqayıt") &&
              (!value || value === "")
            ) {
              return this.createError({
                path: "registeredAddress.district",
                message: "Rayon daxil edin",
              });
            }
            return true;
          })
          .max(
            actualAddress_Max,
            "Faktiki ünvan maksimum 500 simvol olmalıdır"
          ),
      }),
    }),
    mobile: Yup.array()
      .of(
        Yup.object().shape({
          code: Yup.string().required("Operator daxil edilməlidir"),
          number: Yup.string()
            .matches(numberRegex, "Mobil nömrə rəqəmlərdən ibarət olmalıdır")
            // .min(phoneNumber, "Mobil nömrə minimum 7 simvol olmalıdır")
            // .max(phoneNumber, "Mobil nömrə maksimum 7 simvol olmalıdır")
            .test("number", "Nömrə daxil edilməlidir", (value) => value !== "")
            .required("Nömrə daxil edilməlidir"),
        })
      )
      .required("Mobil nömrəni daxil edin")
      .test(
        "unique-mobile",
        "Mobil nömrə təkrarlanmamalıdır",
        function (value) {
          if (value) {
            const seen = new Set();
            for (let i = 0; i < value.length; i++) {
              const { code, number } = value[i];
              const combinedValue = `${code}${number}`;
              if (seen.has(combinedValue)) {
                return this.createError({
                  path: `${this.path}[${i}].number`,
                  message: "Mobil nömrə təkrarlanmamalıdır",
                });
              }
              seen.add(combinedValue);
            }
          }
          return true;
        }
      ),

    email: Yup.array().of(
      Yup.string()
        .email("Düzgün email daxil edin")
        .required("Email daxil edilməlidir")
        .test("check-mail", "", async function (value) {
          const response = await checkUniqueness("email", value, employeeId);
          if (!response?.data?.isUnique) {
            return this.createError({
              message: response?.data?.message,
            });
          }
          return true;
        })
        .test(
          "check-email",
          "Bu email artıq istifadə edilib və yaxud heç bir istifadəçiyə aid deyil",
          async function (value, { parent }) {
            const isAllEmailsSame = isEmailUnique(parent);
            return !isAllEmailsSame;
          }
        )
        .max(150, "Email maksimum 150 simvol olmalıdır")
    ),
  });
};
export const MyCabinetContactInfoValidation = (employeeId) => {
  return Yup.object().shape({
    actualAddress: Yup.object().shape({
      address: Yup.string()
        .min(actualAddress_Min, "Faktiki ünvan minimum 5 simvol olmalıdır")
        .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır")
        .required("Faktiki ünvanı daxil edin"),
      city: Yup.string()
        .when("district", {
          is: (val) => !val || val === "",
          then: Yup.string().required("Şəhər və ya rayon seçin"),
          otherwise: Yup.string(),
        })
        .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır"),
      district: Yup.string()
        .test("district", "Rayon daxil edin", function (value) {
          const city = this.resolve(Yup.ref("city"));
          if (
            (city === "Bakı" || city === "Sumqayıt") &&
            (!value || value === "")
          ) {
            return this.createError({
              path: "actualAddress.district",
              message: "Rayon daxil edin",
            });
          }
          return true;
        })
        .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır"),
      homeNumber: Yup.array().of(
        Yup.string()
          .max(homeNumberMax, "Ev nömrəsi maksimum 50 simvol olmalıdır")
          .test(
            "check-homeNumber",
            "Bu ev telefonu artıq istifadə edilib",
            async function (value) {
              const response = await handleUniqueness(
                "homeNumber",
                value,
                employeeId
              );
              return response;
            }
          )
      ),
    }),
    registeredAddress: Yup.object().when("sameAddress", {
      is: false,
      then: Yup.object().shape({
        address: Yup.string()
          .min(actualAddress_Min, "Faktiki ünvan minimum 5 simvol olmalıdır")
          .max(actualAddress_Max, "Faktiki ünvan maksimum 500 simvol olmalıdır")
          .required("Faktiki ünvanı daxil edin")
          .test(
            "addresses-match",
            "Eyni adres iki dəfə yazıla bilməz",
            function (value) {
              const { actualAddress, registeredAddress, sameAddress } =
                this.parent;
              const addressesMatch =
                actualAddress === registeredAddress &&
                actualAddress !== "" &&
                registeredAddress !== "" &&
                sameAddress === false;
              return addressesMatch;
            }
          ),
        city: Yup.string().when("district", {
          is: (val) => !val || val === "",
          then: Yup.string().required("Şəhər və ya rayon seçin"),
          otherwise: Yup.string().max(
            cityMax,
            "Şəhər adı maksimum 70 simvol olmalıdır"
          ),
        }),
        district: Yup.string()
          .test("district", "Rayon daxil edin", function (value) {
            const city = this.resolve(Yup.ref("city"));
            if (
              (city === "Bakı" || city === "Sumqayıt") &&
              (!value || value === "")
            ) {
              return this.createError({
                path: "registeredAddress.district",
                message: "Rayon daxil edin",
              });
            }
            return true;
          })
          .max(
            actualAddress_Max,
            "Faktiki ünvan maksimum 500 simvol olmalıdır"
          ),
      }),
    }),
    mobile: Yup.array()
      .of(
        Yup.object().shape({
          code: Yup.string().required("Operator daxil edilməlidir"),
          number: Yup.string()
            .min(phoneNumber, "Mobil nömrə minimum 7 simvol olmalıdır")
            .max(phoneNumber, "Mobil nömrə maksimum 7 simvol olmalıdır")
            .test("number", "Nömrə daxil edilməlidir", (value) => value !== "")
            .required("Nömrə daxil edilməlidir")
            .test(
              "check-mobile",
              "Bu nömrə artıq istifadə edilib",
              async function (value) {
                const { code, number } = this.parent;
                const combinedValue = code + number;
                const response = await handleUniqueness(
                  "mobile",
                  combinedValue,
                  employeeId
                );
                return response;
              }
            ),
        })
      )
      .required("Mobil nömrəni daxil edin"),
    email: Yup.array()
      .of(
        Yup.object({
          data: Yup.string()
            .email("Düzgün email daxil edin")
            .required("Email daxil edilməlidir")
            .test(
              "check-email",
              "Bu email artıq istifadə edilib və yaxud heç bir istifadəçiyə aid deyil",
              async function (value, { parent }) {
                let isUnique = false;

                for (let i = 0; i < parent.length; i++) {
                  const element = parent[i];
                  const response = await handleUniqueness(
                    "email",
                    element,
                    employeeId
                  );
                  if (response === true) {
                    isUnique = true;
                    break;
                  }
                }
                return isUnique;
              }
            )
            .max(150, "Email maksimum 150 simvol olmalıdır"),
        })
      )
      .nullable(),
  });
};

export const EducationValidation = (employeeId) => {
  return Yup.object().shape({
    education: Yup.array().of(
      Yup.object().shape({
        edType: Yup.string().required("Təhsil növünü seçin"),
        institution: Yup.string().required("Təhsil müəssisəsini seçin"),
        faculty: Yup.string().when("edType", {
          is: (val) => hasFaculties(val),
          then: Yup.string().required("Təhsil sahəsini seçin"),
          otherwise: Yup.string().notRequired(),
        }),
        startDate: Yup.date()
          .transform((value, originalValue) => {
            const parsedDate = new Date(originalValue);
            return isValid(parsedDate) ? parsedDate : null;
          })
          .min(
            Yup.ref("startDate"),
            "Təhsilin başlama tarixi təhsilin bitiş tarixidən əvvəl olmalıdır"
          )
          .max(
            Yup.ref("endDate"),
            "Təhsilin başlama tarixi təhsilin bitiş tarixidən əvvəl olmalıdır"
          )
          .max(new Date(), "Təhsilin başlama tarixi gələcək ola bilməz.")
          .nullable(),
        endDate: Yup.date()
          .transform((value, originalValue) => {
            const parsedDate = new Date(originalValue);
            return isValid(parsedDate) ? parsedDate : null;
          })
          .when(["stillStudying"], (stillStudying, schema) => {
            return stillStudying
              ? schema
              : schema
                  .min(
                    Yup.ref("startDate"),
                    "Təhsilin başlama tarixi bitmə tarixindən sonra ola bilməz."
                  )
                  .nullable();
          }),
      })
    ),
  });
};

export const academicDegreeValidation = (employeeId) => {
  return Yup.object().shape({
    academicDegrees: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Elmi dərəcə adı zəruridir"),
        issuer: Yup.string().required("Verən müəssisə zəruridir"),
        date: Yup.date()
          .required("Tarix zəruridir")
          .min(new Date(1940, 0, 1), "Tarix 1940 tarixindən sonra olmalıdır")
          .max(new Date(), "Tarix cari tarixdən böyük ola bilməz"),
      })
    ),
  });
};

export const socialStatusValidation = (employeeId) => {
  return Yup.object().shape({
    socialStatus: Yup.array().of(
      Yup.object().shape({
        statusType: Yup.string().required("Sosial Status növü zəruridir"),
        date: Yup.date()
          .required("Tarix zəruridir")
          .min(new Date(1940, 0, 1), "Tarix 1940 tarixindən sonra olmalıdır")
          .max(new Date(), "Tarix cari tarixdən böyük ola bilməz"),
      })
    ),
  });
};

export const supplementalWagesValidation = (employeeId) => {
  return Yup.object().shape({
    supplementalWages: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Əməkhaqqına əlavənin səbəbi zəruridir"),
        value: Yup.number()
          .typeError("Əməkhaqqına əlavəsi rəqəm olmalıdır")
          .min(0, "Əməkhaqqına əlavəsi 0-dan kiçik ola bilməz"),
      })
    ),
  });
};
export const deductionsWagesValidation = (employeeId) => {
  return Yup.object().shape({
    deductionWages: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Əməkhaqqına tutulma səbəbi zəruridir"),
        value: Yup.number()
          .typeError("Əməkhaqqına tutulma rəqəm olmalıdır")
          .min(0, "Əməkhaqqına tutulma 0-dan kiçik ola bilməz"),
      })
    ),
  });
};
export const deductionWagesValidation = (employeeId) => {
  return Yup.object().shape({
    supplementalWages: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Əməkhaqqına tutulmanın səbəbi zəruridir"),
      })
    ),
  });
};

export const reprimandRewardValidation = (employeeId) => {
  return Yup.object().shape({
    reprimandsAndRewards: Yup.array().of(
      Yup.lazy((value, { parent }) => {
        return Yup.object().shape({
          rType: Yup.string().required(
            "Mükafat / Təltif / Tənbeh növü zəruridir"
          ),
          description: Yup.string()
            .required("Mükafat / Təltif / Tənbeh məzmunu zəruridir")
            .max(
              RewardsReprimandsLeng,
              "Mükafat / Təltif / Tənbeh məzmunu maksimum 500 hərf olmalıdır"
            ),
          // .test(
          //   "unique-description",
          //   "Bu məzmunu artıq istifadə edilib və ya heç bir istifadəçiyə aid deyil",
          //   function (value) {
          //     const { from } = this.options;

          //     // Extract the reprimandsAndRewards array
          //     const reprimandsAndRewards = from[1].value.reprimandsAndRewards;

          //     // Initialize a flag to check if any rType matches
          //     let hasMatchingRType = false;

          //     // Loop through each item in the array to compare rType values
          //     for (let i = 0; i < reprimandsAndRewards.length; i++) {
          //       for (let j = i + 1; j < reprimandsAndRewards.length; j++) {
          //         console.log(
          //           reprimandsAndRewards[i].rType,
          //           reprimandsAndRewards[j].rType
          //         );

          //         if (
          //           reprimandsAndRewards[i].rType ===
          //           reprimandsAndRewards[j].rType
          //         ) {
          //           hasMatchingRType = true; // Set the flag if a match is found
          //         }
          //       }

          //       if (hasMatchingRType) {
          //         break; // Exit the outer loop early if a match is found
          //       }
          //     }

          //     // Proceed with the description check if rType matches are found
          //     if (reprimandsAndRewards.length > 1 && hasMatchingRType) {
          //       const descriptions = reprimandsAndRewards.map(
          //         (item) => item.description
          //       );
          //       return (
          //         descriptions.filter((desc) => desc === value).length <= 1
          //       );
          //     } else {
          //       return true;
          //     }
          //   }
          // ),
          date: Yup.date()
            .required("Tarix zəruridir")
            .min(new Date(1940, 0, 1), "Tarix 1940 tarixindən sonra olmalıdır")
            .max(new Date(), "Tarix cari tarixdən böyük ola bilməz"),
          organization: Yup.string().required(
            "Mükafat / Təltif / Tənbehin təqdim olunduğu müəssisə zəruridir"
          ),
        });
      })
    ),
  });
};

export const inventoriesValidation = (employeeId) => {
  return Yup.object().shape({
    inventories: Yup.array().of(
      Yup.object().shape({
        itemType: Yup.string().required("İnventar növü zəruridir"),
        name: Yup.string().required("İnventarın modeli zəruridir"),
        number: Yup.string()
          .required("İnventar nömrəsi zəruridir")
          .matches(
            letterNumberRegex,
            "İnventar yalnız hərf və rəqəmlərdən ibarət olmalıdır"
          )
          .max(inventarLeng, "İnventar nömrəsi maksimum 150 rəqəm ola bilər")
          .test(
            "check-number",
            "Bu inventar nömrəsi artıq istifadə edilib",
            async function (value) {
              const response = await handleUniqueness(
                "inventories.number",
                value,
                employeeId
              );
              return response;
            }
          ),
        serialNumber: Yup.string().test(
          "check-number",
          "Bu inventar seriyası artıq istifadə edilib",
          async function (value) {
            const response = await handleUniqueness(
              "inventories.serialNumber",
              value,
              employeeId
            );
            return response;
          }
        ),

        date: Yup.date()
          .required("Təhkim tarixi zəruridir")
          .min(
            new Date(1940, 0, 1),
            "Təhkim tarixi 1940 tarixindən sonra olmalıdır"
          ),
        // .max(new Date(), "Tarix cari tarixdən böyük ola bilməz"),
      })
    ),
  });
};

export const documentsValidation = (employeeId) => {
  return Yup.object().shape({
    documents: Yup.array().of(
      Yup.object().shape({
        docType: Yup.string().required("Sənədin növü zəruridir"),
        filename: Yup.string(),
        fileBase64: Yup.string().when("filename", {
          is: (filename) =>
            !filename ||
            (typeof filename === "string" && filename.trim() === ""),
          then: Yup.string().required("Şəkil əlavə edin"),
        }),
        issuanceDate: Yup.date()
          .nullable()
          .required("Verilmə tarixi zəruridir")
          .min(
            new Date(1940, 0, 1),
            "Verilmə tarixi 1940 tarixindən sonra olmalıdır"
          )
          .max(new Date(), "Verilmə tarixi cari tarixdən böyük ola bilməz"),
        expirationDate: Yup.date()
          .nullable()
          .min(new Date(), "Verilmə tarixi cari tarixdən kiçik ola bilməz"),
      })
    ),
  });
};

export const structureDetailsValidation = (employeeId) => {
  return Yup.object().shape({
    VOEN: Yup.string()
      .required("VOEN daxil edilməlidir")
      .min(10, "VOEN minimum 10 simvoldan ibarət olmalıdır")
      .max(10, "VOEN maksimum 10 simvoldan ibarət olmalıdır")
      .matches(/^\d+$/, "VÖEN yalnız rəqəmlərdən ibarət olmalıdır")
      .test(
        "check-insuranceAccountNumber-vone",
        "VÖEN və Sığortaedənin Uçot Nömrəsi eyni ola bilməz",
        function (value) {
          const insuranceAccountNumber = this.parent.insuranceAccountNumber;
          const iban = value;
          return insuranceAccountNumber !== iban;
        }
      )
      .test(
        "check-VONE",
        "Bu VÖEN artıq istifadə edilib",
        async function (value) {
          // const { code, number } = this.parent;
          // const combinedValue = code + number;

          const response = await handleUniqueness("VOEN", value, employeeId);
          return response;
        }
      ),
    oilIndustry: Yup.string().required("Sektor zəruridir"),
    insuranceAccountNumber: Yup.string()
      .required("Sığortaedənin Uçot Nömrəsi zəruridir")
      .min(
        11,
        "Sığortaedənin Uçot Nömrəsi minimum 11 simvoldan ibarət olmalıdır"
      )
      .max(
        11,
        "Sığortaedənin Uçot Nömrəsi maksimum 11 simvoldan ibarət olmalıdır"
      )
      .matches(
        /^\d+$/,
        "Sığortaedənin Uçot Nömrəsi yalnız rəqəmlərdən ibarət olmalıdır"
      ),
    bankDetails: Yup.array().of(
      Yup.object().shape({
        bankName: Yup.string().required("Bank adı zəruridir"),
        bankCode: Yup.number().required("Bank kodu zəruridir"),
        bankVoen: Yup.string().required("Bank VÖEN zəruridir"),
        accountNumber: Yup.string()
          .trim()
          .matches(
            letterNumberRegex,
            "Hesab nömrəsi hərf və rəqəmlərdən ibarət olmalıdır"
          )
          .min(IBAN_Leng, "Hesab nömrəsi minimum 28 simvol olmalıdır")
          .max(IBAN_Leng, "Hesab nömrəsi maksimum 28 simvol olmalıdır")
          .required("Hesab nömrəsi zəruridir")
          .test(
            "unique-accountNumber",
            "Hesab nömrəsi eyni ola bilməz",
            function (value, context) {
              const { bankDetails } = context?.from[1]?.value;
              const accountNumbers = bankDetails.map(
                (detail) => detail.accountNumber
              );
              return (
                accountNumbers.filter((number) => number === value).length <= 1
              );
            }
          ),
        swift: Yup.string()
          .trim()
          .matches(
            letterNumberRegex,
            "Swift hərf və rəqəmlərdən ibarət olmalıdır"
          )
          .required("Swift zəruridir")
          .min(Swift_Leng_Min, "Swift kodu minimum 2 hərf olmalıdır.")
          .max(
            Swift_Leng_Max,
            "Swift kodu maksimum 250 hərfdən ibarət ola bilər."
          )
          .test(
            "unique-Swift",
            "Swift kodu eyni ola bilməz",
            function (value, context) {
              const { bankDetails } = context?.from[1]?.value;
              const swift = bankDetails.map((detail) => detail.swift);
              return swift.filter((number) => number === value).length <= 1;
            }
          ),
        iban: Yup.string()
          .trim()
          .matches(
            letterNumberRegex,
            "IBAN yalnız hərf və rəqəmlərdən ibarət olmalıdır"
          )
          .min(IBAN_Leng, "IBAN 28 minimum simvol olmalıdır")
          .max(IBAN_Leng, "IBAN 28 maksimum simvol olmalıdır")
          .required("IBAN zəruridir")
          .test(
            "check-accountNumber-iban",
            "Müxbir hesab nömrəsi və IBAN eyni ola bilməz",
            function (value) {
              const accountNumber = this.parent.accountNumber;
              const iban = value;
              return accountNumber !== iban;
            }
          )
          .test(
            "unique-IBAN",
            "IBAN kodu eyni ola bilməz",
            function (value, context) {
              const { bankDetails } = context?.from[1]?.value;
              const iban = bankDetails.map((detail) => detail.iban);
              return iban.filter((number) => number === value).length <= 1;
            }
          ),
      })
    ),
  });
};

export const transportVehiclesValidation = () => {
  return Yup.object().shape({
    transportVehicles: Yup.array().of(
      Yup.object().shape({
        brand: Yup.string().required("Nəqliyyat markası zəruridir"),
        transportationType: Yup.string().required("Nəqliyyat növü zəruridir"),
        name: Yup.string().required("Model zəruridir"),
        registrationNumber: Yup.string()
          .required("N.V. Qeydiyyat Şəhadətnaməsi No. zəruridir")
          .max(
            registrationNumber,
            "N.V. Qeydiyyat Şəhadətnaməsi No. maksimum 25 simvol olmalıdır."
          ),
        registrationPlate: Yup.string()
          .required("N.V. Qeydiyyat Nişanı zəruridir")
          .max(
            registrationPlate,
            "N.V. Qeydiyyat Nişanı maksimum 15 simvol olmalıdır."
          ),
        year: Yup.string()
          .required("Buraxılış ili zəruridir")
          .max(4, "Zəhmət olmasa buraxılış ilini düzgün daxil edin")
          .min(4, "Zəhmət olmasa buraxılış ilini düzgün daxil edin")
          .matches(numberRegex, "Buraxılış ili yalnız rəqəmlər olmalıdır"),
        engineCapacity: Yup.string()
          .matches(
            numberRegex,
            "Mühərrik Həcmi (sm3) yalnız rəqəmlər olmalıdır"
          )
          .required("Mühərrik Həcmi (sm3) zəruridir"),
        transmissionType: Yup.string().required(
          "Nəqliyyat növündə sürət qutusu zəruridir"
        ),
      })
    ),
  });
};

export const militaryValidation = () => {
  return Yup.object().shape({
    militaryService: Yup.object().shape({
      hasMilitaryService: Yup.boolean().required(
        "Hərbi mükəlləfiyyət statusu mütləqdir"
      ),
      issuerName: Yup.string().when("hasMilitaryService", {
        is: true,
        then: Yup.string().required("Sənədi verən orqan mütləqdir"),
      }),
      troopType: Yup.string().when("hasMilitaryService", {
        is: true,
        then: Yup.string().required("Qoşun növü mütləqdir"),
      }),
      militaryRank: Yup.string().when("hasMilitaryService", {
        is: true,
        then: Yup.string().required("Rütbə mütləqdir"),
      }),
      serviceStartDate: Yup.date().when("hasMilitaryService", {
        is: true,
        then: Yup.date().required("Xidmətə başlama tarixi mütləqdir"),
      }),
      serviceEndDate: Yup.date().when("hasMilitaryService", {
        is: true,
        then: Yup.date()
          .required("Xidmətin bitmə tarixi mütləqdir")
          .min(
            Yup.ref("serviceStartDate"),
            "Xidmətin bitmə tarixi xidmətə başlama tarixindən sonra olmalıdır"
          )
          .max(new Date(), "Xidmətin bitmə tarixi gələcək ola bilməz"),
      }),
      reason: Yup.string().when("hasMilitaryService", {
        is: false,
        then: Yup.string().required("Səbəb mütləqdir"),
      }),
      articleNumber: Yup.array().when(["hasMilitaryService", "reason"], {
        is: (hasMilitaryService, reason) =>
          hasMilitaryService === false &&
          reason === "Sağlamlıq vəziyyətinə görə möhlət",
        then: Yup.array().required("Maddə mütləqdir").min(1, "Maddə mütləqdir"),
      }),
      note: Yup.string().max(500, "Qeyd maksimum 500 simvol olmalıdır"),
    }),
  });
};

export const driverEditLicenseValidation = () => {
  const today = endOfDay(new Date());

  return Yup.object().shape({
    status: Yup.string().required("Status is required"),
    driverLicense: Yup.object().when("status", {
      is: "Var",
      then: Yup.object().shape({
        licenseIssuer: Yup.string().required(
          "Sənədi verən təşkilat tələb olunur"
        ),
        licenseIssuanceDate: Yup.date()
          .required("Sənəd vermə tarixi tələb olunur")
          .typeError("Bu tarix səhvdir")
          .min(
            Yup.ref("licenseIssuanceDate"),
            "Sənəd vermə tarixi Sənədin bitmə tarixidən əvvəl olmalıdır"
          )
          .max(
            Yup.ref("licenseExpirationDate"),
            "Sənəd vermə tarixi Sənədin bitmə tarixidən əvvəl olmalıdır"
          )
          .max(new Date(), "Sənəd vermə tarixi gələcək tarix ola bilməz."),
        licenseExpirationDate: Yup.date()
          .required("Sənədin bitmə tarixi tələb olunur")
          .typeError("Bu tarix səhvdir")
          .min(
            Yup.ref("licenseIssuanceDate"),
            "Sənədin bitmə tarixi sənədin verilmə tarixidən sonra olmalıdır"
          )
          .test(
            "expiration-date",
            "Sənədin bitmə tarixi keçmiş ola bilməz",
            function (value) {
              return !value || value > today;
            }
          ),
        licenseNumber: Yup.string()
          .required("Sənəd nömrəsi tələb olunur")
          .matches(
            letterNumberRegex,
            "Sənəd nömrəsi yalnız hərf və rəqəmlərdən ibarət olmalıdır"
          ),
        licenseCategory: Yup.array()
          .of(Yup.string())
          .min(1, "Ən azı bir kateqoriya seçilməlidir")
          .required("Ən azı bir kateqoriya seçilməlidir"),
      }),
      otherwise: Yup.object().shape({
        licenseIssuer: Yup.string().nullable(),
        licenseIssuanceDate: Yup.date().nullable(),
        licenseExpirationDate: Yup.date().nullable(),
        licenseNumber: Yup.string().nullable(),
        licenseCategory: Yup.array().nullable(),
      }),
    }),
  });
};
export const driverLicenseValidation = () => {
  const today = endOfDay(new Date());

  return Yup.object().shape({
    driverLicense: Yup.object().shape({
      licenseIssuer: Yup.string().required(
        "Sənədi verən təşkilat tələb olunur"
      ),
      licenseIssuanceDate: Yup.date()
        .required("Sənəd vermə tarixi tələb olunur")
        .typeError("Bu tarix səhvdir")
        .min(
          Yup.ref("licenseIssuanceDate"),
          "Sənəd vermə tarixi Sənədin bitmə tarixidən əvvəl olmalıdır"
        )
        .max(
          Yup.ref("licenseExpirationDate"),
          "Sənəd vermə tarixi Sənədin bitmə tarixidən əvvəl olmalıdır"
        )
        .max(new Date(), "Sənəd vermə tarixi gələcək tarix ola bilməz."),
      licenseExpirationDate: Yup.date()
        .required("Sənədin bitmə tarixi tələb olunur")
        .typeError("Bu tarix səhvdir")
        .min(
          Yup.ref("licenseIssuanceDate"),
          "Sənədin bitmə tarixi sənədin verilmə tarixidən sonra olmalıdır"
        )
        .test(
          "expiration-date",
          "Sənədin bitmə tarixi keçmiş ola bilməz",
          function (value) {
            return !value || value > today;
          }
        ),
      licenseNumber: Yup.string()
        .required("Sənəd nömrəsi tələb olunur")
        .matches(
          /^[A-Za-z0-9\s]+$/,
          "Sənəd nömrəsi yalnız hərf və rəqəmlərdən ibarət olmalıdır"
        )
        .max(50, "Sənəd nömrəsi maksimum 50 simvol olmalıdır"),
      licenseCategory: Yup.array()
        .of(Yup.string())
        .min(1, "Ən azı bir kateqoriya seçilməlidir")
        .required("Ən azı bir kateqoriya seçilməlidir"),
    }),
  });
};

export const certificationsValidation = () => {
  return Yup.object({
    certifications: Yup.array().of(
      Yup.object({
        qualification: Yup.string().required("İxtisas zəruridir"),
        number: Yup.string().required("Sertifikat nömrəsi zəruridir"),
        issueDate: Yup.date()
          .required("Sertifikatın verilmə tarixi zəruridir")
          .max(
            new Date(),
            "Sertifikatın verilmə tarixi gələcək tarix ola bilməz."
          ),
      })
    ),
  });
};

export const addWorkValidation = () => {
  return Yup.object({
    name: Yup.string().required("Ad daxil edilməlidir"),
    type: Yup.string().required("Tipi daxil edilməlidir"),
    value: Yup.string().required("Dəyəri daxil edilməlidir"),
    vacation: Yup.string().required("Məzuniyyət daxil edilməlidir"),
    reason: Yup.string().required("Səbəbi daxil edilməlidir"),
  });
};
