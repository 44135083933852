import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import SelectInput from "../../../../../components/ui/Select/SelectInput";
import {
  getStructureForLabourContractChanges_SERVICE,
  getStructureEmployeesForLabourContractByID_SERVICE,
} from "../../../../../services/documentServices";
import { DatePicker } from "@mui/x-date-pickers";
import { Field } from "formik";

export default function StructureSelecting({
  values,
  onSelect,
  setEmployee,
  contIdHandler,
  setInventoryTypes,
  setSelectedEmpData,
}) {
  const [structures, setStructures] = useState([]);
  const [selectedStructure, setSelectedStructure] = useState({
    index: -1,
    value: "",
  });
  const [staffs, setStaffs] = useState([]);
  const [selectedStaffs, setSelectedStaffs] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [allChecked, setAllChecked] = useState(false);

  useState(() => {
    onSelect(staffs);
  }, [staffs]);

  useEffect(() => {
    fetchAllStructures();
  }, []);

  useEffect(() => {
    selectedStructure.value && fetchStaffs();
  }, [structures, selectedStructure]);

  useEffect(() => {
    if (selectedStructure.index === -1) return;
    selectedStructure.index === 0
      ? fetchNextStructure(selectedStructure.value, undefined)
      : fetchNextStructure(undefined, selectedStructure.value);
  }, [selectedStructure]);

  const fetchStaffs = async () => {
    const resp =
      selectedStructure.index === 0
        ? await getStructureEmployeesForLabourContractByID_SERVICE(
            selectedStructure.value
          )
        : await getStructureEmployeesForLabourContractByID_SERVICE(
            undefined,
            selectedStructure.value
          );
    contIdHandler(resp?.data[0]?.staffs[0]?.contract?._id);
    setStaffs(resp.data);
  };

  const fetchAllStructures = async () => {
    try {
      const { data } = await getStructureForLabourContractChanges_SERVICE();
      if (data) {
        const _data = data.map((item) => ({
          name: item.organization[0].name,
          key: item._id,
        }));
        setStructures([_data]);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const fetchNextStructure = async (
    structureId = undefined,
    parentId = undefined
  ) => {
    try {
      const { data } = await getStructureForLabourContractChanges_SERVICE(
        structureId,
        parentId
      );
      if (data && data.length > 0) {
        const _data = data.map((item) => ({
          name: item.source.name,
          key: item._id,
        }));
        setStructures((prev) => [...prev, _data]);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const handlerOnSelect = () => {
    const arr = Object.values(selectedStaffs).filter((item) => item);

    if (!arr.length > 0) {
      swal({
        icon: "error",
        title: "Xəta",
        closeOnEsc: true,
        closeOnClickOutside: true,
        text: "Əmək müqaviləsi seçilməmişdir",
      });
      return;
    }

    onSelect(arr);
    setDisabled(true);
  };

  const handlerUncheckStaff = (e, empId, emp) => {
    const checked = e.target.checked;
    const state = { ...selectedStaffs, [empId]: checked ? empId : undefined };

    setSelectedStaffs(state);
    setEmployee(Object.values(state).filter(Boolean));

    if (checked) {
      setInventoryTypes(emp?.employee?.inventories || []);
      setSelectedEmpData(emp);
    }
    setDisabled(false);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginLeft: "5px",
      }}
    >
      <div>
        <Typography mb={1} variant="h5" align="center">
          Struktur təyin edin
        </Typography>
        {structures?.map((datas, index) => (
          <SelectInput
            data={datas}
            itemName={"name"}
            itemValue={"key"}
            onChange={(value) => {
              setSelectedStructure({
                index: index,
                value: value,
              });
              setStructures((prev) => prev.slice(0, index + 1));
              setSelectedStaffs({});
            }}
          />
        ))}
      </div>
      <Grid sx={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}>
        <Field name={`labourContractChangeDate`}>
          {({ field, form }) => (
            <DatePicker
              {...field}
              label="Əmək müqaviləsində dəyişiklik tarixi"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              value={field.value ? new Date(field.value) : null}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              onChange={(date) => {
                if (date instanceof Date && !isNaN(date)) {
                  form.setFieldValue(`labourContractChangeDate`, date);
                }
              }}
              maxDate={new Date()}
              minDate={new Date(1940, 0, 1)}
            />
          )}
        </Field>
      </Grid>
      <div
        style={{
          height: "300px",
          width: "100%",
          padding: "5px",
          border: "1px solid rgba(255,255,255,0.05)",
          borderRadius: "5px",
          position: "relative",
        }}
      >
        <Typography mb={1} variant="h5" align="center">
          Mövcud strukturdakı əmək müqavilələri
        </Typography>
        <div style={{ height: "100%" }}>
          <div
            style={{
              overflow: "scroll",
              height: "80%",
            }}
          >
            {!staffs.length > 0 && (
              <p
                style={{
                  width: "100%",
                  height: "80%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Mövcud strukturda əmək müqaviləsi yoxdur
              </p>
            )}
            {staffs?.map((emp) =>
              emp?.staffs
                .filter(
                  (item, index, self) =>
                    index === self.findIndex((t) => t._id === item._id)
                )
                .map((item) => (
                  <div
                    key={item._id}
                    style={{
                      padding: "3px",
                      borderBottom: "1px solid rgba(255,255,255, 0.01)",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        {item.contract.data.docNumber}
                      </span>{" "}
                      - {item.employee[0].firstname} {item.employee[0].lastname}{" "}
                      {item.employee[0].fathername}
                    </p>
                    <input
                      type="checkbox"
                      value={item._id}
                      checked={Boolean(selectedStaffs[item._id])}
                      onChange={(e) => handlerUncheckStaff(e, item._id, emp)}
                    />
                  </div>
                ))
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              height: "20%",
            }}
          >
            <Button
              sx={{ height: "35px" }}
              onClick={handlerOnSelect}
              variant="contained"
              disabled={disabled || !values?.labourContractChangeDate}
            >
              <Typography>Əmək müqavilələrini seç</Typography>
            </Button>
            <Button
              sx={{ height: "35px" }}
              onClick={() => {
                const obj = {};
                if (!allChecked) {
                  const uniqueIds = new Set();

                  staffs.forEach((staff) =>
                    staff.staffs.forEach((item, index) => {
                      if (!uniqueIds.has(item._id)) {
                        uniqueIds.add(item._id);
                        obj[item._id] = item._id;
                      }
                    })
                  );
                  setSelectedStaffs(obj);
                  setEmployee(Object.values(obj).filter(Boolean));
                  setAllChecked(true);
                } else {
                  const uniqueIds = new Set();
                  staffs.forEach((staff) =>
                    staff.staffs.forEach((item, index) => {
                      if (!uniqueIds.has(item._id)) {
                        uniqueIds.add(item._id);
                        obj[item._id] = undefined;
                      }
                    })
                  );
                  setSelectedStaffs(obj);
                  setEmployee(Object.values(obj).filter(Boolean));
                  setAllChecked(false);
                }

                setDisabled(false);
              }}
              variant="contained"
            >
              <Typography>Hamısını seç</Typography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
