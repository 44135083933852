import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay, isAfter } from "date-fns";
import { Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getCommonData } from "../../api/api";
import MilitaryReasonConst from "../../constants/employee/MilitaryReasonConst";
import useStaticData from "../../hooks/useStaticData/useStaticData";

const ChildContainer = styled.div`
  width: 100%;
`;

const LabelContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const customMenuStyle = {
  height: "280px",
  width: "500px",
};

export default function MilitaryStep({
  submitted,
  setOpenAddDialog,
  setSelectedType,
}) {
  const [issuers, setIssuers] = useState();
  // const [reasonLines, setReasonLines] = useState([]);
  const [lists, setLists] = useState({
    listOfDiseases: [],
    troopTypes: [],
    ranks: [],
  });

  const [empStaticData] = useStaticData();

  const { values, setErrors, errors, setFieldValue } = useFormikContext();
  const endDate = values.militaryService.serviceEndDate;
  const startDate = values.militaryService.serviceStartDate;
  const today = endOfDay(new Date());
  useEffect(() => {
    setLists({
      listOfDiseases: empStaticData["listOfDiseases"]?.data,
      troopTypes: empStaticData["troopTypes"]?.data,
      ranks: empStaticData["militaryRanks"]?.data,
    });
  }, [empStaticData]);

  useEffect(() => {
    const fetchOptions = async () => {
      const issuersList = await getCommonData("identityCardissuerList");
      setIssuers(issuersList.data);
    };
    fetchOptions();
  }, [submitted]);
  const filteredRanks =
    values?.militaryService?.troopType !== "Azərbaycan Hərbi Dəniz Qüvvələri"
      ? lists?.ranks?.filter(
          (rank) => rank !== "Miçman" && rank !== "Baş Miçman"
        ) || []
      : lists?.ranks || [];

  const has = (
    <ChildContainer>
      <LabelContainer>
        <FormControl
          error={
            errors?.militaryService?.issuerName &&
            errors.militaryService.issuerName
          }
          fullWidth
        >
          <InputLabel id="militaryService.issuerName">
            Sənədi verən orqan *
          </InputLabel>

          <Field
            as={Select}
            name="militaryService.issuerName"
            labelId="militaryService.issuerName"
            label="Sənədi verən orqan"
            id="issuerName"
            defaultValue=""
            fullWidth
            error={
              errors.militaryService?.issuerName &&
              Boolean(errors.militaryService.issuerName)
            }
            required
            onChange={(event) => {
              setFieldValue("militaryService.issuerName", event.target.value);
              if (errors?.militaryService?.issuerName) {
                setErrors({
                  ...errors,
                  militaryService: {
                    ...errors?.militaryService,
                    issuerName: "",
                  },
                });
              }
            }}
          >
            <MenuItem hidden value=""></MenuItem>
            {issuers &&
              [...new Set(issuers)]
                ?.sort((a, b) => a.localeCompare(b, "az"))
                .map((item, index) => (
                  <MenuItem key={`${item} ${index}`} value={item}>
                    {item}
                  </MenuItem>
                ))}
            <Button
              sx={{
                mt: 3,
                ml: 3,
              }}
              variant="outlined"
              onClick={() => {
                setOpenAddDialog(true);
                setSelectedType([
                  "identityCardissuerList",
                  { name: "Sənədi verən orqanlar" },
                ]);
              }}
            >
              Yeni sənəd verən orqan əlavə et
            </Button>
          </Field>
          {errors.militaryService?.issuerName &&
            errors.militaryService?.issuerName && (
              <FormHelperText error>
                {errors.militaryService?.issuerName}
              </FormHelperText>
            )}
        </FormControl>
      </LabelContainer>
      <LabelContainer>
        <FormControl
          error={
            errors.militaryService?.troopType &&
            errors.militaryService.troopType
          }
          fullWidth
        >
          <InputLabel id="militaryService.troopType">Qoşun növü *</InputLabel>
          <Field
            as={Select}
            name="militaryService.troopType"
            labelId="militaryService.troopType"
            label="Qoşun növü *"
            id="troopType"
            fullWidth
            defaultValue=""
            error={
              errors.militaryService?.troopType &&
              Boolean(errors.militaryService.troopType)
            }
            required
            onChange={(event) => {
              setFieldValue("militaryService.troopType", event.target.value);
              if (errors?.militaryService?.troopType) {
                setErrors({
                  ...errors,
                  militaryService: {
                    ...errors?.militaryService,
                    troopType: "",
                  },
                });
              }
            }}
          >
            <MenuItem hidden value=""></MenuItem>
            {lists?.troopTypes &&
              [...lists?.troopTypes]
                .sort((a, b) => a.localeCompare(b, "az"))
                .map((item, index) => (
                  <MenuItem key={`${item}-${index}`} value={item}>
                    {item}
                  </MenuItem>
                ))}
          </Field>

          {errors.militaryService?.troopType &&
            errors.militaryService?.troopType && (
              <FormHelperText error>
                {errors.militaryService?.troopType}
              </FormHelperText>
            )}
        </FormControl>
      </LabelContainer>
      <LabelContainer>
        <FormControl
          error={
            errors.militaryService?.militaryRank &&
            errors.militaryService.militaryRank
          }
          fullWidth
        >
          <InputLabel id="militaryService.militaryRank">Rütbə *</InputLabel>

          <Field
            as={Select}
            name="militaryService.militaryRank"
            labelId="militaryService.militaryRank"
            label="Rütbə"
            id="militaryRank"
            fullWidth
            error={
              errors.militaryService?.militaryRank &&
              Boolean(errors.militaryService.militaryRank)
            }
            required
            onChange={(event) => {
              setFieldValue("militaryService.militaryRank", event.target.value);
              if (errors?.militaryService?.militaryRank) {
                setErrors({
                  ...errors,
                  militaryService: {
                    ...errors?.militaryService,
                    militaryRank: "",
                  },
                });
              }
            }}
          >
            {filteredRanks &&
              [...filteredRanks]
                .sort((a, b) => a.localeCompare(b, "az"))
                .map((item, index) => (
                  <MenuItem key={`${item}-${index}`} value={item}>
                    {item}
                  </MenuItem>
                ))}
          </Field>
          {errors.militaryService?.militaryRank &&
            errors.militaryService?.militaryRank && (
              <FormHelperText error>
                {errors.militaryService?.militaryRank}
              </FormHelperText>
            )}
        </FormControl>
      </LabelContainer>
      <LabelContainer>
        <Field name="militaryService.serviceStartDate">
          {({ field, form }) => (
            <DatePicker
              {...field}
              label="Xidmətə başlama tarixi *"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              value={field?.value ? new Date(field?.value) : null}
              slotProps={{
                textField: {
                  error: Boolean(form.errors.militaryService?.serviceStartDate),
                  helperText:
                    form.errors.militaryService?.serviceStartDate || "",
                  fullWidth: true,
                },
              }}
              onChange={(date) => {
                if (date && !isAfter(date, today)) {
                  form.setFieldValue("militaryService.serviceStartDate", date);
                  if (errors?.militaryService?.serviceStartDate) {
                    setErrors({
                      ...errors,
                      militaryService: {
                        ...errors?.militaryService,
                        serviceStartDate: "",
                      },
                    });
                  }
                } else {
                  form.setFieldValue("militaryService.serviceStartDate", "");
                }
              }}
              minDate={new Date(1940, 0, 1)}
              maxDate={endDate ? endDate : today}
              required
            />
          )}
        </Field>
      </LabelContainer>
      <LabelContainer>
        <Field name="militaryService.serviceEndDate">
          {({ field, form }) => (
            <DatePicker
              {...field}
              label="Xidmətin bitmə tarixi *"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              value={field?.value ? new Date(field?.value) : null}
              slotProps={{
                textField: {
                  error: Boolean(form.errors.militaryService?.serviceEndDate),
                  helperText: form.errors.militaryService?.serviceEndDate || "",
                  fullWidth: true,
                },
              }}
              onChange={(date) => {
                if (date && !isAfter(date, today)) {
                  form.setFieldValue("militaryService.serviceEndDate", date);
                  if (errors?.militaryService?.serviceEndDate) {
                    setErrors({
                      ...errors,
                      militaryService: {
                        ...errors?.militaryService,
                        serviceEndDate: "",
                      },
                    });
                  }
                } else {
                  form.setFieldValue("militaryService.serviceEndDate", "");
                }
              }}
              minDate={startDate ? new Date(startDate) : undefined}
              maxDate={today}
              required
            />
          )}
        </Field>
      </LabelContainer>
    </ChildContainer>
  );

  const hasnot = (
    <ChildContainer>
      {/* Səbəb */}
      <LabelContainer>
        <FormControl
          error={
            errors.militaryService?.reason && errors.militaryService.reason
          }
          fullWidth
        >
          <InputLabel id="militaryService.reason">Səbəb *</InputLabel>
          <Field
            as={Select}
            name="militaryService.reason"
            labelId="militaryService.reason"
            label="Səbəb *"
            id="reason"
            fullWidth
            error={
              errors.militaryService?.reason &&
              Boolean(errors.militaryService.reason)
            }
            required
            onChange={(event) => {
              setFieldValue("militaryService.reason", event.target.value);
              if (errors?.militaryService?.reason) {
                setErrors({
                  ...errors,
                  militaryService: {
                    ...errors?.militaryService,
                    reason: "",
                  },
                });
              }
            }}
          >
            <MenuItem hidden value={undefined}>
              Səbəb seçin...
            </MenuItem>
            {MilitaryReasonConst &&
              MilitaryReasonConst?.sort((a, b) => a.localeCompare(b, "az")).map(
                (item, index) => (
                  <MenuItem key={`${index} ${item}`} value={item}>
                    {item}
                  </MenuItem>
                )
              )}
          </Field>
          {errors.militaryService?.reason && errors.militaryService?.reason && (
            <FormHelperText error>
              {errors.militaryService?.reason}
            </FormHelperText>
          )}
        </FormControl>
      </LabelContainer>

      {/* maddə bənd */}
      {values.militaryService.reason === MilitaryReasonConst[2] && (
        <LabelContainer>
          <FormControl
            error={Boolean(errors?.militaryService?.articleNumber)}
            fullWidth
          >
            <Field name="militaryService.articleNumber">
              {({ field, form }) => {
                const options = lists.listOfDiseases
                  ? lists.listOfDiseases.flatMap((article) => [
                      {
                        label: `${article.article}. ${article.name}`,
                        value: article.article,
                        disabled: article.paragraphs.length > 0,
                      },
                      ...article.paragraphs.map((item) => ({
                        label: `${item.paragraph}. ${item.detail}`,
                        value: item.key,
                      })),
                    ])
                  : [];

                return (
                  <Autocomplete
                    multiple
                    options={options}
                    value={(field.value || [])
                      .map(
                        (val) =>
                          options.find((option) => option.value === val) || null
                      )
                      .filter(Boolean)}
                    getOptionLabel={(option) => option?.label || ""}
                    onChange={(event, selectedOptions) => {
                      form.setFieldValue(
                        "militaryService.articleNumber",
                        selectedOptions.map((option) => option.value)
                      );

                      if (errors?.militaryService?.articleNumber) {
                        form.setErrors({
                          ...errors,
                          militaryService: {
                            ...errors?.militaryService,
                            articleNumber: "",
                          },
                        });
                      }
                    }}
                    disableCloseOnSelect
                    getOptionDisabled={(option) => option.disabled}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Maddə *"
                        variant="outlined"
                        error={Boolean(errors?.militaryService?.articleNumber)}
                        helperText={
                          errors?.militaryService?.articleNumber || ""
                        }
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li
                        {...props}
                        style={{
                          fontWeight: option.disabled ? "bold" : "normal",
                        }}
                      >
                        {option.label}
                      </li>
                    )}
                  />
                );
              }}
            </Field>
          </FormControl>
        </LabelContainer>
      )}

      {/* Qeyd */}
      <LabelContainer>
        <Field
          type="text"
          name="militaryService.note"
          as={TextField}
          fullWidth
          label="Qeyd"
          error={
            errors.militaryService?.note &&
            Boolean(errors.militaryService?.note)
          }
          helperText={
            errors.militaryService?.note && errors.militaryService?.note
          }
          onChange={(event) => {
            setFieldValue("militaryService.note", event.target.value);
            if (errors?.militaryService?.note) {
              setErrors({
                ...errors,
                militaryService: {
                  ...errors?.militaryService,
                  note: "",
                },
              });
            }
          }}
        />
      </LabelContainer>
    </ChildContainer>
  );
  return (
    <>
      <Typography variant="h6" mb={3} mt={3}>
        Hərbi mükəlləfiyyət
      </Typography>

      <LabelContainer>
        <FormControl
          error={
            errors.militaryService?.hasMilitaryService &&
            errors.militaryService.hasMilitaryService
          }
          fullWidth
        >
          <InputLabel id="militaryService.hasMilitaryService">
            Hərbi mükəlləfiyyət *
          </InputLabel>
          <Field
            as={Select}
            name="militaryService.hasMilitaryService"
            labelId="militaryService.hasMilitaryService"
            label="Hərbi mükəlləfiyyət"
            id="hasMilitaryService"
            fullWidth
            error={
              errors.militaryService?.hasMilitaryService &&
              Boolean(errors.militaryService.hasMilitaryService)
            }
            required
          >
            <MenuItem value={true}>Var</MenuItem>
            <MenuItem value={false}>Yoxdur</MenuItem>
          </Field>
          {errors.militaryService?.hasMilitaryService &&
            errors.militaryService?.hasMilitaryService && (
              <FormHelperText error>
                {errors.militaryService?.hasMilitaryService}
              </FormHelperText>
            )}
        </FormControl>
      </LabelContainer>

      {values.militaryService.hasMilitaryService === undefined ? null : (
        <Grid>
          {values.militaryService.hasMilitaryService ? has : hasnot}
          <style>
            {`
            #menu-militaryService\\.articleNumber {
              ${Object.keys(customMenuStyle)
                .map((key) => `${key}: ${customMenuStyle[key]};`)
                .join("\n")}
            }
          `}
          </style>
        </Grid>
      )}
    </>
  );
}
