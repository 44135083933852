import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { putExplanations } from "../../../services/employeeApplication(employee)/EmployeeApplicationService";
import moment from "moment";
import { APP_EXP_DOCMODEL } from "../../../constants";
import { sendExplanation_ApplicationRequest_SERVICE } from "../../../services/cabinetServices";
import FindEmployee from "../../../components/employees/FindEmployee";
import { getActiveStaffss } from "../../../services/staff/StaffServices";

// CSS

export default function ExplanationData({
  row,
  viewDialogText,
  open,
  onClose,
  click,
  type,
  fetchData,
}) {
  const [inputValue, setInputValue] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  const [employees, setEmployees] = useState([]);
  const [totalEmployee, setTotalEmployee] = useState();
  const [text, setText] = useState("");
  const [employeesPayload, setEmployeesPayload] = useState({
    limit: 5,
    skip: 0,
    filter: {
      employeeFirstName: "",
      employeeLastName: "",
      employeeFatherName: "",
    },
  });
  console.log(row);
  async function sendExplanationData() {
    try {
      const { data } = await sendExplanation_ApplicationRequest_SERVICE(
        row?._id,
        text
      );

      toast.done("Məlumat göndərildi", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      fetchData();
      onClose();
    } catch (error) {
      toast.error("Xəta baş verdi", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  const fetchEmployees = async (payload) => {
    try {
      const { data } = await getActiveStaffss(payload);
      if (data) {
        setEmployees(data?.records);
        setTotalEmployee(data.total);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchFilteredEmployees = async () => {
      const { limit, skip, filter, structureId } = employeesPayload;

      const datas = Object.entries(filter)
        .filter(([key, value]) => value.trim() !== "")
        .map(([key, value]) => ({
          column: key,
          operator: "equalsTo",
          value: value,
        }));

      const payload = { limit, offset: skip, structureId };
      if (datas.length > 0) {
        const filterString = JSON.stringify(datas);
        const encodedFilter = encodeURIComponent(filterString);
        payload.filter = encodedFilter;
      }
      // if (structureId) {
      await fetchEmployees(payload);
      // }
    };

    fetchFilteredEmployees();
  }, [employeesPayload]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    const [firstName, lastName, fatherName] = value
      .split(" ")
      .map((part) => part.trim());

    const newPayload = {
      ...employeesPayload,
      filter: {
        employeeFirstName: firstName || "",
        employeeLastName: lastName || "",
        employeeFatherName: fatherName || "",
      },
    };
    setEmployeesPayload(newPayload);
  };

  const hasEmployees =
    employees?.length > 0 &&
    employees?.some((item) => item?.employee?.length > 0);

  return (
    <>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
        <ToastContainer />

        <DialogTitle>
          {type === "explanation" ? "İzahat" : "Ərizə"} (Xahiş olunur aşağıdakı
          xanaya {type === "explanation" ? "izahatınızı" : "ərizənizi"} qeyd
          edəsiniz.Müvafiq sənəd başlıqları sənədə avtomatik əlavə olunacaq.){" "}
        </DialogTitle>
        <DialogContent sx={{ width: "100%" }}>
          <div
            style={{
              margin: "5px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Grid>
              <Grid>Məzmun: {APP_EXP_DOCMODEL[viewDialogText?.content]}</Grid>
              <Grid>
                Yaradılma tarixi :{" "}
                {moment(viewDialogText?.date)?.format("MM/DD/YYYY")}
              </Grid>
              {type === "application" && (
                <Grid className="mt-4">
                  <Box my={3}>
                    <TextField
                      label="İstifadəçi axtar"
                      onChange={handleSearchChange}
                      variant="outlined"
                      fullWidth
                    />
                    <List
                      dense
                      fullWidth
                      sx={{
                        maxHeight: "40vh",
                        bgcolor: "background.paper",
                        overflow: "auto",
                        mb: 3,
                      }}
                    >
                      {hasEmployees ? (
                        employees.map((item, index) => {
                          if (item?.employee.length > 0) {
                            const employee = item.employee[0];
                            return (
                              <ListItem key={index} disablePadding>
                                <ListItemButton
                                  selected={selectedUser?._id === item._id}
                                  onClick={() => setSelectedUser(item)}
                                >
                                  {/* <ListItemAvatar>
                                  <Avatar
                                    alt={`Avatar n°${index + 1}`}
                                    src={`/static/img/avatars/user.png`}
                                  />
                                </ListItemAvatar> */}
                                  <ListItemText
                                    id={item._id}
                                    primary={`${employee.firstname} ${
                                      employee.lastname
                                    } ${employee.fathername} (${
                                      item.position?.name || "---"
                                    })`}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          } else {
                            return null;
                          }
                        })
                      ) : (
                        <Typography>Hal-hazırda əməkdaş yoxdur</Typography>
                      )}
                    </List>
                  </Box>
                </Grid>
              )}
              <Grid className="mt-4">
                <TextField
                  label={type === "explanation" ? "İzahat" : "Ərizə"}
                  multiline
                  rows={8}
                  fullWidth
                  onChange={(e) => setText(e.target.value)}
                />
              </Grid>
              <Grid className="text-right mt-4">
                {/* <Button variant="contained" color="warning">
                  Sənədə bax
                </Button> */}
                <Button
                  variant="contained"
                  className="ms-2"
                  disabled={!text}
                  onClick={sendExplanationData}
                >
                  Göndər
                </Button>
              </Grid>
            </Grid>
            {/* <button
              onClick={sendExplanationData}
              style={
                inputValue.length
                  ? {
                      pointerEvents: "auto",
                      border: "none",
                      backgroundColor: "#2a3889",
                      color: "#fff",
                      borderRadius: "5px",
                      padding: "5px",
                    }
                  : {
                      pointerEvents: "none",
                      border: "none",
                      backgroundColor: "#2a3889",
                      color: "#fff",
                      borderRadius: "5px",
                      padding: "5px",
                    }
              }
            >
              Göndər
            </button> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Bağla
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
