import React, { useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "../vacation-schedule/vacationSchedule.module.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Pie } from "react-chartjs-2";
import moment from "moment";
const months = [
  { name: "Yanvar", key: 0 },
  { name: "Fevral", key: 1 },
  { name: "Mart", key: 2 },
  { name: "Aprel", key: 3 },
  { name: "May", key: 4 },
  { name: "İyun", key: 5 },
  { name: "İyul", key: 6 },
  { name: "Avqust", key: 7 },
  { name: "Sentyabr", key: 8 },
  { name: "Oktyabr", key: 9 },
  { name: "Noyabr", key: 10 },
  { name: "Dekabr", key: 11 },
];
const rangeForVacation = [
  "0%",
  "20%",
  "40%",
  "50%",
  "60%",
  "80%",
  "90%",
  "100%",
];
const palette = ["#9BDFC4", "#62B2FD"];
const totalPeople = 150;
const dataValues = [(totalPeople * 30) / 100, (totalPeople * 70) / 100];
const data = {
  labels: dataValues.map((value) => `${value} nəfər`),
  datasets: [
    {
      data: dataValues,
      backgroundColor: palette,
    },
  ],
};
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return tooltipItem.label;
        },
      },
    },
  },
};
const chunkArray = (arr, size) => {
  return arr.reduce((acc, _, i) => {
    if (i % size === 0) acc.push(arr.slice(i, i + size));
    return acc;
  }, []);
};

const ColorPicker = () => {
  const [color, setColor] = useState("#F8A100");

  const gradient =
    "linear-gradient(90deg, #1B2635 0%, #F8A100 50%, #FF4646 100%)";

  const colorStops = [
    { stop: 0.01, color: "#1B2635" },
    { stop: 0.1, color: "#37362E" },
    { stop: 0.2, color: "#75581F" },
    { stop: 0.3, color: "#8E6619" },
    { stop: 0.4, color: "#B27A11" },
    { stop: 0.5, color: "#F8A100" },
    { stop: 0.6, color: "#F89806" },
    { stop: 0.7, color: "#FA8614" },
    { stop: 0.8, color: "#FB771F" },
    { stop: 0.9, color: "#FC662D" },
    { stop: 1, color: "#FF4646" },
  ];

  const handleChange = (event) => {
    const percentage = event.nativeEvent.offsetX / event.target.clientWidth;
    const selectedColor =
      colorStops.find(({ stop }) => percentage < stop)?.color || "#FF4646";
    setColor(selectedColor);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div
        onClick={handleChange}
        style={{
          width: "300px",
          height: "40px",
          background: gradient,
          cursor: "pointer",
          borderRadius: "5px",
        }}
      ></div>
      <p>
        Selected Color:{" "}
        <span style={{ color, fontWeight: "bold" }}>{color}</span>
      </p>
    </div>
  );
};

export const ActiveMonthTable = ({
  rowData,
  selectedYear,
  handleActiveFindMonth,
}) => {
  const chunkedMonths = chunkArray(months, 4);
  const startDate = moment(rowData?.startdate, "DD/MM/YYYY");
  const endDate = moment(rowData?.enddate, "DD/MM/YYYY");

  const startMonthIndex = startDate.month();
  const endMonthIndex = endDate.month();
  const startYear = startDate.year();
  const endYear = endDate.year();

  return (
    <Grid sx={{ display: "flex", gap: "20px" }}>
      <Table sx={{ marginTop: "16px" }}>
        <TableBody>
          {chunkedMonths.map((row, rowIndex) => (
            <TableRow key={rowIndex} sx={{ border: "1px solid #787878" }}>
              {row.map((month) => {
                const isInRange =
                  selectedYear >= startYear &&
                  selectedYear <= endYear &&
                  month?.key >= startMonthIndex &&
                  month?.key <= endMonthIndex;
                return (
                  <TableCell
                    key={month.key}
                    className={styles.cellStyle}
                    onClick={() =>
                      handleActiveFindMonth(month?.key, month?.name)
                    }
                    sx={{
                      backgroundColor: isInRange ? "#CA0005" : "transparent",
                      border: isInRange
                        ? "1px solid #fff"
                        : "1px solid #787878",
                      color: isInRange ? "white" : "inherit",
                    }}
                  >
                    <p className={styles.cellStyleText}>{month.name}</p>
                  </TableCell>
                );
              })}
              {Array(4 - row.length)
                .fill("")
                .map((_, i) => (
                  <TableCell
                    key={`empty-${rowIndex}-${i}`}
                    className={styles.cellStyle}
                  />
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid sx={{ display: "flex", gap: "20px" }}>
        <div
          style={{
            width: "28px",
            marginTop: "16px",
            background:
              "linear-gradient(180deg, #1B2635 0%, #F8A100 50%, #FF4646 68.68%)",
          }}
        ></div>
        <div
          style={{
            width: "34px",
            marginTop: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "5px 0",
          }}
        >
          {rangeForVacation.map((item, index) => (
            <p>{item}</p>
          ))}
        </div>
      </Grid>
      <Grid sx={{ display: "flex", marginTop: "16px" }}>
        <div className={styles.pie}>
          <div>
            <p className={styles.pieTitle}>
              Ümumi əməkdaşların məzuniyyət nisbəti
            </p>
            <div className={styles.pieSelect}>
              <div className={styles.pieSelectChild}>
                <span className={styles.pieSelectText}>2025</span>
              </div>
              <div className={styles.pieSelectChild}>
                <span className={styles.pieSelectText}>Fevral</span>
              </div>
            </div>
          </div>
          <hr />
          <div className={styles.pieFooter}>
            <div>
              <Stack
                direction="row"
                width="100%"
                textAlign="center"
                spacing={2}
                style={{ marginTop: "15px" }}
              >
                <Box flexGrow={1}>
                  <Box width={90} height={90} sx={{ marginLeft: "25px" }}>
                    <Pie data={data} options={options} />
                  </Box>
                </Box>
              </Stack>
            </div>
            <div className={styles.pieFooterWrapper}>
              <div className={styles.pieFooterWrapperInfo}>
                <span className={styles.pieFooterWrapperInfoText}>
                  <FiberManualRecordIcon fontSize="5px" color="primary" />
                  Ümumi
                </span>
                <span className={styles.pieFooterWrapperInfoText}>
                  150 nəfər
                </span>
              </div>
              <div className={styles.pieFooterWrapperInfo}>
                <span className={styles.pieFooterWrapperInfoText}>
                  <FiberManualRecordIcon
                    fontSize="5px"
                    sx={{ color: "#9BDFC4" }}
                  />
                  Məzuniyyətdə
                </span>
                <span className={styles.pieFooterWrapperInfoText}>30%</span>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
export const PlanningMonthTable = ({
  rowData,
  selectedYear,
  handleFindMonth,
}) => {
  const chunkedMonths = chunkArray(months, 4);
  const startDate = moment(rowData?.startdate, "DD/MM/YYYY");
  const endDate = moment(rowData?.enddate, "DD/MM/YYYY");

  const startMonthIndex = startDate.month();
  const endMonthIndex = endDate.month();
  const startYear = startDate.year();
  const endYear = endDate.year();

  return (
    <Grid sx={{ display: "flex", gap: "20px" }}>
      <Table sx={{ marginTop: "16px" }}>
        <TableBody>
          {chunkedMonths.map((row, rowIndex) => (
            <TableRow key={rowIndex} sx={{ border: "1px solid #787878" }}>
              {row.map((month, index) => {
                const isInRange =
                  selectedYear >= startYear &&
                  selectedYear <= endYear &&
                  month?.key >= startMonthIndex &&
                  month?.key <= endMonthIndex;
                return (
                  <TableCell
                    key={month.key}
                    className={styles.cellStyle}
                    onClick={() => handleFindMonth(month?.key, month?.name)}
                    sx={{
                      backgroundColor: isInRange ? "#CA0005" : "transparent",
                      border: isInRange
                        ? "1px solid #fff"
                        : "1px solid #787878",
                      color: isInRange ? "white" : "inherit",
                    }}
                  >
                    <p className={styles.cellStyleText}>{month.name}</p>
                  </TableCell>
                );
              })}
              {Array(4 - row.length)
                .fill("")
                .map((_, i) => (
                  <TableCell
                    key={`empty-${rowIndex}-${i}`}
                    className={styles.cellStyle}
                  />
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid sx={{ display: "flex", gap: "20px" }}>
        <div
          style={{
            width: "28px",
            marginTop: "16px",
            background:
              "linear-gradient(180deg, #1B2635 0%, #F8A100 50%, #FF4646 68.68%)",
          }}
        ></div>
        <div
          style={{
            width: "34px",
            marginTop: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "5px 0",
          }}
        >
          {rangeForVacation.map((item, index) => (
            <p>{item}</p>
          ))}
        </div>
      </Grid>
      <Grid sx={{ display: "flex", marginTop: "16px" }}>
        <div className={styles.pie}>
          <div>
            <p className={styles.pieTitle}>
              Ümumi əməkdaşların məzuniyyət nisbəti
            </p>
            <div className={styles.pieSelect}>
              <div className={styles.pieSelectChild}>
                <span className={styles.pieSelectText}>2025</span>
              </div>
              <div className={styles.pieSelectChild}>
                <span className={styles.pieSelectText}>Fevral</span>
              </div>
            </div>
          </div>
          <hr />
          <div className={styles.pieFooter}>
            <div>
              <Stack
                direction="row"
                width="100%"
                textAlign="center"
                spacing={2}
                style={{ marginTop: "15px" }}
              >
                <Box flexGrow={1}>
                  <Box width={90} height={90} sx={{ marginLeft: "25px" }}>
                    <Pie data={data} options={options} />
                  </Box>
                </Box>
              </Stack>
            </div>
            <div className={styles.pieFooterWrapper}>
              <div className={styles.pieFooterWrapperInfo}>
                <span className={styles.pieFooterWrapperInfoText}>
                  <FiberManualRecordIcon fontSize="5px" color="primary" />
                  Ümumi
                </span>
                <span className={styles.pieFooterWrapperInfoText}>
                  150 nəfər
                </span>
              </div>
              <div className={styles.pieFooterWrapperInfo}>
                <span className={styles.pieFooterWrapperInfoText}>
                  <FiberManualRecordIcon
                    fontSize="5px"
                    sx={{ color: "#9BDFC4" }}
                  />
                  Məzuniyyətdə
                </span>
                <span className={styles.pieFooterWrapperInfoText}>30%</span>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export const PlanningDaysCalendar = ({ year, month, rowData }) => {
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDay = new Date(year, month, 1).getDay();
  const adjustedFirstDay = firstDay === 0 ? 6 : firstDay - 1;

  const weekdays = [
    "Bazar ertəsi",
    "Çərşənbə axşamı",
    "Çərşənbə",
    "Cümə axşamı",
    "Cümə",
    "Şənbə",
    "Bazar",
  ];

  let days = Array(adjustedFirstDay).fill(null);
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  const rows = [];
  for (let i = 0; i < days.length; i += 7) {
    rows.push(days.slice(i, i + 7));
  }

  const startMoment = moment(rowData?.startdate, "DD/MM/YYYY");
  const endMoment = moment(rowData?.enddate, "DD/MM/YYYY");

  // Ensure the selected month matches
  const isSameMonth =
    month >= startMoment.month() && month <= endMoment.month();
  const isSameYear = year >= startMoment.year() && year <= endMoment.year();

  // Define start and end days for highlighting
  const startDay =
    isSameMonth && isSameYear
      ? startMoment.month() === month
        ? startMoment.date()
        : 1
      : null;
  const endDay =
    isSameMonth && isSameYear
      ? endMoment.month() === month
        ? endMoment.date()
        : daysInMonth
      : null;

  return (
    <Table sx={{ margin: "16px 0" }}>
      <TableHead>
        <TableRow>
          {weekdays.map((day, index) => (
            <TableCell
              sx={{
                textAlign: "left",
                fontSize: "10px",
                color: index < 5 ? "#B6C0D9" : "#ED3779",
              }}
              key={day}
              align="center"
            >
              {day}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((week, index) => (
          <TableRow key={index}>
            {week.map((day, i) => {
              const isInRange =
                isSameMonth && isSameYear && day >= startDay && day <= endDay;
              return (
                <TableCell
                  sx={{
                    backgroundColor: isInRange ? "#CA0005" : "transparent",
                    border: isInRange ? "1px solid #fff" : "1px solid #787878",
                    color: isInRange ? "white" : "inherit",
                  }}
                  className={styles.cellStyle}
                  key={i}
                  align="center"
                >
                  <p className={styles.cellStyleText}>{day || ""}</p>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export const ActiveDaysCalendar = ({ year, month, rowData }) => {
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDay = new Date(year, month, 1).getDay();
  const adjustedFirstDay = firstDay === 0 ? 6 : firstDay - 1;

  const weekdays = [
    "Bazar ertəsi",
    "Çərşənbə axşamı",
    "Çərşənbə",
    "Cümə axşamı",
    "Cümə",
    "Şənbə",
    "Bazar",
  ];

  let days = Array(adjustedFirstDay).fill(null);
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  const rows = [];
  for (let i = 0; i < days.length; i += 7) {
    rows.push(days.slice(i, i + 7));
  }

  const startMoment = moment(rowData?.startdate, "DD/MM/YYYY");
  const endMoment = moment(rowData?.enddate, "DD/MM/YYYY");

  // Ensure the selected month matches
  const isSameMonth =
    month >= startMoment.month() && month <= endMoment.month();
  const isSameYear = year >= startMoment.year() && year <= endMoment.year();

  // Define start and end days for highlighting
  const startDay =
    isSameMonth && isSameYear
      ? startMoment.month() === month
        ? startMoment.date()
        : 1
      : null;
  const endDay =
    isSameMonth && isSameYear
      ? endMoment.month() === month
        ? endMoment.date()
        : daysInMonth
      : null;

  return (
    <Table sx={{ margin: "16px 0" }}>
      <TableHead>
        <TableRow>
          {weekdays.map((day, index) => (
            <TableCell
              sx={{
                textAlign: "left",
                fontSize: "10px",
                color: index < 5 ? "#B6C0D9" : "#ED3779",
              }}
              key={day}
              align="center"
            >
              {day}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((week, index) => (
          <TableRow key={index}>
            {week.map((day, i) => {
              const isInRange =
                isSameMonth && isSameYear && day >= startDay && day <= endDay;
              return (
                <TableCell
                  sx={{
                    backgroundColor: isInRange ? "#CA0005" : "transparent",
                    border: isInRange ? "1px solid #fff" : "1px solid #787878",
                    color: isInRange ? "white" : "inherit",
                  }}
                  className={styles.cellStyle}
                  key={i}
                  align="center"
                >
                  <p className={styles.cellStyleText}>{day || ""}</p>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
