import React from "react";
import { Button, Grid2 } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const OverTimeFormBottom = ({
  push,
  dirty,
  values,
  isValid,
  setIsDate,
  isSubmitting,
}) => {
  return (
    <Grid2
      sx={{
        mt: 3,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {/* {values?.type && values?.overtime?.length < 50 && (
        <Button
          variant="contained"
          onClick={() => {
            push({
              employee: "",
              contract: "",
              contractDate: "",
              structure: "",
              position: "",
              footer: [
                {
                  date: "",
                  hour: "",
                  paymentType: "Double",
                },
              ],
            });
            setIsDate(false);
          }}
        >
          Əməkdaş əlavə et <AddIcon />
        </Button>
      )} */}
      {values?.type && (
        <Button
          disabled={isSubmitting || !isValid || !dirty}
          type="submit"
          variant="contained"
        >
          Yadda saxla
        </Button>
      )}
    </Grid2>
  );
};
