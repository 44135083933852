import React from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Field } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { RelationshipInfoEnum } from "../../../../constants/constants";

const VacationForDialog = ({
  values,
  setFieldValue,
  selectedValue,
  selectedEmpData,
}) => {
  function getForSeniortyLevel() {
    const totalExperienceYears = selectedEmpData?.laborActivity?.reduce(
      (total, item) =>
        total + getYearsDiff(item.startDate, item.endDate || new Date()),
      0
    );

    if (totalExperienceYears >= 5 && totalExperienceYears < 10) return 2;
    if (totalExperienceYears >= 10 && totalExperienceYears < 15) return 4;
    if (totalExperienceYears >= 15) return 6;

    return 0;
  }
  function getForParental() {
    const children = selectedEmpData?.relationshipInfo?.filter(
      (rel) => rel.relationType === RelationshipInfoEnum.child
    );

    if (!children?.length) return 0;

    if (selectedEmpData.gender === "female") {
      if (
        children.length === 2 &&
        children.every(
          (item) => getYearsDiff(new Date(item.dob), new Date()) <= 14
        )
      ) {
        return 2;
      }

      const filtered = children.filter(
        (item) => getYearsDiff(new Date(item.dob), new Date()) <= 14
      );

      if (filtered.length >= 3) {
        return 5;
      }
    } else if (selectedEmpData?.gender === "male") {
      const hasWife = selectedEmpData?.relationshipInfo.find(
        (item) => item.relationType === RelationshipInfoEnum.wife
      );

      if (!hasWife && children.length > 0) {
        if (
          children.length === 2 &&
          children.every(
            (item) => getYearsDiff(new Date(item.dob), new Date()) <= 14
          )
        ) {
          return 2;
        }

        const filtered = children.filter(
          (item) => getYearsDiff(new Date(item.dob), new Date()) <= 14
        );

        if (filtered.length >= 3) {
          return 5;
        }
      }
    }
    return 0;
  }
  function getYearsDiff(start, end) {
    return moment(end).diff(start, "years");
  }

  const forSeniortyLevel = [...new Set([getForSeniortyLevel(), 2, 4, 6])];
  const forParental = [...new Set([getForParental(), 2, 5])];

  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          // sx={{ border: "1px solid #56606F", borderRadius: "4px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>Məzuniyyət üzrə</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>Əsas məzuniyyət (gün)</InputLabel>
                <Field
                  as={Select}
                  label="Əsas məzuniyyət (gün)"
                  defaultValue=""
                  fullWidth
                  name="vacation.vacationDaysCount"
                  onChange={(event) => {
                    setFieldValue(
                      "vacation.vacationDaysCount",
                      event.target.value
                    );
                  }}
                >
                  {selectedValue?.vacationDaysCount &&
                    selectedValue?.vacationDaysCount.map((item, i) => (
                      <MenuItem key={`${item}${i}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>Əmək stajına görə əlavə (gün)</InputLabel>
                <Field
                  as={Select}
                  label="Əmək stajına görə əlavə (gün)"
                  defaultValue=""
                  fullWidth
                  name="vacation.forSeniorityLevel"
                  onChange={(event) => {
                    setFieldValue(
                      "vacation.forSeniorityLevel",
                      event.target.value
                    );
                  }}
                >
                  {forSeniortyLevel &&
                    forSeniortyLevel.map((item, i) => (
                      <MenuItem key={`${item}${i}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>
                  4.28.3 Ə.M. 117.1 bəndində nəzərə alınan hallara görə əlavələr
                  (gün)
                </InputLabel>
                <Field
                  as={Select}
                  label="4.28.3 Ə.M. 117.1 bəndində nəzərə alınan hallara görə əlavələr
                  (gün)"
                  defaultValue=""
                  fullWidth
                  name="vacation.forParental"
                  onChange={(event) => {
                    setFieldValue("vacation.forParental", event.target.value);
                  }}
                >
                  {forParental &&
                    forParental.map((item, i) => (
                      <MenuItem key={`${item}${i}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>
            <TextField
              type="number"
              label="Kollektiv müqavilə (saziş) üzrə"
              value={values?.vacation?.forCollectiveAgreement}
              fullWidth
              onWheel={(e) => e.target.blur()}
              name="vacation.forCollectiveAgreement"
              onChange={(event) => {
                setFieldValue(
                  "vacation.forCollectiveAgreement",
                  event.target.value
                );
              }}
            />
            <TextField
              type="number"
              label="Sosial-məişət müavinəti"
              value={values?.vacation?.socialHouseholdBenefit}
              fullWidth
              name="vacation.socialHouseholdBenefit"
              onWheel={(e) => e.target.blur()}
              onChange={(event) => {
                setFieldValue(
                  "vacation.socialHouseholdBenefit",
                  event.target.value
                );
              }}
            />
            <TextField
              type="text"
              label="Konkret tədbir və ya ödənişi haqqında"
              value={values?.vacation?.aboutEventOrPayer}
              fullWidth
              name="vacation.aboutEventOrPayer"
              onChange={(event) => {
                setFieldValue("vacation.aboutEventOrPayer", event.target.value);
              }}
            />
          </Grid>
        </AccordionDetails>
      </CustomAccordion>
    </>
  );
};

export default VacationForDialog;
