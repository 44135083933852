import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import {
  Autocomplete,
  FormControl,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import DepartmentsEmployeeAtWork from "../../../components/vacations/hrpage/DepartmentsEmployeeAtWork";
import MonthlyRequestsBar from "../../../components/vacations/hrpage/MonthlyRequestsBar";
import PeopleAtWorkDoughnut from "../../../components/vacations/hrpage/PeopleAtWorkDoughnut";
import VacationStatistics from "../../../components/vacations/hrpage/VacationStatistics";
import VacationsRequestsTable from "../../../components/vacations/hrpage/VacationsRequestsTable";
import { getCompanyStructures } from "../../../services/structure/StructureServices";

const Divider = styled(MuiDivider)(spacing);

function VacationsComponent({ structureId }) {
  return (
    // <Card mb={6}>
    <Grid>
      <Grid container spacing={5} mb={5}>
        <Grid item xs={6}>
          <VacationStatistics id={structureId} />
        </Grid>
        <Grid item xs={6}>
          <MonthlyRequestsBar id={structureId} />
        </Grid>
      </Grid>
      <Grid container spacing={5} mb={5}>
        <Grid item xs={7}>
          <DepartmentsEmployeeAtWork id={structureId} />
        </Grid>
        <Grid item xs={5}>
          <PeopleAtWorkDoughnut id={structureId} />
        </Grid>
      </Grid>
      <Grid>
        <VacationsRequestsTable id={structureId} />
      </Grid>
    </Grid>
    // </Card>
  );
}

function Vacations() {
  const navigate = useNavigate();
  const [structureDatas, setStructureDatas] = useState([]);
  const [structureId, setStructureId] = useState("");
  async function fetchCompanyStructures() {
    try {
      const data = await getCompanyStructures();
      setStructureDatas(data?.structures);
    } catch (err) {
      console.log("error: ", err);
    }
  }
  useEffect(() => {
    fetchCompanyStructures();
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          item
          width="100%"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h3" width="200px" gutterBottom display="inline">
            Məzuniyyətlər
          </Typography>
          <FormControl style={{ width: "30%" }}>
            <Autocomplete
              options={
                structureDatas?.filter(
                  (structure) =>
                    structure?.organization?.name &&
                    structure?.organization?.name.trim() !== ""
                ) || []
              }
              getOptionLabel={(option) => option?.organization?.name || ""}
              onChange={(event, selectedStructure) => {
                const id = selectedStructure?._id || "";
                setStructureId(id);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Struktur" variant="outlined" />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item>
          {/* <Button
            fullWidth
            onClick={() => {
              navigate("/create-vacation-request");
            }}
            variant="contained"
            endIcon={<AddCircleIcon />}
          >
            <Typography noWrap>Məzuniyyət Sorğusu Yarat</Typography>
          </Button> */}
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <VacationsComponent structureId={structureId} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Vacations;
