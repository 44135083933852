// Read downloadRequests.js and create the service for this API in downloadServices.js based on its structure and methods
import { downloadWorkTimeAccountTable_API } from "../api/downloadRequests";

// ⇩ ⇩ ⇩  Download Requests ⇩ ⇩ ⇩

export const downloadWorkTimeAccountTable_SERVICE = async ({
  filter,
  month,
  year,
  structureId,
}) => {
  try {
    const response = await downloadWorkTimeAccountTable_API(
      filter,
      month,
      year,
      structureId
    );
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
// ⇧ ⇧ ⇧ Download Requests ⇧ ⇧ ⇧;
