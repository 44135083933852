import { Cancel, ManageSearch } from "@mui/icons-material";
import React, { createRef, memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FilterModel from "../../models/table/Filter/FilterModel";
import { getDescendantProp } from "../../utils/helperFunctions";
import ValueInput from "./parts/Filter/parts/dropdown/body/filterItem/ValueInput";
import LoadingTable from "./parts/LoadingTable";
import TableFooter from "./parts/TableFooter";
import Toolbar from "./parts/Toolbar";
import moment from "moment";
import Column from "../../models/table/Column";

const Container = styled.div`
  width: 100%;
  overflow: visible;
  border: 2px solid rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  padding: 5px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const TableComponent = styled.table`
  border-collapse: collapse;
  border-spacing: 0px;
  border-radius: 5px;
  padding: 10px 40px;
  table-layout: auto;
  width: 100%;
  display: table;
`;

const Col = styled.col`
  padding: 5px 15px;
  border: 1px solid rgba(255, 255, 255, 0.025);
  display: table-column;
  overflow: auto;
  min-width: 50px;
  // width: auto;
`;

const CellCont = styled.div`
  display: flex;
  justify-content: center;
  row-gap: 5px;
  align-items: center;
  text-align: center;
  min-height: 20px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TH = styled.th`
  white-space: nowrap;
  padding: 5px 5px;
  text-align: center;
  font-weight: 500;
  text-overflow: ellipsis;
  cursor: default;
  min-width: auto;
  overflow: hidden;
  width: auto;
  max-width: none;
`;

const Td = styled.td`
  overflow: hidden;
  padding: 10px;
  margin: 0;
  font-weight: 100;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Tr = styled.tr`
  border: 1px solid rgba(255, 255, 255, 0.05);
`;
const SubHeaderTr = styled.tr`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
`;

const Colgroup = styled.colgroup``;

const Thead = styled.thead`
  border-radius: 3px;
  background-color: rgba(21, 27, 76, 0.7);
  border-bottom: 1px solid rgba(41, 47, 96, 1);
`;

const TableWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  overflow: auto;
`;

function SalaryTable({
  columns,
  data,
  totalRows,
  offset,
  setOffset,
  limit,
  setLimit,
  isLoading,
  graphicName,
  applyFiltersHandler,
  cancelAppliedFilters,
  downloadHandler,
  addLeftContButtons,
  addRightContButtons,
  hideColumnVisibility = false,
  hideDownload = false,
  hideFilter = false,
  editMode,
  setShowFilter,
  resetAll,
  showReset,
}) {
  const initialInputsValues =
    columns?.length &&
    columns.reduce((acc, column) => {
      if (column.filterType?.length > 0 && column.isSubHeader === false) {
        return {
          ...acc,
          [column.path]: new FilterModel({
            column: column.path,
            operator: column.filterType[0].key,
            value: "",
          }),
        };
      } else if (column.filterType?.length > 0 && column.isSubHeader === true) {
        column.subHeader?.forEach((subHeader) => {
          acc[subHeader.colmun] = new FilterModel({
            column: subHeader.colmun,
            operator: subHeader.filterType[0].key,
            value: "",
          });
        });
        return acc;
      }
      return acc;
    }, {});

  const [showColSearch, setShowColSearch] = useState(false);
  const [fontSize, setFontSize] = useState(14);
  const [cols, setCols] = useState([]);
  const [colWidth, setColWidth] = useState(150);
  const [isAllShown, setIsAllShown] = useState(true);

  const [quickFilters, setQuickFilters] = useState(initialInputsValues || {});

  const tableRef = createRef(null);

  // useEffect(() => {
  //   const { width } = tableRef.current.getBoundingClientRect();
  //   // setColWidth((+width - 50) / columns.length);
  // }, []);

  useEffect(() => {
    if (tableRef.current) {
      const table = tableRef.current;
      const headerCells = table.querySelectorAll("th");
      const bodyCells = table.querySelectorAll("td");

      // Her sütun için maksimum genişliği hesapla
      headerCells.forEach((th, index) => {
        let maxWidth = th.offsetWidth;

        // Aynı sütundaki tüm hücreleri kontrol et
        bodyCells.forEach((td, tdIndex) => {
          if (tdIndex % headerCells.length === index) {
            maxWidth = Math.max(maxWidth, td.offsetWidth);
          }
        });

        // Minimum 100px genişlik ve içeriğe göre maksimum genişlik
        const finalWidth = Math.max(100, maxWidth);
        th.style.width = `${finalWidth}px`;
      });
    }
  }, [data, cols]); // data veya cols değiştiğinde tekrar hesapla

  useEffect(() => {
    const sortedColumns = [...columns].sort((a, b) => {
      if (a.isStaticColumn && !b.isStaticColumn) {
        return 1; // Move 'a' (isStaticColumn: true) to the end
      } else if (!a.isStaticColumn && b.isStaticColumn) {
        return -1; // Keep 'b' (isStaticColumn: true) before 'a'
      } else {
        return 0; // Maintain the current order
      }
    });

    setCols(sortedColumns);
  }, [columns]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    e.preventDefault();
    const staticColumns = cols?.filter((item) => item?.isStaticColumn);
    const changingArea = cols?.length - 1 - (staticColumns?.length || 0);

    const oldIndex = parseInt(e.dataTransfer.getData("index"));
    if (cols[oldIndex].isStaticColumn || newIndex > changingArea) return;

    const updatedCols = [...cols];
    const [movedCol] = updatedCols.splice(oldIndex, 1);

    updatedCols.splice(newIndex, 0, movedCol);

    setCols(updatedCols);
  };

  // const generateQuickFilterState = () => {
  //   const arr = columns?.map((col) => {
  //     return {
  //       id: col.path,
  //       column: col.path,
  //       operator: col?.filterType && col.filterType[0]?.key,
  //       isQuickFilter: true,
  //       value: "",
  //     };
  //   });
  //   setQuickFilters(arr);
  // };

  const singleFilterHandler = (column, value) => {
    setQuickFilters((prevValues) => {
      if (value === " ") {
        value = value.trim();
      } else if (value?.slice(value?.length - 2) === "  ") {
        value = value?.slice(0, value?.length - 1);
      }

      // For columns without subheaders
      return {
        ...prevValues,
        [column?.colmun ? column?.colmun : column.path]: new FilterModel({
          column: column?.colmun
            ? column?.colmun
            : column.filterColumnKey
            ? column.filterColumnKey
            : column.path,
          operator: column.filterType[0].key,
          value: column?.colmun ? parseInt(value) : value,
        }),
      };
    });
  };

  const resetQuickFilterHandler = (column) => {
    setQuickFilters((prevValues) => ({
      ...prevValues,
      [column?.colmun ? column?.colmun : column?.path]: new FilterModel({
        column: column?.colmun
          ? column?.colmun
          : column?.filterColumnKey
          ? column?.filterColumnKey
          : column?.path,
        operator: column?.filterType?.[0]?.key,
        value: "",
      }),
    }));
  };

  const emptyRowRender = () => {
    const emptyCount = limit && (isLoading ? limit : limit - data?.length);

    const tableRows = [];

    if (emptyCount <= 0) return;

    for (let i = 0; i < emptyCount; i++) {
      tableRows.push(
        <Tr key={"empty" + i}>
          <Td>
            <CellCont></CellCont>
          </Td>
        </Tr>
      );
    }
    return tableRows;
  };

  const cancelAppliedFiltersHandler = async () => {
    setQuickFilters(initialInputsValues);
    await cancelAppliedFilters();
  };

  const sendColumnsForColumns = async () => {
    await downloadHandler(cols);
  };

  const formatDate = (dateString) => {
    const date = moment(dateString);
    const formattedDate = date.format("DD/MM/YYYY");
    return formattedDate;
  };

  return (
    <Container>
      <Toolbar
        columns={{ data: cols, setData: setCols }}
        fontSize={fontSize}
        fontSizeHandler={(value) => setFontSize(value)}
        limit={limit}
        setLimit={(value) => {
          setOffset(0);
          setLimit(value);
        }}
        applyFiltersHandler={applyFiltersHandler}
        cancelAppliedFilters={cancelAppliedFiltersHandler}
        quickFilters={quickFilters}
        downloadHandler={sendColumnsForColumns}
        addLeftContButtons={addLeftContButtons}
        addRightContButtons={addRightContButtons}
        hideColumnVisibility={hideColumnVisibility}
        hideFilter={hideFilter}
        hideDownload={hideDownload}
        setColWidth={setColWidth}
        colWidth={colWidth}
        editMode={editMode}
        setShowFilter={setShowFilter}
        resetAll={resetAll}
        showReset={showReset}
        isAllShown={isAllShown}
        setIsAllShown={setIsAllShown}
      />
      <div style={{ position: "relative" }}>
        <TableWrapper>
          <TableContainer ref={tableRef}>
            <TableComponent>
              <Colgroup>
                <Col style={{ maxWidth: "60px" }} />
                {cols?.map((col, index) =>
                  col.isShow ? (
                    col.isSubHeader === false ? (
                      <Col
                        key={col.id || index}
                        style={{
                          minWidth: col.minWidth || "auto",
                        }}
                      />
                    ) : (
                      <React.Fragment key={col.id || index}>
                        <Col style={{ minWidth: col.minWidth || "auto" }} />
                        <Col style={{ minWidth: col.minWidth || "auto" }} />
                        <Col style={{ minWidth: col.minWidth || "auto" }} />
                      </React.Fragment>
                    )
                  ) : null
                )}
                {!isAllShown ? <Col /> : null}
              </Colgroup>

              <Thead>
                <Tr>
                  <TH className="sticky-th">
                    <span></span>
                  </TH>
                  {cols
                    .filter((col) => col.isShow)
                    .reduce((acc, col) => {
                      if (col.groupHeader) {
                        if (
                          acc.length === 0 ||
                          acc[acc.length - 1].groupHeader !== col.groupHeader
                        ) {
                          acc.push({
                            groupHeader: col.groupHeader,
                            colSpan: 1,
                            headerColor: col.headerColor || "#f0f0f0",
                          });
                        } else {
                          acc[acc.length - 1].colSpan += 1;
                        }
                      } else {
                        if (
                          acc.length > 0 &&
                          acc[acc.length - 1].groupHeader === null
                        ) {
                          acc[acc.length - 1].colSpan += 1;
                        } else {
                          acc.push({
                            groupHeader: null,
                            colSpan: 1,
                            headerColor: "transparent",
                          });
                        }
                      }
                      return acc;
                    }, [])
                    .map((group, index) => (
                      <TH
                        key={index}
                        colSpan={group.colSpan}
                        style={{
                          textAlign: "center",
                          backgroundColor: group.headerColor,
                          fontWeight: group.groupHeader ? "bold" : "normal",
                          borderBottom: "2px solid #ddd",
                          padding: "10px",
                        }}
                      >
                        {group.groupHeader || ""}
                      </TH>
                    ))}
                </Tr>

                <Tr>
                  <TH className="sticky-th">
                    <span>№</span>
                  </TH>
                  {cols
                    .filter((col) => col.isShow)
                    .map((item, index) => (
                      <TH
                        key={index}
                        draggable
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, index)}
                        style={{
                          ...(item.path === "#" && {
                            position: "sticky",
                            left: "47px",
                            zIndex: 2,
                            backgroundColor: "#151b4c",
                            color: "white",
                          }),
                          textAlign: "center",
                          padding: "8px",
                          fontSize: fontSize,
                          borderBottom: "1px solid #ddd",
                          whiteSpace: "normal",
                        }}
                      >
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          <span>{item.headerName}</span>
                          {!hideFilter && !item.disableColSearch && (
                            <ManageSearch
                              style={{ cursor: "pointer", marginLeft: "8px" }}
                              onClick={() => setShowColSearch(!showColSearch)}
                            />
                          )}
                        </div>
                        {showColSearch && !item.disableColSearch && (
                          <div style={{ marginTop: "5px" }}>
                            <ValueInput
                              key={item.path}
                              column={item}
                              setQuickFilters={setQuickFilters}
                              values={item.filterValues}
                              fetchHandler2={item.fetchFilterValuesV2}
                              dataGridName={item.dataGridName}
                              fetchHandler={item.fetchFilterValues}
                              filterType={item.filterType}
                              onChange={(e) => singleFilterHandler(item, e)}
                              value={quickFilters?.[item.path]?.value}
                              filter={quickFilters?.[item.path]}
                              filters={quickFilters}
                              triggerFetching={showColSearch}
                            />
                            <Cancel
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                              onClick={() => resetQuickFilterHandler(item)}
                            />
                          </div>
                        )}
                      </TH>
                    ))}
                </Tr>
              </Thead>

              <tbody>
                {!isLoading &&
                  Array.isArray(data) &&
                  data.length > 0 &&
                  data.map((row, rowIndex) => {
                    const maxDays = 7;
                    const thresholdHours = row?.structure?.workHourMode;
                    let totalHours;
                    if (row?.graphics) {
                      for (
                        let i = 0;
                        i <= row?.graphics.length - maxDays;
                        i++
                      ) {
                        const week = row.graphics.slice(i, i + maxDays);
                        totalHours = week.reduce(
                          (sum, day) => sum + day.dailyHours,
                          0
                        );
                      }
                    }
                    return (
                      <Tr
                        key={rowIndex}
                        style={{
                          background:
                            totalHours > thresholdHours && "#ff000014",
                        }}
                      >
                        <Td
                          className="sticky-td"
                          style={{
                            position: "sticky",
                            left: "-1px",
                            zIndex: 2,
                            background: "#1B2635",
                          }}
                        >
                          {totalHours > thresholdHours && (
                            <div
                              style={{
                                position: "absolute",
                                transform: "translate(-3px, 33px)",
                                fontSize: "11px",
                                background: "#570101",
                              }}
                            >
                              Həftəlik norma saat pozulmuşdur
                            </div>
                          )}
                          <CellCont>{offset + rowIndex + 1}</CellCont>
                        </Td>
                        {cols?.map(
                          (col, colIndex) =>
                            col?.isShow &&
                            (col?.isSubHeader === false && !col?.rowSpan ? (
                              <Td
                                style={{
                                  ...(col.path === "#" && {
                                    position: "sticky",
                                    left: "47px",
                                    zIndex: 1,
                                    background: "#1B2635",
                                  }),
                                }}
                                key={colIndex}
                              >
                                {col.renderCell ? (
                                  col.renderCell(row, col)
                                ) : (
                                  <CellCont>
                                    <div
                                      style={{
                                        fontSize: fontSize,
                                        textOverflow: "ellipsis",
                                        width: "250px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {col.path === "dob"
                                        ? formatDate(
                                            getDescendantProp(row, col.path)
                                          )
                                        : col.getValue
                                        ? col.getValue(row, col)
                                        : getDescendantProp(row, col.path)}
                                    </div>
                                  </CellCont>
                                )}
                              </Td>
                            ) : (
                              col.renderCell && col.renderCell(row, col)
                            ))
                        )}
                      </Tr>
                    );
                  })}
                {emptyRowRender()}
              </tbody>
              {isLoading && <LoadingTable />}
            </TableComponent>
          </TableContainer>
        </TableWrapper>
        <TableFooter
          totalRows={totalRows}
          limit={limit}
          offset={offset}
          paginationHandler={(page) => {
            const value = (+page - 1) * limit;
            setOffset(value);
          }}
        />
      </div>
    </Container>
  );
}

export default memo(SalaryTable);
