import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { handleServiceCall } from "../services/handleServiceCall";
import {
  registerUser_SERVICE,
  deleteUser_SERVICE,
  editUser_SERVICE,
  getUser_SERVICE,
  getUsers_SERVICE,
} from "../services/user-accountServices";

const useUserActions = () => {
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userRegisterData, setUserRegisterData] = useState({
    citizenship: "",
    email: "",
    pin: "",
    passportNumber: "",
    type: "u",
  });
  const [userRegisterDataRef, setUserRegisterDataRef] = useState({
    email: "",
  });
  const [userRegisterDialog, setUserRegisterDialog] = useState(false);
  const [userEditDialog, setUserEditDialog] = useState(false);
  const [userDeleteDialog, setUserDeleteDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [preDefinedData, setPreDefinedData] = useState({});
  const requestError = (error) => {
    enqueueSnackbar("Xəta baş verdi", { variant: "error" });
    // setUserRegisterDialog(false);
  };

  // const refetchUsers = async () => {
  //   await handleServiceCall(
  //     handleGetAllSuccess,
  //     requestError,
  //     getUsers_SERVICE,
  //     { limit: 10, offset: 0 }
  //   );
  // };
  // Success Handlers
  const handleRegisterSuccess = (data, email) => {
    enqueueSnackbar("İstifadəçi uğurla yaradıldı", { variant: "success" });
    setActiveStep(2);
    setPreDefinedData({ email: data?.email, pin: data.pin, user: data._id });
  };
  const handleDeleteSuccess = () => {
    enqueueSnackbar("İstifadəçi uğurla silindi", { variant: "success" });
    setUserDeleteDialog(false);
    getUsers();
  };

  const handleGetUserSuccess = (data) => {
    setUserRegisterData({
      citizenship: data?.citizenship,
      email: data?.email,
      pin: data?.pin,
      passportNumber: data?.passportNumber,
      id: data?._id,
      email_ref: data?.email,
      citizenship_ref: data?.citizenship,
    });
  };

  const handleEditSuccess = (data) => {
    enqueueSnackbar("İstifadəçi uğurla redaktə edildi", { variant: "success" });
    setUserEditDialog(false);
    getUsers();
  };

  const handleGetAllSuccess = (data) => {
    setData(data?.items);
    setTotal(data?.total);
  };

  // API Calls
  const registerUser = async (userRegisterData) =>
    await handleServiceCall(
      handleRegisterSuccess,
      requestError,
      registerUser_SERVICE,
      userRegisterData
    );

  const deleteUser = async (id) =>
    await handleServiceCall(
      setLoading,
      handleDeleteSuccess,
      requestError,
      deleteUser_SERVICE,
      id
    );

  const getUser = async (id) =>
    await handleServiceCall(
      handleGetUserSuccess,
      requestError,
      getUser_SERVICE,
      id
    );

  const editUser = async (userEditData) =>
    await handleServiceCall(
      handleEditSuccess,
      requestError,
      editUser_SERVICE,
      userEditData
    );

  const getUsers = async () =>
    await handleServiceCall(
      setLoading,
      handleGetAllSuccess,
      requestError,
      getUsers_SERVICE,
      { limit: 10, offset: 0 }
    );

  const handleChange = (e) => {
    setUserRegisterData({
      ...userRegisterData,
      [e.target.name]: e.target.value,
    });
  };

  return {
    registerUser,
    deleteUser,
    getUser,
    editUser,
    getUsers,
    handleChange,
    userRegisterData,
    setUserRegisterData,
    userRegisterDialog,
    setUserRegisterDialog,
    userDeleteDialog,
    setUserDeleteDialog,
    activeStep,
    setActiveStep,
    setUserEditDialog,
    userEditDialog,
    data,
    setData,
    total,
    loading,
    preDefinedData,
  };
};

export default useUserActions;
