import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, FieldArray, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { getCommonData } from "../../api/api";

const RewardsReprimands = ({
  values,
  setSelectedType,
  setOpenAddDialog,
  submitted,
}) => {
  const [rewardOrganizations, setRewardOrganizations] = useState([]);
  const { errors, setErrors, setFieldValue } = useFormikContext();

  useEffect(() => {
    const handleOrganizations = async () => {
      const response = await getCommonData("organizations");
      setRewardOrganizations(response.data);
    };
    handleOrganizations();
  }, [submitted]);

  return (
    <FieldArray name="reprimandsAndRewards">
      {({ push, remove }) => (
        <div>
          {values?.reprimandsAndRewards &&
            values?.reprimandsAndRewards.map((form, index) => (
              <div key={index}>
                <>
                  <Typography mt={3} mb={3} variant="h6">
                    {index + 1})Mükafat, təltif və tənbehləri
                  </Typography>

                  {/* Rewards/ Reprimands and  description */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.reprimandsAndRewards?.[index]?.rType &&
                          Boolean(errors?.reprimandsAndRewards?.[index]?.rType)
                        }
                        fullWidth
                      >
                        <InputLabel id={`reprimandsAndRewards[${index}].rType`}>
                          Mükafat / Təltif / Tənbeh *
                        </InputLabel>
                        <Field
                          as={Select}
                          name={`reprimandsAndRewards[${index}].rType`}
                          labelId={`reprimandsAndRewards[${index}].rType`}
                          label="Mükafat / Təltif / Tənbeh *"
                          id={`reprimandsAndRewards[${index}].rType`}
                          defaultValue=""
                          fullWidth
                          error={
                            errors?.reprimandsAndRewards?.[index]?.rType &&
                            Boolean(
                              errors?.reprimandsAndRewards?.[index]?.rType
                            )
                          }
                          onChange={async (e) => {
                            setFieldValue(
                              `reprimandsAndRewards[${index}].rType`,
                              e.target.value
                            );
                            if (errors?.reprimandsAndRewards?.[index]?.rType) {
                              setErrors({
                                ...errors,
                                reprimandsAndRewards: [
                                  ...errors.reprimandsAndRewards.slice(
                                    0,
                                    index
                                  ),
                                  {
                                    ...errors.reprimandsAndRewards[index],
                                    rType: "",
                                  },
                                  ...errors.reprimandsAndRewards.slice(
                                    index + 1
                                  ),
                                ],
                              });
                            }
                          }}
                        >
                          <MenuItem value="prize">Mükafat</MenuItem>
                          <MenuItem value="reward">Təltif</MenuItem>
                          <MenuItem value="reprimand">Tənbeh</MenuItem>
                        </Field>
                        {errors?.reprimandsAndRewards?.[index]?.rType &&
                          errors?.reprimandsAndRewards?.[index]?.rType && (
                            <FormHelperText error>
                              {errors?.reprimandsAndRewards?.[index]?.rType}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name={`reprimandsAndRewards[${index}].description`}
                        as={TextField}
                        fullWidth
                        error={
                          errors?.reprimandsAndRewards?.[index]?.description &&
                          Boolean(
                            errors?.reprimandsAndRewards?.[index]?.description
                          )
                        }
                        helperText={
                          errors?.reprimandsAndRewards?.[index]?.description &&
                          errors?.reprimandsAndRewards?.[index]?.description
                        }
                        label="Mükafat / Təltif / Tənbehin məzmunu *"
                        onChange={async (e) => {
                          setFieldValue(
                            `reprimandsAndRewards[${index}].description`,
                            e.target.value
                          );
                          if (
                            errors?.reprimandsAndRewards?.[index]?.description
                          ) {
                            setErrors({
                              ...errors,
                              reprimandsAndRewards: [
                                ...errors.reprimandsAndRewards.slice(0, index),
                                {
                                  ...errors.reprimandsAndRewards[index],
                                  description: "",
                                },
                                ...errors.reprimandsAndRewards.slice(index + 1),
                              ],
                            });
                          }
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* start date and company */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field name={`reprimandsAndRewards[${index}].date`}>
                        {({ field, form }) => (
                          <DatePicker
                            {...field}
                            label="Mükafat / Təltif / Tənbehin qüvvəyə mindiyi tarix *"
                            sx={{ width: "100%" }}
                            format="dd/MM/yyyy"
                            value={field?.value ? new Date(field?.value) : null}
                            slotProps={{
                              textField: {
                                error: Boolean(
                                  form.errors?.reprimandsAndRewards?.[index]
                                    ?.date
                                ),
                                helperText:
                                  form.errors?.reprimandsAndRewards?.[index]
                                    ?.date || "",
                                fullWidth: true,
                              },
                            }}
                            onChange={(date) => {
                              if (date instanceof Date && !isNaN(date)) {
                                form.setFieldValue(
                                  `reprimandsAndRewards[${index}].date`,
                                  date
                                );
                                if (
                                  errors?.reprimandsAndRewards?.[index]?.date
                                ) {
                                  setErrors({
                                    ...errors,
                                    reprimandsAndRewards: [
                                      ...errors.reprimandsAndRewards.slice(
                                        0,
                                        index
                                      ),
                                      {
                                        ...errors.reprimandsAndRewards[index],
                                        date: "",
                                      },
                                      ...errors.reprimandsAndRewards.slice(
                                        index + 1
                                      ),
                                    ],
                                  });
                                }
                              } else {
                                form.setFieldValue(
                                  `reprimandsAndRewards[${index}].date`,
                                  ""
                                );
                              }
                            }}
                            maxDate={new Date()}
                            minDate={new Date(1940, 0, 1)}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid sx={{ width: "100%" }}>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.reprimandsAndRewards?.[index]
                              ?.organization &&
                            Boolean(
                              errors?.reprimandsAndRewards?.[index]
                                ?.organization
                            )
                          }
                          fullWidth
                        >
                          <InputLabel
                            id={`reprimandsAndRewards[${index}].organization`}
                          >
                            Mükafat / Təltif / Tənbehin təqdim olunduğu müəssisə
                            *
                          </InputLabel>
                          <Field
                            as={Select}
                            name={`reprimandsAndRewards[${index}].organization`}
                            labelId={`reprimandsAndRewards[${index}].organization`}
                            label="Mükafat / Təltif / Tənbehin təqdim olunduğu müəssisə *"
                            id={`reprimandsAndRewards[${index}].organization`}
                            defaultValue=""
                            fullWidth
                            error={
                              errors?.reprimandsAndRewards?.[index]
                                ?.organization &&
                              Boolean(
                                errors?.reprimandsAndRewards?.[index]
                                  ?.organization
                              )
                            }
                            onChange={async (e) => {
                              setFieldValue(
                                `reprimandsAndRewards[${index}].organization`,
                                e.target.value
                              );
                              if (
                                errors?.reprimandsAndRewards?.[index]
                                  ?.organization
                              ) {
                                setErrors({
                                  ...errors,
                                  reprimandsAndRewards: [
                                    ...errors.reprimandsAndRewards.slice(
                                      0,
                                      index
                                    ),
                                    {
                                      ...errors.reprimandsAndRewards[index],
                                      organization: "",
                                    },
                                    ...errors.reprimandsAndRewards.slice(
                                      index + 1
                                    ),
                                  ],
                                });
                              }
                            }}
                          >
                            {rewardOrganizations.length > 0 &&
                              rewardOrganizations
                                .sort((a, b) => a.localeCompare(b, "az"))
                                .map((item, index) => (
                                  <MenuItem value={item} key={index}>
                                    {item}
                                  </MenuItem>
                                ))}
                            <Button
                              sx={{
                                mt: 3,
                                ml: 3,
                              }}
                              variant="outlined"
                              onClick={() => {
                                setOpenAddDialog(true);
                                setSelectedType([
                                  "organizations",
                                  { name: "Təşkilatlar" },
                                ]);
                              }}
                            >
                              Yeni mükafat / Təltif / Tənbehin təqdim olunduğu
                              müəssisə əlavə et
                            </Button>
                          </Field>
                          {errors?.reprimandsAndRewards?.[index]
                            ?.organization &&
                            errors?.reprimandsAndRewards?.[index]
                              ?.organization && (
                              <FormHelperText error>
                                {
                                  errors?.reprimandsAndRewards?.[index]
                                    ?.organization
                                }
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </>

                <Button
                  sx={{ mt: 3 }}
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={() => remove(index)}
                >
                  Mükafat / Təltif / Tənbeh sil
                </Button>
              </div>
            ))}
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            type="button"
            onClick={() => push({})}
          >
            Mükafat / Təltif / Tənbeh əlavə et
          </Button>
        </div>
      )}
    </FieldArray>
  );
};

export default RewardsReprimands;
