import React, { useEffect, useState } from "react";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Divider,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import AddEmployee from "../../pages/employee/AddEmployee";
import { styled } from "@mui/material/styles";
import Badge from "@mui/icons-material/Badge";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { AccountCircle } from "@mui/icons-material";
import { GENERAL_BLOCK } from "../../styled";
import useUserActions from "../../hooks/useUserActions";
import { act } from "react";
import { getAllStructures } from "../../api/api";
import AccountCreate from "../Accounts/AccountCreate";
const UserCreate = () => {
  const [completed, setCompleted] = useState({});
  const [structures, setAllStructures] = useState([]);
  const {
    registerUser,
    handleChange,
    activeStep,
    setActiveStep,
    userRegisterData,
    preDefinedData,
  } = useUserActions();

  const getAllStructures_ = async () => {
    try {
      const { data } = await getAllStructures();
      if (!data.structures) return [];
      return data.structures && setAllStructures(data.structures);
    } catch (error) {
      console.log("getAllStructuresForFilter", error);
    }
  };
  useEffect(() => {
    getAllStructures_();
  }, []);
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  console.log(activeStep);
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
      ...theme.applyStyles("dark", {
        borderColor: theme.palette.grey[800],
      }),
    },
  }));

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
  }));

  const steps = ["İstifadəçi yarat", "Əməkdaş yarat", "Hesab yarat"];

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <GroupAddIcon />,
      2: <Badge />,
      3: <AccountCircle />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.completed,
        style: {
          backgroundImage:
            "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
          boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
        },
      },
      {
        props: ({ ownerState }) => ownerState.completed,
        style: {
          backgroundImage:
            "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
        },
      },
      {
        props: ({ ownerState }) => ownerState.completed,
        style: {
          backgroundImage:
            "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
        },
      },
    ],
  }));

  const goBack = () => {
    setActiveStep(0);
  };
  return (
    <div>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <br />
      {allStepsCompleted() ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography>
            {activeStep === 0 ? (
              <form action="">
                <TextField
                  label="Poçt ünvanı"
                  type="email"
                  name="email"
                  value={userRegisterData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                />

                {userRegisterData?.citizenship === "notCitizen" ? (
                  <>
                    <Divider sx={{ my: 3 }} />
                    <TextField
                      label="Passport nömrəsi"
                      type="text"
                      name="passportNumber"
                      value={userRegisterData.passportNumber}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </>
                ) : (
                  <>
                    <Divider sx={{ my: 3 }} />
                    <TextField
                      label="FİN"
                      type="text"
                      name="pin"
                      value={userRegisterData?.pin}
                      onChange={handleChange}
                      inputProps={{
                        maxLength:
                          userRegisterData?.citizenship ===
                            "temporaryResident" ||
                          userRegisterData?.citizenship === "permanentResident"
                            ? 6
                            : 7,
                      }}
                      fullWidth
                      required
                    />
                    <GENERAL_BLOCK as="span" f_size="10px">
                      {userRegisterData?.citizenship === "temporaryResident" ||
                      userRegisterData?.citizenship === "permanentResident"
                        ? "maks: 6 simvol"
                        : "maks: 7 simvol"}
                    </GENERAL_BLOCK>
                  </>
                )}
                <Divider sx={{ my: 3 }} />
                <FormControl fullWidth>
                  <InputLabel>Vətəndaşlıq</InputLabel>
                  <Select
                    name="citizenship"
                    value={userRegisterData?.citizenship}
                    onChange={handleChange}
                    required
                    label="Vətəndaşlıq"
                  >
                    <MenuItem value="citizen">Vətəndaş</MenuItem>
                    <MenuItem value="temporaryResident">
                      Müvəqqəti sakin
                    </MenuItem>
                    <MenuItem value="permanentResident">Daimi sakin</MenuItem>
                    <MenuItem value="notCitizen">Vətəndaş deyil</MenuItem>
                  </Select>
                </FormControl>
              </form>
            ) : activeStep === 1 ? (
              <AddEmployee
                goBack={goBack}
                preDefinedPin={preDefinedData?.pin}
              />
            ) : (
              <AccountCreate preDefinedData={preDefinedData} />
            )}
          </Typography>
          {activeStep === 0 && (
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={() => registerUser(userRegisterData)}
                sx={{ mr: 1 }}
              >
                Yadda saxla
              </Button>
            </Box>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default UserCreate;
