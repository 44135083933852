import { api } from "./axiosInterceptor";

// ⇩ ⇩ ⇩  User & Account Requests ⇩ ⇩ ⇩
export const registerUserAccount_API = async (payload) => {
  let { type, ...rest } = payload;
  try {
    const response = await api.post(`accounts/${type}`, { ...rest });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteUser_API = async (id) => {
  try {
    const response = await api.delete(`accounts/u/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUser_API = async (id) => {
  try {
    const response = await api.get(`accounts/u/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAccount_API = async (id) => {
  try {
    const response = await api.delete(`accounts/a/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editUser_API = async (payload) => {
  let { id, ...rest } = payload;
  try {
    const response = await api.put(`accounts/u/${id}`, { ...rest });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAccountsByUserId_API = async (payload) => {
  let { id, limit, offset } = payload;
  return await api.get(`accounts/a?limit=${limit}&offset=${offset}&user=${id}`);
};

export const getUsers_API = async (payload) => {
  let { limit, offset } = payload;
  console.log(payload);
  return await api.get(
    `accounts/u?limit=${limit}&offset=${offset}&sort={"field":"createdAt","order":"desc"}`
  );
};
// ⇧ ⇧ ⇧  User & Account Requests ⇧ ⇧ ⇧
