import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { getActiveStructuresEmptyStaff } from "../services/ordersServices";

export const usePlanningVacationSchedule = (selectedYear) => {
  const [selectedIndexMonth, setSelectedIndexMonth] = useState("");
  const [selectedNameMonth, setSelectedNameMonth] = useState("");
  const [showDaysCalendar, setShowDaysCalendar] = useState(false);
  const [planningOffset, setPlanningOffset] = useState(0);
  const [planningLimit, setPlanningLimit] = useState(5);
  const [planningLoading, setPlanningLoading] = useState(false);
  const [planningFilters, setPlanningFilters] = useState([]);

  const handleFindMonth = (index, name) => {
    setSelectedIndexMonth(index);
    setSelectedNameMonth(name);
    setShowDaysCalendar(true);
  };
  const getDaysInMonth = (year, month) =>
    new Date(year, month + 1, 0).getDate();

  useEffect(() => {
    const daysInMonth = getDaysInMonth(selectedYear, selectedIndexMonth);
  }, [selectedIndexMonth, selectedYear]);
  return {
    planningLimit,
    planningOffset,
    planningFilters,
    planningLoading,
    handleFindMonth,
    showDaysCalendar,
    setPlanningLimit,
    selectedNameMonth,
    setPlanningOffset,
    selectedIndexMonth,
    setPlanningLoading,
    setPlanningFilters,
    setShowDaysCalendar,
  };
};
export const useActiveVacationSchedule = (selectedYear) => {
  const [selectedActiveIndexMonth, setSelectedActiveIndexMonth] = useState("");
  const [selectedActiveNameMonth, setSelectedActiveNameMonth] = useState("");
  const [showActiveDaysCalendar, setShowActiveDaysCalendar] = useState(false);
  const [activeOffset, setActiveOffset] = useState(0);
  const [activeLimit, setActiveLimit] = useState(5);
  const [activeLoading, setActiveLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const handleActiveFindMonth = (index, name) => {
    setSelectedActiveIndexMonth(index);
    setSelectedActiveNameMonth(name);
    setShowActiveDaysCalendar(true);
  };
  const getDaysInMonth = (year, month) =>
    new Date(year, month + 1, 0).getDate();

  useEffect(() => {
    const daysInMonth = getDaysInMonth(selectedYear, selectedActiveIndexMonth);
  }, [selectedActiveIndexMonth, selectedYear]);
  return {
    activeLimit,
    activeOffset,
    activeFilters,
    activeLoading,
    setActiveLimit,
    setActiveOffset,
    setActiveFilters,
    setActiveLoading,
    handleActiveFindMonth,
    showActiveDaysCalendar,
    selectedActiveNameMonth,
    selectedActiveIndexMonth,
    setShowActiveDaysCalendar,
  };
};

export const useVacationSchedule = () => {
  const [value, setValue] = useState("0");
  const [allStructures, setAllStructures] = useState([]);
  const [staffId, setStaffId] = useState();
  const [positionId, setPositionId] = useState();
  const [selectedYear, setSelectedYear] = useState("");
  const [nestedChildren, setNestedChildren] = useState([]);
  const [contractPosition, setContractPosition] = useState();
  const [planningToogle, setPlanningToogle] = useState(false);
  const [structureId, setStructureId] = useState("");
  const currentYear = new Date().getFullYear();
  const theme = useTheme();
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (e, value) => {
    setValue(value);
  };
  const handleChangeYear = (year) => {
    setSelectedYear(year);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const header = {
    title: "Məzuniyyət qrafiki",
    variant: "h3",
  };
  async function getPositionsData() {
    if (structureId) {
      try {
        const res = await getActiveStructuresEmptyStaff({
          structureId: structureId,
        });
        setNestedChildren(res?.data?.children);
      } catch (err) {
        console.log(err);
      }
    }
  }
  useEffect(() => {
    structureId && getPositionsData();
  }, [structureId]);
  useEffect(() => {
    const handleAllStructures = async () => {
      try {
        const response = await getActiveStructuresEmptyStaff({
          getStructures: true,
        });
        setAllStructures(response.data);
      } catch (error) {
        console.log("err", error);
      }
    };
    handleAllStructures();
  }, []);

  const years = Array.from({ length: 2099 - currentYear + 1 }, (_, index) => {
    const year = currentYear + index;
    return `${year}`;
  });
  return {
    years,
    theme,
    value,
    header,
    staffId,
    a11yProps,
    setStaffId,
    structureId,
    selectedYear,
    allStructures,
    setPositionId,
    nestedChildren,
    planningToogle,
    setStructureId,
    handleChangeTab,
    handleChangeYear,
    handleChangeIndex,
    setPlanningToogle,
    setContractPosition,
  };
};
