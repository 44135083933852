export const handleServiceCall = async (
  setLoading,
  requestSuccess,
  requestError,
  serviceFunction,
  ...payload
) => {
  try {
    setLoading(true);
    const response = await serviceFunction(...payload);
    console.log(response);
    if (response) {
      requestSuccess(response?.data);
      setLoading(false);
    }
  } catch (error) {
    console.log(error);
    requestError(error);
    setLoading(false);
  }
};
