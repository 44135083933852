import moment from "moment";
import { translationMap } from "../../../../../../components/dialogs/documentDialog/helpers";

export function orderEmpConstModifeValue(
  values,
  selectedCont,
  detailData,
  oldData
) {
  console.log(values);

  const isWeekDaysEqual = values?.workVacationTime?.offDays.every((item) => {
    const existsInNew = oldData?.offDays.includes(translationMap[item]);
    return existsInNew;
  });
  const isLaborFunctionsEqual = values?.labourPeriod?.laborFunctions.every(
    (item) => {
      const existsInNew = oldData?.laborFunctions.includes(item);
      return existsInNew;
    }
  );
  const isProvidedProtectiveEquipmentEqual =
    oldData?.providedProtectiveEquipment.every((item) => {
      const existsInNew = Array.isArray(
        values?.protection?.providedProtectiveEquipment
      )
        ? values?.protection?.providedProtectiveEquipment.includes(item)
        : values?.protection?.providedProtectiveEquipment === item;
      return existsInNew;
    });
  const isProvidedFoodEqual = oldData?.providedFood.every((item) => {
    const existsInNew = Array.isArray(values?.protection?.providedFood)
      ? values?.protection?.providedFood.includes(item)
      : values?.protection?.providedFood === item;
    return existsInNew;
  });
  const isOtherAdditionalConditionsEqual =
    Array.isArray(oldData?.otherAdditionalConditions) &&
    oldData?.otherAdditionalConditions.length > 0
      ? oldData.otherAdditionalConditions.every((item) => {
          const existsInNew =
            Array.isArray(
              values?.physicalEducationSport?.otherAdditionalConditions
            ) &&
            values.physicalEducationSport.otherAdditionalConditions.includes(
              item
            );
          return existsInNew;
        })
      : false;

  const isPersonalPropertiesUsedByEmployeeEqual =
    oldData?.personalPropertiesUsedByEmployee.length > 0
      ? oldData?.personalPropertiesUsedByEmployee.every((item) => {
          const existsInNew =
            values?.regulationPropertyRelation?.personalPropertiesUsedByEmployee.includes(
              item
            );
          return existsInNew;
        })
      : false;

  const isEqual =
    oldData?.workingCondition?.name === values?.wage?.workingCondition?.name &&
    oldData?.workingCondition?.parent ===
      values?.wage?.workingCondition?.parent;

  const allValuesEmpty = Object.values(values?.wage?.workingCondition).every(
    (v) => v === ""
  );

  return {
    staffs: Array.isArray(selectedCont) ? selectedCont : [selectedCont],
    labourContractChangeDate: detailData?.data?.startWorkYear,
    createOrder: `${values?.createOrder}`,
    updates: [
      {
        section: "informationAboutParties",
        data: {
          empWorkPlaceType:
            oldData?.empWorkPlaceType !==
              values?.informationAboutParties?.empWorkPlaceType &&
            values?.informationAboutParties?.empWorkPlaceType
              ? values?.informationAboutParties?.empWorkPlaceType
              : "",
        },
      },
      {
        section: "labourPeriod",
        data: {
          contractPeriodType:
            oldData?.contractPeriodType !==
              values?.labourPeriod?.contractPeriodType &&
            values?.labourPeriod?.contractPeriodType
              ? values?.labourPeriod?.contractPeriodType
              : "",
          probationPeriodType:
            oldData?.probationPeriodType !==
              values?.labourPeriod?.probationPeriodType &&
            values?.labourPeriod?.probationPeriodType
              ? values?.labourPeriod?.probationPeriodType
              : "",
          probationPeriod:
            oldData?.probationPeriod !==
              parseInt(values?.labourPeriod?.probationPeriod) &&
            !isNaN(parseInt(values?.labourPeriod?.probationPeriod))
              ? parseInt(values?.labourPeriod?.probationPeriod)
              : "",
          reasonOfTemporaryContract:
            oldData?.reasonOfTemporaryContract !==
              values?.labourPeriod?.reasonOfTemporaryContract &&
            values?.labourPeriod?.contractPeriodType !== "indefinite" &&
            !isNaN(values?.labourPeriod?.reasonOfTemporaryContract)
              ? values?.labourPeriod?.reasonOfTemporaryContract
              : "",
          contractStartDate:
            oldData?.contractStartDate !==
              values?.labourPeriod?.contractStartDate &&
            values?.labourPeriod?.contractStartDate
              ? moment(values?.labourPeriod?.contractStartDate).format(
                  "MM/DD/yyyy"
                )
              : "",
          contractEndDate:
            oldData?.contractEndDate !==
              values?.labourPeriod?.contractEndDate &&
            values?.labourPeriod?.contractPeriodType !== "indefinite" &&
            values?.labourPeriod?.contractEndDate
              ? moment(values?.labourPeriod?.contractEndDate).format(
                  "MM/DD/yyyy"
                )
              : "",
          jobStartDate:
            oldData?.jobStartDate !== values?.labourPeriod?.jobStartDate &&
            values?.labourPeriod?.jobStartDate
              ? moment(values?.labourPeriod?.jobStartDate).format("MM/DD/yyyy")
              : "",
          laborFunctions: isLaborFunctionsEqual
            ? ""
            : values?.labourPeriod?.laborFunctions.length > 0
            ? values?.labourPeriod?.laborFunctions
            : "",
        },
      },
      {
        section: "wage",
        data: {
          paymentOfWork:
            oldData?.paymentOfWork !== values?.wage?.paymentOfWork &&
            values?.wage?.paymentOfWork
              ? values?.wage?.paymentOfWork
              : "",
          wage:
            oldData?.wage !== parseFloat(values?.wage?.wage) &&
            !isNaN(parseFloat(values?.wage?.wage))
              ? parseFloat(values?.wage?.wage)
              : "",
          bonusPeriod:
            oldData?.bonusPeriod !== values?.wage?.bonusPeriod &&
            values?.wage?.bonusPeriod
              ? values?.wage?.bonusPeriod
              : "",
          givenBonus:
            oldData?.givenBonus !== parseFloat(values?.wage?.givenBonus) &&
            !isNaN(parseFloat(values?.wage?.givenBonus))
              ? parseFloat(values?.wage?.givenBonus)
              : "",
          partTimeWorkHour:
            oldData?.partTimeWorkHour !==
              parseInt(values?.wage?.partTimeWorkHour) &&
            !isNaN(parseInt(values?.wage?.partTimeWorkHour))
              ? parseInt(values?.wage?.partTimeWorkHour)
              : "",
          overtime:
            oldData?.overtime !== parseInt(values?.wage?.overtime) &&
            !isNaN(parseInt(values?.wage?.overtime))
              ? parseInt(values?.wage?.overtime)
              : "",
          workingCondition: isEqual
            ? ""
            : allValuesEmpty
            ? ""
            : values?.wage?.workingCondition,
          selectedBank:
            oldData?.selectedBank !== values?.wage?.selectedBank &&
            values?.wage?.selectedBank
              ? values?.wage?.selectedBank
              : "",
          conditionsOfSalaryPayment:
            oldData?.conditionsOfSalaryPayment !==
              values?.wage?.conditionsOfSalaryPayment &&
            values?.wage?.conditionsOfSalaryPayment
              ? values?.wage?.conditionsOfSalaryPayment
              : "",
          paymentPeriod:
            oldData?.paymentPeriod !== parseInt(values?.wage?.paymentPeriod) &&
            !isNaN(parseInt(values?.wage?.paymentPeriod))
              ? parseInt(values?.wage?.paymentPeriod)
              : "",
          paymentPeriodDate:
            oldData?.paymentPeriodDate !== values?.wage?.paymentPeriodDate &&
            values?.wage?.paymentPeriodDate
              ? values?.wage?.paymentPeriodDate
              : "",
          freelanceWorkIncomeType:
            oldData?.freelanceWorkIncomeType !==
              values?.wage?.freelanceWorkIncomeType &&
            values?.wage?.freelanceWorkIncomeType
              ? values?.wage?.freelanceWorkIncomeType
              : "",
          freelanceWorkIncome:
            oldData?.freelanceWorkIncome !==
              values?.wage?.freelanceWorkIncome &&
            values?.wage?.freelanceWorkIncome
              ? values?.wage?.freelanceWorkIncome
              : "",
        },
      },
      {
        section: "protection",
        data: {
          providedProtectiveEquipment: isProvidedProtectiveEquipmentEqual
            ? ""
            : Array.isArray(values?.protection?.providedProtectiveEquipment)
            ? values?.protection?.providedProtectiveEquipment.length > 0
              ? values?.protection?.providedProtectiveEquipment
              : ""
            : values?.protection?.providedProtectiveEquipment
            ? [values?.protection?.providedProtectiveEquipment]
            : "",
          providedFood: isProvidedFoodEqual
            ? ""
            : Array.isArray(values?.protection?.providedFood)
            ? values?.protection?.providedFood.length > 0
              ? values?.protection?.providedFood
              : ""
            : values?.protection?.providedFood
            ? [values?.protection?.providedFood]
            : "",

          instructionalPeriod:
            oldData?.instructionalPeriod !==
              values?.protection?.instructionalPeriod &&
            values?.protection?.instructionalPeriod
              ? values?.protection?.instructionalPeriod
              : "",
        },
      },
      {
        section: "workVacationTime",
        data: {
          offDays: isWeekDaysEqual
            ? ""
            : values?.workVacationTime?.offDays.length > 0
            ? values?.workVacationTime?.offDays
            : "",
          workScheduleId:
            oldData?.workScheduleId !==
              values?.workVacationTime?.workScheduleId &&
            values?.workVacationTime?.workScheduleId
              ? values?.workVacationTime?.workScheduleId
              : "",
          workScheduleAsText:
            oldData?.workScheduleAsText !==
              values?.workVacationTime?.workScheduleAsText &&
            values?.workVacationTime?.workScheduleAsText
              ? values?.workVacationTime?.workScheduleAsText
              : "",
          assignedWorkTurnValue:
            oldData?.assignedWorkTurnValue !==
              values?.workVacationTime?.assignedWorkTurnValue &&
            values?.workVacationTime?.assignedWorkTurnValue
              ? values?.workVacationTime?.assignedWorkTurnValue
              : "",
        },
      },
      {
        section: "vacation",
        data: {
          vacationDaysCount:
            oldData?.vacationDaysCount !==
              values?.vacation?.vacationDaysCount &&
            values?.vacation?.vacationDaysCount
              ? values?.vacation?.vacationDaysCount
              : "",
          forSeniorityLevel:
            oldData?.forSeniorityLevel !==
              values?.vacation?.forSeniorityLevel &&
            !isNaN(values?.vacation?.forSeniorityLevel)
              ? values?.vacation?.forSeniorityLevel
              : "",
          aboutEventOrPayer:
            oldData?.aboutEventOrPayer !==
              values?.vacation?.aboutEventOrPayer &&
            values?.vacation?.aboutEventOrPayer
              ? values?.vacation?.aboutEventOrPayer
              : "",
          forParental:
            oldData?.forParental !== values?.vacation?.forParental &&
            !isNaN(values?.vacation?.forParental)
              ? values?.vacation?.forParental
              : "",
          forCollectiveAgreement:
            oldData?.forCollectiveAgreement !==
              parseInt(values?.vacation?.forCollectiveAgreement) &&
            !isNaN(parseInt(values?.vacation?.forCollectiveAgreement))
              ? parseInt(values?.vacation?.forCollectiveAgreement)
              : "",
          socialHouseholdBenefit:
            oldData?.socialHouseholdBenefit !==
              parseInt(values?.vacation?.socialHouseholdBenefit) &&
            !isNaN(parseInt(values?.vacation?.socialHouseholdBenefit))
              ? parseInt(values?.vacation?.socialHouseholdBenefit)
              : "",
        },
      },
      {
        section: "physicalEducationSport",
        data: {
          physicalEducationAndSportsConditions:
            oldData?.physicalEducationAndSportsConditions !==
              values?.physicalEducationSport
                ?.physicalEducationAndSportsConditions &&
            values?.physicalEducationSport?.physicalEducationAndSportsConditions
              ? values?.physicalEducationSport
                  ?.physicalEducationAndSportsConditions
              : "",
          otherAdditionalConditions: isOtherAdditionalConditionsEqual
            ? ""
            : oldData?.otherAdditionalConditions !==
                values?.physicalEducationSport?.otherAdditionalConditions &&
              values?.physicalEducationSport?.otherAdditionalConditions
            ? values?.physicalEducationSport?.otherAdditionalConditions
            : "",
        },
      },
      {
        section: "socialProtection",
        data: {
          monthlyInsuranceFee:
            oldData?.monthlyInsuranceFee !==
              parseFloat(values?.socialProtection?.monthlyInsuranceFee) &&
            !isNaN(parseFloat(values?.socialProtection?.monthlyInsuranceFee))
              ? parseFloat(values?.socialProtection?.monthlyInsuranceFee)
              : "",
          extraSumInsured:
            oldData?.extraSumInsured !==
              parseFloat(values?.socialProtection?.extraSumInsured) &&
            !isNaN(parseFloat(values?.socialProtection?.extraSumInsured))
              ? parseFloat(values?.socialProtection?.extraSumInsured)
              : "",
        },
      },
      {
        section: "regulationPropertyRelation",
        data: {
          shareOwnershipOfEnterprise:
            oldData?.shareOwnershipOfEnterprise !==
              parseFloat(
                values?.regulationPropertyRelation?.shareOwnershipOfEnterprise
              ) &&
            !isNaN(
              parseFloat(
                values?.regulationPropertyRelation?.shareOwnershipOfEnterprise
              )
            )
              ? parseFloat(
                  values?.regulationPropertyRelation?.shareOwnershipOfEnterprise
                )
              : "",
          dividendAmount:
            oldData?.dividendAmount !==
              parseFloat(values?.regulationPropertyRelation?.dividendAmount) &&
            !isNaN(
              parseFloat(values?.regulationPropertyRelation?.dividendAmount)
            )
              ? parseFloat(values?.regulationPropertyRelation?.dividendAmount)
              : "",
          personalPropertiesUsedByEmployee:
            isPersonalPropertiesUsedByEmployeeEqual
              ? ""
              : values?.regulationPropertyRelation
                  ?.personalPropertiesUsedByEmployee.length > 0
              ? values?.regulationPropertyRelation
                  ?.personalPropertiesUsedByEmployee
              : "",
        },
      },
      {
        section: "administrative",
        data: {
          oldRecruiterId:
            oldData?.recruiterId !== values?.administrative?.oldRecruiterId &&
            values?.administrative?.oldRecruiterId
              ? values?.administrative?.oldRecruiterId
              : "",
        },
      },
      {
        section: "jobRelated",
        data: {
          old: detailData?.data?.staffId?._id,
          new: detailData?.data?.vacantStaffId?._id,
        },
      },
    ].filter((update) => {
      const { data } = update;

      return Object.keys(data).some((key) => {
        const value = data[key];
        const allEmptyStrings =
          typeof value === "object" &&
          Object.values(value).every((v) => v === "");

        return (
          value !== "" &&
          !(Array.isArray(value) && value.length === 0) &&
          !(
            typeof value === "object" &&
            (Object.keys(value).length === 0 || allEmptyStrings)
          )
        );
      });
    }),
  };
}
