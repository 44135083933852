import {
  Button,
  CardContent,
  Grid,
  Card as MuiCard,
  Typography,
  styled,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";

import Table from "../../table/Table";

import { Person } from "@mui/icons-material";
import InputIcon from "@mui/icons-material/Input";
import { useNavigate } from "react-router-dom";
import { getEmployeeRequestsHr } from "../../../api/employeeRequests/employeeRequests";
import {
  PUBLIC_URL,
  OPERATORS_CONTAINING,
  SYSTEM_CONFIRMATION_STATUS_FOR_FILTER,
  VACATION_TYPES,
} from "../../../constants";
import Column from "../../../models/table/Column";
import { getDownloadVacationrRequest } from "../../../services/vacation/VacationRequest";
import { downloadFile } from "../../../utils/downloadCSV";
import { getStaticFilterValues } from "../../../utils/forFilterDropdown/getStructureUtils";
import {
  getDescendantProp,
  normalizeDate,
  vacationConverter,
} from "../../../utils/helperFunctions";
import StatusGenerator from "../../table/StatusGenerator";
import { removeEmptyStrings } from "../../../utils/removeEmptyString";

const Card = styled(MuiCard)(spacing);

const VacationsRequestsTable = ({ id }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState([]);
  const [total, setTotal] = useState(0);
  const requestType = "VacationRequest";

  const [currentRequests, setCurrentRequests] = useState([]);
  let filterModel = [
    {
      column: "docModel",
      operator: "equalsTo",
      value: requestType,
    },
  ];

  if (id) {
    filterModel.push({
      column: "baseStructure",
      operator: "equalsTo",
      value: id,
    });
  }
  useEffect(() => {
    paginationFetchingHandler();
  }, [filters, limit, offset, id]);

  const paginationFetchingHandler = async () => {
    if (!filters.length > 0) {
      const encoded = encodeURIComponent(JSON.stringify(filterModel));
      await getVacations(encoded);
    } else {
      const encodedFilter = encodeURIComponent(
        JSON.stringify([filterModel, ...filters])
      );
      await getVacations(encodedFilter);
    }
  };

  const downlaodHandler = async (columnsForExport) => {
    try {
      const colsExp = [];

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        colsExp.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodeColumns = encodeURIComponent(JSON.stringify(colsExp));
      const encodeFilters = encodeURIComponent(
        JSON.stringify([
          {
            column: "docModel",
            operator: "equalsTo",
            value: "VacationRequest",
          },
          ...filters,
        ])
      );
      const resp = await getDownloadVacationrRequest({
        columns: encodeColumns,
        filters: encodeFilters,
        isWithFilter: filters.length > 0,
      });
      if (resp) downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }

    // try {
    //   const encodedFilter =
    //     filters.length > 0
    //       ? encodeURIComponent(JSON.stringify(filters))
    //       : undefined;

    //   const {data} = await getDownloadVacationrRequest(encodedFilter);

    // } catch (error) {
    //   console.log("err", error);
    // }
  };

  const handleIconClick = (params, action) => {
    const rowId = params.id; // Assuming your data has an 'id' field for each row
    switch (action) {
      case "visibility":
        navigate(`/employees/${rowId}/view`);
        break;
      case "edit":
        navigate(`/employees/${rowId}`);
        break;
      case "delete":
        break;
      default:
        break;
    }
  };

  const getVacations = async (filters = undefined) => {
    try {
      setIsLoading(true);
      const vacationsResponse = await getEmployeeRequestsHr(
        limit,
        offset,
        filters
      );

      if (vacationsResponse && vacationsResponse.data) {
        setTotal(vacationsResponse.data.total);
        // const modifiedResponse = vacationsResponse.data.requests.map(
        //   (item, index) => {
        //     const statusEmployee = item.approvementChain.find(
        //       (user) => user.status === "pending"
        //     );

        //     return {
        //       ...item,
        //       id: item._id,
        //       name:
        //         item.originalRequest.employee.firstname +
        //         " " +
        //         item.originalRequest.employee.lastname,
        //       startDate: new Date(item.originalRequest?.startDate),
        //       endDate: new Date(item.originalRequest?.endDate),
        //       structure: item.decisionChain?.structure?.organization?.name,
        //       department: item.decisionChain?.topCompanyPartRel?.source?.name,
        //       subStructure: item.decisionChain?.companyPartRel?.source?.name,
        //       pendingEmployee: statusEmployee?.employee
        //         ? statusEmployee.employee.firstname +
        //           " " +
        //           statusEmployee.employee.lastname
        //         : "Təsdiq olunub",
        //     };
        //   }
        // );
        setCurrentRequests(vacationsResponse.data.requests);
      }
    } catch (error) {
      console.log("err", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Status menu
  const [chainEl, setChainEl] = useState(null);
  const [approvementChainStatus, setApprovementChainStatus] = useState([]);

  const handleMenuClick = (event, action, params) => {
    setChainEl(event.currentTarget);
    setApprovementChainStatus(params);
  };

  const handleMenuClose = (action) => {
    setChainEl(null);
    setApprovementChainStatus([]);
  };

  //   Request status
  const requestStatus = [
    { keyword: "approved", title: "Təsdiq olunub", color: "#008D0E" },
    {
      keyword: "pendingChainApprove",
      title: "Qərar gözlənilir",
      color: "#4D9DF8",
    },
    {
      keyword: "pendingOrder",
      title: "Əmr gözlənilir",
      color: "#4D9DF8",
    },
    {
      keyword: "pendingOrderApprove",
      title: "Əmr imza gözlənilir",
      color: "#4D9DF8",
    },
    {
      keyword: "pendingDirectorApprove",
      title: "Qərar gözlənilir",
      color: "#4D9DF8",
    },
    {
      keyword: "rejectedByChainUser",
      title: "Menecer imtina edib",
      color: "#D32F2F",
    },
    {
      keyword: "rejectedByDirector",
      title: "Rəhbər imtina edib",
      color: "#D32F2F",
    },
    {
      keyword: "approvedByDirector",
      title: "Rəhbər təsdiq edib",
      color: "#008D0E",
    },
    { keyword: "updatedByCreator", title: "Yeniləndi", color: "#8B25F1" },
    {
      keyword: "reRequestedToManager",
      title: "Yönləndirilib rəhbərə",
      color: "#F57C00",
    },
    {
      keyword: "reRequestedToCreator",
      title: "Yönləndirilib əməkdaşa",
      color: "#CBAEE8",
    },
    { keyword: "cancelledByHr", title: "Ləğv olunub", color: "#530000" },
  ];

  const column = [
    new Column({
      path: "requestNO",
      headerName: "Sorğu nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "originalRequest.employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
      hasCellButton: true,
      cellButtonProps: {
        icon: <Person />,
        value: (row) => {
          return getDescendantProp(row, "originalRequest.employee.id");
        },
        onClick: (value) => {
          window.open(`/employees/${value}/view`);
        },
      },
    }),
    new Column({
      path: "originalRequest.employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "originalRequest.vacationType",
      headerName: "Məzuniyyət növü",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: VACATION_TYPES,
      getValue: (row, column) =>
        vacationConverter(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "originalRequest.startDate",
      headerName: "Başlanğıc tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "originalRequest.endDate",
      headerName: "Bitmə tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "baseStructure.organization.name",
      headerName: "Təşkilat",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterColumnKey: "baseStructure._id",
      // fetchFilterValues: getAllStructuresForFilter,
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "vacation",
      keyForResponseDataFromFilter: "baseStructures",
    }),
    new Column({
      path: "topPartOfStructure.source.name",
      headerName: "Struktur",
      filterColumnKey: "topPartOfStructure._id",
      filterType: OPERATORS_CONTAINING.OPTION,
      // fetchFilterValues: getTopPartsForFilter,
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "vacation",
      dependencieKeysOfFilter: {
        structures: ["baseStructure._id"],
      },
      keyForResponseDataFromFilter: "topPartOfStructures",
    }),
    new Column({
      path: "subStructure.source.name",
      headerName: "Sub-struktur",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterColumnKey: "subStructure._id",
      // fetchFilterValues: getPartsForFilter,
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "vacation",
      dependencieKeysOfFilter: {
        structures: ["baseStructure._id"],
        topPartOfStructures: ["topPartOfStructure._id"],
      },
      keyForResponseDataFromFilter: "subStructures",
    }),
    new Column({
      path: "position.source.name",
      headerName: "Vəzifə",
      filterType: OPERATORS_CONTAINING.OPTION,
      fetchFilterValuesV2: getStaticFilterValues,
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: SYSTEM_CONFIRMATION_STATUS_FOR_FILTER,
      renderCell: (row, column) => {
        const approvers = [];

        if (row.status === "pendingOrderApprove") {
          row.originalRequest?.contract?.verifiers?.map((item, index) => {
            approvers.push({
              index: index + 1,
              employee: item.employee.firstname + item.employee.lastname,
              status: item.status,
            });
          });
        } else {
          row.approvementChain?.map((item, index) => {
            approvers.push({
              index: index + 1,
              employee:
                item.staff.employee[0].firstname +
                item.staff.employee[0].lastname,
              status: item.status,
            });
          });
        }

        return (
          <StatusGenerator
            approvement={approvers} // it depends on coming data's structure.  other path maybe like this "row.example"
            value={getDescendantProp(row, column.path)} // this props for set status type
            docType={"systemConfirmation"} // depending on the type of page  you may write only these strings "systemConfirmation","dissmisal","generalDocuments","structureChangeRequest"
          />
        );
      },
    }),
  ];

  return (
    <div>
      <CardContent>
        <Grid>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              mb: 5,
            }}
            variant="h3"
          >
            Cari Məzuniyyət Sorğuları
          </Typography>
          <Grid
            sx={{ display: "flex", justifyContent: "flex-end", mt: -3, mb: 5 }}
          >
            <Button
              onClick={() => {
                navigate("/hr-vacation-requests/detailed");
              }}
              variant="contained"
              startIcon={<InputIcon />}
            >
              Məzuniyyətlər səhifəsinə keç
            </Button>
          </Grid>
          <Grid
            sx={{
              width: "100%",
            }}
          >
            <Table
              columns={column}
              data={currentRequests}
              totalRows={total}
              offset={offset}
              setOffset={(value) => setOffset(value)}
              limit={limit}
              setLimit={(value) => setLimit(value)}
              isLoading={isLoading}
              applyFiltersHandler={(filters) => setFilters(filters)}
              cancelAppliedFilters={() => setFilters([])}
              downloadHandler={(columns) => downlaodHandler(columns)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );
};

export default VacationsRequestsTable;
