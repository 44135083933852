import { handleApiError } from "../../api/changeRequests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addOvertimeApplication_API,
  addPersonalOvertimeApplication_API,
  deleteBulkOvertimeApplication_API,
  deleteEmployeeOvertimeApplication_API,
  getListOvertimeApplication_API,
  postBulkOvertimeApplication_API,
  putBulkOvertimeApplication_API,
} from "../../api/overtimeRequests";
import { removeEmptyStrings } from "../../utils/removeEmptyString";

export const getListOvertimeApplication_SERVICE = async (params) => {
  try {
    const response = await getListOvertimeApplication_API(params);
    return response;
  } catch (err) {
    handleApiError(err);
  }
};
export const postBulkOvertimeApplication_SERVICE = async (data) => {
  try {
    const response = await postBulkOvertimeApplication_API(data);
    toast.success("Uğurla əlavə edildi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    handleApiError(err);
  }
};
export const addOvertimeApplication_SERVICE = async (data) => {
  try {
    const response = await addOvertimeApplication_API(data);
    return response;
  } catch (err) {
    handleApiError(err);
  }
};
export const addPersonalOvertimeApplication_SERVICE = async (data) => {
  try {
    const response = await addPersonalOvertimeApplication_API(data);
    toast.success("Uğurla əlavə edildi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    handleApiError(err);
  }
};
export const putBulkOvertimeApplication_SERVICE = async (
  id,
  data,
  newEmployeeArr,
  getData,
  close
) => {
  try {
    const response = await putBulkOvertimeApplication_API(id, data);
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    if (newEmployeeArr.length > 0 && response?.status === 201) {
      await Promise.all(
        newEmployeeArr.map(async (emp) => {
          const result = removeEmptyStrings(emp);
          try {
            const res = await addOvertimeApplication_SERVICE(result);
            if (res?.status === 201) {
              getData();
              close();
            }
            return res;
          } catch (error) {
            handleApiError(error);
          }
        })
      );
    } else {
      if (response?.status === 201) {
        getData();
        close();
      }
    }
    return response;
  } catch (err) {
    handleApiError(err);
  }
};
export const deleteBulkOvertimeApplication_SERVICE = async (id) => {
  try {
    const response = await deleteBulkOvertimeApplication_API(id);
    toast.success("Uğurla silindi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    handleApiError(err);
  }
};
export const deleteEmployeeOvertimeApplication_SERVICE = async (id) => {
  try {
    const response = await deleteEmployeeOvertimeApplication_API(id);
    toast.success("Uğurla əməkdaş silindi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    handleApiError(err);
  }
};
