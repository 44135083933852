class Column {
  // constructor(path, headerName, searchFetchingHandler) {
  constructor({
    path,
    headerName,
    rowData = false,
    groupHeader = null,
    headerColor,
    isShow = true,
    isSubHeader = false,
    subHeader = [],
    isStaticColumn = false,
    disableColSearch = false,
    isWithFilter = true,
    unhidableColumn = false,
    renderCell = undefined,
    getValue = undefined,
    filterColumnKey = undefined,
    filterType,
    filterValues = undefined,
    hasCellButton = false,
    colSpan = undefined,
    rowSpan = undefined,
    cellButtonProps = {
      icon: <div></div>,
      value: "",
      onClick: (row) => {},
    },
    forExportIsArr = false,
    combinedFields = [],
    fetchFilterValues = undefined,
    fetchFilterValuesV2 = undefined,
    // this is for fetchFilterValues
    // send this key first parameter of dataGridName fetchFilterValuesV2(filterPageName)
    dataGridName = "",
    // this is for fetchFilterValuesV2.
    // before fetching looking this keys in filters(column).
    // if has, then taking id's and send to fetch function
    dependencieKeysOfFilter = {
      structures: "",
      topPartOfStructures: "",
    },
    widthOfColumn = undefined,
    keyForResponseDataFromFilter,
  }) {
    this.id = Date.now().toString(36) + Math.random().toString(36).substr(2);
    this.path = path;
    this.headerName = headerName;
    this.rowData = rowData;
    this.groupHeader = groupHeader;
    this.headerColor = headerColor;
    this.isShow = isShow;
    this.isSubHeader = isSubHeader;
    this.isStaticColumn = isStaticColumn;
    this.disableColSearch = disableColSearch;
    this.colSpan = colSpan;
    this.rowSpan = rowSpan;
    this.subHeader = subHeader;
    // .renderCell(row, column)
    this.renderCell = renderCell;
    this.isWithFilter = isWithFilter;
    this.unhidableColumn = unhidableColumn;
    this.getValue = getValue;
    this.filterType = filterType;
    // if filterType is option that will {key: , name: }
    this.filterValues = filterValues;
    // if there has function then  values fetching and returning data
    // data must be type of { key: ... , name: ...}
    this.fetchFilterValues = fetchFilterValues;
    // this.searchFetchingHandler = searchFetchingHandler;
    this.hasCellButton = hasCellButton;
    this.cellButtonProps = cellButtonProps;
    this.filterColumnKey = filterColumnKey;
    this.forExportIsArr = forExportIsArr;
    this.combinedFields = combinedFields;
    this.fetchFilterValuesV2 = fetchFilterValuesV2;
    this.dependencieKeysOfFilter = dependencieKeysOfFilter;
    this.dataGridName = dataGridName;
    this.widthOfColumn = widthOfColumn;
    this.keyForResponseDataFromFilter = keyForResponseDataFromFilter;
  }
}

export default Column;
