import { Person, RemoveRedEye } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import OrderDialog from "../../../components/companyStructure/orderDialog";
import DocumentDialog from "../../../components/dialogs/documentDialog/DocumentDialog";
import ContractDialog from "../../../components/dialogs/documentDialog/ContractDialog";
import OperatorGenerator from "../../../components/table/OperatorGenerator";
import StatusGenerator from "../../../components/table/StatusGenerator";
import Table from "../../../components/table/Table";
import {
  // BASE_URL,
  OPERATORS_CONTAINING,
  PUBLIC_URL,
  STAFFS_STATUS_TYPE,
  STAFF_DROPDOWN_VALUE,
  STAFF_STATUS_FOR_FILTER,
} from "../../../constants";
import Column from "../../../models/table/Column";
import Operator from "../../../models/table/Operator";
import { getContract } from "../../../services/documentations/ContractServices";
import * as employeeServices from "../../../services/employees/EmployeeServices";
import {
  getCurrStaffTracking,
  getDownloadStaffCSV,
  getStaffTracking,
} from "../../../services/staffTracking/StaffTrackingServices";
import { downloadFile } from "../../../utils/downloadCSV";
import {
  // getAllStructuresForFilter,
  // getPartsForFilter,
  // getPositionsForFilter,
  getStaticFilterValues,
  // getTopPartsForFilter,
} from "../../../utils/forFilterDropdown/getStructureUtils";
import { getDescendantProp } from "../../../utils/helperFunctions";
import useDialog from "../../../hooks/useDialog";

export default function AppointmentTable() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  // const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const contractDialogHandle = useDialog(false);
  const [contractId, setContractId] = useState(null);

  const [openModalOrder, setOpenModalOrder] = useState(false);
  const [orderData, setOrderData] = useState();
  const [orderId, setOrderId] = useState();
  const [isCreated, setIsCreated] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  // const navigate = useNavigate();

  useEffect(() => {
    paginationHandler();
  }, [filters, limit, offset, isCreated]);

  const paginationHandler = async () => {
    if (!filters.length > 0) {
      await fetchData();
      return;
    } else {
      const modified = filters.map((item) => {
        if (item.column === "originalRequest.staff.staffUnit") {
          return {
            ...item,
            value: +item.value,
          };
        }
        return item;
      });
      const encodedFilter = encodeURIComponent(JSON.stringify(modified));
      await fetchData(encodedFilter);
    }
  };

  //fetch
  const fetchData = async (filters = undefined) => {
    try {
      setIsLoading(true);
      const payload = {
        offset: offset,
        limit: limit,
        filter: filters,
      };
      const { data } = await getStaffTracking(payload);
      // const arr =
      //   data && data.requests.map((item) => ({ ...item, id: item._id }));

      setData(data?.requests);
      setTotal(data?.total);
      setIsLoading(false);
    } catch (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  const handleOpenDialog = (request) => {
    if (request.contracts?.length) {
      const lastContract = request.contracts.pop();
      setContractId(lastContract._id);
      setShowDialog(true);
    }
  };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };
  //columns and columns rendering handlers
  const columns = [
    new Column({
      path: "#",
      headerName: "Operator",
      widthOfColumn: 200,
      filterType: OPERATORS_CONTAINING.TEXT,
      disableColSearch: true,
      // isStaticColumn: true,
      unhidableColumn: true,
      renderCell: (row, column) => {
        return operatorsRenderHandler(row, column);
      },
    }),
    new Column({
      path: "baseStructure.organization.name",
      headerName: "Təşkilat",
      filterColumnKey: "baseStructure",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "employment-request",
      keyForResponseDataFromFilter: "baseStructures",
      filterType: OPERATORS_CONTAINING.OPTION,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, false);
      },
    }),
    new Column({
      path: "topPartOfStructure.source.name",
      headerName: "Struktur",
      filterColumnKey: "topPartOfStructure",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "employment-request",
      dependencieKeysOfFilter: {
        structures: ["baseStructures"],
      },
      keyForResponseDataFromFilter: "topPartOfStructures",
      filterType: OPERATORS_CONTAINING.IN,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, false);
      },
    }),
    new Column({
      path: "subStructure.source.name",
      headerName: "Sub-struktur",
      filterColumnKey: "subStructure",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "employment-request",
      dependencieKeysOfFilter: {
        structures: ["baseStructures"],
        topPartOfStructure: ["topPartOfStructure"],
      },
      keyForResponseDataFromFilter: "subStructures",
      filterType: OPERATORS_CONTAINING.IN,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, false);
      },
    }),
    new Column({
      path: "position.source.name",
      headerName: "Vəzifə",
      filterColumnKey: "position",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "employment-request",
      dependencieKeysOfFilter: {
        structures: ["baseStructures"],
        topPartOfStructure: ["topPartOfStructure", "subStructures"],
      },
      keyForResponseDataFromFilter: "positions",
      filterType: OPERATORS_CONTAINING.IN,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, false);
      },
    }),
    new Column({
      path: "originalRequest.staff.staffUnit",
      headerName: "Ştat",
      // filterColumnKey: "position",
      filterValues: STAFF_DROPDOWN_VALUE,
      // fetchFilterValues: getTopPartsForFilter,
      filterType: OPERATORS_CONTAINING.OPTION,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, false);
      },
    }),
    new Column({
      path: "originalRequest.employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
      hasCellButton: true,
      cellButtonProps: {
        icon: <Person />,
        onClick: (value) => window.open(`/employees/${value}/view`),
        value: (row) => {
          return getDescendantProp(row, "originalRequest.employee.id");
        },
      },
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, false);
      },
    }),
    new Column({
      path: "originalRequest.employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, false);
      },
    }),
    new Column({
      path: "originalRequest.employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, false);
      },
    }),
    new Column({
      path: "originalRequest.staff.status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: STAFF_STATUS_FOR_FILTER,
      renderCell: (row, column) => {
        const approvers = [];
        if (
          row?.originalRequest?.staff?.status === "pending_contract_approve"
        ) {
          row?.originalRequest?.contract?.verifiers?.map((item, index) => {
            return approvers.push({
              index: index + 1,
              employee:
                item?.employee?.firstname + " " + item?.employee?.lastname,
              status: item?.status,
            });
          });
        } else if (
          row.originalRequest?.staff?.status === "pending_order_approve"
        ) {
          row.originalRequest?.staff?.labourOrder?.verifiers?.map(
            (item, index) => {
              return approvers.push({
                index: index + 1,
                employee:
                  item?.employee?.firstname + " " + item?.employee?.lastname,
                status: item?.status,
              });
            }
          );
        } else {
          row.approvementChain.map((item, index) => {
            return approvers.push({
              index: index + 1,
              employee:
                row?.originalRequest?.staff?.contract?.data?.employeeFullName,
              status: row?.originalRequest?.staff?.status,
            });
          });
        }
        return (
          <StatusGenerator
            approvement={approvers}
            value={getDescendantProp(row, column.path)}
            docType={"staffStatus"}
          />
        );
      },
    }),
  ];

  const allContExportHandler = async (columnsForExport) => {
    try {
      const forExport = [];
      const isWithFilter =
        filters.length > 0 && filters[0]?.column?.length > 0 ? true : false;
      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;

        forExport.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodedColumns = encodeURIComponent(JSON.stringify(forExport));
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));
      const resp = await getDownloadStaffCSV({
        isWithFilter: isWithFilter,
        columns: encodedColumns,
        filter: encodedFilter,
      });
      resp && downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };
  const handleStatusAction = (status, employeeId, staffId) => {
    switch (status) {
      case STAFFS_STATUS_TYPE.PENDING_CONTRACT:
        createContract(employeeId, staffId);
        break;
      case STAFFS_STATUS_TYPE.PENDING_ORDER:
        handleOpenOrderDialog(staffId);
        break;
      default:
        break;
    }
  };

  const operatorsRenderHandler = (row, column) => {
    const status = getDescendantProp(
      row,
      "originalRequest.staff.status",
      false
    );

    const employeeId = getDescendantProp(
      row,
      "originalRequest.employee._id",
      false
    );
    const staffId = getDescendantProp(row, "staff", false);
    // const checkLoggedUser =
    //   row.originalRequest.staff.structure.recruiter.id === currentEmployee.id;

    let title = "";
    let bkColor = "";

    switch (status) {
      // case STAFFS_STATUS_TYPE.PENDING_APPLICATION:
      //   bkColor = "rgba(75,134,206, 1)";
      //   title = "Ərizəni təsdiq et";
      //   break;
      case STAFFS_STATUS_TYPE.PENDING_CONTRACT:
        bkColor = "rgba(61,101,80,0.8)";
        title = "Müqavilə yarat";
        break;
      case STAFFS_STATUS_TYPE.PENDING_CONTRACT_APPROVE:
        bkColor = "rgba(198, 40, 40, 0.5)";
        // title = checkLoggedUser ? "Müqavilə imzala" : "";
        break;
      case STAFFS_STATUS_TYPE.PENDING_ORDER:
        bkColor = "rgba(155, 169, 176, 0.5)";
        title = "Əmr yarat";
        break;
      case STAFFS_STATUS_TYPE.PENDING_ORDER_APPROVE:
        // eslint-disable-next-line no-unused-vars
        bkColor = "rgba(136, 87, 123, 0.8)";
        // title = checkLoggedUser ? "Əmr imzala" : "";
        break;
      default:
        break;
    }

    const operators = [
      new Operator({
        icon: <RemoveRedEye />,
        onClick: () => {
          handleOpenDialog(row);
        },
        type: 0,
      }),
      ((status !== STAFFS_STATUS_TYPE.PENDING_CONTRACT && title) ||
        (status !== STAFFS_STATUS_TYPE.PENDING_ORDER && title)) &&
        new Operator({
          title: title,
          type: 0,
          onClick: () => handleStatusAction(status, employeeId, staffId),
        }),
    ];

    return <OperatorGenerator operators={operators} />;
    // return (
    //   <div className="d-flex justify-content-center">
    //     <div className="me-2">
    //       <OperatorGenerator operators={operators} />
    //     </div>
    //     {/* <DocumentDialog
    //       documentId={row._id}
    //       disableSigning={true}
    //       fetchHandler={getCurrStaffTracking}
    //       // buttonGroup={button_group}
    //       // refreshPage={paginationFetchingHandler}
    //     /> */}
    //   </div>
    // );
  };
  const openInNewTab = (url) => {
    const newTab = window.open(url, "_blank");
    if (newTab) {
      newTab.opener = null;
    }
  };

  const createContract = (employee_id, staff_id) => {
    const contractUrl = `/labour-contract/${staff_id}`;
    // navigate(`/labour-contract/${id}`, {
    //   state: {
    //     staffId: id,
    //   },
    // });
    // localStorage.setItem("staffId", id);
    openInNewTab(contractUrl);
  };

  const handleOpenOrderDialog = (staffId) => {
    setOpenModalOrder(true);
    loadOptionsForCommand(staffId);
    setOrderId(staffId);
  };

  const loadOptionsForCommand = async (selectedStaffId) => {
    try {
      // const _start = loadedOptions?.length;
      const response = await employeeServices.checkOrder(selectedStaffId);
      if (response) {
        setOrderData(response);
      }
    } catch (error) {
      console.log("error: ", error);
      return {
        options: {
          value: "",
          label: "",
        },
        hasMore: false,
      };
    }
  };

  const handleCloseOrderDialog = () => {
    setOpenModalOrder(false);
    setOrderId("");
    setOrderData();
  };

  return (
    <Grid
      style={{
        width: "100%",
        // height: "55vh",
      }}
    >
      {/* <Card sx={{ width: "100%" }}> */}
      {data && (
        <Table
          data={data}
          limit={limit}
          offset={offset}
          totalRows={total}
          columns={columns}
          isLoading={isLoading}
          setLimit={(value) => setLimit(value)}
          setOffset={(value) => setOffset(value)}
          applyFiltersHandler={applyFiltersHandler}
          cancelAppliedFilters={cancelAppliedFiltersHandler}
          downloadHandler={(columns) => allContExportHandler(columns)}
        />
      )}
      {contractId && (
        <ContractDialog
          id={contractId}
          open={showDialog}
          onClose={() => {
            setShowDialog(false);
            setContractId();
          }}
          disableSigning={true}
          fetchHandler={getContract}
        />
      )}
      {openModalOrder && (
        <OrderDialog
          open={openModalOrder}
          onClose={handleCloseOrderDialog}
          staffId={orderId}
          data={orderData}
          isUpdated={isCreated}
          setIsUpdated={setIsCreated}
          onClosePosition={() => {}}
        />
      )}
    </Grid>
  );
}
