import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import RoleCard from "../../components/Roles/RoleCard";
import { GENERAL_BLOCK } from "../../styled";
import NewRoleCard from "../../components/Roles/NewRoleCard";
import useRolePermissionActions from "../../hooks/useRolePermissionActions";
import { ExpandLess, ExpandMore, Inbox, Mail } from "@mui/icons-material";
import { Box } from "@mui/system";

const Index = () => {
  const [lang, setLang] = useState("az");

  const {
    createRole,
    editRole,
    deleteRole,
    getRoles,
    getRole,
    getModuleTree,
    getPermissionByModule,
    permissions,
    setModuleId,
    handleChange,
    handlePermissions,
    roleCreateData,
    setRoleCreateData,
    roleCreateDialog,
    setRoleCreateDialog,
    roleEditDialog,
    setRoleEditDialog,
    roleDeleteDialog,
    setRoleDeleteDialog,
    data,
    tree,
    loading,
    offset,
    limit,
    moduleId,
  } = useRolePermissionActions();
  const [openMenu, setOpenMenu] = useState(null);
  const submit = () => {
    if (
      roleCreateData?.title?.[lang] === "" ||
      roleCreateData?.description?.[lang] === ""
    )
      return;
    createRole(roleCreateData);
  };

  useEffect(() => {
    getRoles();
  }, [offset, limit, moduleId]);

  useEffect(() => {
    getModuleTree();
  }, []);

  useEffect(() => {
    if (moduleId !== null) getPermissionByModule();
  }, [offset, limit, moduleId]);

  let role_id = useRef(null);

  const handleDeleteDialog = (id) => {
    role_id.current = id;
    setRoleDeleteDialog(true);
  };

  const handleEditDialog = (id) => {
    role_id.current = id;
    setRoleEditDialog(true);
  };

  useEffect(() => {
    // Initialize state for open menus
    const initializeState = (items, parentPath = "") => {
      let initialState = {};
      items.forEach((item) => {
        const fullPath = parentPath ? `/${item.path}` : item.path;
        // console.log(fullPath);
        if (item.modules && item.modules.length > 0) {
          initialState[fullPath] = false;
          Object.assign(initialState, initializeState(item.modules, fullPath));
        }
      });
      return initialState;
    };
    setOpenMenu(initializeState(tree));
  }, [tree]);

  const renderMenuItems = (items, parentPath = "") => {
    return items.map((item) => {
      const fullPath = parentPath ? `${parentPath}/${item.path}` : item.path;
      const isOpen = openMenu[fullPath] || false;
      const hasModules = item.modules && item.modules.length > 0;
      // console.log(fullPath);
      return (
        <div key={item._id}>
          <ListItem
            button
            onClick={() => handleToggle(fullPath, item?._id, hasModules)}
          >
            <ListItemText primary={item.localized_title} />
            {hasModules ? isOpen ? <ExpandLess /> : <ExpandMore /> : null}
          </ListItem>
          {hasModules && (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className="ps-4">
                {renderMenuItems(item.modules, fullPath)}
              </List>
            </Collapse>
          )}
        </div>
      );
    });
  };

  const handleToggle = (path, module_id, hasModules) => {
    setModuleId(module_id);
    if (!hasModules) return;
    setOpenMenu((prevOpenMenus) => ({
      ...prevOpenMenus,
      [path]: !prevOpenMenus[path],
    }));
  };

  // const handleToggle = (path, hasModules) => {
  //   if (!hasModules) return;
  //   setOpenMenu((prevOpenMenus) => {
  //     console.log(prevOpenMenus);
  //     const newOpenMenus = Object.keys(prevOpenMenus).reduce((acc, key) => {
  //       console.log(acc, key);
  //       acc[key] = key === path ? !prevOpenMenus[key] : false;
  //       return acc;
  //     }, {});
  //     return newOpenMenus;
  //   });
  // };

  const children = (permissions) => (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {permissions?.map((permission) => (
        <FormControlLabel
          className="d-block"
          label={permission?.localized_description}
          control={
            <Checkbox
            // checked={checked[0] && checked[1]}
            // indeterminate={checked[0] !== checked[1]}
            // onChange={handleChange1}
            />
          }
        />
      ))}
    </Box>
  );

  return (
    <React.Fragment>
      <Helmet title="Users register" />
      <Typography
        variant="h3"
        gutterBottom
        display="inline"
        sx={{ marginRight: 6 }}
      >
        Rollar
      </Typography>
      <Divider my={3} className="mb-5" />
      <GENERAL_BLOCK d_play="grid" gtc="repeat(3, minmax(25%, 1fr))" gap="20px">
        {data?.length ? (
          data?.map((role) => (
            <RoleCard
              role={role}
              deleteRole={handleDeleteDialog}
              editRole={handleEditDialog}
            />
          ))
        ) : (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={172}
            sx={{ borderRadius: 5 }}
          />
        )}
        <NewRoleCard setRoleCreateDialog={setRoleCreateDialog} />
      </GENERAL_BLOCK>
      {/* Create dialog */}
      <Dialog
        open={roleCreateDialog}
        onClose={() => setRoleCreateDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        slotProps={{
          paper: { style: { minWidth: "60%", minHeight: "90%" } },
        }}
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          Rol yaradılması
        </DialogTitle>
        <DialogContent className="pt-3">
          <FormControl sx={{ width: 100 }} size="small">
            <InputLabel id="demo-multiple-name-label">Dil seçin</InputLabel>
            <Select
              size="small"
              labelId="demo-multiple-name-label"
              label="Dil seçin"
              id="demo-multiple-name"
              value={lang}
              onChange={(e) => setLang(e.target.value)}
            >
              <MenuItem key="az" value="az">
                az
              </MenuItem>
              <MenuItem key="en" value="en">
                en
              </MenuItem>
              <MenuItem key="ru" value="ru">
                ru
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Başlıq"
            size="small"
            placeholder="Başlıq"
            className="ms-2 me-2"
            name="title"
            value={roleCreateData?.title?.[lang]}
            onChange={(e) => handleChange(e, lang)}
          />
          <TextField
            label="Alt başlıq"
            size="small"
            placeholder="Alt başlıq"
            name="description"
            value={roleCreateData?.description?.[lang]}
            onChange={(e) => handleChange(e, lang)}
          />
          <Box sx={{ display: "flex", mt: 10 }}>
            <Box sx={{ width: "50%", overflow: "scroll", height: "630px" }}>
              {renderMenuItems(tree)}
            </Box>
            <Box sx={{ ml: 10 }}>
              {permissions?.length ? (
                <>
                  {permissions?.length > 1 && (
                    <FormControlLabel
                      className="d-block"
                      label="Hamısını seç"
                      control={
                        <Checkbox
                        // checked={checked[0] && checked[1]}
                        // indeterminate={checked[0] !== checked[1]}
                        // onChange={handleChange1}
                        />
                      }
                    />
                  )}
                  {children(permissions)}
                </>
              ) : (
                <div>Hər hansı bir icazə mövcud deyil</div>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRoleCreateDialog(false)}>Bağla</Button>
          <Button
            onClick={submit}
            disabled={
              roleCreateData?.title?.[lang] === "" ||
              roleCreateData?.description?.[lang] === ""
            }
          >
            Yadda saxla
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit dialog */}
      <Dialog
        open={roleEditDialog}
        onClose={() => setRoleEditDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        slotProps={{
          paper: { style: { minWidth: "50%" } },
        }}
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          Role redaktə edilməsi
        </DialogTitle>
        <DialogContent className="pt-3">
          <form action="">
            <FormControl sx={{ width: 100 }} size="small">
              <InputLabel id="demo-multiple-name-label">Dil seçin</InputLabel>
              <Select
                size="small"
                labelId="demo-multiple-name-label"
                label="Dil seçin"
                id="demo-multiple-name"
                value={lang}
                onChange={(e) => setLang(e.target.value)}
              >
                <MenuItem key="az" value="az">
                  az
                </MenuItem>
                <MenuItem key="en" value="en">
                  en
                </MenuItem>
                <MenuItem key="ru" value="ru">
                  ru
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Başlıq"
              size="small"
              placeholder="Başlıq"
              className="ms-2 me-2"
              name="title"
              value={roleCreateData?.title?.[lang]}
              onChange={(e) => handleChange(e, lang)}
            />
            <TextField
              label="Alt başlıq"
              size="small"
              placeholder="Alt başlıq"
              name="description"
              value={roleCreateData?.description?.[lang]}
              onChange={(e) => handleChange(e, lang)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRoleEditDialog(false)}>Bağla</Button>
          <Button
            onClick={() => {
              const { citizenship_ref, email_ref, ...rest } = roleCreateData;
              editRole({ ...roleCreateData, id: role_id });
            }}
            // disabled={userRegisterData?.email === userRegisterData?.email_ref}
          >
            Yadda Saxla
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete dialog */}
      <Dialog
        open={roleDeleteDialog}
        onClose={() => setRoleDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        slotProps={{
          paper: {
            style: { minWidth: "20%" },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          Rol silinməsi
        </DialogTitle>
        <DialogContent className="pt-3">
          Bu rolu silmək istədiyinizdən əminsiniz?
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => setRoleDeleteDialog(false)}
          >
            Bağla
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={loading}
            onClick={() => deleteRole(role_id.current)}
          >
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Index;
