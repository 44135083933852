import { Box } from "@mui/material";
import React from "react";
import { FieldArray, Form, Formik } from "formik";
import {
  overtimeColumns,
  overtimeMockData,
  overTimeValidationSchema,
} from "./mockOverTime";
import Table from "../../components/table/Table";
import {
  OverTimeEditDilaog,
  CancelledOverTimeDialog,
} from "./components/OverTimeDilaog";
import ContractDialog from "../../components/dialogs/documentDialog/ContractDialog";
import { getContract } from "../../services/documentations/ContractServices";
import { useAddOvertime } from "../../hooks/useOvertime";
import OverTimeHead from "./components/OverTimeHead";
import { OverTimeFormTop } from "./components/OverTimeFormTop";
import { OverTimeFormCenter } from "./components/OverTimeFormCenter";
import { OverTimeFormBottom } from "./components/OverTimeFormBottom";

const OverTime = () => {
  const {
    data,
    total,
    limit,
    isDate,
    offset,
    loading,
    empData,
    getData,
    editdata,
    setLimit,
    showModal,
    setOffset,
    setIsDate,
    setFilters,
    selectedId,
    cancelRowId,
    cancelDialog,
    aplicationId,
    setShowModal,
    handleSubmit,
    editShowModal,
    initialValues,
    setSelectedId,
    show_Accordion,
    setCancelDialog,
    setEditShowModal,
    set_show_Accordion,
    operatorColumnGenerator,
  } = useAddOvertime();
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <OverTimeHead
          setIsDate={setIsDate}
          show_Accordion={show_Accordion}
          set_show_Accordion={set_show_Accordion}
        />
        {show_Accordion && (
          <Formik
            initialValues={initialValues}
            validationSchema={overTimeValidationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              setErrors,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="overtime">
                    {({ push, remove }) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <OverTimeFormTop
                          errors={errors}
                          values={values}
                          touched={touched}
                          overtimeMockData={overtimeMockData}
                        />
                        {values?.type &&
                          values.overtime.map((_, index) => (
                            <OverTimeFormCenter
                              index={index}
                              isDate={isDate}
                              errors={errors}
                              values={values}
                              remove={remove}
                              empData={empData}
                              touched={touched}
                              setIsDate={setIsDate}
                              setFieldValue={setFieldValue}
                            />
                          ))}
                        <OverTimeFormBottom
                          push={push}
                          dirty={dirty}
                          values={values}
                          isValid={isValid}
                          setIsDate={setIsDate}
                          isSubmitting={isSubmitting}
                        />
                      </div>
                    )}
                  </FieldArray>
                </Form>
              </>
            )}
          </Formik>
        )}
        <Table
          data={data}
          limit={limit}
          offset={offset}
          totalRows={total}
          isLoading={loading}
          setLimit={setLimit}
          setOffset={setOffset}
          cancelAppliedFilters={() => setFilters([])}
          columns={overtimeColumns(operatorColumnGenerator)}
          applyFiltersHandler={(filters) => setFilters(filters)}
        />
        {editShowModal && (
          <OverTimeEditDilaog
            empData={empData}
            getData={getData}
            editData={editdata}
            open={editShowModal}
            close={setEditShowModal}
          />
        )}
        {cancelDialog && (
          <CancelledOverTimeDialog
            id={cancelRowId}
            getData={getData}
            open={cancelDialog}
            close={setCancelDialog}
          />
        )}
        {selectedId && (
          <ContractDialog
            id={selectedId}
            open={showModal}
            aplicationId={aplicationId}
            onClose={() => {
              setShowModal(false);
              setSelectedId();
            }}
            fetchHandler={getContract}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default OverTime;
