import { api } from "./axiosInterceptor";

// ⇩ ⇩ ⇩  User & Account Requests ⇩ ⇩ ⇩
export const postRole_API = async (payload) => {
  console.log(payload);
  //   let { type, ...rest } = payload;
  try {
    const response = await api.post(`/users/roles`, { ...payload });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteRole_API = async (id) => {
  try {
    const response = await api.delete(`users/roles/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getModuleTree_API = async (id) => {
  try {
    const response = await api.get(`users/modules/tree`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPermissionByModule_API = async (payload) => {
  let { moduleId, limit, offset } = payload;
  console.log(payload);
  try {
    const response = await api.get(
      `users/permissions?module=${moduleId}&limit=${limit}&offset=${offset}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRole_API = async (id) => {
  try {
    const response = await api.get(`users/roles/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deletePermission_API = async (id) => {
  try {
    const response = await api.delete(`/users/permission/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editRole_API = async (payload) => {
  let { id, ...rest } = payload;
  try {
    const response = await api.put(`users/roles/${id}`, { ...rest });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAccountsByUserId_API = async (payload) => {
  let { id, limit, offset } = payload;
  return await api.get(`accounts/a?limit=${limit}&offset=${offset}&user=${id}`);
};

export const getRoles_API = async (payload) => {
  let { limit, offset } = payload;
  console.log(payload);
  return await api.get(`users/roles?limit=${limit}&offset=${offset}`);
};
// ⇧ ⇧ ⇧  User & Account Requests ⇧ ⇧ ⇧
