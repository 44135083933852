import React from "react";
// Material UI
// import { makeStyles } from "@material-ui/core/styles";
import {
  FormHelperText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
// Utilities
import moment from "moment";
import { TYPES_ENUM } from "../../../constants";

// const useStyles = makeStyles({
//   input: {
//     "& input[type=number]": {
//       "-moz-appearance": "textfield",
//     },
//     "& input[type=number]::-webkit-outer-spin-button": {
//       "-webkit-appearance": "none",
//       margin: 0,
//     },
//     "& input[type=number]::-webkit-inner-spin-button": {
//       "-webkit-appearance": "none",
//       margin: 0,
//     },
//   },
// });

const TableWithDynamicInputs = ({
  headers,
  data,
  formik,
  disabled = false,
}) => {
  // const [date, setDate] = useState(null);

  const createDynamicInputsByType = (type, label, name) => {
    let dynamicInput = "";
    switch (type) {
      case TYPES_ENUM.DATE:
        dynamicInput = (
          <DatePicker
            label={label}
            disableFuture
            value={formik.values.data[name]}
            onChange={(_val) => {
              // setDate(_val);
              formik.setFieldValue("data", {
                ...formik.values.data,
                [name]: moment(_val).format("MM/DD/yyyy"),
              });
            }}
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => <TextField {...params} />}
            disabled={disabled}
          />
        );
        break;
      default:
        const _isNumberType = type === TYPES_ENUM.NUMBER;
        // const _className = _isNumberType ? classes.input : "";
        dynamicInput = (
          <TextField
            name={name}
            type={type}
            id="outlined-basic"
            sx={{
              ...(disabled && {
                "& input[type=number]": {
                  "-moz-appearance": "textfield",
                },
                "& input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "& input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              }),
            }}
            label={label}
            value={formik.values.data[name]}
            variant="outlined"
            onChange={(e) => {
              const { value } = e.target;
              const _val = _isNumberType ? minMaxValueHandler(value) : value;

              formik.setFieldValue("data", {
                ...formik.values.data,
                [name]: _val,
              });
            }}
            disabled={disabled}
          />
        );
        break;
    }
    return dynamicInput;
  };

  // const handleChange = (e, isNumberType, name) => {
  //   const { value } = e.target;
  //   const _val = isNumberType ? minMaxValueHandler(value) : value;

  //   formik.setFieldValue("data", {
  //     ...formik.values.data,
  //     [name]: _val,
  //   });
  // };

  const minMaxValueHandler = (value) => {
    let _val = value + "";
    if (value.length > 1 && value[0] === "0") {
      _val = value.split(0)[1];
    }
    if (value.toString().includes(".")) _val = value.toString().split(".");
    return parseInt(_val);
  };

  return (
    <Paper
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Table style={{ width: "65%" }}>
        <TableHead>
          <TableRow>
            {headers?.length > 0
              ? headers.map((header, index) => (
                  <TableCell
                    key={index + 1}
                    align={index > 0 ? "center" : "left"}
                  >
                    {header}
                  </TableCell>
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0
            ? data?.map((row, index) => (
                <TableRow key={index + 1}>
                  <TableCell component="th" scope="row">
                    {row?.label}
                  </TableCell>

                  <TableCell align="center">
                    {createDynamicInputsByType(
                      row?.type,
                      row?.label,
                      row?.name
                    )}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default TableWithDynamicInputs;
