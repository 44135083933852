import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

import {
  Button,
  Divider as MuiDivider,
  Grid2,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  TextField,
  CircularProgress,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import CompensationTable from "./compensation-table/compensation-table";
import useCompensation from "../../../../../hooks/useCompensation";
const Divider = styled(MuiDivider)(spacing);

const Compensation = () => {
  const navigate = useNavigate();
  const {
    data,
    loading,
    employee,
    tableData,
    commandData,
    contractData,
    localAmount,
    handleSubmit,
    setLocalAmount,
    setContractName,
    compensationData,
    updateVacantorder,
    handleInputChange,
    currentContractData,
    handleEmployeeChange,
    localCompensationDays,
    handleCurrentContract,
    currentTerminationData,
    currentcompensationData,
    setLocalCompensationDays,
    createFillingVacantPositionOrder,
  } = useCompensation();
  useEffect(() => {
    setContractName("compensation-order");
  }, []);
  return (
    <Card>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "700px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <CardContent>
          <Grid2 sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid2>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            Kompensassiyanın verilməsi
          </Typography>
          <Divider my={6} />
          <Grid2 sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid2 sx={{ width: "50%" }}>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: commandData?._id ? "none" : "",
                }}
              >
                <InputLabel>Əməkdaş(A.S.A)</InputLabel>
                <Select
                  name="Əməkdaş(A.S.A)"
                  label="Əməkdaş(A.S.A)"
                  variant="outlined"
                  value={employee.employeeId || ""}
                  onChange={(e) => handleEmployeeChange(e.target.value)}
                >
                  {data &&
                    data?.map((d, i) => (
                      <MenuItem value={d?._id} key={i}>
                        {d?.firstname} {d?.lastname} {d?.fathername}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Grid2 sx={{ display: "flex", gap: "25px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel>Müqavilə nömrəsi</InputLabel>
                  <Select
                    name="Müqavilə nömrəsi"
                    label="Müqavilə nömrəsi"
                    variant="outlined"
                    value={employee?.empStaffId || ""}
                    disabled={!employee.employeeId}
                    onChange={(e) => handleCurrentContract(e.target.value)}
                  >
                    {contractData &&
                      contractData?.map((d, i) => (
                        <MenuItem value={d?.contract?.staffs?.[0]?._id} key={i}>
                          {d?.contract?.data?.docNumber}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="Müqavilənin tarixi"
                    value={
                      compensationData?.laborContractSignDate
                        ? new Date(compensationData?.laborContractSignDate) ||
                          null
                        : new Date(
                            currentContractData?.contract?.decisionDate
                          ) || null
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
              </Grid2>
              <TextField
                name="Tabel nömrəsi"
                label="Tabel nömrəsi"
                value={employee?.tabelNumber || ""}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
              />
              <TextField
                name="Təşkilat"
                label="Təşkilat"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                value={
                  compensationData?.organization
                    ? compensationData?.organization || ""
                    : currentContractData?.structure?.organization?.name || ""
                }
                variant="outlined"
                my={2}
              />
              <TextField
                name="Struktur"
                label="Struktur"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                value={
                  compensationData?.structure
                    ? compensationData?.structure || ""
                    : currentContractData?.contract?.topPartOfStructures?.[0]
                        ?.source?.name || ""
                }
                variant="outlined"
                my={2}
              />
              <TextField
                name="Substruktur"
                label="Substruktur"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                value={
                  compensationData?.subStructure
                    ? compensationData?.subStructure || ""
                    : currentContractData?.part?.parent?.source?.name || ""
                }
                variant="outlined"
                my={2}
              />
              <TextField
                name="Vəzifə"
                label="Vəzifə"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                value={
                  compensationData?.position
                    ? compensationData?.position || ""
                    : currentContractData?.part?.source.name || ""
                }
                variant="outlined"
                my={2}
              />
            </Grid2>
            <Grid2 sx={{ width: "50%" }}>
              <TextField
                name="Gün"
                label="Gün"
                type="number"
                onChange={(e) => {
                  setLocalCompensationDays(e.target.value);
                  handleInputChange("residueDaysFromVacation", e.target.value);
                }}
                value={
                  compensationData?.residueDaysFromVacation
                    ? compensationData?.residueDaysFromVacation || ""
                    : localCompensationDays || ""
                }
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                }}
                variant="outlined"
                my={2}
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "+" ||
                    e.key === "-"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <Grid2 sx={{ display: "flex", gap: "25px" }}>
                <TextField
                  name="Məbləğ"
                  label="Məbləğ"
                  value={
                    compensationData?.amount
                      ? compensationData?.amount || ""
                      : localAmount || ""
                  }
                  sx={{ width: "70%" }}
                  variant="outlined"
                  my={2}
                  onKeyDown={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "+" ||
                      e.key === "-"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    // Sadəcə nöqtə və rəqəmə icazə ver
                    if (!/^\d*\.?\d*$/.test(value)) {
                      return;
                    }

                    if (value.length <= 7) {
                      setLocalAmount(value);
                      handleInputChange("amount", value);
                    }
                  }}
                />
                <FormControl sx={{ width: "30%", pointerEvents: "none" }}>
                  <InputLabel>Valyuta</InputLabel>
                  <Select
                    name="currency"
                    labelId="Valyuta"
                    label="Valyuta"
                    onChange={(e) =>
                      handleInputChange("currency", e.target.value)
                    }
                    value={"AZN"}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="AZN">AZN</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2 mb={10}>
            <TextField
              onChange={(e) => handleInputChange("note", e.target.value)}
              name="Qeyd"
              label="Qeyd"
              fullWidth
              variant="outlined"
              slotProps={{
                input: {
                  maxLength: 500,
                },
              }}
              value={compensationData?.note || ""}
            />
          </Grid2>
          <CompensationTable data={tableData} />
          <Divider my={6} />
          <Grid2
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "25px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => navigate("/documentation/orders")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              type="submit"
              title="Kənarlaşdırma yarat"
              variant="contained"
              sx={{ height: "100%" }}
              onClick={
                commandData?._id
                  ? updateVacantorder
                  : createFillingVacantPositionOrder
              }
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Təsdiq et
              </p>
            </Button>
          </Grid2>
        </CardContent>
      )}
    </Card>
  );
};

export default Compensation;
