import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import NestedSelect from "../../../../../utils/NestedSelect";
import useOrders from "../../../../../hooks/useOrders";
import { order_clauses } from "../../../../../constants";
import useTransferAnotherJob from "../../../../../hooks/useTransferAnotherJob";

const Divider = styled(MuiDivider)(spacing);
const TransferAnotherJob = () => {
  const [orderType, setOrderType] = useState(false);
  const {
    data,
    errors,
    loading,
    employee,
    navigate,
    setStaffId,
    isFormValid,
    structureId,
    commandData,
    contractData,
    handleSubmit,
    setPositionId,
    setStructureId,
    nestedChildren,
    setContractName,
    handleInputChange,
    vacantStaffData,
    updateVacantorder,
    currentContractData,
    setContractPosition,
    handleEmployeeChange,
    handleCurrentContract,
    transferAnotherJobData,
    setTransferAnotherJobData,
    createFillingVacantPositionOrder,
  } = useTransferAnotherJob();
  useEffect(() => {
    setContractName("transfer-order");
  }, []);

  const maxEndDate = (startDate) => {
    if (startDate) {
      const maxDate = new Date(startDate);
      maxDate.setMonth(maxDate.getMonth() + 1);
      return maxDate;
    }
    return new Date();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "700px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <CardContent>
            <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => navigate("/documentation/orders")}
                variant="text"
                sx={{ color: "#fff" }}
              >
                {" "}
                <CloseIcon />
              </Button>
            </Grid>
            <Typography variant="h3" textAlign="center" mt={5} mb={7}>
              Başqa işə keçirilmə
            </Typography>
            <Divider my={6} />
            <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
              <Grid sx={{ width: "50%" }}>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel>Əməkdaş(A.S.A)</InputLabel>
                  <Select
                    name="Əməkdaş(A.S.A)"
                    labelId="Əməkdaş(A.S.A)"
                    label="Əməkdaş(A.S.A)"
                    value={employee.employeeId || ""}
                    onChange={(e) => handleEmployeeChange(e.target.value)}
                  >
                    {data &&
                      data.map((d, i) => (
                        <MenuItem key={i} value={d.id}>
                          {`${d?.firstname} ${d?.lastname} ${d?.fathername}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Grid
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "25px",
                    marginBottom: "16px",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="contract_number">
                      Müqavilə nömrəsi
                    </InputLabel>
                    <Select
                      name="Müqavilə nömrəsi"
                      labelId="Müqavilə nömrəsi"
                      label="Müqavilə nömrəsi"
                      fullWidth
                      variant="outlined"
                      value={employee?.trAnJobEmpStaffId || ""}
                      onChange={(e) => {
                        const selectedStaffId = e.target.value;
                        const selectedStaff = contractData
                          ?.flatMap((d) => d?.contract?.staffs || [])
                          ?.find((staff) => staff?._id === selectedStaffId);

                        handleCurrentContract(selectedStaffId);
                        setStructureId(selectedStaff?.structure?._id);
                      }}
                      disabled={!employee.employeeId}
                    >
                      {contractData &&
                        contractData.map((d, i) =>
                          d?.contract?.staffs?.map((staff) => (
                            <MenuItem key={staff?._id} value={staff?._id}>
                              {d?.contract?.data?.docNumber}
                            </MenuItem>
                          ))
                        )}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: "100%", pointerEvents: "none" }}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      name="Müqavilənin tarixi"
                      label="Müqavilənin tarixi"
                      value={
                        currentContractData?.contract?.decisionDate
                          ? currentContractData?.contract?.decisionDate || ""
                          : transferAnotherJobData?.contractDate || null
                      }
                      onChange={(date) =>
                        handleInputChange("newContractDate", date)
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </FormControl>
                </Grid>
                <TextField
                  name="Tabel nömrəsi"
                  label="Tabel nömrəsi"
                  value={employee?.tabelNumber}
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  variant="outlined"
                  my={2}
                />
                <TextField
                  name="Təşkilat"
                  label="Təşkilat"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  my={2}
                  value={
                    currentContractData?.structure?.organization?.name
                      ? currentContractData?.structure?.organization?.name || ""
                      : transferAnotherJobData?.structure || ""
                  }
                />
                <TextField
                  name="Struktur"
                  label="Struktur"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  my={2}
                  value={
                    currentContractData?.contract?.topPartOfStructures?.[0]
                      ?.source?.name
                      ? currentContractData?.contract?.topPartOfStructures?.[0]
                          ?.source?.name || ""
                      : transferAnotherJobData?.topPartOfStrucutre || ""
                  }
                />
                <TextField
                  name="Substruktur"
                  label="Substruktur"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  my={2}
                  value={
                    currentContractData?.part?.parent?.source?.name
                      ? currentContractData?.part?.parent?.source?.name || " "
                      : transferAnotherJobData?.subStructure || ""
                  }
                />
                <TextField
                  name="Vəzifə"
                  label="Vəzifə"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                  my={2}
                  value={
                    currentContractData?.part?.source.name
                      ? currentContractData?.part?.source.name || ""
                      : transferAnotherJobData?.position || ""
                  }
                />
              </Grid>
              <Grid sx={{ width: "50%" }}>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel>Əmrin növü</InputLabel>
                  <Select
                    name="orderType"
                    label="Əmrin növü"
                    fullWidth
                    value={transferAnotherJobData?.orderType || ""}
                    variant="outlined"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (e.target.value === "tempTransferJob") {
                        setOrderType(true);
                        setTransferAnotherJobData({
                          ...transferAnotherJobData,
                          clause: {
                            id: "clause2",
                            name: "b. Ə/M uyğun Maddə 60",
                          },
                          orderType: value,
                        });
                      } else {
                        setOrderType(false);
                        setTransferAnotherJobData({
                          ...transferAnotherJobData,
                          clause: {
                            id: "clause1",
                            name: "a. Ə/M uyğun Maddə 59",
                          },
                          orderType: value,
                        });
                      }
                    }}
                  >
                    <MenuItem value="transferJob">Başqa işə keçirmə</MenuItem>
                    <MenuItem value="tempTransferJob">
                      Başqa işə müvəqqəti keçirmə
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel>Yeni Təşkilat</InputLabel>
                  <Select
                    name="organization"
                    label="Yeni Təşkilat"
                    variant="outlined"
                    value={
                      transferAnotherJobData?.newStructure
                        ? transferAnotherJobData?.newStructure || ""
                        : currentContractData?.structure?.organization?.name ||
                          ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      setStructureId(value?._id);
                      setTransferAnotherJobData({
                        ...transferAnotherJobData,
                        structure: value,
                      });
                    }}
                  >
                    <MenuItem
                      value={
                        transferAnotherJobData?.newStructure
                          ? transferAnotherJobData?.newStructure || ""
                          : currentContractData?.structure?.organization
                              ?.name || ""
                      }
                      hidden
                    >
                      {transferAnotherJobData?.newStructure
                        ? transferAnotherJobData?.newStructure || ""
                        : currentContractData?.structure?.organization?.name ||
                          ""}
                    </MenuItem>
                  </Select>
                </FormControl>
                <NestedSelect
                  setStaffId={setStaffId}
                  structureId={structureId}
                  setPositionId={setPositionId}
                  nestedChildren={nestedChildren}
                  setContractPosition={setContractPosition}
                />
                {/* {structureId ? (
                  <NestedSelect
                    setStaffId={setStaffId}
                    structureId={structureId}
                    setPositionId={setPositionId}
                    nestedChildren={nestedChildren}
                    setContractPosition={setContractPosition}
                  />
                ) : vacantStaffData !== "" ? (
                  <Grid>
                    <TextField
                      name="Struktur"
                      label="Struktur"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        marginBottom: "16px",
                        pointerEvents: "none",
                      }}
                      my={2}
                      value={
                        vacantStaffData?.vacantTopPartOfStructure?.name || ""
                      }
                    />
                    <TextField
                      name="Substruktur"
                      label="Substruktur"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        marginBottom: "16px",
                        pointerEvents: "none",
                      }}
                      my={2}
                      value={vacantStaffData?.vacantSubStructure?.name || ""}
                    />
                    <TextField
                      name="Vəzifə"
                      label="Vəzifə"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        marginBottom: "16px",
                        pointerEvents: "none",
                      }}
                      my={2}
                      value={vacantStaffData?.vacantPosition?.name || ""}
                    />
                  </Grid>
                ) : null} */}
                <Grid
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "25px",
                    marginBottom: "16px",
                  }}
                >
                  <TextField
                    sx={{ width: "50%" }}
                    name="personalCategory"
                    label="Yeni personal kateqoriya"
                    value={transferAnotherJobData?.personalCategory || ""}
                    onChange={(e) =>
                      handleInputChange("personalCategory", e.target.value)
                    }
                    slotProps={{
                      input: {
                        maxLength: 500,
                      },
                    }}
                    variant="outlined"
                    my={2}
                  />
                  <TextField
                    name="Yeni ixtisas dərəcəsi"
                    label="Yeni ixtisas dərəcəsi"
                    sx={{ width: "50%" }}
                    variant="outlined"
                    value={transferAnotherJobData?.positionLevel || ""}
                    slotProps={{
                      input: {
                        maxLength: 500,
                      },
                    }}
                    onChange={(e) =>
                      handleInputChange("positionLevel", e.target.value)
                    }
                    my={2}
                  />
                </Grid>
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <InputLabel>Əmək məcəlləsinin maddəsi</InputLabel>
                  <Select
                    name="position"
                    labelId="Əmək məcəlləsinin maddəsi"
                    label="Əmək məcəlləsinin maddəsi"
                    fullWidth
                    variant="outlined"
                    value={
                      transferAnotherJobData.clause?.id
                        ? transferAnotherJobData.clause?.id || ""
                        : transferAnotherJobData.clause || ""
                    }
                  >
                    {order_clauses?.map((o) => (
                      <MenuItem key={o?.id} value={o?.id}>
                        {o?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Grid
                  sx={{
                    display: "flex",
                    gap: "25px",
                    width: "100%",
                    marginBottom: "16px",
                  }}
                >
                  <DatePicker
                    my={2}
                    name="startWorkYear"
                    minDate={new Date()}
                    sx={{ width: "50%" }}
                    inputFormat="dd/MM/yyyy"
                    label="İşə başlama tarixi"
                    value={transferAnotherJobData?.startWorkYear || null}
                    onChange={(date) =>
                      handleInputChange("startWorkYear", date)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                  {transferAnotherJobData.orderType === "tempTransferJob" && (
                    <FormControl
                      sx={{ width: "100%" }}
                      // error={!!errors.endWorkYear}
                    >
                      <DatePicker
                        name="endWorkYear"
                        maxDate={maxEndDate(
                          transferAnotherJobData?.startWorkYear
                        )}
                        minDate={transferAnotherJobData?.startWorkYear || null}
                        label="Bitmə tarixi"
                        inputFormat="dd/MM/yyyy"
                        value={transferAnotherJobData?.endWorkYear || null}
                        onChange={(date) =>
                          handleInputChange("endWorkYear", date)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.endWorkYear}
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid mb={10}>
              <TextField
                name="Qeyd"
                label="Qeyd"
                value={transferAnotherJobData?.note || ""}
                fullWidth
                onChange={(e) => handleInputChange("note", e.target.value)}
                variant="outlined"
                my={2}
                slotProps={{
                  input: {
                    maxLength: 1000,
                  },
                }}
              />
            </Grid>
            <Divider my={6} />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "25px",
              }}
            >
              <Button
                title="İmtina et"
                variant="contained"
                sx={{ height: "100%" }}
                color="error"
                onClick={() => navigate("/documentation/orders")}
              >
                <p
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Geri
                </p>
              </Button>
              <Button
                title="Kənarlaşdırma yarat"
                variant="contained"
                disabled={
                  commandData?._id ? false : isFormValid("TransferAnotherJob")
                }
                sx={{ height: "100%" }}
                onClick={
                  commandData?._id
                    ? updateVacantorder
                    : createFillingVacantPositionOrder
                }
              >
                <p
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Təsdiq et
                </p>
              </Button>
            </Grid>
          </CardContent>
        )}
      </Card>
    </form>
  );
};

export default TransferAnotherJob;
