import React, { useEffect, useState } from "react";
import { Field, FieldArray, ErrorMessage, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  Typography,
  FormHelperText,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { isValid, parse } from "date-fns";
import moment from "moment";
import { getCommonData, updateEmployee } from "../../api/api";
import { reprimandRewardValidation } from "../employees/Validations";
import CustomAccordion from "../ui/Accordion/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EditRewardsAndReprimands = ({
  employeeById,
  submitted,
  setSubmitted,
}) => {
  const [rewardOrganizations, setRewardOrganizations] = useState([]);
  const [pastData, setPastData] = useState([]);
  const [isDate, setIsDate] = useState(false);

  useEffect(() => {
    const handleOrganizations = async () => {
      const response = await getCommonData("organizations");
      const rewardOrganizations = response?.data || [];
      setRewardOrganizations(rewardOrganizations);
      const combinedData = [];
      if (rewardOrganizations.length) {
        combinedData.push({ rewardOrganizations: [...rewardOrganizations] });
      }

      handlePastData(combinedData);
    };
    handleOrganizations();
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    // Handle form submission
    const modifiedValues = {
      ...values,
      reprimandsAndRewards:
        values?.reprimandsAndRewards &&
        values?.reprimandsAndRewards.map((item) => ({
          ...item,
          date: item.date ? moment(item.date).format("MM/DD/yyyy") : null,
          description: item.description.trim(),
        })),
    };
    // Handle form submission
    await updateEmployee(employeeById._id, modifiedValues);
    setIsDate(false);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };

  const initialValues = {
    reprimandsAndRewards: employeeById.reprimandsAndRewards || [],
  };
  function handlePastData(data) {
    setPastData(data);
  }
  function handlePush(index) {
    rewardOrganizations &&
      rewardOrganizations.push(
        employeeById?.reprimandsAndRewards[index]?.organization
      );
  }
  function handleHidden(name, data) {
    return pastData.every((past) => !past[name]?.includes(data));
  }
  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Mükafat, təltif və tənbehləri</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              reprimandsAndRewards: initialValues.reprimandsAndRewards || [], // Set the initial laborActivity values from employeeById
            }}
            onSubmit={handleSubmit}
            validationSchema={reprimandRewardValidation}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              dirty,
            }) => (
              <Form>
                <FieldArray name="reprimandsAndRewards">
                  {({ push, remove }) => (
                    <div>
                      {values?.reprimandsAndRewards &&
                        values?.reprimandsAndRewards.map((form, index) => (
                          <div key={index}>
                            <>
                              {handlePush(index)}
                              <Typography mt={5} variant="h6">
                                {index + 1})Mükafat, təltif və tənbehləri
                              </Typography>

                              {/* Rewards/ Reprimands and  description */}
                              <Grid
                                sx={{ display: "flex", gap: 3, mb: 3, mt: 5 }}
                              >
                                <Grid sx={{ width: "100%" }}>
                                  <FormControl
                                    fullWidth
                                    error={
                                      touched?.reprimandsAndRewards?.[index]
                                        ?.rType &&
                                      errors?.reprimandsAndRewards?.[index]
                                        ?.rType &&
                                      Boolean(
                                        errors?.reprimandsAndRewards?.[index]
                                          ?.rType
                                      )
                                    }
                                  >
                                    <InputLabel
                                      id={`reprimandsAndRewards[${index}].rType`}
                                    >
                                      Mükafat / Təltif / Tənbeh
                                    </InputLabel>
                                    <Field
                                      as={Select}
                                      name={`reprimandsAndRewards[${index}].rType`}
                                      labelId={`rreprimandsAndRewards[${index}].rType`}
                                      label="Mükafat / Təltif / Tənbeh"
                                      id={`reprimandsAndRewards[${index}].rType`}
                                      defaultValue=""
                                      fullWidth
                                      error={
                                        touched?.reprimandsAndRewards?.[index]
                                          ?.rType &&
                                        errors?.reprimandsAndRewards?.[index]
                                          ?.rType &&
                                        Boolean(
                                          errors?.reprimandsAndRewards?.[index]
                                            ?.rType
                                        )
                                      }
                                    >
                                      <MenuItem value="prize">Mükafat</MenuItem>
                                      <MenuItem value="reprimand">
                                        Tənbeh
                                      </MenuItem>
                                      <MenuItem value="reward">Təltif</MenuItem>
                                    </Field>
                                  </FormControl>
                                  {touched?.reprimandsAndRewards?.[index]
                                    ?.rType &&
                                    errors?.reprimandsAndRewards?.[index]
                                      ?.rType &&
                                    errors?.reprimandsAndRewards?.[index]
                                      ?.rType && (
                                      <FormHelperText error>
                                        {
                                          errors?.reprimandsAndRewards?.[index]
                                            ?.rType
                                        }
                                      </FormHelperText>
                                    )}
                                </Grid>
                                <Grid sx={{ width: "100%" }}>
                                  <FormControl
                                    fullWidth
                                    error={
                                      touched?.reprimandsAndRewards?.[index]
                                        ?.description &&
                                      errors?.reprimandsAndRewards?.[index]
                                        ?.description &&
                                      Boolean(
                                        errors?.reprimandsAndRewards?.[index]
                                          ?.description
                                      )
                                    }
                                  >
                                    <Field
                                      type="text"
                                      name={`reprimandsAndRewards[${index}].description`}
                                      as={TextField}
                                      fullWidth
                                      label="Mükafat / Təltif / Tənbehin məzmunu"
                                      error={
                                        touched?.reprimandsAndRewards?.[index]
                                          ?.description &&
                                        errors?.reprimandsAndRewards?.[index]
                                          ?.description &&
                                        Boolean(
                                          errors?.reprimandsAndRewards?.[index]
                                            ?.description
                                        )
                                      }
                                    />
                                  </FormControl>
                                  {touched?.reprimandsAndRewards?.[index]
                                    ?.description &&
                                    errors?.reprimandsAndRewards?.[index]
                                      ?.description &&
                                    errors?.reprimandsAndRewards?.[index]
                                      ?.description && (
                                      <FormHelperText error>
                                        {
                                          errors?.reprimandsAndRewards?.[index]
                                            ?.description
                                        }
                                      </FormHelperText>
                                    )}
                                </Grid>
                              </Grid>

                              {/* start date and company */}
                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid
                                  onClick={() => setIsDate(true)}
                                  sx={{ width: "100%" }}
                                >
                                  <Field
                                    name={`reprimandsAndRewards[${index}].date`}
                                  >
                                    {({ field, form }) => (
                                      <>
                                        <DatePicker
                                          {...field}
                                          label="Mükafat / Təltif / Tənbehin qüvvəyə mindiyi tarix"
                                          sx={{ width: "100%" }}
                                          format="dd/MM/yyyy"
                                          value={
                                            form?.values?.reprimandsAndRewards[
                                              index
                                            ]?.date
                                              ? new Date(field?.value)
                                              : null
                                          }
                                          slotProps={{
                                            textField: {
                                              error:
                                                isDate &&
                                                Boolean(
                                                  form.errors
                                                    ?.reprimandsAndRewards?.[
                                                    index
                                                  ]?.date
                                                ),
                                              helperText:
                                                (isDate &&
                                                  form.errors
                                                    ?.reprimandsAndRewards?.[
                                                    index
                                                  ]?.date) ||
                                                "",
                                              fullWidth: true,
                                            },
                                          }}
                                          onChange={(date) => {
                                            if (
                                              date instanceof Date &&
                                              !isNaN(date)
                                            ) {
                                              form.setFieldValue(
                                                `reprimandsAndRewards[${index}].date`,
                                                date
                                              );
                                            } else {
                                              form.setFieldValue(
                                                `reprimandsAndRewards[${index}].date`,
                                                ""
                                              );
                                            }
                                          }}
                                          maxDate={new Date()}
                                          minDate={new Date(1940, 0, 1)}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </Grid>
                                <Grid sx={{ width: "100%" }}>
                                  <FormControl
                                    error={
                                      touched?.reprimandsAndRewards?.[index]
                                        ?.organization &&
                                      errors?.reprimandsAndRewards?.[index]
                                        ?.organization &&
                                      Boolean(
                                        errors?.reprimandsAndRewards?.[index]
                                          ?.organization
                                      )
                                    }
                                    fullWidth
                                  >
                                    <InputLabel
                                      id={`reprimandsAndRewards[${index}].organization`}
                                    >
                                      Mükafat / Təltif / Tənbehin təqdim
                                      olunduğu müəssisə *
                                    </InputLabel>
                                    <Field
                                      as={Select}
                                      name={`reprimandsAndRewards[${index}].organization`}
                                      labelId={`reprimandsAndRewards[${index}].organization`}
                                      label="Mükafat / Təltif / Tənbehin təqdim olunduğu müəssisə *"
                                      id={`reprimandsAndRewards[${index}].organization`}
                                      defaultValue=""
                                      fullWidth
                                      error={
                                        touched?.reprimandsAndRewards?.[index]
                                          ?.organization &&
                                        errors?.reprimandsAndRewards?.[index]
                                          ?.organization &&
                                        Boolean(
                                          errors?.reprimandsAndRewards?.[index]
                                            ?.organization
                                        )
                                      }
                                    >
                                      {rewardOrganizations &&
                                        [...new Set(rewardOrganizations)]
                                          .sort((a, b) =>
                                            a.localeCompare(b, "az")
                                          )
                                          .map((item, index) => (
                                            <MenuItem
                                              hidden={handleHidden(
                                                "rewardOrganizations",
                                                item
                                              )}
                                              value={item}
                                              key={index}
                                            >
                                              {item}
                                            </MenuItem>
                                          ))}
                                    </Field>
                                    {touched?.reprimandsAndRewards?.[index]
                                      ?.organization &&
                                      errors?.reprimandsAndRewards?.[index]
                                        ?.organization &&
                                      errors?.reprimandsAndRewards?.[index]
                                        ?.organization && (
                                        <FormHelperText error>
                                          {
                                            errors?.reprimandsAndRewards?.[
                                              index
                                            ]?.organization
                                          }
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </>

                            <Button
                              sx={{ mt: 3 }}
                              variant="contained"
                              color="error"
                              type="button"
                              onClick={() => {
                                remove(index);
                                setIsDate(false);
                              }}
                            >
                              Mükafat / Təltif / Tənbeh sil
                            </Button>
                          </div>
                        ))}
                      <Button
                        sx={{ mt: 3 }}
                        variant="contained"
                        type="button"
                        onClick={() => {
                          push({});
                          setIsDate(false);
                        }}
                      >
                        Mükafat / Təltif / Tənbeh əlavə et
                      </Button>
                    </div>
                  )}
                </FieldArray>
                <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValid || isSubmitting || !dirty}
                  >
                    Dəyişiklikləri yadda saxla
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EditRewardsAndReprimands;
