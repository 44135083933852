import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid2,
} from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import { overtimeMockData, overTimeValidationSchema } from "../mockOverTime";
import moment from "moment";
import {
  putBulkOvertimeApplication_SERVICE,
  deleteBulkOvertimeApplication_SERVICE,
  deleteEmployeeOvertimeApplication_SERVICE,
} from "../../../services/overtime/overtimeServices";
import { handleApiError } from "../../../api/changeRequests";
import { removeEmptyStrings } from "../../../utils/removeEmptyString";
import BusinessTripSkeleton from "../../leaves/BusinessTripSkeleton";
import OverTimeSkeleton from "./OverTimeSkeleton";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay } from "date-fns";

export const OverTimeEditDilaog = ({
  open,
  close,
  empData,
  getData,
  editData,
}) => {
  const [isDate, setIsDate] = useState(false);
  const [loading, setLoading] = useState(true);
  const today = endOfDay(new Date());
  const initialValues = {
    overtime:
      editData?.employeeApplications?.map((app) => ({
        employee: app?.employee?._id,
        contract: app?.contract?.staffs?.[0],
        status: app?.status,
        structure: app?.contract?.baseStructures?.organization?.[0]?.name,
        footer: app?.workShifts?.map((foot) => ({
          date: moment(foot?.date).format("MM/DD/YYYY"),
          hour: `${foot?.hours}`,
          paymentType: foot?.paymentType,
        })),
      })) || [],
    reason: editData?.reason,
    contractItem: editData?.clause,
    type: editData?.type,
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      const existingEmployees =
        editData?.employeeApplications?.map((app) => app?.employee?._id) || [];
      const newEmployeeArr = values?.overtime
        ?.filter(
          (overtimeEntry) =>
            !existingEmployees.includes(overtimeEntry?.employee)
        )
        ?.map((overtimeEntry) => ({
          type: values?.type,
          uniqueId: editData?._id,
          employeeId: overtimeEntry?.employee,
          staffId: overtimeEntry?.contract,
          workShifts: (overtimeEntry?.footer || []).map((foot) => ({
            date: moment(foot?.date).format("MM/DD/YYYY"),
            hours: parseFloat(foot?.hour) || 0,
            paymentType: foot?.paymentType,
          })),
        }));
      const modifiedValues = {
        type: values?.type,
        reason: values?.reason,
        clause: values?.contractItem,
        employees: values?.overtime?.map((item) => ({
          id: item?.employee,
          staffId: item?.contract,
          workShifts: item?.footer?.map((foot) => ({
            date: moment(foot?.date).format("MM/DD/YYYY"),
            hours: parseFloat(foot?.hour),
            paymentType: foot?.paymentType,
          })),
        })),
      };
      const result = removeEmptyStrings(modifiedValues);
      await putBulkOvertimeApplication_SERVICE(
        editData?._id,
        result,
        newEmployeeArr,
        getData,
        close
      );

      setSubmitting(false);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };
  const handleDeleteEmployee = async (id, remove, setIsDate, index) => {
    const targetApplication = editData?.employeeApplications?.find(
      (app) => app?.employee?._id === id
    );

    if (targetApplication) {
      const res = await deleteEmployeeOvertimeApplication_SERVICE(
        targetApplication?._id
      );
      if (res?.status === 201) {
        getData();
        remove(index);
        setIsDate(false);
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          maxWidth: "unset",
          height: "500px",
        },
      }}
      onClose={() => close(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {loading ? (
        <OverTimeSkeleton />
      ) : (
        <>
          <DialogTitle
            sx={{ fontSize: "24px", textAlign: "center" }}
            id="alert-dialog-title"
          >
            İş vaxtından artıq işə çıxma dəyişiklik
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={initialValues}
              validationSchema={overTimeValidationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                isValid,
                errors,
                touched,
                isSubmitting,
                handleChange,
                setFieldValue,
                setErrors,
                dirty,
              }) => (
                <>
                  <Form>
                    <FieldArray name="overtime">
                      {({ push, remove }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <Grid2 sx={{ display: "flex", gap: "10px", mt: 4 }}>
                            <Grid2 sx={{ width: "33%" }}>
                              <FormControl
                                fullWidth
                                error={
                                  touched?.type &&
                                  errors?.type &&
                                  Boolean(errors?.type)
                                }
                              >
                                <InputLabel id={`type`}>Növ</InputLabel>
                                <Field
                                  as={Select}
                                  name={`type`}
                                  id={`type`}
                                  labelId={`type`}
                                  label="Növ"
                                  defaultValue=""
                                  fullWidth
                                  error={
                                    touched?.type &&
                                    errors?.type &&
                                    Boolean(errors?.type)
                                  }
                                >
                                  {overtimeMockData?.[0]?.overtimeTypes.map(
                                    (type, index) => {
                                      return (
                                        <MenuItem value={type?.value}>
                                          {type?.label}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={`type`}
                                  component="div"
                                  style={{ color: "red", fontSize: "12px" }}
                                />
                              </FormControl>
                            </Grid2>
                            <Grid2 sx={{ width: "33%" }}>
                              <FormControl
                                fullWidth
                                error={
                                  errors?.contractItem && touched?.contractItem
                                }
                              >
                                <InputLabel id={`contractItem`}>
                                  Əmək məcəlləsinin maddəsi
                                </InputLabel>
                                <Field
                                  fullWidth
                                  as={Select}
                                  defaultValue=""
                                  disabled={!values?.type}
                                  label="Əmək məcəlləsinin maddəsi"
                                  id={`contractItem`}
                                  name={`contractItem`}
                                  labelId={`contractItem`}
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        width: "100px",
                                        maxHeight: 200,
                                        overflowY: "auto",
                                      },
                                    },
                                  }}
                                  error={
                                    errors?.contractItem &&
                                    touched?.contractItem &&
                                    Boolean(errors?.contractItem)
                                  }
                                >
                                  {values?.type &&
                                    overtimeMockData?.[0]?.contractItemTypes?.[
                                      values?.type
                                    ].map((item, contIndex) => (
                                      <MenuItem
                                        key={contIndex}
                                        value={item?.value}
                                        sx={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          display: "inline-block",
                                          width: "100%",
                                        }}
                                      >
                                        <Tooltip
                                          title={item?.label}
                                          followCursor
                                        >
                                          {item?.label}
                                        </Tooltip>
                                      </MenuItem>
                                    ))}
                                </Field>

                                {errors?.contractItem && touched?.contractItem && (
                                  <ErrorMessage
                                    name={`contractItem`}
                                    component="div"
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  />
                                )}
                              </FormControl>
                            </Grid2>
                            <Grid2 sx={{ width: "33%" }}>
                              <Field
                                type="text"
                                name={`reason`}
                                as={TextField}
                                disabled={!values?.type}
                                error={
                                  touched?.reason && Boolean(errors?.reason)
                                }
                                helperText={touched?.reason && errors?.reason}
                                fullWidth
                                label="Səbəb"
                              />
                            </Grid2>
                          </Grid2>
                          {values?.type &&
                            values.overtime.map((_, index) => (
                              <div>
                                <Accordion defaultExpanded>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    <Typography mt={3} mb={3} variant="h6">
                                      {/* {index + 1})  */}
                                      Əməkdaş
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div>
                                      <Grid2
                                        key={index}
                                        sx={{
                                          gap: "10px",
                                          width: "100%",
                                          display: "flex",
                                          flexDirection: "column",
                                          mb: 2,
                                        }}
                                      >
                                        <Grid2
                                          sx={{
                                            gap: "10px",
                                            width: "100%",
                                            display: "flex",
                                          }}
                                        >
                                          <Grid2 sx={{ width: "50%" }}>
                                            <FormControl
                                              fullWidth
                                              error={
                                                errors?.overtime?.[index]
                                                  ?.employee &&
                                                touched?.overtime?.[index]
                                                  ?.employee &&
                                                Boolean(
                                                  errors?.overtime?.[index]
                                                    ?.employee
                                                )
                                              }
                                            >
                                              <Field
                                                name={`overtime[${index}].employee`}
                                              >
                                                {({ field, form }) => {
                                                  const selectedEmployee =
                                                    empData.find((emp) => {
                                                      return (
                                                        emp._id ===
                                                        values?.overtime?.[
                                                          index
                                                        ]?.employee
                                                      );
                                                    }) || null;

                                                  return (
                                                    <Autocomplete
                                                      options={empData || []}
                                                      getOptionLabel={(
                                                        option
                                                      ) => {
                                                        if (!option) return "";
                                                        return `${
                                                          option?.firstname ||
                                                          ""
                                                        } ${
                                                          option?.lastname || ""
                                                        } ${
                                                          option?.fathername ||
                                                          ""
                                                        }`;
                                                      }}
                                                      value={selectedEmployee}
                                                      onChange={(
                                                        event,
                                                        selectedOption
                                                      ) => {
                                                        form.setFieldValue(
                                                          `overtime[${index}].employee`,
                                                          selectedOption
                                                            ? selectedOption._id
                                                            : ""
                                                        );
                                                      }}
                                                      renderOption={(
                                                        props,
                                                        option
                                                      ) => (
                                                        <li
                                                          {...props}
                                                          key={option?._id}
                                                        >
                                                          {option?.firstname}{" "}
                                                          {option?.lastname} (
                                                          {option?.fathername})
                                                        </li>
                                                      )}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="Əməkdaş"
                                                          variant="outlined"
                                                          helperText={
                                                            touched?.overtime?.[
                                                              index
                                                            ]?.employee &&
                                                            errors?.overtime?.[
                                                              index
                                                            ]?.employee
                                                          }
                                                          error={
                                                            Boolean(
                                                              errors
                                                                ?.overtime?.[
                                                                index
                                                              ]?.employee
                                                            ) &&
                                                            Boolean(
                                                              touched
                                                                ?.overtime?.[
                                                                index
                                                              ]?.employee
                                                            )
                                                          }
                                                        />
                                                      )}
                                                    />
                                                  );
                                                }}
                                              </Field>

                                              <ErrorMessage
                                                name={`overtime[${index}].employee`}
                                                component="div"
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              />
                                            </FormControl>
                                          </Grid2>
                                          <Grid2 sx={{ width: "50%" }}>
                                            <FormControl
                                              fullWidth
                                              error={
                                                errors?.overtime?.[index]
                                                  ?.contract &&
                                                touched?.overtime?.[index]
                                                  ?.contract &&
                                                Boolean(
                                                  errors?.overtime?.[index]
                                                    ?.contract
                                                )
                                              }
                                            >
                                              <InputLabel>
                                                Əmək müqaviləsi
                                              </InputLabel>
                                              <Field
                                                as={Select}
                                                name={`overtime[${index}].contract`}
                                                value={
                                                  values?.overtime?.[index]
                                                    ?.contract
                                                }
                                                label="Əmək müqaviləsi"
                                                fullWidth
                                                onChange={(e) => {
                                                  const { value } = e.target;

                                                  const selectStaff = empData
                                                    ?.find(
                                                      (emp) =>
                                                        emp?._id ===
                                                        values?.overtime?.[
                                                          index
                                                        ]?.employee
                                                    )
                                                    ?.staffs?.find(
                                                      (staff) =>
                                                        staff?._id === value
                                                    );

                                                  setFieldValue(
                                                    `overtime[${index}].contract`,
                                                    value
                                                  );
                                                  setFieldValue(
                                                    `overtime[${index}].structure`,
                                                    selectStaff?.structure
                                                      ?.organization?.name || ""
                                                  );
                                                }}
                                                disabled={
                                                  !values?.overtime?.[index]
                                                    ?.employee
                                                }
                                                error={
                                                  errors?.overtime?.[index]
                                                    ?.contract &&
                                                  touched?.overtime?.[index]
                                                    ?.contract &&
                                                  Boolean(
                                                    errors?.overtime?.[index]
                                                      ?.contract
                                                  )
                                                }
                                              >
                                                {values?.overtime?.[index]
                                                  ?.employee &&
                                                  empData
                                                    ?.filter(
                                                      (emp) =>
                                                        emp?._id ===
                                                        values?.overtime?.[
                                                          index
                                                        ]?.employee
                                                    )
                                                    ?.flatMap((emp) =>
                                                      emp?.staffs?.map(
                                                        (staff) => (
                                                          <MenuItem
                                                            key={staff?._id}
                                                            value={staff?._id}
                                                          >
                                                            {
                                                              staff?.contract
                                                                ?.data
                                                                ?.docNumber
                                                            }
                                                          </MenuItem>
                                                        )
                                                      )
                                                    )}
                                              </Field>
                                              <ErrorMessage
                                                name={`overtime[${index}].contract`}
                                                component="div"
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              />
                                            </FormControl>
                                          </Grid2>
                                        </Grid2>
                                        <Grid2
                                          sx={{
                                            gap: "10px",
                                            width: "100%",
                                            display: "flex",
                                          }}
                                        >
                                          <Grid2
                                            sx={{
                                              width: "100%",
                                              pointerEvents: "none",
                                            }}
                                          >
                                            <Field
                                              type="text"
                                              name={`overtime[${index}].structure`}
                                              as={TextField}
                                              fullWidth
                                              label="Struktur"
                                            />
                                          </Grid2>
                                        </Grid2>
                                        <Grid2
                                          sx={{
                                            gap: "10px",
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <FieldArray
                                            name={`overtime[${index}].footer`}
                                          >
                                            {({ push, remove }) => (
                                              <>
                                                {values.overtime?.[
                                                  index
                                                ]?.footer.map((foot, ind) => (
                                                  <Grid2
                                                    container
                                                    spacing={2}
                                                    key={ind}
                                                  >
                                                    <Grid2
                                                      item
                                                      xs={4}
                                                      sx={{ width: "25%" }}
                                                      onClick={() =>
                                                        setIsDate(true)
                                                      }
                                                    >
                                                      <Field
                                                        name={`overtime[${index}].footer[${ind}].date`}
                                                      >
                                                        {({ field, form }) => {
                                                          const error =
                                                            isDate &&
                                                            form.errors
                                                              .overtime?.[index]
                                                              ?.footer?.[ind]
                                                              ?.date;

                                                          return (
                                                            <>
                                                              <DatePicker
                                                                {...field}
                                                                sx={{
                                                                  width: "100%",
                                                                }}
                                                                label="Tarix"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={
                                                                  field.value
                                                                    ? new Date(
                                                                        field.value
                                                                      )
                                                                    : null
                                                                }
                                                                disabled={
                                                                  !values
                                                                    ?.overtime?.[
                                                                    index
                                                                  ]?.contract
                                                                }
                                                                onChange={(
                                                                  value
                                                                ) =>
                                                                  form.setFieldValue(
                                                                    field.name,
                                                                    value
                                                                  )
                                                                }
                                                                minDate={
                                                                  new Date()
                                                                }
                                                                maxDate={
                                                                  new Date(
                                                                    2099,
                                                                    11,
                                                                    31
                                                                  )
                                                                }
                                                                slotProps={{
                                                                  textField: {
                                                                    error:
                                                                      !!error,
                                                                    helperText:
                                                                      error ||
                                                                      "",
                                                                    fullWidth: true,
                                                                  },
                                                                }}
                                                              />
                                                            </>
                                                          );
                                                        }}
                                                      </Field>
                                                    </Grid2>

                                                    <Grid2
                                                      sx={{ width: "25%" }}
                                                      item
                                                      xs={4}
                                                    >
                                                      <Field
                                                        name={`overtime[${index}].footer[${ind}].hour`}
                                                      >
                                                        {({ field, form }) => {
                                                          const error =
                                                            form.touched
                                                              .overtime &&
                                                            form.touched
                                                              .overtime?.[index]
                                                              ?.footer?.[ind]
                                                              ?.hour &&
                                                            form.errors
                                                              .overtime &&
                                                            form.errors
                                                              .overtime?.[index]
                                                              ?.footer?.[ind]
                                                              ?.hour;

                                                          return (
                                                            <TextField
                                                              {...field}
                                                              fullWidth
                                                              disabled={
                                                                !values
                                                                  ?.overtime?.[
                                                                  index
                                                                ]?.contract
                                                              }
                                                              label="Saat"
                                                              error={!!error}
                                                              helperText={
                                                                error
                                                                  ? error
                                                                  : ""
                                                              }
                                                            />
                                                          );
                                                        }}
                                                      </Field>
                                                    </Grid2>

                                                    <Grid2
                                                      sx={{ width: "25%" }}
                                                      item
                                                      xs={4}
                                                    >
                                                      <FormControl
                                                        component="fieldset"
                                                        variant="standard"
                                                      >
                                                        <RadioGroup
                                                          name={`overtime[${index}].footer[${ind}].paymentType`}
                                                          row
                                                          defaultValue="Double"
                                                          onChange={(e) => {
                                                            setFieldValue(
                                                              `overtime[${index}].footer[${ind}].paymentType`,
                                                              e?.target?.value
                                                            );
                                                          }}
                                                          value={
                                                            values?.overtime?.[
                                                              index
                                                            ]?.footer?.[ind]
                                                              ?.paymentType
                                                          }
                                                        >
                                                          <FormControlLabel
                                                            value="Double"
                                                            control={
                                                              <Radio color="primary" />
                                                            }
                                                            label="2X (2 qat)"
                                                          />
                                                          {values?.type !==
                                                            "Extended" && (
                                                            <FormControlLabel
                                                              value="Substitute_Day"
                                                              control={
                                                                <Radio color="primary" />
                                                              }
                                                              label="Əvəzgün"
                                                            />
                                                          )}
                                                        </RadioGroup>
                                                      </FormControl>
                                                    </Grid2>

                                                    <Grid2
                                                      item
                                                      xs={12}
                                                      sx={{
                                                        display: "flex",
                                                        gap: "10px",
                                                        width: "25%",
                                                      }}
                                                    >
                                                      {ind ===
                                                        values?.overtime?.[
                                                          index
                                                        ]?.footer.length -
                                                          1 &&
                                                        values?.overtime?.[
                                                          index
                                                        ]?.footer.length <
                                                          30 && (
                                                          <Button
                                                            type="button"
                                                            variant="outlined"
                                                            onClick={() => {
                                                              push({
                                                                date: "",
                                                                hour: "",
                                                                paymentType:
                                                                  "Double",
                                                              });
                                                              setIsDate(false);
                                                            }}
                                                          >
                                                            + Əlavə et
                                                          </Button>
                                                        )}

                                                      {values?.overtime?.[index]
                                                        ?.footer.length === 1
                                                        ? ind > 0
                                                        : ind >= 0 && (
                                                            <Button
                                                              type="button"
                                                              color="error"
                                                              variant="outlined"
                                                              onClick={() => {
                                                                remove(ind);
                                                                setIsDate(
                                                                  false
                                                                );
                                                              }}
                                                            >
                                                              - Sil
                                                            </Button>
                                                          )}
                                                    </Grid2>
                                                  </Grid2>
                                                ))}
                                              </>
                                            )}
                                          </FieldArray>
                                        </Grid2>

                                        {/* <Grid2>
                                          <Button
                                            color="error"
                                            variant="outlined"
                                            onClick={() => {
                                              handleDeleteEmployee(
                                                values?.overtime?.[index]
                                                  ?.employee,
                                                remove,
                                                setIsDate,
                                                index
                                              );
                                            }}
                                          >
                                            Əməkdaş Sil
                                          </Button>
                                        </Grid2> */}
                                      </Grid2>
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            ))}

                          <Grid2
                            sx={{
                              mt: 3,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* {values?.type && values?.overtime?.length < 50 && (
                              <Button
                                variant="contained"
                                onClick={() => {
                                  push({
                                    employee: "",
                                    contract: "",
                                    structure: "",
                                    footer: [
                                      {
                                        date: "",
                                        hour: "",
                                        paymentType: "Double",
                                      },
                                    ],
                                  });
                                  setIsDate(false);
                                }}
                              >
                                Əməkdaş əlavə et <AddIcon />
                              </Button>
                            )} */}
                            <Button
                              type="submit"
                              variant="contained"
                              disabled={isSubmitting || !isValid || !dirty}
                            >
                              Yadda saxla
                            </Button>
                          </Grid2>
                        </div>
                      )}
                    </FieldArray>
                  </Form>
                </>
              )}
            </Formik>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export const CancelledOverTimeDialog = ({ id, getData, open, close }) => {
  const handleCancelled = async (id) => {
    try {
      const res = await deleteBulkOvertimeApplication_SERVICE(id);
      if (res.status === 201) {
        getData();
      }
    } catch (err) {
      handleApiError(err);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => close(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle sx={{ textAlign: "center" }} id="form-dialog-title">
        Ləğv etmək istədiyinizdən əminsiniz ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ textAlign: "center" }}
          id="alert-dialog-description"
        >
          Bu əməliyyata davam etsəniz iş vaxtından artıq işə çıxma sorğusu ləğv
          ediləcək!
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => close(false)} color="primary">
          Ləğv et
        </Button>
        <Button
          onClick={() => {
            handleCancelled(id);
            close(false);
          }}
          color="primary"
        >
          Bəli
        </Button>
      </DialogActions>
    </Dialog>
  );
};
