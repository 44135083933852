import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { postGroupChat, postPrivateChat } from "../../api/api";
import { getEmployees } from "../../services/employees/EmployeeServices";

const CreateChatDialog = ({ state, dispatch, user }) => {
  const { users, createChatDialog, submitted } = state;

  const [emps, setEmps] = useState([]);
  const [total, setTotal] = useState("");
  const [filter, setFilter] = useState({
    firstname: "",
    lastname: "",
    fathername: "",
  });
  // Select group or private
  const [selectChat, setSelectChat] = useState("private");

  useEffect(() => {
    getAllEmployees();
  }, [filter]);

  const getAllEmployees = async () => {
    try {
      const generatedFilters = encodeURIComponent(
        JSON.stringify([
          { column: "status", operator: "equalsTo", value: "active" },
          {
            column: "firstname",
            operator: "contains",
            value: filter.firstname || "",
          },
          {
            column: "lastname",
            operator: "contains",
            value: filter.lastname || "",
          },
          {
            column: "fathername",
            operator: "contains",
            value: filter.fathername || "",
          },
        ])
      );

      const payload = {
        limit: 999999,
        skip: 0,
        filter: generatedFilters,
      };

      const response = await getEmployees(payload);
      if (response) {
        setEmps(response.employees);
        setTotal(response.total);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Checked users for group
  const [checked, setChecked] = useState([]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  // Selected user for private chat
  const [selectedUser, setSelectedUser] = useState({ id: "" });

  // Create private chat
  const [privateName, setPrivateName] = useState();
  const handleCreatePrivate = async (type, selectedUserId) => {
    const response = await postPrivateChat(type, [selectedUserId]);
    dispatch({
      type: "SET_SUBMITTED",
      payload: !submitted,
    });
    dispatch({
      type: "SET_CREATE_CHAT_DIALOG",
      payload: false,
    });
    dispatch({
      type: "SET_SEARCH_USERNAME",
      payload: "",
    });
    setChecked([]);
  };

  // Create group chat
  const [groupName, setGroupName] = useState();
  const handleCreateGroup = async (type, groupName, checked) => {
    const response = await postGroupChat(type, groupName, checked);
    dispatch({
      type: "SET_SUBMITTED",
      payload: !submitted,
    });
    dispatch({
      type: "SET_CREATE_CHAT_DIALOG",
      payload: false,
    });
    dispatch({
      type: "SET_SEARCH_USERNAME",
      payload: "",
    });
    setChecked([]);
    setSelectChat("private");
  };
  const convertToEnglish = (text) => {
    // Define character mappings
    const charMap = {
      İ: "I",
      // Add more character mappings as needed
    };

    // Replace characters using regular expression
    return text.replace(/[İ]/g, (char) => charMap[char]);
  };
  return (
    <>
      <Dialog
        fullWidth
        open={createChatDialog}
        onClose={() => {
          dispatch({
            type: "SET_CREATE_CHAT_DIALOG",
            payload: false,
          });
          dispatch({
            type: "SET_SEARCH_USERNAME",
            payload: "",
          });
          setSelectChat("private");
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" mb={-3}>
          <Typography variant="h4" mb={4}>
            Çat yarat
          </Typography>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Çat növünü seçin </FormLabel>
            <RadioGroup
              row
              aria-label="Çat seçin"
              name="selectChat"
              defaultValue="private"
              onChange={(e) => {
                setSelectChat(e.target.value);
                dispatch({
                  type: "SET_SEARCH_USERNAME",
                  payload: "",
                });
              }}
            >
              <FormControlLabel
                value="private"
                control={<Radio />}
                label="Şəxsi"
              />
              <FormControlLabel
                value="group"
                control={<Radio />}
                label="Qrup"
              />
            </RadioGroup>
          </FormControl>
        </DialogTitle>
        {selectChat === "private" ? (
          // Private chat
          <>
            <DialogContent>
              {/* <TextField
                autoFocus
                margin="dense"
                id="privateName"
                label="Çat adı"
                type="text"
                onChange={(e) => setPrivateName(e.target.value)}
                fullWidth
                required
              /> */}
              {/* User Search */}
              <Typography variant="h6" fontSize={12} textAlign={"center"}>
                Axtarış üçün daxil edin:
              </Typography>
              <Box
                my={3}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <TextField
                  sx={{ width: "33%" }}
                  label="Ad"
                  onChange={(e) => {
                    setFilter((prevState) => ({
                      ...prevState,
                      firstname: convertToEnglish(e.target.value),
                    }));
                  }}
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  sx={{ width: "33%" }}
                  label="Soyad"
                  onChange={(e) => {
                    setFilter((prevState) => ({
                      ...prevState,
                      lastname: convertToEnglish(e.target.value),
                    }));
                  }}
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  sx={{ width: "33%" }}
                  label="Ata adı"
                  onChange={(e) => {
                    setFilter((prevState) => ({
                      ...prevState,
                      fathername: convertToEnglish(e.target.value),
                    }));
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Box>

              {/* User list */}
              <List
                dense
                fullWidth
                sx={{
                  maxHeight: "40vh",
                  bgcolor: "background.paper",
                  overflow: "auto",
                }}
              >
                {emps.map((item, index) => {
                  if (item.user !== user.id) {
                    return (
                      <ListItem key={index} disablePadding>
                        <ListItemButton
                          selected={selectedUser._id === item._id}
                          onClick={() => {
                            setSelectedUser(item);
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              alt={`Avatar n°${index + 1}`}
                              src={`/static/img/avatars/user.png`}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            id={item._id}
                            primary={`${item.firstname} ${item.lastname} ${item.fathername}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  }
                })}
              </List>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  dispatch({
                    type: "SET_CREATE_CHAT_DIALOG",
                    payload: false,
                  });
                  dispatch({
                    type: "SET_SEARCH_USERNAME",
                    payload: "",
                  });
                }}
                color="primary"
              >
                Ləğv et
              </Button>
              <Button
                onClick={() => {
                  handleCreatePrivate("private", selectedUser._id);
                  setSelectedUser("");
                }}
                color="primary"
                disabled={!selectedUser._id}
              >
                Çat yarat
              </Button>
            </DialogActions>
          </>
        ) : (
          // Group chat
          <>
            <DialogContent>
              {/* <DialogContentText>
                To subscribe to this website, please enter your email address
                here. We will send updates occasionally.
              </DialogContentText> */}
              <TextField
                autoFocus
                margin="dense"
                id="groupName"
                label="Qrup çat adı"
                type="text"
                fullWidth
                onChange={(e) => {
                  setGroupName(e.target.value);
                }}
                required
              />

              {/* User Search */}
              <Box
                my={3}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <TextField
                  sx={{ width: "33%" }}
                  label="Ad"
                  onChange={(e) => {
                    setFilter((prevState) => ({
                      ...prevState,
                      firstname: e.target.value,
                    }));
                  }}
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  sx={{ width: "33%" }}
                  label="Soyad"
                  onChange={(e) => {
                    setFilter((prevState) => ({
                      ...prevState,
                      lastname: e.target.value,
                    }));
                  }}
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  sx={{ width: "33%" }}
                  label="Ata adı"
                  onChange={(e) => {
                    setFilter((prevState) => ({
                      ...prevState,
                      fathername: e.target.value,
                    }));
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Box>

              {/* User list */}
              <List
                dense
                fullWidth
                sx={{
                  maxHeight: "40vh",
                  bgcolor: "background.paper",
                  overflow: "auto",
                }}
              >
                {emps.map((item, index) => {
                  const labelId = `checkbox-list-secondary-label-${item.user}`;
                  if (item.user !== user.id) {
                    return (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onChange={handleToggle(item._id)}
                            checked={checked.indexOf(item._id) !== -1}
                            aria-labelledby={labelId}
                          />
                        }
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemAvatar>
                            <Avatar
                              alt={`Avatar n°${index + 1}`}
                              src={`/static/img/avatars/user.png`}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            id={item._id}
                            primary={`${item.firstname} ${item.lastname} ${item.fathername}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  }
                })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  dispatch({
                    type: "SET_CREATE_CHAT_DIALOG",
                    payload: false,
                  });
                  dispatch({
                    type: "SET_SEARCH_USERNAME",
                    payload: "",
                  });
                  setSelectChat("private");
                }}
                color="primary"
              >
                Ləğv et
              </Button>
              <Button
                onClick={() => {
                  handleCreateGroup("group", groupName, checked);
                }}
                color="primary"
                disabled={!groupName || checked?.length === 0}
              >
                Qrupu yarat
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default CreateChatDialog;
