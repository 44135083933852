/* eslint-disable prettier/prettier */

// Private Base
import { Add as AddIcon, Delete } from "@mui/icons-material";
import { RelationshipInfoEnum } from "./constants/constants";
export const BASE_URL =
  process.env.REACT_APP_API_URL_FROM_YAML ?? process.env.REACT_APP_API_URL;
export const PUBLIC_URL =
  process.env.REACT_APP_API_PUBLIC_FROM_YAML ??
  process.env.REACT_APP_PUBLIC_URL;
// Themes
export const THEMES = {
  // DEFAULT: "DEFAULT",
  DARK: "DARK",
  // LIGHT: "LIGHT",
  // BLUE: "BLUE",
  // GREEN: "GREEN",
  // INDIGO: "INDIGO",
};

export const templateTypes = [
  "order",
  "agreement",
  "application",
  "presentation-essay",
];
export const templateInputTypes = ["string", "number", "date"];
export const templateInputSource = ["custom", "[default"];
export const correctTemplateExtensions = ["doc", "docx"];

export const errorMessageTranslate = (message) => {
  const phraseToTranslate = "already has an application for";
  const translatedPhrase = "artıq ərizəyə malikdir";

  const translations = {
    "Invalid overtime day:": "Yanlış əlavə iş günü:",
    "Updating not allowed. Application status:":
      "Yenilənməyə icazə verilmir. Tətbiq statusu:",
    "Invalid structure for employee:": "İşçi üçün etibarsız struktur:",
    "Deletion not allowed. Application status:":
      "Silməyə icazə verilmir. Tətbiq statusu:",
    "Staff not found for:": "Staff tapılmadı:",
    "Approved contract not found for employee:":
      "İşçi üçün təsdiqlənmiş müqavilə tapılmadı:",
    "for employee:": "işçi üçün:",
    "is under 18 and not eligible for overtime.":
      "yaşı 18-dən azdır və əlavə iş üçün uyğun deyil.",
    "is not eligible for overtime due to unsuitable working condition.":
      "uyğun olmayan iş şəraitinə görə iş vaxtından artıq iş üçün uyğun deyil.",
    "All contracts should belong to the same structure.":
      "Bütün müqavilələr eyni struktura aid olmalıdır.",
    "Employee structure mismatch.": "İşçi strukturunun uyğunsuzluğu.",
    "An application for this employee already exists in this request":
      "Bu sorğuda bu işçi üçün ərizə artıq mövcuddur",
    "Extended overtime should not be over 2 hours due to heavy or harmful work conditions.":
      "Ağır və ya zərərli iş şəraiti səbəbindən əlavə iş vaxtı 2 saatdan çox olmamalıdır.",
    "Non-working day overtime should not be over 10 hours due to heavy or harmful work conditions.":
      "Ağır və ya zərərli iş şəraiti ilə əlaqədar qeyri-iş günü əlavə iş vaxtı 10 saatdan çox olmamalıdır",
    "Employee is not eligible for overtime due to unsuitable working condition.":
      "İşçi yararsız iş şəraitinə görə iş vaxtından artıq işləmək hüququna malik deyil",
    "Approved contract not found.": "Təsdiq edilmiş müqavilə tapılmadı.",
    "Staff not found.": "Staff tapılmadı.",
    "Applications not found.": "Tətbiqlər tapılmadı.",
    "Employees not found.": "İşçilər tapılmadı.",
    "Something went wrong": "Xəta baş verdi",
    "Contract type not found.": "Müqavilə növü tapılmadı.",
    "For extended overtime, work shift hours cannot exceed 4.":
      "Uzadılmış iş vaxtı üçün iş növbəsi saatlarından çox ola bilməz 4.",
  };
  if (
    message.startsWith("Invalid overtime day:") ||
    message.startsWith("Invalid structure for employee:") ||
    message.startsWith("Deletion not allowed. Application status:") ||
    message.startsWith("Staff not found for:") ||
    message.startsWith("Approved contract not found for employee:") ||
    message.startsWith("Updating not allowed. Application status:")
  ) {
    const parts = message.split(": ");
    const translatePart = parts[0] + ":";
    const originPart = parts[1];

    return `${translations[translatePart] || translatePart} ${originPart}`;
  }
  if (
    message.endsWith("is under 18 and not eligible for overtime.") ||
    message.endsWith(
      "is not eligible for overtime due to unsuitable working condition."
    )
  ) {
    const parts = message.split("is ");
    const translatePart = "is " + parts[1];
    const originPart = parts[0];

    return `${originPart} ${translations[translatePart] || translatePart} `;
  }
  if (message.includes(phraseToTranslate)) {
    let [name, datePart] = message.split(phraseToTranslate);
    datePart = datePart.trim().replace(/\.$/, "");
    return `${name.trim()} ${datePart.trim()} ${translatedPhrase} `;
  }
  return translations[message] || message;
};

export const clauses = [
  {
    parent:
      "72. Əmək vəzifələrinin kobud şəkildə pozulması hesab edilən hallar",
    children: {
      sub_title: "",
      children: [
        "72 - a) özünün xəstəliyi, yaxın qohumunun xəstələnməsi və ya vəfat etməsi istisna olunmaqla heç bir üzürlü səbəb olmadan bütün iş günü işə gəlməməsi",
        "72 - b) alkoqollu içkilər, narkotik vasitələr və psixotrop, toksik və digər zəhərli maddələr qəbul edərək sərxoş vəziyyətdə işə gəlməsi, habelə işə gəldikdən sonra iş yerində həmin içkiləri və ya maddələri qəbul edərək sərxoş vəziyyətdə olması",
        "72 - c) təqsirli hərəkətləri (hərəkətsizliyi) nəticəsində mülkiyyətçiyə maddi ziyan vurması",
        "72 - ç) təqsirli hərəkətləri (hərəkətsizliyi) nəticəsində əməyin mühafizəsi qaydalarını pozaraq iş yoldaşlarının səhhətinə xəsarət yetirməsi və ya onların bu səbəbdən həlak olması",
        "72 - d) qəsdən istehsal, kommersiya və ya dövlət sirrinin yayılması və ya bu sirrin gizli saxlanılması üzrə öhdəliklərini yerinə yetirməməsi",
        "72 - e) əmək fəaliyyəti zamanı yol verdiyi kobud səhvləri, hüquq pozuntuları nəticəsində işəgötürənin, müəssisənin və ya mülkiyyətçinin qanuni mənafeyinə ciddi xələl gətirməsi",
        "72 - ə) əmək funksiyasını pozduğuna görə əvvəllər işəgötürən tərəfindən verilmiş intizam tənbehindən nəticə çıxarmayaraq altı ay ərzində təkrarən əmək funksiyasını pozması",
        "72 - f) iş vaxtı ərzində bilavasitə iş yerində inzibati xətalara və ya cinayət tərkibi olan ictimai-təhlükəli əməllərə yol verməsi",
      ],
    },
  },
  {
    parent: "62. İşçinin işdən kənar edilməsi",
    children: {
      sub_title: "",
      children: [
        "62 - a) işçi alkoqollu içkilər, narkotik vasitələr və psixotrop, toksik və digər zəhərli maddələr qəbul edərək sərxoş vəziyyətdə işə gəldikdə, habelə işə gəldikdən sonra iş yerində həmin içkiləri və ya maddələri qəbul edərək sərxoş vəziyyətdə olduqda",
        "62 - b) bu Məcəllənin 226-cı maddəsi ilə müəyyən edilmiş hallarda işçi icbari tibbi müayinə olunmaqdan boyun qaçırdıqda və ya keçirilmiş müayinələrin nəticələrinə əsasən həkim komissiyalarının verdikləri tövsiyələri yerinə yetirmədikdə",
        "62 - c) iş vaxtı ərzində işçi bilavasitə iş yerində müvafiq səlahiyyətli orqanın qərarı ilə təsdiqlənən inzibati xətaları və ya cinayət tərkibi olan ictimai-təhlükəli əməllər törətdikdə",
        "62 - ç) insanın immunçatışmazlığı virusu ilə yaşayan şəxslərin işləməsinə yol verilməyən peşə və vəzifələrdə işləyən işçi vaxtaşırı insanın immunçatışmazlığı virusuna mütləq tibbi müayinədən imtina etdikdə",
      ],
    },
  },
];

export const clauses_EM = [
  {
    parent: "68.2. Əmək müqaviləsinə xitam verilməsinin əsasları",
    children: {
      sub_title: "",
      children: [
        "68.2 - a) tərəflərdən birinin təşəbbüsü;",
        "68.2 - b) əmək müqaviləsinin müddətinin qurtarması;",
        `68.2 - c) əmək şəraitinin şərtlərinin dəyişdirilməsi;`,
        `68.2 - ç) müəssisənin mülkiyyətçisinin dəyişməsi ilə əlaqədar (bu Məcəllənin 63-cü maddəsinin ikinci hissəsində göstərilən işçilər);`,
        "68.2 - d) tərəflərin iradəsindən asılı olmayan hallar",
        `68.2 - e) tərəflərin əmək müqaviləsində müəyyən etdiyi hallar.`,
      ],
    },
  },
  {
    parent: "69. Əmək müqaviləsinin işçi tərəfindən ləğv edilməsi qaydası ",
    children: {
      sub_title: "",
      children: [
        // "69 - 1. İşçi bir təqvim ayı qabaqcadan işəgötürəni yazılı ərizəsi ilə xəbərdar etməklə əmək müqaviləsini ləğv edə bilər.",
        // "69 - 2. Ərizə verildiyi gündən bir təqvim ayı bitdikdən sonra işçi işə çıxmamaq və son haqq-hesabının aparılmasını tələb etmək hüququna malikdir. Bu halda işəgötürən işçinin tələblərini yerinə yetirməyə borcludur.",
        "69 - 3. İşçi yaşa, əlilliyə görə təqaüdə çıxdıqda, təhsilini davam etdirmək üçün müvafiq təhsil müəssisəsinə daxil olduqda, yeni yaşayış yerinə köçdükdə, başqa işəgötürənlə əmək müqaviləsi bağladıqda, seksual qısnamaya məruz qaldıqda və qanunvericilikdə nəzərdə tutulmuş digər hallarda ərizəsində göstərdiyi gün əmək müqaviləsi ləğv edilə bilər.",
        // "69 - 4. Əmək müqaviləsini ləğv etmək barədə ərizə vermiş işçi xəbərdarlıq müddəti bitənədək istədiyi vaxt ərizəsini geri götürə və ya onu etibarsız hesab etmək barədə işəgötürənə yeni ərizə verə bilər. Bu halda əmək müqaviləsi ləğv edilə bilməz. Bu şərtlə ki, işəgötürən həmin vəzifəyə (peşəyə) yeni işçinin götürülməsi barədə işçiyə rəsmi qaydada yazılı xəbərdarlıq etməmiş olsun. Əmək müqaviləsi bu maddə ilə müəyyən edilmiş qaydalara əməl olunmaqla ləğv edildikdən sonra işçinin əvvəlki ərizəsini geri götürmək və ya onu etibarsız hesab etmək barədə edilən müraciətin hüquqi qüvvəsi yoxdur.",
        // "69 - 5. İşçi ərizəsində əmək münasibətlərini dayandırmaq istədiyi günü göstərməyibsə, xəbərdarlıq müddəti bitənədək bu maddə ilə müəyyən edilmiş əsasla əmək müqaviləsinin ləğv edilməsinə yol verilmir.",
        // "69 - 6. İşçi məzuniyyət hüququndan istifadə etməklə ona müvafiq iş ilinə görə məzuniyyətin verilməsi və məzuniyyət müddətinin qurtardığı gündən əmək müqaviləsinin ləğv edilməsi xahişi ilə işəgötürənə ərizə verə bilər. İşçi məzuniyyət müddəti bitənədək bu maddənin 4-cü hissəsi ilə müəyyən edilmiş qaydada əmək müqaviləsinin ləğv edilməsi barədə ərizəsini geri götürə və ya onu etibarsız hesab etmək barədə işəgötürənə yazılı müraciət edə bilər. Bu halda işçinin xahişi təmin edilməlidir.",
        // "69 - 7. İşəgötürən tərəfindən zor işlədilərək, hədə-qorxu gələrək, yaxud hər hansı başqa üsulla işçinin iradəsinin əleyhinə əmək müqaviləsini ləğv etməyə onu məcbur etmək qadağandır."
      ],
    },
  },
  {
    parent:
      "70. İşəgötürən tərəfindən əmək müqaviləsinin ləğv edilməsinin əsasları",
    children: {
      sub_title: "",
      children: [
        "70 - a) müəssisə ləğv edildikdə;",
        "70 - b) işçilərin sayı və ya ştatları ixtisar edildikdə;",
        "70 - c) peşəkarlıq səviyyəsinin, ixtisasının (peşəsinin) kifayət dərəcədə olmadığına görə işçinin tutduğu vəzifəyə uyğun gəlmədiyi barədə səlahiyyətli orqan tərəfindən müvafiq qərar qəbul edildikdə;",
        "70 - ç) işçi özünün əmək funksiyasını və ya əmək müqaviləsi üzrə öhdəliklərini yerinə yetirmədikdə, yaxud bu Məcəllənin 72-ci maddəsində sadalanan hallarda əmək vəzifələrini kobud şəkildə pozduqda;",
        "70 - d) sınaq müddəti ərzində işçi özünü doğrultmadıqda;",
        "70 - e) dövlət büdcəsindən maliyyələşən müəssisənin işçisi çalışmanın yaş həddinə çatdıqda.",
      ],
    },
  },
  // {
  //   parent: "71. Əmək müqaviləsi işəgötürən tərəfindən ləğv edilərkən görülən tədbirlər",
  //   children: {
  //     sub_title: "",
  //     children: [
  //       "71 - 1. İşçilərin sayı azaldılarkən və ya ştatların ixtisarı həyata keçirilərkən, o cümlədən işçinin tutduğu vəzifəyə uyğun gəlmədiyi barədə səlahiyyətli orqan tərəfindən müvafiq qərar qəbul edilərkən işəgötürən bu Məcəllə ilə müəyyən edilmiş tədbirlər görür.",
  //       "71 - 2. Bu Məcəllənin 70-ci maddəsinin «ç» bəndində nəzərdə tutulan əsasla əmək müqaviləsinin ləğv edilməsinə bu şərtlə yol verilir ki, işçi qəsdən və ya səhlənkarlıqla, etinasızlıqla əmək funksiyasını, vəzifə borcunu (öhdəliklərini) yerinə yetirmədiyinə görə müvafiq iş yerində işin, istehsalın, əmək və icra intizamının normal ahəngi pozulmuş olsun və ya mülkiyyətçinin, işəgötürənin, habelə əmək kollektivinin (onun ayrı-ayrı üzvlərinin) hüquqlarına və qanunla qorunan mənafelərinə hər hansı formada ziyan dəymiş olsun.",
  //       "71 - 3. İşəgötürən bu Məcəllənin 70-ci maddəsi ilə müəyyən edilmiş əsasların hər hansı biri ilə əmək müqaviləsinin ləğv edilməsinin zəruriliyini əsaslandırmalıdır.",
  //     ]
  //   }
  // },
  {
    parent:
      "74. Tərəflərin iradəsindən asılı olmayan hallarda əmək müqaviləsinə xitam verilməsinin əsasları ",
    children: {
      sub_title: "",
      children: [
        "74 - a) işçi hərbi və ya alternativ xidmətə çağırıldıqda;",
        "74 - b) əvvəllər müvafiq işdə (vəzifədə) çalışan işçinin işinə (vəzifəsinə) bərpa edilməsi barədə məhkəmənin qanuni qüvvəyə minmiş qətnaməsi (qərarı) olduqda.",
        "74 - c) qanunvericiliklə daha uzun müddət müəyyən edilməyibsə, əmək qabiliyyətinin fasiləsiz olaraq altı aydan çox müddətə tam itirilməsi ilə əlaqədar işçi əmək funksiyasını yerinə yetirə bilmədikdə;",
        "74 - ç) İşçinin nəqliyyat vasitəsini idarəetmə hüququndan məhrum etmə, müəyyən vəzifə tutma və ya müəyyən fəaliyyətlə məşğul olma hüququndan məhrum etmə, müəyyən müddətə azadlıqdan məhrum etmə və ya ömürlük azadlıqdan məhrum etmə cəzasına məhkum edildiyi barədə məhkəmənin hökmü qanuni qüvvəyə mindikdə;",
        "74 - d) məhkəmənin qanuni qüvvəyə minmiş qərarı ilə işçinin fəaliyyət qabiliyyətsizliyi təsdiq edildikdə;",
        "74 - e) işçi vəfat etdikdə, məhkəmənin qanuni qüvvəyə minmiş qərarı ilə xəbərsiz itkin düşmüş və ya ölmüş elan edildikdə;",
        "74 - ə) əvvəllər həmin müəssisədə çalışan işçi müddətli həqiqi hərbi xidmətdən ehtiyata buraxıldıqdan sonra öz iş yerinə (vəzifəsinə) qayıtmaq hüququndan istifadə etdikdə;",
        "74 - f) qanunla pedaqoji fəaliyyətlə məşğul olması qadağan edilmiş şəxslə pedaqoji fəaliyyətlə məşğul olmaq üçün əmək müqaviləsinin bağlandığı aşkar edildikdə.",
      ],
    },
  },
  {
    parent:
      "75.2 Əmək müqaviləsində nəzərdə tutulan hallarda ona xitam verilməsi barədə tərəflərin qarşılıqlı razılığı ilə aşağıdakı əlavə hallar nəzərdə tutula bilər",
    children: {
      sub_title: "",
      children: [
        "75.2 - a) tərəflərin qarşılıqlı razılığı;",
        "75.2 - b) səhhəti ilə əlaqədar olaraq işçinin müvafiq vəzifədə (peşədə) çalışması sağlamlığı üçün təhlükəli olduğu barədə tibb müəssisəsinin müvafiq rəyinə görə;",
        "75.2 - c) əmək funksiyasının müəyyən müddətdə icrası zamanı müvafiq iş yerində peşə xəstəliyinə tutulmanın yüksək ehtimalı olduğu halda;",
        "75.2 - ç) işin və ya göstərilən xidmətlərin həcminin azalması ilə əlaqədar müəyyən dövr keçdikdən sonra işçi ilə hökmən yenidən əmək müqaviləsi bağlayacağı şərti ilə işəgötürən yazılı formada məcburi öhdəlik götürdükdə;",
        "75.2 - d) bu maddənin tələblərinə əməl edilməklə tərəflərin müəyyən etdiyi digər hallar.",
      ],
    },
  },
];
export const hours = [
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:15",
  "15:15",
  "15:15",
  "15:15",
  "15:15",
  "15:15",
  "15:15",
  "15:15",
  "15:15",
  "15:15",
  "15:15",
];

export function generateHoursArray() {
  var hours = [];
  for (var hour = 5; hour < 24; hour++) {
    for (var minute = 0; minute < 60; minute += 1) {
      hours.push(
        `${hour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`
      );
    }
  }
  return hours;
}

export const GRAPHIC_TYPES = {
  standard: "standart",
  shift: "növbəli",
  flexible: "sürüşkən",
};

export const TYPES_ENUM = {
  STRING: "string",
  NUMBER: "number",
  DATE: "date",
};

export const SUBJECT_TYPES_ENUM = {
  COMPANY_PART: "CompanyPart",
  EMPLOYEE: "Employee",
  MANAGEMENT: "Management",
  COMPANY_STRUCTURE: "CompanyStructure",
  STAFF: "Staff",
};

export const SUBJECT_TYPES = [
  // {
  //   _id: "1",
  //   name: "CompanyPart",
  // },
  {
    _id: "1",
    name: "Employee",
    label: "Əməkdaş",
  },
  // {
  //   _id: "2",
  //   name: "Management",
  //   label: "Rəhbərlik",
  // },
  // {
  //   _id: "4",
  //   name: "CompanyStructure",
  // },
  // {
  //   _id: "5",
  //   name: "Staff",
  // }
];

export const structureNamingsEnum = {
  ORGANIZATION: "organization",
  POSITION: "position",
  MANAGEMENT: "management",
  DEPARTMENT: "Departament",
};

export const docModelTypes = {
  COMPANYPART: "CompanyPart",
  MANAGEMENT: "Management",
};

export const tempLogoUrl =
  "https://media.istockphoto.com/id/1406197730/photo/portrait-of-a-young-handsome-indian-man.webp?s=1024x1024&w=is&k=20&c=VruKKTu4jBF2xPEEQUMWwd4bwJPysSsqLuZ7h1OyD8M=";

export const notFoundLogoUrl =
  "https://brightspotcdn.byu.edu/dims4/default/e74744e/2147483647/strip/true/crop/340x340+0+0/resize/200x200!/quality/90/?url=https%3A%2F%2Fbrigham-young-brightspot.s3.amazonaws.com%2Fbd%2F7f%2Face2612141aa8c6ad180b0786739%2Fdefault-pfp.jpg";

export const BUTTON_STATE_ENUMS = {
  CONTRACT_IN_DRAFT: {
    name: "contract_in_draft",
    status: "contract_in_draft",
    case: "#5252ab75",
    content: "Ə.M Müqaviləsi təsdiq gözləyir",
    // icon: <Delete />,
  },
  EMPTY: {
    name: "empty",
    status: "empty",
    case: "#1B263B",
    content: "Əməkdaş təyin et",
    icon: <AddIcon />,
  },
  ACTIVE: {
    name: "active",
    status: "success",
    case: "green",
    content: "Aktiv",
  },
  PENDING_APPLICATION: {
    name: "pending_application",
    status: "pending",
    case: "#FFCF96",
    content: "Ərizə təsdiqi gözlənilir",
  },
  REJECTED_APPLICATION: {
    name: "rejected_application",
    status: "error",
    case: "#c91313",
    content: "Ərizə imtina edilmişdir",
  },
  PENDING_CONTRACT: {
    name: "pending_contract",
    status: "pending",
    case: "#BEAEE2",
    content: "Müqavilə tərtib et",
  },
  PENDING_CONTRACT_APPROVE: {
    name: "pending_contract_approve",
    status: "pending",
    case: "#F4A9A8",
    content: "Ə.M. imzalanması",
  },
  REJECTED_CONTRACT_APPROVE: {
    name: "rejected_contract_approve",
    status: "error",
    case: "#c91313",
    content: "Müqavilə imzalanmamışdır",
  },
  PENDING_ORDER: {
    name: "pending_order",
    status: "pending",
    case: "#6dbab0",
    content: "Əmr tərtib et",
  },
  PENDING_ORDER_APPROVE: {
    name: "pending_order_approve",
    status: "pending",
    case: "#d9b470",
    content: "Əmr imzası gözlənilir",
  },
  REJECTED_ORDER_APPROVE: {
    name: "rejected_order_approve",
    status: "pending",
    case: "#c91313",
    content: "Əmr imtina edilmişdir",
  },
  PENDING_TERMINATION_ORDER_APPROVE: {
    name: "pending_termination_order_approve",
    status: "pending",
    case: "#51254f",
    content: "Xitam əmri təsdiqi gözlənir",
  },
  PENDING_POSITION_CHANGE_CONTRACT_APPROVE: {
    name: "pending_position_change_contract_approve",
    status: "pending_position_change_contract_approve",
    case: "#da55a58c",
    content: "Vəzifə dəyişikliyi təsdiqi gözlənir",
  },
  TEMPORARILY_FILLED: {
    name: "temporarily_filled",
    status: "temporarily_filled",
    case: "#736585",
    content: "Müvəqqəti əvəz edir",
  },
};

export const STAFF_TIME = [
  {
    _id: "1",
    timeId: 0.25,
    content: "0.25",
  },
  {
    _id: "2",
    timeId: 0.5,
    content: "Yarım ştat (0.5)",
  },
  {
    _id: "3",
    timeId: 0.75,
    content: "0.75",
  },
  {
    _id: "4",
    timeId: 1,
    content: "Tam ştat (1)",
  },
];

const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

export const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
      color: "#fff",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};

export const WEEK_DAYS = [
  {
    value: "Bazar Ertəsi",
    label: "Bazar Ertəsi",
    disable: false,
  },
  {
    value: "Çərşənbə axşamı",
    label: "Çərşənbə axşamı",
    disable: false,
  },
  {
    value: "Çərşənbə",
    label: "Çərşənbə",
    disable: false,
  },
  {
    value: "Cümə axşamı",
    label: "Cümə axşamı",
    disable: false,
  },
  {
    value: "Cümə",
    label: "Cümə",
    disable: false,
  },
  {
    value: "Şənbə",
    label: "Şənbə",
    disable: false,
  },
  {
    value: "Bazar",
    label: "Bazar",
    disable: false,
  },
];

export const WORKING_MODE = {
  FIVE_DAYS: 5,
  SIX_DAYS: 6,
};

export const SIGNING_CARD_TYPES = {
  PENDING: "PENDING",
  SIGNED: "SIGNED",
  CANCELED: "CANCELED",
};

export const EMPLOYMENT_CONTRACT_TYPE = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
  EXPIRED: "expired",
  TERMINATED: "terminated",
  DRAFT: "draft",
};

export const LEAVES_STATUS = {
  PENDING_EXPLANATION: "pendingExplanation",
  CANCELLED: "cancelled",
  REJECTED: "rejected",
  PENDING_ORDER: "pendingOrder",
  PENDING_ORDER_APPROVE: "pendingOrderApprove",
  ACTIVE: "active",
  EXPLANATION_SENT: "explanationSent",
};

export const OPERATORS_CONTAINING = {
  DATE: [
    { key: "isAfter", name: "sonra" },
    { key: "isBefore", name: "əvvəl" },
    { key: "is", name: "bərabər olan" },
    { key: "isNot", name: "bərabər olmayan" },
    { key: "dateBetween", name: "tarix aralıqında" },
  ],
  TEXT: [
    {
      key: "contains",
      name: "ibarət olan",
    },
    {
      key: "startsWith",
      name: "başlayan",
    },
    {
      key: "endsWith",
      name: "bitən",
    },
    { key: "isEmpty", name: "boş olan" },
    {
      key: "isNotEmpty",
      name: "boş olmayan",
    },
  ],
  OPTION: [
    {
      key: "equalsTo",
      name: "bərabər olan",
    },
    {
      key: "notEqual",
      name: "bərabər olmayan",
    },
  ],
  OPTION_MULTIPLE: [
    {
      key: "equal",
      name: "bərabər olan",
    },
  ],
  TIME: [
    {
      key: "day",
      name: "gün",
    },
    {
      key: "week",
      name: "həftə",
    },
    {
      key: "month",
      name: "ay",
    },
    {
      key: "year",
      name: "il",
    },
  ],
  SINGLE_OPTION: [
    {
      key: "equalsTo",
      name: "bərabər olan",
    },
  ],
  IN: [
    {
      key: "in",
      name: "bərabər olan",
    },
    {
      key: "nin",
      name: "bərabər olmayan",
    },
  ],
  OPTION_NUMBER: [
    {
      key: "equalsTo",
      name: "bərabər olan",
    },
    {
      key: "notEqual",
      name: "bərabər olmayan",
    },
  ],
  NUMBER: [
    {
      key: "greaterThan",
      name: "böyük",
    },
    {
      key: "lessThan",
      name: "kiçik",
    },
    {
      key: "greaterThanEqual",
      name: "böyük bərabər",
    },
    {
      key: "lessThanEqual",
      name: "kiçik bərabər",
    },
    {
      key: "between",
      name: "arasında",
    },
  ],
  SINGLE_NUMBER: [
    {
      key: "equalsTo",
      name: "bərabər olan",
    },
  ],
  IS_NUMBER: [
    {
      key: "equalsNumber",
      name: "bərabər olan",
    },
    {
      key: "notEqualsNumber",
      name: "bərabər olmayan",
    },
  ],
};

export const CONTRACT_TYPE = {
  LABOUR: "labour-contract",
  ORDER: "vacation-order",
};

export const DOCUMENT_NAME = {
  "labour-contract": "Əmək Müqaviləsi",
  labourContractUpdate: "Əmək müqaviləsində dəyişiklik əmri",
  "labour-order": "Əmək müqaviləsinin rəsmiləşdirilməsi",
  "vacation-order": "Məzuniyyət",
  "exclusion-order": "İşdən kənarlaşdırma",
  "labour-terminate-order": "İşə xitam",
  "structure-request-service": "Struktur dəyişiklik sorğusu",
  "structure-request-order": "Struktur dəyişiklik əmri",
};

export const STAFFS_STATUS_TYPE = {
  PENDING_APPLICATION: "pending_application",
  REJECTED_APPLICATION: "rejected_application",
  PENDING_CONTRACT: "pending_contract",
  PENDING_CONTRACT_APPROVE: "pending_contract_approve",
  REJECTED_CONTRACT_APPROVE: "rejected_contract_approve",
  PENDING_ORDER: "pending_order",
  PENDING_ORDER_APPROVE: "pending_order_approve",
  INACTIVE: "inactive",
  EMPTY: "empty",
  ACTIVE: "active",
};

export const STAFF_DROPDOWN_VALUE = [
  { name: "0.25", key: 0.25 },
  { name: "0.5", key: 0.5 },
  { name: "0.75", key: 0.75 },
  { name: "1", key: 1 },
];
export const isForeignCountry = [
  { name: "Xarici", key: true },
  { name: "Ölkədaxili", key: false },
];
export const overTimeType = [
  { name: "İş vaxtından artıq işə çıxma", key: "Extended" },
  { name: "Qeyri iş günlərində işə çıxma", key: "Non_Working_Day" },
];
export const paymentType = [
  { name: "İkiqat", key: "Double" },
  { name: "Əvəzgün", key: "Substitute_Day" },
];
export const clauseValue = [
  {
    name: "Maddə 101 a. dövlətin müdafiəsinin təmin olunması üçün, habelə təbii fəlakətin, istehsal qəzasının qarşısını almaq və ya onların nəticələrini aradan qaldırmaq üçün yerinə yetirilməsi zəruri olan ən vacib işlərin görülməsinə",
    key: "State_Defense",
  },
  {
    name: "Maddə 101 b. su, qaz və elektrik təchizatı, isitmə, kanalizasiya, rabitə və digər kommunal müəssisələrində işlərin, xidmətlərin pozulmasına səbəb olan gözlənilməz hadisələrin nəticələrini aradan qaldırmaq üçün zəruri işlərin görülməsini təmin etmək üçün",
    key: "Utility_Service_Continuity",
  },
  {
    name: "Maddə 101 a. dövlətin müdafiəsinin təmin olunması üçün, habelə təbii fəlakətin, istehsal qəzasının qarşısını almaq və ya onların nəticələrini aradan qaldırmaq üçün yerinə yetirilməsi zəruri olan ən vacib işlərin görülməsinə",
    key: "Unfinished_Technical_Work",
  },
  {
    name: "Maddə 101 ç. işçilərin əksəriyyətinin işinin dayandırılmasına səbəb olan sıradan çıxmış mexanizmlərin, qurğuların təmiri, bərpası ilə əlaqədar işlərin görülməsi zərurəti olduqda",
    key: "Critical_Repair_Work",
  },
  {
    name: "Maddə 101 d. əvəz edən işçinin işdə olmaması ilə əlaqədar işə fasilə verilməsinə yol vermək mümkün olmadıqda.",
    key: "Substitute_Employee_Absence",
  },
  {
    name: "Maddə 107 Fasiləsiz istehsalatlar, ticarət, ictimai iaşə, rabitə, nəqliyyat və digər xidmət müəssisələri",
    key: "Critical_Repair_Work",
  },
];

export const isLocked = [
  { name: "Tabel bağlıdır", key: true },
  { name: "Tabel aktivdir ", key: false },
];

export const EMPLOYEE_REQUEST_TYPE_NORMALIZED_NAME = {
  VacationRequest: "Məzuniyyət sorğusu",
  EmploymentRequest: "İşə təyin olma sorğusu",
  StructureRequest: "Struktur yeniləmə sorğusu",
};

export const VACATION_TYPES = [
  { value: "labor", name: "Əmək məzuniyyəti" },
  { value: "creative", name: "Yaradıcılıq məzuniyyəti" },
  { value: "educational", name: "Təhsil məzuniyyəti" },
  { value: "social", name: "Sosial məzuniyyət" },
  { value: "partialPaidSocial", name: "Qismən ödənişli sosial məzuniyyət" },
  { value: "unpaid", name: "Ödənişsiz məzuniyyət" },
];

export const reasonArr = {
  creativeVacationReason: [
    {
      label: "Fəlsəfə doktoru elmi dərəcəsi almaq ilə əlaqəli - Ə.M. m122, b2",
      value: "DoctoralDissertation",
    },
    {
      label: "Elmler doktoru elmi dərəcəsi almaqla əlaqəli - Ə.M. m122, b2",
      value: "ScientificDoctorateDissertation",
    },
    {
      label:
        "Doktoranturada ( Adyunkturada) təhsil almaq və ya daxil olmaqla əlaqəli - Ə.M m 122 , b3",
      value: "DoctorateEducation",
    },
    {
      label:
        "Dərsliklər və ya dərs vəsaitlərinin yazmaq ilə əlaqəli  - Ə.M m 122, b4",
      value: "TextbookCreation",
    },
  ],
  educationalVacationReason: [
    {
      label:
        "Müvafiq tədris mövsümü sessiyalarında tədris-təlimdə iştirak etmək, laboratoriya işlərini yerinə yetirmək, yoxlamaları və imtahanları vermək üçün – Ə.M. -  m123 a",
      value: "ParticipationInEducation",
    },
    {
      label: "Dövlət imtahanlarını vermək üçün - Ə.M. -  m123 b",
      value: "StateExams",
    },
    {
      label:
        "Diplom layihəsini (işini) hazırlamaq və müdafiə etmək üçün Ə.M. -  m123 c",
      value: "DiplomaPreparation",
    },
  ],
  socialVacationReason: [
    {
      label:
        "Ə.M. Maddə 125, a) Hamiləlik dövründə və doğuşdan sonrakı dövr üçün işləyən qadınlara verilən ödənişli məzuniyyət",
      value: "GeneralMaternity",
    },
    {
      label:
        "Ə.M. Maddə 125, b) Kənd təsərrüfatı istehsalında çalışan qadınlara hamiləliyə və doğuşa görə məzuniyyət",
      value: "AgriculturalWorkersMaternity",
    },
  ],
  unpaidVacationReason: [
    {
      label:
        "Ə.M. Maddə 130, a) Həkim məsləhət komissiyasının rəyi əsasında xroniki xəstəliyə tutulmuş uşağı olan valideynlərdən birinə, yaxud ailənin uşağa bilavasitə qulluq edən digər üzvünə — uşaq dörd yaşına çatanadək.",
      value: "ParentalChronicCare",
    },
    {
      label:
        "Ə.M. Maddə 130, b) Arvadları doğuşla əlaqədar məzuniyyətdə olan kişilərə — 14 təqvim gününədək.",
      value: "MaternitySupport",
    },
    {
      label:
        "Ə.M. Maddə 130, c) 16 yaşınadək uşağı olan qadınlara və ya tək valideynlərə, qəyyumlara, himayədarlara — 14 təqvim gününədək.",
      value: "SingleParentChildCare",
    },
    {
      label:
        "Ə.M. Maddə 130, ç) Orqanizmin funksiyalarının pozulması faizindən və səbəbindən asılı olmayaraq əlilliyi olan, o cümlədən sağlamlıq imkanları məhdud olan 18 yaşınadək işçilərə — bir təqvim ayınadək.",
      value: "WorkersWithDisabilities",
    },
    {
      label:
        "Ə.M. Maddə 130, d) Müharibədə döyüşən ordunun tərkibində iştirak etmiş şəxslərə — 14 təqvim gününədək.",
      value: "WarVeteran",
    },
    {
      label:
        "Ə.M. Maddə 130, e) Azərbaycan Respublikasının ərazi bütövlüyü və suverenliyinin müdafiəsi zamanı xəsarət (yaralanma, travma, kontuziya) almış işçilərə — 14 təqvim gününədək.",
      value: "InjuryDuringDefense",
    },
    {
      label:
        "Ə.M. Maddə 130, ə) İnsanın immunçatışmazlığı virusu ilə yaşayan uşaq böyüdən, həmçinin 18 yaşınadək əlilliyi müəyyən edilmiş uşağı olan valideynlərə — 14 təqvim gününədək.",
      value: "HIVOrDisabledChild",
    },
    {
      label:
        "Ə.M. Maddə 130, f) Doktoranturada (adyunkturada), təhsil alan işçilərə — 1 təqvim ayınadək.",
      value: "DoctorateStudies",
    },
    {
      label:
        "Ə.M. Maddə 130, g) Ali təhsil müəssisələrində qəbul imtahanlarına buraxılan işçilərə — 14 təqvim günü, orta ixtisas təhsil müəssisələrinə qəbul imtahanlarına buraxılan işçilərə — 7 təqvim günü.",
      value: "EntranceExam",
    },
    {
      label:
        "Ə.M. Maddə 130, ğ) İxtirasının və ya səmərələşdirici təklifinin işlədiyi müəssisədən kənar yerdə ilkin tətbiqi zamanı müəllifə — 14 təqvim gününədək.",
      value: "InventionApplication",
    },
    {
      label:
        "Ə.M. Maddə 130, h) Ailənin xəstə üzvünə qulluq edən qohumlardan birinə — tibb müəssisəsinin rəyi ilə 14 təqvim gününədək.",
      value: "SickFamilyCare",
    },
    {
      label:
        "Ə.M. Maddə 130, x) 18 yaşınadək əlilliyi müəyyən edilmiş uşaqları olan işçilərə - 14 təqvim gününədək.",
      value: "DisabledChildCare",
    },
    {
      label:
        "Ə.M. Maddə 130, i) Ailə, məişət və başqa sosial məsələləri həll etmək üçün işçilərə -7 təqvim gününədək.",
      value: "FamilySocialMatters",
    },
    {
      label:
        "Ə.M. Maddə 130, k) Azərbaycan Respublikasının Seçki Məcəlləsinə uyğun olaraq qeydə alınmış namizədə müvafiq seçki komissiyasında qeydə alındığı gündən seçkilərin nəticələrinin rəsmi dərc edildiyi günədək ərizəsində göstərilən müddət üçün ödənişsiz məzuniyyət verilir.",
      value: "ElectionCandidacy",
    },
  ],
};

export const CONTRACTS_TYPE_DOC_MODELS_ENUMS = {
  ORDER: "order",
  AGREEMENT: "agreement",
  APPLICATION: "application",
  SERVICE: "service",
  Explanation: "izahat",
};

export const COLORS_STATUS = {
  BLUE: "rgba(5,42,101, 0.9)",
  RED: "rgba(121,46,46, 0.9)",
  GREEN: "rgba(30,100,63,0.90)",
  ORANGE: "rgba(189,119,52,0.90)",
  PURPLE: "#A52D78",
  DARK_RED: "#9E0021",
  GREY: "rgba(96,96,96,0.90)",
};

export const REQUESTS_STATUS_TYPE = {
  Approved: "approved",
  PendingChainApprove: "pendingChainApprove",
  RejectedByChainUser: "rejectedByChainUser",
  UpdatedByCreator: "updatedByCreator",
  ReRequestedToManager: "reRequestedToManager",
  ReRequestedToCreator: "reRequestedToCreator",
  CancelledByHR: "cancelledByHr",
  PendingOrder: "pendingOrder",
  PendingOrderApprove: "pendingOrderApprove",
};

// status types for filter

export const SYSTEM_CONFIRMATION_STATUS_FOR_FILTER = [
  {
    key: "approved",
    name: "Aktiv",
  },
  {
    key: "pendingChainApprove",
    name: "Təsdiqləmə gözlənilir",
  },
  {
    key: "pendingOrder",
    name: "Əmr tərtibi gözlənilir",
  },
  {
    key: "pendingOrderApprove",
    name: "Əmr imzası gözlənilir",
  },
  {
    key: "rejectedByChainUser",
    name: "Sorğuya imtina edilmişdir",
  },
  {
    key: "cancelledByHr",
    name: "HR tərəfindən ləğv edilmişdir",
  },
  {
    key: "reRequestedToManager",
    name: "Rəhbərə yenidən yönləndirilmişdir",
  },
  {
    key: "reRequestedToCreator",
    name: "Əməkdaşa düzəliş üçün yönləndirilmişdir",
  },
];

export const STRUCTURE_CHGANGE_REQUEST_FOR_FILTER = [
  {
    key: "completed",
    name: "Aktiv",
  },
  {
    key: "pendingHrSign",
    name: "HR imzası gözlənilir",
  },
  {
    key: "pendingRecruiterApprove",
    name: "İşəgötürənin təsdiqi gözlənilir",
  },
  {
    key: "pendingHrChanges",
    name: "Dəyişiklik gözlənilir",
  },
  {
    key: "pendingRecruiterSign",
    name: "Əmr imza gözlənilir",
  },
  {
    key: "rejectedByRecruiter",
    name: "Sorğuya imtina edilmişdir",
  },
  {
    key: "cancelledByHR",
    name: "HR tərəfindən ləğv edilmişdir",
  },
];

export const CHANGE_REQUESTS_FOR_FILTER = [
  {
    key: "approved",
    name: "Aktiv",
  },
  {
    key: "pending",
    name: "Gözlənilir",
  },
  {
    key: "rejected",
    name: "İmtina edilmişdir",
  },
];

export const GENERAL_DOCUMENTS_FOR_FILTER = [
  {
    key: "approved",
    name: "Aktiv",
  },
  {
    key: "pending",
    name: "Sənədin imzalanması gözlənilir",
  },
  {
    key: "pendingTerminationApprove",
    name: "Xitam əmrinin imzası gözlənilir",
  },
  {
    key: "rejected",
    name: "Sənədin imzalanmasına imtina edilmişdir",
  },
  {
    key: "expired",
    name: "Sənəd qüvvədən düşmüşdür",
  },
  {
    key: "terminated",
    name: "Sənəd ləğv edilmişdir",
  },
  {
    key: "draft",
    name: "Ə.M. təsdiq gözlənilir",
  },
];

export const DISSMISAL_FOR_FILTER = [
  {
    key: "active",
    name: "Aktiv",
  },
  {
    key: "pendingExplanation",
    name: "İzahat gözlənilir",
  },
  {
    key: "pendingOrder",
    name: "Əmr tərtibatı gözlənilir",
  },
  {
    key: "pendingOrderApprove",
    name: "Əmr imzası gözlənilir",
  },
  {
    key: "cancelled",
    name: "Ləğv edilmişdir",
  },
  {
    key: "rejectedOrderApprove",
    name: "Əmr imzalanmasına imtina edilmişdir",
  },
  {
    key: "rejected",
    name: "",
  },
  {
    key: "requestedToEmployee",
    name: "Əməkdaşa izahat sorğusu yönləndirilib",
  },
  {
    key: "requestedToEmployee",
    name: "İzahat əməkdaş tərəfindən göndərilib",
  },
];

export const STAFF_STATUS_FOR_FILTER = [
  {
    key: "approved",
    name: "Aktiv",
  },
  {
    key: "active",
    name: "Qüvvədə",
  },
  {
    key: "pending_application",
    name: "Ərizə gözlənilir",
  },
  {
    key: "pending_contract",
    name: "Ə.M. tərtibi gözlənilir",
  },
  {
    key: "pending_contract_approve",
    name: "Ə.M. tərəflər tərəfindən imzalanması gözlənilir",
  },
  {
    key: "pending_order",
    name: "Əmr tərtibi gözlənilir",
  },
  {
    key: "pending_order_approve",
    name: "Əmr imzası gözlənilir",
  },
  {
    key: "pending_termination_order_approve",
    name: "Xitam əmrinin imzası gözlənilir",
  },
  {
    key: "rejected_application",
    name: "Ərizəyə imtina edilmişdir",
  },
  {
    key: "rejected_contract_approve",
    name: "Ə.M. imzalanmasına imtina edilmişdir",
  },
  {
    key: "rejected_order_approve",
    name: "Əmr imzalanmasına imtina edilmişdir",
  },
  {
    key: "empty",
    name: "--",
  },
];
export const BUSINESS_TRIP_STATUS_FOR_FILTER = [
  {
    key: "awaiting-signature",
    name: "İmza gözlənilir",
  },
  {
    key: "signed-and-planned",
    name: "İmzalanıb/Planlaşdırılır",
  },
  {
    key: "not-signed",
    name: "İmzalanmamış",
  },
  {
    key: "ongoing",
    name: "Davam Edən",
  },
  {
    key: "concluded",
    name: "Bitmiş",
  },
  {
    key: "cancelled",
    name: "Ləğv Edilmiş",
  },
];

export const OVERTIME_STATUS_FOR_FILTER = [
  {
    key: "pendingExplanation",
    name: "İzahat gözlənilir",
  },
  {
    key: "approved",
    name: "Aktiv",
  },
  {
    key: "active",
    name: "Aktiv",
  },
  {
    key: "requestedToEmployee",
    name: "İzahat sorğusu yönləndirilib",
  },
  {
    key: "explanationSentByEmployee",
    name: "İzahat əməkdaş tərəfindən göndərilib",
  },
  {
    key: "pendingOrder",
    name: "Əmr tərtibi gözlənilir",
  },
  {
    key: "pendingOrderApprove",
    name: "Əmr imzası gözlənilir",
  },
  {
    key: "rejected",
    name: "Deaktiv",
  },
  {
    key: "rejectedOrderApprove",
    name: "Əmrə imtina verilmişdir",
  },
  {
    key: "cancelled",
    name: "Ləğv Edilmiş",
  },
];

// status types

export const SYSTEM_CONFIRMATION_STATUS = {
  approved: {
    key: "approved",
    shortName: "Aktiv",
    longName: "Aktiv",
    color: COLORS_STATUS.GREEN,
  },
  pendingChainApprove: {
    key: "pendingChainApprove",
    shortName: "Gözlənilir",
    longName: "Təsdiqləmə gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  pendingOrder: {
    key: "pendingOrder",
    shortName: "Gözlənilir",
    longName: "Əmr tərtibi gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  pendingOrderApprove: {
    key: "pendingOrderApprove",
    shortName: "Gözlənilir",
    longName: "Əmr imzası gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  rejectedByChainUser: {
    key: "rejectedByChainUser",
    shortName: "Deaktiv",
    longName: "Sorğuya imtina edilmişdir",
    color: COLORS_STATUS.RED,
  },
  cancelledByHr: {
    key: "cancelledByHr",
    shortName: "Deaktiv",
    longName: "HR tərəfindən ləğv edilmişdir",
    color: COLORS_STATUS.RED,
  },
  updatedByCreator: {
    key: "updatedByCreator",
    shortName: "Xüsusi",
    longName: "Əməkdaş müvafiq dəyişiklik etmişdir",
    color: COLORS_STATUS.ORANGE,
  },
  reRequestedToManager: {
    key: "reRequestedToManager",
    shortName: "Xüsusi",
    longName: "Rəhbərə yenidən yönləndirilmişdir",
    color: COLORS_STATUS.ORANGE,
  },
  reRequestedToCreator: {
    key: "reRequestedToCreator",
    shortName: "Xüsusi",
    longName: "Əməkdaşa düzəliş üçün yönləndirilmişdir",
    color: COLORS_STATUS.ORANGE,
  },
  rejectedOrderApprove: {
    key: "rejectedOrderApprove",
    shortName: "İmtina",
    longName: "Əmrə imtina verilmişdir",
    color: COLORS_STATUS.RED,
  },
};

export const DISSMISAL = {
  active: {
    key: "active",
    shortName: "Aktiv",
    longName: "Aktiv",
    color: COLORS_STATUS.GREEN,
  },
  pendingExplanation: {
    key: "pendingExplanation",
    shortName: "Gözlənilir",
    longName: "İzahat gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  pendingOrder: {
    key: "pendingOrder",
    shortName: "Gözlənilir",
    longName: "Əmr tərtibatı gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  pendingOrderApprove: {
    key: "pendingOrderApprove",
    shortName: "Gözlənilir",
    longName: "Əmr imzası gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  cancelled: {
    key: "cancelled",
    shortName: "Deaktiv",
    longName: "Ləğv edilmişdir",
    color: COLORS_STATUS.RED,
  },
  rejectedOrderApprove: {
    key: "rejectedOrderApprove",
    shortName: "Deaktiv",
    longName: "Əmr imzalanmasına imtina edilmişdir",
    color: COLORS_STATUS.RED,
  },
  rejected: {
    key: "rejected",
    shortName: "Rədd edilmişdir",
    longName: "Əməkdaşın izahatı rədd edilmişdir",
    color: COLORS_STATUS.ORANGE,
  },
  requestedToEmployee: {
    key: "requestedToEmployee",
    shortName: "Xüsusi",
    longName: "Əməkdaşa izahat sorğusu yönləndirilib",
    color: COLORS_STATUS.ORANGE,
  },
  explanationSentByEmployee: {
    key: "explanationSentByEmployee",
    shortName: "Xüsusi",
    longName: "İzahat əməkdaş tərəfindən göndərilib",
    color: COLORS_STATUS.GREY,
  },
};
export const ORDERS_DOCUMENTS = {
  pending: {
    key: "pending",
    shortName: "Gözlənilir",
    longName: "Sənədin imzalanması gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
};
export const GENERAL_DOCUMENTS = {
  approved: {
    key: "approved",
    shortName: "Aktiv",
    longName: "Aktiv",
    color: COLORS_STATUS.GREEN,
  },
  pending: {
    key: "pending",
    shortName: "Gözlənilir",
    longName: "Sənədin imzalanması gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  pendingTerminationApprove: {
    key: "pendingTerminationApprove",
    shortName: "Gözlənilir",
    longName: "Xitam əmrinin imzası gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  rejected: {
    key: "rejected",
    shortName: "Deaktiv",
    longName: "Sənədin imzalanmasına imtina edilmişdir",
    color: COLORS_STATUS.RED,
  },
  expired: {
    key: "expired",
    shortName: "Deaktiv",
    longName: "Sənəd qüvvədən düşmüşdür",
    color: COLORS_STATUS.RED,
  },
  terminated: {
    key: "terminated",
    shortName: "Deaktiv",
    longName: "Sənəd ləğv edilmişdir",
    color: COLORS_STATUS.RED,
  },
};

export const STRUCTURE_CHGANGE_REQUEST = {
  approved: {
    key: "approved",
    shortName: "Aktiv",
    longName: "Aktiv",
    color: COLORS_STATUS.GREEN,
  },
  completed: {
    key: "completed",
    shortName: "Aktiv",
    longName: "Aktiv",
    color: COLORS_STATUS.GREEN,
  },
  pendingHrSign: {
    key: "pendingHrSign",
    shortName: "Gözlənilir",
    longName: "HR imzası gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  pendingRecruiterApprove: {
    key: "pendingRecruiterApprove",
    shortName: "Gözlənilir",
    longName: "İşəgötürənin təsdiqi gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  pendingHrChanges: {
    key: "pendingHrChanges",
    shortName: "Gözlənilir",
    longName: "Dəyişiklik gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  pendingRecruiterSign: {
    key: "pendingRecruiterSign",
    shortName: "Gözlənilir",
    longName: "Əmr imza gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  pending: {
    key: "pending",
    shortName: "Gözlənilir",
    longName: "Gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  pendingChainApprove: {
    key: "pendingChainApprove",
    shortName: "Təsdiqləmə gözlənilir",
    longName: "Təsdiqləmə gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  rejectedByRecruiter: {
    key: "rejectedByRecruiter",
    shortName: "Deaktiv",
    longName: "Sorğuya imtina edilmişdir",
    color: COLORS_STATUS.RED,
  },
  rejectedByChainUser: {
    key: "rejectedByChainUser",
    shortName: "Deaktiv",
    longName: "Sorğuya imtina edilmişdir",
    color: COLORS_STATUS.RED,
  },
  cancelledByHR: {
    key: "cancelledByHR",
    shortName: "Deaktiv",
    longName: "HR tərəfindən ləğv edilmişdir",
    color: COLORS_STATUS.RED,
  },
  rejected: {
    key: "rejected",
    shortName: "Deaktiv",
    longName: "HR tərəfindən ləğv edilmişdir",
    color: COLORS_STATUS.RED,
  },
};

export const OVERTIME_STATUS = {
  approved: {
    key: "approved",
    shortName: "Aktiv",
    longName: "Aktiv",
    color: COLORS_STATUS.GREEN,
  },
  active: {
    key: "active",
    shortName: "Aktiv",
    longName: "Aktiv",
    color: COLORS_STATUS.GREEN,
  },
  pendingExplanation: {
    key: "pendingExplanation",
    shortName: "Gözlənilir",
    longName: "İzahat gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  requestedToEmployee: {
    key: "requestedToEmployee",
    shortName: "Gözlənilir",
    longName: "Əməkdaş təsdiqi gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  cancelled: {
    key: "cancelled",
    shortName: "Ləğv Edilmiş",
    longName: "Ləğv Edilmiş",
    color: COLORS_STATUS.RED,
  },
  explanationSentByEmployee: {
    key: "explanationSentByEmployee",
    shortName: "Gözlənilir",
    longName: "Ərizə əməkdaş tərəfindən göndərilib",
    color: COLORS_STATUS.BLUE,
  },
  pendingOrder: {
    key: "pendingOrder",
    shortName: "Gözlənilir",
    longName: "Əmr tərtibi gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  pendingOrderApprove: {
    key: "pendingOrderApprove",
    shortName: "Gözlənilir",
    longName: "Əmr imzası gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  rejected: {
    key: "rejected",
    shortName: "Deaktiv",
    longName: "Deaktiv",
    color: COLORS_STATUS.RED,
  },
  rejectedOrderApprove: {
    key: "rejectedOrderApprove",
    shortName: "İmtina",
    longName: "Əmrə imtina verilmişdir",
    color: COLORS_STATUS.RED,
  },
};

export function STAFF_STATUS(isVerifide) {
  return {
    approved: {
      key: "approved",
      shortName: "Aktiv",
      longName: "Aktiv",
      color: COLORS_STATUS.GREEN,
    },
    contract_in_draft: {
      key: "contract_in_draft",
      shortName: "Gözlənilir",
      longName: "Müqavilə imzalanması gözlənilir",
      color: COLORS_STATUS.BLUE,
    },
    pending: {
      key: "pending",
      shortName: "Gözlənilir",
      longName: "Gözlənilir",
      color: COLORS_STATUS.BLUE,
    },
    pendingTerminationApprove: {
      key: "pendingTerminationApprove",
      shortName: "Gözlənilir",
      longName: "Xitam əmrinin imzası gözlənilir",
      color: COLORS_STATUS.BLUE,
    },
    rejected: {
      key: "rejected",
      shortName: "Deaktiv",
      longName: "Deaktiv",
      color: COLORS_STATUS.RED,
    },
    expired: {
      key: "expired",
      shortName: "Deaktiv",
      longName: "Sənəd qüvvədən düşmüşdür",
      color: COLORS_STATUS.RED,
    },
    terminated: {
      key: "terminated",
      shortName: "Deaktiv",
      longName: "Sənəd ləğv edilmişdir",
      color: COLORS_STATUS.RED,
    },
    active: {
      key: "active",
      shortName: "Aktiv",
      longName: "Qüvvədə",
      color: COLORS_STATUS.GREEN,
    },
    pending_application: {
      key: "pending_application",
      shortName: "Gözlənilir",
      longName: "Ərizə gözlənilir",
      color: COLORS_STATUS.BLUE,
    },
    pending_contract: {
      key: "pending_contract",
      shortName: "Gözlənilir",
      longName: "Hal-hazırda ərizənin təsdiqi gözlənilir",
      color: COLORS_STATUS.BLUE,
    },
    pending_contract_approve: {
      key: "pending_contract_approve",
      shortName: "Gözlənilir",
      longName: "Ə.M. tərəflər tərəfindən imzalanması gözlənilir",
      color: COLORS_STATUS.BLUE,
    },
    pending_order: {
      key: "pending_order",
      shortName: "Gözlənilir",
      longName: "Əmr tərtibi gözlənilir",
      color: COLORS_STATUS.BLUE,
    },
    pendingUpdate: {
      key: "pendingUpdate",
      shortName: "Gözlənilir",
      longName: "Əmr yenilənməsi gözlənilir",
      color: COLORS_STATUS.GREEN,
    },
    pending_order_approve: {
      key: "pending_order_approve",
      shortName: "Gözlənilir",
      longName: "Əmr imzası gözlənilir",
      color: COLORS_STATUS.BLUE,
    },
    pending_termination_order_approve: {
      key: "pending_termination_order_approve",
      shortName: "Gözlənilir",
      longName: "Xitam əmrinin imzası gözlənilir",
      color: COLORS_STATUS.BLUE,
    },
    pendingLabourChangeSign: {
      key: "pendingLabourChangeSign",
      shortName: "Gözlənilir",
      longName: "Əmək müqaviləsinin imzalanması gözlənilir",
      color: COLORS_STATUS.BLUE,
    },
    rejected_application: {
      key: "rejected_application",
      shortName: "Deaktiv",
      longName: "Ərizəyə imtina edilmişdir",
      color: COLORS_STATUS.RED,
    },
    rejected_contract_approve: {
      key: "rejected_contract_approve",
      shortName: "Deaktiv",
      longName: "Ə.M. imzalanmasına imtina edilmişdir",
      color: COLORS_STATUS.RED,
    },
    rejected_order_approve: {
      key: "rejected_order_approve",
      shortName: "Deaktiv",
      longName: "Əmr imzalanmasına imtina edilmişdir",
      color: COLORS_STATUS.RED,
    },
    empty: {
      key: "empty",
      shortName: "Xüsusi",
      longName: "",
      color: COLORS_STATUS.ORANGE,
    },
    draft: isVerifide
      ? {
          key: "pendingSignature",
          shortName: "İmza gözlənilir",
          longName: "İmza gözlənilir",
          color: COLORS_STATUS.BLUE,
        }
      : {
          key: "pendingApprove",
          shortName: "Təsdiq gözlənilir",
          longName: "Təsdiq gözlənilir",
          color: COLORS_STATUS.BLUE,
        },
    updated: {
      key: "updated",
      shortName: "Yenilənmiş",
      longName: "Müqavilə yenilənmişdir",
      color: COLORS_STATUS.ORANGE,
    },
    extended: {
      key: "extended",
      shortName: "Uzadılmış",
      longName: "Müqavilə uzadılmışdır",
      color: COLORS_STATUS.ORANGE,
    },
    cancelled: {
      key: "cancelled",
      shortName: "Ləğv Edilmiş",
      longName: "Ləğv Edilmiş",
      color: COLORS_STATUS.RED,
    },
  };
}

export const EMPLOYEES_STATUS = {
  active: {
    key: "active",
    shortName: "Aktiv",
    longName: "Əməkdaş hazırda ştatdadır",
    color: COLORS_STATUS.GREEN,
  },
  inactive: {
    key: "inactive",
    shortName: "Deaktiv",
    longName: "Əməkdaşın aktiv əmək müqaviləsi yoxdur",
    color: COLORS_STATUS.GREY,
  },
};

export const CONT_UPDATE_STATUS = {
  pending: {
    key: "pending",
    shortName: "Gözlənilir",
    longName: "Ə.M. dəyişiklik sorğusu imza gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  rejected: {
    key: "rejected",
    shortName: "İmtina",
    longName: "Ə.M. dəyişiklik sorğusu imtina edilmişdir",
    color: COLORS_STATUS.RED,
  },
  approved: {
    key: "approved",
    shortName: "Aktiv",
    longName: "Qüvvədə",
    color: COLORS_STATUS.GREEN,
  },
};

// document models

export const DOCUMENT_MODELS = {
  order: "Əmr",
  agreement: "Müqavilə",
  application: "Ərizə",
  service: "Xidməti yazı",
  explanation: "İzahat",
};

export const BUSINESS_TRIP_MODELS = {
  "awaiting-signature": {
    key: "awaiting-signature",
    shortName: "İmza gözlənilir",
    longName: "İmza gözlənilir",
    color: COLORS_STATUS.BLUE,
  },
  "signed-and-planned": {
    key: "signed-and-planned",
    shortName: "İmzalanıb/Planlaşdırılır",
    longName: "İmzalanıb/Planlaşdırılır",
    color: COLORS_STATUS.GREEN,
  },
  "not-signed": {
    key: "not-signed",
    shortName: "İmzalanmamış",
    longName: "İmzalanmamış",
    color: COLORS_STATUS.DARK_RED,
  },
  ongoing: {
    key: "ongoing",
    shortName: "Davam Edən",
    longName: "Davam Edən",
    color: COLORS_STATUS.ORANGE,
  },
  concluded: {
    key: "concluded",
    shortName: "Bitmiş",
    longName: "Bitmiş",
    color: COLORS_STATUS.GREY,
  },
  cancelled: {
    key: "cancelled",
    shortName: "Ləğv Edilmiş",
    longName: "Ləğv Edilmiş",
    color: COLORS_STATUS.RED,
  },
};

export const titleHandler = (offDayType) => {
  switch (offDayType) {
    case "holiday":
      return "Bayram günü";
    case "nonWorkingDay":
      return "Qeyri-iş günü";
    case "restDay":
      return "İstirahət günü";
    case "mourningDay":
      return "Hüzn günü";
    default:
      break;
  }
};

export const bgColorHandler = (offDayType) => {
  switch (offDayType) {
    case "holiday":
      return COLORS_STATUS.RED;
    case "nonWorkingDay":
      return COLORS_STATUS.BLUE;
    case "restDay":
      return COLORS_STATUS.GREEN;
    case "mourningDay":
      return "#000";
    default:
      break;
  }
};

export function step1(labels, emp_data) {
  return {
    stp: 1,
    data: {
      empWorkPlaceType: {
        label: `1.2.3 ${
          (labels?.length &&
            labels?.find((l) => l?.name === "empWorkPlaceType")?.label) ??
          ""
        }`,
        value: "main",
        type: "single-select",
        required: true,
      },
      legalDistrict: {
        label: `${
          (labels?.length &&
            labels?.find((l) => l?.name === "actualWorkAddressRegion")
              ?.label) ??
          ""
        }`,
        value: emp_data?.structureAddress?.legalDistrict,
        type: "single-select",
        required: true,
      },
      actualWorkAddress: {
        label: "Ünvan",
        value: emp_data?.structureAddress?.legalAddress,
        type: "string",
        required: false,
      },
    },
  };
}
export function step2(labels) {
  return {
    stp: 2,
    data: {
      contractPeriodType: {
        label: `2.1 ${
          labels?.length &&
          labels?.find((l) => l?.name === "contractPeriodType")?.label
        }`,
        value: "term",
        type: "single-select",
        // required: true,
      },
      reasonOfTemporaryContract: {
        label: `2.3.1 ${
          labels?.length &&
          labels?.find((l) => l?.name === "reasonOfTemporaryContract")?.label
        }`,
        value: 2,
        type: "single-select",
        required: false,
        disabled: false,
      },
      probationPeriodType: {
        label: `2.2 ${
          labels?.length &&
          labels?.find((l) => l?.name === "probationPeriodType")?.label
        }`,
        value: "day",
        type: "single-select",
        required: false,
      },
      probationPeriod: {
        label: `${
          labels?.length &&
          labels?.find((l) => l?.name === "probationPeriod")?.label
        }`,
        value: "",
        type: "number",
        required: false,
      },
      probationPeriod2: {
        label: "Sınaq müddətinin bitməsinə dair xəbərdarlıq (gün)",
        value: "3",
        type: "read-only",
        required: false,
        disabled: true,
      },
      contractStartDate: {
        label: `2.3.2 ${
          labels?.length &&
          labels?.find((l) => l?.name === "contractStartDate")?.label
        }`,
        value: "",
        type: "date",
        required: true,
      },
      contractEndDate: {
        label: `2.3.3 ${
          labels?.length &&
          labels?.find((l) => l?.name === "contractEndDate")?.label
        }`,
        value: "",
        type: "date",
        required: true,
        disabled: false,
      },
      jobStartDate: {
        label: `2.3.4 ${
          labels?.length &&
          labels?.find((l) => l?.name === "jobStartDate")?.label
        }`,
        value: "",
        type: "date",
        required: true,
        field_name: "jobStartDate",
      },
      laborFunctions: {
        label:
          "3.1 İşçi aşağıdakı əmək funksiyasının icrasını öhdəsinə götürür:",
        value: [],
        type: "",
        required: false,
      },
    },
  };
}
export function step3(
  additional_wage,
  wage_monthly,
  labels,
  constants,
  emp_data,
  contract_data,
  isEditContract,
  getVacationDaysForSeniorityLevel,
  getVacationDaysForParental
) {
  return {
    stp: 3,
    data: [
      {
        paymentOfWork: {
          label: `4.2 ${
            labels?.length &&
            labels?.find((l) => l?.name === "paymentOfWork")?.label
          }`,
          value:
            Object.keys(constants || {})?.length &&
            constants?.paymentOfWork[0]?.value,
          type: "single-select",
          required: true,
        },
        wage: {
          label: `4.2.1 ${
            labels?.length && labels?.find((l) => l?.name === "wage")?.label
          }`,
          value: "",
          type: "number",
          required: true,
        },
        wageBonus: {
          label: `4.2.2 ${
            labels?.length &&
            labels?.find((l) => l?.name === "wageBonus")?.label
          }`,
          value: additional_wage,
          default_value: additional_wage,
          type: "number",
          required: false,
          disabled: true,
        },
        wageMonthly: {
          label: `4.1. İşçinin aylıq əmək haqqı`,
          value: wage_monthly,
          type: "number",
          required: false,
          disabled: true,
        },
        bonusPeriod: {
          label: `4.3. ${
            labels?.length &&
            labels?.find((l) => l?.name === "bonusPeriod")?.label
          }`,
          value:
            Object.keys(constants)?.length && constants?.bonusPeriod[0]?.value,
          type: "single-select",
          required: false,
        },
        givenBonus: {
          label: `${
            labels?.length &&
            labels?.find((l) => l?.name === "givenBonus")?.label
          }`,
          value: "",
          type: "number",
          required: false,
        },
        overtime: {
          label: `4.4.2 ${
            labels?.length && labels?.find((l) => l?.name === "overtime")?.label
          }`,
          value: "",
          type: "number",
          required: false,
        },
        workingCondition: {
          label: `4.4 ${
            labels?.length &&
            labels?.find((l) => l?.name === "workingCondition")?.label
          }`,
          value: "",
          type: "multi-group-select",
          required: true,
        },
        paymentPeriod: {
          label: `4.4.3 ${
            labels?.length &&
            labels?.find((l) => l?.name === "paymentPeriod")?.label
          }`,
          value:
            Object.keys(constants)?.length &&
            constants?.paymentPeriod?.[0]?.value,
          type: "single-select",
          required: false,
        },
        paymentPeriodDate: {
          label: `1-ci ödənişin tarixi`,
          value: "",
          type: "string",
          required: false,
        },
        freelanceWorkIncomeType: {
          label: `4.5 ${
            labels?.length &&
            labels?.find((l) => l?.name === "freelanceWorkIncomeType")?.label
          }`,
          value:
            Object.keys(constants)?.length &&
            constants?.freelanceWorkIncomeType[0]?.value,
          type: "single-select",
          required: false,
        },
        freelanceWorkIncome: {
          label: `${
            labels?.length &&
            labels?.find((l) => l?.name === "freelanceWorkIncome")?.label
          }`,
          value: "",
          type: "number",
          required: false,
        },
        conditionsOfSalaryPayment: {
          label: `4.7 ${
            labels?.length &&
            labels?.find((l) => l?.name === "conditionsOfSalaryPayment")?.label
          }`,
          value: "",
          type: "string",
          required: false,
        },
      },
      {
        providedProtectiveEquipment: {
          label: `4.10 ${
            labels?.length &&
            labels?.find((l) => l?.name === "providedProtectiveEquipment")
              ?.label
          }`,
          value: emp_data?.inventories?.[0]?.name,
          type: "multi-select",
          required: false,
        },
        providedFood: {
          label: `4.11 ${
            labels?.length &&
            labels?.find((l) => l?.name === "providedFood")?.label
          }`,
          value: [],
          type: "multi-select",
          required: false,
        },
        instructionalPeriod: {
          label: `4.12 ${
            labels?.length &&
            labels?.find((l) => l?.name === "instructionalPeriod")?.label
          }`,
          value:
            Object.keys(constants)?.length &&
            constants?.providedProtectiveEquipment?.[0]?.value,
          type: "single-select",
          required: false,
        },
        providedCar: {
          label: `4.22 ${
            labels?.length &&
            labels?.find((l) => l?.name === "providedCar")?.label
          }`,
          value: !isEditContract
            ? emp_data?.transportVehicles?.length
              ? "yes"
              : "no"
            : contract_data?.providedCar,
          type: "single-select",
          required: false,
        },
      },
      {
        workingMode: {
          label: `4.22 ${
            labels?.length &&
            labels?.find((l) => l?.name === "workingMode")?.label
          }`,
          value: isEditContract ? "" : "standard",
          type: "single-select",
          required: true,
        },
        workScheduleId: {
          label: `Qrafiklər`,
          value: "",
          type: "single-select",
          required: true,
        },
        schedules_by_workScheduleId: {
          label: `Qrafiklər`,
          value: "",
          type: "graphics",
        },
        assignedWorkTurnValue: {
          label: `Növbəni seçin`,
          value: 1,
          type: "",
          required: true,
        },
        partTimeWorkHour: {
          label: `4.19 ${
            labels?.length &&
            labels?.find((l) => l?.name === "partTimeWorkHour")?.label
          }`,
          value: "",
          type: "number",
          required: false,
        },
        offDays: {
          label: `4.23 ${
            labels?.length && labels?.find((l) => l?.name === "offDays")?.label
          }`,
          value: [""],
          type: "multi-select",
          required: true,
        },
        offDays_count: {
          label: `İstirahət günləri sayı`,
          value: "",
          type: "number",
          disabled: true,
          required: false,
        },
        workScheduleAsText: {
          label: `Digər qrafik haqqında`,
          value: "",
          type: "",
          required: false,
        },
      },
      {
        workYearStart: {
          label: `4.26.1 İş ilinin başlanma tarixi`,
          value: "",
          type: "read-only",
          required: false,
          disabled: true,
        },
        workYearEnd: {
          label: `4.26.2 İş ilinin bitmə tarixi`,
          value: "",
          type: "read-only",
          required: false,
          disabled: true,
        },
        vacationDaysCount: {
          label: `4.27 ${
            labels?.length &&
            labels?.find((l) => l?.name === "vacationDaysCount")?.label
          }`,
          value:
            constants?.vacationDaysCount && constants?.vacationDaysCount[0],
          type: "single-select",
          required: true,
        },
        forSeniorityLevel: {
          label: `4.28.1 ${
            labels?.length &&
            labels?.find((l) => l?.name === "forSeniorityLevel")?.label
          }`,
          value: getVacationDaysForSeniorityLevel(),
          type: "single-select",
          required: false,
          // disabled: true,
        },
        forWorkConditions: {
          label: `4.28.2 Əmək şəraitinə görə əlavə (gün)`,
          value: 0,
          type: "number",
          required: false,
          disabled: true,
        },
        forParental: {
          label: `4.28.3 ${
            labels?.length &&
            labels?.find((l) => l?.name === "forParental")?.label
          }`,
          value: getVacationDaysForParental(),
          type: "single-select",
          required: false,
        },
        forCollectiveAgreement: {
          label: `4.28.4 Kollektiv müqavilə (saziş) üzrə`,
          value: 0,
          type: "number",
          required: false,
        },
        totalVacation: {
          label: `4.29 Ümümi əmək məzuniyyəti (gün)`,
          value: "",
          type: "read-only",
          required: false,
          disabled: true,
        },
        socialHouseholdBenefit: {
          label: `4.30.1 Sosial-məişət müavinəti`,
          value: "",
          type: "number",
          required: false,
        },
        aboutEventOrPayer: {
          label: `4.30.2 Konkret tədbir və ya ödənişi haqqında`,
          value: "",
          type: "string",
          required: false,
        },
      },
      {
        physicalEducationAndSportsConditions: {
          label: `4.34 Bədən tərbiyəsi və idman üzrə əlavə şərtlər`,
          value: "",
          type: "string",
          required: false,
        },
      },
      {
        otherAdditionalConditions: {
          label: ``,
          value: [],
          type: "string",
          required: false,
        },
      },
    ],
  };
}
export const step4 = {
  stp: 4,
  data: [
    {
      monthlyInsuranceFee: {
        label: `6.1.1 Sığorta haqqı (aylıq)`,
        value: "",
        type: "number",
        inputTy: "float",
        required: false,
      },
      extraSumInsured: {
        label: `6.1.2 Əlavə sığorta məbləği`,
        value: "",
        type: "number",
        inputTy: "float",
        required: false,
      },
    },
    {
      shareOwnershipOfEnterprise: {
        label: `7.2 Müəssisənin mülkiyyətdə payı`,
        value: "",
        type: "number",
        required: false,
      },
      dividendAmount: {
        label: `7.3 Divident məbləği`,
        value: "",
        type: "number",
        inputTy: "float",
        required: false,
      },
    },
    {
      personalPropertiesUsedByEmployee: {
        label: `7.4`,
        value: [],
        type: "",
        required: false,
      },
    },
    {
      changesInEmploymentContract: {
        label: ``,
        value: [],
        type: "",
        required: false,
      },
    },
    {
      contractTerminationCases: {
        label: `9.5`,
        value: [],
        type: "",
        required: false,
      },
    },
    {
      selectedBank: "",
    },
  ],
};

export const order_clauses = [
  {
    id: "clause1",
    name: "a. Ə/M uyğun Maddə 59",
  },
  {
    id: "clause2",
    name: "b. Ə/M uyğun Maddə 60",
  },
];
export const APP_EXP_DOCMODEL = {
  ExclusionApplication: "Kənarlaşdırma ərizəsi",
  OrderApplication: "Əmr ərizəsi",
};

export const APP_EXP_STATUS = {
  requestedToEmployee: {
    key: "requestedToEmployee",
    shortName: "Xüsusi",
    longName: "Əməkdaşa izahat sorğusu yönləndirilib",
    color: COLORS_STATUS.ORANGE,
  },
  explanationSentByEmployee: {
    key: "explanationSentByEmployee",
    shortName: "Xüsusi",
    longName: "İzahat əməkdaş tərəfindən göndərilib",
    color: COLORS_STATUS.ORANGE,
  },
};
