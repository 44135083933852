import React from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useBusinessTrip } from "../../hooks/useBusinessTrip";

const BusinessTripForm = ({ getBusinessTripData }) => {
  const {
    country,
    empData,
    submited,
    leave_state,
    handleSubmit,
    setForeignCity,
    show_Accordion,
    set_leave_state,
    isLeaveStateEmpty,
    shouldDisableDate,
    set_show_Accordion,
    disabledReasonMessage,
  } = useBusinessTrip(getBusinessTripData);

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => set_show_Accordion(!show_Accordion)}
          variant="contained"
          sx={{ height: "100%" }}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Ezamiyyət yarat
          </p>
        </Button>
      </div>
      {show_Accordion && (
        <form onSubmit={handleSubmit}>
          <div className="d-flex flex-column gap-3">
            <div className="d-flex w-100 gap-3">
              <div className="w-25">
                <div>Əməkdaş (A.S.A)</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <Autocomplete
                      options={empData || []}
                      getOptionLabel={(option) =>
                        `${option?.firstname || ""} ${option?.lastname || ""} ${
                          option?.fathername || ""
                        }`
                      }
                      value={
                        empData.find(
                          (emp) => emp._id === leave_state?.employeeId
                        ) || null
                      }
                      onChange={(event, selectedEmp) => {
                        const substituteEmp = empData?.filter(
                          (emp) => emp?._id !== selectedEmp?._id
                        );

                        set_leave_state({
                          ...leave_state,
                          employeeId: selectedEmp?._id || "",
                          empTabelNo: selectedEmp?.tableNumber || "",
                          substitute: substituteEmp || [],
                          staff: selectedEmp?.staffs || [],
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Əməkdaş (A.S.A)"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="w-25">
                <div>Tabel No</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    label="Tabel No"
                    fullWidth
                    value={leave_state?.empTabelNo}
                    InputProps={{
                      readOnly: true,
                      style: {
                        pointerEvents: "none",
                        cursor: "default",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-25">
                <div>Müqavilə nömrəsi</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Müqavilə nömrəsi
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={leave_state?.staffId}
                      disabled={!leave_state?.employeeId}
                      label="Müqavilə nömrəsi"
                      placeholder="ok"
                      onChange={(e) => {
                        const selectedStaff =
                          leave_state?.staff &&
                          leave_state?.staff.filter((item) => {
                            return item?._id === e?.target?.value;
                          });

                        set_leave_state({
                          ...leave_state,
                          staffId: e.target.value,
                          positionName: selectedStaff?.[0]?.position?.name,
                        });
                      }}
                    >
                      {leave_state?.staff &&
                        leave_state?.staff.map((e) => (
                          <MenuItem value={e?._id}>
                            {e?.contract?.data?.docNumber}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="w-25">
                <div>Vəzifə</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    label="Vəzifə"
                    value={leave_state?.positionName}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: {
                        pointerEvents: "none",
                        cursor: "default",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex w-100 gap-3">
              <div className="w-25">
                <div>Əvəz Edən Əməkdaş (A.S.A)</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <Autocomplete
                      options={leave_state?.substitute || []}
                      getOptionLabel={(option) =>
                        `${option?.firstname || ""} ${option?.lastname || ""} ${
                          option?.fathername || ""
                        }`
                      }
                      value={
                        leave_state?.substitute?.find(
                          (emp) => emp._id === leave_state?.substituteId
                        ) || null
                      }
                      onChange={(event, selectedEmp) => {
                        set_leave_state({
                          ...leave_state,
                          substituteId: selectedEmp?._id || "",
                          substituteTabelNo: selectedEmp?.tableNumber || "",
                          substituteStaff: selectedEmp?.staffs || [],
                        });
                      }}
                      disabled={!leave_state?.employeeId}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Əvəz Edən Əməkdaş (A.S.A)"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="w-25">
                <div>Əvəz Edənin Tabel No</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    label="Əvəz Edənin Tabel No"
                    fullWidth
                    value={leave_state?.substituteTabelNo}
                    InputProps={{
                      readOnly: true,
                      style: {
                        pointerEvents: "none",
                        cursor: "default",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-25">
                <div>Əvəz Edənin Müqavilə nömrəsi</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Əvəz Edənin Müqavilə nömrəsi
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={leave_state?.substituteStaffId}
                      disabled={!leave_state?.substituteId}
                      label="Əvəz Edənin Müqavilə nömrəsi"
                      placeholder="ok"
                      onChange={(e) => {
                        const selectedStaff =
                          leave_state?.substituteStaff &&
                          leave_state?.substituteStaff.filter((item) => {
                            return item?._id === e?.target?.value;
                          });
                        set_leave_state({
                          ...leave_state,
                          substituteStaffId: e.target.value,
                          substitutePositionName:
                            selectedStaff?.[0]?.position?.name,
                        });
                      }}
                    >
                      {leave_state?.substituteStaff &&
                        leave_state?.substituteStaff.map((e) => (
                          <MenuItem value={e?._id}>
                            {e?.contract?.data?.docNumber}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="w-25">
                <div>Əvəz Edənin Vəzifə</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    label="Əvəz Edənin Vəzifə"
                    value={leave_state?.substitutePositionName}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: {
                        pointerEvents: "none",
                        cursor: "default",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex w-100 gap-3">
              <div className="w-25">
                <div>Ezamiyyət Səbəbi</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    value={leave_state?.tripReason}
                    onChange={(e) =>
                      set_leave_state({
                        ...leave_state,
                        tripReason: e.target.value,
                      })
                    }
                    label="Ezamiyyət Səbəbi"
                    fullWidth
                  />
                </div>
              </div>

              {leave_state.isChecked ? (
                <div className="w-25">
                  <div>Ezam Olduğu Ölkə</div>
                  <div className="mt-2">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Ezam Olduğu Ölkə
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Ezam Olduğu Ölkə"
                        placeholder="ok"
                        value={leave_state?.foreignTripDetails}
                        onChange={(e) => {
                          if (leave_state?.isChecked) {
                            set_leave_state({
                              ...leave_state,
                              foreignTripDetails: e.target.value,
                            });
                          } else {
                            set_leave_state({
                              ...leave_state,
                              foreignTripDetails: "",
                            });
                          }
                        }}
                      >
                        {country?.length > 0 &&
                          country.map((e, index) => [
                            <ListSubheader
                              key={`header-${index}`}
                              component="div"
                            >
                              {index + 1}.{e?.name}
                            </ListSubheader>,
                            <MenuItem key={`item-${index}`} value={e?.name}>
                              {e?.name}
                            </MenuItem>,
                            ...e?.cities.map((city, cityIndex) => (
                              <MenuItem
                                key={`city-${index}-${cityIndex}`}
                                value={e}
                                onClick={() => setForeignCity(city?.name)}
                              >
                                {city?.name}
                              </MenuItem>
                            )),
                          ])}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              ) : (
                <div className="w-25">
                  <div>Ezam Olduğu Yer (Öklədaxili)</div>
                  <div className="mt-2">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Ezam Olduğu Yer (Öklədaxili)
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={leave_state?.withinLocation}
                        label="Ezam Olduğu Yer (Öklədaxili)"
                        placeholder="ok"
                        onChange={(e) => {
                          if (leave_state?.isChecked) {
                            set_leave_state({
                              ...leave_state,
                              withinLocation: "",
                            });
                          } else {
                            set_leave_state({
                              ...leave_state,
                              withinLocation: e.target.value,
                            });
                          }
                        }}
                      >
                        {leave_state?.withinCounty &&
                          leave_state?.withinCounty?.map((e) => (
                            <MenuItem value={e?.label}>{e?.label}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
              <div className="w-25">
                <div>Xarici</div>
                <div className="mt-2">
                  <FormControlLabel
                    label="Xarici"
                    control={
                      <Checkbox
                        checked={leave_state.isChecked}
                        onChange={(e) =>
                          set_leave_state({
                            ...leave_state,
                            isChecked: e.target.checked,
                          })
                        }
                      />
                    }
                  />
                </div>
              </div>
              <div className="w-25 invisible"></div>
            </div>
            <div className="d-flex w-100 gap-3">
              <div className="w-25">
                <div>Ezamiyyə Başalama Tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    sx={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    value={
                      leave_state?.tripStartDate
                        ? new Date(leave_state?.tripStartDate)
                        : null
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    label="Ezamiyyə Başalama Tarixi"
                    minDate={new Date()}
                    disabled={
                      !leave_state?.employeeId && !leave_state?.substituteId
                    }
                    shouldDisableDate={shouldDisableDate}
                    onChange={(e) => {
                      const newDate = e;
                      set_leave_state({
                        ...leave_state,
                        tripStartDate: newDate,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-25">
                <div>Ezamiyyə Bitmə Tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    sx={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    value={
                      leave_state?.tripEndDate
                        ? new Date(leave_state?.tripEndDate)
                        : null
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    label="Ezamiyyə Bitmə Tarixi"
                    disabled={!leave_state?.tripStartDate}
                    minDate={leave_state?.tripStartDate}
                    shouldDisableDate={shouldDisableDate}
                    onChange={(e) => {
                      const newDate = e;
                      set_leave_state({
                        ...leave_state,
                        tripEndDate: newDate,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-25">
                <div>İşə çıxma Tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    sx={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    value={
                      leave_state?.returnToWorkDate
                        ? new Date(leave_state?.returnToWorkDate)
                        : null
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    label="İşə çıxma Tarixi"
                    disabled={!leave_state?.tripEndDate}
                    minDate={leave_state?.tripEndDate}
                    shouldDisableDate={shouldDisableDate}
                    onChange={(e) => {
                      const newDate = e;
                      set_leave_state({
                        ...leave_state,
                        returnToWorkDate: newDate,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-25">
                <div>Ezamiyyə müddəti (gün)</div>
                <div className="mt-2">
                  <TextField
                    type="number"
                    label="Ezamiyyə müddəti (gün)"
                    value={leave_state?.tripDuration}
                    InputProps={{
                      readOnly: true,
                      style: {
                        pointerEvents: "none",
                        cursor: "default",
                      },
                    }}
                    fullWidth
                  />
                </div>
              </div>
            </div>
            {disabledReasonMessage?.length > 0 && (
              <div className="d-flex flex-column w-100 gap-3">
                {disabledReasonMessage &&
                  disabledReasonMessage.map((item, index) => {
                    return (
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography
                          sx={{ color: "#ff9800" }}
                          key={`${item?.az}-${index}`}
                        >
                          {item?.az}
                        </Typography>
                      </Paper>
                    );
                  })}
              </div>
            )}
          </div>
          <div className="w-25 ms-2 mt-3">
            <Button
              type="submit"
              variant="contained"
              disabled={isLeaveStateEmpty() || submited}
            >
              Əlavə et
            </Button>
          </div>
        </form>
      )}
    </>
  );
};

export default BusinessTripForm;
