import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Divider as MuiDivider,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import Column from "../../models/table/Column";
import { OPERATORS_CONTAINING } from "../../constants";
import { getStaticFilterValues } from "../../utils/forFilterDropdown/getStructureUtils";
import Operator from "../../models/table/Operator";
import { Close, Delete, Edit } from "@mui/icons-material";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import ButtonDropdown from "../../components/ui/ButtonDropdown/ButtonDropdown";
import { handleServiceCall } from "../../services/handleServiceCall";
import useUserActions from "../../hooks/useUserActions";
import Table from "../../components/table/Table";
import {
  deleteUser_SERVICE,
  editUser_SERVICE,
  getAllUsers_SERVICE,
  registerUser_SERVICE,
} from "../../services/user-accountServices";
import { enqueueSnackbar } from "notistack";
import { GENERAL_BLOCK } from "../../styled";
import UserCreate from "./UserCreate";
import UserTable from "./UserTable";
const Index = () => {
  const {
    registerUser,
    deleteUser,
    editUser,
    getUsers,
    getUser,
    handleChange,
    userRegisterData,
    userRegisterDialog,
    setUserRegisterDialog,
    setUserEditDialog,
    setUserDeleteDialog,
    userDeleteDialog,
    userEditDialog,
    data,
    total,
    loading,
  } = useUserActions();

  let user_id = useRef(null);

  const columns_ = [
    new Column({
      headerName: "Operator",
      path: "#",
      isStaticColumn: false,
      disableColSearch: false,
      renderCell: (row) => {
        <ButtonDropdown
          dropdownComponent={
            <Grid sx={{ flexDirection: "column", display: "flex" }}>
              <Button
                width={"100%"}
                onClick={() => handleOptionClick("Option 1")}
              >
                <Typography color={"white"}>Xitam verilmiş ƏM</Typography>
              </Button>
              <Button
                width={"100%"}
                onClick={() => handleOptionClick("Option 2")}
              >
                <Typography color={"white"}>Xitam əmri</Typography>
              </Button>
            </Grid>
          }
          styleContainer={{ height: "auto", width: "30px" }}
          title={<Close />}
          styleButton={{ background: "none" }}
          styleBtnContainer={{ width: "33px" }}
        />;

        const operators = [
          new Operator({
            icon: <Edit />,
            onClick: () => {
              setUserEditDialog(true);
              getUser(row?._id);
            },
            type: 0,
          }),
          new Operator({
            icon: <Delete />,
            onClick: () => {
              user_id.current = row?._id;
              setUserDeleteDialog(true);
            },
            hideCondition: row.isUsedInStaff,
            type: 0,
          }),
        ];

        return <OperatorGenerator operators={operators} />;
      },
    }),
    new Column({
      path: "pin",
      headerName: "Fin",
      widthOfColumn: 100,
      // renderCell: (row, col) => {
      //   // console.log(row);
      //   return row.staff?.employee[0]?.tableNumber;
      // },
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "firstname",
      headerName: "Ad",
      widthOfColumn: 100,
      // renderCell: (row, col) => {
      //   // console.log(row);
      //   return row.staff?.employee[0]?.tableNumber;
      // },
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "lastname",
      headerName: "Soyad",
      widthOfColumn: 200,
      filterType: OPERATORS_CONTAINING.TEXT,
      dataGridName: "time-table",
    }),
    new Column({
      path: "fathername",
      headerName: "Ata adı",
      widthOfColumn: 200,
      filterColumnKey: "parentPart._id",
      dependencieKeysOfFilter: {
        structures: ["baseStructure._id"],
        topPartOfStructures: ["topPartOfStructure._id"],
      },
      dataGridName: "time-table",
      keyForResponseDataFromFilter: "topPartOfStructures",
      filterType: OPERATORS_CONTAINING.IN,
    }),
    new Column({
      path: "account",
      headerName: "Hesab",
      widthOfColumn: 200,
      filterColumnKey: "subStructure._id",
      dependencieKeysOfFilter: {
        structures: ["baseStructure._id"],
        topPartOfStructures: ["topPartOfStructure._id"],
      },
      dataGridName: "time-table",
      keyForResponseDataFromFilter: "topPartOfStructures",
      filterType: OPERATORS_CONTAINING.IN,
    }),
    new Column({
      path: "email",
      headerName: "Poçt ünvanl",
      widthOfColumn: 150,
      filterColumnKey: "staff.part._id",
      dependencieKeysOfFilter: {
        structures: ["baseStructure._id"],
        topPartOfStructures: ["topPartOfStructure._id"],
      },
      dataGridName: "time-table",
      fetchFilterValuesV2: getStaticFilterValues,
      keyForResponseDataFromFilter: "positions",
      filterType: OPERATORS_CONTAINING.IN,
    }),
    // new Column({
    //   path: "status",
    //   headerName: "Status",
    //   disableColSearch: true,
    //   widthOfColumn: 150,
    // }),
  ];

  console.log(userRegisterData);

  const [columns, set_columns] = useState(columns_);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorId, setAnchorId] = useState("");
  const handleClosePopover = () => {
    setAnchorEl(null);
    setAnchorId("");
  };
  const handleOptionClick = () => {
    // Add your logic here for handling the selected option
    handleClosePopover();
  };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };
  const Divider = styled(MuiDivider)(spacing);
  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  useEffect(() => {
    getUsers();
  }, [offset, limit]);

  return (
    <React.Fragment>
      <Helmet title="Users register" />
      <Typography
        variant="h3"
        gutterBottom
        display="inline"
        sx={{ marginRight: 6 }}
      >
        İstifadəçilər reyesteri
      </Typography>
      <Divider my={6} />
      <div className="text-right mb-4">
        <Button variant="contained" onClick={setUserRegisterDialog}>
          İstifadəçi yarat
        </Button>
      </div>
      <Table
        columns={columns}
        data={data}
        totalRows={total}
        applyFiltersHandler={applyFiltersHandler}
        cancelAppliedFilters={cancelAppliedFiltersHandler}
        offset={offset}
        setOffset={(value) => setOffset(value)}
        limit={limit}
        setLimit={(value) => setLimit(value)}
        isLoading={loading}
        // downloadHandler={(cols) => downloadHandler(cols)}
      />

      {/*  Register dialog */}
      <Dialog
        open={userRegisterDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { minWidth: "50%" } }}
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          İstifadəçi yaradılması
        </DialogTitle>
        <DialogContent>
          <UserCreate />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUserRegisterDialog(false)}>Bağla</Button>
          {/* <Button onClick={registerUser} autoFocus>
            Yadda Saxla
          </Button> */}
        </DialogActions>
      </Dialog>
      {/* Edit dialog */}
      <Dialog
        open={userEditDialog}
        onClose={() => setUserEditDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { minWidth: "50%" } }}
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          İstifadəçi Redaktə edilməsi
        </DialogTitle>
        <DialogContent>
          <form action="">
            <TextField
              label="Poçt ünvanı"
              type="email"
              name="email"
              value={userRegisterData.email}
              onChange={handleChange}
              fullWidth
              required
            />

            {userRegisterData?.citizenship === "notCitizen" ? (
              <>
                <Divider sx={{ my: 3 }} />
                <TextField
                  label="Passport nömrəsi"
                  type="text"
                  name="passportNumber"
                  value={userRegisterData.passportNumber}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </>
            ) : (
              <>
                <Divider sx={{ my: 3 }} />
                <TextField
                  label="FİN"
                  type="text"
                  name="pin"
                  value={userRegisterData?.pin}
                  onChange={handleChange}
                  inputProps={{
                    maxLength:
                      userRegisterData?.citizenship === "temporaryResident" ||
                      userRegisterData?.citizenship === "permanentResident"
                        ? 6
                        : 7,
                  }}
                  fullWidth
                  required
                />
                <GENERAL_BLOCK as="span" f_size="10px">
                  {userRegisterData?.citizenship === "temporaryResident" ||
                  userRegisterData?.citizenship === "permanentResident"
                    ? "maks: 6 simvol"
                    : "maks: 7 simvol"}
                </GENERAL_BLOCK>
              </>
            )}
            <Divider sx={{ my: 3 }} />
            <FormControl fullWidth>
              <InputLabel>Vətəndaşlıq</InputLabel>
              <Select
                name="citizenship"
                value={userRegisterData?.citizenship}
                onChange={handleChange}
                required
                label="Vətəndaşlıq"
              >
                <MenuItem value="citizen">Vətəndaş</MenuItem>
                <MenuItem value="temporaryResident">Müvəqqəti sakin</MenuItem>
                <MenuItem value="permanentResident">Daimi sakin</MenuItem>
                <MenuItem value="notCitizen">Vətəndaş deyil</MenuItem>
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUserEditDialog(false)}>Bağla</Button>
          <Button
            onClick={() => {
              const { citizenship_ref, email_ref, ...rest } = userRegisterData;
              editUser({ ...rest, id: user_id });
            }}
            // disabled={userRegisterData?.email === userRegisterData?.email_ref}
          >
            Yadda Saxla
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete dialog */}
      <Dialog
        open={userDeleteDialog}
        onClose={() => setUserDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          İstifadəçi silinməsi
        </DialogTitle>
        <DialogContent>
          Bu istifadəçini silmək istədiyinizdən əminsiniz?
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => setUserDeleteDialog(false)}
          >
            Bağla
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={loading}
            onClick={() => deleteUser(user_id.current)}
          >
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default Index;
