import { api } from "./axiosInterceptor";

export const getListOvertimeApplication_API = async (params) => {
  let endpoint = "employee/manager/overtime-application";
  const response = await api.get(endpoint, { params: params });
  return response;
};
export const postBulkOvertimeApplication_API = async (data) => {
  let endpoint = "employee/manager/overtime-application/bulk";
  const response = await api.post(endpoint, data);
  return response;
};
export const addOvertimeApplication_API = async (data) => {
  let endpoint = "employee/manager/overtime-application";
  const response = await api.post(endpoint, data);
  return response;
};
export const addPersonalOvertimeApplication_API = async (data) => {
  let endpoint = "employee/e/overtime-application";
  const response = await api.post(endpoint, data);
  return response;
};
export const putBulkOvertimeApplication_API = async (uniqueId, data) => {
  let endpoint = `employee/manager/overtime-application/${uniqueId}`;
  const response = await api.put(endpoint, data);
  return response;
};
export const deleteBulkOvertimeApplication_API = async (uniqueId) => {
  let endpoint = `employee/manager/overtime-application/bulk/${uniqueId}`;
  const response = await api.delete(endpoint);
  return response;
};
export const deleteEmployeeOvertimeApplication_API = async (id) => {
  let endpoint = `employee/manager/overtime-application/${id}`;
  const response = await api.delete(endpoint);
  return response;
};
