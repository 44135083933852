// React
import { useState } from "react";

// components
import Table from "../../../components/table/Table";
import Column from "../../../models/table/Column";

// Material UI
import { Grid } from "@mui/material";

// API
import { useSelector } from "react-redux";
import moment from "moment";
import { OPERATORS_CONTAINING } from "../../../constants";

export default function MCInventories() {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [filters, setFilters] = useState([]);
  const { currentEmployee } = useSelector((state) => state.commonThings);
  // useEffect(() => {
  //   paginationHandler();
  // }, [user, offset, limit, filters]);

  // const fetchData = async (filters = undefined) => {
  //   try {
  //     setIsLoading(true);
  //     const payload = {
  //       offset: offset,
  //       limit: limit,
  //       filter: filters,
  //     };
  //     const { data } = await getEmployeeByUserId(user.id);
  //     setRowData(data?.inventories);
  //     setTotalRow(data?.total);
  //   } catch (error) {
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const paginationHandler = async () => {
  //   if (!filters.length > 0) {
  //     await fetchData();
  //     return;
  //   } else {
  //     const encodedFilter = encodeURIComponent(JSON.stringify(filters));
  //     await fetchData(encodedFilter);
  //   }
  // };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const columns = [
    new Column({
      path: "number",
      headerName: "İnventar nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "itemType",
      headerName: "İnventar növü",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "name",
      headerName: "İnventar modeli",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "serialNumber",
      headerName: "İnventar seria no",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "date",
      headerName: "Təhkim edilmə tarixi",
      renderCell: (row) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{moment(row?.date).format("DD/MM/YYYY")}</p>
          </div>
        );
      },
    }),
  ];

  return (
    <>
      {currentEmployee?.inventories?.length !== 0 ? (
        <Grid sx={{ width: "100%" }}>
          <Table
            columns={columns}
            data={currentEmployee?.inventories}
            totalRows={currentEmployee?.inventories?.length}
            offset={offset}
            setOffset={(value) => setOffset(value)}
            limit={limit}
            setLimit={(value) => setLimit(value)}
            applyFiltersHandler={applyFiltersHandler}
            cancelAppliedFilters={cancelAppliedFiltersHandler}
          />
        </Grid>
      ) : (
        <Grid
          sx={{
            width: "100%",
            fontSize: "40px",
            fontWeight: 600,
            textAlign: "center",
            marginTop: "20px",
            backgroundColor: "#233044",
            borderRadius: "10px",
          }}
        >
          Heç bir məlumat tapılmadı
        </Grid>
      )}
    </>
  );
}
