/* eslint-disable prettier/prettier */
import { api } from "../../api/axiosInterceptor";

// POST: /employee/staff -> Add Staff
export const addStaff = async (payload, not) => {
  try {
    const response = await api.post("/employee/staff", payload);
    return await response.data;
  } catch (error) {
    if (error && error?.response?.data?.errors[0]?.message === "Number must be less than or equal to 100") {
      not("Ştat sayı 100dən çox ola bilməz!", { variant: "error" })
    }
  }
};

export const exportStaff = async (checkedExport, structure_ID, type) => {
  let ids = checkedExport.map((id) => `"${id}"`);
  let url =
    checkedExport?.length > 0
      ? `?relIds=[${ids}]&structureId=${structure_ID}`
      : `?structureId=${structure_ID}`;
  try {
    const response = await api.get(`/employee/staff/excel${url}&type=${type}`, {
      responseType: "blob",
    });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getActiveStaffs = async (limit = 999, offset = 0, selectedStructureByStaff) => {
  if (selectedStructureByStaff) {
    return api.get(`/employee/staff/active?limit=${limit}&offset=${offset}&structureId=${selectedStructureByStaff}`);
  } else {
    return api.get(`/employee/staff/active?limit=${limit}&offset=${offset}`);
  }
};
export const getWorkYears = async () => {
  try {
    let endpoint = `/employee/vacation-request/workYears`;
    const response = await api.get(endpoint);
    return response;
  } catch (err) {
    console.log(err);
  }
};
export const getActiveStaffss = async (params) => {
  try {
    let endpoint = `/employee/staff/active`;
    const response = await api.get(endpoint, { params: params });
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateStaff = async ({
  staffId,
  payload
}) => {
  return await api.put(`/employee/staff/${staffId}`, {
    ...payload,
  });
};

export const deleteStaff = async (id) => {
  return await api.delete(`/employee/staff/${id}`);
};

export const getStaffsById = async ({ id, limit, offset }) => {
  return await api.get(
    `/employee/staff?limit=${limit}&offset=${offset}&structureId=${id}`
  );
};

export const getStaffById = async (id) => {
  return await api.get(`/employee/staff/${id}`);
};

export const removeStaff = async (staffId, empId) => {
  return await api.patch(`/employee/staff/remove-employee/${staffId}`, {
    id: empId,
  });
};
