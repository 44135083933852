import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import useOrders from "../../../../../hooks/useOrders";
import useTemporaryReplacement from "../../../../../hooks/useTemporaryReplacement";

const Divider = styled(MuiDivider)(spacing);

const TemporaryReplacement = () => {
  const navigate = useNavigate();
  const {
    data,
    employee,
    contractData,
    currentContractData,
    handleEmployeeChange,
    temporaryReplacementData,
    handleCurrentContract,
  } = useOrders();
  const {
    persent,
    setPersent,
    commandData,
    isFormValid,
    replacedData,
    setContractName,
    replacedEmployee,
    updateVacantorder,
    emptyPositionDatas,
    replacedCurrentContractData,
    handleReplacedEmployeeChange,
    handleReplacedCurrentContract,
    createFillingVacantPositionOrder,
    handleInputChange,
    replacedContractData,
    setStaffId,
  } = useTemporaryReplacement(employee?.empStaffId);
  useEffect(() => {
    setContractName("temporary-replacement-order");
  }, []);

  return (
    <form>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        <CardContent>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            Müvəqqəti əvəzetmə
          </Typography>
          <Divider my={6} />
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid sx={{ width: "50%" }}>
              <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                Əməkdaş Məlumatları
              </Typography>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel>Əməkdaş(A.S.A)</InputLabel>
                <Select
                  name="Əməkdaş(A.S.A)"
                  label="Əməkdaş(A.S.A)"
                  value={employee?.employeeId}
                  onChange={(e) => handleEmployeeChange(e.target.value)}
                  variant="outlined"
                >
                  {data &&
                    data.map((d, i) => (
                      <MenuItem key={i} value={d?._id}>
                        {`${d?.firstname} ${d?.lastname} ${d?.fathername}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Grid sx={{ display: "flex", gap: "25px" }}>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel>Müqavilə nömrəsi</InputLabel>
                  <Select
                    name="Müqavilə nömrəsi"
                    label="Müqavilə nömrəsi"
                    variant="outlined"
                    value={employee?.empStaffId}
                    onChange={(e) => handleCurrentContract(e.target.value)}
                    disabled={!employee.employeeId}
                  >
                    {contractData &&
                      contractData?.map((d, i) => (
                        <MenuItem key={i} value={d?.contract?.staffs?.[0]?._id}>
                          {d?.contract?.data?.docNumber}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={
                      temporaryReplacementData?.oldContractDate
                        ? new Date(temporaryReplacementData?.oldContractDate)
                        : null
                    }
                    label="Müqavilənin tarixi"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
              </Grid>
              <TextField
                name="Tabel nömrəsi"
                label="Tabel nömrəsi"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
                value={
                  temporaryReplacementData?.oldTabelNumber
                    ? temporaryReplacementData?.oldTabelNumber || ""
                    : employee?.tabelNumber || ""
                }
              />
              <TextField
                name="Təşkilat"
                label="Təşkilat"
                variant="outlined"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                my={2}
                value={
                  temporaryReplacementData?.organization
                    ? temporaryReplacementData?.organization || ""
                    : currentContractData?.structure?.organization?.name || ""
                }
              />
              <TextField
                name="Substruktur"
                label="Substruktur"
                variant="outlined"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                my={2}
                value={
                  temporaryReplacementData?.structure
                    ? temporaryReplacementData?.structure || ""
                    : currentContractData?.contract?.topPartOfStructures?.[0]
                        ?.source?.name || ""
                }
              />
              <TextField
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                name="Substruktur"
                label="Substruktur"
                variant="outlined"
                my={2}
                value={
                  temporaryReplacementData?.subStructure
                    ? temporaryReplacementData?.subStructure || ""
                    : currentContractData?.part?.parent?.source?.name || ""
                }
              />
              <TextField
                name="Vəzifə"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                label="Vəzifə"
                variant="outlined"
                my={2}
                value={
                  temporaryReplacementData?.position
                    ? temporaryReplacementData?.position || ""
                    : currentContractData?.part?.source.name || ""
                }
              />
            </Grid>
            <Grid sx={{ width: "50%" }}>
              <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                Əvəz edilənin məlumatları
              </Typography>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="replaced-employee">Əməkdaş(A.S.A)</InputLabel>
                <Select
                  name="replaced-employee"
                  labelId="Əməkdaş(A.S.A)"
                  label="Əməkdaş(A.S.A)"
                  fullWidth
                  value={replacedEmployee.employeeId || ""}
                  onChange={(e) => handleReplacedEmployeeChange(e.target.value)}
                  variant="outlined"
                >
                  {replacedData &&
                    replacedData
                      .filter((d) => d._id !== employee.employeeId)
                      .map((d, i) => (
                        <MenuItem key={i} value={d?._id}>
                          {`${d?.firstname} ${d?.lastname} ${d?.fathername}`}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <Grid
                sx={{
                  display: "flex",
                  gap: "25px",
                  width: "100%",
                  paddingRight: "8px",
                }}
              >
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel>Müqavilə nömrəsi</InputLabel>
                  <Select
                    name="Müqavilə nömrəsi"
                    label="Müqavilə nömrəsi"
                    fullWidth
                    variant="outlined"
                    value={
                      temporaryReplacementData?.newContractNumber
                        ? temporaryReplacementData?.newContractNumber || ""
                        : replacedEmployee?.vacantStaffId || ""
                    }
                    onChange={(e) => {
                      handleReplacedCurrentContract(e.target.value);
                      setStaffId(e.target.value);
                    }}
                    disabled={!replacedEmployee.employeeId}
                  >
                    {/* {temporaryReplacementData?.newContractNumber && (
                      <MenuItem
                        hidden
                        value={temporaryReplacementData?.newContractNumber}
                      >
                        {temporaryReplacementData?.newContractNumber}
                      </MenuItem>
                    )} */}
                    {replacedContractData &&
                      replacedContractData?.map((d, i) => (
                        <MenuItem key={i} value={d?.contract?.staffs?.[0]?._id}>
                          {d?.contract?.data?.docNumber}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={
                      temporaryReplacementData?.newContractDate
                        ? new Date(temporaryReplacementData?.newContractDate)
                        : null
                    }
                    label="Müqavilənin tarixi"
                    name="newContractDate"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
              </Grid>
              <TextField
                name="Tabel nömrəsi"
                label="Tabel nömrəsi"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
                value={
                  temporaryReplacementData?.newTabelNumber
                    ? temporaryReplacementData?.newTabelNumber || ""
                    : replacedEmployee?.tabelNumber || ""
                }
              />
              <TextField
                name="Təşkilat"
                label="Təşkilat"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
                value={
                  temporaryReplacementData?.newOrganization
                    ? temporaryReplacementData?.newOrganization || ""
                    : replacedCurrentContractData?.structure?.organization
                        ?.name || ""
                }
              />
              <TextField
                name="Struktur"
                label="Struktur"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
                value={
                  temporaryReplacementData?.newStructure
                    ? temporaryReplacementData?.newStructure || ""
                    : replacedCurrentContractData?.contract
                        ?.topPartOfStructures?.[0]?.source?.name || ""
                }
              />
              <TextField
                name="Substruktur"
                label="Substruktur"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
                value={
                  temporaryReplacementData?.newSubStructure
                    ? temporaryReplacementData?.newSubStructure || ""
                    : replacedCurrentContractData?.part?.parent?.source?.name ||
                      ""
                }
              />
              <TextField
                name="Vəzifə"
                label="Vəzifə"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
                value={
                  temporaryReplacementData?.newPosition
                    ? temporaryReplacementData?.newPosition || ""
                    : replacedCurrentContractData?.part?.source.name || ""
                }
              />
              <Grid
                sx={{
                  display: "flex",
                  gap: "25px",
                  width: "100%",
                  paddingRight: "8px",
                }}
              >
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="Başlama tarixi"
                    name="startDate"
                    minDate={new Date()}
                    value={
                      temporaryReplacementData?.startDate
                        ? temporaryReplacementData?.startDate || null
                        : emptyPositionDatas?.startDate || null
                    }
                    onChange={(date) => handleInputChange("startDate", date)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  label="Bitmə tarixi"
                  name="endDate"
                  minDate={new Date()}
                  value={
                    temporaryReplacementData?.endDate
                      ? temporaryReplacementData?.endDate || null
                      : emptyPositionDatas?.endDate || null
                  }
                  onChange={(date) => handleInputChange("endDate", date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  paddingRight: "8px",
                }}
              >
                <InputLabel>Ödəniş növü</InputLabel>
                <Select
                  name="paymentType"
                  labelId="Ödənişin növü"
                  label="Ödənişin növü"
                  fullWidth
                  variant="outlined"
                  value={
                    emptyPositionDatas?.paymentType?.value
                      ? emptyPositionDatas?.paymentType?.value || "amount"
                      : emptyPositionDatas?.paymentType || "amount"
                  }
                  onChange={(e) => {
                    handleInputChange("paymentType", e.target.value);
                    setPersent(e.target.value === "percent" ? true : false);
                  }}
                >
                  <MenuItem value="amount">Nağd</MenuItem>
                  <MenuItem value="percent">Faiz</MenuItem>
                </Select>
              </FormControl>
              <Grid
                sx={{
                  display: "flex",
                  gap: "25px",
                  width: "100%",
                  paddingRight: "8px",
                }}
              >
                <TextField
                  label="Əmək haqqına əlavə"
                  name="additionToSalary"
                  value={temporaryReplacementData?.additionToSalary || null}
                  onKeyDown={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "+" ||
                      e.key === "-"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    handleInputChange("additionToSalary", e.target.value)
                  }
                  sx={{
                    width:
                      emptyPositionDatas?.paymentType !== "percent" && !persent
                        ? "70%"
                        : "100%",
                  }}
                  variant="outlined"
                  my={2}
                  type="number"
                />
                {emptyPositionDatas.paymentType !== "percent" && !persent && (
                  <FormControl sx={{ width: "30%", pointerEvents: "none" }}>
                    <InputLabel>Valyuta</InputLabel>
                    <Select
                      name="currency"
                      value={"AZN"}
                      onChange={(e) =>
                        handleInputChange("currency", e.target.value)
                      }
                      labelId="Valyuta"
                      label="Valyuta"
                      fullWidth
                      variant="outlined"
                    >
                      <MenuItem value="AZN">AZN</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Divider my={6} />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => navigate("/documentation/orders")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              title="Kənarlaşdırma yarat"
              variant="contained"
              disabled={commandData?._id ? false : isFormValid()}
              onClick={
                commandData?._id
                  ? updateVacantorder
                  : createFillingVacantPositionOrder
              }
              sx={{ height: "100%" }}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Təsdiq et
              </p>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default TemporaryReplacement;
