import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import Header from "../../components/layouts/header/Header";
import Table from "../../components/table/Table";
import { useEffect, useState } from "react";
import Column from "../../models/table/Column";
import { useTheme } from "@emotion/react";
import { v4 as uuidv4 } from "uuid";
import {
  CloudUpload,
  CloudUpload as MuiCloudUpload,
} from "@mui/icons-material";

// -------------
import React from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useBusinessTrip } from "../../hooks/useBusinessTrip";
import { display, textAlign } from "@mui/system";
import { getAllEmployees, getCommonData } from "../../api/api";
import { api } from "../../api/axiosInterceptor";
import { toast } from "react-toastify";
import moment from "moment";
import NewCommonDataDialog from "../../components/employees/NewCommonDataDialog";
import {
  createOrderForDeduction,
  deleteDeductionData,
  editDeductionData,
  getDeductionData,
  getPermanentDeductions,
  getTemporaryDeductions,
  postDeductionWage,
} from "./services";
import { normalizeDate } from "../../utils/helperFunctions";
import Operator from "../../models/table/Operator";
// icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete as DeleteIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import { OPERATORS_CONTAINING } from "../../constants";
import { update } from "lodash";

//--------------
const header = {
  title: "Əməkhaqqından tutulmalar",
  variant: "h1",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function DeductionsFromSalary() {
  // error states
  const [errorEndDate, setErrorEndDate] = useState("");
  const [errorStartDate, setErrorStartDate] = useState("");
  const [errorEditEndDate, setErrorEditEndDate] = useState("");
  const [errorEditStartDate, setErrorEditStartDate] = useState("");

  // permanent table states
  const [permanentData, setPermanentData] = useState([]);
  const [permanentTotal, setPermanentTotal] = useState(0);
  const [permanentFilters, setPermanentFilters] = useState([]);
  const [permanentLimit, setPermanentLimit] = useState(5);
  const [permanentOffset, setPermanentOffset] = useState(0);
  const [permanentIsLoading, setPermanentIsLoading] = useState(false);
  // temporary table states
  const [temporaryData, setTemporaryData] = useState([]);
  const [temporaryTotal, setTemporaryTotal] = useState(0);
  const [temporaryFilters, setTemporaryFilters] = useState([]);
  const [temporaryLimit, setTemporaryLimit] = useState(5);
  const [temporaryOffset, setTemporaryOffset] = useState(0);
  const [temporaryIsLoading, setTemporaryIsLoading] = useState(false);

  // dialogs
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [deleteDataDialogue, setDeleteDataDialogue] = useState(false);
  const [createOrderDialogue, setCreateOrderDialogue] = useState(false);
  const [editDataDialogue, setEditDataDialogue] = useState(false);
  const [watchDataDialogue, setWatchDataDialogue] = useState(false);

  // dialog form datas
  const [dataID, setDataID] = useState("");
  const [dataForView, setDataForView] = useState({});
  const [updateDatas, setUpdateDatas] = useState({
    id: "",
    deductionType: "",
    deductionName: { value: "", _id: "" },
    amountType: "",
    value: "",
    startDate: "",
    endDate: "",
    article: "",
    note: "",
  });

  const [showAccordion, setShowAccordion] = useState(false);

  // button disabel states
  const [create_btn_disable, set_create_btn_disable] = useState(false);
  const [edit_btn_disable, set_edit_btn_disable] = useState(false);

  // form datas
  const [deductionsName, setDeductionsName] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [employee, set_employee] = useState({});
  const [currentEmployee, setCurrentEmployee] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [selectedType, setSelectedType] = useState([
    "",
    {
      name: "",
      values: [],
    },
  ]);

  const [leave_state, set_leave_state] = useState({
    employee: "",
    staffId: "",
    docDate: "",
    tableNumber: "",
    baseStructure: "",
    structure: "",
    positionName: "",
    deductionType: "",
    deductionName: "",
    amountType: "",
    value: "",
    startDate: "",
    endDate: "",
    article: "",
    note: "",
  });
  useEffect(() => {
    paginationFetchingHandlerForPermanent();
  }, [permanentLimit, permanentOffset, permanentFilters]);
  useEffect(() => {
    paginationFetchingHandlerForTemporary();
  }, [temporaryLimit, temporaryOffset, temporaryFilters]);

  // for all employees
  useEffect(() => {
    getEmployees();
  }, []);

  // for all deduction types
  useEffect(() => {
    const handleSupplements = async () => {
      try {
        const response = await getCommonData("deductionWageGeneral");
        const deductions = response?.data || [];
        setDeductionsName(deductions);
        const combinedData = [];
        if (deductions.length) {
          combinedData.push({ deductions: [...deductions] });
        }
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    handleSupplements();
  }, [submitted]);

  // get one employee datas
  useEffect(() => {
    if (leave_state?.employee) {
      getEmployeeByID(leave_state.employee);
    }
  }, [leave_state.employee]);

  //for  create button disable
  useEffect(() => {
    set_create_btn_disable(
      leave_state?.employee === "" ||
        leave_state?.staffId === "" ||
        leave_state?.docDate === "" ||
        leave_state?.tableNumber === "" ||
        leave_state?.baseStructure === "" ||
        leave_state?.structure === "" ||
        leave_state?.positionName === "" ||
        leave_state?.deductionType === "" ||
        leave_state?.deductionName === "" ||
        leave_state?.amountType === "" ||
        leave_state?.value === "" ||
        leave_state?.startDate === "" ||
        (leave_state?.deductionType !== "temporary" &&
          leave_state?.endDate === "")
    );
  }, [leave_state]);

  //for  edit button disable
  useEffect(() => {
    set_edit_btn_disable(
      updateDatas?.id === "" ||
        updateDatas?.deductionType === "" ||
        updateDatas?.deductionName === "" ||
        updateDatas?.amountType === "" ||
        updateDatas?.value === "" ||
        updateDatas?.startDate === "" ||
        (updateDatas?.deductionType !== "temporary" &&
          updateDatas?.endDate === "")
    );
  }, [updateDatas]);

  // emekdaslarin siyahisi sorgusu
  const getEmployees = async () => {
    const employeeFilter = [
      {
        column: "status",
        operator: "equalsTo",
        value: "active",
      },
    ];
    try {
      const response = await getAllEmployees(
        "",
        "",
        encodeURIComponent(JSON.stringify(employeeFilter))
      );
      if (response?.data?.employees) {
        let emp = response?.data?.employees
          // ?.filter((e) => e?.status !== "inactive")
          .map((e) => ({
            firstname: e?.firstname,
            lastname: e?.lastname,
            fathername: e?.fathername,
            id: e?._id,
          }));
        set_employee(emp);
      }
    } catch (error) {
      console.log("general statistics", error);
    }
  };

  // get permanentData
  const paginationFetchingHandlerForPermanent = async () => {
    if (permanentFilters.length) {
      await getPermanentDeductionsData([...permanentFilters]);
    } else {
      await getPermanentDeductionsData();
    }
  };

  const getPermanentDeductionsData = async (permanentFilters = undefined) => {
    try {
      setPermanentIsLoading(true);

      if (permanentFilters) {
        const resp = await getPermanentDeductions({
          offset: permanentOffset || 0,
          limit: permanentLimit || 10,
          filters: encodeURIComponent(
            JSON.stringify([
              ...permanentFilters,
              { column: "type", operator: "equalsTo", value: "permanent" },
            ])
          ),
        });
        setPermanentData([...resp.data.data]);
        setPermanentTotal(resp.data.total);
      } else {
        const resp = await getPermanentDeductions({
          offset: permanentOffset || 0,
          limit: permanentLimit || 10,
          filters: encodeURIComponent(
            JSON.stringify([
              { column: "type", operator: "equalsTo", value: "permanent" },
            ])
          ),
        });
        setPermanentData([...resp.data.data]);
        setPermanentTotal(resp.data.total);
      }
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setPermanentIsLoading(false);
    }
  };
  // get temporary data
  const paginationFetchingHandlerForTemporary = async () => {
    if (temporaryFilters.length) {
      await getTemporaryDeductionsData([...permanentFilters]);
    } else {
      await getTemporaryDeductionsData();
    }
  };

  const getTemporaryDeductionsData = async (temporaryFilters = undefined) => {
    try {
      setTemporaryIsLoading(true);

      if (temporaryFilters) {
        const resp = await getTemporaryDeductions({
          offset: temporaryOffset || 0,
          limit: temporaryLimit || 10,
          filters: encodeURIComponent(
            JSON.stringify([
              ...temporaryFilters,
              { column: "type", operator: "equalsTo", value: "temporary" },
            ])
          ),
        });
        setTemporaryData([...resp.data.data]);
        setTemporaryTotal(resp.data.total);
      } else {
        const resp = await getTemporaryDeductions({
          offset: temporaryOffset || 0,
          limit: temporaryLimit || 10,
          filters: encodeURIComponent(
            JSON.stringify([
              { column: "type", operator: "equalsTo", value: "temporary" },
            ])
          ),
        });
        setTemporaryData([...resp.data.data]);
        setTemporaryTotal(resp.data.total);
      }
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setTemporaryIsLoading(false);
    }
  };

  // get employee datas

  const getEmployeeByID = async (id) => {
    try {
      const response = await api.get(`employee/e/${id}`);
      setCurrentEmployee(response.data);
      set_leave_state({
        ...leave_state,
        tableNumber: response.data.tableNumber,
      });
    } catch (err) {
      err?.response?.data?.errors.map((item) => {
        console.log(item);
        if (item?.message === "Employee not found") {
          return toast.error("Əməkdaş tapılmadı", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      });
    }
  };

  // delete data
  const deleteData = async (data) => {
    await deleteDeductionData(data);
    await getPermanentDeductionsData();
    await getTemporaryDeductionsData();
  };

  // createorder
  const createOrder = async (data) => {
    await createOrderForDeduction(data);
    await getPermanentDeductionsData();
    await getTemporaryDeductionsData();
  };
  //edit ucun melumatlari elde etme sorgusu
  const getDataForEdit = async (id) => {
    const data = await getDeductionData(id);
    const mainData = data?.data?.wageDeductionData;
    setDataForView({ ...mainData });
    setUpdateDatas({
      id: mainData._id,
      deductionType: mainData.type,
      deductionName: { value: mainData.data.name, _id: mainData.data.id },
      amountType: "",
      value: mainData.data.value,
      startDate: mainData.startDate,
      endDate: mainData.endDate,
      article: mainData.clause.name,
      note: mainData.note,
    });
  };
  // edit data
  const editData = async (data) => {
    const modifiedValue = {
      ...updateDatas,
      deductionName: updateDatas.deductionName.value,
      deductionId: updateDatas.deductionName._id,
    };
    await editDeductionData(modifiedValue);
    await getPermanentDeductionsData();
    await getTemporaryDeductionsData();
    setEditDataDialogue(false);
  };

  // swipe datas
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const handleChangeTab = (e, value) => {
    setValue(value);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const handleSubmit = async (e) => {
    const uniqueID = uuidv4();
    await postDeductionWage(leave_state, uniqueID);
    await getPermanentDeductionsData();
    await getTemporaryDeductionsData();
    set_leave_state({
      employee: "",
      staffId: "",
      docDate: "",
      tableNumber: "",
      baseStructure: "",
      structure: "",
      positionName: "",
      deductionType: "",
      deductionName: "",
      amountType: "",
      value: "",
      startDate: "",
      endDate: "",
      article: "",
      note: "",
    });
    setShowAccordion(false);
  };

  const articleOfTheLaborCode = [
    "a) qanunvericiliklə müəyyən edilmiş müvafiq vergilər, sosial sığorta haqları və digər icbari ödəmələr",
    "b) qanunvericilikdə nəzərdə tutulan icra sənədləri üzrə müəyyən edilmiş məbləğ",
    "c) işəgötürənə işçinin (tam maddi məsuliyyət daşıdığı hallar istisna olunmaqla) təqsiri üzündən onun orta aylıq əmək haqqından artıq olmayan miqdarda vurduğu ziyanın məbləği",
    "ç) müvafiq iş ilinə görə məzuniyyətə çıxmış və həmin iş ili bitənədək işdən çıxdığı halda məzuniyyətin işlənməmiş günlərinə düşən məzuniyyət pulu",
    "d) xidməti ezamiyyətə göndərilən işçiyə avans olaraq verilmiş ezamiyyə xərclərinin ezamiyyətdən qayıtdıqdan sonra artıq qalmış borc məbləği",
    "e) mühasibat tərəfindən ehtiyatsızlıqla səhvən yerinə yetirilən riyazi əməliyyatlar nəticəsində artıq verilmiş məbləğlər",
    "ə) təsərrüfat ehtiyaclarından ötrü malların, əmtəələrin alınması üçün verilmiş, lakin xərclənməmiş və vaxtında qaytarılmamış pulun məbləği",
    "f) kollektiv müqavilələrdə nəzərdə tutulan hallarda müəyyən edilmiş məbləğ",
    "g) həmkarlar ittifaqının üzvü olan işçilərin əmək haqqından mühasibatlıq vasitəsi ilə tutulan və 4 iş günü müddətində həmin müəssisənin həmkarlar ittifaqı təşkilatının xüsusi hesabına köçürülən həmkarlar ittifaqına üzvlük ",
  ];
  // operator generator
  const operatorGeneratorHandler = (row, column) => {
    let visibility = false;
    let createOrder = false;
    if (row.status === "active" || row.status === "rejected") {
      visibility = true;
    }
    if (row.status === "pending_order_approve") {
      createOrder = true;
    }
    const operators = [
      new Operator({
        icon: <VisibilityIcon />,
        onClick: () => {
          setWatchDataDialogue(true);
          getDataForEdit(row._id);
        },
        type: 0,
      }),
      new Operator({
        icon: <EditIcon />,
        onClick: () => {
          getDataForEdit(row._id);
          setEditDataDialogue(true);
        },
        type: 0,
        hideCondition: visibility,
      }),
      new Operator({
        icon: <DeleteIcon />,
        onClick: () => {
          setDeleteDataDialogue(true);
          setDataID(row._id);
        },
        hideCondition: row.isUsedInStaff,
        type: 0,
        hideCondition: visibility,
      }),
      new Operator({
        icon: <div>Əmr yarat</div>,
        onClick: () => {
          setCreateOrderDialogue(true);
          setDataID(row._id);
        },
        type: 0,
        hideCondition: visibility || createOrder,
      }),
    ];

    return <OperatorGenerator operators={operators} />;
  };

  // columns
  const permanentColumn = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      // isStaticColumn: true,
      widthOfColumn: 200,
      renderCell: operatorGeneratorHandler,
    }),
    new Column({
      path: "employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "contract.data.docNumber",
      headerName: "Əmək müqaviləsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "structure.organization.name",
      headerName: "Təşkilat",
      filterType: OPERATORS_CONTAINING.OPTION,
    }),
    new Column({
      path: "topPartOfStructure.source.name",
      headerName: "Struktur",
      filterType: OPERATORS_CONTAINING.OPTION,
    }),
    new Column({
      path: "subStructure.source.name",
      headerName: "Sub-struktur",
      filterType: OPERATORS_CONTAINING.OPTION,
      dependencieKeysOfFilter: {
        structures: ["baseStructures"],
        topPartOfStructures: ["topPartOfStructures"],
      },
      keyForResponseDataFromFilter: "topPartOfStructures",
    }),
    new Column({
      path: "position",
      headerName: "Vəzifə",
      filterType: OPERATORS_CONTAINING.OPTION,
    }),
    new Column({
      path: "data[0].name",
      headerName: "Tutulmanın adı",
      filterColumnKey: "data.name",

      renderCell: (data) => {
        return <div style={{ textAlign: "center" }}>{data.data.name}</div>;
      },
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "note",
      headerName: "Tutulmanın izahı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "startDate",
      headerName: "Başlama tarixi",
      renderCell: (data) => {
        return (
          <div style={{ textAlign: "center" }}>
            {normalizeDate(data.startDate)}
          </div>
        );
      },
      filterType: OPERATORS_CONTAINING.DATE,
    }),
    new Column({
      path: "endDate",
      headerName: "Bitmə tarixi",
      renderCell: (data) => {
        return (
          <div style={{ textAlign: "center" }}>
            {normalizeDate(data.endDate)}
          </div>
        );
      },
      filterType: OPERATORS_CONTAINING.DATE,
    }),
    new Column({
      path: "data[0].value",
      headerName: "Məbləğ",
      filterColumnKey: "data.value",
      renderCell: (data) => {
        return <div style={{ textAlign: "center" }}>{data.data.value}</div>;
      },
      filterType: OPERATORS_CONTAINING.NUMBER,
    }),

    // new Column({
    //   path: "type",
    //   headerName: "Tutulmanın növü",
    //   renderCell: (data) => {
    //     let type = "";
    //     if (data.type === "temporary") {
    //       type = "Birdəfəlik";
    //     } else if (data.type === "permanent") {
    //       type = "Daimi";
    //     }
    //     return <div style={{ textAlign: "center" }}>{type}</div>;
    //   },
    //   filterType: OPERATORS_CONTAINING.OPTION,
    // }),
    new Column({
      path: "clause.name",
      headerName: "Ə.M. maddəsi",

      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.OPTION,

      renderCell: (data) => {
        let status;
        if (data.status === "active") {
          status = "Aktiv";
        } else if (data.status === "rejected") {
          status = "İmtina edilmişdir";
        } else if (
          data.status === "pending" ||
          data.status === "pending_order" ||
          data.status === "pending_order_approve"
        ) {
          status = "Gözlənilir";
        }
        return (
          <>
            <div
              style={{
                textAlign: "center",
                borderRadius: "5px",
                backgroundColor:
                  data.status === "active"
                    ? "#39a12f"
                    : data.status === "rejected"
                    ? "#c33c50"
                    : "#2aaee2",
              }}
            >
              {status}
            </div>
          </>
        );
      },
    }),
  ];

  return (
    <>
      <Grid sx={{ width: "100%", textAlign: "center", marginBottom: "50px" }}>
        <Header data={header} />
      </Grid>

      <Box>
        <Box>
          <Tabs value={value} onChange={handleChangeTab}>
            <Tab label="Daimi tutulmalar" value={0} {...a11yProps(0)} />
            <Tab label="Birdəfəlik tutulmalar" value={1} {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => setShowAccordion(!showAccordion)}
          variant="contained"
          sx={{ height: "100%" }}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "50px",
            }}
          >
            Tutulma yarat
          </p>
        </Button>
      </div>
      {showAccordion && (
        <form>
          <div className="d-flex flex-column gap-3">
            {/* emekdasin adi,muqavile nomresi,muqavile tarixi,tabel nomresi */}
            <div className="d-flex w-100 gap-3">
              <div className="w-25 ">
                <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
                  Əməkdaşlar
                </InputLabel>

                <Autocomplete
                  sx={{ width: "100%" }}
                  options={employee}
                  renderInput={(params) => (
                    <TextField {...params} label="Əməkdaşı seçin" />
                  )}
                  getOptionLabel={(option) =>
                    `${option.firstname} ${option?.lastname} ${option?.fathername}`
                  }
                  inputValue={searchValue || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onInputChange={(event, newInputValue) => {
                    setSearchValue(newInputValue);
                  }}
                  onChange={(e, newInputValue) => {
                    setCurrentEmployee(newInputValue);
                    set_leave_state({
                      ...leave_state,
                      employee: newInputValue?.id ? newInputValue.id : "",
                    });
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {`${option.firstname} ${option.lastname} ${option.fathername}`}
                      </li>
                    );
                  }}
                />
              </div>
              <div className="w-25">
                <div>Müqavilə nömrəsi</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Müqavilə nömrəsi
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={leave_state?.staffId}
                      disabled={!leave_state?.employee}
                      label="Müqavilə nömrəsi"
                      placeholder="ok"
                      onChange={(e) => {
                        const selectedStaff =
                          currentEmployee &&
                          currentEmployee.staffs.filter((item) => {
                            return item?._id === e?.target?.value;
                          });

                        set_leave_state({
                          ...leave_state,
                          staffId: e.target.value,
                          positionName: selectedStaff?.[0]?.position?.name,
                          baseStructure:
                            selectedStaff?.[0]?.structure?.organization?.name,
                          structure:
                            selectedStaff?.[0]?.contract
                              ?.topPartOfStructures?.[0]?.source?.name,
                          docDate: selectedStaff?.[0]?.contract?.decisionDate,
                        });
                      }}
                    >
                      {currentEmployee?.staffs &&
                        currentEmployee.staffs.map((e) => (
                          <MenuItem value={e?._id}>
                            {e?.contract?.data?.docNumber}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="w-25">
                <div>Müqavilə tarixi</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    label="Müqavilə tarixi"
                    fullWidth
                    value={
                      leave_state.staffId
                        ? moment(leave_state?.docDate).format("DD/MM/yyyy")
                        : ""
                    }
                    InputProps={{
                      readOnly: true,
                      style: {
                        pointerEvents: "none",
                        cursor: "default",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-25">
                <div>Tabel No</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    label="Tabel No"
                    fullWidth
                    value={leave_state?.tableNumber}
                    InputProps={{
                      readOnly: true,
                      style: {
                        pointerEvents: "none",
                        cursor: "default",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            {/* teskilat,struktur,vezife,tutulmanin novu */}
            <div className="d-flex w-100 gap-3">
              <div className="w-25">
                <div>Təşkilat</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    label="Təşkilat"
                    value={leave_state?.baseStructure || ""}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: {
                        pointerEvents: "none",
                        cursor: "default",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-25">
                <div>Struktur</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    label="Struktur"
                    value={leave_state?.structure || ""}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: {
                        pointerEvents: "none",
                        cursor: "default",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-25">
                <div>Vəzifə</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    label="Vəzifə"
                    value={leave_state?.positionName || ""}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: {
                        pointerEvents: "none",
                        cursor: "default",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-25">
                <div>Tutulmanın növü</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Tutulmanın növü
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={leave_state?.deductionType || ""}
                      label="Tutulma növü"
                      onChange={(e) => {
                        if (e.target.value === "temporary") {
                          set_leave_state({
                            ...leave_state,
                            deductionType: e.target.value,
                            endDate: "",
                          });
                        } else {
                          set_leave_state({
                            ...leave_state,
                            deductionType: e.target.value,
                          });
                        }
                      }}
                    >
                      <MenuItem key={`1`} value={"permanent"}>
                        Daimi
                      </MenuItem>
                      <MenuItem key={`2`} value={"temporary"}>
                        Birdəfəlik
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            {/* emekhaqqindan tutulmani sec,mebleg,faiz,fayl yukle */}
            <div className="d-flex w-100 gap-3">
              <div className="w-25">
                <div>Əməkhaqqından tutulmanı seç</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Əməkhaqqından tutulmaların səbəbi *
                    </InputLabel>
                    <Select
                      label="Əməkhaqqından tutulmaların səbəbi *"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => {
                        const selectedItem = e.target.value;
                        set_leave_state({
                          ...leave_state,
                          deductionName: selectedItem.value,
                          deductionId: selectedItem._id,
                        });
                      }}
                      fullWidth
                    >
                      {deductionsName &&
                        deductionsName
                          .sort((a, b) => a?.name?.localeCompare(b?.name, "az"))
                          .map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item.value}
                            </MenuItem>
                          ))}
                      <Button
                        sx={{
                          mt: 3,
                          ml: 3,
                        }}
                        variant="outlined"
                        onClick={() => {
                          setOpenAddDialog(true);
                          setSelectedType([
                            "deductionWageGeneral",
                            { name: "Ümumi əlavələr" },
                          ]);
                        }}
                      >
                        Yeni əməkhaqqına tutulma səbəbi əlavə et
                      </Button>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="w-25">
                <div>Məbləğ növü</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Məbləğ növü
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={leave_state?.amountType || ""}
                      label="Məbləğ növü"
                      onChange={(e) => {
                        set_leave_state({
                          ...leave_state,
                          amountType: e.target.value,
                        });
                      }}
                    >
                      <MenuItem key={`1`} value={"amount"}>
                        Məbləğ
                      </MenuItem>
                      <MenuItem key={`2`} value={"percent"}>
                        Faiz
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="w-25">
                <div>Miqdar</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    label="Miqdar"
                    value={leave_state?.value || ""}
                    onChange={(e) => {
                      set_leave_state({
                        ...leave_state,
                        value: +e.target.value,
                      });
                    }}
                    fullWidth
                  />
                </div>
              </div>
              <div
                className="w-25"
                style={{
                  display: "flex",
                }}
              >
                <InputLabel sx={{ color: "white" }}>Fayl yüklə</InputLabel>
                <Tooltip title="pdf, doc, docx, png, jpeg">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <span className="upload" style={{ marginRight: "6px" }}>
                      {leave_state?.issuancePdf?.length !== 0 ? (
                        <span>{leave_state?.issuancePdf?.name}</span>
                      ) : (
                        <></>
                      )}
                      <input
                        type="file"
                        id="raised-button-file"
                        name="templateFile"
                        accept=".pdf, .doc, .docx, .png, .jpeg"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          if (e.target?.files?.length > 0) {
                            const file = e.target.files[0];
                            set_leave_state({
                              ...leave_state,
                              issuancePdf: file,
                            });
                          }
                        }}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="outlined"
                          color="primary"
                          component="span"
                        >
                          <CloudUpload mr={2} /> Fayl yüklə
                        </Button>
                      </label>
                    </span>
                  </div>
                </Tooltip>
              </div>
            </div>
            {/* baslama tarixi,bitme tarixi,emek mecellesinin maddesi */}
            <div className="d-flex w-100 gap-3">
              <div className="w-25">
                <div>Başlama tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    sx={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    value={
                      leave_state?.startDate
                        ? new Date(leave_state?.startDate)
                        : null
                    }
                    slotProps={{
                      textField: {
                        error: !!errorStartDate,
                        helperText: errorStartDate,
                        fullWidth: true,
                      },
                    }}
                    maxDate={leave_state?.endDate}
                    // shouldDisableDate={shouldDisableDate}
                    onChange={(date) => {
                      set_leave_state({
                        ...leave_state,
                        startDate: date,
                      });
                    }}
                    onError={(reason, value) => {
                      if (reason === "invalidDate") {
                        setErrorStartDate("Keçərsiz tarix formatı");
                      } else if (reason === "maxDate") {
                        setErrorStartDate(
                          "Başlama tarixi bitmə tarixindən böyük ola bilməz"
                        );
                      } else {
                        setErrorStartDate("");
                      }
                    }}
                  />
                </div>
              </div>
              <div className="w-25 ms-2">
                <div>Bitmə tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    disabled={
                      leave_state.deductionType === "temporary" ? true : false
                    }
                    sx={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    value={
                      leave_state?.endDate
                        ? new Date(leave_state?.endDate)
                        : null
                    }
                    slotProps={{
                      textField: {
                        error: !!errorEndDate,
                        helperText: errorEndDate,
                        fullWidth: true,
                      },
                    }}
                    minDate={leave_state?.startDate}
                    // shouldDisableDate={shouldDisableDate}
                    onChange={(date) => {
                      set_leave_state({
                        ...leave_state,
                        endDate: date,
                      });
                    }}
                    onError={(reason, value) => {
                      if (reason === "invalidDate") {
                        setErrorEndDate("Keçərsiz tarix formatı");
                      } else if (reason === "minDate") {
                        setErrorEndDate(
                          "Bitmə tarixi başlama tarixindən kiçik ola bilməz"
                        );
                      } else {
                        setErrorEndDate("");
                      }
                    }}
                  />
                </div>
              </div>
              <div className="w-50">
                <div>Əmək məcəlləsinin maddəsi</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Əmək məcəlləsinin maddəsi
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={leave_state?.article || ""}
                      label="Tutulma növü"
                      onChange={(e) => {
                        set_leave_state({
                          ...leave_state,
                          article: e.target.value,
                        });
                      }}
                    >
                      {articleOfTheLaborCode.map((item, index) => {
                        return (
                          <MenuItem
                            style={{ overflow: "auto" }}
                            key={index}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            {/* sebeb */}
            <div className="d-flex w-100 gap-3">
              <div className="w-100 ">
                <div>Səbəbi</div>
                <div className="mt-2">
                  <TextField
                    onChange={(e) =>
                      set_leave_state({
                        ...leave_state,
                        note: e.target.value,
                      })
                    }
                    value={leave_state?.note || ""}
                    fullWidth
                    placeholder="Səbəbi"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-25 ms-2 mt-3">
            <Button
              variant="contained"
              disabled={create_btn_disable}
              onClick={handleSubmit}
            >
              Yarat
            </Button>
          </div>
        </form>
      )}
      <NewCommonDataDialog
        openAddDialog={openAddDialog}
        setOpenAddDialog={setOpenAddDialog}
        selectedType={selectedType}
        submitted={submitted}
        setSubmitted={setSubmitted}
      />
      <Box>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Grid className="mt-4">
            <Table
              columns={permanentColumn}
              data={permanentData}
              totalRows={permanentTotal}
              offset={permanentOffset}
              setOffset={(value) => setPermanentOffset(value)}
              limit={permanentLimit}
              setLimit={(value) => setPermanentLimit(value)}
              isLoading={permanentIsLoading}
              applyFiltersHandler={(filters) => setPermanentFilters(filters)}
              cancelAppliedFilters={() => setPermanentFilters([])}
            />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Grid className="mt-4">
            <Table
              columns={permanentColumn}
              data={temporaryData}
              totalRows={temporaryTotal}
              offset={temporaryOffset}
              setOffset={(value) => setTemporaryOffset(value)}
              limit={temporaryLimit}
              setLimit={(value) => setTemporaryLimit(value)}
              isLoading={temporaryIsLoading}
              applyFiltersHandler={(filters) => setTemporaryFilters(filters)}
              cancelAppliedFilters={() => setTemporaryFilters([])}
            />
          </Grid>
        </TabPanel>
      </Box>
      {/* delete dialog */}
      <Dialog
        open={deleteDataDialogue}
        onClose={() => setDeleteDataDialogue(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Məlumat silinsin?</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteDataDialogue(false);
            }}
          >
            Xeyr
          </Button>
          <Button
            onClick={() => {
              console.log(dataID);
              setDeleteDataDialogue(false);
              deleteData(dataID);
            }}
          >
            Bəli
          </Button>
        </DialogActions>
      </Dialog>
      {/* create order dialog */}
      <Dialog
        open={createOrderDialogue}
        onClose={() => setCreateOrderDialogue(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Əmr yaradılsın?</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setCreateOrderDialogue(false);
            }}
          >
            Xeyr
          </Button>
          <Button
            onClick={() => {
              setCreateOrderDialogue(false);
              createOrder(dataID);
            }}
          >
            Bəli
          </Button>
        </DialogActions>
      </Dialog>
      {/* edit dialog */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "700px",
            maxWidth: "unset",
            padding: "15px 10px",
          },
        }}
        open={editDataDialogue}
        onClose={() => setEditDataDialogue(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ textAlign: "center", fontSize: "24px" }}
          id="alert-dialog-title"
        >
          Tutulma məlumatlarını dəyiş
        </DialogTitle>
        {updateDatas && (
          <div className="d-flex flex-column gap-3">
            {/* emekhaqqindan tutulmani sec,mebleg,faiz,fayl yukle */}
            <div className="d-flex w-100 gap-3">
              <div className="w-25">
                <div>Tutulmanı seç</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Əməkhaqqından tutulmaların səbəbi
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={updateDatas?.deductionName?._id || ""}
                      onChange={(e) => {
                        const selectedId = e.target.value;
                        const selectedItem = deductionsName.find(
                          (item) => item._id === selectedId
                        );
                        setUpdateDatas({
                          ...updateDatas,
                          deductionName: selectedItem || { value: "", _id: "" },
                        });
                      }}
                      fullWidth
                    >
                      {deductionsName &&
                        deductionsName
                          .sort((a, b) => a?.name?.localeCompare(b?.name, "az"))
                          .map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.value}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="w-25">
                <div>Məbləğ növü</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Məbləğ növü
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={updateDatas?.amountType || ""}
                      label="Məbləğ növü"
                      onChange={(e) => {
                        setUpdateDatas({
                          ...updateDatas,
                          amountType: e.target.value,
                        });
                      }}
                    >
                      <MenuItem key={`1`} value={"amount"}>
                        Məbləğ
                      </MenuItem>
                      <MenuItem key={`2`} value={"percent"}>
                        Faiz
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="w-25">
                <div>Miqdar</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    value={updateDatas?.value || ""}
                    onChange={(e) => {
                      setUpdateDatas({
                        ...updateDatas,
                        value: +e.target.value,
                      });
                    }}
                    fullWidth
                  />
                </div>
              </div>
              <div className="w-25">
                <div>Tutulmanın növü</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Tutulmanın növü
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={updateDatas?.deductionType || ""}
                      label="Tutulma növü"
                      onChange={(e) => {
                        setUpdateDatas({
                          ...updateDatas,
                          deductionType: e.target.value,
                        });
                      }}
                    >
                      <MenuItem key={`1`} value={"permanent"}>
                        Daimi
                      </MenuItem>
                      <MenuItem key={`2`} value={"temporary"}>
                        Birdəfəlik
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            {/* baslama tarixi,bitme tarixi,emek mecellesinin maddesi */}
            <div className="d-flex w-100 gap-3">
              <div className="w-25">
                <div>Başlama tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    sx={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    value={
                      updateDatas?.startDate
                        ? new Date(updateDatas?.startDate)
                        : null
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    maxDate={updateDatas?.endDate}
                    // shouldDisableDate={shouldDisableDate}
                    onChange={(date) => {
                      setUpdateDatas({
                        ...updateDatas,
                        startDate: date,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-25 ms-2">
                <div>Bitmə tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    disabled={
                      updateDatas.deductionType === "temporary" ? true : false
                    }
                    sx={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    value={
                      updateDatas?.endDate
                        ? new Date(updateDatas?.endDate)
                        : null
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    minDate={updateDatas?.startDate}
                    // shouldDisableDate={shouldDisableDate}
                    onChange={(date) => {
                      setUpdateDatas({
                        ...updateDatas,
                        endDate: date,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-50">
                <div>Əmək məcəlləsinin maddəsi</div>
                <div className="mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Əmək məcəlləsinin maddəsi
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={updateDatas?.article || ""}
                      label="Əmək məcəlləsinin maddəsi"
                      onChange={(e) => {
                        setUpdateDatas({
                          ...updateDatas,
                          article: e.target.value,
                        });
                      }}
                    >
                      {articleOfTheLaborCode.map((item, index) => {
                        return (
                          <MenuItem
                            style={{ overflow: "auto" }}
                            key={index}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            {/* sebeb */}
            <div className="d-flex w-100 gap-3">
              <div className="w-100 ">
                <div>Səbəbi</div>
                <div className="mt-2">
                  <TextField
                    onChange={(e) =>
                      setUpdateDatas({
                        ...updateDatas,
                        note: e.target.value,
                      })
                    }
                    value={updateDatas?.note || ""}
                    fullWidth
                    placeholder="Səbəbi"
                  />
                </div>
              </div>
            </div>
            <div className="w-25 ms-2 mt-3">
              <Button
                variant="contained"
                disabled={edit_btn_disable}
                onClick={editData}
              >
                Dəyişiklik et
              </Button>
            </div>
          </div>
        )}
      </Dialog>
      {/* watch dialog */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "700px",
            maxWidth: "unset",
            padding: "15px 10px",
          },
        }}
        open={watchDataDialogue}
        onClose={() => setWatchDataDialogue(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ textAlign: "center", fontSize: "24px" }}
          id="alert-dialog-title"
        >
          Tutulma məlumatları
        </DialogTitle>
        <form>
          <div className="d-flex flex-column gap-3">
            {/* emekdasin adi,muqavile nomresi,muqavile tarixi,tabel nomresi */}
            <div className="d-flex w-100 gap-3">
              <div className="w-25 ">
                <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
                  Əməkdaş adı
                </InputLabel>

                <div className="mt-2">
                  <TextField
                    type="text"
                    fullWidth
                    value={`  ${dataForView?.employee?.firstname}  ${dataForView?.employee?.lastname}  ${dataForView?.employee?.fathername}`}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="w-25">
                <div>Müqavilə nömrəsi</div>
                <div className="mt-2">
                  <div className="mt-2">
                    <TextField
                      type="text"
                      fullWidth
                      disabled={true}
                      value={dataForView?.contract?.data?.docNumber}
                    />
                  </div>
                </div>
              </div>
              <div className="w-25">
                <div>Struktur</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    value={dataForView?.topPartOfStructure?.source?.name || ""}
                    fullWidth
                    disabled={true}
                  />
                </div>
              </div>
              <div className="w-25">
                <div>Vəzifə</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    value={dataForView?.position || ""}
                    fullWidth
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            {/* teskilat,struktur,vezife,tutulmanin novu */}
            <div className="d-flex w-100 gap-3">
              <div className="w-25">
                <div>Tutulmanın növü</div>
                <div className="mt-2">
                  <div className="mt-2">
                    <TextField
                      type="text"
                      fullWidth
                      disabled={true}
                      value={
                        dataForView?.type === "temporary"
                          ? "Daimi"
                          : dataForView?.type === "permanent"
                          ? "Birdəfəlik"
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="w-25">
                <div>Tutulma adı</div>
                <div className="mt-2">
                  <div className="mt-2">
                    <TextField
                      type="text"
                      fullWidth
                      disabled={true}
                      value={dataForView?.data?.name}
                    />
                  </div>
                </div>
              </div>
              <div className="w-25">
                <div>Ümumi</div>
                <div className="mt-2">
                  <div className="mt-2">
                    <TextField
                      type="text"
                      fullWidth
                      disabled={true}
                      value={dataForView?.total}
                    />
                  </div>
                </div>
              </div>
              <div className="w-25">
                <div>Ödənilib</div>
                <div className="mt-2">
                  <div className="mt-2">
                    <TextField
                      type="text"
                      fullWidth
                      disabled={true}
                      value={dataForView?.paid}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* qaliq,miqdar,baslama tarixi bitme tarixi */}
            <div className="d-flex w-100 gap-3">
              <div className="w-25">
                <div>Qalıq</div>
                <div className="mt-2">
                  <div className="mt-2">
                    <TextField
                      type="text"
                      fullWidth
                      disabled={true}
                      value={dataForView?.remaining}
                    />
                  </div>
                </div>
              </div>
              <div className="w-25">
                <div>Tutulma miqdarı</div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    disabled={true}
                    value={dataForView?.data?.value || ""}
                    fullWidth
                  />
                </div>
              </div>
              <div className="w-25">
                <div>Başlama tarixi</div>
                <div className="mt-2">
                  <div className="mt-2">
                    <TextField
                      type="text"
                      fullWidth
                      disabled={true}
                      value={normalizeDate(dataForView?.startDate) || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="w-25">
                <div>Bitmə tarixi</div>
                <div className="mt-2">
                  <div className="mt-2">
                    <TextField
                      type="text"
                      fullWidth
                      disabled={true}
                      value={normalizeDate(dataForView?.endDate) || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* emek mecellesinin maddesi */}
            <div className="d-flex w-100 gap-3">
              <div className="w-100">
                <div>Əmək məcəlləsinin maddəsi</div>
                <div className="mt-2">
                  <div className="mt-2">
                    <TextField
                      type="text"
                      fullWidth
                      disabled={true}
                      value={dataForView?.clause?.name || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* sebeb */}
            <div className="d-flex w-100 gap-3">
              <div className="w-100 ">
                <div>Səbəbi</div>
                <div className="mt-2">
                  <TextField
                    value={dataForView?.note || ""}
                    fullWidth
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
}
