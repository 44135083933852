import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { handleServiceCall } from "../services/handleServiceCall";
import {
  registerUser_SERVICE,
  deleteUser_SERVICE,
  editUser_SERVICE,
  getAllUsers_SERVICE,
  registerAccount_SERVICE,
  getAllAccounts_SERVICE,
  getAccounts_SERVICE,
} from "../services/user-accountServices";

// Import your API services
// import {
//   registerUser_SERVICE,
//   deleteUser_SERVICE,
//   editUser_SERVICE,
//   getAllUsers_SERVICE,
// } from "../services/user-accountServices";
const useAccountActions = () => {
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userRegisterDialog, setUserRegisterDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [preDefinedData, setPreDefinedData] = useState({});
  const requestError = (error) => {
    enqueueSnackbar("Xəta baş verdi", { variant: "error" });
    // setUserRegisterDialog(false);
  };

  //   const refetchUsers = async () => {
  //     await handleServiceCall(
  //       handleGetAllSuccess,
  //       requestError,
  //       getAllAccounts_SERVICE,
  //       { limit: 10, offset: 0 }
  //     );
  //   };
  // Success Handlers
  const handleRegisterSuccess = (data, email) => {
    enqueueSnackbar("Hesab uğurla yaradıldı", { variant: "success" });
    setActiveStep(2);
  };
  //   const handleDeleteSuccess = () => {
  //     enqueueSnackbar("Hesab uğurla silindi", { variant: "success" });
  //     refetchUsers();
  //   };

  //   const handleEditSuccess = (data) => {
  //     enqueueSnackbar("Hesab uğurla redaktə edildi", { variant: "success" });
  //     refetchUsers();
  //   };

  const handleGetAllSuccess = (data) => {
    setData(data?.items);
    setTotal(data?.total);
  };

  // API Calls
  const registerAccount = async (accountRegisterData) =>
    await handleServiceCall(
      handleRegisterSuccess,
      requestError,
      registerAccount_SERVICE,
      "a",
      accountRegisterData
    );

  //   const deleteUser = async (id) =>
  //     await handleServiceCall(
  //       handleDeleteSuccess,
  //       requestError,
  //       deleteUser_SERVICE,
  //       id
  //     );

  //   const editUser = async (id) =>
  //     await handleServiceCall(
  //       handleEditSuccess,
  //       requestError,
  //       editUser_SERVICE,
  //       id
  //     );

  const getAccounts = async (id) =>
    await handleServiceCall(
      setLoading,
      handleGetAllSuccess,
      requestError,
      getAccounts_SERVICE,
      { id, limit: 10, offset: 0 }
    );

  return {
    registerAccount,
    // deleteUser,
    // editUser,
    getAccounts,
    // refetchUsers,
    userRegisterDialog,
    activeStep,
    setActiveStep,
    setUserRegisterDialog,
    data,
    setData,
    total,
    loading,
  };
};

export default useAccountActions;
