import React, { useState } from "react";
import { Field, FieldArray, Formik, Form, ErrorMessage } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  FormHelperText,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CreateIcon from "@mui/icons-material/Create";
import { sendPersonalInfoChanges } from "../../../services/employeeCabinet/employeeCabinet";
import { Step4Schema } from "../../../components/employees/Validations";
import { relationshipInfoValidation } from "./Validations";

const educationType = [
  { value: "primary", name: "Ümumi təhsil - İbtidati təhsil" },
  { value: "bachelor", name: "Ali təhsil - bakalavriat" },
  { value: "master", name: "Ali təhsil - magistratura" },
  { value: "doctoral", name: "Ali təhsil - doktorantura" },
  { value: "generalSecondary", name: "Ümumi təhsil - ümumi orta təhsil" },
  { value: "completeSecondary", name: "Ümumi təhsil - tam orta təhsil" },
  { value: "firstVocational", name: "İlk peşə-ixtisas təhsili" },
  { value: "secondarySpecial", name: "Orta ixtisas təhsili" },
  {
    value: "secBasisOfGeneralSec",
    name: "Ümumi orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "secBasisOfCompleteSec",
    name: "Tam orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "incompleteHigherTill1993",
    name: "Ali təhsil - 1993 cü ilə qədər natamam ali təhsil",
  },
  {
    value: "completeHigherTill1993",
    name: "Ali təhsil - 1997 ci ilə qədər tam ali təhsil",
  },
];

const countries = [
  "Albaniya",
  "Almaniya",
  "Anqola",
  "Antiqua və Barbuda",
  "Argentina",
  "Avstraliya",
  "Avstriya",
  "Azərbaycan",
  "Bahamalar",
  "Bahrayn",
  "Banqladeş",
  "Barbados",
  "Belarus",
  "Belçika",
  "Boliviya",
  "Bosniya və Herseqovina",
  "Botswana",
  "Braziliya",
  "Böyük Britaniya",
  "Büyükdəniz adası",
  "Bəhreyn",
  "Bəruney",
  "Cənubi Afrika",
  "Cənubi Sudan",
  "Cənubi Koreya",
  "Çad",
  "Çexiya",
  "Çili",
  "Çin",
  "Çernoqoriya",
  "Çexoslavakiya",
  "Danemarka",
  "Dominika",
  "Dominikan Respublikası",
  "Ekvador",
  "Ekvatorial Qvineya",
  "Eritreya",
  "Estoniya",
  "Əfqanıstan",
  "Fici",
  "Filippin",
  "Finlandiya",
  "Fransa",
  "Fransız Qvineyası",
  "Fələstin",
  "Gana",
  "Qabon",
  "Qambiya",
  "Qazaxıstan",
  "Qatar",
  "Qrenada",
  "Qvineya",
  "Qvineya-Bisau",
  "Gürcüstan",
  "Haiti",
  "Hollandiya",
  "Honduras",
  "Honkonq",
  "Hindistan",
  "Horvatiya",
  "Indoneziya",
  "İngiltərə",
  "İordaniya",
  "İraq",
  "İran",
  "İrlandiya",
  "İslandiya",
  "İspaniya",
  "İsrail",
  "İsveç",
  "İsveçrə",
  "İtaliya",
  "Kamboca",
  "Kamerun",
  "Kanada",
  "Keniya",
  "Kipr",
  "Kiribati",
  "Kolumbiya",
  "Komor Adaları",
  "Kongo",
  "Kosta Rika",
  "Kotd’İvor",
  "Kuba",
  "Kuveyt",
  "Kırqızıstan",
  "Latviya",
  "Lesoto",
  "Livan",
  "Liberiya",
  "Litva",
  "Lixtenşteyn",
  "Liviya",
  "Lüksemburq",
  "Makedoniya",
  "Malavi",
  "Malayziya",
  "Maldiv adaları",
  "Malta",
  "Maroq",
  "Marşal adaları",
  "Mauritaniya",
  "Mauritius",
  "Meksika",
  "Moldova",
  "Monako",
  "Monqolustan",
  "Monteneqro",
  "Morokko",
  "Mozambik",
  "Myanma",
  "Mərakeş",
  "Nambiya",
  "Nauru",
  "Nepal",
  "Niderland",
  "Niger",
  "Nigeriya",
  "Nikaraqua",
  "Norveç",
  "Pakistan",
  "Palau",
  "Panama",
  "Paraqvay",
  "Portuqaliya",
  "Polşa",
  "Rumıniya",
  "Rusiya",
  "Ruanda",
  "San-Marino",
  "Svazilend",
  "Salvador",
  "Samoa",
  "San-Tome və Prinsip",
  "Seneqal",
  "Seyşel adaları",
  "Sinhapur",
  "Slovakiya",
  "Sloveniya",
  "Solomon adaları",
  "Somali",
  "Sudan",
  "Surinam",
  "Suriya",
  "Svədistan",
  "Səudiyyə Ərəbistanı",
  "Sərbiya",
  "Tacikistan",
  "Tanzaniya",
  "Tailand",
  "Tayvan",
  "Toga",
  "Tonqa",
  "Trinidad və Tobaqo",
  "Tunis",
  "Turkmənistan",
  "Tuvalu",
  "Türkiyə",
  "Ukrayna",
  "Urugvay",
  "Vanuatu",
  "Vatikan",
  "Venesuela",
  "Vyetnam",
  "Yamayka",
  "Yaponiya",
  "Yeni Zelandiya",
  "Yunanıstan",
  "Yəmən",
  "Zambiya",
  "Zimbabve",
];

const MCViewRelationshipInfo = ({ employeeById }) => {
  const [formCorrection, setFormCorrection] = useState(false);
  const [requestButton, setRequestButton] = useState(false);

  const onClickCB = () => {
    setFormCorrection(!formCorrection);
  };

  // Initial values
  const initialValues = {
    relationshipInfo: employeeById.relationshipInfo || [],
  };

  // Update the initialValues object with parsed dates
  const modifiedValues = {
    ...initialValues,
    relationshipInfo: initialValues.relationshipInfo.map((item, index) => ({
      ...item,
      id: index,
      idCardNumber:
        item.isForeigner === true
          ? { number: item.idCardNumber }
          : {
              code: item?.idCardNumber?.startsWith("AA")
                ? item.idCardNumber.slice(0, 2)
                : item?.idCardNumber?.startsWith("AZE")
                ? item.idCardNumber.slice(0, 3)
                : "",
              number: item?.idCardNumber?.startsWith("AA")
                ? item.idCardNumber.slice(2)
                : item?.idCardNumber?.startsWith("AZE")
                ? item.idCardNumber.slice(3)
                : "",
            },
    })),
  };

  const [dataIndex, setDataIndex] = useState(
    modifiedValues.relationshipInfo.at(-1).id
  );
  function updateData(newObject, oldObject) {
    let updateData = {
      index: newObject.id,
      operation: "update",
      data: {},
    };

    const keys = Object.keys(newObject);
    for (const key of keys) {
      if (newObject[key] !== oldObject[key] && !newObject[key].code) {
        updateData.data[key] = {
          old: newObject[key],
          new: oldObject[key],
        };
      } else if (
        newObject[key].code !== oldObject[key].code ||
        newObject[key].number !== oldObject[key].number
      ) {
        updateData.data[key] = {
          old: oldObject[key].code + oldObject[key].number,

          new: newObject[key].code + newObject[key].number,
        };
      }
    }
    const a = Object.keys(updateData.data);
    if (a.length) {
      return updateData;
    }
  }

  function makeChangesData(formValue, oldValue) {
    let changesData = {
      section: "relationship-info",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];
    formValue.relationshipInfo.forEach((obj1) => {
      const obj2 = oldValue.relationshipInfo.find(
        (item) => item.id === obj1.id
      );

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          index: obj1.id,
          data: {
            ...obj1,
            idCardNumber: obj1.idCardNumber.code + obj1.idCardNumber.number,
          },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.relationshipInfo.forEach((obj2) => {
      const obj1 = formValue.relationshipInfo.find(
        (item) => item.id === obj2.id
      );

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }

    sendPersonalInfoChanges(changesData);
  }

  function handleSubmit(data) {
    makeChangesData(data, modifiedValues);
  }

  // form controller for submitting

  function controlFormForSubmitting(data) {
    controllData(data, modifiedValues);
  }

  function controllData(formValue, oldValue) {
    let changesData = {
      section: "relationship-info",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];
    formValue.relationshipInfo.forEach((obj1) => {
      const obj2 = oldValue.relationshipInfo.find(
        (item) => item.id === obj1.id
      );

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          index: obj1.id,
          data: {
            ...obj1,
            idCardNumber: obj1?.idCardNumber?.code + obj1?.idCardNumber?.number,
          },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.relationshipInfo.forEach((obj2) => {
      const obj1 = formValue.relationshipInfo.find(
        (item) => item.id === obj2.id
      );

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }
    if (!changesData.changes.length) {
      setRequestButton(false);
    }
  }
  return (
    <>
      {employeeById ? (
        <Grid>
          <Button
            style={
              formCorrection
                ? { backgroundColor: "rgba(121,46,46, 0.9)", marginTop: "10px" }
                : { marginTop: "10px" }
            }
            onClick={onClickCB}
            variant="contained"
            color="secondary"
          >
            {formCorrection ? "Ləğv et" : "Dəyişiklik et"}

            <CreateIcon sx={{ marginLeft: 5 }} />
          </Button>

          <Formik
            onSubmit={handleSubmit}
            validationSchema={relationshipInfoValidation}
            initialValues={{
              relationshipInfo: modifiedValues.relationshipInfo || [], // Set the initial laborActivity values from employeeById
            }}
          >
            {({ values, errors, setFieldValue, handleChange }) => (
              <Form
                style={
                  !formCorrection
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                <FieldArray name="relationshipInfo">
                  {({ push, remove }) => (
                    <div>
                      {values.relationshipInfo.map((form, index) => (
                        <div key={index}>
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                              }}
                            >
                              <Typography mt={3} mb={3} variant="h6">
                                {index + 1 + ")"}Qohumluq məlumatı{" "}
                              </Typography>
                            </div>

                            {/* Relation type and full name */}
                            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                              <Grid sx={{ width: "100%" }}>
                                <FormControl
                                  error={
                                    errors?.relationshipInfo?.[index]
                                      ?.relationType &&
                                    Boolean(
                                      errors?.relationshipInfo?.[index]
                                        ?.relationType
                                    )
                                  }
                                  fullWidth
                                >
                                  <InputLabel
                                    id={`relationshipInfo[${index}].relationType`}
                                  >
                                    Qohumluq əlaqəsi növü *
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name={`relationshipInfo[${index}].relationType`}
                                    labelId={`relationshipInfo[${index}].relationType`}
                                    label="Qohumluq əlaqəsi növü *"
                                    id={`relationshipInfo[${index}].relationType-select`}
                                    defaultValue=""
                                    fullWidth
                                    onChange={(e) => {
                                      setRequestButton(true);
                                      setFieldValue(
                                        `relationshipInfo[${index}].relationType`,
                                        e.target.value
                                      );
                                    }}
                                    error={
                                      errors?.relationshipInfo?.[index]
                                        ?.relationType &&
                                      Boolean(
                                        errors?.relationshipInfo?.[index]
                                          ?.relationType
                                      )
                                    }
                                  >
                                    <MenuItem value="father">Atam</MenuItem>
                                    <MenuItem value="mother">Anam </MenuItem>
                                    <MenuItem value="brother">
                                      Qardaşım{" "}
                                    </MenuItem>
                                    <MenuItem value="sister">Bacım </MenuItem>

                                    <MenuItem value="husband">
                                      Yoldaşım{" "}
                                    </MenuItem>
                                    <MenuItem value="father-in-law">
                                      Qaynatam{" "}
                                    </MenuItem>
                                    <MenuItem value="mother-in-law">
                                      Qaynanam{" "}
                                    </MenuItem>
                                    <MenuItem value="son">Oğlum </MenuItem>
                                    <MenuItem value="daughter">Qızım </MenuItem>
                                    <MenuItem value="grandchild">
                                      Nəvəm{" "}
                                    </MenuItem>
                                  </Field>
                                  {errors.relationshipInfo?.[index]
                                    ?.relationType &&
                                    errors.relationshipInfo?.[index]
                                      ?.relationType && (
                                      <FormHelperText error>
                                        {
                                          errors.relationshipInfo?.[index]
                                            ?.relationType
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              <Grid sx={{ width: "100%" }}>
                                <Field
                                  type="text"
                                  name={`relationshipInfo[${index}].fullname`}
                                  as={TextField}
                                  label="Soyadı Adı Atasının adı *"
                                  fullWidth
                                  onChange={(e) => {
                                    setRequestButton(true);
                                    setFieldValue(
                                      `relationshipInfo[${index}].fullname`,
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    errors?.relationshipInfo?.[index]
                                      ?.fullname &&
                                    Boolean(
                                      errors?.relationshipInfo?.[index]
                                        ?.fullname
                                    )
                                  }
                                  helperText={
                                    errors?.relationshipInfo?.[index]
                                      ?.fullname &&
                                    errors?.relationshipInfo?.[index]?.fullname
                                  }
                                />
                              </Grid>
                            </Grid>

                            {/* fin and national id card number */}
                            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                              {(values.relationshipInfo[index]?.isMyiExist &&
                                values.relationshipInfo[index].isForeigner) ||
                              !values.relationshipInfo[index].isForeigner ? (
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    type="text"
                                    name={`relationshipInfo[${index}].pin`}
                                    as={TextField}
                                    fullWidth
                                    label="FIN *"
                                    onChange={(event) => {
                                      setRequestButton(true);
                                      const uppercaseValue =
                                        event.target.value.toUpperCase();
                                      setFieldValue(
                                        `relationshipInfo[${index}].pin`,
                                        uppercaseValue
                                      );
                                    }}
                                    error={
                                      errors?.relationshipInfo?.[index]?.pin &&
                                      Boolean(
                                        errors?.relationshipInfo?.[index]?.pin
                                      )
                                    }
                                    helperText={
                                      errors?.relationshipInfo?.[index]?.pin &&
                                      errors?.relationshipInfo?.[index]?.pin
                                    }
                                  />
                                </Grid>
                              ) : null}

                              <Grid
                                sx={{ width: "100%", display: "flex", gap: 3 }}
                              >
                                {values.relationshipInfo[index]
                                  .isForeigner ? null : (
                                  <Grid sx={{ width: 1 / 5 }}>
                                    <Field
                                      as={Select}
                                      name={`relationshipInfo[${index}].idCardNumber.code`}
                                      labelId={`relationshipInfo[${index}].idCardNumber.code`}
                                      label=""
                                      id={`relationshipInfo[${index}].idCardNumber.code`}
                                      defaultValue=""
                                      fullWidth
                                      onChange={(e) => {
                                        setRequestButton(true);
                                        setFieldValue(
                                          `relationshipInfo[${index}].idCardNumber.code`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        errors?.relationshipInfo?.[index]
                                          ?.idCardNumber?.code &&
                                        Boolean(
                                          errors?.relationshipInfo?.[index]
                                            ?.idCardNumber?.code
                                        )
                                      }
                                      helperText={
                                        errors?.relationshipInfo?.[index]
                                          ?.idCardNumber?.code &&
                                        errors?.relationshipInfo?.[index]
                                          ?.idCardNumber?.code
                                      }
                                    >
                                      <MenuItem value="AA">AA</MenuItem>
                                      <MenuItem value="AZE">AZE</MenuItem>
                                    </Field>
                                  </Grid>
                                )}

                                <Grid
                                  sx={{
                                    width:
                                      values.relationshipInfo[index]
                                        .isForeigner &&
                                      !values.relationshipInfo[index]
                                        ?.isMyiExist
                                        ? "50%"
                                        : values.relationshipInfo[index]
                                            .isForeigner
                                        ? "100%"
                                        : 4 / 5,
                                  }}
                                >
                                  <Field
                                    type="text"
                                    name={`relationshipInfo[${index}].idCardNumber.number`}
                                    as={TextField}
                                    fullWidth
                                    onChange={(e) => {
                                      setRequestButton(true);
                                      setFieldValue(
                                        `relationshipInfo[${index}].idCardNumber.number`,
                                        e.target.value
                                      );
                                    }}
                                    label={
                                      values.relationshipInfo[index].isForeigner
                                        ? "Passportun nömrəsi *"
                                        : "Vəsiqəsinin nömrəsi *"
                                    }
                                    error={
                                      errors?.relationshipInfo?.[index]
                                        ?.idCardNumber?.number &&
                                      Boolean(
                                        errors?.relationshipInfo?.[index]
                                          ?.idCardNumber?.number
                                      )
                                    }
                                    helperText={
                                      errors?.relationshipInfo?.[index]
                                        ?.idCardNumber?.number &&
                                      errors?.relationshipInfo?.[index]
                                        ?.idCardNumber?.number
                                    }
                                  />
                                </Grid>

                                {values.relationshipInfo[index]?.isMyiExist &&
                                  values.relationshipInfo[index]
                                    .isForeigner && (
                                    <Field
                                      type="text"
                                      name={`relationshipInfo[${index}].tRPNumber`}
                                      as={TextField}
                                      fullWidth
                                      label="MYİ seriya Nömrəsi"
                                      onChange={(e) => {
                                        setRequestButton(true);
                                        setFieldValue(
                                          `relationshipInfo[${index}].tRPNumber`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        errors?.relationshipInfo?.[index]
                                          ?.tRPNumber &&
                                        Boolean(
                                          errors?.relationshipInfo?.[index]
                                            ?.tRPNumber
                                        )
                                      }
                                      helperText={
                                        errors?.relationshipInfo?.[index]
                                          ?.tRPNumber &&
                                        errors?.relationshipInfo?.[index]
                                          ?.tRPNumber
                                      }
                                      required
                                    />
                                  )}
                                {values.relationshipInfo[index].isForeigner && (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values.relationshipInfo[index]
                                            ?.isMyiExist || false
                                        }
                                        onChange={handleChange}
                                        name={`relationshipInfo[${index}].isMyiExist`}
                                        color="primary"
                                      />
                                    }
                                    label="Müvəqqəti yaşayış icazəm var"
                                  />
                                )}
                              </Grid>
                            </Grid>

                            {/* DOB and education info */}
                            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                              <Field name={`relationshipInfo[${index}].dob`}>
                                {({ field, form }) => (
                                  <DatePicker
                                    {...field}
                                    label="Doğum tarixi *"
                                    sx={{ width: "100%" }}
                                    format="dd/MM/yyyy"
                                    value={
                                      field.value
                                        ? new Date(field?.value)
                                        : null
                                    }
                                    slotProps={{
                                      textField: {
                                        error: Boolean(
                                          form.errors?.relationshipInfo?.[index]
                                            ?.dob
                                        ),
                                        helperText:
                                          form.errors?.relationshipInfo?.[index]
                                            ?.dob || "",
                                        fullWidth: true,
                                      },
                                    }}
                                    onChange={(date) => {
                                      setRequestButton(true);
                                      if (
                                        date instanceof Date &&
                                        !isNaN(date)
                                      ) {
                                        form.setFieldValue(
                                          `relationshipInfo[${index}].dob`,
                                          date
                                        );
                                      } else {
                                        form.setFieldValue(
                                          `relationshipInfo[${index}].dob`,
                                          ""
                                        );
                                      }
                                    }}
                                    maxDate={new Date()}
                                    minDate={new Date(1940, 0, 1)}
                                  />
                                )}
                              </Field>
                              <Grid sx={{ width: "100%" }}>
                                <FormControl
                                  error={
                                    errors?.relationshipInfo?.[index]
                                      ?.education &&
                                    Boolean(
                                      errors?.relationshipInfo?.[index]
                                        ?.education
                                    )
                                  }
                                  fullWidth
                                >
                                  <InputLabel
                                    id={`relationshipInfo[${index}].education`}
                                  >
                                    Təhsili *
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name={`relationshipInfo[${index}].education`}
                                    labelId={`relationshipInfo[${index}].education`}
                                    label="Qohumluq əlaqəsi növü"
                                    id={`relationshipInfo[${index}].education-select`}
                                    defaultValue=""
                                    fullWidth
                                    onChange={(e) => {
                                      setRequestButton(true);
                                      setFieldValue(
                                        `relationshipInfo[${index}].education`,
                                        e.target.value
                                      );
                                    }}
                                    error={
                                      errors?.relationshipInfo?.[index]
                                        ?.education &&
                                      Boolean(
                                        errors?.relationshipInfo?.[index]
                                          ?.education
                                      )
                                    }
                                  >
                                    {educationType.map((item, index) => (
                                      <MenuItem key={index} value={item.value}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                  {errors.relationshipInfo?.[index]
                                    ?.education &&
                                    errors.relationshipInfo?.[index]
                                      ?.education && (
                                      <FormHelperText error>
                                        {
                                          errors.relationshipInfo?.[index]
                                            ?.education
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                            </Grid>

                            {/* Service period and same workplace */}
                            <Grid
                              sx={{
                                display: "flex",
                                gap: 3,
                                alignItems: "center",
                              }}
                            >
                              <Grid sx={{ width: "100%" }}>
                                <Field
                                  type="text"
                                  name={`relationshipInfo[${index}].placeOfWork`}
                                  as={TextField}
                                  fullWidth
                                  onChange={(e) => {
                                    setRequestButton(true);
                                    setFieldValue(
                                      `relationshipInfo[${index}].placeOfWork`,
                                      e.target.value
                                    );
                                  }}
                                  label="İş yeri (vəzifəsi) *"
                                  error={
                                    errors?.relationshipInfo?.[index]
                                      ?.placeOfWork &&
                                    Boolean(
                                      errors?.relationshipInfo?.[index]
                                        ?.placeOfWork
                                    )
                                  }
                                  helperText={
                                    errors?.relationshipInfo?.[index]
                                      ?.placeOfWork &&
                                    errors?.relationshipInfo?.[index]
                                      ?.placeOfWork
                                  }
                                />
                              </Grid>
                              {values.relationshipInfo[index].isForeigner ? (
                                <FormControl
                                  error={
                                    errors.citizenship && errors.citizenship
                                  }
                                  fullWidth
                                >
                                  <InputLabel id="citizenship">
                                    Vətəndaşlığı *
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name={`relationshipInfo[${index}].citizenship`}
                                    labelId="citizenship"
                                    label="citizenship"
                                    id="citizenship-select"
                                    fullWidth
                                    error={
                                      errors?.relationshipInfo?.[index]
                                        ?.citizenship &&
                                      Boolean(
                                        errors?.relationshipInfo?.[index]
                                          ?.citizenship
                                      )
                                    }
                                    required
                                  >
                                    {countries.map((item, index) => (
                                      <MenuItem key={index} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                  {errors?.relationshipInfo?.[index]
                                    ?.citizenship &&
                                    errors?.relationshipInfo?.[index]
                                      ?.citizenship && (
                                      <FormHelperText error>
                                        {
                                          errors?.relationshipInfo?.[index]
                                            ?.citizenship
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              ) : null}
                            </Grid>
                            <Grid sx={{ marginTop: "20px", width: "50%" }}>
                              <FieldArray
                                name={`relationshipInfo[${index}].mobile`}
                              >
                                {({ push, remove }) => (
                                  <>
                                    {values?.relationshipInfo?.[index]
                                      ?.mobile &&
                                      values?.relationshipInfo?.[
                                        index
                                      ]?.mobile.map((mobile, ind) => (
                                        <Grid
                                          mb={3}
                                          key={ind}
                                          container
                                          spacing={2}
                                          alignItems="center"
                                        >
                                          <Grid item xs={9}>
                                            <Field
                                              type="tel"
                                              name={`relationshipInfo[${index}].mobile[${ind}]`}
                                              as={TextField}
                                              fullWidth
                                              label={`Mobil ${ind + 1}`}
                                            />
                                            <ErrorMessage
                                              name={`relationshipInfo[${index}].mobil[${ind}]`}
                                              component="div"
                                            />
                                          </Grid>
                                          <Grid item xs={3}>
                                            {ind ===
                                              values.relationshipInfo[index]
                                                .mobile.length -
                                                1 && (
                                              <Button
                                                type="button"
                                                onClick={() => push("")}
                                              >
                                                + Mobil əlavə et
                                              </Button>
                                            )}
                                            {ind > 0 && (
                                              <Button
                                                type="button"
                                                onClick={() => remove(ind)}
                                              >
                                                - Mobil sil
                                              </Button>
                                            )}
                                          </Grid>
                                        </Grid>
                                      ))}
                                  </>
                                )}
                              </FieldArray>
                            </Grid>
                          </>
                          <Button
                            sx={
                              formCorrection
                                ? { visibility: "visible" }
                                : { visibility: "hidden" }
                            }
                            variant="contained"
                            color="error"
                            type="button"
                            style={{
                              marginBottom: "10px",
                              marginTop: "10px",
                            }}
                            onClick={() => {
                              setRequestButton(true);
                              remove(index);
                              const updatedArray =
                                values.relationshipInfo.filter(
                                  (item) => item.id !== index
                                );
                              controlFormForSubmitting({
                                relationshipInfo: updatedArray,
                              });
                            }}
                          >
                            Ləğv et
                          </Button>
                        </div>
                      ))}
                      <Button
                        sx={
                          formCorrection
                            ? { visibility: "visible" }
                            : { visibility: "hidden" }
                        }
                        variant="contained"
                        color="secondary"
                        style={{ marginBottom: "10px", marginTop: "20px" }}
                        onClick={() => {
                          let newId = dataIndex + 1;
                          setDataIndex(newId);
                          push({
                            id: newId,
                          });
                        }}
                      >
                        Əlavə et
                      </Button>
                    </div>
                  )}
                </FieldArray>
                <Grid
                  style={
                    formCorrection
                      ? { visibility: "visible", marginTop: "10px" }
                      : { visibility: "hidden" }
                  }
                >
                  <Button
                    type="submit"
                    style={
                      !requestButton
                        ? { pointerEvents: "none" }
                        : {
                            backgroundColor: "#28358a",
                            pointerEvents: "auto",
                          }
                    }
                    variant="contained"
                    color="secondary"
                  >
                    Dəyişikliklər sorğusu göndər
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default MCViewRelationshipInfo;
