import { api } from "../axiosInterceptor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeEmptyStrings } from "../../utils/removeEmptyString";

export const getEmployeeRequestsHr = async (limit, offset, filters) => {
  let params = {
    limit: limit,
    offset: offset,
    filter: filters,
  };

  params = removeEmptyStrings(params);
  try {
    const response = await api.get("employee/employee-request/manager/hr", {
      params: params,
    });

    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        toastId: "employee-request/manager/hr",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Vacation Requests for managers
export const getApproverVacationRequest = async (limit, offset) => {
  let params = {
    limit: limit,
    offset: offset,
  };
  try {
    const response = await api.get("employee/employee-request/manager", {
      params: params,
    });

    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getApproverVacationWithFilter = async (offset, limit, status) => {
  let params = {
    offset: offset,
    limit: limit,
    status: status,
  };
  try {
    const response = await api.get("employee/employee-request/manager/filter", {
      params: params,
    });

    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const updateEmployeeRequest = async (id, status, description) => {
  let data = {
    status: status,
    description: description,
  };

  try {
    const response = await api.post(
      `employee/employee-request/manager/${id}`,
      data
    );
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Download report
export const downloadVacationRequestManager = async (params) => {
  try {
    const response = await api.get(
      `employee/employee-request/manager/download`,
      {
        params,
      }
    );
    // toast.success("Downloaded Successfully", {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "dark",
    // });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Resend employee request
export const resendEmployeeRequest = async (id, subject, description) => {
  try {
    const response = await api.patch(
      `employee/employee-request/manager/${id}/resend`,
      {
        subject: subject,
        description: description,
      }
    );
    toast.success("Successfully resend", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const cancelEmployeeRequest = async (id) => {
  try {
    const response = await api.patch(
      `employee/employee-request/manager/${id}/cancel`
    );
    toast.success("Successfully cancelled", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const vacationTrackingRequest = async (filter, limit, offset) => {
  try {
    const filterParam =
      filter.length > 0
        ? `filter=${encodeURIComponent(JSON.stringify(filter))}&`
        : "";
    const response = await api.get(
      `employee/vacation-tracking?${filterParam}limit=${limit}&offset=${offset}`
    );
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const addRemainderVacationTracking = async (data) => {
  try {
    const response = await api.post(`employee/vacation-tracking`, data);
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(
        item.message ===
          "The provided work year overlaps with an existing report"
          ? "Təqdim olunan iş ili mövcud hesabatla üst-üstə düşür"
          : item.message,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    });
  }
};

export const getDownloadvacationTrackingRequest = async (
  filter,
  limit,
  offset
) => {
  try {
    const filterParam =
      filter.length > 0
        ? `filter=${encodeURIComponent(JSON.stringify(filter))}&`
        : "";

    const response = await api.get(
      `employee/vacation-tracking/download?${filterParam}limit=${limit}&offset=${offset}`,
      { responseType: "blob" }
    );
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};
