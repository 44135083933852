import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid2,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Table from "../../components/table/Table";
// icons
import VisibilityIcon from "@mui/icons-material/Visibility";

import Header from "../../components/layouts/header/Header";
import { useEffect, useReducer, useState } from "react";
import useOrders from "../../hooks/useOrders";
import { getAllStructures, getChainlessPartForSalary } from "../../api/api";
import {
  decisionChainReducer,
  initialState,
} from "../../redux/DecisionChainReducer";
import NestedSelectForSalaryBase from "./nestedSelectForSalaryBase";
import { DatePicker } from "@mui/x-date-pickers";
import Column from "../../models/table/Column";
import { OPERATORS_CONTAINING } from "../../constants";
import { normalizeDate } from "../../utils/helperFunctions";
import Operator from "../../models/table/Operator";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import CreateNewBase from "./createNewBaseData";
import { getSalaryBaseHistory } from "./services";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const header = {
  title: "Əməkhaqqı bazası",
  variant: "h1",
};

export default function SalaryBase() {
  const [state, dispatch] = useReducer(decisionChainReducer, initialState);
  const { structureId, organizationId, nestedChildren } = state;
  const [watchDataDialogue, setWatchDataDialogue] = useState(false);
  const [allStructures, setAllStructures] = useState([]);
  // table states
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState("");
  const [showData, setShowData] = useState(false);
  const [initialFilter, setInitialFilter] = useState({
    position: "",
    month: "",
    year: "",
  });
  const [errorStartDate, setErrorStartDate] = useState("");
  const [errorEndDate, setErrorEndDate] = useState("");
  const [showAccordion, setShowAccordion] = useState(false);

  useEffect(() => {
    getStructures();
  }, []);

  const getStructures = async () => {
    try {
      const resp = await getAllStructures();
      setAllStructures(resp.data.structures);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setShowData(
      initialFilter?.position === "" ||
        initialFilter?.month === "" ||
        initialFilter?.year === ""
    );
  }, [initialFilter]);

  useEffect(() => {
    if (initialFilter.position && initialFilter.month && initialFilter.year) {
      paginationFetchingHandler();
    }
  }, [limit, offset, filters]);

  const handlePositionIDForInitialFilters = async (id) => {
    setInitialFilter({
      ...initialFilter,
      position: id,
    });
  };

  const handleOrganizationName = async (e) => {
    dispatch({
      type: "SET_STRUCTURE_ID",
      payload: e.target.value._id,
    });
    dispatch({
      type: "SET_ORGANIZATION_ID",
      payload: e.target.value.organization._id,
    });

    const response = await getChainlessPartForSalary({
      structureId: e.target.value._id,
    });

    dispatch({
      type: "GET_NESTED_CHILDREN",
      payload: response.data.records,
    });
  };

  const operatorGeneratorHandler = (row, column) => {
    let visibility = false;
    let createOrder = false;
    if (row.status === "active" || row.status === "rejected") {
      visibility = true;
    }
    if (row.status === "pending_order_approve") {
      createOrder = true;
    }
    const operators = [
      new Operator({
        icon: <VisibilityIcon />,
        onClick: () => {
          // setWatchDataDialogue(true);
          // getDataForEdit(row._id);
        },
        type: 0,
      }),
    ];

    return <OperatorGenerator operators={operators} />;
  };
  const column = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      // isStaticColumn: true,
      widthOfColumn: 200,
      renderCell: operatorGeneratorHandler,
    }),
    new Column({
      path: "employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.pin",
      headerName: "Fin",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "structure.organization.name",
      headerName: "Təşkilat",
      filterType: OPERATORS_CONTAINING.OPTION,
    }),
    // new Column({
    //   path: "topPartOfStructure.source.name",
    //   headerName: "Struktur",
    //   filterType: OPERATORS_CONTAINING.OPTION,
    // }),
    // new Column({
    //   path: "subStructure.source.name",
    //   headerName: "Sub-struktur",
    //   filterType: OPERATORS_CONTAINING.OPTION,
    // }),
    // new Column({
    //   path: "position",
    //   headerName: "Vəzifə",
    //   filterType: OPERATORS_CONTAINING.OPTION,
    // }),

    new Column({
      path: "startDate",
      headerName: `Cəmi  ödənilmiş( Son 12 tam ay)`,
      renderCell: (data) => {
        return (
          <div style={{ textAlign: "center" }}>
            {normalizeDate(data.startDate)}
          </div>
        );
      },

      filterType: OPERATORS_CONTAINING.NUMBER,
    }),
    new Column({
      path: "startDate",
      headerName: `Orta aylıq əmək haqqı`,
      renderCell: (data) => {
        return (
          <div style={{ textAlign: "center" }}>
            {normalizeDate(data.startDate)}
          </div>
        );
      },

      filterType: OPERATORS_CONTAINING.NUMBER,
    }),
    new Column({
      path: "startDate",
      headerName: `Cari ay üçün orta günlük əmək haqqı(standart)`,
      renderCell: (data) => {
        return (
          <div style={{ textAlign: "center" }}>
            {normalizeDate(data.startDate)}
          </div>
        );
      },

      filterType: OPERATORS_CONTAINING.NUMBER,
    }),
    new Column({
      path: "startDate",
      headerName: `Cari ay üçün orta günlük əmək haqqı(Əmək məzuniyyəti)`,
      renderCell: (data) => {
        return (
          <div style={{ textAlign: "center" }}>
            {normalizeDate(data.startDate)}
          </div>
        );
      },

      filterType: OPERATORS_CONTAINING.NUMBER,
    }),
  ];

  // get Base data

  const paginationFetchingHandler = async () => {
    if (filters.length) {
      await getBaseData([...filters]);
    } else {
      await getBaseData();
    }
  };
  const getBaseData = async (filters = undefined) => {
    try {
      setIsLoading(true);

      if (filters) {
        const resp = await getSalaryBaseHistory({
          offset: offset || 0,
          limit: limit || 10,
          month: initialFilter.month ? initialFilter.month : "",
          year: initialFilter.year ? initialFilter.year : "",
          positionId: initialFilter.position ? initialFilter.position : "",
          filters: encodeURIComponent(JSON.stringify([...filters])),
        });
        setDatas([...resp.data.staffWages]);
        setTotal(resp.data.total);
      } else {
        const resp = await getSalaryBaseHistory({
          offset: offset || 0,
          limit: limit || 10,
          month: initialFilter.month ? initialFilter.month : "",
          year: initialFilter.year ? initialFilter.year : "",
          positionId: initialFilter.position ? initialFilter.position : "",
        });

        setDatas([...resp.data.staffWages]);
        setTotal(resp.data.total);
      }
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setIsLoading(false);
    }
  };

  const showSalaryBase = () => {
    paginationFetchingHandler();
  };

  return (
    <>
      <Grid2 sx={{ width: "100%", textAlign: "center", marginBottom: "50px" }}>
        <Header data={header} />
      </Grid2>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => setShowAccordion(!showAccordion)}
          variant="contained"
          sx={{ height: "100%" }}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "50px",
            }}
          >
            Yeni məlumat əlavə et
          </p>
        </Button>
      </div>
      {showAccordion && <CreateNewBase showAccordion={setShowAccordion} />}

      {/* form part */}

      <DialogContent>
        <Grid2 sx={{ display: "flex", width: "100%", gap: 15 }}>
          <Grid2
            sx={{
              width: "100%",
              py: 2,
              maxHeight: "55vh",
              overflowY: "auto",
            }}
          >
            <FormControl sx={{ mb: 6 }} fullWidth>
              <InputLabel id="organizationName">Təşkilatı seçin</InputLabel>
              <Select
                labelId="organizationName"
                id="organizationName"
                input={<Input />}
                fullWidth
                MenuProps={MenuProps}
                onChange={(e) => {
                  handleOrganizationName(e);
                }}
              >
                {allStructures &&
                  allStructures.map((structure, index) => {
                    if (structure?.organization?.name) {
                      return (
                        <MenuItem key={index} value={structure}>
                          {structure.organization.name}
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
            </FormControl>
            {structureId && organizationId && nestedChildren !== "" ? (
              <div style={{ display: "flex", columnGap: "15px" }}>
                <NestedSelectForSalaryBase
                  state={state}
                  dispatch={dispatch}
                  initialFilter={handlePositionIDForInitialFilters}
                />
              </div>
            ) : null}
          </Grid2>
        </Grid2>
      </DialogContent>

      <div style={{ display: "flex", columnGap: "10px" }}>
        {/* tarix */}
        <div>
          <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
            Tarix
          </InputLabel>

          <div>
            <DatePicker
              label={'"ay" və "il"'}
              views={["year", "month"]}
              openTo="month"
              sx={{ width: "100%" }}
              maxDate={Date()}
              value={date ? new Date(date) : null}
              slotProps={{
                textField: {
                  sx: {
                    "& .MuiInputBase-input": {
                      height: "8px",
                    },
                  },
                },
              }}
              onChange={(date) => {
                if (date) {
                  const month = date.getMonth() + 1;
                  const year = date.getFullYear();
                  setInitialFilter({
                    ...initialFilter,
                    month,
                    year,
                  });
                }
              }}
            />
          </div>
        </div>
        {/* button */}
        <div
          style={{
            marginLeft: "20px",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <div style={{ height: "50%" }}>
            <Button
              disabled={showData}
              variant="contained"
              color="primary"
              onClick={showSalaryBase}
            >
              Göstər
            </Button>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "100px" }}>
        <Table
          columns={column}
          data={datas}
          totalRows={total}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={isLoading}
          applyFiltersHandler={(filters) => setFilters(filters)}
          cancelAppliedFilters={() => setFilters([])}
          //   downloadHandler={(cols) => downlaodHandler(cols)}
        />
      </div>
      {/* watch dialog */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "700px",
            maxWidth: "unset",
            padding: "15px 10px",
          },
        }}
        open={watchDataDialogue}
        onClose={() => setWatchDataDialogue(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ textAlign: "center", fontSize: "24px" }}
          id="alert-dialog-title"
        >
          Tutulma məlumatları
        </DialogTitle>
        <form>
          <div className="d-flex flex-column gap-3">
            {/* baslama tarixi bitme tarixi */}
            <div className="d-flex w-100 gap-3">
              <div className="w-25">
                <div>Başlama tarixi</div>
                <div className="mt-2">
                  <div className="mt-2">
                    <TextField
                      type="text"
                      fullWidth
                      disabled={true}
                      // value={normalizeDate(dataForView?.startDate) || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="w-25">
                <div>Bitmə tarixi</div>
                <div className="mt-2">
                  <div className="mt-2">
                    <TextField
                      type="text"
                      fullWidth
                      disabled={true}
                      // value={normalizeDate(dataForView?.endDate) || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
}
