import React, { useEffect, useState } from "react";
import { getEmployees } from "../../../services/employees/EmployeeServices";
import { addRemainderVacationTracking } from "../../../api/employeeRequests/employeeRequests";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeEmptyStrings } from "../../../utils/removeEmptyString";
import moment from "moment";
import { getFitlersData } from "../../../services/filter/filterService";
import dayjs from "dayjs";

const VacationTrackingForm = ({ getData, submited, setSubmited }) => {
  const [empData, setEmpData] = useState([]);
  const [show_Accordion, set_show_Accordion] = useState(false);
  const [pastEndWorkYear, setPastEndWorkYear] = useState([]);
  const [leave_state, set_leave_state] = useState({
    staffId: "",
    employeeId: "",
    startWorkYear: "",
    endWorkYear: "",
    reserved: { main: null, additional: null },
    used: { main: null, additional: null },
    unUsed: { main: null, additional: null },
    isChecked: true,
    interrupted: "",
    oldPosition: "",
    oldStruktur: "",
    oldDepartment: "",
    oldSubStruktur: "",
  });

  const [reservedError, setReservedError] = useState("");
  const [usedError, setUsedError] = useState("");
  const [startWorkYearError, setStartWorkYearError] = useState("");
  const [endWorkYearError, setEndWorkYearError] = useState("");
  const [startEmpWorkYear, setStartEmpWorkYear] = useState("");
  const [unusedError, setUnusedError] = useState("");
  const [dateList, setDateList] = useState([]);
  const [reservedAddError, setReservedAddError] = useState("");
  const [usedAddError, setUsedAddError] = useState("");
  const [unusedAddError, setUnusedAddError] = useState("");
  const [interruptedError, setInterruptedError] = useState("");
  const [disabledRanges, setDisabledRanges] = useState([]);
  const [disabledReasonMessage, setDisabledReasonMessage] = useState([]);
  const lastYear = new Date();
  lastYear.setFullYear(lastYear.getFullYear() - 1);
  const filterModel = [
    {
      column: "status",
      operator: "equalsTo",
      value: "active",
    },
  ];
  const getEmployeeData = async () => {
    const filter = encodeURIComponent(JSON.stringify(filterModel));
    try {
      const response = await getEmployees({ filter: filter });

      setEmpData(response?.employees);
    } catch (err) {
      console.log(err);
    }
  };
  const isLeaveStateEmpty = () => {
    return (
      (leave_state?.isChecked && !leave_state.staffId) ||
      !leave_state.employeeId ||
      !leave_state.startWorkYear ||
      !leave_state.endWorkYear ||
      !leave_state.interrupted ||
      (!leave_state?.isChecked && !leave_state.oldStruktur) ||
      (!leave_state?.isChecked && !leave_state.oldDepartment) ||
      (!leave_state?.isChecked && !leave_state.oldSubStruktur) ||
      (!leave_state?.isChecked && !leave_state.oldPosition) ||
      Object.values(leave_state.reserved).some(
        (value) => value === null || value === ""
      ) ||
      Object.values(leave_state.used).some(
        (value) => value === null || value === ""
      ) ||
      Object.values(leave_state.unUsed).some(
        (value) => value === null || value === ""
      )
    );
  };
  const errorController = () => {
    return (
      reservedError !== "" ||
      usedError !== "" ||
      unusedError !== "" ||
      reservedAddError !== "" ||
      usedAddError !== "" ||
      startWorkYearError !== "" ||
      endWorkYearError !== "" ||
      interruptedError !== "" ||
      unusedAddError !== ""
    );
  };
  async function handleSubmit(e) {
    e.preventDefault();
    setSubmited(true);
    try {
      const modifiedValues = {
        staffId: leave_state?.isChecked ? leave_state?.staffId : "",
        employeeId: leave_state?.employeeId,
        startWorkYear: moment(leave_state?.startWorkYear).format("MM/DD/YYYY"),
        baseStructureAsStr: !leave_state?.isChecked
          ? leave_state?.oldStruktur
          : "",
        topPartOfStructureAsStr: !leave_state?.isChecked
          ? leave_state?.oldDepartment
          : "",
        subStructureAsStr: !leave_state?.isChecked
          ? leave_state?.oldSubStruktur
          : "",
        positionAsStr: !leave_state?.isChecked ? leave_state?.oldPosition : "",
        endWorkYear: moment(leave_state?.endWorkYear).format("MM/DD/YYYY"),
        interrupted: parseFloat(leave_state?.interrupted),
        reserved: {
          main: leave_state?.reserved?.main,
          additional: leave_state?.reserved?.additional,
        },
        used: {
          main: leave_state?.used?.main,
          additional: leave_state?.used?.additional,
        },
        unUsed: {
          main: leave_state?.unUsed?.main,
          additional: leave_state?.unUsed?.additional,
        },
      };
      const result = removeEmptyStrings(modifiedValues);

      const res = await addRemainderVacationTracking(result);
      if (res.status === 201) {
        getData();
      }

      set_show_Accordion(false);
      set_leave_state({
        staffId: "",
        employeeId: "",
        startWorkYear: "",
        endWorkYear: "",
        reserved: { main: null, additional: null },
        used: { main: null, additional: null },
        unUsed: { main: null, additional: null },
        isChecked: true,
        interrupted: "",
        oldPosition: "",
        oldStruktur: "",
        oldDepartment: "",
        oldSubStruktur: "",
      });
      setPastEndWorkYear([]);
    } catch (err) {
      console.log(err);
    }
    setSubmited(false);
  }
  useEffect(() => {
    getEmployeeData();
  }, []);

  const getDateList = async () => {
    let es = [
      { employeeId: leave_state?.employeeId, staffId: leave_state?.staffId },
    ];
    if (leave_state?.employeeId && leave_state?.staffId) {
      const dateList = await getFitlersData({
        es: es,
        dataGridName: "vacation-tracking",
      });
      setDateList(dateList?.data);
    }
  };

  const processDisabledRangesAndMessages = () => {
    setDisabledRanges([]);
    setDisabledReasonMessage([]);
    for (let index = 0; index < dateList?.length; index++) {
      const element = dateList?.[index];

      if (element?.unactiveDates?.length > 0) {
        for (const item of element?.unactiveDates) {
          for (const key in item) {
            const range = {};
            if (item?.[key]?.startDate) {
              range.start = dayjs(item?.[key]?.startDate).toISOString();
            }
            if (item?.[key]?.endDate) {
              range.end = dayjs(item?.[key]?.endDate).toISOString();
            }

            if (Object.keys(range).length > 0) {
              setDisabledRanges((prevRanges) => [...prevRanges, range]);
            }
            setDisabledReasonMessage((prevMessages) => [
              ...prevMessages,
              item?.[key]?.message,
            ]);
          }
        }
      }
    }
  };

  useEffect(() => {
    processDisabledRangesAndMessages();
  }, [dateList]);

  useEffect(() => {
    getDateList();
  }, [leave_state?.staffId, leave_state?.employeeId]);
  const shouldDisableDate = (date) => {
    const targetDate = new Date(date);
    const targetUTCDate = Date.UTC(
      targetDate.getUTCFullYear(),
      targetDate.getUTCMonth(),
      targetDate.getUTCDate()
    );
    return disabledRanges.some((range) => {
      if (range.start && range.end) {
        const startDate = new Date(range.start);
        const endDate = new Date(range.end);

        startDate.setUTCDate(startDate.getUTCDate() - 1);
        endDate.setUTCDate(endDate.getUTCDate() - 1);

        const startUTCDate = Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate()
        );
        const endUTCDate = Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate()
        );

        return targetUTCDate >= startUTCDate && targetUTCDate <= endUTCDate;
      }
      if (range.start) {
        const startDate = new Date(range.start);
        startDate.setUTCDate(startDate.getUTCDate() - 1);

        const startUTCDate = Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate()
        );

        return targetUTCDate >= startUTCDate;
      }
      if (range.end) {
        const endDate = new Date(range.end);
        endDate.setUTCDate(endDate.getUTCDate() - 1);

        const endUTCDate = Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate()
        );

        return targetUTCDate <= endUTCDate;
      }

      return false;
    });
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => set_show_Accordion(!show_Accordion)}
          variant="contained"
          sx={{ height: "100%" }}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Qalıq məzuniyyət əlavə et <AddIcon />
          </p>
        </Button>
      </div>
      {show_Accordion && (
        <form onSubmit={handleSubmit}>
          <div>
            <div style={{ display: "flex", gap: "10px" }}>
              <div style={{ width: "25%" }}>
                <div>Əməkdaş</div>
                <div className="mt-2" style={{ minHeight: "80px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Əməkdaş
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={leave_state?.employeeId}
                      label="Əməkdaş"
                      placeholder="ok"
                      onChange={(e) => {
                        const selectedEmp = empData?.filter(
                          (emp) => emp?.id === e?.target?.value
                        );

                        set_leave_state({
                          ...leave_state,
                          employeeId: e.target.value,
                          endWorkYear: "",
                          startWorkYear: "",
                        });
                      }}
                    >
                      {empData?.length &&
                        empData?.map((e) => (
                          <MenuItem value={e?.id}>
                            {e?.firstname} {e?.lastname} {e?.fathername}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div style={{ width: !leave_state?.isChecked ? "75%" : "25%" }}>
                <div>Vəzifə</div>

                <div
                  className="d-flex flex-column mt-2"
                  style={{ minHeight: "80px", gap: "5px" }}
                >
                  {!leave_state?.isChecked ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        label="Köhnə Təşkilat"
                        value={leave_state?.oldStruktur}
                        onChange={(e) => {
                          const value = e?.target?.value;
                          set_leave_state({
                            ...leave_state,
                            oldStruktur: value,
                          });
                        }}
                      />
                      <TextField
                        label="Köhnə Departament"
                        value={leave_state?.oldDepartment}
                        onChange={(e) => {
                          const value = e?.target?.value;
                          set_leave_state({
                            ...leave_state,
                            oldDepartment: value,
                          });
                        }}
                      />
                      <TextField
                        label="Köhnə Sub-struktur"
                        value={leave_state?.oldSubStruktur}
                        onChange={(e) => {
                          const value = e?.target?.value;
                          set_leave_state({
                            ...leave_state,
                            oldSubStruktur: value,
                          });
                        }}
                      />
                      <TextField
                        label="Köhnə vəzifə"
                        value={leave_state?.oldPosition}
                        onChange={(e) => {
                          const value = e?.target?.value;
                          set_leave_state({
                            ...leave_state,
                            oldPosition: value,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Vəzifə
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={leave_state?.staffId}
                        label="Vəzifə"
                        placeholder="ok"
                        disabled={!leave_state.employeeId}
                        onChange={(e) => {
                          const selectedStaff = empData
                            ?.find((emp) => emp?.id === leave_state.employeeId)
                            ?.staffs.find(
                              (staff) => staff.id === e.target.value
                            );
                          const selectedContract = empData
                            ?.find((emp) => emp?.id === leave_state.employeeId)
                            ?.staffs?.filter(
                              (staff) => staff.id === e.target.value
                            );
                          setStartEmpWorkYear(
                            selectedContract?.[0]?.contract?.data
                              ?.jobYearStartDate
                          );

                          if (selectedStaff?.workYears.length > 0) {
                            setPastEndWorkYear(selectedStaff.workYears);
                          } else {
                            setPastEndWorkYear([]);
                          }

                          set_leave_state({
                            ...leave_state,
                            staffId: e.target.value,
                          });
                        }}
                      >
                        {empData?.length &&
                          empData
                            .filter((e) => e?.id === leave_state.employeeId)
                            .map((e, index) =>
                              e?.staffs.map((staff, index) => (
                                <MenuItem
                                  key={`${staff.id}-${index}`}
                                  value={staff?.id}
                                >
                                  {staff?.position?.name}
                                </MenuItem>
                              ))
                            )}
                      </Select>
                    </FormControl>
                  )}
                  <FormControlLabel
                    label="Siyahıdan seç"
                    control={
                      <Checkbox
                        sx={{ padding: "0" }}
                        checked={leave_state?.isChecked}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          set_leave_state({
                            ...leave_state,
                            isChecked: checked,
                          });
                        }}
                      />
                    }
                    className="m-0 d-flex justify-content-end"
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div>İş ilinin başlaması</div>
                <div style={{ minHeight: "80px" }}>
                  <DatePicker
                    label="İş ilinin başlaması"
                    sx={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    value={
                      leave_state?.startWorkYear
                        ? new Date(leave_state?.startWorkYear)
                        : null
                    }
                    slotProps={{
                      textField: {
                        error: Boolean(startWorkYearError),
                        helperText: startWorkYearError || "",
                        fullWidth: true,
                      },
                    }}
                    disabled={
                      leave_state?.isChecked
                        ? !leave_state.staffId
                        : !leave_state?.employeeId
                    }
                    defaultCalendarMonth={new Date(startEmpWorkYear)}
                    minDate={startEmpWorkYear}
                    maxDate={
                      new Date(startEmpWorkYear) > lastYear
                        ? startEmpWorkYear
                        : lastYear
                    }
                    shouldDisableDate={shouldDisableDate}
                    onChange={(date) => {
                      const startDate = new Date(date);
                      const isGreaterThanPast = pastEndWorkYear.some(
                        (past) => startDate > new Date(past?.start)
                      );
                      if (
                        date >
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() - 1)
                        )
                      ) {
                        setStartWorkYearError(
                          `İş ilinin başlaması cari ildən  ola bilməz`
                        );
                      } else {
                        setStartWorkYearError("");
                      }
                      if (isGreaterThanPast) {
                        set_leave_state({
                          ...leave_state,
                          startWorkYear: "",
                          endWorkYear: "",
                        });
                      } else {
                        const newEndDate = new Date(
                          startDate.setFullYear(startDate.getFullYear() + 1)
                        );
                        set_leave_state({
                          ...leave_state,
                          startWorkYear: date,
                          endWorkYear: newEndDate,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div>Bitmə tarixi</div>

                <div style={{ minHeight: "80px" }}>
                  <DatePicker
                    label="Bitmə tarixi"
                    shouldDisableDate={shouldDisableDate}
                    sx={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    value={
                      leave_state?.endWorkYear
                        ? new Date(leave_state?.endWorkYear)
                        : null
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    minDate={new Date(leave_state.startWorkYear)}
                    InputProps={{
                      readOnly: true,
                      style: {
                        pointerEvents: "none",
                        cursor: "default",
                      },
                    }}
                    disabled={
                      leave_state?.isChecked
                        ? !leave_state.staffId
                        : !leave_state?.employeeId
                    }
                  />
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div>
                  <div style={{ marginTop: "10px" }}>
                    Yaranmış məzuniyyət hüququ
                  </div>
                  <div
                    className="mt-2"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <TextField
                      type="text"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d*$/.test(inputValue)) {
                          set_leave_state({
                            ...leave_state,
                            reserved: {
                              main: e.target.value,
                              additional: leave_state?.reserved?.additional,
                            },
                          });
                          const used = parseFloat(leave_state.used.main);
                          const unused = parseFloat(leave_state.unUsed.main);
                          const total = parseFloat(e.target.value);
                          if (
                            used &&
                            unused &&
                            total &&
                            used + unused !== total
                          ) {
                            setReservedError(
                              "İstifadə olunmuş və qalıq məzuniyyət günlərinin ümumi sayı yaranmış məzuniyyət hüququ sayından az və ya çox ola bilməz"
                            );
                          } else {
                            setReservedError("");
                          }
                        } else {
                          set_leave_state({
                            ...leave_state,
                            reserved: {
                              main: "",
                              additional: leave_state?.reserved?.additional,
                            },
                          });
                        }
                      }}
                      label="Əsas"
                      value={leave_state?.reserved?.main}
                      disabled={
                        leave_state?.isChecked
                          ? !leave_state.staffId
                          : !leave_state?.employeeId
                      }
                      fullWidth
                      error={Boolean(reservedError)}
                      helperText={reservedError}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                    <TextField
                      type="text"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d*$/.test(inputValue)) {
                          set_leave_state({
                            ...leave_state,
                            reserved: {
                              main: leave_state?.reserved?.main,
                              additional: e.target.value,
                            },
                          });
                          const used = parseFloat(leave_state.used.additional);
                          const unused = parseFloat(
                            leave_state.unUsed.additional
                          );
                          const total = parseFloat(e.target.value);
                          if (
                            used &&
                            unused &&
                            total &&
                            used + unused !== total
                          ) {
                            setReservedAddError(
                              "Əlavə İstifadə olunmuş və əlavə qalıq məzuniyyət günlərinin ümumi sayı əlavə yaranmış məzuniyyət hüququ sayından az və ya çox ola bilməz"
                            );
                          } else {
                            setReservedAddError("");
                          }
                        } else {
                          set_leave_state({
                            ...leave_state,
                            reserved: {
                              main: leave_state?.reserved?.main,
                              additional: "",
                            },
                          });
                        }
                      }}
                      label="Əlavə"
                      value={leave_state?.reserved?.additional}
                      fullWidth
                      disabled={
                        leave_state?.isChecked
                          ? !leave_state.staffId
                          : !leave_state?.employeeId
                      }
                      error={Boolean(reservedAddError)}
                      helperText={reservedAddError}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div style={{ marginTop: "10px" }}>
                    İstifadə edilmiş məzuniyyət
                  </div>
                  <div
                    className="mt-2"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <TextField
                      type="text"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d*$/.test(inputValue)) {
                          set_leave_state({
                            ...leave_state,
                            used: {
                              main: e.target.value,
                              additional: leave_state?.used?.additional,
                            },
                          });

                          const used = parseFloat(e.target.value);
                          const unused = parseFloat(leave_state.unUsed.main);
                          const total = parseFloat(leave_state.reserved.main);

                          if (used > total) {
                            setUsedError(
                              `İstifadə edilmiş məzuniyət günlərinin sayı yaranmış məzuniyyət hüququdan çox ola bilməz`
                            );
                          } else if (
                            total &&
                            used &&
                            unused &&
                            total - unused !== used &&
                            !unusedError
                          ) {
                            setUsedError(
                              `İstifadə edilmiş məzuniyət günlərinin sayı ${
                                total - unused
                              }-dan çox və ya az ola bilməz`
                            );
                          } else {
                            setUsedError("");
                          }
                        } else {
                          set_leave_state({
                            ...leave_state,
                            used: {
                              main: "",
                              additional: leave_state?.used?.additional,
                            },
                          });
                        }
                      }}
                      disabled={
                        leave_state?.isChecked
                          ? !leave_state.staffId
                          : !leave_state?.employeeId
                      }
                      label="Əsas"
                      value={leave_state?.used?.main}
                      fullWidth
                      error={Boolean(usedError)}
                      helperText={usedError}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                    <TextField
                      type="text"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d*$/.test(inputValue)) {
                          set_leave_state({
                            ...leave_state,
                            used: {
                              main: leave_state?.used?.main,
                              additional: e.target.value,
                            },
                          });

                          const used = parseFloat(e.target.value);
                          const unused = parseFloat(
                            leave_state.unUsed.additional
                          );
                          const total = parseFloat(
                            leave_state.reserved.additional
                          );
                          if (used > total) {
                            setUsedAddError(
                              `Əlavə istifadə edilmiş məzuniyət günlərinin sayı yaranmış əlavə məzuniyyət hüququndan çox ola bilməz`
                            );
                          } else if (
                            total &&
                            used &&
                            unused &&
                            total - unused !== used &&
                            !unusedAddError
                          ) {
                            setUsedAddError(
                              `Əlavə istifadə edilmiş məzuniyət günlərinin sayı ${
                                total - unused
                              }-dan çox və ya az ola bilməz`
                            );
                          } else {
                            setUsedAddError("");
                          }
                        } else {
                          set_leave_state({
                            ...leave_state,
                            used: {
                              main: leave_state?.used?.main,
                              additional: "",
                            },
                          });
                        }
                      }}
                      disabled={
                        leave_state?.isChecked
                          ? !leave_state.staffId
                          : !leave_state?.employeeId
                      }
                      label="Əlavə"
                      value={leave_state?.used?.additional}
                      fullWidth
                      error={Boolean(usedAddError)}
                      helperText={usedAddError}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div style={{ marginTop: "10px" }}>
                    Qalıq məzuniyyət günləri
                  </div>
                  <div
                    className="mt-2"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <TextField
                      type="text"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d*$/.test(inputValue)) {
                          set_leave_state({
                            ...leave_state,
                            unUsed: {
                              main: inputValue,
                              additional: leave_state?.unUsed?.additional,
                            },
                          });

                          const used = parseFloat(leave_state.used.main);
                          const unused = parseFloat(inputValue);
                          const total = parseFloat(leave_state.reserved.main);
                          if (unused > total) {
                            setUnusedError(
                              `Qalıq məzuniyət günlərinin sayı yaranmış məzuniyyət hüququndan çox və ya az ola bilməz`
                            );
                          } else if (
                            total &&
                            used &&
                            unused &&
                            total - used !== unused &&
                            !usedError
                          ) {
                            setUnusedError(
                              `Qalıq məzuniyət günlərinin sayı ${
                                total - used
                              }-dan çox və ya az ola bilməz`
                            );
                          } else {
                            setUnusedError("");
                          }
                        } else {
                          set_leave_state({
                            ...leave_state,
                            unUsed: {
                              main: "",
                              additional: leave_state?.unUsed?.additional,
                            },
                          });
                        }
                      }}
                      label="Əsas"
                      value={leave_state?.unUsed?.main}
                      disabled={
                        leave_state?.isChecked
                          ? !leave_state.staffId
                          : !leave_state?.employeeId
                      }
                      fullWidth
                      error={Boolean(unusedError)}
                      helperText={unusedError}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />

                    <TextField
                      type="text"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d*$/.test(inputValue)) {
                          set_leave_state({
                            ...leave_state,
                            unUsed: {
                              main: leave_state?.unUsed?.main,
                              additional: e.target.value,
                            },
                          });
                          const used = parseFloat(leave_state.used.additional);
                          const unused = parseFloat(e.target.value);
                          const total = parseFloat(
                            leave_state.reserved.additional
                          );
                          if (unused > total) {
                            setUnusedAddError(
                              `Əlavə qalıq məzuniyət günlərinin sayı yaranmış əlavə məzuniyyət hüququndan çox  ola bilməz`
                            );
                          } else if (
                            total &&
                            used &&
                            unused &&
                            total - used !== unused &&
                            !usedAddError
                          ) {
                            setUnusedAddError(
                              `Əlavə qalıq məzuniyət günlərinin sayı ${
                                total - used
                              }-dan çox və ya az ola bilməz`
                            );
                          } else {
                            setUnusedAddError("");
                          }
                        } else {
                          set_leave_state({
                            ...leave_state,
                            unUsed: {
                              main: leave_state?.unUsed?.main,
                              additional: "",
                            },
                          });
                        }
                      }}
                      disabled={
                        leave_state?.isChecked
                          ? !leave_state.staffId
                          : !leave_state?.employeeId
                      }
                      label="Əlavə"
                      value={leave_state?.unUsed?.additional}
                      fullWidth
                      error={Boolean(unusedAddError)}
                      helperText={unusedAddError}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div style={{ marginTop: "10px" }}>Əvəzgün</div>
                  <div
                    className="mt-2"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <TextField
                      type="text"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d*$/.test(inputValue)) {
                          set_leave_state({
                            ...leave_state,
                            interrupted: e.target.value,
                          });
                        }
                      }}
                      label="Əvəzgün"
                      value={leave_state?.interrupted}
                      fullWidth
                      error={Boolean(interruptedError)}
                      helperText={interruptedError}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {disabledReasonMessage?.length > 0 && (
              <div className="d-flex flex-column w-100 gap-3 mt-2">
                {disabledReasonMessage &&
                  disabledReasonMessage.map((item, index) => {
                    return (
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography
                          sx={{ color: "#ff9800" }}
                          key={`${item?.az}-${index}`}
                        >
                          {item?.az}
                        </Typography>
                      </Paper>
                    );
                  })}
              </div>
            )}
          </div>
          <div className="w-25 ms-2 mt-3">
            <Button
              type="submit"
              variant="contained"
              disabled={isLeaveStateEmpty() || errorController() || submited}
            >
              Əlavə et
            </Button>
          </div>
        </form>
      )}
    </>
  );
};

export default VacationTrackingForm;
