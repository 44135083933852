import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

// Material UI
import { Button, Divider as MuiDivider, Grid, Typography } from "@mui/material";
import { Add as AddIcon, Visibility } from "@mui/icons-material";

// Services
import * as contractServices from "../../../services/documentations/ContractServices";
import { getDescendantProp } from "../../../utils/helperFunctions";
// import DocumentDialog from "../../../components/dialogs/documentDialog/DocumentDialog";
import DocumentDialog from "../../../components/dialogs/documentDialog/OldDocumentDialog";
import Column from "../../../models/table/Column";
import Table from "../../../components/table/Table";
import { OPERATORS_CONTAINING, SUBJECT_TYPES } from "../../../constants";
import { downloadFile } from "../../../utils/downloadCSV";
import StatusGenerator from "../../../components/table/StatusGenerator";
import { getDownloadCommands } from "../../../services/structure/StructureRequestService";
import EditIcon from "@mui/icons-material/Edit";
import {
  getLabourContractDetails_SERVICE,
  getLabourContractDoc_SERVICE,
  getLabourContractUpdates_SERVICE,
} from "../../../services/documentServices";
import EmployeeContractModal from "../orders/create-orders/transfer-another-job/emp-contract-modal";
import Operator from "../../../models/table/Operator";
import OperatorGenerator from "../../../components/table/OperatorGenerator";
import ContractDialog from "../../../components/dialogs/documentDialog/ContractDialog";
import { getLabourContractDoc_API } from "../../../api/documentRequests";
const Divider = styled(MuiDivider)(spacing);

const ViewTypeofContracts = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState();
  const [data, setData] = useState([]);
  const [dataID, setDataID] = useState();
  const [limit, setLimit] = useState(10);
  const [datas, setDatas] = useState([]);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState([]);
  const [oldData, setOldData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(undefined);
  const [detailData, setDetailData] = useState([]);
  const [documentType, setContractType] = useState();
  const [selectedCont, setSelectedCont] = useState("");
  const [individualModal, setIndividualModal] = useState(false);
  const paramsData = new URLSearchParams(document.location.search);

  const type = paramsData.get("contractType");
  const label = paramsData.get("label");
  const name = paramsData.get("name");
  const contractTypeId = paramsData.get("contractID");

  useEffect(() => {
    let additionalFilters = [];
    setContractType(type);
    if (contractTypeId) {
      additionalFilters.push({
        column: "contractType._id",
        operator: "equalsTo",
        value: contractTypeId,
      });
    }

    paginationFetchingHandler(additionalFilters);
  }, [limit, offset, filters, type]);

  const paginationFetchingHandler = async (additionalFilters) => {
    await getAllContracts([...filters, ...additionalFilters]);
  };

  const getAllContracts = async (filters = []) => {
    try {
      setLoading(true);
      const { contracts, total } =
        await contractServices.getContractsWithFilters({
          limit: limit || 10,
          offset: offset || 0,
          filters: encodeURIComponent(JSON.stringify([...filters])),
        });
      const _respDatas = contracts.map((type) => ({
        ...type,
        id: type._id,
      }));
      setDatas(_respDatas);
      setTotal(total);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  const docType = {
    order: "Əmr",
    agreement: "Müqavilə",
    application: "Ərizə",
  };

  const onCloseHandler = () => {
    setIsShow(false);
  };

  // const handleChooseVersion = (id) => {
  //   if (dataDialog.oldVersions.length > 0) {
  //     const foundedV = dataDialog.oldVersions.find(
  //       (item) => item.contract._id === id
  //     );
  //     if (!foundedV && dataDialog._id === id) {
  //       setVersionData(dataDialog);
  //       setShowContract(true);
  //       return;
  //     }
  //     setVersionData(foundedV.contract);
  //   } else {
  //     setVersionData(dataDialog);
  //   }
  //   setShowContract(true);
  // };

  // const handleCloseShowContract = () => {
  //   setShowContract(false);
  //   setVersionData(null);
  // };

  // const handleCloseDialog = () => {
  //   setIsShow(false);
  //   setDataDialog(null);
  //   // setVersionData(null);
  // };

  // const handlerGetVersionsObject = () => {
  //   const versions = [];

  //   versions.push({
  //     title: `Son versiya`,
  //     description: `Tarix: ${new Date(dataDialog.createdAt).toLocaleString(
  //       "az-AZ",
  //       {
  //         hour12: false,
  //       }
  //     )}`,
  //     value: dataDialog._id,
  //   });

  //   dataDialog.oldVersions.map((item, index) => {
  //     const obj = {
  //       title: `v.${index + 1}`,
  //       description: `Tarix: ${new Date(item.contract.createdAt).toLocaleString(
  //         "az-AZ",
  //         { hour12: false }
  //       )}`,
  //       value: item.contract._id ? item.contract._id : dataDialog._id,
  //     };
  //     versions.push(obj);
  //   });

  //   return versions;
  // };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const downloadHandler = async (columnsForExport) => {
    try {
      const colsExp = [];

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        colsExp.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      let additionalFilters = [];

      const contractTypeId = paramsData.get("contractType");
      if (contractTypeId) {
        additionalFilters.push({
          column: "contractType._id",
          operator: "equalsTo",
          value: contractTypeId,
        });
      }

      const encodeColumns = encodeURIComponent(JSON.stringify(colsExp));
      const encodeFilters = encodeURIComponent(
        JSON.stringify([...filters, ...additionalFilters])
      );
      const resp = await getDownloadCommands({
        columns: encodeColumns,
        filter: encodeFilters,
        isWithFilter: true,
      });

      if (resp) downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  const fetchData = async (filters = undefined) => {
    try {
      setLoading(true);
      const payload = {
        offset: offset,
        limit: limit,
        filter: filters,
      };
      const { data } = await getLabourContractUpdates_SERVICE(payload);

      setData(data?.contracts);
      setTotal(data?.total);
      setLoading(false);
    } catch (error) {
      console.log("err", error);
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      if (dataID) {
        const res = await getLabourContractDetails_SERVICE(dataID);
        setDetailData(res?.data);
        setSelectedCont(res?.data?.data?.fromContractId?.data?.staffId);
        console.log(res.data);
        setOldData(res?.data?.data?.fromContractId?.data);
      }
    } catch (error) {
      console.log("err", error);
    }
  };
  useEffect(() => {
    getData();
  }, [dataID]);

  const columns = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      renderCell: (row, column) => {
        // return (
        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //       gap: "10px",
        //     }}
        //   >
        //     {/* <DocumentDialog
        //       documentId={row._id}
        //       fetchHandler={contractServices.getContract}
        //       documentType={documentType}
        //     /> */}

        //     <Link
        //       to={`/documentation/create/static-order?value=${row?._id}&name=${name}`}
        //     >
        //       <EditIcon />
        //     </Link>
        //     {name === "transfer-order" && (
        //       <Button
        //         onClick={() => {
        //           setIndividualModal(true);
        //           setDataId(row?._id);
        //         }}
        //       >
        //         Ə.M Dəyişiklik et
        //       </Button>
        //     )}
        //   </div>
        // );
        const operators = [
          new Operator({
            icon: <Visibility />,
            onClick: () => {
              setDataID(row._id);

              setIsShow(true);
            },
            type: 0,
          }),
          ...(name === "transfer-order"
            ? [
                new Operator({
                  title: "Ə.M Dəyişiklik et",
                  onClick: () => {
                    setIndividualModal(true);
                    setDataID(row._id);
                  },
                }),
              ]
            : []),
          new Operator({
            icon: <EditIcon />,
            onClick: () => {
              navigate(
                `/documentation/create/static-order?value=${row?._id}&name=${name}`
              );
            },
            type: 0,
          }),
        ];

        return <OperatorGenerator operators={operators} />;
      },
    }),
    new Column({
      path: "data.docNumber.value",
      headerName: "Əmrin nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "contractType.code",
      headerName: "Kod",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "contractType.templateName",
      headerName: "Faylın adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "docModel",
      headerName: "Document Model",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: SUBJECT_TYPES,
    }),
    new Column({
      path: "subjects.subject.firstname",
      headerName: "Əməkdaşın adı",
      filterType: OPERATORS_CONTAINING.TEXT,

      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      path: "subjects.subject.lastname",
      headerName: "Əməkdaşın soyadı",
      filterType: OPERATORS_CONTAINING.TEXT,

      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.OPTION,
      isStaticColumn: true,

      renderCell: (row, column) => {
        const approvers = [];
        row.verifiers.map((item, index) => {
          approvers.push({
            index: index + 1,
            employee:
              item.employee?.lastname +
              " " +
              item.employee?.firstname +
              " " +
              item.employee?.fathername,
            status: item.status,
          });
        });
        return (
          <StatusGenerator
            approvement={approvers}
            value={getDescendantProp(row, column.path)}
            docType={"generalDocuments"}
          />
        );
      },
    }),
  ];

  let button_group = [
    ...(status === "pending"
      ? [
          {
            // onClick: () =>
            //   renderCommmenDialog({ isShow: true, type: "approved" }),
            label: "İmzala",
            color: "success",
            gap: "me-1",
          },
          {
            // onClick: () =>
            //   renderCommmenDialog({ isShow: true, type: "rejected" }),
            label: "İmtina et",
            color: "error",
          },
        ]
      : []),
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{ marginRight: "5px" }}
            variant="contained"
            color="primary"
            onClick={() => {
              if (documentType === "order") {
                navigate("/documentation/orders");
              } else if (documentType === "agreement") {
                navigate("/documentation/contracts");
              } else if (documentType === "application") {
                navigate("/documentation/application");
              }
            }}
          >
            {docType[documentType]}lər
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              navigate(
                `/documentation/create/static-order?value=${contractTypeId}&name=${name}`
              )
            }
          >
            <AddIcon />
            {docType[documentType]} Yarat
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} mb={6}>
          <Table
            columns={columns}
            data={datas}
            totalRows={total}
            offset={offset}
            setOffset={(value) => setOffset(value)}
            limit={limit}
            setLimit={(value) => setLimit(value)}
            isLoading={loading}
            applyFiltersHandler={applyFiltersHandler}
            cancelAppliedFilters={cancelAppliedFiltersHandler}
            downloadHandler={downloadHandler}
          />
        </Grid>
      </Grid>
      {isShow && (
        // <DocumentDialog
        //   id={dataID}
        //   onClose={onCloseHandler}
        //   open={isShow}
        //   buttonGroup={button_group}
        //   setStatus={setStatus}
        //   fetch
        // />
        <ContractDialog
          id={dataID}
          open={isShow}
          onClose={() => onCloseHandler()}
          disableSigning={true}
          fetchHandler={getLabourContractDoc_API}
        />
      )}
      {individualModal && (
        <EmployeeContractModal
          detailData={detailData}
          oldData={oldData}
          fetchData={fetchData}
          open={individualModal}
          selectedCont={selectedCont}
          setOpen={setIndividualModal}
        />
      )}
    </>
  );
};

export default ViewTypeofContracts;
