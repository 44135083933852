import React, { useState } from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { Field } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const LabourPeriod = ({ setFieldValue, selectedValue, values, errors }) => {
  const [inputValue, setInputValue] = useState("");

  const handleAddChip = (setFieldValue, values) => {
    const laborFunctions = values?.labourPeriod?.laborFunctions || [];
    if (inputValue.trim() !== "") {
      setFieldValue("labourPeriod.laborFunctions", [
        ...laborFunctions,
        inputValue.trim(),
      ]);
      setInputValue("");
    }
  };

  const handleDeleteChip = (chipToDelete, setFieldValue, values) => {
    setFieldValue(
      "labourPeriod.laborFunctions",
      values?.labourPeriod?.laborFunctions.filter(
        (chip) => chip !== chipToDelete
      ) || []
    );
  };

  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>Əmək müqaviləsininin müddəti</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>Əmək Müqaviləsi növü</InputLabel>
                <Field
                  as={Select}
                  label="Əmək Müqaviləsi növü"
                  defaultValue=""
                  fullWidth
                  name="labourPeriod.contractPeriodType"
                  onChange={(event) => {
                    setFieldValue(
                      "labourPeriod.contractPeriodType",
                      event.target.value
                    );
                  }}
                >
                  {selectedValue?.contractPeriodType &&
                    selectedValue?.contractPeriodType.map((item, index) => {
                      return (
                        <MenuItem
                          key={`${item?.value}${index}`}
                          value={item?.value}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                </Field>
              </FormControl>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                gap: "5px",
              }}
            >
              <TextField
                type="number"
                label="Sınaq müddəti"
                value={values?.labourPeriod?.probationPeriod}
                fullWidth
                // disabled
                name="labourPeriod.probationPeriod"
                onWheel={(e) => e.target.blur()}
                onChange={(event) => {
                  setFieldValue(
                    "labourPeriod.probationPeriod",
                    event.target.value
                  );
                }}
              />
              <FormControl fullWidth>
                <InputLabel>Sınaq müddəti növü</InputLabel>
                <Field
                  as={Select}
                  label="Sınaq müddəti növü"
                  disabled
                  defaultValue=""
                  fullWidth
                  name="labourPeriod.probationPeriodType"
                  onChange={(event) => {
                    setFieldValue(
                      "labourPeriod.probationPeriodType",
                      event.target.value
                    );
                  }}
                >
                  {selectedValue?.probationPeriodType &&
                    selectedValue?.probationPeriodType.map((item, index) => {
                      return (
                        <MenuItem
                          key={`${item?.value}${index}`}
                          value={item?.value}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                </Field>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <Field name={`labourPeriod.contractStartDate`}>
                {({ field, form }) => (
                  <DatePicker
                    {...field}
                    label="Əmək Müqaviləsi başlama tarixi"
                    disabled
                    sx={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    value={field.value ? new Date(field.value) : null}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    onChange={(date) => {
                      if (date instanceof Date && !isNaN(date)) {
                        form.setFieldValue(
                          `labourPeriod.contractStartDate`,
                          date
                        );
                      }
                    }}
                    maxDate={new Date()}
                    minDate={new Date(1940, 0, 1)}
                  />
                )}
              </Field>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <Field name={`labourPeriod.contractEndDate`}>
                {({ field, form }) => (
                  <>
                    <DatePicker
                      {...field}
                      label="Əmək Müqaviləsi bitmə tarixi"
                      sx={{ width: "100%" }}
                      format="dd/MM/yyyy"
                      value={
                        values?.labourPeriod?.contractPeriodType ===
                        "indefinite"
                          ? null
                          : field.value
                          ? new Date(field.value)
                          : null
                      }
                      slotProps={{
                        textField: {
                          error: Boolean(
                            form?.errors?.labourPeriod?.contractEndDate
                          ),
                          helperText:
                            form?.errors?.labourPeriod?.contractEndDate,
                          fullWidth: true,
                        },
                      }}
                      disabled={
                        values?.labourPeriod?.contractPeriodType ===
                        "indefinite"
                      }
                      onChange={(date) => {
                        if (date === null || date === "Invalid Date") {
                          form.setErrors({
                            ...form.errors,
                            labourPeriod: {
                              ...form.errors.labourPeriod,
                              contractEndDate: "Bitiş tarixi zəruridir",
                            },
                          });
                        } else {
                          form.setErrors({
                            ...form.errors,
                            labourPeriod: {
                              ...form.errors.labourPeriod,
                              contractEndDate: "",
                            },
                          });
                        }
                        if (date instanceof Date && !isNaN(date)) {
                          if (
                            values?.labourPeriod?.contractPeriodType ===
                            "indefinite"
                          ) {
                            form.setFieldValue(
                              `labourPeriod.contractEndDate`,
                              null
                            );
                            form.setErrors({
                              ...form.errors,
                              labourPeriod: {
                                ...form.errors.labourPeriod,
                                contractEndDate: "",
                              },
                            });
                          } else {
                            form.setFieldValue(
                              `labourPeriod.contractEndDate`,
                              date
                            );
                          }
                        }
                      }}
                      minDate={new Date(1940, 0, 1)}
                    />
                  </>
                )}
              </Field>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <Field name={`labourPeriod.jobStartDate`}>
                {({ field, form }) => (
                  <DatePicker
                    {...field}
                    label="Əməkdaşın işə başlama tarixi"
                    sx={{ width: "100%" }}
                    format="dd/MM/yyyy"
                    value={field.value ? new Date(field.value) : null}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    disabled
                    onChange={(date) => {
                      if (date instanceof Date && !isNaN(date)) {
                        form.setFieldValue(`labourPeriod.jobStartDate`, date);
                      }
                    }}
                    // maxDate={new Date()}
                    minDate={new Date(1940, 0, 1)}
                  />
                )}
              </Field>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <FormControl
                error={
                  errors?.labourPeriod?.reasonOfTemporaryContract &&
                  Boolean(errors?.labourPeriod?.reasonOfTemporaryContract)
                }
                fullWidth
              >
                <InputLabel>Müddətli bağlanma səbəbi</InputLabel>
                <Field
                  as={Select}
                  label="Müddətli bağlanma səbəbi"
                  defaultValue=""
                  fullWidth
                  value={
                    values?.labourPeriod?.contractPeriodType === "indefinite"
                      ? ""
                      : values?.labourPeriod?.reasonOfTemporaryContract
                  }
                  disabled={
                    values?.labourPeriod?.contractPeriodType === "indefinite"
                      ? true
                      : false
                  }
                  error={
                    errors?.labourPeriod?.reasonOfTemporaryContract &&
                    Boolean(errors?.labourPeriod?.reasonOfTemporaryContract)
                  }
                  name="labourPeriod.reasonOfTemporaryContract"
                  onChange={(event) => {
                    if (
                      values?.labourPeriod?.contractPeriodType === "indefinite"
                    ) {
                      setFieldValue(
                        `labourPeriod.reasonOfTemporaryContract`,
                        ""
                      );
                    } else {
                      setFieldValue(
                        "labourPeriod.reasonOfTemporaryContract",
                        event.target.value
                      );
                    }
                  }}
                >
                  {selectedValue?.reasonOfTemporaryContract &&
                    selectedValue?.reasonOfTemporaryContract.map((item, i) => (
                      <MenuItem key={`${item.value}${i}`} value={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                </Field>
                {errors?.labourPeriod?.reasonOfTemporaryContract && (
                  <FormHelperText error>
                    {errors?.labourPeriod?.reasonOfTemporaryContract}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <>
              <TextField
                type="text"
                label="İşçi aşağıdakı əmək funksiyasının icrasını öhdəsinə götürür"
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                fullWidth
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleAddChip(setFieldValue, values);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => handleAddChip(setFieldValue, values)}
                      disabled={!inputValue.trim()}
                    >
                      <AddIcon />
                    </IconButton>
                  ),
                }}
              />

              {/* Render chips */}
              <Paper
                component="ul"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "8px",
                  marginTop: "16px",
                  width: "100%",
                }}
              >
                {values?.labourPeriod?.laborFunctions &&
                  values?.labourPeriod?.laborFunctions.map((func, index) => (
                    <li key={index} style={{ margin: "4px", width: "45%" }}>
                      <Chip
                        label={
                          <div
                            style={{
                              maxWidth: "200px",
                              whiteSpace: "normal",
                              wordBreak: "break-word",
                            }}
                          >
                            {func}
                          </div>
                        }
                        onDelete={() =>
                          handleDeleteChip(func, setFieldValue, values)
                        }
                        deleteIcon={<CloseIcon />}
                        style={{ height: "auto" }}
                      />
                    </li>
                  ))}
              </Paper>
            </>
          </Grid>
        </AccordionDetails>
      </CustomAccordion>
    </>
  );
};

export default LabourPeriod;
