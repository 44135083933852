import {
  deleteAccount_API,
  deleteUser_API,
  editUser_API,
  getAccountsByUserId_API,
  getAllUsersAccounts_API,
  getUser_API,
  getUsers_API,
  registerUserAccount_API,
} from "../api/user-accountRequets";
import { handleApiCall } from "./apiHandler";

// ⇩ ⇩ ⇩ User & Account Services; ⇩ ⇩ ⇩
export const registerUser_SERVICE = async (...payload) =>
  handleApiCall(registerUserAccount_API, ...payload);

export const deleteUser_SERVICE = async (id) =>
  handleApiCall(deleteUser_API, id);

export const editUser_SERVICE = async (...payload) =>
  handleApiCall(editUser_API, ...payload);

export const getUser_SERVICE = async (id) => handleApiCall(getUser_API, id);

export const getUsers_SERVICE = async (...payload) =>
  handleApiCall(getUsers_API, ...payload);
// ----------------------------------------------------------
export const registerAccount_SERVICE = async (...payload) =>
  handleApiCall(registerUserAccount_API, ...payload);

export const getAccounts_SERVICE = async (id) =>
  handleApiCall(getAccountsByUserId_API, id);

export const deleteAccount_SERVICE = async (id) =>
  handleApiCall(deleteAccount_API, id);

// ⇧ ⇧ ⇧ User & Account Services ⇧ ⇧ ⇧
