import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { getEmployeeById } from "../../../api/api";
import {
  getEmployeeVacationReport,
  getVacationRequests,
  getVacationsWithFilter,
} from "../../../api/vacationRequests/vacationRequests";
import EmpReportingForm from "../../../components/vacations/employee/EmpReportingForm";
import CardTotals from "../../../layouts/vacations/cards/CardTotals";
import VacationCards from "../../../layouts/vacations/cards/VacationCards";
import { SystemRequestCardItem } from "../../../components/cards/SystemRequestCardItem";
import RequestDialog from "../../../components/dialogs/documentDialog/RequestDialog";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const typeOfDialog = "employee-request";

export default function EmptyCard() {
  const navigate = useNavigate();
  const [totalRequests, setTotalRequests] = useState();
  const [dialogTitle, setdialogTitle] = useState("");
  const [dialogColor, setDialogColor] = useState();
  const [dialogContent, setDialogContent] = useState();
  const [handleVacationDialog, setHandleVacationDialog] = useState(false);
  const [id, setId] = useState("");
  const [requests, setRequests] = useState({
    approved: "",
    pending: "",
    reRequested: "",
    rejected: "",
  });

  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const cardTitles = {
    pendingTitle: "Yaratdığım və qərar gözləyən sorğular",
    approvedTitle: "Təsdiq edilmiş sorğularım",
    rejectedTitle: "İmtina edilmiş / düzəltməli olduğum sorğular",
    rerequestedTitle: "Düzəldib yenidən göndərdiyim sorğular",
  };
  const [daysCount, setDaysCount] = useState("");

  const { currentEmployee } = useSelector((state) => state.commonThings);

  useEffect(() => {
    const getVacations = async () => {
      try {
        const response = await getVacationRequests(limit, offset);

        if (response && response.data) {
          setTotalRequests({
            approved: response.data.approved.total,
            pending: response.data.pending.total,
            rejected: response.data.rejected.total,
            rerequested: response.data.reRequested.total,
          });
          setRequests(response.data);
        }
      } catch (err) {
        console.log("error: ", err);
      }
    };
    getVacations();
    currentEmployee && getdaysCount();
  }, [currentEmployee]);

  const getdaysCount = async () => {
    try {
      const { data } = await getEmployeeVacationReport();

      data && setDaysCount(data?.unUsed);
    } catch (error) {
      console.log("err", error);
    }
  };

  const handlePagination = async (value, status) => {
    const newOffset = (value - 1) * 5;
    try {
      const response = await getVacationsWithFilter(limit, newOffset, status);
      if (response && response.data) {
        if (status === "pending") {
          setRequests((prevState) => ({
            ...prevState,
            pending: response.data,
          }));
        } else if (status === "approved") {
          setRequests((prevState) => ({
            ...prevState,
            approved: response.data,
          }));
        } else if (status === "rejected") {
          setRequests((prevState) => ({
            ...prevState,
            rejected: response.data,
          }));
        } else {
          setRequests((prevState) => ({
            ...prevState,
            reRequested: response.data,
          }));
        }
      }
    } catch (err) {
      console.log("error: ", err);
    }
  };

  const handleDialogId = (id) => {
    setId(id);
    setHandleVacationDialog(true);
  };

  return (
    <Grid>
      <Grid mb={7} sx={{ alignItems: "center", display: "flex", gap: 5 }}>
        <Grid>
          <CardTotals totalRequests={totalRequests} />
        </Grid>
        <Grid sx={{ width: "100%" }}>
          <Card px={4} py={3}>
            <EmpReportingForm />
          </Card>
        </Grid>
      </Grid>
      <Grid
        mb={7}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            backgroundColor: "#407ad6",
            p: 3,
            borderRadius: 1,
            display: "flex",
            gap: 3,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              border: 4,
              width: 50,
              height: 50,
              borderRadius: "50%",
              borderColor: "rgba(15, 76, 117, 1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {daysCount !== "" ? (
              <Typography variant="h4">{daysCount}</Typography>
            ) : (
              <CircularProgress color="inherit" size={22} />
            )}
          </Box>
          <Typography variant="h4">Qalan məzuniyyət günlərimin sayı</Typography>
        </Grid>
        <Button
          size="large"
          variant="contained"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            navigate("/create-vacation-request");
          }}
        >
          Yeni Məzuniyyət Sorğusu Yarat
        </Button>
      </Grid>

      {/* <Grid>
        <VacationCards
          requests={requests}
          totalRequests={totalRequests}
          limit={limit}
          cardTitles={cardTitles}
          handlePagination={handlePagination}
          isFromEmp
        />
      </Grid> */}
      <Grid container spacing={5}>
        {Object.keys(requests)?.map((k) => (
          <Grid item xs={6} lg={3}>
            <SystemRequestCardItem
              data={requests[k]?.records}
              total={requests[k]?.total}
              limit={limit}
              approver
              type={k}
              dialogHandler={handleDialogId}
              setdialogTitle={setdialogTitle}
              setDialogColor={setDialogColor}
              setDialogContent={setDialogContent}
              handlePagination={handlePagination}
              // setPagination={setPagination}
              // pagination={{ set: setPagination, state: pagination }}
              // submitted={submitted}
              // setSubmitted={setSubmitted}
              // submit={handleUpdateVacation}
              // setShow={setShowDialog}
            />
          </Grid>
        ))}
        {handleVacationDialog && (
          // <RequestShowDialog
          //   open={handleVacationDialog}
          //   onClose={() => setHandleVacationDialog(false)}
          //   updateRequestHandle={(id, status, desc) =>
          //     handleUpdateVacation(id, status, desc)
          //   }
          //   id={id}
          //   typeOfDialog={typeOfDialog}
          // />
          <RequestDialog
            open={handleVacationDialog}
            onClose={() => setHandleVacationDialog(false)}
            id={dialogContent._id}
            // id={id}
            // fetchHandler={getVacationRequest}
            // handlerUpdateReq={handleUpdateVacation}
            // fetchHandler={isFromEmp && getEmployeeRequest}
          />
        )}
        {/* <VacationCards
          requests={requests}
          totalRequests={totalRequests}
          limit={limit}
          cardTitles={cardTitles}
          approver={approver}
          handlePagination={handlePagination}
          submitted={submitted}
          setSubmitted={setSubmitted}
          typeOfDialog={typeOfDialog}
        /> */}
      </Grid>
    </Grid>
  );
}
