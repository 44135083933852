import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import {
  overtimeMockData,
  overTimePersonalValidationSchema,
} from "../overtime/mockOverTime";
import Header from "../../components/layouts/header/Header";
import { DatePicker } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import moment from "moment";
import { addPersonalOvertimeApplication_SERVICE } from "../../services/overtime/overtimeServices";
import { removeEmptyStrings } from "../../utils/removeEmptyString";

const PersonalOvertime = () => {
  const [show_Accordion, set_show_Accordion] = useState(false);
  const [isDate, setIsDate] = useState(false);
  const { currentEmployee } = useSelector((state) => state?.commonThings);
  const headerData = {
    title: "İş vaxtından artıq işə çıxma",
    variant: "h3",
  };
  const initialValues = {
    employee: `${currentEmployee?.firstname} ${currentEmployee?.lastname}`,
    contract: "",
    structure: "",
    position: "",
    footer: [
      {
        date: "",
        hour: "",
        paymentType: "Double",
      },
    ],
    reason: "",
    contractItem: "",
    type: "",
  };
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);
      const modifiedValues = {
        type: values?.type,
        reason: values?.reason,
        clause: values?.contractItem,
        staffId: values?.contract,
        workShifts: values?.footer?.map((foot) => ({
          date: moment(foot?.date).format("MM/DD/YYYY"),
          hours: parseFloat(foot?.hour),
          paymentType: foot?.paymentType,
        })),
      };
      const result = removeEmptyStrings(modifiedValues);
      const res = await addPersonalOvertimeApplication_SERVICE(result);

      if (res?.status === 201) {
        set_show_Accordion(false);
        // getData();
      }
      setSubmitting(false);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
        </Grid>
        <div className="d-flex justify-content-end">
          <Button
            title="Yarat"
            onClick={() => {
              set_show_Accordion(!show_Accordion);
              setIsDate(false);
            }}
            variant="contained"
            sx={{ height: "100%" }}
          >
            <p
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Yarat <AddIcon />
            </p>
          </Button>
        </div>
        {show_Accordion && (
          <Formik
            initialValues={initialValues}
            validationSchema={overTimePersonalValidationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              setErrors,
              dirty,
            }) => (
              <>
                <Form>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Grid sx={{ display: "flex", gap: "10px" }}>
                      <Grid sx={{ width: "33%" }}>
                        <FormControl
                          fullWidth
                          error={
                            touched?.type &&
                            errors?.type &&
                            Boolean(errors?.type)
                          }
                        >
                          <InputLabel id={`type`}>Növ</InputLabel>
                          <Field
                            as={Select}
                            name={`type`}
                            id={`type`}
                            labelId={`type`}
                            label="Növ"
                            defaultValue=""
                            fullWidth
                            error={
                              touched?.type &&
                              errors?.type &&
                              Boolean(errors?.type)
                            }
                          >
                            {overtimeMockData?.[0]?.overtimeTypes.map(
                              (type, index) => {
                                return (
                                  <MenuItem value={type?.value}>
                                    {type?.label}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Field>
                          <ErrorMessage
                            name={`type`}
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid sx={{ width: "33%" }}>
                        <FormControl
                          fullWidth
                          error={errors?.contractItem && touched?.contractItem}
                        >
                          <InputLabel id={`contractItem`}>
                            Əmək məcəlləsinin maddəsi
                          </InputLabel>
                          <Field
                            fullWidth
                            as={Select}
                            defaultValue=""
                            disabled={!values?.type}
                            label="Əmək məcəlləsinin maddəsi"
                            id={`contractItem`}
                            name={`contractItem`}
                            labelId={`contractItem`}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  width: "100px",
                                  maxHeight: 200,
                                  overflowY: "auto",
                                },
                              },
                            }}
                            error={
                              errors?.contractItem &&
                              touched?.contractItem &&
                              Boolean(errors?.contractItem)
                            }
                          >
                            {values?.type &&
                              overtimeMockData?.[0]?.contractItemTypes?.[
                                values?.type
                              ].map((item, contIndex) => (
                                <MenuItem
                                  key={contIndex}
                                  value={item?.value}
                                  sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline-block",
                                    width: "100%",
                                  }}
                                >
                                  <Tooltip title={item?.label} followCursor>
                                    {item?.label}
                                  </Tooltip>
                                </MenuItem>
                              ))}
                          </Field>

                          {errors?.contractItem && touched?.contractItem && (
                            <ErrorMessage
                              name={`contractItem`}
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid sx={{ width: "33%" }}>
                        <Field
                          type="text"
                          name={`reason`}
                          as={TextField}
                          disabled={!values?.type}
                          error={touched?.reason && Boolean(errors?.reason)}
                          helperText={touched?.reason && errors?.reason}
                          fullWidth
                          label="Səbəb"
                        />
                      </Grid>
                    </Grid>
                    {values?.type && (
                      <div>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Typography mt={3} mb={3} variant="h6">
                              Əməkdaş
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div>
                              <Grid
                                sx={{
                                  gap: "10px",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  mb: 2,
                                }}
                              >
                                <Grid
                                  sx={{
                                    gap: "10px",
                                    width: "100%",
                                    display: "flex",
                                  }}
                                >
                                  <Grid sx={{ width: "50%" }}>
                                    <Field
                                      type="text"
                                      name={`employee`}
                                      as={TextField}
                                      fullWidth
                                      label="Əməkdaş"
                                      disabled
                                    />
                                  </Grid>
                                  <Grid sx={{ width: "50%" }}>
                                    <FormControl
                                      fullWidth
                                      error={
                                        errors?.contract &&
                                        touched?.contract &&
                                        Boolean(errors?.contract)
                                      }
                                    >
                                      <InputLabel>Əmək müqaviləsi</InputLabel>
                                      <Field
                                        as={Select}
                                        name={`contract`}
                                        label="Əmək müqaviləsi"
                                        defaultValue=""
                                        fullWidth
                                        disabled={!values?.employee}
                                        onChange={(val) => {
                                          let value =
                                            currentEmployee?.staffs.filter(
                                              (item) => {
                                                return (
                                                  item?._id ===
                                                  val?.target?.value
                                                );
                                              }
                                            );
                                          handleChange(val);
                                          setFieldValue(
                                            `position`,
                                            value?.[0]?.position?.name || ""
                                          );
                                          setFieldValue(
                                            `structure`,
                                            value?.[0]?.structure?.organization
                                              ?.name || ""
                                          );
                                        }}
                                        error={
                                          errors?.contract &&
                                          touched?.contract &&
                                          Boolean(errors?.contract)
                                        }
                                      >
                                        <MenuItem
                                          sx={{
                                            fontSize: "14px",
                                            paddingLeft: "8px",
                                          }}
                                          disabled
                                        >
                                          Müqavilə
                                        </MenuItem>
                                        {currentEmployee?.staffs?.map(
                                          (item, index) =>
                                            item?.status === "active" && (
                                              <MenuItem
                                                key={index}
                                                value={item?._id}
                                              >
                                                {
                                                  item?.contract?.data
                                                    ?.docNumber
                                                }
                                              </MenuItem>
                                            )
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name={`contract`}
                                        component="div"
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid
                                  sx={{
                                    gap: "10px",
                                    width: "100%",
                                    display: "flex",
                                  }}
                                >
                                  <Grid
                                    sx={{
                                      width: "50%",
                                      pointerEvents: "none",
                                    }}
                                  >
                                    <Field
                                      type="text"
                                      name={`structure`}
                                      as={TextField}
                                      fullWidth
                                      label="Struktur"
                                    />
                                  </Grid>
                                  <Grid
                                    sx={{
                                      width: "50%",
                                      pointerEvents: "none",
                                    }}
                                  >
                                    <Field
                                      type="text"
                                      name={`position`}
                                      as={TextField}
                                      fullWidth
                                      label="Vəzifə"
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  sx={{
                                    gap: "10px",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <FieldArray name={`footer`}>
                                    {({ push, remove }) => (
                                      <>
                                        {values?.footer.map((foot, ind) => (
                                          <Grid container spacing={2} key={ind}>
                                            <Grid
                                              item
                                              xs={4}
                                              onClick={() => setIsDate(true)}
                                            >
                                              <Field
                                                name={`footer[${ind}].date`}
                                              >
                                                {({ field, form }) => {
                                                  const error =
                                                    form.errors?.footer?.[ind]
                                                      ?.date &&
                                                    form.errors?.footer?.[ind]
                                                      ?.date;

                                                  return (
                                                    <>
                                                      <DatePicker
                                                        {...field}
                                                        label="Tarix"
                                                        inputFormat="dd/MM/yyyy"
                                                        value={
                                                          field.value || null
                                                        }
                                                        disabled={
                                                          !values?.contract
                                                        }
                                                        onChange={(value) =>
                                                          form.setFieldValue(
                                                            field.name,
                                                            value
                                                          )
                                                        }
                                                        minDate={
                                                          new Date(2025, 0, 1)
                                                        }
                                                        maxDate={
                                                          new Date(2099, 11, 31)
                                                        }
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                            fullWidth
                                                            error={
                                                              isDate && !!error
                                                            }
                                                            helperText={
                                                              isDate && error
                                                                ? error
                                                                : ""
                                                            }
                                                          />
                                                        )}
                                                      />
                                                    </>
                                                  );
                                                }}
                                              </Field>
                                            </Grid>

                                            <Grid item xs={4}>
                                              <Field
                                                name={`footer[${ind}].hour`}
                                              >
                                                {({ field, form }) => {
                                                  const error =
                                                    form.touched?.footer?.[ind]
                                                      ?.hour &&
                                                    form.errors?.footer?.[ind]
                                                      ?.hour;

                                                  return (
                                                    <TextField
                                                      {...field}
                                                      fullWidth
                                                      disabled={
                                                        !values?.contract
                                                      }
                                                      label="Saat"
                                                      error={!!error}
                                                      helperText={
                                                        error ? error : ""
                                                      }
                                                    />
                                                  );
                                                }}
                                              </Field>
                                            </Grid>

                                            <Grid item xs={4}>
                                              <FormControl
                                                component="fieldset"
                                                variant="standard"
                                              >
                                                <RadioGroup
                                                  name={`footer[${ind}].paymentType`}
                                                  row
                                                  defaultValue="Double"
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `footer[${ind}].paymentType`,
                                                      e?.target?.value
                                                    );
                                                  }}
                                                  value={
                                                    values?.footer?.[ind]
                                                      ?.paymentType
                                                  }
                                                >
                                                  <FormControlLabel
                                                    value="Double"
                                                    control={
                                                      <Radio color="primary" />
                                                    }
                                                    label="2X (2 qat)"
                                                  />
                                                  {values?.type !==
                                                    "Extended" && (
                                                    <FormControlLabel
                                                      value="Substitute_Day"
                                                      control={
                                                        <Radio color="primary" />
                                                      }
                                                      label="Əvəzgün"
                                                    />
                                                  )}
                                                </RadioGroup>
                                              </FormControl>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={12}
                                              sx={{
                                                display: "flex",
                                                gap: "10px",
                                              }}
                                            >
                                              {ind ===
                                                values?.footer.length - 1 &&
                                                values?.footer.length < 30 && (
                                                  <Button
                                                    type="button"
                                                    variant="outlined"
                                                    onClick={() => {
                                                      push({
                                                        date: "",
                                                        hour: "",
                                                        paymentType: "Double",
                                                      });
                                                      setIsDate(false);
                                                    }}
                                                  >
                                                    + Əlavə et
                                                  </Button>
                                                )}

                                              {values?.footer.length === 1
                                                ? ind > 0
                                                : ind >= 0 && (
                                                    <Button
                                                      type="button"
                                                      color="error"
                                                      variant="outlined"
                                                      onClick={() => {
                                                        remove(ind);
                                                        setIsDate(false);
                                                      }}
                                                    >
                                                      - Sil
                                                    </Button>
                                                  )}
                                            </Grid>
                                          </Grid>
                                        ))}
                                      </>
                                    )}
                                  </FieldArray>
                                </Grid>
                              </Grid>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}

                    <Grid
                      sx={{
                        mt: 3,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {values?.type && (
                        <Button
                          disabled={isSubmitting || !isValid || !dirty}
                          type="submit"
                          variant="contained"
                        >
                          Yadda saxla
                        </Button>
                      )}
                    </Grid>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        )}
      </Box>
    </React.Fragment>
  );
};

export default PersonalOvertime;
