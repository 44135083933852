import { useEffect, useState } from "react";
import {
  TextField,
  IconButton,
  Select,
  MenuItem,
  Box,
  Button,
  InputLabel,
  Autocomplete,
  FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { DatePicker } from "@mui/x-date-pickers";
import { getAllEmployees } from "../../api/api";
import { api } from "../../api/axiosInterceptor";
import { toast } from "react-toastify";
import { postAddBaseData } from "./services";

const months = [
  { value: "1", label: "Yanvar" },
  { value: "2", label: "Fevral" },
  { value: "3", label: "Mart" },
  { value: "4", label: "Aprel" },
  { value: "5", label: "May" },
  { value: "6", label: "İyun" },
  { value: "7", label: "İyul" },
  { value: "8", label: "Avqust" },
  { value: "9", label: "Sentyabr" },
  { value: "10", label: "Oktyabr" },
  { value: "11", label: "Noyabr" },
  { value: "12", label: "Dekabr" },
];

export default function CreateNewBase({ showAccordion }) {
  const [fields, setFields] = useState([{ id: 1, value1: "", value2: "" }]);
  const [employee, set_employee] = useState({});
  const [currentEmployee, setCurrentEmployee] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const [leave_state, set_leave_state] = useState({
    employee: "",
    staffId: "",
    salaries: [
      { month: "", year: "", salary: "", totalWorkDays: "", workDays: "" },
    ],
  });
  // for all employees
  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    if (leave_state?.employee) {
      getEmployeeByID(leave_state.employee);
    }
  }, [leave_state.employee]);

  const handleAddField = () => {
    set_leave_state({
      ...leave_state,
      salaries: [
        ...leave_state.salaries,
        { month: "", year: "", salary: "", totalWorkDays: "", workDays: "" },
      ],
    });
  };

  const handleChange = (index, field, value) => {
    const updatedSalaries = [...leave_state.salaries];
    updatedSalaries[index][field] = value;
    set_leave_state({ ...leave_state, salaries: updatedSalaries });
  };
  const handleRemoveField = (index) => {
    const updatedSalaries = leave_state.salaries.filter(
      (_, idx) => idx !== index
    );
    set_leave_state({ ...leave_state, salaries: updatedSalaries });
  };

  const isFormValid =
    leave_state.employee &&
    leave_state.staffId &&
    leave_state.salaries.every(
      (field) =>
        field.month &&
        field.year &&
        field.salary &&
        field.totalWorkDays &&
        field.workDays
    );
  // emekdaslarin siyahisi sorgusu
  const getEmployees = async () => {
    const employeeFilter = [
      {
        column: "status",
        operator: "equalsTo",
        value: "active",
      },
    ];
    try {
      const response = await getAllEmployees(
        "",
        "",
        encodeURIComponent(JSON.stringify(employeeFilter))
      );
      if (response?.data?.employees) {
        let emp = response?.data?.employees
          // ?.filter((e) => e?.status !== "inactive")
          .map((e) => ({
            firstname: e?.firstname,
            lastname: e?.lastname,
            fathername: e?.fathername,
            id: e?._id,
          }));
        set_employee(emp);
      }
    } catch (error) {
      console.log("general statistics", error);
    }
  };

  // get employee datas

  const getEmployeeByID = async (id) => {
    try {
      const response = await api.get(`employee/e/${id}`);
      setCurrentEmployee(response.data);
    } catch (err) {
      err?.response?.data?.errors.map((item) => {
        console.log(item);
        if (item?.message === "Employee not found") {
          return toast.error("Əməkdaş tapılmadı", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      });
    }
  };

  const handleSubmit = async (e) => {
    postAddBaseData(leave_state);
    set_leave_state({
      employee: "",
      staffId: "",
      salaries: [
        { month: "", year: "", salary: "", totalWorkDays: "", workDays: "" },
      ],
    });
    showAccordion(false);
  };

  return (
    // <>
    //   <form>
    //     <div className="d-flex  gap-3">
    //       <div className="w-50 ">
    //         <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
    //           Əməkdaşlar
    //         </InputLabel>

    //         <Autocomplete
    //           sx={{ width: "100%" }}
    //           options={Array.isArray(employee) ? employee : []}
    //           renderInput={(params) => (
    //             <TextField {...params} label="Əməkdaşı seçin" />
    //           )}
    //           getOptionLabel={(option) =>
    //             `${option.firstname} ${option?.lastname} ${option?.fathername}`
    //           }
    //           inputValue={searchValue || ""}
    //           isOptionEqualToValue={(option, value) => option?.id === value?.id}
    //           onInputChange={(event, newInputValue) => {
    //             setSearchValue(newInputValue);
    //           }}
    //           onChange={(e, newInputValue) => {
    //             setCurrentEmployee(newInputValue);
    //             set_leave_state({
    //               ...leave_state,
    //               employee: newInputValue?.id ? newInputValue.id : "",
    //             });
    //           }}
    //           renderOption={(props, option) => {
    //             return (
    //               <li {...props} key={option.id}>
    //                 {`${option.firstname} ${option.lastname} ${option.fathername}`}
    //               </li>
    //             );
    //           }}
    //         />
    //       </div>
    //       <div className="w-50">
    //         <div>Müqavilə nömrəsi</div>
    //         <div className="mt-2">
    //           <FormControl fullWidth>
    //             <InputLabel id="demo-simple-select-label">
    //               Müqavilə nömrəsi
    //             </InputLabel>
    //             <Select
    //               labelId="demo-simple-select-label"
    //               id="demo-simple-select"
    //               value={leave_state?.staffId}
    //               disabled={!leave_state?.employee}
    //               label="Müqavilə nömrəsi"
    //               placeholder="ok"
    //               onChange={(e) => {
    //                 set_leave_state({
    //                   ...leave_state,
    //                   staffId: e.target.value,
    //                 });
    //               }}
    //             >
    //               {currentEmployee?.staffs &&
    //                 currentEmployee.staffs.map((e) => (
    //                   <MenuItem value={e?._id}>
    //                     {e?.contract?.data?.docNumber}
    //                   </MenuItem>
    //                 ))}
    //             </Select>
    //           </FormControl>
    //         </div>
    //       </div>
    //     </div>
    //     <div>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           flexDirection: "column",
    //           gap: 2,
    //           marginTop: "50px",
    //           marginBottom: "50px",
    //         }}
    //       >
    //         {fields.map((field, index) => (
    //           <Box key={field.id} sx={{ display: "flex", gap: 2 }}>
    //             <div style={{ width: "33%" }}>
    //               <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
    //                 Ay
    //               </InputLabel>
    //               <Select
    //                 value={field.month || "Ay seç"}
    //                 onChange={(e) =>
    //                   handleChange(index, "month", e.target.value)
    //                 }
    //                 displayEmpty
    //                 fullWidth
    //               >
    //                 <MenuItem value="" disabled>
    //                   Ay Seç
    //                 </MenuItem>
    //                 {months.map((month) => (
    //                   <MenuItem key={month.value} value={month.value}>
    //                     {month.label}
    //                   </MenuItem>
    //                 ))}
    //               </Select>
    //             </div>
    //             <div style={{ width: "33%" }}>
    //               <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
    //                 İl
    //               </InputLabel>
    //               <DatePicker
    //                 views={["year"]} // Sadece yıl seçmeye izin ver
    //                 label="İl seç"
    //                 value={field.year}
    //                 onChange={(newValue) =>
    //                   handleChange(index, "year", newValue)
    //                 }
    //                 slotProps={{ textField: { fullWidth: true } }}
    //               />
    //             </div>
    //             <div style={{ width: "33%" }}>
    //               <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
    //                 Maaş
    //               </InputLabel>
    //               <TextField
    //                 type="number"
    //                 label="Maaş"
    //                 value={field.value1}
    //                 onChange={(e) =>
    //                   handleChange(index, "value1", e.target.value)
    //                 }
    //                 fullWidth
    //               />
    //             </div>
    //           </Box>
    //         ))}
    //         <IconButton
    //           onClick={() => handleRemoveField(index)}
    //           color="secondary"
    //         >
    //           <RemoveIcon />
    //         </IconButton>
    //         <IconButton
    //           onClick={handleAddField}
    //           color="primary"
    //           style={{ width: "50px" }}
    //         >
    //           <AddIcon />
    //         </IconButton>
    //       </Box>
    //       <Button
    //         variant="contained"
    //         // disabled={create_btn_disable}
    //         // onClick={handleSubmit}
    //       >
    //         Yarat
    //       </Button>
    //     </div>
    //   </form>
    // </>
    <>
      <form>
        <div className="d-flex gap-3">
          <div className="w-50">
            <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
              Əməkdaşlar
            </InputLabel>
            <Autocomplete
              sx={{ width: "100%" }}
              options={Array.isArray(employee) ? employee : []}
              renderInput={(params) => (
                <TextField {...params} label="Əməkdaşı seçin" />
              )}
              getOptionLabel={(option) =>
                `${option.firstname} ${option?.lastname} ${option?.fathername}`
              }
              inputValue={searchValue || ""}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onInputChange={(event, newInputValue) => {
                setSearchValue(newInputValue);
              }}
              onChange={(e, newInputValue) => {
                setCurrentEmployee(newInputValue);
                set_leave_state({
                  ...leave_state,
                  employee: newInputValue?.id ? newInputValue.id : "",
                });
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {`${option.firstname} ${option.lastname} ${option.fathername}`}
                  </li>
                );
              }}
            />
          </div>
          <div className="w-50">
            <div>Müqavilə nömrəsi</div>
            <div className="mt-2">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Müqavilə nömrəsi
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={leave_state?.staffId}
                  disabled={!leave_state?.employee}
                  label="Müqavilə nömrəsi"
                  onChange={(e) => {
                    set_leave_state({
                      ...leave_state,
                      staffId: e.target.value,
                    });
                  }}
                >
                  {currentEmployee?.staffs &&
                    currentEmployee.staffs.map((e) => (
                      <MenuItem value={e?._id}>
                        {e?.contract?.data?.docNumber}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          {leave_state.salaries.map((field, index) => (
            <Box key={index} sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
              <div style={{ width: "30%" }}>
                <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
                  Ay
                </InputLabel>
                <Select
                  value={field.month || "Ay seç"}
                  onChange={(e) => handleChange(index, "month", e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Ay Seç
                  </MenuItem>
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ width: "30%" }}>
                <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
                  İl
                </InputLabel>
                <DatePicker
                  views={["year"]}
                  label="İl seç"
                  value={field.year ? new Date(field.year, 0, 1) : null}
                  onChange={(newValue) =>
                    handleChange(
                      index,
                      "year",
                      newValue ? newValue.getFullYear() : ""
                    )
                  }
                  disableFuture
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </div>
              <div style={{ width: "30%" }}>
                <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
                  Maaş
                </InputLabel>
                <TextField
                  type="number"
                  label="Maaş"
                  value={field.salary}
                  onChange={(e) =>
                    handleChange(index, "salary", e.target.value)
                  }
                  fullWidth
                />
              </div>
              <IconButton
                onClick={() => handleRemoveField(index)}
                color="secondary"
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  },
                }}
              >
                <RemoveIcon />
              </IconButton>
              <div style={{ width: "30%", marginTop: "30px" }}>
                <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
                  Ümumi gün sayı
                </InputLabel>
                <TextField
                  type="number"
                  label="Ümumi gün sayı"
                  value={field.totalWorkDays}
                  onChange={(e) =>
                    handleChange(index, "totalWorkDays", e.target.value)
                  }
                  fullWidth
                />
              </div>
              <div style={{ width: "30%", marginTop: "30px" }}>
                <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
                  Gün sayı
                </InputLabel>
                <TextField
                  type="number"
                  label="Gün sayı"
                  value={field.workDays}
                  onChange={(e) =>
                    handleChange(index, "workDays", e.target.value)
                  }
                  fullWidth
                />
              </div>
            </Box>
          ))}

          <IconButton
            onClick={handleAddField}
            color="primary"
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>

        <Button
          variant="contained"
          disabled={!isFormValid}
          onClick={handleSubmit}
        >
          Yarat
        </Button>
      </form>
    </>
  );
}
