import {
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React from "react";

export const OverTimeFormTop = ({
  errors,
  values,
  touched,
  overtimeMockData,
}) => {
  return (
    <Grid2 sx={{ display: "flex", gap: "10px" }}>
      <Grid2 sx={{ width: "33%" }}>
        <FormControl
          fullWidth
          error={touched?.type && errors?.type && Boolean(errors?.type)}
        >
          <InputLabel id={`type`}>Növ</InputLabel>
          <Field
            as={Select}
            name={`type`}
            id={`type`}
            labelId={`type`}
            label="Növ"
            defaultValue=""
            fullWidth
            error={touched?.type && errors?.type && Boolean(errors?.type)}
          >
            {overtimeMockData?.[0]?.overtimeTypes.map((type, index) => {
              return <MenuItem value={type?.value}>{type?.label}</MenuItem>;
            })}
          </Field>
          <ErrorMessage
            name={`type`}
            component="div"
            style={{ color: "red", fontSize: "12px" }}
          />
        </FormControl>
      </Grid2>
      <Grid2 sx={{ width: "33%" }}>
        <FormControl
          fullWidth
          error={errors?.contractItem && touched?.contractItem}
        >
          <InputLabel id={`contractItem`}>Əmək məcəlləsinin maddəsi</InputLabel>
          <Field
            fullWidth
            as={Select}
            defaultValue=""
            disabled={!values?.type}
            label="Əmək məcəlləsinin maddəsi"
            id={`contractItem`}
            name={`contractItem`}
            labelId={`contractItem`}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: "100px",
                  maxHeight: 200,
                  overflowY: "auto",
                },
              },
            }}
            error={
              errors?.contractItem &&
              touched?.contractItem &&
              Boolean(errors?.contractItem)
            }
          >
            {values?.type &&
              overtimeMockData?.[0]?.contractItemTypes?.[values?.type].map(
                (item, contIndex) => (
                  <MenuItem
                    key={contIndex}
                    value={item?.value}
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <Tooltip title={item?.label} followCursor>
                      {item?.label}
                    </Tooltip>
                  </MenuItem>
                )
              )}
          </Field>

          {errors?.contractItem && touched?.contractItem && (
            <ErrorMessage
              name={`contractItem`}
              component="div"
              style={{
                color: "red",
                fontSize: "12px",
              }}
            />
          )}
        </FormControl>
      </Grid2>
      <Grid2 sx={{ width: "33%" }}>
        <Field
          type="text"
          name={`reason`}
          as={TextField}
          disabled={!values?.type}
          error={touched?.reason && Boolean(errors?.reason)}
          helperText={touched?.reason && errors?.reason}
          fullWidth
          label="Səbəb"
        />
      </Grid2>
    </Grid2>
  );
};
