// NOTE!!!
// PLEASE CONSIDER ALL COMMENTS BELOW
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  exclusionOrderGenerator,
  structureOrderGenerator,
} from "./generatorFields/structureRequestOrder";
import { structureServiceGenerator } from "./generatorFields/structureServiceGenerator";
import { labourContractChangeGenerator } from "./generatorFields/labourContractChangeGenerator";
import { useSelector } from "react-redux";
import { COLORS_STATUS, REQUESTS_STATUS_TYPE } from "../../../constants";
import { contractHandlers, translationMap } from "./helpers";
import { useLocation } from "react-router-dom";
import moment from "moment";

const useDocumentDialogHook = (
  isShow,
  fetchHandler,
  // navList = [],
  id,
  loading,
  modalStatus,
  disableSigning,
  refreshPage,
  onClose,
  btnGroupLocationInApp
) => {
  const location = useLocation().pathname;
  const { currentEmployee } = useSelector((state) => state.commonThings);
  const [contractData, setContractData] = useState();
  const [fields, setFields] = useState([]);
  const [comments, setComments] = useState([]);
  const [status, setStatus] = useState(null);
  const [disableSign, setDisableSign] = useState(disableSigning);
  const [navList, setNavList] = useState();
  const [isGroupFields, setIsGroupFields] = useState(false);
  const [versionsList, setVersionsList] = useState([]);
  const [currentVersion, setCurrVersion] = useState("");
  const [relatedDocs, setRelatedDocs] = useState([]);
  const [currentNav, set_currentNav] = useState(0);
  const [sections, render_sections] = useState({
    info: true,
    doc: false,
    comment: false,
  });
  const [commmenDialog, renderCommmenDialog] = useState({
    type: null,
    isShow: false,
  });

  useEffect(() => {
    // setIsLoading(true);
    // documentId ? setData(documentId) : setData(navList?.[0]?.value);
    if (isShow) {
      id ? getData(id, fetchHandler) : getData(navList?.[0]?.value);
    }
  }, [isShow, id, fetchHandler]);

  useEffect(() => {
    // if (navList && navList?.length > 0) {

    // setRelatedDocs(navList);
    const indexxx = relatedDocs?.findIndex(
      (item) => item?.value === contractData?._id
    );
    set_currentNav(indexxx);
    // }
  }, [navList, contractData]);

  // const navListGenerators = {
  //   "/signing": generateNavListForDocSign,
  //   "/approver-vacation-requests": generateNavListforSystemApprovements,
  // }; // this definition shows that in which page generators will be used.

  // const generateNavList = (location, data) => {
  //   const generator = navListGenerators[location];
  //   if (generator) {
  //     generator(data);
  //   }
  // };

  const getData = async (_id, fetchHandler) => {
    try {
      loading?.setIsLoading(true);
      const { data } = await fetchHandler(_id, true, modalStatus);
      if (data) {
        setStatus(data?.status);
        // generateNavList(location, data);
        if (location === "/approver-vacation-requests") {
          generateNavListforSystemApprovements(data);
          generateComments(data);
          generateStatus(data);
        } else {
          generateNavListForDocSign(data);
          generateCommentsforDocSign(data);
          generateStatusDocSign(data);
        }
        setContractData(data);
        setIsGroupFields(true);
        generateFields(data);
        generateVersions(data);
        loading?.setIsLoading(false);
      }
    } catch (error) {
      error?.response?.data?.errors.map((item) => {
        let message = item.message;
        if (message === "You don't have enough privilege for view") {
          message = "Sizin bu hissəyə daxil olmaq üçün müvafiq icazəniz yoxdur";
        }
        return toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    } finally {
      loading?.setIsLoading(false);
    }
  };

  const generateVersions = (data) => {
    if (versionsList?.length > 0) return;

    const list = [];
    const versions = data.oldVersions;

    if (versions?.length === 0) {
      list.push({
        label: "1",
        value: id,
      });
    } else {
      versions?.map((item, index) => {
        list.push({
          label: `${index + 1}`,
          value: item.contract._id,
        });
      });

      list.push({
        label: `${versions?.length + 1}`,
        value: id,
      });
    }
    setCurrVersion(list[list.length - 1].value);
    setVersionsList(list);
  };
  const generateCommentsforDocSign = (data) => {
    const verifiers = data?.verifiers;
    const _comments = [];
    const labelMsg = `Şərh: Mən `;

    const status = (status) => {
      switch (status) {
        case "approved":
          return "təsdiq edirəm";
        case "rejected":
          return "imtina edirəm";
        case ("pending", "draft"):
          return "qərar gözlənilir";
        default:
          return "...";
      }
    };

    if (verifiers?.length === 0) return;
    // eslint-disable-next-line array-callback-return
    verifiers?.map((item) => {
      // eslint-disable-next-line array-callback-return
      if (item.status === "pending") return;

      const employeeName = `${item.employee.firstname} ${item.employee.lastname}`;
      const positionName = item.employee?.position?.name
        ? ` (${item.employee.position.name})`
        : "";
      const label = `${labelMsg}${employeeName}${positionName} ${status(
        item.status
      )}`;

      _comments.push({
        label: label,
        value: item.description,
      });
    });
    // return _comments;
    setComments([..._comments]);
  };

  const generateComments = (data) => {
    if (data?.docModel === "StructureRequest") {
      generateCommentsOfStructureReq(data);
      return;
    }

    const arr = [];
    const status = (status) => {
      switch (status) {
        case "approved":
          return "təsdiq edirəm";
        case "rejected":
          return "imtina edirəm";
        default:
          return "";
      }
    };
    const msg = "Şərh: ";

    data.approvementChain?.forEach((approver) => {
      const { firstname, lastname, position } = approver?.staff?.employee[0];
      if (approver.status === "pending") return;

      arr.push({
        label: `${msg}${firstname} ${lastname}${
          position?.name ? ` (${position.name})` : ""
        } - ${status(approver?.status)}`,
        value: approver.description,
      });
    });

    if (data.hrNotes?.length > 0) {
      data.hrNotes.map((item) => {
        arr.push({
          label: msg + "Yönləndirilmə səbəbi",
          value: item.description,
        });
      });
    }

    setComments([...arr]);
  };

  const generateCommentsOfStructureReq = (data) => {
    const arr = [];

    // data?.contract?.verifiers?.map((item) =>
    //   arr.push({
    //     label: `Təsdiqləyici -${" "}
    //     ${item?.employee?.firstname + " " + item?.employee?.lastname}`,
    //     value: item?.description,
    //   })
    // );

    data?.approvementChain?.map(
      (item) =>
        item.status !== "pending" &&
        arr.push({
          label: `Təsdiqləyici -${" "}
        ${
          item?.staff.employee?.firstname + " " + item?.staff.employee?.lastname
        }`,
          value: item?.description,
        })
    );

    const messageOwner =
      data.originalRequest?.creatorId?.firstname +
      " " +
      data.originalRequest?.creatorId?.lastname;

    data?.originalRequest?.message?.map((item) =>
      arr.push({
        label: `${messageOwner}: Struktur - ${" "}
        ${item?.key}`,
        value: item.value,
      })
    );

    setComments([...arr]);
  };

  const generateStatusDocSign = (data) => {
    // check verifier logged and status
    checkVerifierStatus(data);
    setStatus(data.status);
  };

  const generateStatus = (data) => {
    const isRejected = isRejectedHandler(data);
    if (isRejected) {
      setStatus(isRejected);
      return;
    }

    const loggedStatus = checkLoggedUser(data);
    if (loggedStatus) {
      if (loggedStatus === "reRequested") {
        setStatus(data.status);
        return;
      }
      setStatus(loggedStatus);
    } else {
      setStatus(data.status);
    }
  };

  const generateNavListForDocSign = (data) => {
    const list = [];

    if (navList && navList.length > 0) {
      setRelatedDocs(navList);
      return;
    }

    data?.relatedContracts.map((item, index) => {
      item.docId === id && set_currentNav(index);
      const obj = {
        label: normalizeDocName(item.contractType),
        value: item.docId,
      };
      // item.docId === data._id ? list.unshift(obj) :
      list.push(obj);
    });
    list.length > 1 && setRelatedDocs(list);
  };

  const generateNavListforSystemApprovements = (data) => {
    if (data?.docModel === "EmploymentRequest") {
      const navList = [
        {
          label: "Sorğu",
          value: 0,
        },
        { label: "Namizəd haqqında", value: data.originalRequest.employee.id },
      ];
      setNavList(navList);
    }
  };

  const checkLoggedUser = (data) => {
    const isIt = data?.approvementChain?.find(
      (item) => item.staff.employee[0].id === currentEmployee.id
    );

    if (isIt) return isIt.status;
    else return false;
  };

  const isRejectedHandler = (data) => {
    const isRejected = data?.approvementChain?.find(
      (item) => item.status === "rejected"
    );
    if (isRejected) return isRejected.status;
    else return false;
  };
  const checkVerifierStatus = (data) => {
    // signing buttons show only pending contract
    if (data.status !== "pending") {
      setDisableSign(true);
      return;
    }

    // logged user has on signers
    const founded = data.verifiers?.find(
      (item) => item.employee.id === currentEmployee._id
    );

    // if will not
    if (!founded || founded.status !== "pending") {
      setDisableSign(true);
      return;
    }

    // if (data.selfSign) {
    //   setDisableSign(true);
    //   return;
    // }

    setDisableSign(false);
  };
  const navHandler = async (id) => {
    setFields([]);
    setComments();
    setStatus();
    setDisableSign(true);
    await getData(id, fetchHandler);
  };
  const versionHandler = async (id) => {
    setFields([]);
    setComments();
    setStatus();
    setDisableSign(true);
    setCurrVersion(id);
    await getData(id, fetchHandler);
  };
  const generateFields = (data) => {
    // if (data.contractType.name === "structure-request-service") {
    //   const arr = loopingFields(data.data);
    //   const subjectsField = findSubjectsFields(data.subjects);
    //   const generalDetails = findGeneralDetails(data);
    //   arr.unshift(subjectsField, ...generalDetails);
    //   setFields([...arr]);
    //   return;
    // }
    if (location === "/approver-vacation-requests") {
      setFields(data.data);
    } else {
      if (data.contract?.contractType.type === "application") {
        setFields(data?.contract?.data);
        return;
      }
      if (data.contract?.contractType.name === "structure-request-service") {
        setFields(data?.data);
        return;
      }
      if (data.contractType.name === "structure-request-order") {
        const fields = structureOrderGenerator(data);
        setFields(fields);
        return;
      }
      if (data.contractType.name === "structure-request-service") {
        // const fields = structureServiceGenerator(data);
        setFields(data?.data);
        return;
      }
      if (data.contractType.name === "labourContractUpdate") {
        // const fields = labourContractChangeGenerator(data);
        setFields(data?.data);
        return;
      }
      if (data.contractType.name === "exclusion-order") {
        const fields = exclusionOrderGenerator(data);
        setFields(fields);
        return;
      }
      if (data.contractType.name === "labour-contract") {
        const fields = labourContractChangeGenerator(data);
        setFields(fields);
        return;
      }
      if (data.contractType.name === "business-trip-order") {
        // const fields = exclusionOrderGenerator(data);
        setFields(data?.data);
        return;
      }
      if (data.contractType.name === "vacation-application") {
        const fields = exclusionOrderGenerator(data);
        setFields(fields);
        return;
      }

      if (data.contractType.type === "order") {
        const fields = exclusionOrderGenerator(data);
        setFields(fields);
        return;
      }
      if (data.contractType.type === "agreement") {
        const fields = exclusionOrderGenerator(data);
        setFields(fields);
        return;
      }
    }
  };

  const generateBgColor = () => {
    if (status) {
      switch (status) {
        case "draft":
        case "pending":
          return COLORS_STATUS.BLUE;
        case "approved":
          return COLORS_STATUS.GREEN;
        case "rejected":
          return COLORS_STATUS.RED;
        case "pendingUpdate":
          return COLORS_STATUS.BLUE;
        case "updated":
          return COLORS_STATUS.GREEN;
        case REQUESTS_STATUS_TYPE.ReRequestedToCreator:
          return COLORS_STATUS.RED;
        case REQUESTS_STATUS_TYPE.CancelledByHR:
          return COLORS_STATUS.RED;
        case REQUESTS_STATUS_TYPE.PendingChainApprove:
          return COLORS_STATUS.BLUE;
        case REQUESTS_STATUS_TYPE.PendingOrder:
          return COLORS_STATUS.BLUE;
        case REQUESTS_STATUS_TYPE.PendingOrderApprove: // ????????????????????
          return COLORS_STATUS.GREEN;
        case REQUESTS_STATUS_TYPE.ReRequestedToManager:
          return COLORS_STATUS.ORANGE;
        case REQUESTS_STATUS_TYPE.RejectedByChainUser:
          return COLORS_STATUS.RED;
        case REQUESTS_STATUS_TYPE.UpdatedByCreator:
          return COLORS_STATUS.RED;
        default:
          return COLORS_STATUS.GREY;
      }
    }
  };
  const generatedHeader = () => {
    const docType = contractHandlers?.findDocType(
      contractData?.contractType?.type
    );
    switch (status) {
      case "updated":
        return `Yenilənmiş ${docType}`;
      case "draft":
        return `Təsdiq gözləyən ${docType}`;
      case "pending":
        return `İmza gözləyən ${docType}`;
      case "approved":
        return `Qüvvədə olan ${docType}`;
      case "pendingUpdate":
        return `İmza gözləyən dəyişiklik edilmiş ${docType}`;
      case "rejected":
        return `İmtina edilmiş ${docType}`;
      default:
        return "Sorğu";
    }
  };
  const generatedHeaderForSysApprovement = () => {
    switch (status) {
      case "pending":
        return "Təsdiq gözləyən sorğu";
      case "approved":
        return "Təsdiqlənmiş sorğu";
      case "rejected":
        return "İmtina edilmiş sorğu";
      case REQUESTS_STATUS_TYPE.ReRequestedToCreator:
        return "Əməkdaşa yönləndirilmiş sorğu";
      case REQUESTS_STATUS_TYPE.CancelledByHR:
        return "HR tərəfindən imtina edilmiş";
      case REQUESTS_STATUS_TYPE.PendingChainApprove:
        return "Təsdiq gözləyən sorğu";
      case REQUESTS_STATUS_TYPE.PendingOrder:
        return "Sorğu ilə bağlı əmr gözlənilir";
      case REQUESTS_STATUS_TYPE.PendingOrderApprove:
        return "İmza gözləyən əmr"; // ??????????
      case REQUESTS_STATUS_TYPE.ReRequestedToManager:
        return "Yenidən yönləndirilmiş sorğu";
      case REQUESTS_STATUS_TYPE.RejectedByChainUser:
        return "İmtina edilmiş sorğu";
      case REQUESTS_STATUS_TYPE.UpdatedByCreator:
        return "Əməkdaş tərəfindən yenilənmiş sorğu";
      default:
        return "Sorğu";
    }
  };

  const docNumberHandler = () => {
    const docNo = contractData
      ? validateValue(
          contractData?.data?.contract_general_info?.map((item) => {
            if (item.key === "docNumber") return item.value;
          })
        )
      : validateValue(
          contractData?.data?.document_general_info?.map((item) => {
            if (item.key === "docNumber") {
              return item.value;
            }
          })
        );
    return docNo;
  };
  function renderValue(item, dataVersionIdentifier) {
    let value;
    if (item?.value === "") return "---";
    if (item?.key === "verifiers") {
      if (item?.value?.some((item) => item?.date)) {
        value = moment(item?.date)?.format("DD/mm/yyyy");
      } else {
        value = "---";
      }
    }

    if (Array.isArray(item?.value)) {
      if (item?.key !== "verifiers") {
        value = item?.value?.map((item) => {
          if (typeof item === "object") {
            return item?.value;
          } else {
            return item;
          }
        });
      }
    } else {
      switch (item?.type) {
        case "date":
          value = moment(item?.value)?.format("DD/MM/YYYY");
          break;
        case "object":
          value =
            dataVersionIdentifier === "old"
              ? item?.value?.old
              : dataVersionIdentifier === "new"
              ? item?.value?.new
              : item?.value;
          if (item?.key === "wageBonus") {
            if (dataVersionIdentifier === "old") {
              value = `${item?.value?.old?.value} ${
                wageBonus[item?.value?.old?.type] // backend_instance = item: {value: {old: {type: "manat": value: "..."}}, new: {type: "manat": value: "..."}}}
              }`;
            } else if (dataVersionIdentifier === "new") {
              value = `${item?.value?.new?.value} ${
                wageBonus[item?.value?.new?.type] // backend_instance = item: {value: {old: {type: "manat": value: "..."}}, new: {type: "manat": value: "..."}}}
              }`;
            } else {
              value = `${item?.value?.value} ${
                wageBonus[item?.value.type] // backend_instance = item: {value: {type: "manat": value: "..."}}
              }`;
            }
          }
          if (item?.key === "probationPeriod") {
            if (dataVersionIdentifier === "old") {
              value = `${item?.value?.old?.value} ${
                probationPeriod[item?.value?.old?.type] // backend_instance = item: {value: {old: {type: "day": value: "..."}}, new: {type: "day": value: "..."}}}
              }`;
            } else if (dataVersionIdentifier === "new") {
              value = `${item?.value?.new?.value} ${
                probationPeriod[item?.value?.new?.type] // backend_instance = item: {value: {old: {type: "day": value: "..."}}, new: {type: "day": value: "..."}}}
              }`;
            } else {
              value = `${item?.value?.value} ${
                probationPeriod[item?.value.type] // backend_instance = item: {value: {type: "day": value: "..."}}
              }`;
            }
          }
          if (item?.key === "workingCondition") {
            value =
              dataVersionIdentifier === "old"
                ? item?.value?.old?.name
                : dataVersionIdentifier === "new"
                ? item?.value?.new?.name
                : item?.value?.name;
          }
          break;
        case "number":
          if (item?.key === "probationRefusalTime") {
            value = `${item?.value?.value} ${
              item?.value.type === "day"
                ? "gün"
                : item?.value.type === "month"
                ? "ay"
                : "il"
            }`;
          } else {
            value =
              dataVersionIdentifier === "old"
                ? item?.value?.old
                : dataVersionIdentifier === "new"
                ? item?.value?.new
                : item?.value;
            if (item?.key === "wage") {
              value = `${value} azn`;
            }
          }
          break;
        default:
          value =
            dataVersionIdentifier === "old"
              ? item?.value?.old
              : dataVersionIdentifier === "new"
              ? item?.value?.new
              : item?.value;
          break;
      }
    }
    let v = translationMap[value] ? translationMap[value] : value;
    return !value || value?.length === 0
      ? "---"
      : Array.isArray(value)
      ? value.join(",")
      : v;
  }
  return {
    contractData,
    setContractData,
    fields,
    status,
    relatedDocs,
    currentNav,
    set_currentNav,
    setRelatedDocs,
    versionHandler,
    navHandler,
    render_sections,
    sections,
    docNumberHandler,
    generateBgColor,
    generatedHeader:
      location === "/approver-vacation-requests"
        ? generatedHeaderForSysApprovement
        : generatedHeader,
    renderCommmenDialog,
    commmenDialog,
    comments,
    getData,
    styles,
    probationPeriod,
    wageBonus,
    renderValue,
    versionsList,
  };
};

export default useDocumentDialogHook;

const normalizeDocName = (key) => {
  switch (key) {
    case "employment-application":
      return "Ərizə";
    case "labour-contract":
      return "Müqavilə";
    case "labour-order":
      return "Əmr";
    case "vacation-order":
      return "Məzuniyyət əmri";
    case "vacation-application":
      return "Məzuniyyət ərizəsi";
    case "labour-termination-order":
      return "Xitam əmri";
    default:
      return key;
  }
};

const validateValue = (value) => {
  return value && value.length > 0 ? value : "";
};

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // height: "100%",
  },
  docCont: {
    // height: "350px",
    position: "relative",
  },
  doc: {
    width: "100%",
    height: "476px",
    backgroundColor: "#fff",
  },
  downBtnCont: {
    width: "100%",
    marginTop: "10px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
  },
  downloadBtn: {
    margin: "auto",
    width: "100%",
    backgroundColor: "#d9d9d9",
    borderRadius: 1,
    color: "rgba(0,0,0,0.7)",
    "&:hover": {
      backgroundColor: "grey",
      color: "#fff",
    },
  },
  commentCont: {
    width: "100%",
    paddingX: "20px",
    overflow: "hidden",
    height: "100%",
    overflow: "auto",
    marginTop: "5px",
  },
  commentContainer: { width: "100%", marginTop: 5 },
  input: {
    backgroundColor: "#FFF",
    color: "#000",
    paddingY: 5,
    paddingX: "40px",
    width: "100%",
    borderRadius: 3,
    lineHeight: "24px",
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "90vh",
    // padding: "5px",
    width: "100%",
    maxWidth: "1000px",
    overflow: "auto",
    zIndex: 999,
  },
  loadingContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    padding: "5px 0px",
  },
};

const probationPeriod = {
  month: "ay",
  day: "gün",
  year: "il",
  none: "yoxdur",
};

const wageBonus = {
  manat: "azn",
  perctentage: "faiz",
};
