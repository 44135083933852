import { handleApiCall } from "./apiHandler";
import {
  deleteRole_API,
  editRole_API,
  getModuleTree_API,
  getPermissionByModule_API,
  getRoles_API,
  postRole_API,
} from "../api/role_permissionRequests";

// ⇩ ⇩ ⇩ Roles & Permissions Services ⇩ ⇩ ⇩
export const createRole_SERVICE = async (...payload) =>
  handleApiCall(postRole_API, ...payload);

export const deleteRole_SERVICE = async (id) =>
  handleApiCall(deleteRole_API, id);

export const editRole_SERVICE = async (...payload) =>
  handleApiCall(editRole_API, ...payload);

// export const getRole_SERVICE = async (id) => handleApiCall(getRole_API, id);

export const getRoles_SERVICE = async (...payload) =>
  handleApiCall(getRoles_API, ...payload);

export const getModuleTree_SERVICE = async (...payload) =>
  handleApiCall(getModuleTree_API, ...payload);

export const getPermissionByModule_SERVICE = async (...payload) =>
  handleApiCall(getPermissionByModule_API, ...payload);

// ----------------------------------------------------------
// export const registerAccount_SERVICE = async (...payload) =>
//   handleApiCall(registerUserAccount_API, ...payload);

// export const getAccounts_SERVICE = async (id) =>
//   handleApiCall(getAccountsByUserId_API, id);

// export const deleteAccount_SERVICE = async (id) =>
//   handleApiCall(deleteAccount_API, id);

// ⇧ ⇧ ⇧ Roles & Permissions Services ⇧ ⇧ ⇧
