import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Slices
import templateReducer from "../features/slices/template.slice";
import structureNameReducer from "../features/slices/structureNaming.slice";
import structureReducer from "../features/slices/structure.slice";

import commonThingsSlice from "../features/slices/commonThings.slice";
import nationalitySlice from "../features/slices/nationality.slice";
import employeeSlice from "../features/slices/employee.slice";
import AuthSlice from "../features/slices/Auth.slice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["structureName", "nationality", "emp"],
};

export const rootReducer = combineReducers({
  emp: employeeSlice,
  template: templateReducer,
  structureName: structureNameReducer,
  structure: structureReducer,
  commonThings: commonThingsSlice,
  nationality: nationalitySlice,
  auth: AuthSlice,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);
