import * as Yup from "yup";
import Column from "../../models/table/Column";
import {
  BUSINESS_TRIP_STATUS_FOR_FILTER,
  clauseValue,
  OPERATORS_CONTAINING,
  OVERTIME_STATUS_FOR_FILTER,
  overTimeType,
  paymentType,
} from "../../constants";
import moment from "moment";
import { Tooltip } from "@mui/material";
import StatusGenerator from "../../components/table/StatusGenerator";
import { getStaticFilterValues } from "../../utils/forFilterDropdown/getStructureUtils";
import React from "react";

export const overTimeValidationSchema = Yup.object().shape({
  type: Yup.string().required("Növ seçilməyib"),
  contractItem: Yup.string()
    .required("Əmək məcəlləsinin maddəsi zəruridir")
    .min(1, "Əmək məcəlləsinin maddəsi seçilməlidir"),
  reason: Yup.string().max(200, "Səbəb maksimum 200 simvol olmalıdır"),
  overtime: Yup.array().of(
    Yup.object().shape({
      employee: Yup.string().required("Əməkdaş seçimi zəruridir"),
      contract: Yup.string().required("Müqavilə seçilməyib."),
      footer: Yup.array().of(
        Yup.object().shape({
          date: Yup.date()
            .required("Tarix seçimi zəruridir")
            .typeError("Tarix etibarlı formatda olmalıdır"),
          hour: Yup.number()
            .typeError("Saat rəqəm olmalıdır")
            .required("Saat daxil edilməlidir")
            .positive("Saat müsbət olmalıdır"),
        })
      ),
    })
  ),
});
export const overTimePersonalValidationSchema = Yup.object().shape({
  type: Yup.string().required("Növ seçilməyib"),
  contractItem: Yup.string()
    .required("Əmək məcəlləsinin maddəsi zəruridir")
    .min(1, "Əmək məcəlləsinin maddəsi seçilməlidir"),
  reason: Yup.string().max(200, "Səbəb maksimum 200 simvol olmalıdır"),
  contract: Yup.string().required("Müqavilə seçilməyib."),
  footer: Yup.array().of(
    Yup.object().shape({
      date: Yup.date()
        .required("Tarix seçimi zəruridir")
        .typeError("Tarix etibarlı formatda olmalıdır"),
      hour: Yup.number()
        .typeError("Saat rəqəm olmalıdır")
        .required("Saat daxil edilməlidir")
        .positive("Saat müsbət olmalıdır"),
    })
  ),
});

const translate = {
  Extended: "İş vaxtından artıq işə çıxma",
  Non_Working_Day: "Qeyri iş günlərində işə çıxma",
  Double: "İkiqat",
  Substitute_Day: "Əvəzgün",
  State_Defense:
    "Maddə 101 a. dövlətin müdafiəsinin təmin olunması üçün, habelə təbii fəlakətin, istehsal qəzasının qarşısını almaq və ya onların nəticələrini aradan qaldırmaq üçün yerinə yetirilməsi zəruri olan ən vacib işlərin görülməsinə",
  Utility_Service_Continuity:
    "Maddə 101 b. su, qaz və elektrik təchizatı, isitmə, kanalizasiya, rabitə və digər kommunal müəssisələrində işlərin, xidmətlərin pozulmasına səbəb olan gözlənilməz hadisələrin nəticələrini aradan qaldırmaq üçün zəruri işlərin görülməsini təmin etmək üçün;",
  Unfinished_Technical_Work:
    "Maddə 101 c. başlanmış və istehsalın texniki şəraitinə görə iş gününün sonunadək tamamlana bilməyən işlərin dayandırılması avadanlıqların, əmtəələrin qarşısıalınmaz korlanması, sıradan çıxması təhlükəsi zamanı işlərin tamamlanması zəruriyyəti olduqda;",
  Critical_Repair_Work:
    "Maddə 101 ç. işçilərin əksəriyyətinin işinin dayandırılmasına səbəb olan sıradan çıxmış mexanizmlərin, qurğuların təmiri, bərpası ilə əlaqədar işlərin görülməsi zərurəti olduqda;",
  Substitute_Employee_Absence:
    "Maddə 101 d. əvəz edən işçinin işdə olmaması ilə əlaqədar işə fasilə verilməsinə yol vermək mümkün olmadıqda.",
  Continuous_Production:
    "Maddə 107 Fasiləsiz istehsalatlar, ticarət, ictimai iaşə, rabitə, nəqliyyat və digər xidmət müəssisələri",
};

export const overtimeColumns = (operatorColumnGenerator) => {
  return [
    new Column({
      headerName: "Operator",
      disableColSearch: true,
      path: "#",
      renderCell: operatorColumnGenerator,
    }),
    new Column({
      path: "employee.firstname",
      headerName: "Əməkdaşın adı",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row) => {
        return (
          row.employeeApplications &&
          row.employeeApplications.map((emp, index) => {
            return (
              <tr
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
                key={emp?._id}
              >
                <td className="specialText">{emp?.employee?.firstname}</td>
              </tr>
            );
          })
        );
      },
    }),
    new Column({
      path: "employee.lastname",
      headerName: "Əməkdaşın soyadı",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row) => {
        return (
          row.employeeApplications &&
          row.employeeApplications.map((emp, index) => {
            return (
              <tr
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
                key={emp?._id}
              >
                <td className="specialText">{emp?.employee?.lastname}</td>
              </tr>
            );
          })
        );
      },
    }),
    new Column({
      path: "employee.fathername",
      headerName: "Əməkdaşın ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row) => {
        return (
          row.employeeApplications &&
          row.employeeApplications.map((emp, index) => {
            return (
              <tr
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
                key={emp?._id}
              >
                <td className="specialText">{emp?.employee?.fathername}</td>
              </tr>
            );
          })
        );
      },
    }),
    new Column({
      path: "originalApplication.contract.data.docNumber",
      headerName: "Əmək müqaviləsinin nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row) => {
        return (
          row.employeeApplications &&
          row.employeeApplications.map((emp, index) => {
            return (
              <tr
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
                key={emp?._id}
              >
                <td className="specialText">{emp?.contract?.docNumber}</td>
              </tr>
            );
          })
        );
      },
    }),
    new Column({
      path: "originalApplication.contract.baseStructures._id",
      headerName: "Struktur",
      filterType: OPERATORS_CONTAINING.IN,
      filterColumnKey: "originalApplication.contract.baseStructures._id",
      dataGridName: "overtime-application",
      fetchFilterValuesV2: getStaticFilterValues,
      keyForResponseDataFromFilter: "baseStructures",
      renderCell: (row) => {
        return (
          row.employeeApplications &&
          row.employeeApplications.map((emp, index) => {
            return (
              <tr
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
                key={emp?._id}
              >
                <td className="specialText">
                  {emp?.contract?.baseStructures?.organization?.[0]?.name}
                </td>
              </tr>
            );
          })
        );
      },
    }),
    new Column({
      path: "originalApplication.contract.subStructures._id",
      headerName: "Sub-struktur",
      dataGridName: "overtime-application",
      filterColumnKey: "originalApplication.contract.subStructures._id",
      fetchFilterValuesV2: getStaticFilterValues,
      dependencieKeysOfFilter: {
        structures: ["baseStructure._id"],
        topPartOfStructures: ["topPartOfStructure._id"],
      },
      keyForResponseDataFromFilter: "subStructures",
      filterType: OPERATORS_CONTAINING.IN,
      renderCell: (row) => {
        return (
          row.employeeApplications &&
          row.employeeApplications.map((emp, index) => {
            return (
              <tr
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
                key={emp?._id}
              >
                <td className="specialText">
                  {emp?.contract?.subStructures?.source?.[0]?.name}
                </td>
              </tr>
            );
          })
        );
      },
    }),
    new Column({
      path: "originalApplication.contract.positions._id",
      headerName: "Vəzifə",
      dataGridName: "overtime-application",
      filterColumnKey: "originalApplication.contract.positions._id",
      fetchFilterValuesV2: getStaticFilterValues,
      dependencieKeysOfFilter: {
        structures: ["baseStructure._id"],
        topPartOfStructures: ["topPartOfStructure._id"],
      },
      keyForResponseDataFromFilter: ".positions",
      filterType: OPERATORS_CONTAINING.IN,
      renderCell: (row) => {
        return (
          row.employeeApplications &&
          row.employeeApplications.map((emp, index) => {
            return (
              <tr
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
                key={emp?._id}
              >
                <td className="specialText">
                  {emp?.contract?.positions?.source?.[0]?.name}
                </td>
              </tr>
            );
          })
        );
      },
    }),
    new Column({
      path: "originalApplication.workShifts.date",
      headerName: "Tarix(lər)",
      filterType: OPERATORS_CONTAINING.DATE,
      renderCell: (row) => {
        return (
          row.employeeApplications &&
          row.employeeApplications.map((emp, index) => {
            return emp?.workShifts.map((item, i) => {
              return (
                <tr
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                  key={item?._id}
                >
                  <td className="specialText">
                    <p>{moment(item?.date)?.format("DD/MM/YYYY")}</p>
                  </td>
                </tr>
              );
            });
          })
        );
      },
    }),
    new Column({
      path: "originalApplication.workShifts.hours",
      headerName: "Saat(lar)",
      filterType: OPERATORS_CONTAINING.IS_NUMBER,
      renderCell: (row) => {
        return (
          row.employeeApplications &&
          row.employeeApplications.map((emp, index) => {
            return emp?.workShifts.map((item, i) => {
              return (
                <tr
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                  key={item?._id}
                >
                  <td className="specialText">
                    <p>{item?.hours}</p>
                  </td>
                </tr>
              );
            });
          })
        );
      },
    }),
    new Column({
      path: "originalApplication.workShifts.paymentType",
      headerName: "Ödənişin növü",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: paymentType,
      renderCell: (row) => {
        return (
          row.employeeApplications &&
          row.employeeApplications.map((emp, index) => {
            return emp?.workShifts.map((item, i) => {
              return (
                <tr
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                  key={item?._id}
                >
                  <td className="specialText">
                    <p>{translate[item?.paymentType]}</p>
                  </td>
                </tr>
              );
            });
          })
        );
      },
    }),
    new Column({
      path: "originalApplication.type",
      headerName: "İş vaxtından artıq işə çıxmanın növü",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: overTimeType,
      renderCell: (row) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title={translate[row?.type]} followCursor>
              <p className="specialText">{translate[row?.type]}</p>
            </Tooltip>
          </div>
        );
      },
    }),
    new Column({
      path: "originalApplication.clause",
      headerName: "Ə.M. maddəsi",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: clauseValue,
      renderCell: (row) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title={translate[row?.clause]} followCursor>
              <p className="specialText">{translate[row?.clause]}</p>
            </Tooltip>
            <p></p>
          </div>
        );
      },
    }),
    new Column({
      path: "originalApplication.reason",
      headerName: "Səbəb",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title={row?.reason} followCursor>
              <p className="specialText">{row?.reason}</p>
            </Tooltip>
          </div>
        );
      },
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterValues: OVERTIME_STATUS_FOR_FILTER,
      filterType: OPERATORS_CONTAINING.OPTION,
      renderCell: (row, column) => {
        return (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "30px" }}
          >
            {row?.employeeApplications.map((element, index) => {
              return (
                <StatusGenerator
                  // isVerified={isVerified}
                  data={row}
                  approvement={[
                    {
                      index: index + 1,
                      employee: `${element?.employee?.firstname} ${element?.employee?.lastname}`,
                      status: element?.status,
                    },
                  ]}
                  value={element?.status}
                  docType={"overtime"}
                />
              );
            })}
          </div>
        );
      },
    }),
  ];
};

export const overtimeMockData = [
  {
    firstname: "Eli",
    lastname: "Eliyev",
    fathername: "Veli",
    docNumber: "ƏM80000",
    structure: "Blink-BI",
    subStructure: "FE Team",
    position: "Frontend Developer",
    date: "01/01/2025",
    hour: "8",
    type: "İş vaxtından artıq işə çıxma",
    contractItem: "Ə.M. maddə 99.4",
    reason: "Siqaret çəkdiyi üçün",
    paymentType: "2x (2qat)",
    overtimeTypes: [
      {
        label: "1. İş vaxtından artıq işə çıxma",
        value: "Extended",
      },
      {
        label: "2. Qeyri iş günlərində işə çıxma",
        value: "Non_Working_Day",
      },
    ],
    contractItemTypes: {
      Extended: [
        {
          label:
            "Maddə 101 a. dövlətin müdafiəsinin təmin olunması üçün, habelə təbii fəlakətin, istehsal qəzasının qarşısını almaq və ya onların nəticələrini aradan qaldırmaq üçün yerinə yetirilməsi zəruri olan ən vacib işlərin görülməsinə;",
          value: "State_Defense",
        },
        {
          label:
            "Maddə 101 b. su, qaz və elektrik təchizatı, isitmə, kanalizasiya, rabitə və digər kommunal müəssisələrində işlərin, xidmətlərin pozulmasına səbəb olan gözlənilməz hadisələrin nəticələrini aradan qaldırmaq üçün zəruri işlərin görülməsini təmin etmək üçün;",
          value: "Utility_Service_Continuity",
        },
        {
          label:
            "Maddə 101 c. başlanmış və istehsalın texniki şəraitinə görə iş gününün sonunadək tamamlana bilməyən işlərin dayandırılması avadanlıqların, əmtəələrin qarşısıalınmaz korlanması, sıradan çıxması təhlükəsi zamanı işlərin tamamlanması zəruriyyəti olduqda;",
          value: "Unfinished_Technical_Work",
        },
        {
          label:
            "Maddə 101 ç. işçilərin əksəriyyətinin işinin dayandırılmasına səbəb olan sıradan çıxmış mexanizmlərin, qurğuların təmiri, bərpası ilə əlaqədar işlərin görülməsi zərurəti olduqda;",
          value: "Critical_Repair_Work",
        },
        {
          label:
            "Maddə 101 d. əvəz edən işçinin işdə olmaması ilə əlaqədar işə fasilə verilməsinə yol vermək mümkün olmadıqda.",
          value: "Substitute_Employee_Absence",
        },
      ],
      Non_Working_Day: [
        {
          label:
            "Maddə 101 a. dövlətin müdafiəsinin təmin olunması üçün, habelə təbii fəlakətin, istehsal qəzasının qarşısını almaq və ya onların nəticələrini aradan qaldırmaq üçün yerinə yetirilməsi zəruri olan ən vacib işlərin görülməsinə;",
          value: "State_Defense",
        },
        {
          label:
            "Maddə 101 b. su, qaz və elektrik təchizatı, isitmə, kanalizasiya, rabitə və digər kommunal müəssisələrində işlərin, xidmətlərin pozulmasına səbəb olan gözlənilməz hadisələrin nəticələrini aradan qaldırmaq üçün zəruri işlərin görülməsini təmin etmək üçün;",
          value: "Utility_Service_Continuity",
        },
        {
          label:
            "Maddə 107 Fasiləsiz istehsalatlar, ticarət, ictimai iaşə, rabitə, nəqliyyat və digər xidmət müəssisələri",
          value: "Continuous_Production",
        },
      ],
    },
  },
];
