import styled from "styled-components";
import Column from "../../../models/table/Column";
const Td = styled.td`
  padding: 10px;
  margin: 0;
  font-weight: 100;
  cursor: pointer;
`;

export const activeVacationColumns = (setRowData) => {
  return [
    new Column({
      path: "name",
      headerName: "Ad Soyad",
    }),
    new Column({
      path: "fathername",
      headerName: "Ata adı",
    }),
    new Column({
      path: "startdate",
      headerName: "Başlama tarixi",
      rowData: true,
      renderCell: (row, col) => {
        return (
          <Td style={{}} onMouseEnter={() => setRowData(row)}>
            <p style={{ textAlign: "center" }}>{row?.startdate}</p>
          </Td>
        );
      },
    }),
    new Column({
      path: "enddate",
      headerName: "Bitmə tarixi",
      renderCell: (row) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {row?.enddate}
          </div>
        );
      },
    }),
  ];
};
export const planningVacationColumns = (setRowData) => {
  return [
    new Column({
      path: "name",
      headerName: "Ad Soyad",
    }),
    new Column({
      path: "fathername",
      headerName: "Ata adı",
    }),
    new Column({
      path: "startdate",
      headerName: "Başlama tarixi",
      rowData: true,
      renderCell: (row, col) => {
        return (
          <Td style={{}} onMouseEnter={() => setRowData(row)}>
            <p style={{ textAlign: "center" }}>{row?.startdate}</p>
          </Td>
        );
      },
    }),
    new Column({
      path: "enddate",
      headerName: "Bitmə tarixi",
      renderCell: (row) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {row?.enddate}
          </div>
        );
      },
    }),
  ];
};

export const activeVacationRows = [
  {
    id: 1,
    name: "Tariyel Hümbətov",
    fathername: "Telman",
    FIN: "5LI9O6H",
    startdate: "09/03/2025",
    enddate: "15/04/2025",
    content: "ƏQMİ",
    approver: "Mehdi Məmmədzadə",
  },
  {
    id: 2,
    name: "Tariyel Hümbətov",
    fathername: "Telman",
    FIN: "5LI9O6H",
    startdate: "01/07/2026",
    enddate: "04/09/2026",
    content: "Xəstəlik",
    approver: "Mehdi Məmmədzadə",
  },
  {
    id: 3,
    name: "Tariyel Hümbətov",
    fathername: "Telman",
    FIN: "5LI9O6H",
    startdate: "23/10/2027",
    enddate: "30/11/2027",
    content: "Xəstəlik",
    approver: "Mehdi Məmmədzadə",
  },
];
export const planningVacationRows = [
  {
    id: 1,
    name: "Tariyel Hümbətov",
    fathername: "Telman",
    FIN: "5LI9O6H",
    startdate: "09/08/2025",
    enddate: "15/10/2025",
    content: "ƏQMİ",
    approver: "Mehdi Məmmədzadə",
  },
  {
    id: 2,
    name: "Tariyel Hümbətov",
    fathername: "Telman",
    FIN: "5LI9O6H",
    startdate: "01/01/2026",
    enddate: "04/02/2026",
    content: "Xəstəlik",
    approver: "Mehdi Məmmədzadə",
  },
  {
    id: 3,
    name: "Tariyel Hümbətov",
    fathername: "Telman",
    FIN: "5LI9O6H",
    startdate: "23/02/2027",
    enddate: "28/05/2027",
    content: "Xəstəlik",
    approver: "Mehdi Məmmədzadə",
  },
];
