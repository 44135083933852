import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Button,
  ButtonGroup,
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { getAllStructures, setRecruiter } from "../../api/api";
import { getStructureById } from "../../services/structure/StructureServices";
import FindEmployee from "../../components/employees/FindEmployee";
import useDialog from "../../hooks/useDialog";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FullNameFields = ["firstname", "lastname", "fathername"];

function StaffDetails() {
  const [selectedButton, setSelectedButton] = useState(0);
  const [companyData, setCompanyData] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [selectedStructure, setSelectedStructure] = useState(null);

  // ** Find employee
  const employeeDialogHandle = useDialog();
  const [selectedUser, setSelectedUser] = useState();

  // Get company data
  const getCompanyDetails = async (id) => {
    const companyResponse = await getStructureById(id);
    if (companyResponse) {
      setCompanyData(companyResponse);
    }
  };
  // // Get all employees

  //Get Structure Naming
  useEffect(() => {
    const getStructureNames = async () => {
      try {
        const response = await getAllStructures();

        if (response && response.data) {
          setDataTable(response.data.structures);
        }

        getCompanyDetails(response.data.structures[0]._id);
      } catch (err) {
        console.log(err);
      }
    };
    getStructureNames();
  }, []);

  //   Selected structure part
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    const selectedItem = dataTable.find((item, index) => {
      if (index === selectedButton) {
        return item;
      }
    });
    if (selectedItem) {
      setSelectedStructure(selectedItem);
      try {
        getCompanyDetails(selectedItem?._id);
      } catch (err) {
        console.log(err);
      }
    }
  }, [selectedButton, dataTable]);

  const handleChange = (e, index) => {
    setSelectedButton(index);
    setSelectedUser();
  };

  if (!companyData) {
    return "Göstəriləcək şirkət yoxdur";
  }

  const handleSubmit = async () => {
    try {
      const response = await setRecruiter(companyData._id, selectedUser._id);
      if (response && response.data) {
        setCompanyData((prevData) => ({
          ...prevData,
          recruiterStaff: selectedUser,
        }));
        setSelectedUser();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Card mb={6}>
        <ButtonGroup aria-label="outlined primary button group" fullWidth>
          <Grid container>
            {dataTable
              .filter((item) => item.organization?.name)
              .map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={3}>
                  <Button
                    onClick={(e) => handleChange(e, index)}
                    variant={selectedButton === index ? "contained" : "text"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      height: "40px",
                      paddingInline: "16px",
                      textAlign: "left",
                    }}
                  >
                    {item?.organization?.name}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </ButtonGroup>
      </Card>

      <Card mb={6}>
        <CardContent>
          <div className="staffHead">
            <div className="staffBtn">
              <Button
                onClick={employeeDialogHandle.openDialog}
                variant="contained"
              >
                Əməkdaş seç
              </Button>
            </div>
            {companyData?.logo ? (
              <figure>
                <img
                  src={`https://chr.blink-bi.az/api/employee/s/logo/${companyData.logo}`}
                  alt="Staff Pitcure"
                />
              </figure>
            ) : null}
          </div>

          <Grid sx={{ mt: 6 }}>
            <Grid container spacing={6} mb={3}>
              <Grid item md={6}>
                <TextField
                  name="asa"
                  label="A.S.A"
                  fullWidth
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                  value={
                    FullNameFields.every(
                      (key) => selectedUser?.employee[0]?.[key]
                    )
                      ? FullNameFields.map(
                          (key) => selectedUser?.employee[0]?.[key]
                        ).join(" ")
                      : FullNameFields.every(
                          (key) =>
                            companyData?.recruiterStaff?.employee[0]?.[key]
                        )
                      ? FullNameFields.map(
                          (key) =>
                            companyData?.recruiterStaff?.employee[0]?.[key]
                        ).join(" ")
                      : "Göstəriləcək məlumat yoxdur"
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="contactDetails"
                  label="Əlaqə məlumatları"
                  fullWidth
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                  value={
                    selectedUser?.employee[0]?.mobile
                      ? selectedUser?.employee[0]?.mobile[0]
                      : companyData?.recruiterStaff?.employee[0]?.mobile
                      ? companyData?.recruiterStaff?.employee[0]?.mobile[0]
                      : "Göstəriləcək məlumat yoxdur"
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>

            <TextField
              name="email"
              label="Email"
              fullWidth
              onChange={handleChange}
              type="email"
              variant="outlined"
              sx={{ mb: 3 }}
              value={
                selectedUser?.employee[0].email
                  ? selectedUser?.employee[0].email[0]
                  : companyData?.recruiterStaff?.employee[0]?.email
                  ? companyData?.recruiterStaff?.employee[0]?.email[0]
                  : "Göstəriləcək məlumat yoxdur"
              }
              InputProps={{
                readOnly: true,
              }}
            />

            <TextField
              name="position"
              label="Vəzifəsi"
              fullWidth
              onChange={handleChange}
              variant="outlined"
              sx={{ mb: 3 }}
              value={
                selectedUser?.recruiterStaff?.position?.name
                  ? selectedUser?.recruiterStaff?.position?.name
                  : companyData?.recruiterStaff?.position?.name
                  ? companyData?.recruiterStaff?.position?.name
                  : "Göstəriləcək məlumat yoxdur"
              }
              InputProps={{
                readOnly: true,
              }}
            />

            <TextField
              name="education"
              label="Təhsili"
              fullWidth
              onChange={handleChange}
              variant="outlined"
              sx={{ mb: 3 }}
              value={
                selectedUser?.recruiterStaff?.employee[0]?.education[0]?.faculty
                  ? selectedUser?.recruiterStaff?.employee[0]?.education[0]
                      ?.faculty
                  : companyData?.recruiterStaff?.employee[0]?.education[0]
                      ?.faculty
                  ? companyData?.recruiterStaff?.employee[0]?.education[0]
                      ?.faculty
                  : "Göstəriləcək məlumat yoxdur"
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid container justifyContent="flex-end">
            <Button
              onClick={handleSubmit}
              disabled={!selectedUser?._id}
              type="submit"
              variant="contained"
              color="primary"
              mt={3}
            >
              Yadda saxla
            </Button>
          </Grid>
        </CardContent>
      </Card>
      <FindEmployee
        structureId={selectedStructure?._id}
        findEmployeeDialog={employeeDialogHandle.isOpen}
        setFindEmployeeDialog={employeeDialogHandle.setDialog}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
    </>
  );
}

function Blank() {
  return (
    <React.Fragment>
      <ToastContainer />

      <Helmet title="Təşkilat məlumatları" />
      <Typography variant="h3" gutterBottom display="inline">
        İşəgötürənin məlumatları
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <StaffDetails />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Blank;
