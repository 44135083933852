import { useEffect, useState } from "react";
import { getAllEmployees } from "../api/api";
import { handleApiError } from "../api/changeRequests";
import {
  getListOvertimeApplication_SERVICE,
  postBulkOvertimeApplication_SERVICE,
} from "../services/overtime/overtimeServices";
import { Close, Edit, RemoveRedEye } from "@mui/icons-material";
import moment from "moment";
import Operator from "../models/table/Operator";
import OperatorGenerator from "../components/table/OperatorGenerator";
import { removeEmptyStrings } from "../utils/removeEmptyString";

export function useAddOvertime() {
  const [show_Accordion, set_show_Accordion] = useState(false);
  const [isDate, setIsDate] = useState(false);
  const [offset, setOffset] = useState(0);
  const [empData, setEmpData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [data, setData] = useState([]);
  const [editdata, setEditData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(5);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [cancelRowId, setCancelRowId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [total, setTotal] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [aplicationId, setAplicationdId] = useState("");
  const [editShowModal, setEditShowModal] = useState(false);
  const headerData = {
    title: "İş vaxtından artıq işə çıxma",
    variant: "h3",
  };
  const getEmployees = async () => {
    try {
      const res = await getAllEmployees();
      setEmpData(res?.data?.employees);
    } catch (err) {
      handleApiError(err);
    }
  };
  const getData = async (filters) => {
    if (!filters) {
      filters = [];
    }
    const params = {
      limit: limit,
      offset: offset,
      filter: encodeURIComponent(JSON.stringify(filters)),
    };
    setLoading(true);
    try {
      const respons = await getListOvertimeApplication_SERVICE(params);
      setTotal(respons?.data?.data?.count);
      setData(respons?.data?.data?.records);
    } catch (err) {
      handleApiError(err);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData(filters);
  }, [limit, offset, filters]);
  useEffect(() => {
    getEmployees();
  }, []);
  const initialValues = {
    overtime: [
      {
        employee: "",
        contract: "",
        contractDate: "",
        structure: "",
        position: "",
        footer: [
          {
            date: "",
            hour: "",
            paymentType: "Double",
          },
        ],
      },
    ],
    reason: "",
    contractItem: "",
    type: "",
  };
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);
      const modifiedValues = {
        type: values?.type,
        reason: values?.reason,
        clause: values?.contractItem,
        employees: values?.overtime?.map((item) => ({
          id: item?.employee,
          staffId: item?.contract,
          workShifts: item?.footer?.map((foot) => ({
            date: moment(foot?.date).format("MM/DD/YYYY"),
            hours: parseFloat(foot?.hour),
            paymentType: foot?.paymentType,
          })),
        })),
      };
      const result = removeEmptyStrings(modifiedValues);
      const res = await postBulkOvertimeApplication_SERVICE(result);
      if (res?.status === 201) {
        set_show_Accordion(false);
        getData();
      }
      setSubmitting(false);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  function handleEdit(row) {
    setEditShowModal(true);
    setEditData(row);
  }
  async function handleCancelled(row) {
    setCancelDialog(true);
    setCancelRowId(row);
  }
  const handleOpenDialog = (row) => {
    const application = row?.employeeApplications?.find(
      (app) => app?.activity?.contract
    );

    if (application) {
      setSelectedId(application?.activity?.contract);
      setAplicationdId(application?._id);
      setShowModal(true);
    }
  };

  const operatorColumnGenerator = (row, column) => {
    const operators = [
      row?.employeeApplications?.some(
        (application) => application?.activity?.contract
      ) &&
        new Operator({
          icon: <RemoveRedEye />,
          onClick: () => handleOpenDialog(row),
          type: 0,
        }),
      [
        "requestedToEmployee",
        "cancelled",
        "rejectedOrderApprove",
        "rejected",
      ].some((status) =>
        row?.employeeApplications?.some((app) => app?.status === status)
      ) &&
        new Operator({
          icon: <Close />,
          onClick: () => handleCancelled(row?._id),
          type: 0,
        }),
      new Operator({
        icon: <Edit />,
        onClick: () => handleEdit(row),
        type: 0,
      }),
    ];
    console.log(operators);

    return <OperatorGenerator operators={operators} />;
  };

  return {
    data,
    limit,
    total,
    isDate,
    offset,
    loading,
    empData,
    getData,
    editdata,
    setLimit,
    showModal,
    setOffset,
    setIsDate,
    setFilters,
    headerData,
    selectedId,
    cancelRowId,
    cancelDialog,
    setShowModal,
    handleSubmit,
    aplicationId,
    editShowModal,
    initialValues,
    setSelectedId,
    show_Accordion,
    setCancelDialog,
    setEditShowModal,
    set_show_Accordion,
    operatorColumnGenerator,
  };
}
